import dayjs from 'dayjs';
import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * The EditChildModal component is used to edit child details in a modal.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {boolean} props.forceRefresh - A boolean indicating whether to force refresh the parent component.
 * @param {function} props.setForceRefresh - A function to set the forceRefresh value in the parent component.
 * @param {Object} props.record - The child record to edit.
 * @param {string} props.record._id - The unique identifier of the child.
 * @param {string} props.record.first_name - The first name of the child.
 * @param {string} props.record.last_name - The last name of the child.
 * @param {Object} props.record.pickup_code - The pickup code object.
 * @param {string} props.record.pickup_code.code - The pickup code of the child.
 * @returns {React.ReactNode} The EditChildModal component.
 */
export const EditChildModal = ({ forceRefresh, setForceRefresh, record }) => {
  const { fields, isFieldsLoading } = useFields();
  const { dispatchAPI } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.entry_date && dayjs(data.entry_date),
        value: data.weight.value,
        unit: data.weight.unit
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record,
        entry_date: dayjs(record?.entry_date),
        value: record?.weight?.value,
        unit: record?.weight?.unit
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (objective, body) => {
    try {
      setIsSubmitting(true);
      const url = `children/${record._id}`;
      await dispatchAPI(objective === 'create' ? 'POST' : 'PATCH', {
        url,
        body: {
          ...body,
          weight: {
            value: body.value,
            unit: body.unit
          }
        }
      });
      closeModal();
      form.resetFields();
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${record?.first_name} ${record?.last_name}`}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        baseUrl="children"
        resource="children"
        config={config}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        withoutPageHeaderCustom
        closeModal={closeModal}
        customFormInstance={form}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

EditChildModal.propTypes = {
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo: PropTypes.string,
    weight: PropTypes.shape({
      unit: PropTypes.string,
      value: PropTypes.number
    }),
    entry_date: PropTypes.string,
    pickup_code: PropTypes.shape({
      code: PropTypes.string
    })
  })
};

EditChildModal.defaultProps = {
  forceRefresh: false,
  setForceRefresh: null,
  record: null
};
