import PropTypes from 'prop-types';
import { Card, Flex, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlanningCircles } from '../../../../components/PlanningCircles/PlanningCircles';

/**
 * @description
 * PlanningCard is a component that displays the planning information
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the planning
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} PlanningCard component
 */
export const PlanningCard = ({ data, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      title={t('pre-registrations.show.cards.planning')}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <Flex gap="middle">
          <PlanningCircles referenceWeeks={data} />
        </Flex>
      </Skeleton>
    </Card>
  );
};

PlanningCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool
};

PlanningCard.defaultProps = {
  data: [],
  isLoading: false
};
