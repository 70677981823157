import dayjs from 'dayjs';

/**
 * Renders a formatted string of time slots from the provided reference day array.
 *
 * @param {Array<Date>} referenceDay - An array of date objects representing time slots.
 * @returns {string} A formatted string with the times in 'HH:mm' format, separated by ' : ' for the second slot.
 */
export const slotRender = (referenceDay) => {
  const datesArray = referenceDay.map(
    (slot, index) =>
      `${index === 1 ? ' : ' : ''}${dayjs.utc(slot).format('HH:mm')}`
  );

  return datesArray.join('');
};
