import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { customerNature } from '../../../../../utils/constants/tagColors';

/**
 * List content for the InformationsCard component
 * @hook
 * @param {Object} data The data of the customer account
 * @param {String} data.label The label of the customer account
 * @param {String} data.customer_nature The customer nature - exemple : 'physical' or 'moral'
 * @param {Array} data.linked_children The linked children of the contracts linked to the customer account
 * @returns {Array} The list content
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const { label, customer_nature, children } = data;

  const linkedChildren = children?.map((child) => {
    if (!child) return null;
    const { _id, first_name, last_name } = child;
    if (!_id && !first_name && !last_name) return null;
    return (
      <Link to={`/children/show/${_id}`} key={_id}>
        {`${first_name} ${last_name}`}
      </Link>
    );
  });

  const formattedChildren = linkedChildren?.reduce((acc, link, index) => {
    const separator = index < linkedChildren.length - 1 ? ', ' : '';
    return [...acc, link, separator];
  }, []);

  return [
    {
      label: 'customer-account.form.label',
      span: 1,
      content: label || '-'
    },
    {
      label: 'customer-account.form.customer_nature',
      span: 2,
      content:
        (
          <Tag color={customerNature[customer_nature]}>
            {t(`customer-account.form.${customer_nature}`)}
          </Tag>
        ) || '-'
    },
    {
      label: 'customer-account.form.linked_children',
      span: 3,
      content: children?.length ? formattedChildren : '-'
    }
  ];
};
