import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { Inbox } from './components/Inbox';
import { ParentConversation } from './components/ParentConversation';
import { useAuthContext } from '../../../contexts/AuthContext';
import { parentContentStyle } from '../../../utils/constants/parentContentStyle';

/**
 * `ParentInbox` is a React component that serves as a container for the messaging interface in a parent's portal.
 * It switches between showing an inbox view and a specific conversation view based on whether a conversation is selected.
 *
 * This component integrates with custom context hooks for authentication and conversation data, and utilizes Ant Design components
 * and custom components like `ContentCustom` and `PageHeaderCustom` for layout and UI elements.
 *
 * The component also handles the display logic to switch between viewing all messages in the inbox and a specific conversation.
 *
 * @returns {JSX.Element} A JSX element that conditionally renders either the inbox or a specific conversation view.
 */
export const ParentInbox = () => {
  const { user } = useAuthContext();
  const { selectedConversation, setSelectedConversation } =
    useConversationContext();
  const { t } = useTranslation();

  const interlocutor =
    Object.keys(selectedConversation).length &&
    selectedConversation.users.find((userItem) => userItem._id !== user._id);

  const title = Object.keys(selectedConversation).length ? (
    <Space>
      <Button type="link" onClick={() => setSelectedConversation({})}>
        <ArrowLeftOutlined />
      </Button>
      {`${t('messages_and_notes.conversation_with')} ${
        interlocutor.first_name
      } ${interlocutor.last_name}`}
    </Space>
  ) : (
    t('messages_and_notes.parent_title')
  );

  return (
    <>
      <PageHeaderCustom title={title} withoutBreadCrumbs withoutNavigateBack />
      <ContentCustom style={parentContentStyle}>
        {!Object.keys(selectedConversation).length ? (
          <Inbox />
        ) : (
          <ParentConversation />
        )}
      </ContentCustom>
    </>
  );
};
