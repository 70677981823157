import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Flex, Steps, message as AntdMessage, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';
import { PreRegistrationValidationForms } from './PreRegistrationValidationForms';
import { PreRegistrationsMethods } from './methods';
import handleNextStep from './utils/handleNextStep';
import formatBody from './utils/formatBody';
import { formItemLayout } from '../../utils/constants/formLayout';

const steps = [
  'additional_info',
  'customer_account',
  'payment_method',
  'documents'
];

/**
 * PreRegistrationValidation UI component.
 *
 * @component
 * @description UI component that displays the PreRegistrationValidation page with a form for each steps.
 * @return {object} The UI of the PreRegistrationValidation component.
 */
export const PreRegistrationValidation = () => {
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { preRegistrationId } = useParams();
  const { t } = useTranslation();
  const [preRegistration, setPreRegistration] = useState({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState('existingCustomerAccount');
  const [mandatoryDocuments, setMandatoryDocuments] = useState([]);
  const [directDebit, setDirectDebit] = useState(false);
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getPreRegistration, getMandatoryDocuments, patchContract } =
    PreRegistrationsMethods();

  const onFinish = () => {
    handleNextStep(
      form,
      current,
      setCurrent,
      preRegistration,
      setPreRegistration
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const { _id } = preRegistration.contract || {};

      const requiredDocuments = mandatoryDocuments.filter(
        (document) => document.required
      );

      const triggerBoolFunction =
        (requiredDocuments || []).length > 0
          ? requiredDocuments.every((document) =>
              filesConfiguration.some(
                (file) => file.fileKey === document.document_title
              )
            )
          : true;

      if (!_id)
        return AntdMessage.error(t('errors.message.contract_not_found'));

      if (!triggerBoolFunction) {
        setLoading(false);
        return AntdMessage.error(t('errors.message.missing_required'));
      }

      const customUrl = `/contracts/finale-validation`;
      await patchContract(
        _id,
        preRegistration,
        filesList,
        filesConfiguration,
        customUrl
      );

      setLoading(false);
      navigate(-1);
      return null;
    } catch (error) {
      return message(error);
    }
  };

  const props = {
    initialValues: preRegistration,
    onFinish: (values) => values && onFinish(values),
    handlePrevious: () => setCurrent(current - 1)
  };

  const forms = PreRegistrationValidationForms({
    props,
    selectedRadio,
    setSelectedRadio,
    setMandatoryDocuments,
    mandatoryDocuments,
    setCurrent,
    current,
    handleSubmit,
    preRegistration,
    filesConfiguration,
    setFilesConfiguration,
    filesList,
    setFilesList,
    loading,
    form,
    setDirectDebit,
    directDebit
  });

  const getDaycareDocumentsConfiguration = async () => {
    const data = await getMandatoryDocuments();
    if (data.length) {
      setMandatoryDocuments(data);
    }
  };

  const getPreRegistrationData = async () => {
    const data = await getPreRegistration(preRegistrationId);

    if (data) {
      const body = formatBody(data);
      form.setFieldsValue(body);
      setPreRegistration(body);
      setDirectDebit(body?.method === 'DIRECTDEBIT');
      setFilesList(body?.contract?.documents?.map((doc) => doc?.file));
      setFilesConfiguration(
        body?.contract?.documents?.map((doc) => ({
          name: doc?.file?.metadata?.originalName,
          fileKey: doc?.document_title,
          type: doc?.type
        }))
      );
    }
  };

  useEffect(() => {
    if (!preRegistrationId) return;
    (() => {
      getPreRegistrationData();
      getDaycareDocumentsConfiguration();
    })();
  }, [preRegistrationId]);

  return (
    <ContentCustom>
      <Flex vertical gap="large">
        <Steps current={current}>
          {steps.map((item) => (
            <Steps.Step key={item} title={t(`contract.steps.${item}`)} />
          ))}
        </Steps>
        <Form loading={!preRegistration} {...formItemLayout} form={form}>
          {forms[current]}
        </Form>
      </Flex>
    </ContentCustom>
  );
};
