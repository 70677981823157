import {
  Button,
  DatePicker,
  Form,
  Modal,
  Row,
  message as antDMessage
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  BorderlessTableOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { drawerTailFormLayout } from '../../utils/constants/formLayout';

const { RangePicker } = DatePicker;

const monthFormat = 'MM-YYYY';

/**
 * A component for exporting accounting data.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.objective - The objective for the export (e.g., 'invoices', 'payments').
 * @returns {JSX.Element} AccountingExportButton component.
 */
export const AccountingExportButton = ({ objective }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    form.resetFields();
    setOpen((prev) => !prev);
  };

  const getExportData = async (body) => {
    setIsSubmitting(true);
    try {
      const { headers, data } = await dispatchAPI('POST', {
        url: `accounting-configurations/export`,
        body: {
          ...body,
          daycare,
          objective
        },
        responseType: 'arraybuffer'
      });

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const contentDisposition = headers['Content-Disposition'];
      let fileName = `${t(
        `configurations.accounting.documents.${objective}`
      )} ${dayjs(body.range_date[0])
        .startOf('month')
        .format('MM-YYYY')} au ${dayjs(body.range_date[1])
        .endOf('month')
        .format('MM-YYYY')}.xlsx`;

      if (contentDisposition) {
        const matches = /filename="(.+?)"/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1];
        }
      }

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      antDMessage.success('EXPORT_SUCCESSFUL');
      handleModal();
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        icon={<BorderlessTableOutlined />}
        onClick={handleModal}
        type="primary"
      >
        {t(`configurations.accounting.button`)}
      </Button>
      <Modal
        title={t(`configurations.accounting.titles.${objective}`)}
        open={open}
        footer={null}
        onClose={handleModal}
        onCancel={handleModal}
      >
        <Form form={form} onFinish={getExportData}>
          <Form.Item
            label={t('configurations.accounting.form.range_date')}
            name={['range_date']}
          >
            <RangePicker picker="month" format={monthFormat} />
          </Form.Item>
          <Form.Item {...drawerTailFormLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={handleModal}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AccountingExportButton.propTypes = {
  objective: PropTypes.string.isRequired
};
