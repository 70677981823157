import { useCallback, useEffect, useState } from 'react';
import { Select, Switch, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

export const useHealthFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [childhoodIllnesses, setChildhoodIllnesses] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [allergies, setAllergies] = useState([]);

  const getChildhoodIllnesses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/childhood-illnesses'
      });
      setChildhoodIllnesses(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAllergies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/allergies'
      });
      setAllergies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getChildhoodIllnesses();
    await getAllergies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const healthFields = [
    {
      name: ['health', 'is_walking'],
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['health', 'allergies'],
      input: (
        <Select loading={isFieldsLoading} allowClear mode="multiple">
          {(allergies || []).map((allergie) => (
            <Option key={allergie._id} value={allergie._id}>
              {allergie.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['health', 'childhood_illnesses'],
      input: (
        <Select loading={isFieldsLoading} allowClear mode="multiple">
          {(childhoodIllnesses || []).map((childhood_illness) => (
            <Option key={childhood_illness._id} value={childhood_illness._id}>
              {childhood_illness.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['health', 'disability'],
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['health', 'personalized_care_plan'],
      input: <TextArea rows={4} style={{ resize: 'none' }} />
    }
  ];

  return { healthFields };
};
