import { formatNumberWithSpaces } from '../../../../utils/formatter';

/**
 * Custom hook that generates column configurations for a table.
 *
 * @hook
 *
 * @param {Function} t - The translation function.
 * @returns {Array} An array of objects representing column configurations,
 * each containing a title, key, dataIndex, and render function to format the data.
 */
export const useColumns = (t) => {
  const suffix = (record) => {
    if (
      [
        t('analysis.table.occupation_rate'),
        t('analysis.table.facturation_rate'),
        t('analysis.table.CAF_participation_rate')
      ].includes(record.label)
    ) {
      return '%';
    }
    return '€';
  };

  return [
    {
      title: '',
      key: 'label',
      dataIndex: 'label'
    },
    {
      title: t('analysis.table.results_real'),
      key: 'results_real',
      render: (record) => `${record?.results_real || 0} ${suffix(record)}`
    },
    {
      title: t('analysis.table.results_prevision'),
      key: 'results_prevision',
      render: (record) =>
        `${formatNumberWithSpaces(record?.results_prevision || 0)} ${suffix(
          record
        )}`
    }
  ];
};
