/**
 * Generates parameters for opening a modal based on the type of event.
 *
 * @function
 *
 * @param {object} event - The event object containing information about the child's schedule and absences.
 * @returns {object} The parameters for opening the modal, including the type of event, child information, times ID, and event ID.
 */
const generateType = (event) => {
  switch (true) {
    case event.childSchedule.type === 'absences':
      return 'absences';
    default:
      return 'pickup_times';
  }
};

/**
 * Generates parameters for opening a modal based on the type of event.
 *
 * @function
 *
 * @param {object} event - The event object containing information about the child's schedule and absences.
 * @returns {object} The parameters for opening the modal, including the type of event, child information, times ID, and event ID.
 */
export const generateOpenModalParameters = (event) => {
  const type = generateType(event);

  const events = [
    ...(event?.pickup_times || []),
    ...(event?.absences || [])
  ].map((pickupTime) => ({
    ...pickupTime,
    type: 'events'
  }));
  const refDay = (event?.reference_day || []).filter((day) =>
    Object.keys(day).some((key) => ['start_time', 'end_time'].includes(key))
  );

  const eventPlanificationToConsider =
    event?.adaptations?.length > 0 ? event.adaptations : refDay;
  const planificationWithReservations =
    event?.reservations?.length > 0
      ? [...event.reservations, ...eventPlanificationToConsider]
      : eventPlanificationToConsider;

  return {
    type,
    child: event?.child,
    timesID: event._id,
    eventID: event._id,
    previsionalPlanning: [...planificationWithReservations, ...events]
  };
};
