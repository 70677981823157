import { Flex, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IsWalking from '../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../assets/images/icons/healthicons_baby-0306m.svg';
import { slotRender } from './slotRender';

/**
 * Renders the content of a child's card line, displaying their name, group, walking status, and other details.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.child - The child object containing details.
 * @param {string} props.child.last_name - The last name of the child.
 * @param {string} props.child.first_name - The first name of the child.
 * @param {Object} props.child.group - The group object containing the group's label and tag color.
 * @param {string} props.child.group.tag_color - The color of the group's tag.
 * @param {string} props.child.group.label - The label of the group.
 * @param {boolean} props.child.is_walking - Indicates if the child is walking.
 * @param {string} props.color - The color used for the tag representing the child's status.
 * @param {string} props.referenceDay - The reference day for the child's status.
 * @param {string} props.error - The error message to be displayed if any.
 *
 * @returns {JSX.Element} The rendered JSX component.
 */
export const ChildrenCardLignContent = ({
  child = { last_name: '', first_name: '', group: '', is_walking: false },
  color,
  referenceDay,
  error
}) => {
  const { last_name, first_name, group, is_walking } = child;
  const { t } = useTranslation();

  return (
    <Flex justify="space-between">
      <Space>
        {`${first_name} ${last_name}`}
        <Tag color={group?.tag_color}>{group?.label}</Tag>
        {is_walking ? (
          <Space>
            <img src={IsWalking} alt="is walking" />
            {t(`home.children_card.is_walking.${is_walking}`)}
          </Space>
        ) : (
          <Space>
            <img src={DontWalk} alt="is not walking" />
            {t(`home.children_card.is_walking.${is_walking}`)}
          </Space>
        )}
      </Space>
      <Space>
        {t(`home.children_card.errors.${error}`)}
        <Tag color={color}>{slotRender(referenceDay)}</Tag>
      </Space>
    </Flex>
  );
};

ChildrenCardLignContent.propTypes = {
  child: PropTypes.shape({
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    group: PropTypes.string,
    is_walking: PropTypes.bool
  }),
  color: PropTypes.string,
  referenceDay: PropTypes.string,
  error: PropTypes.string
};

ChildrenCardLignContent.defaultProps = {
  child: null,
  color: undefined,
  referenceDay: undefined,
  error: undefined
};
