import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { outOfNavRoutes } from '../utils/constants/adminRoutes';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { UserLayout } from '../components/layouts/UserLayout';
import { DeveloperLayout } from '../components/layouts/DeveloperLayout';
import { ManagerLayout } from '../components/layouts/ManagerLayout';
import { routes as tabletRoutes } from '../utils/constants/tabletRoutes';
import { routes as parentRoutes } from '../utils/constants/parentRoutes';
import { TabletLayout } from '../components/layouts/TabletLayout';
import { ParentLayout } from '../components/layouts/ParentLayout';

/**
 * Higher-order component that handles authentication and user role-based rendering of layouts.
 * @component
 * @returns {JSX.Element} Rendered layout component based on user role or redirects to login if not authenticated.
 */
export const RequireAuth = () => {
  const location = useLocation();
  const { isValid, user } = useAuthContext();
  const { '*': urlToken } = useParams();

  const isPostRoute = urlToken?.includes('post-pwd');
  const fromRoute =
    user?.role === 'users:TABLET'
      ? tabletRoutes.PLANNING
      : parentRoutes.CHILDREN_FOLLOW_UP;

  const navigateObject = () => {
    if (['users:TABLET', 'guests:PARENT'].includes(user?.role)) {
      return {
        to: {
          pathname: outOfNavRoutes.LOGIN
        },
        state: { from: fromRoute }
      };
    }
    return {
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    };
  };

  if (!isValid && !isPostRoute) {
    return Navigate(navigateObject());
  }

  const renderLayout = () => {
    switch (user?.role) {
      case 'admins:CORPORATE':
        return <AdminLayout />;
      case 'admins:REGIONAL-MANAGER':
        return <AdminLayout />;
      case 'admins:SUPER-ADMIN':
        return <AdminLayout />;
      case 'users:TABLET':
        return <TabletLayout />;
      case 'users:MANAGER':
        return <ManagerLayout />;
      case 'guests:PARENT':
        return <ParentLayout />;
      case 'developer:DEVELOPER':
        return <DeveloperLayout />;
      case 'users:SUPER-USER':
      default:
        return <UserLayout />;
    }
  };

  return renderLayout();
};
