import { Table } from 'antd';
import React from 'react';
import { EditableRow } from '../../../global-settings/CAFScale/EditableRow';
import { EditableCell } from '../../../global-settings/CAFScale/EditableCell';

/**
 * Renders an expanded row for a specific accounting account within a table, allowing inline editing of its items.
 *
 * This function is used to render detailed data for a specific accounting account within an expandable table row. It utilizes EditableRow and EditableCell components to allow users to edit item values directly within the table. Upon editing, it updates the balance object with the new values and recalculates totals for the accounting account.
 * @function
 *
 * @param {Object[]} data - The data for the expanded row, including sub-items to be edited.
 * @param {Object[]} resourceColumns - Column configuration for the table, including editable fields.
 * @param {Function} setBalance - Function to update the parent component's balance state with changes.
 * @param {Object} balance - The current balance object being edited, which includes all accounting account data and totals.
 * @returns {JSX.Element} A table component configured for inline editing of accounting account items.
 */
export const expandedRowRender = (
  data,
  resourceColumns,
  setBalance,
  balance
) => {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleItemUpdate = (dataIndex, recordId, newValue) => {
    const updatedDocument = { ...balance };

    const itemIndex = updatedDocument.data.findIndex(
      (item) => item._id === data._id
    );
    const subItemIndex = updatedDocument.data[itemIndex].data.findIndex(
      (item) => item._id === recordId
    );

    if (subItemIndex !== -1 && itemIndex !== -1) {
      updatedDocument.data[itemIndex].data[subItemIndex][dataIndex] =
        Number(newValue);
    }

    if (itemIndex !== -1) {
      const accountingAccountData = updatedDocument.data[itemIndex].data;
      let newTotal = 0;
      for (let i = 0; i < accountingAccountData.length; i += 1) {
        newTotal += accountingAccountData[i][dataIndex];
      }
      updatedDocument.data[itemIndex].totals[dataIndex] = newTotal;
    }

    setBalance(updatedDocument);
  };

  const columns = resourceColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        suffix: col.suffix,
        index,
        handleItemUpdate
      })
    };
  });

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      pagination={false}
      scroll={{ x: 1000 }}
      rowKey={(record, index) => record._id || index}
      dataSource={data?.data || []}
      columns={columns}
    />
  );
};
