import { DatePicker, Select, Input, Form } from 'antd';

const { Option } = Select;

/**
 * Hook for managing form fields and loading enums for a user form.
 *
 * @hook
 *
 * @returns {object} - Object containing form fields configuration.
 */
const useFields = () => {
  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: true
    },
    {
      name: ['mobile_phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker />
    }
  ];

  return {
    fields
  };
};

export default useFields;
