import { Col, Row } from 'antd';
import { ResultsTable } from './ResultsTable';

/**
 * Determines if a graph should use a boolean switch based on the title of the graph card. This function is used to enable or disable
 * certain features or displays in the graph component, depending on the type of data being visualized.
 *
 * @function
 *
 * @param {Function} t - Translation function from 'i18next' for localization.
 * @param {string} title - Title of the graph card, used to identify the type of graph.
 * @returns {boolean} Returns true if the graph card title matches specific cases where a boolean switch is needed, otherwise false.
 */
const switchBool = (t, title) =>
  ![t('analysis.cards.occupation_rate_follow_up')].includes(title);

/**
 * Determines the purpose mode based on the provided title and translation function.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the analysis card.
 * @returns {Object} The purpose mode object containing mode and subMode.
 */
const purpose = (t, title) => {
  if (
    [
      t('analysis.cards.carried_out_hours_follow_up'),
      t('analysis.cards.billed_hours_follow_up')
    ].includes(title)
  ) {
    return {
      mode: 'follow_up',
      subMode: 'hours_follow_up'
    };
  }
  if (title === t('analysis.cards.occupation_rate_follow_up')) {
    return {
      mode: 'follow_up',
      subMode: 'rate_follow_up'
    };
  }
  return { mode: 'evolution' };
};

/**
 * A mapping of keys to corresponding colors.
 * @type {Object.<string, string>}
 */
const colorMap = {
  'Objectif à atteindre': '#6CCCF4',
  Constaté: '#EE3F68',
  Objectif: '#FCE200',
  Réél: '#CECECE',
  'CA Cumulé': '#EE3F68',
  Prévision: '#FCE102',
  Cumulé: '#6CCCF4'
};

/**
 * Prepares data for visualization based on the provided parameters.
 *
 * @function
 *
 * @param {Object} datas - The data object.
 * @param {Function} t - The translation function.
 * @param {Object} graphCardPurpose - The mode and submode for data preparation.
 * @param {boolean} isMonthlyMode - Flag indicating if the mode is monthly.
 * @returns {Object} An object containing prepared line and column data.
 */
export const prepareData = (datas, t, graphCardPurpose, isMonthlyMode) => {
  const lineData = [];
  const columnData = [];

  if (datas && graphCardPurpose.subMode === 'hours_follow_up') {
    (datas?.month?.observed || []).forEach((data) =>
      lineData.push({
        ...data,
        type: t(`analysis.graph.month_${data.type}`)
      })
    );
    (datas?.month?.objective || []).forEach((data) =>
      lineData.push({
        ...data,
        type: t(`analysis.graph.month_${data.type}`)
      })
    );
    if (!isMonthlyMode) {
      (datas?.cumulate || []).forEach((data) =>
        columnData.push({
          ...data,
          type: t(`analysis.graph.month_${data.type}`)
        })
      );
    }
  } else if (datas && graphCardPurpose.subMode === 'rate_follow_up') {
    (datas?.observed || []).forEach((data) =>
      lineData.push({
        ...data,
        type: t(`analysis.graph.month_${data.type}`)
      })
    );
    (datas?.objective || []).forEach((data) =>
      lineData.push({
        ...data,
        type: t(`analysis.graph.month_${data.type}`)
      })
    );
  }
  if (datas && graphCardPurpose.mode === 'evolution') {
    if (datas?.month?.observed) {
      Object.keys(datas?.month?.observed).forEach((key) => {
        lineData.push({
          ...datas?.month?.observed[key],
          type: t(`analysis.graph.month_${datas?.month?.observed[key].type}`)
        });
      });
    }
    if (datas?.cumulate) {
      Object.keys(datas?.cumulate).forEach((key) => {
        columnData.push({
          ...datas?.cumulate[key],
          type: t(`analysis.graph.month_${datas?.cumulate[key].type}`)
        });
      });
    }
  }

  return {
    lineData,
    columnData
  };
};

/**
 * Generates labels for the Y-axis of a follow-up chart based on the provided parameters.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} text - The text to be formatted for the Y-axis label.
 * @param {string} title - The title of the chart.
 * @returns {string|number} The formatted label for the Y-axis.
 */
const followUpLabelYAxis = (t, text, title) => {
  if (!text) {
    return 0;
  }
  if (
    [
      t('analysis.cards.carried_out_hours_follow_up'),
      t('analysis.cards.billed_hours_follow_up')
    ].includes(title)
  ) {
    return `${text}h`;
  }
  if (
    [
      t('analysis.cards.operating_incomes_evolution'),
      t('analysis.cards.operating_expenses_evolution'),
      t('analysis.cards.operating_results_evolution')
    ].includes(title)
  ) {
    return `${text}€`;
  }
  if (title === t('analysis.cards.occupation_rate_follow_up')) {
    return `${text}%`;
  }
  return text;
};

/**
 * Formats a number with spaces as thousand separators and ensures two decimal places.
 *
 * @function
 *
 * @param {number|string} num - The number to format. It can be a number or a string representation of a number.
 * @returns {string|number} The formatted number with spaces as thousand separators and two decimal places.
 *                          Returns 0 if the input is undefined or falsy (except zero).
 */
const formatNumberWithSpaces = (num) => {
  if (typeof num === 'undefined' || !num) {
    return 0;
  }
  if (num !== 0) {
    return Number(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return num;
};

/**
 * Generates custom tooltip content for a simple line chart.
 *
 * @function
 *
 * @param {string} tooltipTitle - The title to be displayed in the tooltip.
 * @param {Array} items - The items to be displayed in the tooltip.
 * @param {string} suffix - Suffix of the tooltip text.
 * @returns {string} - The generated HTML string for the tooltip content.
 */
const generateTooltipCustomContentForSimpleLine = (
  tooltipTitle,
  items,
  suffix
) => {
  let itemDisplayed;
  if (items.length === 1) {
    itemDisplayed = items[0];
  } else if (items.length === 2) {
    itemDisplayed = items.find((item) => item.name === 'Prévision');
  } else return '';
  const value = `${(itemDisplayed.data.value || 0).toFixed(2)}`;
  return `<div><h4>${tooltipTitle}</h4><p>${
    itemDisplayed.name
  }: ${formatNumberWithSpaces(value)} ${suffix}</p></div>`;
};

/**
 * Generates custom tooltip content for a dual axes chart.
 *
 * @function
 *
 * @param {string} tooltipTitle - The title to be displayed in the tooltip.
 * @param {Array} items - The items to be displayed in the tooltip.
 * @param {string} suffix - Suffix of the tooltip text.
 * @returns {string} - The generated HTML string for the tooltip content.
 */
const generateTooltipCustomContentForDualAxes = (
  tooltipTitle,
  items,
  suffix
) => {
  const itemsFiltered = items;

  if (itemsFiltered.length > 1) {
    let doesItHaveReal = false;
    let doesItHavePrevisional = false;
    if (itemsFiltered.some((item) => item.name === 'Prévision')) {
      doesItHavePrevisional = true;
    }
    if (itemsFiltered.some((item) => item.name === 'Constaté')) {
      doesItHaveReal = true;
    }

    if (doesItHavePrevisional && doesItHaveReal) {
      const realIndex = itemsFiltered.findIndex(
        (item) => item.name === 'Constaté'
      );

      itemsFiltered.splice(realIndex, 1);
    }
  }

  return `<div style={{margin: "5px"}}>
          <h4>${tooltipTitle}</h4>
            ${items
              .map((item) => {
                const value = `${(
                  item?.data?.value ||
                  item?.data?.count ||
                  0
                ).toFixed(2)}`; // Adjust this to match your data structure
                return `<p>${item.name}: ${formatNumberWithSpaces(
                  value
                )} ${suffix}</p>`;
              })
              .join('')}
          </div>`;
};

/**
 * Generates configuration object for line chart using provided data and parameters.
 *
 * @hook
 *
 * @param {Object} data - The data object containing lineData.
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the chart.
 * @param {string} suffix - Suffix of the tooltip text.
 * @returns {Object} The configuration object for line chart.
 */
const useLineDataConfig = (data, t, title, suffix) => ({
  data: data.lineData,
  xField: 'month',
  tooltip: {
    customContent: (tooltipTitle, items) =>
      generateTooltipCustomContentForSimpleLine(tooltipTitle, items, suffix)
  },
  yField: 'value',
  yAxis: {
    label: {
      formatter: (text) => followUpLabelYAxis(t, text, title)
    }
  },
  legend: {
    position: 'bottom'
  },
  seriesField: 'type',
  color: ({ type }) => colorMap[type],
  lineStyle: ({ type }) => {
    if (type === 'Prévision') {
      return {
        lineDash: [4, 4]
      };
    }
    return {};
  }
});

/**
 * Generates configuration object for dual axes chart using provided data.
 *
 * @hook
 *
 * @param {Object} data - The data object containing columnData and lineData.
 * @param {string} suffix - Suffix of the tooltip text.
 * @returns {Object} The configuration object for dual axes chart.
 */
const useDualAxesConfig = (data, suffix) => ({
  data: [data.lineData, data.columnData],
  xField: 'month',
  yField: ['value', 'count'],
  tooltip: {
    customContent: (tooltipTitle, items) =>
      generateTooltipCustomContentForDualAxes(tooltipTitle, items, suffix)
  },
  legend: {
    position: 'bottom'
  },
  geometryOptions: [
    {
      geometry: 'line',
      isGroup: true,
      seriesField: 'type',
      color: ({ type }) => colorMap[type]
    },
    {
      geometry: 'line',
      lineStyle: ({ type }) => {
        if (type === 'Prévision') {
          return {
            lineDash: [4, 4]
          };
        }
        return {};
      },
      seriesField: 'type',
      color: ({ type }) => colorMap[type]
    }
  ],
  yAxis: {
    value: {
      min: 0
    },
    count: {
      min: 0
    }
  }
});
/**
 * Generates a suffix based on the provided graph card purpose.
 *
 * @function
 *
 * @param {Object} graphCardPurpose - The purpose of the graph card.
 * @param {string} graphCardPurpose.mode - The mode of the graph card.
 * @param {string} graphCardPurpose.subMode - The sub-mode of the graph card.
 * @returns {string} The appropriate suffix ('h', '%', or '€') based on the mode and sub-mode.
 */
const generateSuffix = (graphCardPurpose) => {
  switch (true) {
    case graphCardPurpose.mode === 'follow_up' &&
      graphCardPurpose.subMode === 'hours_follow_up':
      return 'h';
    case graphCardPurpose.mode === 'follow_up' &&
      graphCardPurpose.subMode === 'rate_follow_up':
      return '%';
    default:
      return '€';
  }
};

/**
 * Generates configuration object for a graph based on provided parameters and data.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the graph.
 * @param {Object} datas - The data object.
 * @param {boolean} isMonthlyMode - Flag indicating if the mode is monthly.
 * @returns {Object} The configuration object for the graph.
 */
export const useGraphConfig = (t, title, datas, isMonthlyMode) => {
  const boolean = switchBool(t, title);
  const graphCardPurpose = purpose(t, title);
  const data = prepareData(datas, t, graphCardPurpose, isMonthlyMode);

  const suffix = generateSuffix(graphCardPurpose);

  const lineDataConfig = isMonthlyMode
    ? useLineDataConfig(data, t, title, suffix)
    : useDualAxesConfig(data, suffix);

  const graphConfig = lineDataConfig;
  return {
    boolean,
    graphCardPurpose,
    graphConfig
  };
};

/**
 * Renders the specified component along with a results table if the conditions for the title and purpose match.
 * This function is designed to conditionally layout components based on the type of analysis being performed.
 *
 * @function
 *
 * @param {JSX.Element} component - The main component to be rendered.
 * @param {string} title - The title of the analysis card, which determines the rendering behavior.
 * @param {Array} datas - The data to be passed to the `ResultsTable` if rendered.
 * @param {Function} t - Translation function from 'react-i18next' used for internationalization.
 * @returns {JSX.Element} A row layout with the main component and, conditionally, a results table.
 */
export const render = (component, title, datas, t) => {
  const { mode } = purpose(t, title);
  const tableData =
    title === t('analysis.cards.operating_expenses_evolution')
      ? datas.expenses
      : datas.incomes;

  if (
    mode === 'evolution' &&
    [
      t('analysis.cards.operating_expenses_evolution'),
      t('analysis.cards.operating_incomes_evolution')
    ].includes(title)
  ) {
    const spanSize =
      title === t('analysis.cards.operating_expenses_evolution') ? 24 : 12;

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={spanSize}>
          {component}
        </Col>
        <Col xs={24} xl={spanSize}>
          <ResultsTable datas={tableData} />
        </Col>
      </Row>
    );
  }
  return component;
};

export const generateTableData = (t, source, data = {}) => {
  const {
    facturation_rate,
    previsional_facturation_rate,
    occupation_rate,
    previsional_occupation_rate,
    PSU_hour_amount,
    previsional_PSU_hour_amount,
    CAF_participation_rate,
    previsional_CAF_participation_rate,
    PSU_amount,
    previsional_PSU_amount,
    cost_price,
    previsional_cost_price
  } = data;
  if (source === 'expenses') {
    return [
      {
        label: t('analysis.table.cost_price'),
        results_real: cost_price,
        results_prevision: previsional_cost_price
      }
    ];
  }
  if (source === 'incomes') {
    return [
      {
        label: t('analysis.table.occupation_rate'),
        results_real: occupation_rate,
        results_prevision: previsional_occupation_rate
      },
      {
        label: t('analysis.table.facturation_rate'),
        results_real: facturation_rate,
        results_prevision: previsional_facturation_rate
      },
      {
        label: t('analysis.table.PSU_hour_amount'),
        results_real: PSU_hour_amount,
        results_prevision: previsional_PSU_hour_amount
      },
      {
        label: t('analysis.table.CAF_participation_rate'),
        results_real: CAF_participation_rate,
        results_prevision: previsional_CAF_participation_rate
      },
      {
        label: t('analysis.table.PSU_amount'),
        results_real: PSU_amount,
        results_prevision: previsional_PSU_amount
      }
    ];
  }

  return [];
};
