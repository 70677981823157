import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Flex, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import { EditHealthModal } from './EditHealthModal';
import { VaccinesList } from './VaccinesList';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * HealthTab component for displaying health-related information and managing health records.
 *
 * @component
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.health - Health information object.
 * @param {Array} props.health.allergies - An array of allergy objects.
 * @param {Array} props.health.childhood_illnesses - An array of childhood illness objects.
 * @param {Array} props.health.vaccines - An array of vaccine objects.
 * @param {string} props.health._id - Health record ID.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @param {string} props.childName - The name of the child associated with the health record.
 * @param {Array} props.personalized_care - An array of personalized care objects.
 * @param {boolean} props.refresh - Indicates if a refresh action is needed.
 * @param {function} props.setRefresh - Function to set the refresh state.
 * @param {Array} props.note - An array of note objects.
 * @returns {JSX.Element} The rendered HealthTab component.
 */
export const HealthTab = ({
  isLoading,
  childName,
  health,
  personalized_care,
  refresh,
  setRefresh,
  note
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const [daycareVaccines, setDaycareVaccines] = useState([]);
  const { message } = useErrorMessage();

  const [modal, setModal] = useState();

  const handleModalBehaviour = () => {
    const modalKey = Date.now();
    setModal(
      <EditHealthModal
        key={modalKey}
        forceRefresh={refresh}
        setForceRefresh={setRefresh}
        record={health}
        childName={childName}
      />
    );
  };

  const getDaycareVaccines = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `daycares/${daycare}/daycare-vaccines`
      });
      setDaycareVaccines(data.vaccines_dynamic_list);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getDaycareVaccines();
    })();
  }, []);

  return (
    <>
      <Flex wrap="nowrap" align="center" gap="small">
        <Divider orientation="left">
          {t('children.show.health.title.infos')}
        </Divider>
        <Button type="primary" onClick={handleModalBehaviour}>
          <EditOutlined />
          {t('buttons.edit')}
        </Button>
      </Flex>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} xxl={6}>
          <Card title={t('children.show.health.allergies')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <Row style={{ flexDirection: 'column' }}>
                {health?.allergies?.length ? (
                  health?.allergies.map((allergy) => (
                    <Col key={allergy._id}>{allergy.label}</Col>
                  ))
                ) : (
                  <Col>{t('children.show.diet.allergies.no-allergies')}</Col>
                )}
              </Row>
            </Skeleton>
          </Card>
        </Col>
        <Col xs={24} md={12} xxl={6}>
          <Card title={t('children.show.health.childhood_illnesses')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <Row style={{ flexDirection: 'column' }}>
                {health?.childhood_illnesses?.length ? (
                  health?.childhood_illnesses.map((illness) => (
                    <Col key={illness._id}>{illness.label}</Col>
                  ))
                ) : (
                  <Col>{t('children.show.diet.allergies.no-allergies')}</Col>
                )}
              </Row>
            </Skeleton>
          </Card>
        </Col>
        <Col xs={24} md={12} xxl={6}>
          <Card title={t('children.show.health.personalized_care.title')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <DescriptionList
                layout="vertical"
                data={personalized_care}
                translate
              />
            </Skeleton>
          </Card>
        </Col>
        <Col xs={24} md={12} xxl={6}>
          <Card title={t('children.show.health.note')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <DescriptionList layout="vertical" data={note} translate />
            </Skeleton>
          </Card>
        </Col>
      </Row>
      <Col span={24}>
        <Divider orientation="left">
          {t('children.show.health.title.vaccines')}
        </Divider>
      </Col>
      <Col span={24}>
        <VaccinesList
          vaccineData={health.vaccines}
          refresh={refresh}
          setRefresh={setRefresh}
          healthID={health?._id}
          daycareVaccines={daycareVaccines}
        />
      </Col>
      {modal}
    </>
  );
};

HealthTab.propTypes = {
  health: PropTypes.shape({
    allergies: PropTypes.arrayOf(PropTypes.shape({})),
    childhood_illnesses: PropTypes.arrayOf(PropTypes.shape({})),
    vaccines: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  childName: PropTypes.string,
  personalized_care: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  note: PropTypes.arrayOf(PropTypes.shape({}))
};

HealthTab.defaultProps = {
  health: null,
  isLoading: false,
  childName: undefined,
  personalized_care: null,
  refresh: false,
  setRefresh: null,
  note: null
};
