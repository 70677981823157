/**
 * Calculates and returns the style object for highlighting selected time ranges.
 *
 * @param {number} hour - The current hour.
 * @param {number} index - The index indicating the first or second time range.
 * @param {object} selectedStartTime - The selected start time for the first range.
 * @param {object} selectedStartTime2 - The selected start time for the second range.
 * @param {object} selectedEndTime - The selected end time for the first range.
 * @param {object} selectedEndTime2 - The selected end time for the second range.
 * @returns {Object|null} A style object for highlighting selected time ranges, or null if not selected.
 */
export const getSelectedStyle = (
  hour,
  index,
  selectedStartTime,
  selectedStartTime2,
  selectedEndTime,
  selectedEndTime2
) => {
  const startTimeVar = index === 0 ? selectedStartTime : selectedStartTime2;
  const endTimeVar = index === 0 ? selectedEndTime : selectedEndTime2;

  if (!startTimeVar || !endTimeVar) return null;

  const selectedStartHour = startTimeVar?.hour();
  const selectedStartMinute = startTimeVar?.minute();
  const selectedEndHour = endTimeVar?.hour();
  const selectedEndMinute = endTimeVar?.minute();
  const currentHour = parseInt(hour, 10);

  if (currentHour < selectedStartHour || currentHour > selectedEndHour) {
    return null;
  }

  const startMinute =
    currentHour === selectedStartHour ? selectedStartMinute : 0;
  const endMinute = currentHour === selectedEndHour ? selectedEndMinute : 60;

  const totalMinutes = 60;
  const selectedWidth = ((endMinute - startMinute) / totalMinutes) * 100;
  const selectedPosition = (startMinute / totalMinutes) * 100;

  return {
    width: `${selectedWidth}%`,
    left: `${selectedPosition}%`
  };
};
