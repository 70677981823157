import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { ListResource } from '../../../../../components';
import { useColumns } from './columns';
import { ExtraButton } from './ExtraButton';

/**
 * @component
 * @name PaymentsCard
 * @description Displays the payments card in the customer account page
 * @returns {component} PaymentsCard component
 */
export const PaymentsCard = ({ id }) => {
  const { t } = useTranslation();
  const [rejected, setRejected] = useState(true);
  const columns = useColumns();
  const tradKey = 'customer-account.show.payments';

  return (
    <Card title={t(`${tradKey}.title`)}>
      <ListResource
        resourceName="payments"
        extraQuery={`limit=10&customer_account=${id}${
          rejected ? '&status=REJECTED' : ''
        }`}
        showAction={false}
        populate="invoice"
        columns={columns}
        extraButtons={<ExtraButton tradKey={tradKey} setState={setRejected} />}
        withCreateButton={false}
        editAction={false}
        archiveAction={false}
        deleteAction={false}
        withPageHeader={false}
        withSearchBar={false}
        withPagination={false}
        onDoubleClickAction={false}
      />
    </Card>
  );
};

PaymentsCard.propTypes = {
  id: PropTypes.string.isRequired
};
