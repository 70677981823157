import { useTranslation } from 'react-i18next';
import { useFields } from './useFields';

/**
 * Custom hook for generating collapse panels for accounting configurations.
 *
 * @hook
 *
 * @returns {Array} Array of objects representing collapse panels.
 */
export const useCollapses = () => {
  const { t } = useTranslation();
  const { invoicesFields, paymentFields, rejectionFields } = useFields();

  return [
    {
      title: t('configurations.accounting.invoices'),
      fields: invoicesFields,
      key: 'invoices'
    },
    {
      title: t('configurations.accounting.payments'),
      fields: paymentFields,
      key: 'payments'
    },
    {
      title: t('configurations.accounting.rejections'),
      fields: rejectionFields,
      key: 'rejections'
    }
  ];
};
