import { BaseModel } from './BaseModel';

/**
 * Represents hypotheses related to daycare analysis.
 * @extends BaseModel
 */
export class Hypotheses extends BaseModel {
  /**
   * Constructs a new instance of Hypotheses.
   * @param {object} data - The data object to initialize the hypotheses.
   */
  constructor(data) {
    super('hypotheses', undefined, data);
  }

  /**
   * Retrieves a single hypothesis for the specified daycare.
   * @param {string} daycare - The ID of the daycare.
   * @returns {Promise<object>} A promise that resolves with the retrieved hypothesis.
   */
  static getOne(daycare) {
    return this.dispatchAPI('GET', {
      url: `hypotheses?daycare=${daycare}`
    });
  }

  /**
   * Posts and replaces the previous hypotheses with the provided data.
   * @param {object} body - The data to be posted.
   * @returns {Promise<object>} A promise that resolves with the posted hypothesis.
   */
  static postAndReplacePrevious(body) {
    return this.dispatchAPI('POST', {
      url: `hypotheses`,
      body
    });
  }
}
