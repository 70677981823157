import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { Thread } from '../Thread';

/**
 * Custom component for displaying a thread of notes
 *
 * @component
 * @prop {boolean} refresh - Refresh the component
 * @returns {JSX.Element} - Custom component for displaying a thread of notes
 */
export const NotesThread = ({ refresh }) => {
  const { daycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNotes = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/notes?daycare=${daycare}&populate=from&sort=date`
      });
      setNotes(data);
      setLoading(false);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getNotes();
    })();
  }, [daycare, refresh]);

  return <Thread data={notes} loading={loading} />;
};

NotesThread.propTypes = {
  refresh: PropTypes.bool
};

NotesThread.defaultProps = {
  refresh: false
};
