/**
 * Handles filtering an array based on a translated value, updates the form, and sets the filtered data.
 *
 * @function
 *
 * @param {Array} dataSource - The data source array to filter.
 * @param {string} target - Targeted key in data source object.
 * @param {number} translationKey - The value used for filtering the data.
 *
 * @returns {void}
 */
export const handleArrayFilter = (dataSource, target, translationKey) => {
  switch (true) {
    case target === 'regular_extra_fees' &&
      translationKey === 'FIFTEEN_MINUTES':
      return [...(dataSource?.regular_extra_fees_fifteen_minutes || [])];
    case target === 'regular_extra_fees' && translationKey === 'THIRTY_MINUTES':
      return [...(dataSource?.regular_extra_fees_thirty_minutes || [])];
    case target === 'regular_extra_fees' && translationKey === 'SIXTY_MINUTES':
      return [...(dataSource?.regular_extra_fees_sixty_minutes || [])];
    case target === 'occasional_extra_fees' &&
      translationKey === 'FIFTEEN_MINUTES':
      return [...(dataSource?.occasional_extra_fees_fifteen_minutes || [])];
    case target === 'occasional_extra_fees' &&
      translationKey === 'THIRTY_MINUTES':
      return [...(dataSource?.occasional_extra_fees_thirty_minutes || [])];
    case target === 'occasional_extra_fees' &&
      translationKey === 'SIXTY_MINUTES':
      return [...(dataSource?.occasional_extra_fees_sixty_minutes || [])];
    default:
      return [];
  }
};

export const formKeys = [
  'regular_extra_fees_fifteen_minutes',
  'regular_extra_fees_thirty_minutes',
  'regular_extra_fees_sixty_minutes',
  'occasional_extra_fees_fifteen_minutes',
  'occasional_extra_fees_thirty_minutes',
  'occasional_extra_fees_sixty_minutes'
];

/**
 * Picks the form key based on the target and translationKey.
 *
 * @function
 *
 * @param {string} target - The target type, e.g., 'regular_extra_fees' or 'occasional_extra_fees'.
 * @param {string} translationKey - The translation key indicating the time duration, e.g., 'FIFTEEN_MINUTES', 'THIRTY_MINUTES', 'SIXTY_MINUTES'.
 * @returns {string} The form key corresponding to the target and translationKey.
 */
export const pickFormKey = (target, translationKey) => {
  switch (true) {
    case target === 'regular_extra_fees' &&
      translationKey === 'FIFTEEN_MINUTES':
      return 'regular_extra_fees_fifteen_minutes';
    case target === 'regular_extra_fees' && translationKey === 'THIRTY_MINUTES':
      return 'regular_extra_fees_thirty_minutes';
    case target === 'regular_extra_fees' && translationKey === 'SIXTY_MINUTES':
      return 'regular_extra_fees_sixty_minutes';
    case target === 'occasional_extra_fees' &&
      translationKey === 'FIFTEEN_MINUTES':
      return 'occasional_extra_fees_fifteen_minutes';
    case target === 'occasional_extra_fees' &&
      translationKey === 'THIRTY_MINUTES':
      return 'occasional_extra_fees_thirty_minutes';
    case target === 'occasional_extra_fees' &&
      translationKey === 'SIXTY_MINUTES':
      return 'occasional_extra_fees_sixty_minutes';
    default:
      return 'regular_extra_fees_fifteen_minutes';
  }
};

/**
 * Handles adding a new element to the filtered data and updating the data source and form.
 *
 * @function
 *
 * @param {Array} filteredData - The current filtered data array.
 * @param {number} translatedValue - The value used to determine the duration increment.
 * @param {function} setDataSource - The function to set the updated data source.
 * @param {string} formKey - The target key in the data source to update.
 * @param {Number} extraFeeMultiplier - Extra fee multiplier
 * @param {object} form - Form instance.
 *
 * @returns {void}
 */
export const handleAdd = (
  filteredData,
  translatedValue,
  setDataSource,
  formKey,
  extraFeeMultiplier,
  form
) => {
  const lastItem =
    filteredData.length > 0 ? filteredData[filteredData.length - 1] : null;
  const extraFee = 0.25 * extraFeeMultiplier;

  const newElement = {
    duration: lastItem ? lastItem.duration + translatedValue : translatedValue,
    extra_fee: lastItem ? lastItem.extra_fee + extraFee : extraFee
  };

  setDataSource((prev) => {
    const updatedState = { ...prev };

    updatedState[formKey].push(newElement);

    form.setFields([{ name: formKey, value: updatedState[formKey] }]);

    return updatedState;
  });
};
