import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import { useState } from 'react';
import { FileOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../../../components';
import { TableComponent } from './components/TableComponent';
import { filterDocuments } from './utils/filterDocuments';
import { DocumentModal } from './components/DocumentModal';
import { GenerateDocumentModal } from './components/GenerateDocumentModal';

/**
 * A container component that manages and displays documents.
 *
 * This component handles the filtering, displaying and management (like addition) of child-related documents.
 * It consists of tables for "ATTESTATION" and "other-documents", as well as a modal for document actions.
 *
 * @component
 * @param {Object[]} documents - The array of documents for the child.
 * @param {boolean} refresh - A boolean to trigger the refreshing of documents.
 * @param {function} setRefresh - A function to update the refresh state.
 * @param {string} childName - The name of the child for whom the documents pertain.
 * @param {boolean} RIB - Presence of RIB.
 * @param {Object[]} contractsInfos - Dates of active contract.
 * @returns {JSX.Element} The rendered component.
 */
export const DocumentsContainer = ({
  documents,
  refresh,
  setRefresh,
  childName,
  RIB,
  contractsInfos
}) => {
  const { t } = useTranslation();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const { proofOfAbsences, otherDocuments } = filterDocuments(documents || []);
  const [modal, setModal] = useState(null);

  const generateModal = (source, record) => {
    const modalKey = Date.now();
    setModal(
      <DocumentModal
        key={modalKey}
        source={source}
        childName={childName}
        refresh={refresh}
        setRefresh={setRefresh}
        purpose={record ? 'edit' : 'create'}
        docToUpdate={record}
      />
    );
  };

  return (
    <ContentCustom>
      <Row justify="end">
        <Button
          onClick={() => setOpenDocumentModal(!openDocumentModal)}
          type="primary"
        >
          <FileOutlined />
          {t('children.documents.generate-document')}
        </Button>
      </Row>
      <Row>
        <TableComponent
          documents={proofOfAbsences}
          refresh={refresh}
          setRefresh={setRefresh}
          dividerTitle="ATTESTATION"
          handleModal={(_, record) => generateModal('ATTESTATION', record)}
          scroll={{ x: 900 }}
        />
      </Row>
      <Row>
        <TableComponent
          documents={otherDocuments}
          refresh={refresh}
          setRefresh={setRefresh}
          dividerTitle="OTHERS"
          handleModal={(_, record) => generateModal('OTHERS', record)}
          scroll={{ x: 900 }}
        />
      </Row>
      {modal}
      <GenerateDocumentModal
        open={openDocumentModal}
        setOpen={setOpenDocumentModal}
        childName={childName}
        RIB={RIB}
        contractsInfos={contractsInfos}
      />
    </ContentCustom>
  );
};

DocumentsContainer.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({}),
      document_title: PropTypes.string,
      visibility: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  childName: PropTypes.string,
  RIB: PropTypes.bool.isRequired,
  contractsInfos: PropTypes.shape({})
};

DocumentsContainer.defaultProps = {
  documents: null,
  refresh: false,
  setRefresh: null,
  childName: undefined,
  contractsInfos: null
};
