import {
  CheckOutlined,
  ClockCircleOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import { Row } from 'antd';
import dayjs from 'dayjs';

/**
 * Determines the duration to add for a specific vaccine dose.
 *
 * @function
 *
 * @param {number} dose - The dose number (e.g., 2, 3, 4).
 * @param {object} vaccine - The vaccine object containing dose interval information.
 * @returns {number | undefined} The duration in weeks to add for the given dose, or undefined if not found.
 */
const determineDurationToAdd = (dose, vaccine) => {
  switch (dose) {
    case 2:
      return vaccine?.second_dose_interval;
    case 3:
      return vaccine?.third_dose_interval;
    default:
      return vaccine?.fourth_dose_interval;
  }
};

/**
 * Determines and renders vaccine administration dates with icons and colors.
 *
 * @function
 *
 * @param {Array<object>} daycareVaccines - An array of daycare vaccine objects.
 * @param {object} record - The record containing vaccine information.
 * @param {number} dose - The dose number (e.g., 2, 3, 4).
 * @param {object} t - The translation function or object for internationalization.
 * @returns {JSX.Element | boolean} JSX element with date and icon, or false if no date is determined.
 */
export const determineVaccineDatesManagement = (
  daycareVaccines,
  record,
  dose,
  t
) => {
  const vaccine = daycareVaccines.find(
    (item) => item?._id === record?.vaccine._id
  );

  const durationToAdd = determineDurationToAdd(dose, vaccine);

  if (durationToAdd !== undefined) {
    const today = dayjs();
    let date;
    switch (dose) {
      case 2:
        if (!record?.first_dose) {
          return (
            <Row style={{ color: 'grey' }}>
              {`${t('children.show.health.waiting_dose')} ${dose - 1}`}
              <HourglassOutlined />
            </Row>
          );
        }
        if (record?.second_dose) {
          date = dayjs(record.second_dose);
          return (
            <Row style={{ color: 'green' }}>
              {date.format('DD/MM/YYYY')}
              <CheckOutlined />
            </Row>
          );
        }
        date = dayjs(record.first_dose).add(durationToAdd, 'month');
        if (date.isBefore(today) || date.isSame(today)) {
          return (
            <Row style={{ color: 'red' }}>
              {date.format('DD/MM/YYYY')}
              <ClockCircleOutlined />
            </Row>
          );
        }
        return (
          <Row style={{ color: 'grey' }}>
            {date.format('DD/MM/YYYY')}
            <HourglassOutlined />
          </Row>
        );
      case 3:
        if (!record?.second_dose && !record?.first_dose) {
          return '';
        }
        if (!record?.second_dose) {
          return (
            <Row style={{ color: 'grey' }}>
              {`${t('children.show.health.waiting_dose')} ${dose - 1}`}
              <HourglassOutlined />
            </Row>
          );
        }
        if (record?.third_dose) {
          date = dayjs(record.third_dose);
          return (
            <Row style={{ color: 'green' }}>
              {date.format('DD/MM/YYYY')}
              <CheckOutlined />
            </Row>
          );
        }
        date = dayjs(record.second_dose).add(durationToAdd, 'month');
        if (date.isBefore(today) || date.isSame(today)) {
          return (
            <Row style={{ color: 'red' }}>
              {date.format('DD/MM/YYYY')}
              <ClockCircleOutlined />
            </Row>
          );
        }
        return (
          <Row style={{ color: 'grey' }}>
            {date.format('DD/MM/YYYY')}
            <HourglassOutlined />
          </Row>
        );
      default:
        if (!record?.third_dose && !record?.second_dose) {
          return '';
        }
        if (!record?.third_dose) {
          return (
            <Row style={{ color: 'grey' }}>
              {`${t('children.show.health.waiting_dose')} ${dose - 1}`}
              <HourglassOutlined />
            </Row>
          );
        }
        if (record?.fourth_dose) {
          date = dayjs(record.fourth_dose);
          return (
            <Row style={{ color: 'green' }}>
              {date.format('DD/MM/YYYY')}
              <CheckOutlined />
            </Row>
          );
        }
        date = dayjs(record.third_dose).add(durationToAdd, 'month');
        if (date.isBefore(today) || date.isSame(today)) {
          return (
            <Row style={{ color: 'red' }}>
              {date.format('DD/MM/YYYY')}
              <ClockCircleOutlined />
            </Row>
          );
        }
        return (
          <Row style={{ color: 'grey' }}>
            {date.format('DD/MM/YYYY')}
            <HourglassOutlined />
          </Row>
        );
    }
  }
  return false;
};
