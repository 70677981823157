import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getContractDuration } from './getContractDuration';

/**
 * @description
 * useListContent is a hook that returns the content of the contract card.
 * It is used in the ContractCard component.
 *
 * @param {Object} data - The data of the contract
 * @returns {Array} The content of the contract card
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const { contract_infos } = data;

  return [
    {
      label: 'pre-registrations.show.contract.start_date',
      content: contract_infos?.start_date
        ? dayjs(contract_infos?.start_date).format('DD/MM/YYYY')
        : '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.contract.end_date',
      content: contract_infos?.end_date
        ? dayjs(contract_infos?.end_date).format('DD/MM/YYYY')
        : '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.contract.contract_duration',
      content:
        contract_infos?.start_date && contract_infos?.end_date
          ? getContractDuration(
              contract_infos?.start_date,
              contract_infos?.end_date,
              t
            )
          : '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.contract.is_flexible_planning',
      content: (() => {
        if (!contract_infos) {
          return '-';
        }
        if (contract_infos.is_flexible_planning) {
          return t('yes');
        }
        return t('no');
      })(),
      span: 1
    }
  ];
};
