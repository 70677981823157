import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateDate } from './generateDate';

/**
 * Generates menu items based on event data for creating reservations, absences, adaptations, and pickup times.
 *
 * @hook
 *
 * @param {object} event - The event object containing relevant data.
 * @param {Function} openModal - Function to open a modal for creating events.
 * @param {object} child - The child object associated with the event.
 * @returns {Array} An array of menu items, each containing a key and a label.
 */
export const useMenuItems = (event, openModal, child) => {
  const { t } = useTranslation();

  const events = [
    ...(event?.pickup_times || []),
    ...(event?.absences || [])
  ].map((pickupTime) => ({
    ...pickupTime,
    type: 'events'
  }));

  const refDay = (event?.reference_day || [])
    .filter((day) => Object.keys(day).some((key) => key === 'start_time'))
    .filter(Boolean)
    .map((slot) => ({ ...slot, on_previsional_planning: 'REGULARS' }));
  const formattedReservations = (event?.reservations || []).map(
    (reservation) => ({
      ...reservation,
      on_previsional_planning: 'RESERVATIONS'
    })
  );
  const formattedAdaptations = (event?.adaptations || []).map((adaptation) => ({
    ...adaptation,
    on_previsional_planning: 'ADAPTATIONS'
  }));

  const eventPlanificationToConsider =
    formattedAdaptations?.length > 0 ? formattedAdaptations : refDay;
  const planificationWithReservations =
    formattedReservations?.length > 0
      ? [...formattedReservations, ...eventPlanificationToConsider]
      : eventPlanificationToConsider;

  const previsionalPlanning = [...planificationWithReservations, ...events];

  const projectionPlanningSize =
    event?.childSchedule?.planningProjections?.length || 0;
  const previsionalPresence =
    refDay?.length > 0 ||
    event?.reservations?.length > 0 ||
    event?.adaptations?.length > 0;

  const eventAbsenceDeclaration =
    (event?.absences?.length || 0) === 0 &&
    (event?.pickup_times?.length || 0) === 0;

  const displayReservationAdaptationMenuItems =
    eventAbsenceDeclaration &&
    (event?.adaptations?.length === 0 ||
      !Object.keys(event).some((eventKey) => eventKey === 'adaptations')) &&
    (event?.reservations?.length === 0 ||
      !Object.keys(event).some((eventKey) => eventKey === 'reservations'));

  const displayAbsenceTimeRecorderButtonsMenuItem =
    projectionPlanningSize > 0 && previsionalPresence;

  const displayAbsenceButtonsMenuItem =
    displayAbsenceTimeRecorderButtonsMenuItem && event?.absences?.length === 0;

  const displayTimeRecorderMenuItem =
    displayAbsenceTimeRecorderButtonsMenuItem &&
    event?.pickup_times?.length < 2;

  const reservationButton = displayReservationAdaptationMenuItems && {
    key: 'reservations',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'reservations',
            child,
            'create',
            undefined,
            undefined,
            previsionalPlanning,
            generateDate(event)
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.reservations')}
      </Button>
    )
  };
  const absenceButton = displayAbsenceButtonsMenuItem && {
    key: 'absences',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'absences',
            child,
            'create',
            undefined,
            undefined,
            previsionalPlanning,
            generateDate(event)
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.absences')}
      </Button>
    )
  };
  const adaptationButton = displayReservationAdaptationMenuItems && {
    key: 'adaptations',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'adaptations',
            child,
            'create',
            undefined,
            undefined,
            null,
            generateDate(event)
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.adaptations')}
      </Button>
    )
  };
  const timeRecorderButton = displayTimeRecorderMenuItem && {
    key: 'pickup_times',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'pickup_times',
            child,
            'create',
            undefined,
            undefined,
            previsionalPlanning,
            generateDate(event)
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.pickup_times')}
      </Button>
    )
  };

  const items = [
    absenceButton,
    adaptationButton,
    reservationButton,
    timeRecorderButton
  ].filter(Boolean);

  return items;
};
