import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { usePwdPattern } from '../../utils/pwdPattern';

const CreatePwdForm = ({ switchForm }) => {
  const location = useLocation();
  const { isValid, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = location?.state || { from: { pathname: '/' } };

  const createPwd = async (email, password, confirm) => {
    setLoading(true);
    try {
      await dispatchAPI('CREATE_PWD', {
        email,
        password,
        confirm
      });
      switchForm('login');
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await createPwd(
      values.username.toLowerCase(),
      values.password,
      values.confirm
    );
  };

  return (
    <Form onFinish={handleSubmit}>
      {isValid && <Navigate to={from} />}
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('login.pwdMissing') },
          ...usePwdPattern()
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item
        dependencies={['password']}
        hasFeedback
        name="confirm"
        rules={[
          {
            required: true,
            message: t('login.pwdMissing')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('login.pwdNotMatching'));
            }
          })
        ]}
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.confirmPassword')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.title.createPwd')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePwdForm;

CreatePwdForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};
