import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  TimePicker
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { disabledTime } from '../../../components/TimeRangeBar/utils/disabledTime';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { createTimesLimits } from '../../planning/createTimesLimit';
import { convertEventType } from './utils';

const { Option } = Select;
const { TextArea } = Input;
const formatDate = 'DD/MM/YYYY';

/**
 * Hook to generate fields for a form based on employee information, selected day, purpose, and type.
 *
 * @hook
 *
 * @param {Object} employee - The employee object.
 * @param {Date} selectedDay - The selected day for the form.
 * @param {string} purpose - The purpose of the form (e.g., 'edit', 'create').
 * @param {string} type - The type of the form (e.g., 'attendance', 'leave').
 * @param {String} reference_day The previsional planning.
 * @returns {Object} An object containing an array of field objects.
 */
const useFields = (employee, selectedDay, purpose, type, reference_day) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [absencesReason, setAbsencesReason] = useState();

  const getReservationsReason = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/absence-reason' });
      setAbsencesReason(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getReservationsReason();
    })();
  }, []);

  const { hourStart, hourEnd, minuteStart, minuteEnd } = createTimesLimits(
    convertEventType(type),
    reference_day
  );

  const fields = [
    {
      name: ['type'],
      initialValue: type,
      hidden: true,
      rules: [{ required: true }]
    },
    {
      name: ['employee'],
      initialValue: employee?._id,
      hidden: true,
      rules: [{ required: true }]
    },
    {
      name: ['date'],
      input: (
        <DatePicker
          allowClear={false}
          defaultValue={dayjs(selectedDay)}
          format={formatDate}
          disabled={purpose === 'edit'}
        />
      )
    },
    ...(['REGULAR', 'PLANNING'].includes(type)
      ? [
          {
            name: ['regulars'],
            label: type !== 'PLANNING' ? 'regulars' : 'planning',
            input: (
              <Form.List name="regulars">
                {(Fields, { add, remove }) => (
                  <>
                    {Fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'eventId']}
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, 'times']}>
                          <TimePicker.RangePicker
                            format="HH:mm"
                            disabledTime={() => disabledTime(6, 21)}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={Fields.length >= 2}
                      >
                        Ajouter un créneau
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )
          }
        ]
      : [
          {
            name: ['times'],
            rules: [{ required: true }],
            input: (
              <TimePicker.RangePicker
                format="HH:mm"
                disabledTime={() =>
                  disabledTime(hourStart, hourEnd, null, minuteStart, minuteEnd)
                }
              />
            )
          },
          ...(['REGULAR_ABSENCE', 'IRREGULAR_ABSENCE'].includes(type)
            ? [
                {
                  name: ['reason'],
                  rules: [{ required: type === 'REGULAR_AB' }],
                  input: (
                    <Select loading={!absencesReason}>
                      {(absencesReason || []).map((reason) => (
                        <Option key={reason.name} value={reason._id}>
                          {t(`${reason.name}`)}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : []),
          {
            name: ['comment'],
            input: <TextArea rows={3} />
          }
        ])
  ];

  return {
    fields
  };
};

export default useFields;
