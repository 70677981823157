import { useTranslation } from 'react-i18next';
import { Col, Flex, Popconfirm, Row, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  planningBorderColor,
  principalColorPlanning
} from '../../../utils/constants/tagColors';
import {
  convertEventType,
  reverseConvertEventType
} from '../../employee/planning/utils';

/**
 * Component to render overtime events.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {string} props.source - Source of the event.
 * @param {number} props.widthDiv - Width divisor.
 * @param {object} props.events - Event data.
 * @param {boolean} props.displayButtons - Whether to display edit and delete buttons.
 * @param {Function} props.openModal - Function to open modal.
 * @param {string} props.elementToConsider - Element to consider.
 * @param {Function} props.deleteEvent - Function to delete event.
 * @returns {JSX.Element[]} Array of JSX elements representing overtime events.
 */
export const Overtimes = ({
  source,
  widthDiv,
  events,
  displayButtons,
  openModal,
  elementToConsider,
  deleteEvent
}) => {
  const { t } = useTranslation();
  const { childSchedule, _id, reference_day } = events;
  const { overtimes } = childSchedule;

  return (overtimes || []).map((overtime) => {
    if (overtime) {
      const {
        marginLeft,
        overtimeMatchType,
        start_time,
        end_time,
        type,
        id,
        width
      } = overtime;

      const planningMarginLeftFactor = marginLeft / 100;
      const spanMarginLeft = (widthDiv * planningMarginLeftFactor).toFixed(0);
      const principalColor = principalColorPlanning.overtimes;
      const hoverBorderColor = planningBorderColor.overtimes;

      const childModalType = type !== 'absences' ? 'pickup_times' : 'absences';
      const employeeModalType =
        overtimeMatchType === 'presence'
          ? 'REGULAR'
          : reverseConvertEventType(type);
      const modalTypeToConsider =
        source === 'child' ? childModalType : employeeModalType;
      const deleteEventType =
        source === 'child'
          ? modalTypeToConsider
          : convertEventType(employeeModalType);

      return (
        <Tooltip
          key={`${start_time} ${end_time}`}
          title={
            <Row justify="center">
              <Col
                span={20}
                style={{
                  textAlign: 'left',
                  color: principalColor
                }}
              >
                {t(`plannings.children.type.${overtimeMatchType}`)}
              </Col>
              <Col
                span={4}
                style={{
                  textAlign: 'right',
                  color: 'var(--textColor)'
                }}
              >
                {displayButtons && (
                  <Flex gap="small">
                    <EditOutlined
                      onClick={() =>
                        openModal(
                          modalTypeToConsider,
                          elementToConsider,
                          'edit',
                          id,
                          _id,
                          reference_day
                        )
                      }
                    />
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() =>
                        deleteEvent(deleteEventType, id, _id, source)
                      }
                      icon={<WarningOutlined />}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Flex>
                )}
              </Col>
              <Col
                span={24}
                style={{
                  textAlign: 'left',
                  color: 'var(--mediumGray)'
                }}
              >
                {`${dayjs(start_time).utcOffset(0).format('HH:mm')} - ${dayjs(
                  end_time
                )
                  .utcOffset(0)
                  .format('HH:mm')}`}
              </Col>
              <Col
                span={24}
                style={{
                  textAlign: 'left',
                  color: 'var(--mediumGray)'
                }}
              >
                {`${dayjs(end_time).diff(start_time, 'hour')} heure${
                  dayjs(end_time).diff(start_time, 'hour') > 1 ? 's' : ''
                } ${dayjs(end_time).diff(start_time, 'minute') % 60} minute${
                  dayjs(end_time).diff(start_time, 'minute') % 60 > 1 ? 's' : ''
                }`}
              </Col>
            </Row>
          }
          placement="top"
          color="white"
        >
          <div
            key={width}
            className="planning-bar"
            style={{
              position: 'absolute',
              width: `${width}%`,
              left: Number(spanMarginLeft),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0',
              background: principalColor,
              borderColor: hoverBorderColor,
              borderRadius: 40,
              zIndex: 2,
              color: '#fff'
            }}
          >
            {width >= 10 && (
              <span>
                {`${dayjs(start_time).utcOffset(0).format('HH:mm')} - ${dayjs(
                  end_time
                )
                  .utcOffset(0)
                  .format('HH:mm')}`}
              </span>
            )}
          </div>
        </Tooltip>
      );
    }
    return null;
  });
};
