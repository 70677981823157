import { Row, Col, Flex } from 'antd';
import { BankHolydayTable } from './BankHoliday/BankHoliday';
import { ClosureTimeTable } from './ClosureTime/ClosureTime';
import { OpenHoursTable } from './OpenHours/OpenHours';

export const ListTimetable = () => (
  <Flex vertical gap="middle">
    <OpenHoursTable />
    <Row gutter={[16, 16]}>
      <Col xs={24} xxl={12}>
        <BankHolydayTable />
      </Col>
      <Col xs={24} xxl={12}>
        <ClosureTimeTable />
      </Col>
    </Row>
  </Flex>
);
