import { Button, List, Skeleton } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useConversationContext } from '../../../../contexts/ConversationContext';
import { ParentModal } from './ParentModal';
import { InboxItem } from './InboxItem';

/**
 * `Inbox` is a React component that displays a list of conversation threads for the user.
 * It utilizes the Ant Design library for UI elements and is integrated with custom context hooks
 * `useConversationContext` and `useAuthContext` to access conversation and authentication data.
 *
 * This component handles user interactions such as selecting a conversation and triggering modal pop-ups.
 * It dynamically renders conversation details including the most recent message and the date it was sent.
 *
 * @returns {JSX.Element} JSX structure representing the user's inbox with a list of conversations
 * and a button to open a modal for further actions.
 */
export const Inbox = () => {
  const {
    conversations,
    refreshConversation,
    setRefreshConversation,
    getConversations,
    loading
  } = useConversationContext();
  const [modal, setModal] = useState();

  const handleModalBehaviour = () => {
    const modalKey = Date.now();
    setModal(
      <ParentModal
        key={modalKey}
        forceRefresh={refreshConversation}
        setForceRefresh={setRefreshConversation}
      />
    );
  };

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          maxHeight: '100%',
          width: '100%'
        }}
      >
        <InfiniteScroll
          dataLength={conversations.length}
          next={getConversations}
          hasMore={conversations.length < 10}
          loader={<Skeleton loading={loading} avatar paragraph={{ rows: 1 }} />}
          scrollableTarget="scrollableDiv"
        >
          <List
            itemLayout="horizontal"
            dataSource={conversations}
            renderItem={(item) => <InboxItem item={item} />}
          />
        </InfiniteScroll>
      </div>
      <Button
        type="text"
        onClick={handleModalBehaviour}
        className="new-conversation-btn"
      >
        <MessageOutlined />
      </Button>
      {modal}
    </>
  );
};
