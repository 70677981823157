import { Badge, Button, Col, Dropdown, Flex, Row, Typography } from 'antd';
import {
  CalendarFilled,
  PlusCircleFilled,
  WarningOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { PlanningRangeBar } from '../../../planning/PlanningRangeBar/PlanningRangeBar';
import { useMenuItems } from './utils/menuItems';
import { generateDate } from './utils/generateDate';
import { generateOpenModalParameters } from '../../../planning/children/generateOpenModalParameters';

const { Text } = Typography;

/**
 * `DayRow` is a React component that renders a row representing a day in a planning view.
 * It displays the date and events (like reservations, absences, adaptations, pickup times) for a given child.
 * The component provides dropdown options to create new events and a button to edit existing events.
 *
 * It uses `useAuthContext` for user role-based conditional rendering and `useTranslation` for internationalization.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.event - The event data for the specific day, including date, child details, pickup times, and schedule.
 * @param {Function} props.openModal - Function to open a modal for creating or editing events.
 * @param {Function} props.deleteEvent - Function to delete an event.
 * @param {Object} props.child - The child data for whom the planning is being viewed.
 * @param {Boolean} props.displayButtons - Boolean to display TimeRangeBar buttons.
 *
 * @returns {JSX.Element} A row element for a day in the planning view with event details and action buttons.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.event {Object} - Event data for the day, including child details, pickup times, and schedule.
 * @propTypes.child {Object} - Child data for whom the planning is being viewed.
 * @propTypes.openModal {Function} - Function to open a modal for creating or editing events.
 * @propTypes.deleteEvent {Function} - Function to delete an event.
 */
export const DayRow = ({
  event,
  openModal,
  deleteEvent,
  child,
  isParentView,
  displayButtons
}) => {
  const { user } = useAuthContext();

  const items = useMenuItems(event, openModal, child);
  const menu = {
    items
  };

  const { type, timesID, eventID, previsionalPlanning } =
    generateOpenModalParameters(event);

  const displayButtonsConditionUpdated =
    !isParentView && displayButtons && event?.editAuthorization;

  return (
    <Row align="middle" key={event._id}>
      <Col span={!isParentView ? 4 : 6}>
        <Text style={{ textAlign: 'right' }}>{event.date}</Text>
      </Col>
      <Col span={18}>
        <PlanningRangeBar
          events={event}
          openModal={openModal}
          deleteEvent={deleteEvent}
          childRowDate={generateDate(event)}
          displayButtons={displayButtonsConditionUpdated}
          source="child"
        />
      </Col>
      {displayButtonsConditionUpdated ? (
        <Col span={2}>
          <Flex align="center" gap="small" justify="flex-end">
            {user?.role !== 'guests:PARENT' && (
              <Dropdown menu={menu} disabled={items.length === 0}>
                <Button type="link" className="no-padding">
                  <PlusCircleFilled
                    style={{
                      fontSize: 24,
                      color: 'var(--primaryColor)'
                    }}
                  />
                </Button>
              </Dropdown>
            )}
            <Badge
              count={
                event?.childSchedule?.incompletePickUpTimes?.length > 0 ? (
                  <WarningOutlined style={{ color: '#f5222d' }} />
                ) : null
              }
            >
              <Button
                type="link"
                disabled={
                  !(
                    type === 'pickup_times' &&
                    (event?.childSchedule?.overtimes?.length > 0 ||
                      event?.childSchedule?.pickUpAndPlanningMatch?.length >
                        0 ||
                      event?.childSchedule?.incompletePickUpTimes?.length > 0)
                  )
                }
                onClick={() =>
                  openModal(
                    type,
                    event?.child,
                    'edit',
                    timesID,
                    eventID,
                    previsionalPlanning
                  )
                }
                className="no-padding"
              >
                <CalendarFilled
                  style={{
                    fontSize: 24,
                    color: 'var(--primaryColor)'
                  }}
                />
              </Button>
            </Badge>
          </Flex>
        </Col>
      ) : (
        <Col span={2} />
      )}
    </Row>
  );
};

DayRow.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.string,
    weekStart: PropTypes.string,
    child: PropTypes.shape({}),
    editAuthorization: PropTypes.bool,
    pickup_times: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    ),
    childSchedule: PropTypes.shape({
      overtimes: PropTypes.arrayOf(PropTypes.shape({})),
      pickUpAndPlanningMatch: PropTypes.arrayOf(PropTypes.shape({})),
      incompletePickUpTimes: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    _id: PropTypes.string
  }),
  child: PropTypes.shape({
    _id: PropTypes.string
  }),
  openModal: PropTypes.func,
  deleteEvent: PropTypes.func,
  isParentView: PropTypes.bool,
  displayButtons: PropTypes.bool
};

DayRow.defaultProps = {
  event: null,
  child: null,
  openModal: null,
  deleteEvent: null,
  isParentView: undefined,
  displayButtons: undefined
};
