import {
  Button,
  Col,
  Divider,
  Flex,
  Image,
  Popconfirm,
  Row,
  Tag,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { dateRender } from './utils';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { NewsThemeCard } from './NewsThemeCard';

const { Title } = Typography;

/**
 * A React component for rendering a detailed view of a news item. It displays the news content,
 * images, videos, and other related information. The component supports deleting individual files
 * associated with the news item and shows metadata such as view counts and visibility.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.resource - The news item data including content, images, videos, views, and visibility.
 * @param {Function} props.deleteFileById - A function to handle the deletion of a file associated with the news item.
 *
 * @returns {React.Component} A component that renders the detailed view of a news item.
 */
export const NewsShowRender = ({ resource, deleteFileById }) => {
  const { t } = useTranslation();
  const { dispatchAPI, token, user, daycare } = useAuthContext();
  const [sameTopicNews, setSameTopicNews] = useState();
  const { id } = useParams();
  const { message } = useErrorMessage();

  const getSameTopicNews = async () => {
    try {
      const encodedURL = encodeURI(
        `news/same-topics/${resource?.topic}/${daycare}`
      );
      const { data } = await dispatchAPI('GET', {
        url: encodedURL
      });
      setSameTopicNews(data);
    } catch (e) {
      message(e);
    }
  };

  const userBoolean = ['guests:PARENT', 'users:TABLET'].includes(user?.role);

  useEffect(() => {
    if (resource && userBoolean) {
      (async () => {
        await getSameTopicNews();
      })();
    }
  }, [resource, id]);

  return (
    <Flex vertical gap="large" style={{ maxWidth: 1000 }}>
      <ReactQuill
        value={resource?.content}
        readOnly
        modules={{ toolbar: false }}
        theme="bubble"
      />
      <Row gutter={[16, 16]}>
        {(resource?.images || []).map((photo) => (
          <Col
            key={photo._id}
            xs={24}
            md={12}
            xl={8}
            className="news-gallery-img"
          >
            <Image
              src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${photo._id}/${token}`}
              style={{ maxWidth: '100%' }}
              alt={photo?.metadata?.originalName}
            />
            {!userBoolean && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteFileById(photo._id)}
                icon={<WarningOutlined />}
              >
                <Button shape="circle">
                  <CloseOutlined />
                </Button>
              </Popconfirm>
            )}
          </Col>
        ))}
        {(resource?.videos || []).map((video) => (
          <Col key={video._id} xs={24}>
            <video
              src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${video._id}/${token}`}
              style={{ maxWidth: '100%' }}
              controls
            >
              <track default kind="captions" srcLang="fr" />
            </video>
            {!userBoolean && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteFileById(video._id)}
                icon={<WarningOutlined />}
              >
                <Button>
                  <CloseOutlined />
                </Button>
              </Popconfirm>
            )}
          </Col>
        ))}
      </Row>
      <Flex vertical>
        <Divider />
        {!userBoolean && (
          <Flex gap="large" justify="flex-end" align="center">
            <span style={{ color: 'var(--mediumGray)' }}>
              {`${dateRender(resource, t)} - ${resource?.views?.length} ${t(
                'communication.news.card.views'
              )}`}
            </span>
            <Flex gap="small">
              {(resource?.visibility || []).map((visItem) => (
                <Tag color={visibilitiesColors[visItem]} key={visItem}>
                  {t(`communication.news.list.type.${visItem}`)}
                </Tag>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
      {userBoolean && !!sameTopicNews?.length && (
        <>
          <Title level={3} className="cursive">
            {t('communication.news.same_topic')}
          </Title>
          <Row gutter={[16, 16]}>
            {(sameTopicNews || []).map((news) => (
              <Col xs={24} lg={12}>
                <NewsThemeCard news={news} key={news?._id} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </Flex>
  );
};

NewsShowRender.propTypes = {
  resource: PropTypes.shape({
    content: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    videos: PropTypes.arrayOf(PropTypes.shape({})),
    views: PropTypes.number,
    topic: PropTypes.string,
    visibility: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  deleteFileById: PropTypes.func.isRequired
};

NewsShowRender.defaultProps = {
  resource: null
};
