import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils/constants/adminRoutes';
import { ListResource } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useColumns } from './columns';
import { ExtraButtons } from '../children/ExtraButtons';
import { headers } from './headers';
import { useFormatter } from '../../utils/formatter';

export const ListParents = () => {
  const navigate = useNavigate();
  const columns = useColumns();
  const { parentsDataFormatter } = useFormatter();
  const { daycare } = useAuthContext();
  const [filterStatusOptions, setFilterStatusOptions] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    navigate(`${routes.PARENTS}?p=1&pS=10`);
  }, []);

  const extraQuery = `daycare=${daycare}&contract=${filterStatusOptions}`;

  return (
    <ListResource
      resourceName="parents"
      columns={columns}
      extraQuery={extraQuery}
      resourceModelName="Parent"
      forceRefresh={refresh}
      setRefresh={setRefresh}
      formatter={parentsDataFormatter}
      withCreateButton={false}
      withImportButton={false}
      headers={headers}
      extraButtons={
        <ExtraButtons
          filterStatusOptions={filterStatusOptions}
          setFilterStatusOptions={setFilterStatusOptions}
        />
      }
    />
  );
};
