import { Route, Routes } from 'react-router-dom';

import { Exception } from '../../components';
import { ChildrenList } from './ChildrenList';
import { ShowChild } from './Show/ShowChild';
import { ShowContract } from './Show/Contracts/ShowContract';
import { CreateUpdateContract } from '../Contracts/CreateUpdateContract';
import { ReferenceWeekProvider } from '../../contexts/ReferenceWeekContext';

/**
 * React component for defining the router configuration for children-related routes.
 *
 * @component
 *
 * This component defines the routing configuration for displaying and editing children-related information,
 * such as child details, health information, diet information, and contracts.
 *
 * @returns {JSX.Element} The rendered ChildrenRouter component.
 */
export const ChildrenRouter = () => (
  <ReferenceWeekProvider>
    <Routes>
      <Route index element={<ChildrenList />} />
      <Route path="/show/:id" element={<ShowChild />} />
      <Route
        path="/contracts/create/:childID"
        element={<CreateUpdateContract origin="CHILD" purpose="create" />}
      />
      <Route
        path="/contracts/edit/:childID/:contractID"
        element={<CreateUpdateContract origin="CHILD" purpose="edit" />}
      />
      <Route
        path="/show/:childID/contracts/show/:contractID"
        element={<ShowContract />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ReferenceWeekProvider>
);
