import { createGlobalStyle } from 'styled-components';

const LightTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: var(--logoBlue);
    --addColor: #3F9249;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #d2eefa;
    --secondaryColor: var(--logoPink);
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --textColor: #221E1F;
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --textColorActive: var(--secondaryColor);
    --itemActiveBackground: #d2eefa;
    --itemHoverBackground: #f0f0f0;
    --borderColor: #d9d9d9;
    --borderColorLight: var(--itemHoverBackground);
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --logoBlue: #6CCCF4;
    --logoBlueDarker: #28B2EC;
    --logoYellow: #FCE200;
    --logoOrange: #F1813D;
    --logoPink: #EE3F68;
    --logoPurple: #C661A5;
    --preregistrationBlue: #213472;
    --mediumGray: #727274;
    --tableRow: rgba(198, 97, 165, 0.1);
  }
`;

export default LightTheme;
