import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { HoursBar } from '../../components/HoursBar/HoursBar';
import { TimeSlots } from './children/TimeSlots';

const SlotsModal = ({
  isModalOpen,
  closeModal,
  slots,
  walked,
  daycareSelected
}) => {
  const timeSlots = [{ is_walking: walked, slotEvents: slots }];
  const { t } = useTranslation();
  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={t(`plannings.children.categories.${timeSlots[0]?.is_walking}`)}
      width={1000}
      footer={false}
    >
      <Row style={{ marginTop: 10 }}>
        <Col span={4} />
        <Col span={18}>
          <table>
            <HoursBar />
          </table>
        </Col>
      </Row>
      {timeSlots?.map((slot) => (
        <TimeSlots
          key={slot?.is_walking}
          group={slot?.is_walking}
          slotEvents={slot?.slotEvents}
          daycareSelected={daycareSelected}
        />
      ))}
    </Modal>
  );
};

SlotsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  slots: PropTypes.shape({}).isRequired,
  walked: PropTypes.bool.isRequired,
  daycareSelected: PropTypes.shape({}).isRequired
};

export default SlotsModal;
