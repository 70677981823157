import { Input, Select } from 'antd';

const { Option } = Select;

export const useCompanyFields = () => {
  const companyFields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional'],
      rules: [{ required: false }]
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'state'],
      rules: [{ required: false }]
    },
    {
      name: ['phone_number', 'country_code'],
      input: (
        <Select style={{ width: '25%' }}>
          <Option value="+33">+33</Option>
        </Select>
      )
    },
    {
      name: ['phone_number', 'number'],
      input: <Input type="number" style={{ width: '75%' }} />
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    }
  ];
  return { companyFields };
};
