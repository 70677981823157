import React, { useState } from 'react';
import { Card, Button, DatePicker, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { CreateUpdateBankHoliday } from './CreateUpdateBankHolyday';

/**
 * Display a table of bank holidays.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const BankHolydayTable = () => {
  const { daycare, user } = useAuthContext();
  const { t } = useTranslation();
  const [modal, setModal] = useState();
  const [refresh, setRefresh] = useState(false);
  const [dateFilter, setDateFilter] = useState('');

  const deletePath = `/daycares/${daycare}/holidays`;

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateBankHoliday
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  const columns = useColumns(handleModalBehaviour, refresh, setRefresh);

  return (
    <Card
      size="small"
      title={t('daycares.form.holidays')}
      extra={
        <Flex align="center" gap="small">
          <DatePicker
            picker="year"
            onChange={(e) => {
              setDateFilter(dayjs(e).format('YYYY'));
              setRefresh(!refresh);
            }}
          />
          {user?.role !== 'users:MANAGER' && (
            <Button onClick={() => handleModalBehaviour({}, 'create')}>
              <PlusOutlined />
              {t('buttons.create')}
            </Button>
          )}
        </Flex>
      }
    >
      {modal}
      <ListResource
        resourceName="daycares/holidays"
        tradKey="daycares"
        columns={columns}
        resourceModelName="Daycare"
        extraQuery={`daycare=${daycare}${
          dateFilter ? `&date=${dateFilter}` : ''
        }`}
        customActionColumn
        withCreateButton={false}
        withSearchBar={false}
        withPageHeader={false}
        showAction={false}
        editAction={false}
        onDoubleClickAction={null}
        archivedButton={false}
        archiveAction={false}
        deleteAction={false}
        customDeletePath={deletePath}
        forceRefresh={refresh}
        scroll={{ x: 500 }}
      />
    </Card>
  );
};
