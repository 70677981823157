import { BaseModel } from './BaseModel';

/**
 * Represents a CafStatement object.
 */
export class BalanceImport extends BaseModel {
  constructor(data) {
    super('balance-import', undefined, data);
  }

  /**
   * Executes a POST request to create a new resource using the dispatchAPI function.
   *
   * @function
   *
   * @param {Object} body - The data to be sent in the request body.
   * @returns {Promise<Object>} A Promise representing the result of the POST request.
   */
  static post(body) {
    return this.dispatchAPI('POST', {
      url: `/balance-imports`,
      body
    });
  }

  /**
   * Executes a GET request to fetch all resources using the dispatchAPI function.
   *
   * @function
   *
   * @param {Function} dispatchAPI - The function used for making API requests.
   * @param {Object} options - Additional options for the request.
   * @param {string} options.filters - Optional filters to apply to the request.
   * @returns {Promise<Object>} A Promise representing the result of the GET request.
   */
  static fetchAll({ filters = '' }) {
    return this.dispatchAPI('GET', {
      url: 'balance-imports',
      params: { filters }
    });
  }
}
