import { useTranslation } from 'react-i18next';

/**
 * @hook useColumns
 * @description The columns of the invoices table
 * @returns {Array} columns
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const tradKey = 'customer-account.show.amounts.list';

  return [
    {
      title: t(`${tradKey}.total_paid`),
      key: 'total_paid',
      dataIndex: 'total_paid',
      render: (total_paid) => (total_paid ? `${total_paid} €` : '0.00 €')
    },
    {
      title: t(`${tradKey}.to_be_paid`),
      key: 'to_be_paid',
      dataIndex: 'to_be_paid',
      render: (to_be_paid) => (to_be_paid ? `${to_be_paid} €` : '0.00 €')
    },
    {
      title: t(`${tradKey}.waiting_payment`),
      key: 'waiting_payment',
      dataIndex: 'waiting_payment',
      render: (waiting_payment) =>
        waiting_payment ? `${waiting_payment} €` : '0.00 €'
    },
    {
      title: t(`${tradKey}.unpaid`),
      key: 'unpaid',
      dataIndex: 'unpaid',
      render: (unpaid) => (unpaid ? `${unpaid} €` : '0.00 €')
    },
    {
      title: t(`${tradKey}.balance`),
      key: 'balance',
      dataIndex: 'balance',
      render: (balance) => (balance ? `${balance} €` : '0.00 €')
    }
  ];
};
