import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { days } from '../../utils/days';
import { getTotalHoursAndMinutesForWeek } from './utils/totalHoursAndMinutesForWeek';
import { isTimeSelected } from './utils/timeSelected';
import { fullDayStyle, circleHalfStyle } from './utils/circleStyles';

/**
 * @description
 * PlanningCircles is a component that displays little circles to represent the
 * time slots selected by the user for each day of the week.
 * If the time slot is full day, the circle is full.
 * If the time slot is half day, the circle is half.
 * If the time slot is not selected, the circle is empty.
 *
 * @component
 * @param {Object} referenceWeeks - The reference weeks selected by the user
 * @returns {React.Component} PlanningCircles component
 */
export const PlanningCircles = ({ referenceWeeks }) => {
  const { t } = useTranslation();

  return (
    <>
      {referenceWeeks.map((week, index) => {
        const { totalHours, totalMinutes } =
          getTotalHoursAndMinutesForWeek(week);
        return (
          <Col span={week.span} key={`reference-week-${week}`}>
            <div style={{ color: 'var(--mediumGray)', fontSize: 13 }}>
              {t(`pre-registrations.form.summary.reference_week`)} {index + 1}
              {` : ${totalHours}h${totalMinutes}`}
            </div>
            <div className="circle-container">
              {days.map((day, dayIndex) => {
                const { isFirstHalf, isSecondHalf, fullDay } = isTimeSelected(
                  referenceWeeks,
                  index,
                  dayIndex
                );
                return (
                  <div key={`${day}-${week}`} className="circle">
                    {fullDay && <div style={fullDayStyle(true)} />}
                    <div style={circleHalfStyle(isFirstHalf, false)} />
                    <div style={circleHalfStyle(isSecondHalf, true)} />
                  </div>
                );
              })}
            </div>
          </Col>
        );
      })}
    </>
  );
};

PlanningCircles.propTypes = {
  referenceWeeks: PropTypes.arrayOf(PropTypes.shape({}))
};

PlanningCircles.defaultProps = {
  referenceWeeks: []
};
