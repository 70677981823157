import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DayRow } from './DayRow';

/**
 * Renders a week of events with the option to open a modal and delete events.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.events - An array of events for the week.
 * @param {string} props.planningMode - The planning mode ('month' or 'week').
 * @param {Function} props.openModal - A function to open a modal for event details.
 * @param {boolean} props.isParentView - State determining the role logged
 * @param {Function} props.deleteEvent - A function to delete an event.
 * @param {Boolean} props.displayButtons - Boolean to display RangeBar displayButtons.
 * @returns {JSX.Element} A component that renders a week of events.
 */
export const Week = ({
  events,
  planningMode,
  openModal,
  deleteEvent,
  isParentView,
  displayButtons
}) => {
  const { t } = useTranslation();

  const weekNumber = dayjs(events[0]?.weekStart)?.add(2, 'day').week();
  return (
    <>
      {planningMode === 'month' && (
        <Divider>{`${t(
          'children.show.planning.options.weekly_view'
        )} ${weekNumber}`}</Divider>
      )}
      {events.map((event) => (
        <DayRow
          key={event._id}
          event={event}
          openModal={openModal}
          deleteEvent={deleteEvent}
          isParentView={isParentView}
          displayButtons={displayButtons}
        />
      ))}
    </>
  );
};

Week.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      weekStart: PropTypes.string
    })
  ),
  planningMode: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  isParentView: PropTypes.bool,
  displayButtons: PropTypes.bool
};

Week.defaultProps = {
  events: null,
  isParentView: undefined,
  displayButtons: undefined
};
