import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Card, List, DatePicker } from 'antd';
import { useHomeContext } from '../../../contexts/HomeContext';
import { ChildrenCardLignContent } from '../utils/ChildrenCardLignContent';

/**
 * @component ChildrenCard
 * @description Component that renders the pickup times errors card. Used in the home page.
 * @returns {JSX} Component JSX
 */
export const ChildrenCard = () => {
  const { t } = useTranslation();
  const {
    childrenCardLoading,
    selectedDayChildrenCard,
    setSelectedDayChildrenCard,
    dateFormat,
    pickupTimesErrors
  } = useHomeContext();

  return (
    <Card
      title={
        <Flex justify="space-between" align="baseline">
          <span>{t('home.titles.children')}</span>
          <DatePicker
            allowClear={false}
            format={dateFormat}
            defaultValue={selectedDayChildrenCard}
            onChange={(value) => setSelectedDayChildrenCard(value)}
          />
        </Flex>
      }
      style={{ width: '100%', height: '100%' }}
    >
      <List
        bordered={false}
        loading={childrenCardLoading}
        dataSource={pickupTimesErrors}
        renderItem={({ child, error, referenceDay, color }) => (
          <List.Item>
            <List.Item.Meta
              title={
                <ChildrenCardLignContent
                  child={child}
                  error={error}
                  referenceDay={referenceDay}
                  color={color}
                />
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
