import { Checkbox, Input } from 'antd';

/**
 * useHouseholdFields
 * A custom hook that builds the household fields for the pre-registration form
 *
 * @hook
 * @returns {object} householdFields
 *
 * @param {string} isMonoparentalFamily - Monoparental family state
 * @param {function} setIsMonoparentalFamily - Function to set state
 * @example
 * const { householdFields } = useHouseholdFields();
 */
export const useHouseholdFields = (
  isMonoparentalFamily,
  setIsMonoparentalFamily
) => {
  const householdFields = [
    {
      name: ['household', 'beneficiary_number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['household', 'annual_incomes'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['household', 'dependent_children'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['household', 'dependent_children_with_disabilities'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['household', 'monoparental_family'],
      valuePropName: 'checked',
      input: (
        <Checkbox
          onChange={() => setIsMonoparentalFamily(!isMonoparentalFamily)}
        />
      )
    }
  ];
  return { householdFields };
};
