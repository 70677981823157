/**
 * Utility function that provides configurations for managing document display and actions.
 *
 * This function takes in files configuration, a list of files, and a downloadDocument function,
 * and returns an object with helper functions for handling document actions and display.
 *
 * @param {Array} filesConfiguration - The configuration for document files.
 * @param {Array} filesList - The list of document files.
 * @param {Function} downloadDocument - A function to download a document file.
 * @returns {Object} An object containing helper functions for managing document display and actions.
 */
export const documentFormConfigs = (
  filesConfiguration,
  filesList,
  downloadDocument
) => {
  const handleDownload = (document) => {
    const fileConfig = filesConfiguration.find(
      (itemDoc) => itemDoc?.fileKey === document?.document_title
    );
    const fileToDownload = filesList.find(
      (file) => file?.metadata?.originalName === fileConfig.name
    );

    return downloadDocument(fileToDownload);
  };

  const displayDownloadButton = (document) => {
    const fileConfig = filesConfiguration.find(
      (itemDoc) => itemDoc?.fileKey === document?.document_title
    );
    if (fileConfig) {
      const file = filesList.find(
        (fileItem) => fileItem?.metadata?.originalName === fileConfig.name
      );
      if (file?._id) {
        return true;
      }
    }
    return false;
  };

  const displayFileName = (document) => {
    const fileConfig = filesConfiguration.find(
      (itemDoc) => itemDoc?.fileKey === document?.document_title
    );
    if (fileConfig) {
      const fileToDownload = filesList.find(
        (file) =>
          file?.metadata?.originalName === fileConfig.name ||
          file?.name === fileConfig.name
      );

      return fileToDownload?.metadata?.originalName || fileToDownload?.name;
    }
    return '';
  };

  const displayDeleteButton = (document) => {
    const fileConfig = filesConfiguration.find(
      (itemDoc) => itemDoc?.fileKey === document?.document_title
    );
    if (fileConfig) {
      return true;
    }
    return false;
  };

  return {
    handleDownload,
    displayDownloadButton,
    displayFileName,
    displayDeleteButton
  };
};
