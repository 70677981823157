import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ContainerOutlined,
  ThunderboltOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { CreateUpdateUser } from './CreateUpdateUser';
import { TemplateDocumentButton } from '../../components/TemplateDocumentButton/TemplateDocumentButton';

/**
 * Component for displaying details of a user.
 *
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the user details.
 */
export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, token } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [daycares, setDaycares] = useState();
  const [modal, setModal] = useState();
  const listContent = useListContent(user);
  const [refresh, setRefresh] = useState(false);

  const handleModalBehaviour = () => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateUser
        key={modalKey}
        purpose="edit"
        daycares={daycares}
        record={user}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=photo`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id, refresh]);

  const archiveUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unarchiveUser = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/users/unarchive/${id}` });
      await getUser();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDaycares = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/daycares' });
      setDaycares(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  useEffect(() => {
    (async () => {
      await getDaycares();
    })();
  }, []);

  return (
    <>
      {!isLoading && (
        <PageHeaderCustom
          title={t('users.show.title')}
          extra={
            user?.archived ? (
              <Popconfirm
                title={t('datatable.column.action.unarchive.title')}
                okText={t('datatable.column.action.unarchive.ok')}
                okButtonProps={{ type: 'primary' }}
                cancelText={t('datatable.column.action.unarchive.cancel')}
                onConfirm={unarchiveUser}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.unarchive')} `}
                  <ThunderboltOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <>
                <Button
                  disabled={!user}
                  type="primary"
                  onClick={handleModalBehaviour}
                >
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
                {/* Component for test purpose */}
                <TemplateDocumentButton
                  parameters={[
                    {
                      collection: 'User',
                      documentId: id
                    },
                    {
                      collection: 'Child',
                      documentId: '65ba13930df9ce5553b344ac'
                    }
                  ]}
                  use_case="PRESENCE_CERTIFICATE"
                />
                <Popconfirm
                  title={t('datatable.column.action.archive.title')}
                  okText={t('datatable.column.action.archive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.archive.cancel')}
                  onConfirm={archiveUser}
                  icon={<WarningOutlined />}
                >
                  <Button danger>
                    {`${t('buttons.archive')} `}
                    <ContainerOutlined />
                  </Button>
                </Popconfirm>
              </>
            )
          }
        />
      )}

      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={8} lg={6} xl={4} xxl={2}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 80 }}
              active
            >
              {user?.photo ? (
                <Avatar
                  size={80}
                  src={
                    user?.photo
                      ? `${process.env.REACT_APP_API_URL}/users/get-img-url/${user?.photo._id}/${token}`
                      : ''
                  }
                />
              ) : (
                <Avatar size={80} />
              )}
            </Skeleton>
          </Col>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent} translate />
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
      {modal}
    </>
  );
};
