import dayjs from 'dayjs';

/**
 * Calculates the time difference between the current time and a given time.
 *
 * @param {string} time - The target time in the format 'HH:mm'.
 * @returns {string} A string representing the time difference.
 *                  If the difference in hours is greater than 0, it returns the difference in hours and minutes (e.g., '+1h 30min').
 *                  Otherwise, it returns only the minutes part (e.g., '-15min').
 *                  A '+' sign is prefixed for positive time differences.
 */
export const calculateTimeDiff = (time) => {
  const [hours, minutes] = time.split(':').map(Number);

  const timeToCompare = dayjs().hour(hours).minute(minutes).second(0);

  const now = dayjs();

  const diff = now.diff(timeToCompare);

  let diffHours = Math.floor(diff / (60 * 60 * 1000));
  const diffMinutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));

  const formatDiff = (value, unit) => `${value > 0 ? '+' : ''}${value}${unit}`;

  if (Math.abs(diffMinutes) > 0) {
    if (!now.isAfter(timeToCompare)) {
      diffHours += 1;
    }
  }

  if (Math.abs(diffHours) > 1) {
    return {
      color: diffHours > 0 ? 'var(--errorColor)' : 'var(--mediumGray)',
      text: `${formatDiff(diffHours, 'h')}${Math.abs(diffMinutes)}min`,
      fontWeight: 'bold'
    };
  }

  return {
    color: diffMinutes > 0 ? 'var(--errorColor)' : 'var(--mediumGray)',
    text: formatDiff(diffMinutes, 'min'),
    fontWeight: 'bold'
  };
};

/**
 * Determines the color based on whether the real time is before, after, or equal to the expected time.
 * It returns a green color ('var(--addColor)') if the real time is before or equal to the expected time,
 * and a red color ('var(--errorColor') if the real time is after the expected time.
 *
 * @param {string} realTime - The real time, in 'HH:mm' format.
 * @param {string} expectedTime - The expected time, in 'HH:mm' format.
 * @returns {string} Hex color code representing the result of the comparison. Green ('var(--addColor)') if realTime is before or at the expected time, red ('var(--errorColor') if after.
 */
export const determineRealTimeColor = (realTime, expectedTime) => {
  const realTimeDayjs = dayjs(realTime, 'HH:mm');
  const expectedTimeDayjs = dayjs(expectedTime, 'HH:mm');

  if (!expectedTime) {
    return 'var(--textColor)';
  }
  if (realTimeDayjs.isBefore(expectedTimeDayjs)) {
    return 'var(--addColor)';
  }
  if (realTimeDayjs.isAfter(expectedTimeDayjs)) {
    return 'var(--errorColor';
  }
  return 'var(--addColor)';
};
