import { useConversationContext } from '../../../contexts/ConversationContext';
import { Thread } from '../Thread';

/**
 * @component
 * @description Conversation thread component
 * @returns {jsx} Conversation thread
 */
export const ConversationThread = () => {
  const { selectedConversation } = useConversationContext();
  const { messages } = selectedConversation;

  return <Thread data={messages} loading={false} />;
};
