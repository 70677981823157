// Main nav menuItems
export const routes = {
  PLANNING: '/planning',
  CHILDREN_FOLLOW_UP: '/follow-up',
  COMMUNICATION: '/communication',
  MESSAGES_AND_NOTES: '/message-and-notes',
  INVOICES: '/invoices',
  DOCUMENTS: '/documents'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TIME_RECORDER: '/time-recorder',
  PASSWORD_CREATION: '/post-pwd',
  CHECKOUT_PAYMENT: '/checkout-payment'
};

// url search parameters
export const pathSearches = {};
