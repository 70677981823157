import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Modal, Space, Table } from 'antd';
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../../components';
import { useListContent } from './listContent';
import { useColumns } from './showColumns';
import { expandedRowRender } from './expandedRowRender';
import { RowAddModal } from './RowAddModal';

/**
 * A component to display and manage the details of a specific cash balance import.
 *
 * This component fetches and displays the details of a cash balance import identified by its ID from the URL parameters. It allows users to view and edit the financial data of the import, including adding new rows and saving modifications. The data is displayed in a table with expandable rows for detailed entries, and the page header includes actions for saving changes or adding new rows. The state is managed locally for balance data, refresh triggers, and modal visibility.
 *
 * @component
 * @returns {JSX.Element} The ShowBalance component renders a page header with action buttons, a description list for balance metadata, and a table with main and detailed financial data. It also includes a modal for adding new rows to the balance data.
 */
export const ShowBalance = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [balance, setBalance] = useState();
  const content = useListContent(balance);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onRowExpand = (expanded, record) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  const getBalance = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `balance-imports/${id}?populate=file,author`
      });
      const formatData = {
        ...data,
        data: data?.data.map((item) => ({ ...item, key: item._id }))
      };
      setBalance(formatData);
    } catch (e) {
      message(e);
    }
  };

  const patchBalance = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `balance-imports/${id}`,
        body: balance
      });
      setRefresh(!refresh);
      Modal.success({
        title: t('analysis.modal.successful')
      });
    } catch (e) {
      message(e);
    }
  };

  const deleteRow = (record) => {
    const accountingClassIndex = balance.data.findIndex(
      (item) => item.compte === record.compte.charAt(0)
    );

    if (accountingClassIndex !== -1) {
      const newBalance = { ...balance };
      const accountingClassData = [
        ...newBalance.data[accountingClassIndex].data
      ];

      const indexToRemove = accountingClassData.findIndex(
        (item) => item._id === record._id
      );

      if (indexToRemove !== -1) {
        accountingClassData.splice(indexToRemove, 1);
        newBalance.data[accountingClassIndex].data = accountingClassData;

        setBalance(newBalance);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getBalance();
    })();
  }, [refresh]);

  const mainColumns = useColumns(dayjs(balance?.month).format('YYYY'), 'main');
  const subColumns = useColumns(
    dayjs(balance?.month).format('YYYY'),
    'expanded',
    deleteRow
  );

  return (
    <>
      <PageHeaderCustom
        title={t('analysis.imported_balance', {
          date: dayjs(balance?.import_date).format('DD/MM/YYYY')
        })}
        extra={
          <Space>
            <Button
              icon={<SaveOutlined />}
              onClick={patchBalance}
              type="primary"
            >
              {t('analysis.buttons.save_modifications')}
            </Button>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => setOpen(true)}
              type="primary"
            >
              {t('analysis.buttons.add_row')}
            </Button>
          </Space>
        }
      />
      <ContentCustom>
        <DescriptionList data={content} translate layout="vertical" />
        <Table
          columns={mainColumns}
          dataSource={balance?.data || []}
          scroll={{ x: 1000 }}
          pagination={false}
          expandable={{
            expandedRowRender: (record) =>
              expandedRowRender(record, subColumns, setBalance, balance),
            rowExpandable: (record) => record.data.length,
            expandedRowKeys,
            onExpand: onRowExpand
          }}
        />
      </ContentCustom>
      <RowAddModal
        open={open}
        setOpen={setOpen}
        balance={balance}
        setBalance={setBalance}
        patchBalance={patchBalance}
      />
    </>
  );
};
