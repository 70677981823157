import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating content for a diet list.
 *
 * @param {Object} data - Diet-related data to be used for generating content.
 * @param {string} data.meal_type - The type of meal.
 * @param {boolean} data.vegetarian - A boolean indicating whether the diet is vegetarian.
 * @param {boolean} data.special_meat_diet - A boolean indicating whether the diet is a special meat diet.
 * @returns {Object} An object containing eating habits content.
 */
export const useDietListContent = (data = {}) => {
  const { t } = useTranslation();
  const { vegetarian, special_meat_diet, meal_type } = data;

  const eatingHabits = [
    {
      label: t('children.show.diet.meal_type.title'),
      content: t(`diets.enums.meal_types.${meal_type}`),
      span: 1
    },
    {
      label: t('children.show.diet.vegetarian'),
      content: vegetarian ? t(`yes`) : t('no'),
      span: 1
    },
    {
      label: t('children.show.diet.special_meat_diet'),
      content: special_meat_diet ? t(`yes`) : t('no'),
      span: 1
    }
  ];

  return {
    eatingHabits
  };
};
