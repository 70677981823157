import { Input, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Group } = Radio;

/**
 * Fields for the form
 * @hook
 * @param {Object} enums - The enums from the backend.
 * @returns {Array} The fields to use in the form.
 */
export const useGeneralSettingsFields = (enums) => {
  const { t } = useTranslation();

  return [
    {
      name: 'sickness_waiting_period',
      label: t('sickness_waiting_period'),
      input: <Input type="number" addonAfter={t('days')} />
    },
    {
      name: 'number_of_hours_per_day',
      label: t('number_of_hours_per_day'),
      input: <Input type="number" addonAfter="h" />
    },
    {
      name: 'CAF_mandate',
      label: t('CAF_mandate'),
      input: (
        <Group>
          {enums?.CAFMandateOptions?.map((option) => (
            <Radio value={option}>
              {t(`configurations.invoicing.form.CAF_mandate_options.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      name: 'PAJE_template',
      label: t('PAJE_template'),
      input: (
        <Select>
          {enums?.PAJE_templates?.map((template) => (
            <Option value={template}>{template}</Option>
          ))}
        </Select>
      )
    }
  ];
};
