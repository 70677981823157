import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useIngredientColumns } from './columns';
import { CreateUpdateIngredient } from './CreateUpdateIngredient';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * A component for displaying and managing a list of ingredients.
 * It provides functionalities to create new ingredients, update existing ones, and delete them.
 *
 * Utilizes a modal for creating/updating ingredients, and a custom hook for configuring the list columns.
 * It also includes a refresh mechanism to update the list after changes.
 *
 * @returns {JSX.Element} The ingredients list component.
 */
export const IngredientsList = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();

  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState();

  const onModalOpen = (record, purpose, resourceName) => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <CreateUpdateIngredient
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        resourceName={resourceName}
      />
    );
  };

  const deleteIngredient = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `ingredients/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const ingredientsColumns = useIngredientColumns(
    onModalOpen,
    deleteIngredient
  );

  return (
    <>
      <Card
        title={t('ingredients.title')}
        extra={
          <Button
            onClick={() => onModalOpen(undefined, 'create', 'ingredients')}
          >
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        }
      >
        <ListResource
          resourceName="ingredients"
          tradKey="ingredients"
          resourceModelName="Ingredient"
          extraQuery={`daycare=${daycare}`}
          withPageHeader={false}
          withCreateButton={false}
          showAction={false}
          columns={ingredientsColumns}
          forceRefresh={refresh}
          withUploadButton={false}
          customActionColumn
          onDoubleClickAction={false}
        />
      </Card>
      {modal}
    </>
  );
};
