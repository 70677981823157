import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Flex, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { useDaycareFields } from '../Fields/daycareFields';
import { usePreregistrationContext } from '../../../contexts/PreregistrationContext';

const { Title } = Typography;

/**
 * Component for rendering the daycare form.
 * This component is rendered inside the pre-registration form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @returns {React.Component} Daycare form component
 */
export const DaycareForm = ({ onFinish, initialValues }) => {
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { setSwitchToForm, switchToForm } = usePreregistrationContext();
  const { t } = useTranslation();
  const { daycareFields } = useDaycareFields();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      onFinish={onFinish}
      {...formItemLayout}
      form={form}
      className="prereg-form daycare-choice"
    >
      <Flex justify="center">
        <Title className="prereg-form-title">
          {t('pre-registrations.form.select_daycare')}
        </Title>
      </Flex>
      <Flex justify="center">
        {daycareFields.map((field) =>
          generateFields('pre-registrations', field)
        )}
      </Flex>
      <Flex justify="end">
        <Button type="link" onClick={() => setSwitchToForm(!switchToForm)}>
          {t('buttons.cancel')}
        </Button>
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </Form>
  );
};

DaycareForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({})
};
DaycareForm.defaultProps = {
  initialValues: {}
};
