import { BaseModel } from './BaseModel';

/**
 * Represents a CafStatement object.
 */
export class CafEstimation extends BaseModel {
  constructor(data) {
    super('caf-estimations', undefined, data);
  }

  /**
   * Retrieve all CafEstimations.
   *
   * @function
   *
   * @param {string} daycare - Actual daycare selected.
   * @param {string} dateFilter - Date filter from analysis context.
   * @returns {Promise<object>} A Promise representing the result of the request.
   */
  static getCafEstimation(daycare, dateFilter) {
    return this.dispatchAPI('GET', {
      url: `caf-estimations?daycare=${daycare}&date=${dateFilter}`
    });
  }
}
