import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const usePricesAndCalculMethodsFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      name: 'minimum_monthly_resources',
      label: t('minimum_monthly_resources'),
      input: <Input type="number" addonAfter="€" disabled />
    },
    {
      name: 'maximum_monthly_resources',
      label: t('maximum_monthly_resources'),
      input: <Input type="number" addonAfter="€" disabled />
    }
  ];

  return fields;
};
