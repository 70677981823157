import { createContext, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { message as antDMessage } from 'antd';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';
import { useErrorMessage } from '../utils/errorMessage';
import { routes as userRoutes } from '../utils/constants/userRoutes';
import { routes as adminRoutes } from '../utils/constants/adminRoutes';
import { routes as devRoutes } from '../utils/constants/devRoutes';
import { BaseModel } from '../models/BaseModel';

export const AuthContext = createContext({ isValid: false });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [isDoubleFactorAuth, setIsDoubleFactorAuth] = useState(false);
  const [daycare, setDaycare] = useStateWithLocalStorage('daycare', undefined);
  const [token, setToken] = useStateWithLocalStorage('token');
  const [refreshCompletedDaycare, setRefreshCompletedDaycare] = useState(false);
  const [refreshToken, setRefreshToken] =
    useStateWithLocalStorage('refresh_token');
  const [remember, setRememberMe] = useStateWithLocalStorage(
    'remember_me',
    !!refreshToken
  );
  const [isValid, setIsValid] = useState(!!refreshToken || !!token);
  const [pendingTasksTray, setPendingTasksTray] = useStateWithLocalStorage(
    'pendingTasksTray',
    []
  );
  const [configurationComplete, setConfigurationComplete] = useState(false);

  const setSession = (accessToken, logout) => {
    const findRouteKeyByPath = (path, routes) => {
      const routeKeys = Object.keys(routes);
      const foundKey = routeKeys.find((key) => routes[key] === `/${path}`);
      return foundKey || null;
    };

    const prevUrl = window.location.href.split('/').pop();
    const routeKey = findRouteKeyByPath(prevUrl, {
      ...userRoutes,
      ...adminRoutes,
      ...devRoutes
    });

    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
      if (!logout && routeKey) {
        antDMessage.warning(t('login.expiredSession'));
      }
    }
  };

  const loginAPI = async (email, password, rememberMe) => {
    const { data } = await axiosInstance.get('/login', {
      auth: {
        username: email,
        password
      }
    });

    if (!data.double_factor_authentication) {
      setSession(data.token, false);
      setUser(data.user);
      if (data.user.daycare) setDaycare(data.user.daycare);
      if (data.user.last_selected_daycare)
        setDaycare(data.user.last_selected_daycare);
      setRememberMe(rememberMe);
      setIsDoubleFactorAuth(false);
      antDMessage.success(t('login.success'));
      if (rememberMe) setRefreshToken(data.refresh_token);
    } else {
      setIsDoubleFactorAuth(true);
      setUser(data.user);
    }
    return data;
  };

  const login2FA = async (body) => {
    const result = await axiosInstance.post('/login', body);
    setSession(result.data.token, false);
    setUser(result.data.user);
    setIsDoubleFactorAuth(false);
    antDMessage.success(t('login.success'));
    if (body.rememberMe) setRefreshToken(result.data.refresh_token);
  };

  const createPwd = async (values) => {
    await axiosInstance.post('/login/create-pwd', values);
    setIsDoubleFactorAuth(false);
    antDMessage.success(t('login.passwordCreated'));
  };

  const registerAPI = (values) => axiosInstance.post('/register', values);

  const isTokenExpired = () => {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (Number(decodedToken.exp) < Number(currentTime)) {
      setToken(null);
      return true;
    }
    return false;
  };

  const logout = () => {
    setSession(null, true);
    setUser(null);
  };

  const isTokenValid = async () => {
    if ((!token || isTokenExpired()) && !refreshToken) {
      setSession(null, false);
      return false;
    }
    try {
      if (remember && refreshToken && !token) {
        const result = await axiosInstance.post('/token/refresh', {
          refresh_token: refreshToken
        });
        setToken(result.data.newToken);
        return { newToken: result.data.newToken };
      }
    } catch (e) {
      antDMessage.warning(t('login.expiredSession'));
      setSession(null, false);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      await isTokenValid();
    })();
  }, []);

  const fetchAPI = async (
    url,
    method = 'GET',
    body = undefined,
    responseType = 'json',
    cancelToken = undefined
  ) => {
    const { newToken } = await isTokenValid();
    if (
      ['POST', 'PATCH', 'DELETE'].includes(method) &&
      !window.navigator.onLine
    ) {
      setPendingTasksTray([
        ...(pendingTasksTray || []),
        ...[{ url, method, body }]
      ]);
      return new Response();
    }
    const result = await axiosInstance({
      url,
      method,
      responseType,
      cancelToken,
      data: body,
      headers: {
        Authorization: `Bearer ${newToken || token}`
      }
    });
    return result;
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'LOGIN2FA':
        return login2FA(options);
      case 'CREATE_PWD':
        return createPwd(options);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
        return fetchAPI(options.url, type, options.body, options.responseType);
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };
  BaseModel.dispatchAPI = dispatchAPI;

  const getDaycareConfigurationComplete = async () => {
    try {
      setConfigurationComplete(false);
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}?fields=configuration_complete`
      });

      setConfigurationComplete(data?.configuration_complete || false);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (daycare && token)
      (async () => {
        await getDaycareConfigurationComplete();
      })();
  }, [daycare, token, refreshCompletedDaycare]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        daycare,
        setDaycare,
        token,
        isValid,
        isDoubleFactorAuth,
        setIsDoubleFactorAuth,
        dispatchAPI,
        remember,
        pendingTasksTray,
        setPendingTasksTray,
        refreshCompletedDaycare,
        setRefreshCompletedDaycare,
        configurationComplete,
        setConfigurationComplete
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
