import { Card, Col, Collapse, Row, Skeleton, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useListContent } from './useListContent';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useColumns } from '../../Summary/columns';

/**
 * A card component for displaying quotation details, including invoice schedule and other information.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The quotation data, including invoice schedule.
 * @param {Array} props.data.invoice_schedule - An array of objects representing invoice schedule items.
 * @param {boolean} props.isLoading - Boolean flag indicating whether data is still loading.
 * @returns {JSX.Element} JSX representation of the QuotationCard component.
 */

export const QuotationCard = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const columns = useColumns();
  const listContent = useListContent(data);

  const items = (data?.invoice_schedule || []).map((invoice) => ({
    key: invoice.date,
    label: (
      <Row justify="space-between">
        <Col>{invoice.date}</Col>
        <Col>{`${invoice.all_included_total.toFixed(2)} €`}</Col>
      </Row>
    ),
    children: (
      <Table
        columns={columns}
        dataSource={[invoice]}
        pagination={false}
        scroll={{ x: 1000 }}
      />
    )
  }));

  return (
    <Card title={t('pre-registrations.show.cards.quotation')}>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <DescriptionList data={listContent} translate layout="vertical" />
        <Collapse items={items} />
      </Skeleton>
    </Card>
  );
};

QuotationCard.propTypes = {
  data: PropTypes.shape({
    invoice_schedule: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isLoading: PropTypes.bool
};

QuotationCard.defaultProps = {
  data: null,
  isLoading: false
};
