import { useCallback, useEffect, useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Tag,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import IsWalking from '../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../assets/images/icons/healthicons_baby-0306m.svg';

const { Option } = Select;

/**
 * The useFields hook provides form fields and related functions for child data input.
 *
 * @returns {Object} An object containing form fields, loading status, and base64-encoded image.
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [groups, setGroups] = useState([]);

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['entry_date'],
      input: <DatePicker />
    },
    {
      name: ['gender'],
      input: (
        <Select>
          {(enums.genders || []).map((gender) => (
            <Option key={gender} value={gender}>
              {t(`children.genders.${gender}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['group'],
      input: (
        <Select>
          {(groups || []).map((group) => (
            <Option key={group._id} value={group._id}>
              <Tag color={group?.tag_color}>{group?.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['is_walking'],
      input: (
        <Flex gap="middle" align="center">
          <Flex gap="small" align="center">
            <img src={DontWalk} alt="dont-walk" />
            {t('children.list.is_walking.no')}
          </Flex>
          <Form.Item
            name={['is_walking']}
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <Switch />
          </Form.Item>
          <Flex gap="small" align="center">
            <img src={IsWalking} alt="dont-walk" />
            {t('children.list.is_walking.yes')}
          </Flex>
        </Flex>
      )
    },
    {
      name: ['hygiene'],
      input: (
        <Select>
          {(enums.hygiene || []).map((item) => (
            <Option key={item} value={item}>
              {t(`children.hygieneEnums.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['weight'],
      input: (
        <Row>
          <Col span={16}>
            <Form.Item noStyle name={['value']}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item noStyle name={['unit']}>
              <Select variant="borderless">
                {(enums.weight_units || []).map((unit) => (
                  <Option key={unit} value={unit}>
                    {unit}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )
    }
  ];

  const getRoles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/children/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/children-groups' });
      setGroups(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getRoles();
    await getGroups();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
