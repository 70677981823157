import { Route, Routes } from 'react-router-dom';
import { ConversationContextProvider } from '../../contexts/ConversationContext';
import { MessagesAndNotes } from './MessagesAndNotes';
import { Exception } from '../../components';

export const MessagesAndNotesRouter = () => (
  <ConversationContextProvider>
    <Routes>
      <Route index element={<MessagesAndNotes />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConversationContextProvider>
);
