import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { generatePDF } from '../../utils/generatePDF';

/**
 * Button component to generate and download a PDF document based on a template.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.parameters - The collections name used for generating the document.
 * @param {string} props.use_case - The use case for the document generation.
 * @param {string} props.button_text - The text displayed on the button.
 * @param {function} [props.onClick] - Optional click handler for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const TemplateDocumentButton = ({
  parameters,
  use_case,
  button_text,
  onClick
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  return (
    <Button
      type="primary"
      onClick={() => {
        generatePDF(message, dispatchAPI, parameters, use_case, setIsLoading);
        if (onClick) onClick();
      }}
      icon={isLoading ? <Spin /> : <FilePdfOutlined />}
      loading={isLoading}
    >
      {`${t(`${button_text}`)} `}
    </Button>
  );
};

TemplateDocumentButton.propTypes = {
  parameters: PropTypes.shape({
    collection: PropTypes.string,
    documentId: PropTypes.string
  }).isRequired,
  use_case: PropTypes.string.isRequired,
  button_text: PropTypes.string,
  onClick: PropTypes.func
};

TemplateDocumentButton.defaultProps = {
  onClick: null,
  button_text: 'template-documents.form.generate_PDF'
};
