import {
  FaCog,
  FaHeading,
  FaRegFileAlt,
  FaFileSignature
} from 'react-icons/fa';
import FormInfo from '../FormInfo';
import TemplateEditor from './TemplateEditor';

/**
 * Generates the steps configuration for a multi-step form.
 *
 * @hook
 *
 * @param {string} status - The current status of the form.
 * @param {Array} configurations - Array of available configurations.
 * @param {string} pickedConfiguration - The currently selected configuration.
 * @param {Function} setPickedConfiguration - Function to set the selected configuration.
 * @param {Object} template - The template object.
 * @param {string} content - The content of the template.
 * @param {Function} setContent - Function to set the content of the template.
 * @param {string} purpose - The purpose of the template.
 * @param {boolean} isSubmitting - Flag indicating if the form is being submitted.
 * @param {Object} formValues - The values of the form fields.
 * @param {Array} tags - The tags to be used in the template.
 * @param {Function} t - Translation function.
 * @returns {Array} Array of step configurations for the multi-step form.
 */
export const useSteps = (
  status,
  configurations,
  pickedConfiguration,
  setPickedConfiguration,
  template,
  content,
  setContent,
  purpose,
  isSubmitting,
  formValues,
  tags,
  t
) =>
  [
    {
      key: 'config',
      label: (
        <>
          <FaCog /> {t('template-documents.form.configuration')}
        </>
      ),
      children: (
        <FormInfo
          status={status}
          configurations={configurations}
          setPickedConfiguration={setPickedConfiguration}
          formValues={formValues}
        />
      )
    },
    formValues.header && {
      label: (
        <>
          <FaHeading /> {t('template-documents.form.header')}
        </>
      ),
      key: 'header',
      children: (
        <TemplateEditor
          template={template}
          content={content}
          setContent={setContent}
          objective="header"
          pickedConfiguration={pickedConfiguration}
          purpose={purpose}
          isSubmitting={isSubmitting}
          tags={tags}
        />
      )
    },
    {
      label: (
        <>
          <FaRegFileAlt /> {t('template-documents.form.body')}{' '}
        </>
      ),
      key: 'body',
      children: (
        <TemplateEditor
          template={template}
          content={content}
          setContent={setContent}
          objective="body"
          pickedConfiguration={pickedConfiguration}
          purpose={purpose}
          isSubmitting={isSubmitting}
          tags={tags}
        />
      )
    },

    formValues.footer && {
      label: (
        <>
          <FaFileSignature /> {t('template-documents.form.footer')}{' '}
        </>
      ),
      key: 'footer',
      children: (
        <TemplateEditor
          template={template}
          content={content}
          setContent={setContent}
          objective="footer"
          pickedConfiguration={pickedConfiguration}
          purpose={purpose}
          isSubmitting={isSubmitting}
          tags={tags}
        />
      )
    }
  ].filter(Boolean);
