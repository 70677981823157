import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';

/**
 * Styled component for the success contract component.
 */
const StyledSuccess = styled.div`
  width: 100%;
  text-align: center;
  flex: 1;
  padding: 8vh 24px;
`;

/**
 * Styled component for the success contract title.
 */
const SuccessTitle = styled.h1`
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
`;

/**
 * Renders the success contract component.
 *
 * @component
 * @returns {JSX.Element} The success contract component.
 */
export const SuccessContract = () => {
  const { t } = useTranslation();
  const translationKey = 'contract.success';

  return (
    <StyledSuccess>
      <SuccessTitle>{t(`${translationKey}.title`)}</SuccessTitle>
      <div>
        <p>{t(`${translationKey}.message`)}</p>
      </div>
      <div>
        <p>{t(`${translationKey}.will_come_back_soon`)}</p>
      </div>
      <div>
        <Button style={{ margin: 16 }} onClick={() => window.close()}>
          {t('buttons.close_page')}
        </Button>
      </div>
    </StyledSuccess>
  );
};
