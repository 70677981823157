import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Flex, Typography, Button, Dropdown } from 'antd';
import { PlusCircleFilled, CalendarFilled } from '@ant-design/icons';
import { PlanningRangeBar } from '../PlanningRangeBar/PlanningRangeBar';
import { useAuthContext } from '../../../contexts/AuthContext';
import { createAddEventMenu } from '../../employee/planning/utils';
import { generateOpenModalParameters } from './generateOpenModalParameters';

const { Text } = Typography;

/**
 * EmployeeRow component displays the planning range bar for an employee event.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {Object} props.event - The event object representing the employee event.
 * @param {Function} props.openModal - Function to open a modal for adding or editing events.
 * @param {Function} props.deleteEvent - Function to delete an event.
 * @param {boolean} props.displayButtons - Boolean indicating whether to display action buttons.
 * @returns {JSX.Element} EmployeeRow component JSX.
 */
export const EmployeeRow = ({
  event,
  openModal,
  deleteEvent,
  displayButtons
}) => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const userBoolean = user.role === 'users:TABLET';
  const menu = createAddEventMenu(openModal, t, event);

  const { type, id } = generateOpenModalParameters(event);

  return (
    <Row align="middle">
      <Col span={4} style={{ paddingRight: 8 }}>
        <Flex align="center" gap="small" justify="flex-end">
          <Text>{`${event?.employee?.first_name} ${event?.employee?.last_name}`}</Text>
        </Flex>
      </Col>
      <Col span={18}>
        <PlanningRangeBar
          events={event}
          openModal={openModal}
          deleteEvent={deleteEvent}
          displayButtons={displayButtons}
          reference_day={event?.reference_day}
          source="employee"
        />
      </Col>
      <Col span={2}>
        {displayButtons && !userBoolean && (
          <Flex align="center" gap="small" justify="flex-end">
            <Dropdown menu={menu}>
              <Button type="link" className="no-padding">
                <PlusCircleFilled
                  style={{
                    fontSize: 24,
                    color: 'var(--primaryColor)'
                  }}
                />
              </Button>
            </Dropdown>
            <Button
              type="link"
              className="no-padding"
              disabled={
                !(
                  event?.childSchedule?.overtimes?.length > 0 ||
                  event?.childSchedule?.pickUpAndPlanningMatch?.length > 0
                )
              }
              onClick={() =>
                openModal(
                  type,
                  event.employee,
                  'edit',
                  id,
                  event._id,
                  event.reference_day
                )
              }
            >
              <CalendarFilled
                style={{
                  fontSize: 24,
                  color: 'var(--primaryColor)'
                }}
              />
            </Button>
          </Flex>
        )}
      </Col>
    </Row>
  );
};

EmployeeRow.propTypes = {
  openModal: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  event: PropTypes.shape({
    date: PropTypes.string,
    childSchedule: PropTypes.shape({
      overtimes: PropTypes.arrayOf(PropTypes.shape({})),
      pickUpAndPlanningMatch: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    employee: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    reference_day: PropTypes.arrayOf(PropTypes.shape({})),
    pickup_times: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
    _id: PropTypes.string
  }).isRequired,
  displayButtons: PropTypes.bool.isRequired
};
