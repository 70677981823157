import { Flex, Collapse, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { CreateUpdateDynamicList } from './CreateUpdateDynamicList';
import { useDocumentsColumns } from './documentsColumns';
import { useVaccinesColumns } from './vaccinesColumns';

const { Panel } = Collapse;

/**
 * Display a table of dynamic lists.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const DynamicListTable = () => {
  const { daycare } = useAuthContext();

  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(null);
  const { t } = useTranslation();

  const onModalOpen = (record, purpose, resourceName) => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <CreateUpdateDynamicList
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        resourceName={resourceName}
      />
    );
  };

  const deletePath = `/daycares/${daycare}/dynamic_list`;

  const documentColumns = useDocumentsColumns(refresh, setRefresh, onModalOpen);

  const vaccinesColumns = useVaccinesColumns(refresh, setRefresh, onModalOpen);

  return (
    <>
      <Collapse defaultActiveKey={['1', '2']}>
        <Panel header={t('configurations.titles.requested_documents')} key="1">
          <Flex justify="end">
            <Button
              onClick={() => onModalOpen({}, 'create', 'documents')}
              type="primary"
            >
              <PlusOutlined />
              {t('buttons.create')}
            </Button>
          </Flex>
          <ListResource
            resourceName={`daycares/${daycare}/dynamic_list/documents`}
            tradKey="daycares"
            resourceModelName="Daycare"
            withPageHeader={false}
            withCreateButton={false}
            showAction={false}
            columns={documentColumns}
            customDeletePath={deletePath}
            forceRefresh={refresh}
            withSearchBar={false}
            withUploadButton={false}
            archivedButton={false}
            customActionColumn
            onDoubleClickAction={false}
          />
        </Panel>
        <Panel header={t('configurations.titles.vaccines')} key="2">
          <Flex justify="end">
            <Button
              onClick={() => onModalOpen({}, 'create', 'vaccines')}
              type="primary"
            >
              <PlusOutlined />
              {t('buttons.create')}
            </Button>
          </Flex>
          <ListResource
            resourceName={`daycares/${daycare}/dynamic_list/vaccines`}
            tradKey="daycares"
            resourceModelName="Daycare"
            withPageHeader={false}
            withCreateButton={false}
            archivedButton={false}
            showAction={false}
            columns={vaccinesColumns}
            customDeletePath={deletePath}
            forceRefresh={refresh}
            withSearchBar={false}
            withUploadButton={false}
            customActionColumn
            onDoubleClickAction={false}
          />
        </Panel>
      </Collapse>
      {modal}
    </>
  );
};
