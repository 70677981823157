import { useTranslation } from 'react-i18next';
import { Button, Divider, Popconfirm, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import {
  ContainerOutlined,
  EditOutlined,
  DownloadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import MenuIcon from '../../../../assets/images/icons/noto_sandwich.svg';
import HealthIcon from '../../../../assets/images/icons/noto_adhesive-bandage.svg';

const iconSize = 14;

/**
 * Custom hook for generating table columns for document management.
 *
 * @hook
 *
 * This hook configures the columns for a table that displays a list of documents.
 * It includes features for sorting, filtering, and rendering custom components like
 * icons, tags, and interactive buttons. Additionally, it handles archiving of
 * documents and downloading files.
 *
 * @param {boolean} refresh - State indicating if the table needs refreshing.
 * @param {Function} setRefresh - Function to toggle the refresh state.
 * @param {Function} onModalOpen - Function to open the modal for editing documents.
 * @param {boolean} isAdmin - Boolean to determine role of connected user.
 * @returns {Array} An array of column configurations for the documents table.
 */
export const useColumns = (refresh, setRefresh, onModalOpen, isAdmin) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();
  const { message } = useErrorMessage();

  const archiveResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/documents/${id}`,
        body: {
          archived: true,
          archiving_date: dayjs()
        }
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  return [
    {
      title: t('communication.documents.list.denomination'),
      key: 'denomination',
      dataIndex: 'denomination',
      sorter: true,
      sortDirections
    },
    {
      title: t('communication.documents.list.document_type'),
      key: 'document_type',
      dataIndex: 'document_type',
      sortDirections,
      sorter: true,
      render: (type) => (
        <Space>
          <img
            src={type === 'MENU' ? MenuIcon : HealthIcon}
            alt={type === 'MENU' ? 'menu-icon' : 'health-icon'}
          />
          {t(`communication.documents.list.type.${type}`)}
        </Space>
      )
    },
    ...(isAdmin
      ? [
          {
            title: t('communication.documents.list.visibility'),
            key: 'visibility',
            dataIndex: 'visibility',
            sortDirections,
            render: (visibility) =>
              visibility.map((type) => (
                <Tag
                  color={visibilitiesColors[type]}
                  style={{ marginRight: 4 }}
                >
                  {t(`communication.documents.list.type.${type}`)}
                </Tag>
              )),
            sorter: true
          },
          {
            title: t('communication.documents.list.start_visibility'),
            key: 'start_visibility',
            dataIndex: 'start_visibility',
            sortDirections,
            sorter: true,
            render: (start_visibility) =>
              dayjs(start_visibility).format('DD/MM/YYYY')
          },
          {
            title: t('communication.documents.list.end_visibility'),
            key: 'end_visibility',
            dataIndex: 'end_visibility',
            sortDirections,
            sorter: true,
            render: (end_visibility) =>
              (end_visibility && dayjs(end_visibility).format('DD/MM/YYYY')) ||
              ''
          }
        ]
      : []),
    {
      align: 'right',
      fixed: 'right',
      width: '10%',
      render: (record) => (
        <>
          <Button
            type="link"
            style={{ padding: 2 }}
            onClick={() => downloadDocument(record?.file)}
          >
            <DownloadOutlined />
          </Button>
          {isAdmin && (
            <>
              <Divider type="vertical" />
              <Button
                type="link"
                style={{ padding: 2 }}
                onClick={() => onModalOpen(record, 'edit', 'documents')}
              >
                <EditOutlined />
              </Button>
              <Divider type="vertical" />
              {record?.status !== 'ARCHIVED' && (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => archiveResource(record?._id)}
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined
                    style={{ color: 'var(--errorColor', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              )}
            </>
          )}
        </>
      )
    }
  ];
};
