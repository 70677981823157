import { useEffect, useState } from 'react';
import { Form, Modal, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { useFields } from './fields';
import { useFollowUpContext } from '../../contexts/FollowUpContext';
import { ModalTail } from '../../components/ModalTail/ModalTail';
import { useHandleResize } from '../../utils/handleResize';

/**
 * A modal component for creating or updating an activity.
 *
 * This component provides a modal dialog with a form for creating or editing an activity.
 * It includes fields dynamically generated based on the activity configuration and the selected origin.
 * The modal supports both group and individual activities and adapts its fields accordingly.
 * It uses the `useFollowUpContext` for context-specific data and operations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The activity record to be edited, if applicable.
 * @param {'edit' | 'create'} props.purpose - The purpose of the modal, either 'edit' or 'create'.
 * @returns {JSX.Element} The JSX rendering of the create/update activity modal.
 *
 * @example
 * const record = {
 *   _id: '1',
 *   date: '2021-01-01',
 *   first_name: 'John',
 *   last_name: 'Doe',
 *   groups: [{...}],
 *   activity: { type: 'someType' }
 * };
 * return (
 *   <CreateUpdateActivityModal
 *     record={record}
 *     parentOrigin="group"
 *     purpose="edit"
 *   />
 * )
 */
export const CreateUpdateActivityModal = ({ record, purpose }) => {
  const { t } = useTranslation();
  const {
    groups,
    activityConfiguration,
    daysChildren,
    refresh,
    setRefresh,
    subActivities,
    setSubActivities,
    child,
    origin,
    setOrigin,
    loadingActivities,
    loadingChildren
  } = useFollowUpContext();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { dispatchAPI, daycare } = useAuthContext();
  const { width } = useHandleResize();
  const [selectedTime, setSelectedTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = useErrorMessage();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const { fields } = useFields(
    groups,
    activityConfiguration,
    origin,
    daysChildren,
    purpose,
    subActivities,
    setSubActivities,
    setSelectedTime,
    selectedTime,
    loadingActivities,
    loadingChildren,
    child
  );

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  useEffect(() => {
    if (record) {
      const filteredActivities = activityConfiguration[record.activity.type];
      setSubActivities(filteredActivities);

      setSelectedTime(dayjs(record.date).format('HH:mm'));

      form.setFieldsValue({
        ...record,
        date: dayjs(record.date),
        groups: record.groups.map((group) => group._id)
      });
    }
  }, [record]);

  const handleSubmit = async (body) => {
    try {
      setIsSubmitting(true);
      const url =
        purpose === 'create' ? `follow-ups` : `follow-ups/${record._id}`;

      const children =
        origin === 'group'
          ? daysChildren.filter((c) => body.groups.includes(c.group._id))
          : body.children;

      let formattedSelectedTime;

      if (selectedTime && width <= 1280) {
        const [hour, minute] = selectedTime.split(':');
        formattedSelectedTime = dayjs().set('hour', hour).set('minute', minute);
      }

      await dispatchAPI(purpose === 'create' ? 'POST' : 'PATCH', {
        url,
        body: {
          ...body,
          date: formattedSelectedTime || body.date,
          daycare,
          children
        }
      });
      closeModal();
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const modalTitle = `${t(
    `children-follow-up.form.${purpose === 'create' ? 'add' : 'edit'}`
  )}${
    origin === 'child' && Object.keys(child).length !== 0
      ? ` - ${child?.first_name} ${child?.last_name}`
      : ''
  }`;

  let render = null;

  if (purpose === 'create') {
    if (origin !== 'child') {
      render = (
        <Row justify="center" style={{ margin: '20px 0px 20px 0px' }}>
          <Radio.Group
            defaultValue="group"
            onChange={(e) => setOrigin(e.target.value)}
          >
            <Radio value="group">
              {t('children-follow-up.form.group-activity')}
            </Radio>
            <Radio value="child">
              {t('children-follow-up.form.individual')}
            </Radio>
          </Radio.Group>
        </Row>
      );
    } else {
      render = (
        <Row justify="center" style={{ margin: '20px 0px 20px 0px' }}>
          <Radio.Group
            defaultValue="child"
            onChange={(e) => setOrigin(e.target.value)}
          >
            <Radio value="group">
              {t('children-follow-up.form.group-activity')}
            </Radio>
            <Radio value="child">
              {t('children-follow-up.form.individual')}
            </Radio>
          </Radio.Group>
        </Row>
      );
    }
  }

  return (
    <Modal
      title={modalTitle}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      {render}
      <Form form={form} onFinish={handleSubmit}>
        {fields.map((field) => generateFields('children-follow-up', field))}
        <ModalTail isSubmitting={isSubmitting} handleModal={closeModal} />
      </Form>
    </Modal>
  );
};

CreateUpdateActivityModal.propTypes = {
  purpose: PropTypes.oneOf(['edit', 'create']),
  record: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    activity: PropTypes.shape({
      type: PropTypes.string
    })
  })
};

CreateUpdateActivityModal.defaultProps = {
  purpose: undefined,
  record: {}
};
