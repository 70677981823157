import { createContext, useContext, useState } from 'react';

export const CommunicationContext = createContext({ isValid: false });

/**
 * Provider component for the CommunicationContext.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} The context provider component.
 */
export const CommunicationContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('1');
  return (
    <CommunicationContext.Provider
      value={{
        activeTab,
        setActiveTab
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};

/**
 * Custom hook to access the CommunicationContext.
 *
 * @hook
 *
 * @returns {{ activeTab: string, setActiveTab: Function }} The context values.
 */
export const useCommunicationContext = () => {
  const context = useContext(CommunicationContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
