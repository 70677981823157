import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Calculates the age based on the given date of birth and formats it into a readable string,
 * showcasing the age in years, months, weeks, or days. This function is intended to provide
 * a human-friendly representation of the age for display purposes.
 *
 * @function
 *
 * @param {string} date - The date of birth in a format recognized by dayjs.
 * @param {Function} t - Translation function from 'react-i18next' for localizing the units of age.
 * @returns {string} A localized string representing the calculated age, or an empty string if the date is not valid.
 */
const getAge = (date, t) => {
  if (date) {
    const birthdayDate = dayjs(date);
    const today = dayjs();

    const ageInYears = today.diff(birthdayDate, 'year');

    birthdayDate.add(ageInYears, 'year');
    const ageInMonths = today.diff(birthdayDate, 'month');

    birthdayDate.add(ageInMonths, 'month');
    const ageInWeeks = today.diff(birthdayDate, 'week');

    birthdayDate.add(ageInWeeks, 'week');
    const ageInDays = today.diff(birthdayDate, 'day');

    switch (true) {
      case ageInDays < 0:
        return '';
      case ageInYears > 0:
        return `${ageInYears} ${t('pre-registrations.form.years')}`;
      case ageInMonths > 0:
        return `${ageInMonths} ${t('pre-registrations.form.months')}`;
      case ageInWeeks > 0:
        return `${ageInWeeks} ${t('pre-registrations.form.weeks')}`;
      default:
        return `${ageInDays} ${t('pre-registrations.form.date_days')}`;
    }
  }
  return '';
};

/**
 * Finds the most recent contract among the provided children.
 *
 * @function
 *
 * @param {Array} children - The array of children objects.
 * @returns {Object|null} The most recent contract object, or null if no contracts are found.
 */
const findMostRecentContract = (children) => {
  let mostRecentContract = null;
  let mostRecentDate = new Date(0);

  children.forEach((child) => {
    child.childData.contracts_docs.forEach((contract) => {
      const contractDate = new Date(contract.created_at);
      if (contractDate > mostRecentDate) {
        mostRecentDate = contractDate;
        mostRecentContract = contract;
      }
    });
  });

  return mostRecentContract;
};

/**
 * Provides formatter functions to transform raw data into a more readable and localized format for display.
 * Includes formatters for parents, children, and employees data, utilizing translations for certain text values.
 *
 * @hook
 * @returns {Object} An object containing formatter functions.
 */
export const useFormatter = () => {
  const { t } = useTranslation();

  const parentsDataFormatter = (data) => {
    const { children, mobile_phone_number, address } = data;
    const { number, postal_code, state, street, city } = address;

    const { household } = findMostRecentContract(children);
    const {
      beneficiary_number,
      annual_incomes,
      dependent_children,
      dependent_children_with_disabilities
    } = household;

    return {
      ...data,
      phone_number: `${mobile_phone_number?.country_code} ${mobile_phone_number?.number}`,
      address: {
        number,
        street,
        postal_code,
        state,
        city
      },
      children: (children || [])
        .map(({ childData }) => {
          const { first_name, last_name } = childData;
          return `${first_name} ${last_name}`;
        })
        .join(', '),
      beneficiary_number,
      dependent_children,
      dependent_children_with_disabilities,
      annual_incomes
    };
  };

  const childrenDataFormatter = (data) => {
    const {
      diet,
      health,
      authorizations,
      group,
      birth_date,
      gender,
      parents,
      is_walking
    } = data;

    const values = {
      ...data,
      birth_date: dayjs(birth_date).format('DD/MM/YYYY'),
      gender: gender ? t(`children.genders.${data.gender}`) : '-',
      group: group.label,
      is_walking: is_walking
        ? t('children.list.is_walking.yes')
        : t('children.list.is_walking.no'),
      age: getAge(birth_date, t),
      parent_1: parents[0]
        ? `${parents[0].first_name} ${parents[0].last_name}`
        : '',
      parent_2: data.parents[1]
        ? `${parents[1].first_name} ${parents[1].last_name}`
        : '',
      allergies: (health.allergies || [])
        .map((allergy) => allergy.label)
        .join(', '),
      childhood_illnesses: (health.childhood_illnesses || [])
        .map((illness) => illness.label)
        .join(', '),
      personalized_care_plan: health.personalized_care_plan
        ? t('yes')
        : t('no'),
      disability: health.disability ? t('yes') : t('no'),
      meal_type: t(`diets.enums.meal_types.${diet.meal_type}`),
      vegetarian: diet.vegetarian ? t('yes') : t('no'),
      special_meal_diet: diet.special_meal_diet ? t('yes') : t('no'),
      food_allergies: (diet.food_allergies || [])
        .map((allergy) => allergy.label)
        .join(', '),
      admin_care: authorizations.admin_care ? t('yes') : t('no'),
      exit_permission: authorizations.exit_permission ? t('yes') : t('no'),
      capture_media: authorizations.capture_media ? t('yes') : t('no'),
      apply_makeup: authorizations.apply_makeup ? t('yes') : t('no'),
      administer_antipyretic: authorizations.administer_antipyretic
        ? t('yes')
        : t('no'),
      specific_permissions: authorizations.specific_permissions || t('no'),
      consent_date: authorizations.consent_date
        ? dayjs(authorizations.consent_date).format('DD/MM/YYYY')
        : ''
    };
    return values;
  };

  const employeesDataFormatter = (data) => {
    const {
      position,
      mobile_phone_number,
      address,
      children_groups,
      contract_start_date,
      contract_end_date,
      code
    } = data;

    return {
      ...data,
      position: position ? position.label : '',
      phone_number: `${mobile_phone_number?.country_code} ${mobile_phone_number?.number}`,
      address: {
        number: address?.number || '',
        street: address?.street || '',
        postal_code: address?.postal_code || '',
        state: address?.state || '',
        city: address?.city || ''
      },
      children_groups: (children_groups || [])
        .map((group) => group.label)
        .join(', '),
      contract_start_date: contract_start_date
        ? dayjs.utc(contract_start_date).format('DD/MM/YYYY')
        : '',
      contract_end_date: contract_end_date
        ? dayjs.utc(contract_end_date).format('DD/MM/YYYY')
        : '',
      code: code.code
    };
  };

  const usersDataFormatter = (data) => {
    const { role, mobile_phone_number, address } = data;
    return {
      ...data,
      role: t(`users.tags.${role.split(':')[1]}`),
      phone_number: `${mobile_phone_number?.country_code} ${mobile_phone_number?.number}`,
      address: {
        number: address?.number || '',
        street: address?.street || '',
        postal_code: address?.postal_code || '',
        state: address?.state || '',
        city: address?.city || ''
      }
    };
  };

  const invoicesDataFormatter = (data) => {
    const { created_at, due_date, customer_account, status } = data;

    const mainBillingAddress = customer_account.billing_address.find(
      (address) => address.is_principal === true
    );

    const consideredBillingAddress =
      mainBillingAddress || customer_account.billing_address[0];

    return {
      ...data,
      status: t(`invoices.tags.${status}`),
      created_at: dayjs(created_at).format('DD/MM/YYYY'),
      due_date: dayjs(due_date).format('DD/MM/YYYY'),
      customer_account_address: `${consideredBillingAddress.number} ${consideredBillingAddress.street} ${consideredBillingAddress.postal_code} ${consideredBillingAddress.city}`
    };
  };

  const customerAccountsFormatter = (data) => {
    const { customer_nature, billing_address, total_to_pay } = data;

    const mainBillingAddress = billing_address.find(
      (address) => address.is_principal === true
    );

    const consideredBillingAddress = mainBillingAddress || billing_address[0];

    const returned = {
      ...data,
      unpaid: total_to_pay > 0 ? t('yes') : t('no'),
      customer_nature: t(`customer-account.form.enums.${customer_nature}`),
      customer_account_address: `${consideredBillingAddress.number} ${consideredBillingAddress.street} ${consideredBillingAddress.postal_code} ${consideredBillingAddress.city}`
    };

    return returned;
  };

  const paymentsDataFormatter = (data) => {
    const { type, status, operation_date, payment_method } = data;
    return {
      ...data,
      type: t(`payments.tags.${type}`),
      operation_date: dayjs(operation_date).format('DD/MM/YYYY'),
      status: t(`payments.tags.${status}`),
      payment_method: t(`payments.enums.payment_methods.${payment_method}`)
    };
  };

  return {
    parentsDataFormatter,
    childrenDataFormatter,
    employeesDataFormatter,
    usersDataFormatter,
    invoicesDataFormatter,
    customerAccountsFormatter,
    paymentsDataFormatter
  };
};

/**
 * Formats a number by inserting spaces as thousand separators to enhance readability.
 *
 * @function
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number with spaces as thousand separators.
 */
export const formatNumberWithSpaces = (num) => {
  if (typeof num === 'undefined' || num === null || !num) {
    return 0;
  }
  if (num !== 0) {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return num;
};

/**
 * Determines the color to use in analysis visualizations based on the numerical value.
 * Returns a specific color for positive and negative numbers.
 *
 * @function
 *
 * @param {number} num - The number based on which the color is determined.
 * @returns {string} The hex code of the color determined based on the given number.
 */
export const determineAnalysisColor = (num) => {
  if (Math.sign(num) === -1) {
    return '#EE3F68';
  }
  return '#221E1F';
};

/**
 * Returns the formatted phone number by concatenating the country code and number.
 *
 * @function
 * @param {Object} phoneNumber - The phone number object. (ex. { country_code: '+33', number: '0612345678' })
 * @param {string} phoneNumber.country_code - The country code. (ex. '+33')
 * @param {string} phoneNumber.number - The phone number. (ex. '0612345678')
 * @returns {string} The formatted phone number. (ex. '+33 6 12 34 56 78') or '-' if no phone number is provided.
 */
export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'object') return '-';
  const { country_code = null, number = null } = phoneNumber;
  if (!country_code || !number) return '-';

  let modifiedNumber;
  let formattedNumber;

  switch (country_code) {
    case '+33':
      // Remove the first digit of the number to return '6 12 34 56 78' format.
      modifiedNumber = number.slice(1);
      formattedNumber = modifiedNumber.replace(
        /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5'
      );
      return `${country_code} ${formattedNumber}`;
    default:
      return '-';
  }
};

/**
 * Formats the given amount by adding spaces as thousand separators.
 *
 * @function
 * @param {number} amount - The amount to be formatted. (e.g. 1234567.89)
 * @returns {string} The formatted amount. (e.g. 1 234 567,89 €) or '0,00 €' if the amount is falsy.
 */
export const formatAmount = (amount) => {
  if (!amount) return '0,00 €';

  return `${Number(amount)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',')} €`;
};
