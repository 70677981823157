import { EventsForm } from '../Forms/EventsForm';
import { InfosForm } from '../Forms/InfosForm';
import { NewsForm } from '../Forms/NewsForm';

/**
 * Hook for generating form content based on the purpose and type of record.
 *
 * @hook
 *
 * @param {Object} record - The record being edited or created.
 * @param {Object} enums - Enumerations or options used in the form.
 * @param {Array} fileList - List of files attached to the form.
 * @param {Function} setFileList - Function to set the list of files attached to the form.
 * @param {Object} form - The form instance.
 * @param {string} purpose - The purpose of the form (e.g., 'create' or 'edit').
 * @returns {Object} An object containing JSX elements for form content.
 */
export const useFormContent = (
  record,
  enums,
  fileList,
  setFileList,
  form,
  purpose
) => ({
  events: (
    <EventsForm
      record={record}
      enums={enums}
      fileList={fileList}
      setFileList={setFileList}
    />
  ),
  infos: <InfosForm record={record} enums={enums} form={form} />,
  news: (
    <NewsForm
      record={record}
      enums={enums}
      form={form}
      fileList={fileList}
      setFileList={setFileList}
      purpose={purpose}
    />
  )
});
