import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/managerRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { PreRegistrationRouter } from '../routes/pre-registrations/PreRegistrationRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { EmployeeRouter } from '../routes/employee/EmployeeRouter';
import { InvoicingRouter } from '../routes/invoicing/InvoicingRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { ChildrenRouter } from '../routes/children/childrenRouter';
import { ParentsRouter } from '../routes/parents/ParentsRouter';
import { Configuration } from '../routes/Configuration/Configuration';
import { MessagesAndNotesRouter } from '../routes/messages-and-notes/MessagesAndNotesRouter';
import { PlanningRouter } from '../routes/planning/PlanningRouter';
import { ReferenceWeekProvider } from '../contexts/ReferenceWeekContext';
import { CreateUpdatePreRegistration } from '../routes/pre-registrations/CreateUpdatePreRegistration';
import { TimeRecorder } from '../routes/planning/TimeRecorder/TimeRecorder';
import { MealsCalendar } from '../routes/meals/MealsCalendar';
import { RequireAuth } from './RequireAuth';
import { CommunicationRouter } from './CommunicationRouter';
import { EmployeeContextProvider } from '../contexts/EmployeeContext';
import { CommunicationContextProvider } from '../contexts/CommunicationContext';
import { ConfigurationContextProvider } from '../routes/Configuration/ConfigurationContext';

/**
 * `ManagerRouter` is a React component that defines the routing structure for the management interface.
 * It sets up various routes using `react-router-dom` for different parts of the application like Home,
 * Settings, User Management, etc. Some routes are protected with `RequireAuth` to ensure authentication.
 *
 * This component also applies a specific class to the body for consistent styling across different routes.
 * The routes are organized to include both out-of-navigation routes (like Login and Confirmation) and
 * main application routes (like Home, Planning, Users, etc.). It also includes a catch-all route for handling
 * undefined paths, rendering an `Exception` component.
 *
 * @component
 * @example
 * return (
 *   <ManagerRouter />
 * )
 * @returns {React.ReactElement} The ManagerRouter component containing all the routes for the manager interface.
 */
export const ManagerRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PRE_REGISTRATION}/create/*`}
          element={
            <ReferenceWeekProvider>
              <CreateUpdatePreRegistration purpose="create" />
            </ReferenceWeekProvider>
          }
        />
        <Route
          path={`${outOfNavRoutes.TIME_RECORDER}/*`}
          element={<TimeRecorder />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.PLANNING}/*`} element={<PlanningRouter />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route
            path={`${routes.PRE_REGISTRATION}/*`}
            element={<PreRegistrationRouter />}
          />
          <Route
            path={`${routes.EMPLOYEES}/*`}
            element={
              <ReferenceWeekProvider>
                <EmployeeContextProvider>
                  <EmployeeRouter />
                </EmployeeContextProvider>
              </ReferenceWeekProvider>
            }
          />
          <Route path={`${routes.INVOICING}/*`} element={<InvoicingRouter />} />
          <Route path={`${routes.CHILDREN}/*`} element={<ChildrenRouter />} />
          <Route path={`${routes.PARENTS}/*`} element={<ParentsRouter />} />
          <Route
            path={`${routes.MESSAGES_AND_NOTES}/*`}
            element={<MessagesAndNotesRouter />}
          />
          <Route path={`${routes.MEALS}/*`} element={<MealsCalendar />} />
          <Route
            path={`${routes.COMMUNICATION}/*`}
            element={
              <CommunicationContextProvider>
                <CommunicationRouter />
              </CommunicationContextProvider>
            }
          />
          <Route
            path={`${routes.CONFIGURATIONS}`}
            element={
              <ConfigurationContextProvider>
                <Configuration />
              </ConfigurationContextProvider>
            }
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
