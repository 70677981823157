import { Button, Divider, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useColumns } from './columns';

/**
 * The TableComponent displays a set of documents in a table format. It offers functionalities
 * to delete a specific document, and also to open a modal based on the dividerTitle provided.
 *
 * @function
 * @component
 * @param {Object} props - Component props.
 * @param {Array} props.documents - Array of document objects.
 * @param {boolean} props.refresh - Boolean to trigger a rerender.
 * @param {Function} props.setRefresh - Function to set the refresh state.
 * @param {string} props.dividerTitle - Title for the divider.
 * @param {Function} props.handleModal - Function to handle the modal opening/closing.
 * @param {Function} props.setPurpose - Function to set the purpose of the modal action.
 * @param {Function} props.setDocToUpdate - Function to set the document that needs to be updated.
 *
 * @returns {ReactElement} Rendered TableComponent
 */
export const TableComponent = ({
  documents,
  refresh,
  setRefresh,
  dividerTitle,
  handleModal,
  setPurpose,
  setDocToUpdate,
  scroll
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();

  const deleteFile = async (docID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `children/${id}/delete-document/${docID}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const columns = useColumns(
    deleteFile,
    handleModal,
    setPurpose,
    setDocToUpdate,
    dividerTitle
  );

  return (
    <>
      <Divider orientation="left">
        {t(`children.documents.divider.${dividerTitle}`)}
      </Divider>
      <Row style={{ flexDirection: 'column', width: '100%' }}>
        <Row justify="end">
          <Button
            onClick={() => handleModal(dividerTitle, null)}
            type="primary"
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Row>
        <Table
          dataSource={documents}
          columns={columns}
          style={{ width: '100%' }}
          scroll={scroll}
        />
      </Row>
    </>
  );
};

TableComponent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      document_title: PropTypes.string,
      visibility: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  dividerTitle: PropTypes.string,
  handleModal: PropTypes.func.isRequired,
  setPurpose: PropTypes.func,
  setDocToUpdate: PropTypes.func,
  scroll: PropTypes.shape({}).isRequired
};

TableComponent.defaultProps = {
  documents: null,
  refresh: false,
  setRefresh: null,
  dividerTitle: undefined,
  setPurpose: null,
  setDocToUpdate: null
};
