import { Button, Tag } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { realColorPlanning } from '../../../utils/constants/tagColors';

/**
 * Generates an array of options for a radio button group representing different time views.
 *
 * @hook
 *
 * @param {Function} t - The translation function used to translate labels.
 * @returns {Object[]} An array of objects representing time view options.
 * Each object has the following properties:
 *   - key {string}: The unique key for the option.
 *   - value {string}: The value associated with the option.
 *   - label {string}: The translated label for the option.
 */
export const timeRadioOptions = (t) => [
  {
    key: 'week',
    value: 'week',
    label: t('employees.show.planning.options.weekly_view')
  },
  {
    key: 'month',
    value: 'month',
    label: t('employees.show.planning.options.monthly_view')
  }
];

/**
 * Converts the event type to its corresponding key in the employee event object.
 *
 * @function
 *
 * @param {string} type The event type to be converted.
 * @returns {string} The key corresponding to the event type in the employee event object.
 * @throws {Error} Throws an error if the event type is unhandled.
 */
export const convertEventType = (type) => {
  switch (type) {
    case 'REGULAR':
      return 'regulars';
    case 'REGULAR_ABSENCE':
      return 'regular_absences';
    case 'IRREGULAR_ABSENCE':
      return 'irregular_absences';
    case 'HOLIDAYS':
      return 'holidays';
    case 'CATCH_UP_DAY':
      return 'catch_up_days';
    case 'PLANNING':
      return 'reference_day';
    default:
      return false;
  }
};

/**
 * Converts event type from its internal representation to its external representation.
 *
 * @function
 *
 * @param {string} type - The internal representation of the event type.
 * @returns {string|false} The external representation of the event type, or false if no match is found.
 */
export const reverseConvertEventType = (type) => {
  switch (type) {
    case 'regulars':
      return 'REGULAR';
    case 'regular_absences':
      return 'REGULAR_ABSENCE';
    case 'irregular_absences':
      return 'IRREGULAR_ABSENCE';
    case 'holidays':
      return 'HOLIDAYS';
    case 'catch_up_days':
      return 'CATCH_UP_DAY';
    case 'reference_day':
      return 'PLANNING';
    default:
      return false;
  }
};

/**
 * Generates an array of planning type filters for use in UI components, each with a label and a value.
 * The labels are rendered as colored tags using Ant Design's `Tag` component.
 *
 * @function
 *
 * @param {Function} t - The translation function from i18next for localizing strings.
 * @param {Array} eventEnums - Array of enums event types coming from employee-event model.
 * @returns {Object[]} An array of objects, each representing a filter option with a label and a value.
 */
export const planningTypeFiltersArray = (t, eventEnums) =>
  (eventEnums || []).map((enumItem) => {
    const lowerCaseEnum = convertEventType(enumItem);
    return {
      label: (
        <Tag color={realColorPlanning[lowerCaseEnum]}>
          {t(`employees.show.planning.options.${lowerCaseEnum}`)}
        </Tag>
      ),
      value: lowerCaseEnum
    };
  });

/**
 * Generates a Date object from an event object containing date and week start information.
 *
 * @function
 *
 * @param {Object} event The event object containing date and week start information.
 * @returns {Date} A Date object representing the generated date.
 */
export const generateDate = (event) => {
  const dayMonth = event.date.split(' ')[1];
  const year = event.weekStart.split('-')[0];

  return dayjs(`${dayMonth}-${year}`, 'DD/MM/YYYY');
};

/**
 * Creates a menu for adding different types of events for an employee.
 *
 * @function
 *
 * @param {Function} openModal A function to open the modal for adding an event.
 * @param {Function} t A function for internationalization (i18n).
 * @param {Object} event - Event of the row.
 * @returns {Object} The menu object containing items for adding events.
 */
export const createAddEventMenu = (openModal, t, event) => {
  const { employee, reference_day } = event;

  const planningOption =
    employee.week_management === 'MANUAL' &&
    (!reference_day || !reference_day?.length === 0)
      ? {
          key: 'planning',
          label: (
            <Button
              type="link"
              onClick={() =>
                openModal(
                  'PLANNING',
                  employee,
                  'create',
                  undefined,
                  undefined,
                  reference_day
                )
              }
            >
              <PlusCircleFilled />
              {t('plannings.employees.dropdown.planning')}
            </Button>
          )
        }
      : null;

  const regularsOption =
    reference_day && reference_day.length > 0
      ? {
          key: 'regular',
          label: (
            <Button
              type="link"
              onClick={() =>
                openModal(
                  'REGULAR',
                  employee,
                  'create',
                  undefined,
                  undefined,
                  reference_day
                )
              }
            >
              <PlusCircleFilled />
              {t('plannings.employees.dropdown.regulars')}
            </Button>
          )
        }
      : null;

  const regularAbsencesOption = {
    key: 'regular_absences',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'REGULAR_ABSENCE',
            employee,
            'create',
            undefined,
            undefined,
            reference_day
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.employees.dropdown.regular_absences')}
      </Button>
    )
  };

  const irregularAbsencesOption = {
    key: 'irregular_absences',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'IRREGULAR_ABSENCE',
            employee,
            'create',
            undefined,
            undefined,
            reference_day
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.employees.dropdown.irregular_absences')}
      </Button>
    )
  };

  const holidaysOption = {
    key: 'holidays',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'HOLIDAYS',
            employee,
            'create',
            undefined,
            undefined,
            reference_day
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.employees.dropdown.holidays')}
      </Button>
    )
  };

  const catchupDaysOption = {
    key: 'catch_up_days',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'CATCH_UP_DAY',
            employee,
            'create',
            undefined,
            undefined,
            reference_day
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.employees.dropdown.catch_up_days')}
      </Button>
    )
  };

  return {
    items: [
      planningOption,
      regularsOption,
      regularAbsencesOption,
      irregularAbsencesOption,
      holidaysOption,
      catchupDaysOption
    ].filter(Boolean)
  };
};
