import { useCallback, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, Spin, message as Message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  drawerTailFormLayout,
  drawerFormLayout
} from '../../utils/constants/formLayout';

/**
 * Create or update a resource using a modal form container.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {string} [props.id] - The identifier of the resource to edit (optional).
 * @param {Array<Object>} props.fields - An array of field objects for the form.
 * @param {string} props.baseUrl - The base URL for API requests.
 * @param {Object} [props.config] - Configuration options for API requests (optional).
 * @param {ReactNode} [props.formExtra] - Additional form content (optional).
 * @param {string} [props.tradKey] - The translation key (optional).
 * @param {string} [props.submitLabel] - The label for the submit button (optional).
 * @param {Function} [props.customSubmit] - A custom submit function (optional).
 * @param {boolean} [props.isCustomSubmitting] - Indicates if custom submitting is in progress (optional).
 * @param {boolean} [props.isParentLoading] - Indicates if a parent component is loading (optional).
 * @param {boolean} [props.withoutPageHeaderCustom] - Indicates whether to display a custom page header (optional).
 * @param {boolean} [props.withGetResource] - Indicates if you need to getResource or not (optional).
 * @param {Function} [props.closeModal] - A function to close the modal (optional).
 * @param {Object} [props.record] - The record object to edit (optional).
 * @param {string} [props.isModal] - Indicates whether the form is used in a modal (optional).
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateContainerModal = forwardRef(
  (
    {
      purpose,
      fields,
      id,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isCustomSubmitting,
      isParentLoading,
      withoutPageHeaderCustom,
      withGetResource,
      closeModal,
      record,
      customFormInstance,
      setRefresh,
      successCreateMessage,
      successEditMessage,
      specialFormLayout,
      updateUrl
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const {
      onGetResource,
      onCreateResource,
      onUpdateResource,
      onGetResourceFromModal
    } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${updateUrl || baseUrl}/${id || record._id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        closeModal();
        (customFormInstance || form).resetFields();
        if (setRefresh) setRefresh((prev) => !prev);
        Message.success(successEditMessage || t('success.messages.update'));
      } catch (e) {
        message(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        closeModal();
        (customFormInstance || form).resetFields();
        if (setRefresh) setRefresh((prev) => !prev);
        return Message.success(
          successCreateMessage || t('success.messages.create')
        );
      } catch (e) {
        if (e.response.data.message === 'UNAUTHORIZED_POST_EVENT') {
          return message(t(e.response.data.message));
        }
        return message(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      if (withGetResource) {
        try {
          const { data } = await dispatchAPI('GET', {
            url: `${baseUrl}/${id}`
          });
          (customFormInstance || form).setFieldsValue(
            onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data
          );
        } catch (e) {
          message(e);
        }
        setIsLoading(false);
      } else {
        (customFormInstance || form).setFieldsValue(
          onGetResourceFromModal && onGetResourceFromModal.setFields(record)
        );
        setIsLoading(false);
      }
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && (id || record)) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource, record, id]);

    const handleSubmit = async (values) => {
      if (customSubmit) {
        customSubmit(purpose, values);
      } else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        {!withoutPageHeaderCustom && (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        )}
        <Spin spinning={isLoading || isParentLoading}>
          <Form
            ref={ref}
            {...(specialFormLayout || drawerFormLayout)}
            onFinish={handleSubmit}
            form={customFormInstance || form}
          >
            {fields.map((field) => generateFields(tradKey || resource, field))}
            {formExtra}
            <Form.Item {...drawerTailFormLayout}>
              <Flex justify="end" wrap="nowrap" gap="small">
                <Button type="link" danger onClick={closeModal}>
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isCustomSubmitting || isSubmitting}
                  disabled={isCustomSubmitting || isSubmitting}
                >
                  {`${t(submitLabel || 'buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Flex>
            </Form.Item>
          </Form>
        </Spin>
      </>
    );
  }
);

CreateUpdateContainerModal.propTypes = {
  successCreateMessage: PropTypes.string,
  successEditMessage: PropTypes.string,
  purpose: PropTypes.string.isRequired,
  id: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  updateUrl: PropTypes.string,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onGetResourceFromModal: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withoutPageHeaderCustom: PropTypes.bool,
  withGetResource: PropTypes.bool,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  isCustomSubmitting: PropTypes.bool,
  record: PropTypes.shape({
    _id: PropTypes.string
  }),
  customFormInstance: PropTypes.shape({}),
  setRefresh: PropTypes.func,
  specialFormLayout: PropTypes.shape({})
};

CreateUpdateContainerModal.defaultProps = {
  config: {},
  id: '',
  successCreateMessage: null,
  successEditMessage: null,
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withoutPageHeaderCustom: false,
  withGetResource: true,
  isModal: false,
  closeModal: () => {},
  isCustomSubmitting: false,
  record: null,
  customFormInstance: null,
  setRefresh: () => {},
  specialFormLayout: null,
  updateUrl: null
};
