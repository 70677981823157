import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Card, Skeleton, List, Button, Tooltip, message } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../utils/downloadDoc';

/**
 * @description
 * DocumentsCard is a component that displays the documents of the contract
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {boolean} isLoading - If the data is loading
 * @param {Object} data - The data of the child
 * @returns {React.Component} DocumentsCard component
 */
export const DocumentsCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const { viewDocument } = useDownloadDocument();
  const [loadingDocId, setLoadingDocId] = useState(null);
  const dataSource = data?.contract?.documents || [];
  const title = t('pre-registrations.show.cards.documents');

  useEffect(() => {
    if (loadingDocId) message.loading(t('success.messages.loading'));
    if (!loadingDocId) message.destroy();
  }, [loadingDocId]);

  const viewDoc = async (_id) => {
    if (_id) {
      setLoadingDocId(_id);
      await viewDocument({ _id });
      setLoadingDocId(null);
    } else {
      message.error(t('errors.message.display_document'));
    }
  };

  return (
    <Card headStyle={{ borderBottom: 'none' }} title={title}>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <List
          bordered
          size="small"
          dataSource={dataSource}
          renderItem={({ file: doc, document_title }) => (
            <List.Item
              key={doc?._id}
              actions={[
                <Tooltip
                  title={t('pre-registrations.show.documents_card.view')}
                >
                  <Button
                    disabled={
                      loadingDocId !== null && loadingDocId !== doc?._id
                    }
                    loading={loadingDocId === doc?._id}
                    type="link"
                    onClick={() => viewDoc(doc?._id)}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
              ]}
            >
              {document_title}
            </List.Item>
          )}
        />
      </Skeleton>
    </Card>
  );
};

DocumentsCard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    contract: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string,
          document_title: PropTypes.string
        })
      )
    })
  })
};

DocumentsCard.defaultProps = {
  isLoading: false,
  data: {
    contract: {
      documents: []
    }
  }
};
