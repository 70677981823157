/**
 * @description
 * useListContent is a hook that returns the content of the household card.
 * It is used in the HouseholdtCard component.
 *
 * @param {Object} data - The data of the household
 * @returns {Array} The content of the household card
 */
export const useListContent = (data = {}) => {
  const { household } = data;

  const formattedAmount = (amount) =>
    new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);

  return [
    {
      label: 'pre-registrations.show.household.beneficiary_number',
      content: household?.beneficiary_number,
      span: 1
    },
    {
      label: 'pre-registrations.show.household.dependent_children',
      content: household?.dependent_children,
      span: 1
    },
    {
      label:
        'pre-registrations.show.household.dependent_children_with_disabilities',
      content: household?.dependent_children_with_disabilities,
      span: 1
    },
    {
      label: 'pre-registrations.show.household.annnual_incomes',
      content:
        household && household.annual_incomes + 1
          ? formattedAmount(household.annual_incomes)
          : '-',
      span: 1
    }
  ];
};
