import { Col, Card, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columnsInvoice';

/**
 * Renders the summary row for the invoice table.
 *
 * @function
 * @param {number} total - The total amount to be displayed in the summary row.
 * @param {Array} columns - The column configuration of the invoice table.
 *
 * @returns {Function} A function that returns a JSX element representing the summary row.
 */
const renderSummary = (total, columns) => () =>
  (
    <Table.Summary.Row>
      <Table.Summary.Cell colSpan={columns.length - 1}>
        Total TTC
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        <strong>{`${Number(total).toFixed(2)} €`}</strong>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

/**
 * Component for displaying invoice details.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array} props.data - Invoice data containing invoice details.
 * @param {number} props.total - The total amount of the invoice.
 * @param {Object} props.child - The child object containing details of the child being invoiced.
 * @param {Array} props.formScratchDetails - Details specific to invoices created from scratch.
 * @param {string} props.type - The type of the invoice, either 'FROM_SCRATCH' or other types.
 *
 * @returns {JSX.Element} Component JSX
 */
export const InvoiceCard = ({
  data,
  total,
  child,
  type,
  invoice,
  resource
}) => {
  const { t } = useTranslation();
  const columns = useColumns(child, type, invoice, resource);

  const displayedByType = {
    REGUL: invoice?.regul_details,
    FROM_SCRATCH: invoice?.from_scratch_details,
    CLASSIC: data,
    BALANCE: data
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span="24">
        <Card
          title={
            resource === 'payment' ? '' : t('invoices.show.titles.invoice')
          }
        >
          <Table
            dataSource={invoice?.type && displayedByType[invoice?.type]}
            columns={columns}
            pagination={false}
            scroll={{ x: 1000 }}
            summary={renderSummary(total, columns)}
          />
        </Card>
      </Col>
    </Row>
  );
};

InvoiceCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  total: PropTypes.number,
  child: PropTypes.shape({}),
  invoice: PropTypes.shape({
    type: PropTypes.string,
    regul_details: PropTypes.arrayOf(PropTypes.shape({})),
    from_scratch_details: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  resource: PropTypes.string
};

InvoiceCard.defaultProps = {
  data: undefined,
  total: undefined,
  child: undefined,
  type: undefined,
  invoice: {},
  resource: null
};
