import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { hours } from '../../../utils/days';
import { PlanningProjections } from './PlanningProjections';
import { Overtimes } from './Overtimes';
import { PickupAndPlanningMatch } from './PickUpAndPlanningMatch';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

/**
 * PlanningRangeBar
 * @component
 * @description Displays the planning range bar
 * @param {Object} events - The events of the child
 * @param {Function} openModal - The function to open the modal
 * @param {Function} deleteEvent - The function to delete an event
 * @param {Boolean} displayButtons - Boolean to display RangeBar buttons.
 * @param {string} source - Source of the component call.
 * @returns {JSX.Element} - The PlanningRangeBar component
 */
export const PlanningRangeBar = ({
  events,
  openModal,
  deleteEvent,
  displayButtons,
  source
}) => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const [widthDiv, setWidthDiv] = useState();
  const { child, employee } = events;
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.clientWidth;
      setWidthDiv(width);
    }
  }, []);

  const elementToConsider = source === 'child' ? child : employee;

  return (
    <table>
      <tbody>
        <td colSpan={hours.length}>
          <div
            className="div-container"
            ref={divRef}
            style={{ position: 'relative' }}
          >
            {hours.map((hour) => (
              <div key={`${hour}`} className="time-slot" />
            ))}
            <PlanningProjections
              widthDiv={widthDiv}
              source={source}
              displayButtons={displayButtons}
              openModal={openModal}
              events={events}
              elementToConsider={elementToConsider}
              deleteEvent={deleteEvent}
            />
            <Overtimes
              widthDiv={widthDiv}
              source={source}
              displayButtons={displayButtons}
              openModal={openModal}
              events={events}
              elementToConsider={elementToConsider}
              deleteEvent={deleteEvent}
            />
            <PickupAndPlanningMatch
              widthDiv={widthDiv}
              source={source}
              displayButtons={displayButtons}
              openModal={openModal}
              events={events}
              elementToConsider={elementToConsider}
              deleteEvent={deleteEvent}
            />
          </div>
        </td>
      </tbody>
    </table>
  );
};

PlanningRangeBar.propTypes = {
  openModal: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  events: PropTypes.shape({
    _id: PropTypes.string,
    child: PropTypes.shape({}),
    employee: PropTypes.shape({}),
    childSchedule: PropTypes.shape({
      planningProjections: PropTypes.arrayOf(PropTypes.shape({})),
      overtimes: PropTypes.arrayOf(PropTypes.shape({})),
      pickUpAndPlanningMatch: PropTypes.arrayOf(PropTypes.shape({})),
      type: PropTypes.string
    }).isRequired
  }).isRequired,
  displayButtons: PropTypes.bool
};

PlanningRangeBar.defaultProps = {
  displayButtons: undefined
};
