import { useTranslation } from 'react-i18next';
import { Col, Flex, Popconfirm, Row, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {
  principalColorPlanning,
  realColorPlanning,
  secondColorPlanning
} from '../../../utils/constants/tagColors';
import {
  convertEventType,
  reverseConvertEventType
} from '../../employee/planning/utils';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Component to render planning projections.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {number} props.widthDiv - Width divisor.
 * @param {string} props.source - Source of the event.
 * @param {boolean} props.displayButtons - Whether to display edit and delete buttons.
 * @param {Function} props.openModal - Function to open modal.
 * @param {object} props.events - Event data.
 * @param {string} props.elementToConsider - Element to consider.
 * @param {Function} props.deleteEvent - Function to delete event.
 * @returns {JSX.Element[]} Array of JSX elements representing planning projections.
 */
export const PlanningProjections = ({
  widthDiv,
  source,
  displayButtons,
  openModal,
  events,
  elementToConsider,
  deleteEvent
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const { childSchedule, _id, reference_day } = events;
  const { planningProjections, overtimes, pickUpAndPlanningMatch } =
    childSchedule;

  const showRegex = /\bshow\b/;

  // Check if the pathname includes "show"
  const includesShow = showRegex.test(pathname);

  return (planningProjections || []).map((planning) => {
    if (planning) {
      const {
        type: planningType,
        marginLeft,
        start_time,
        end_time,
        width,
        id
      } = planning;

      const planningMarginLeftFactor = marginLeft / 100;
      const spanMarginLeft = (widthDiv * planningMarginLeftFactor).toFixed(0);
      const principalColor = principalColorPlanning[planningType];
      const principalColorRGBA = `${principalColor}80`;
      const secondColor = secondColorPlanning[planningType];
      const secondColorRGBA = `${secondColor}80`;

      const eventsPresence =
        overtimes.length > 0 || pickUpAndPlanningMatch.length > 0;

      const displayChildEditDeleteButtons =
        user?.role !== 'guests:PARENT' &&
        (planningType !== 'reservations' ||
          pickUpAndPlanningMatch.length === 0) &&
        planningType !== 'reference_day' &&
        displayButtons;

      const displayEmployeeEditDeleteButtons =
        (reference_day.some((slot) => slot.manual === true) &&
          !eventsPresence &&
          !includesShow) ||
        reference_day.length === 0;

      const conditionForEditDeleteButtons =
        source === 'child'
          ? displayChildEditDeleteButtons
          : displayEmployeeEditDeleteButtons;

      const employeeModalType = reverseConvertEventType(planningType);
      const modalTypeToConsider =
        source === 'child' ? planningType : employeeModalType;
      const deleteEventType =
        source === 'child'
          ? modalTypeToConsider
          : convertEventType(employeeModalType);

      return (
        <Tooltip
          key={`${start_time} ${end_time}`}
          title={
            <Row justify="center">
              <Col
                span={20}
                style={{
                  color: principalColor,
                  textAlign: 'left'
                }}
              >
                {t(`plannings.children.type.${planningType}`)}
              </Col>
              <Col
                span={4}
                style={{
                  textAlign: 'right',
                  color: 'var(--textColor)'
                }}
              >
                {conditionForEditDeleteButtons && (
                  <Flex gap="small">
                    <EditOutlined
                      onClick={() =>
                        openModal(
                          modalTypeToConsider,
                          elementToConsider,
                          'edit',
                          id,
                          _id,
                          reference_day
                        )
                      }
                    />
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() =>
                        deleteEvent(deleteEventType, id, _id, source)
                      }
                      icon={<WarningOutlined />}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Flex>
                )}
              </Col>
              <Col
                span={24}
                style={{
                  textAlign: 'left',
                  color: 'var(--mediumGray)'
                }}
              >
                {`${dayjs(start_time).utcOffset(0).format('HH:mm')} - ${dayjs(
                  end_time
                )
                  .utcOffset(0)
                  .format('HH:mm')}`}
              </Col>
              <Col
                span={24}
                style={{
                  textAlign: 'left',
                  color: 'var(--mediumGray)'
                }}
              >
                {`${dayjs(end_time).diff(start_time, 'hour')} heure${
                  dayjs(end_time).diff(start_time, 'hour') > 1 ? 's' : ''
                } ${dayjs(end_time).diff(start_time, 'minute') % 60} minute${
                  dayjs(end_time).diff(start_time, 'minute') % 60 > 1 ? 's' : ''
                }`}
              </Col>
            </Row>
          }
          placement="top"
          color="white"
        >
          <div
            key={width}
            className="planning-bar"
            style={{
              position: 'absolute',
              width: `${width}%`,
              left: Number(spanMarginLeft),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0',
              borderColor: principalColor,
              background: `repeating-linear-gradient(
              45deg,
              ${principalColorRGBA},
              ${principalColorRGBA} 10px,
              ${secondColorRGBA} 10px,
              ${secondColorRGBA} 20px
            )`,
              ...(planningType === 'absences'
                ? {
                    backgroundColor: realColorPlanning[planningType]
                  }
                : {}),
              borderRadius: 40,
              zIndex: 1,
              color: planningType === 'absences' ? '#fff' : 'var(--textColor)'
            }}
          >
            {width >= 10 && (
              <span>{`${dayjs(start_time)
                .utcOffset(0)
                .format('HH:mm')} - ${dayjs(end_time)
                .utcOffset(0)
                .format('HH:mm')}`}</span>
            )}
          </div>
        </Tooltip>
      );
    }
    return null;
  });
};
