import { useTranslation } from 'react-i18next';
import { Input, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';

const { Option } = Select;
const { Group } = Radio;

/**
 * Fields for the form
 *
 * @hook
 *
 * @param {Object} enums - The enums from the backend.
 * @param {Object} data - Form Data for fields states.
 * @param {Function} setArrayFilter - Function to set arrayFilter state.
 * @returns {Array} The fields to use in the form.
 */
export const useAdditionnalFees = (enums, data, setArrayFilter) => {
  const { t } = useTranslation();
  const [displayExcessAmount, setDisplayExcessAmount] = useState({
    displayRegularBillExcessAmount: false,
    displayOccasionalBillExcessAmount: false,
    displayAdaptationAmount: false,
    initialisation: false
  });

  const handleDisplayExcessAmount = (value, key) => {
    setDisplayExcessAmount((prev) => {
      const updatedState = { ...prev };
      updatedState[key] = !value;
      return updatedState;
    });
  };

  useEffect(() => {
    if (
      displayExcessAmount.initialisation === false &&
      Object.values(data).every(
        (value) => value !== null && value !== undefined
      )
    ) {
      setDisplayExcessAmount({
        displayRegularBillExcessAmount: !data.regularExcess,
        displayOccasionalBillExcessAmount: !data.occasionalExcess,
        displayAdaptationAmount: !data.adaptation_amount,
        initialisation: true
      });
    }
  }, [data]);

  const booleanRadio = (
    <Group>
      <Radio value>{t('yes')}</Radio>
      <Radio value={false}>{t('no')}</Radio>
    </Group>
  );

  const arrayFilters = (enums?.round_up_rules || []).map((rule) => ({
    label: t(`configurations.invoicing.form.enums.${rule}`),
    value: rule,
    key: rule
  }));

  const additionnalFeesFields = [
    {
      startWithDivider: {
        title: t('configurations.invoicing.form.titles.excess')
      },
      name: 'bill_excess_on_regular_care',
      label: t('bill_excess_on_regular_care'),
      input: booleanRadio
    },
    {
      name: 'bill_excess_on_regular_care_take_account_of_contract_rate',
      label: t('bill_excess_on_regular_care_take_account_of_contract_rate'),
      input: (
        <Group
          onChange={(e) =>
            handleDisplayExcessAmount(
              e.target.value,
              'displayRegularBillExcessAmount'
            )
          }
        >
          <Radio value>{t('yes')}</Radio>
          <Radio value={false}>{t('no')}</Radio>
        </Group>
      )
    },
    ...(displayExcessAmount.displayRegularBillExcessAmount
      ? [
          {
            name: 'bill_excess_on_regular_care_amount',
            label: t('bill_excess_on_regular_care_amount'),
            input: <Input type="number" addonAfter="€" />
          }
        ]
      : []),
    {
      name: 'regular_round_up_rules',
      label: t('round_up_rules'),
      input: (
        <Group
          onChange={(e) =>
            setArrayFilter((prev) => {
              const updatedState = { ...prev };

              updatedState.regulars = e.target.value;
              return updatedState;
            })
          }
          defaultValue="FIFTEEN_MINUTES"
          options={arrayFilters}
        />
      )
    }
  ];

  const secondPartAdditionnalFeesFields = [
    {
      name: 'bill_excess_on_occasionnal_care',
      label: t('bill_excess_on_occasionnal_care'),
      input: booleanRadio
    },
    {
      name: 'bill_excess_on_occasionnal_care_take_account_of_contract_rate',
      label: t('bill_excess_on_occasionnal_care_take_account_of_contract_rate'),
      input: (
        <Group
          onChange={(e) =>
            handleDisplayExcessAmount(
              e.target.value,
              'displayOccasionalBillExcessAmount'
            )
          }
        >
          <Radio value>{t('yes')}</Radio>
          <Radio value={false}>{t('no')}</Radio>
        </Group>
      )
    },
    ...(displayExcessAmount.displayOccasionalBillExcessAmount
      ? [
          {
            name: 'bill_excess_on_occasionnal_care_amount',
            label: t('bill_excess_on_occasionnal_care_amount'),
            input: <Input type="number" addonAfter="€" />
          }
        ]
      : []),
    {
      name: 'occasional_round_up_rules',
      label: t('round_up_rules'),
      input: (
        <Group
          onChange={(e) =>
            setArrayFilter((prev) => {
              const updatedState = { ...prev };

              updatedState.occasional = e.target.value;
              return updatedState;
            })
          }
          defaultValue="FIFTEEN_MINUTES"
          options={arrayFilters}
        />
      )
    }
  ];

  const thirdPartAdditionnalFeesFields = [
    {
      startWithDivider: {
        title: t(
          'configurations.invoicing.form.titles.additionnal_reservations'
        )
      },
      name: 'billing_additional_reservation',
      label: t('billing_additional_reservation'),
      input: (
        <Select>
          {enums?.bill_by?.map((billBy) => (
            <Option key={billBy} value={billBy}>
              {t(`configurations.invoicing.form.enums.${billBy}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'billing_additional_reservation_amount',
      label: t('billing_additional_reservation_amount'),
      input: <Input type="number" addonAfter="€" />
    },
    {
      startWithDivider: {
        title: t('configurations.invoicing.form.titles.adaptation')
      },
      name: 'billing_adaptation',
      label: t('billing_adaptation'),
      input: (
        <Select>
          {enums?.bill_by?.map((billBy) => (
            <Option key={billBy} value={billBy}>
              {t(`configurations.invoicing.form.enums.${billBy}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'billing_adaptation_amount_take_account_of_contract_rate',
      label: t('billing_adaptation_amount_take_account_of_contract_rate'),
      input: (
        <Group
          onChange={(e) =>
            handleDisplayExcessAmount(e.target.value, 'displayAdaptationAmount')
          }
        >
          <Radio value>{t('yes')}</Radio>
          <Radio value={false}>{t('no')}</Radio>
        </Group>
      )
    },
    ...(displayExcessAmount.displayAdaptationAmount
      ? [
          {
            name: 'billing_adaptation_amount',
            label: t('billing_adaptation_amount'),
            input: <Input type="number" addonAfter="€" />
          }
        ]
      : []),
    {
      name: 'deduct_existing_schedules_before_adaptation',
      label: t('deduct_existing_schedules_before_adaptation'),
      input: (
        <Group>
          {enums?.deduct_existing_schedules_before_adaptation?.map((item) => (
            <Radio key={item} value={item}>
              {t(`configurations.invoicing.form.enums.${item}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      startWithDivider: {
        title: t('configurations.invoicing.form.titles.reminders')
      },
      name: 'billing_reminder_amount',
      label: t('billing_reminder_amount'),
      input: <Input type="number" addonAfter="€" />
    },
    {
      name: 'waiting_time_before_first_reminder',
      label: t('waiting_time_before_first_reminder'),
      input: <Input type="number" addonAfter={t('days')} />
    },
    {
      name: 'waiting_time_before_second_reminder',
      label: t('waiting_time_before_second_reminder'),
      input: <Input type="number" addonAfter={t('days')} />
    },
    {
      name: 'waiting_time_before_third_reminder',
      label: t('waiting_time_before_third_reminder'),
      input: <Input type="number" addonAfter={t('days')} />
    },
    {
      name: 'waiting_time_before_legal_collection_procedure',
      label: t('waiting_time_before_legal_collection_procedure'),
      input: <Input type="number" addonAfter={t('days')} />
    }
  ];

  return {
    additionnalFeesFields,
    secondPartAdditionnalFeesFields,
    thirdPartAdditionnalFeesFields
  };
};
