import { Card, Col, Spin, Switch } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DualAxes, Line } from '@ant-design/charts';
import { render, useGraphConfig } from './Indicators/utils';
import { useAnalysisContext } from '../../../contexts/AnalysisContext';

/**
 * Graph card component to display a line chart or dual axes chart based on provided data.
 *
 * @component
 *
 * @param {Object} props - The props for the GraphCard component.
 * @param {string} props.title - The title of the graph card.
 * @param {number} props.spanSize - The span size for the graph card layout.
 * @param {Object} props.datas - The data for the graph card.
 * @param {Array<Object>} props.datas.datatable - The data for the table.
 * @param {Object} props.datas.month - The data for the month.
 * @param {Array<Object>} props.datas.month.objective - The data for the month's objective.
 * @param {Array<Object>} props.datas.month.real - The data for the month's real.
 * @param {Array<Object>} props.datas.cumulate - The data for cumulated values.
 * @param {Array<Object>} props.datas.objective - The data for the objective.
 * @param {Array<Object>} props.datas.prevision - The data for the prevision.
 * @param {string} props.mode - The mode of the graph card. Should be either 'follow_up' or 'evolution'.
 * @returns {React.Component} A React component representing the GraphCard.
 */
export const GraphCard = ({ title, spanSize, datas }) => {
  const { t } = useTranslation();
  const [isMonthlyMode, setIsMonthlyMode] = useState(true);
  const { tablesData } = useAnalysisContext();
  const { boolean, graphConfig } = useGraphConfig(
    t,
    title,
    datas,
    isMonthlyMode
  );

  const config = useMemo(() => graphConfig, [isMonthlyMode, datas]);
  const renderedComponent = useMemo(
    () => (isMonthlyMode ? <Line {...config} /> : <DualAxes {...config} />),
    [config, datas]
  );

  return (
    <Col span={spanSize}>
      <Card
        title={title}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: ' column',
          justifyContent: 'space-between'
        }}
        extra={
          boolean && (
            <Switch
              unCheckedChildren={t('analysis.monthly')}
              checkedChildren={t('analysis.cumulate')}
              defaultChecked={!isMonthlyMode}
              onChange={() => setIsMonthlyMode(!isMonthlyMode)}
            />
          )
        }
      >
        {Object.keys(datas).length ? (
          render(renderedComponent, title, tablesData, t)
        ) : (
          <Spin />
        )}
      </Card>
    </Col>
  );
};

GraphCard.propTypes = {
  title: PropTypes.string.isRequired,
  spanSize: PropTypes.number.isRequired,
  datas: PropTypes.shape({
    datatable: PropTypes.arrayOf(PropTypes.shape({})),
    month: PropTypes.shape({
      objective: PropTypes.arrayOf(PropTypes.shape({})),
      real: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    cumulate: PropTypes.arrayOf(PropTypes.shape({})),
    objective: PropTypes.arrayOf(PropTypes.shape({})),
    prevision: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  mode: PropTypes.oneOf(['follow_up', 'evolution'])
};

GraphCard.defaultProps = {
  datas: null,
  mode: null
};
