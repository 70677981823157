import { DatePicker } from 'antd';

/**
 * Get the fields to use in the form.
 * @hook
 * @returns {Object} The fields to use in the form.
 */
export const useFields = () => {
  const fields = [
    {
      label: 'title',
      name: 'title',
      rules: [{ required: true }]
    },
    {
      label: 'rangedate',
      name: 'rangedate',
      input: <DatePicker.RangePicker status="warning" format="DD/MM/YYYY" />,
      rules: [{ required: true }]
    }
  ];

  return {
    fields
  };
};
