import { Row, List } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `ConversationItem` is a component that renders an individual message within a conversation.
 * It displays the message content, sender's name, and the date the message was sent.
 * The message's appearance changes based on whether the current user sent the message.
 *
 * This component uses the `useAuthContext` to determine if the message was sent by the current user,
 * and applies different styling accordingly.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.messageItem - The message item to be rendered, containing the sender's information, message content, and date.
 *
 * @returns {JSX.Element} A Row component representing a single message in a conversation.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.messageItem {Object} - The message item, including the sender's info (`first_name`, `last_name`, `_id`), message content, and date.
 */
export const ConversationItem = ({ messageItem }) => {
  const { user } = useAuthContext();
  const isCurrentUser = messageItem?.from._id === user._id;

  const justify = () => (isCurrentUser ? 'end' : 'start');

  return (
    <Row
      justify={justify()}
      style={{
        width: '100%'
      }}
    >
      <List.Item
        className={
          isCurrentUser ? 'message-list-item right' : 'message-list-item left'
        }
      >
        <List.Item.Meta
          title={`${messageItem?.from?.first_name} ${
            messageItem?.from?.last_name
          } - ${dayjs(messageItem?.date).format('HH:mm')}`}
          description={messageItem?.content}
        />
      </List.Item>
    </Row>
  );
};

ConversationItem.propTypes = {
  messageItem: PropTypes.shape({
    from: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      _id: PropTypes.string
    }),
    content: PropTypes.string,
    date: PropTypes.string
  })
};

ConversationItem.defaultProps = {
  messageItem: null
};
