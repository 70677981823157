import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Card, Col, Flex, Select, Upload } from 'antd';
import {
  DeleteOutlined,
  FolderOpenFilled,
  HourglassOutlined,
  CheckCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { documentFormConfigs } from '../../../Contracts/utils/documentFormConfigs';
import { deleteDocument } from '../../../Contracts/utils/deleteDocument';
import { draggerProps } from '../../../Contracts/utils/draggerProps';
import { handleTempFileListSave } from '../../../Contracts/utils/handleTempFileListSave';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * Component for rendering the document form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Array} documents Object of arrays of documents.
 *
 * @returns {React.Component} Payment method form component
 */
export const DocumentTab = ({ documents, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [tempFileList, setTempFileList] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const { downloadDocument } = useDownloadDocument();
  const { contractID } = useParams();
  const { dispatchAPI, daycare } = useAuthContext();
  const [daycareDocuments, setDaycareDocuments] = useState([]);
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const {
    handleDownload,
    displayDownloadButton,
    displayFileName,
    displayDeleteButton
  } = documentFormConfigs(filesConfiguration, filesList, downloadDocument);

  const deleteDocumentByID = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `contracts/${contractID}/delete-document/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  const getMandatoryDocuments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `daycares/${daycare}?fields=documents_dynamic_list`
      });
      setDaycareDocuments(data.documents_dynamic_list);
    } catch (e) {
      message(e);
    }
  };

  const postDocument = async () => {
    try {
      const formData = new FormData();

      tempFileList.forEach((file) => {
        formData.append('documents', file);
      });

      setFilesConfiguration((prevFilesConfiguration) => {
        formData.append(
          'filesConfiguration',
          JSON.stringify(prevFilesConfiguration)
        );
        return prevFilesConfiguration;
      });

      await dispatchAPI('PATCH', {
        url: `contracts/${contractID}/post-document`,
        body: formData
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (documents) {
      setFilesList((documents || []).map((doc) => doc.file));
      setFilesConfiguration(
        (documents || []).map((doc) => ({
          name: doc?.file?.metadata?.originalName,
          fileKey: doc?.document_title,
          type: doc?.type
        }))
      );
    }
  }, [documents, refresh]);

  useEffect(() => {
    (async () => {
      await getMandatoryDocuments();
    })();
  }, []);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 64, minHeight: 210 }}>
      <Col xs={24} xl={14}>
        <Flex vertical gap="small">
          {(daycareDocuments || [])?.map((item) => (
            <Card key={item.name} className="document-card">
              <Flex justify="space-between" align="center">
                <Flex align="center" gap="small">
                  {filesConfiguration.some(
                    (config) => config.fileKey === item.document_title
                  ) ? (
                    <CheckCircleOutlined style={{ color: 'var(--addColor' }} />
                  ) : (
                    <HourglassOutlined
                      style={{ color: 'var(--disabledColor' }}
                    />
                  )}
                  {`${item?.document_title}${
                    item?.required
                      ? ` - ${t('contracts.form.item_required')}`
                      : ''
                  }`}
                </Flex>

                {displayFileName(item)}

                <Flex>
                  {displayDeleteButton(item) && (
                    <Button
                      onClick={() =>
                        deleteDocument(
                          item.document_title,
                          filesConfiguration,
                          setFilesConfiguration,
                          setFilesList,
                          filesList,
                          deleteDocumentByID,
                          t
                        )
                      }
                      type="link"
                      icon={<DeleteOutlined />}
                    />
                  )}
                  {displayDownloadButton(item) && (
                    <Button
                      onClick={() => handleDownload(item)}
                      type="link"
                      icon={<UploadOutlined />}
                    />
                  )}
                </Flex>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Col>
      <Col xs={24} xl={10}>
        <Dragger
          {...draggerProps(
            tempFileList,
            setTempFileList,
            message,
            filesList,
            t
          )}
          showUploadList={false}
          style={{ marginBottom: 8 }}
        >
          <p className="ant-upload-drag-icon">
            <FolderOpenFilled />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
        <Flex vertical gap="small">
          {(tempFileList || []).map((file, index) => (
            <Flex key={file.name} align="center" gap="small">
              <span
                style={{
                  maxWidth: 'calc(100% - 44px)'
                }}
                className="text-ellipsis"
              >
                {file.name}
              </span>
              <Button
                onClick={() =>
                  setTempFileList((prevList) => {
                    const updatedList = [...prevList];
                    updatedList.splice(index, 1);
                    return updatedList;
                  })
                }
                type="link"
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          ))}
          <Flex align="center" justify="space-between" wrap="wrap" gap="small">
            {tempFileList.length > 0 && (
              <Select
                style={{ width: 320 }}
                onSelect={(value) => {
                  setSelectedType(value);
                }}
                placeholder={t('files.create.category_placeholder')}
              >
                {(daycareDocuments || []).map((item) => (
                  <Option key={item._id} value={item.document_title}>
                    {item.document_title}
                  </Option>
                ))}
              </Select>
            )}
            {selectedType && tempFileList?.length && (
              <Button
                onClick={() =>
                  handleTempFileListSave(
                    tempFileList,
                    filesConfiguration,
                    selectedType,
                    setFilesConfiguration,
                    setFilesList,
                    setTempFileList,
                    setSelectedType,
                    t,
                    deleteDocumentByID,
                    postDocument,
                    'document-tab'
                  )
                }
                type="primary"
              >
                {`${t('buttons.save')}`}
              </Button>
            )}
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};

DocumentTab.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

DocumentTab.defaultProps = {
  documents: [],
  refresh: false,
  setRefresh: null
};
