import PropTypes from 'prop-types';
import { List } from 'antd';
import { handleNotifMenuItemLink } from './utils/handleNotificationMenuLink';

/**
 * NotificationMenuItem
 * @component
 * @param {Object} props - component props
 * @param {Object} props.notification - notification object
 * @param {Function} props.onDeleteNotification - delete notification function
 * @param {Function} props.onReadNotification - Function to patch notification to read.
 * @param {string} props.userID - User ID.
 * @param {Function} props.t - translation function
 * @returns {ReactElement} - NotificationMenuItem component
 */
export const NotificationMenuItem = ({
  notification,
  onDeleteNotification,
  onReadNotification,
  userID,
  t
}) => {
  const readBoolean = (notification?.read || []).some(
    (readItem) => readItem === userID
  );

  return (
    <List.Item
      key={notification._id}
      className={!readBoolean ? 'unread-notification' : 'read-notification'}
    >
      {handleNotifMenuItemLink(
        notification,
        onDeleteNotification,
        onReadNotification,
        t
      )}
    </List.Item>
  );
};

NotificationMenuItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    read: PropTypes.arrayOf(PropTypes.string),
    item: PropTypes.shape({})
  }).isRequired,
  onDeleteNotification: PropTypes.func.isRequired,
  onReadNotification: PropTypes.func.isRequired,
  userID: PropTypes.string,
  t: PropTypes.func.isRequired
};

NotificationMenuItem.defaultProps = {
  userID: undefined
};
