import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import useFields from './fields';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { convertEventType } from './utils';

/**
 * @component EventModal
 * @description EventModal component. Displays the event modal
 * @param {Object} props Component props
 * @param {Boolean} props.isModalOpen Is the modal open
 * @param {Function} props.closeModal Function to close the modal
 * @param {Object} props.selectedChild The selected child
 * @param {String} props.purpose The purpose of the modal
 * @param {String} props.type The type of the event
 * @param {String} props.eventId The event id
 * @param {String} props.recorderId The recorder id
 * @param {String} props.daycare The daycare id
 * @param {Object} props.selectedDay The selected day
 * @returns {JSX} The event modal
 */
const EventModal = ({
  isModalOpen,
  closeModal,
  selectedEmployee,
  purpose,
  type,
  eventId,
  recorderId,
  selectedDay,
  refresh,
  referenceDay,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { daycare } = useAuthContext();
  const { fields, isFieldsLoading } = useFields(
    selectedEmployee,
    selectedDay,
    purpose,
    type,
    referenceDay
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        const updatedType = convertEventType(type);
        switch (type) {
          case 'REGULAR':
            return {
              regulars: (data.regulars || []).map((pickup) => ({
                times: [
                  dayjs.utc(pickup.start_time),
                  dayjs.utc(pickup.end_time)
                ],
                eventId: pickup._id
              })),
              date: data.date && dayjs(data.date)
            };
          case 'PLANNING':
            return {
              regulars: (data.reference_day || []).map((pickup) => ({
                times: [
                  dayjs.utc(pickup.start_time),
                  dayjs.utc(pickup.end_time)
                ],
                eventId: pickup._id
              })),
              date: data.date && dayjs(data.date)
            };
          default:
            return {
              ...data[updatedType]?.find((item) => item?._id === eventId),
              times: [
                dayjs.utc(
                  data[updatedType]?.find((item) => item?._id === eventId)
                    .start_time
                ),
                dayjs.utc(
                  data[updatedType]?.find((item) => item?._id === eventId)
                    .end_time
                )
              ],
              date: data.date && dayjs(data.date)
            };
        }
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const updatedType = convertEventType(type);

        return {
          ...(['REGULAR', 'PLANNING'].includes(type)
            ? {
                regulars:
                  data.regulars &&
                  (data.regulars || []).map((pickup) => {
                    if (pickup.times[1].valueOf() < pickup.times[0].valueOf()) {
                      return {
                        start_time: pickup.times[0]
                          .utcOffset(0)
                          .year(data.date.year())
                          .month(data.date.month())
                          .date(data.date.date())
                          .hour(pickup.times[0].hour())
                          .minute(pickup.times[0].minute()),
                        end_time: pickup.times[1]
                          .utcOffset(0)
                          .year(data.date.year())
                          .month(data.date.month())
                          .date(data.date.date())
                          .hour(pickup.times[1].hour())
                          .minute(pickup.times[1].minute()),
                        _id: pickup.eventId
                      };
                    }
                    return {
                      start_time: pickup.times[1]
                        .utcOffset(0)
                        .year(data.date.year())
                        .month(data.date.month())
                        .date(data.date.date())
                        .hour(pickup.times[1].hour())
                        .minute(pickup.times[1].minute()),
                      end_time: pickup.times[0]
                        .utcOffset(0)
                        .year(data.date.year())
                        .month(data.date.month())
                        .date(data.date.date())
                        .hour(pickup.times[0].hour())
                        .minute(pickup.times[0].minute()),
                      _id: pickup?.eventId
                    };
                  })
              }
            : {
                eventId: data?.eventId && data.eventId,
                [updatedType]: (() => {
                  const time0 = dayjs(data.times[0]);
                  const time1 = dayjs(data.times[1]);

                  let startTime;
                  let endTime;

                  if (time1.valueOf() < time0.valueOf()) {
                    startTime = time0;
                    endTime = time1;
                  } else {
                    startTime = time1;
                    endTime = time0;
                  }

                  return {
                    start_time: startTime
                      .utcOffset(0)
                      .year(data.date.year())
                      .month(data.date.month())
                      .date(data.date.date())
                      .hour(startTime.hour())
                      .minute(startTime.minute()),
                    end_time: endTime
                      .utcOffset(0)
                      .year(data.date.year())
                      .month(data.date.month())
                      .date(data.date.date())
                      .hour(endTime.hour())
                      .minute(endTime.minute()),
                    reason: data?.reason && data.reason,
                    comment: data?.comment && data.comment,
                    billing: data?.billing && data.billing,
                    absence_deduction:
                      data?.absence_deduction && data.absence_deduction,
                    take_vacation: data?.take_vacation && data.take_vacation,
                    _id: data.eventId && data.eventId
                  };
                })()
              }),
          daycare,
          date: data.date && dayjs.utc(data.date),
          type: data?.type && data?.type
        };
      }
    },
    onCreateResource: {
      setBody: (data) => {
        const newBody = data;

        if (!data.date) {
          newBody.date = dayjs.utc(selectedDay).add(2, 'hour');
        } else {
          newBody.date = dayjs.utc(data.date).add(2, 'hour');
        }

        return {
          ...newBody,
          daycare,
          ...(['REGULAR', 'PLANNING'].includes(newBody.type)
            ? {
                regulars: (newBody.regulars || []).map((slot) => ({
                  start_time: dayjs
                    .utc(slot.times[0])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(slot.times[0]).hour())
                    .minute(dayjs(slot.times[0]).minute()),
                  end_time: dayjs
                    .utc(slot.times[1])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(slot.times[1]).hour())
                    .minute(dayjs(slot.times[1]).minute()),
                  manual:
                    selectedEmployee.week_management === 'MANUAL' &&
                    type === 'PLANNING'
                }))
              }
            : {
                times: [
                  dayjs
                    .utc(newBody.times[0])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(newBody.times[0]).hour())
                    .minute(dayjs(newBody.times[0]).minute()),
                  dayjs
                    .utc(newBody.times[1])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(newBody.times[1]).hour())
                    .minute(dayjs(newBody.times[1]).minute())
                ]
              })
        };
      }
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`plannings.employees.modal.title.${purpose}.${type}`)} ${
        selectedEmployee?.first_name
      } ${selectedEmployee?.last_name}`}
      width={700}
      footer={false}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="employee-events/event"
        resource="employee-event"
        closeModal={closeModal}
        withGetResource
        id={recorderId}
        config={config}
        withoutPageHeaderCustom
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Modal>
  );
};

EventModal.propTypes = {
  eventId: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    week_management: PropTypes.string,
    _id: PropTypes.string
  }),
  purpose: PropTypes.string,
  type: PropTypes.string.isRequired,
  selectedDay: PropTypes.instanceOf(dayjs).isRequired,
  recorderId: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  referenceDay: PropTypes.arrayOf(PropTypes.shape({}))
};

EventModal.defaultProps = {
  selectedEmployee: null,
  purpose: null,
  eventId: null,
  refresh: false,
  recorderId: null,
  setRefresh: null,
  referenceDay: null
};

export default EventModal;
