import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';
import Girl from '../../assets/images/icons/genderGirl.png';
import Boy from '../../assets/images/icons/genderBoy.png';
import CanPick from '../../assets/images/icons/canPickIcon.png';
import CantPick from '../../assets/images/icons/cantPickIcon.png';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('parents.list.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('parents.list.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('parents.list.children'),
      render: (record) =>
        (record?.children || [])?.map(({ childData }) => {
          const { first_name, last_name, parents, gender } = childData;
          const parent = parents.find((item) => item.parent === record._id);
          const icon = parent.pickup_authorization ? (
            <img
              src={CanPick}
              alt="can-pick-logo"
              style={{ width: 16, height: 16 }}
            />
          ) : (
            <img
              src={CantPick}
              alt="cant-pick-logo"
              style={{ width: 16, height: 16 }}
            />
          );

          const TooltipContent = (
            <Row style={{ flexDirection: 'column' }}>
              <Col>{`${first_name} ${last_name}`}</Col>
              <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t(
                  `pre-registrations.form.relationships.${parent.relationship}`
                )}
                {icon}
              </Col>
            </Row>
          );
          return (
            <Tooltip title={TooltipContent}>
              {gender === 'GIRL' ? (
                <img
                  src={Girl}
                  alt="gender-girl-logo"
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />
              ) : (
                <img
                  src={Boy}
                  alt="gender-boy-logo"
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />
              )}
            </Tooltip>
          );
        })
    },
    {
      title: t('parents.list.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (_, { mobile_phone_number: { country_code, number } }) =>
        country_code && number && `${country_code} ${number}`
    }
  ];
};
