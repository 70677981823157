import { Col, Row, Select } from 'antd';

/**
 * Render function for the header of a calendar component, allowing selection of month and year.
 *
 * @function
 *
 * @param {Object} props - The props object containing various parameters.
 * @param {moment.Moment} props.value - The current selected date value.
 * @param {Function} props.onChange - Function to handle changes in the selected date value.
 *
 * @returns {JSX.Element} JSX representing the header of the calendar component.
 */
export const headerRender = ({ value, onChange }) => {
  const selectStart = 0;
  const selectEnd = 12;
  const monthOptions = [];

  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    const monthMoment = value.clone().month(i); // Ensures correct month names
    months.push(localeData.monthsShort(monthMoment));
  }

  for (let index = selectStart; index < selectEnd; index += 1) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    );
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 2; i < year + 4; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  return (
    <Row
      justify="end"
      align="middle"
      style={{
        padding: '16px 12px 16px 16px'
      }}
      gutter={[0, 12]}
    >
      <Col
        xs={24}
        xl={4}
        xxl={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          className="my-year-select"
          onChange={(newYear) => {
            const updatedDate = value.clone().year(newYear);
            onChange(updatedDate);
          }}
          value={String(year)}
        >
          {options}
        </Select>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          value={String(month)}
          onChange={(selectedMonth) => {
            const updatedDate = value
              .clone()
              .month(parseInt(selectedMonth, 10));
            onChange(updatedDate);
          }}
          style={{ marginLeft: 8 }}
        >
          {monthOptions}
        </Select>
      </Col>
    </Row>
  );
};
