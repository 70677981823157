import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Modal, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const USERS_URL = '/users';

/**
 * Component for creating or updating user records.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.purpose - The purpose of the form (create or update).
 * @param {Object} props.record - The user record to be updated (if purpose is 'update').
 * @param {Array} props.daycares - The list of daycares.
 * @param {Function} props.setRefresh - Function to set the refresh state.
 * @param {boolean} props.refresh - Boolean indicating whether to refresh data.
 * @returns {JSX.Element} The JSX element representing the form for creating or updating user records.
 */
export const CreateUpdateUser = ({
  purpose,
  record,
  refresh,
  setRefresh,
  daycares
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const { fields, isFieldsLoading, fileList } = useFields(
    purpose,
    record,
    daycares,
    form
  );
  const {
    user,
    setUser,
    dispatchAPI,
    daycare,
    setRefreshCompletedDaycare,
    configurationComplete
  } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && dayjs(data.date_of_birth)
      })
    },
    onGetResourceFromModal: {
      setFields: () => {
        const jsonString = JSON.stringify(record.photo);
        const base64Encoded = btoa(unescape(encodeURIComponent(jsonString)));
        return {
          ...record,
          photo: base64Encoded
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data
        };
      }
    }
  };

  const customSubmit = async (objective, body) => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      if (fileList.length) {
        fileList.forEach((file) => formData.append('photo', file));
      }

      if (objective === 'create') {
        formData.append('values', JSON.stringify({ ...body, daycare }));
      } else {
        formData.append('values', JSON.stringify({ ...body }));
      }

      const url =
        objective === 'create'
          ? `${USERS_URL}?daycare=${daycare}`
          : `${USERS_URL}/${record._id}`;
      await dispatchAPI(objective === 'create' ? 'POST' : 'PATCH', {
        url,
        body: formData
      });
      if (body.role === 'users:MANAGER' && !configurationComplete) {
        setRefreshCompletedDaycare((prev) => !prev);
      }
      closeModal(false);
      antdMessage.success(
        objective === 'create'
          ? `${t('success.messages.email_sent')} ${body.email}`
          : t('success.messages.user_updated')
      );
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t(`users.form.title.${purpose}`)}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        customFormInstance={form}
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        baseUrl="users"
        resource="users"
        config={config}
        withGetResource={false}
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={record}
      />
    </Modal>
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string,
    user_values: PropTypes.shape({}),
    photo: PropTypes.string
  }),
  daycares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

CreateUpdateUser.defaultProps = {
  purpose: '',
  record: {
    _id: '',
    user_values: {}
  },
  setRefresh: () => {},
  refresh: false
};
