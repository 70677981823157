import { Avatar, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

/**
 * List content
 * @function
 * @name listContent
 * @param {object} user User object
 * @param {object} t i18n translation object
 * @description List content for profile page
 * @param {string} user.first_name User first name
 * @param {string} user.last_name User last name
 * @param {string} user.role User role
 * @param {object} user.mobile_phone_number User mobile phone number
 * @param {string} user.mobile_phone_number.number User mobile phone number
 * @param {string} user.mobile_phone_number.country_code User mobile phone number
 * @param {string} user.email User email
 * @param {string} user.photo User photo url
 * @param {string} user.code User pickup code
 * @param {string} token - User token.
 * @returns {Array} List content with label, content and span for each key
 */
const listContent = (
  { first_name, last_name, role, mobile_phone_number, email, photo, code },
  t,
  token
) => [
  {
    label: '',
    content: photo ? (
      <Avatar
        size={80}
        src={
          photo
            ? `${process.env.REACT_APP_API_URL}/users/get-img-url/${photo._id}/${token}`
            : ''
        }
      />
    ) : (
      <Avatar size={80} />
    ),
    span: 3
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 1
  },
  {
    label: 'profile.form.phone_number',
    span: 1,
    content:
      mobile_phone_number && mobile_phone_number.number ? (
        <div>
          {`${
            mobile_phone_number.country_code
          } ${mobile_phone_number.number.slice(
            0,
            1
          )} ${mobile_phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 2
  },
  ...(![
    'admins:SUPER-ADMIN',
    'admins:CORPORATE',
    'admins:REGIONAL-MANAGER',
    'users:MANAGER'
  ].includes(role)
    ? [
        {
          label: 'profile.personalInfo.code',
          content: code || '-',
          span: 3
        }
      ]
    : [])
];

export default listContent;
