/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useFields = () => [
  {
    label: 'label',
    name: 'label',
    rules: [{ required: true }]
  }
];
