import React, { createContext, useContext, useEffect, useState } from 'react';
import { message as antdMessage } from 'antd';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const InvoicingContext = createContext({});

/**
 * Provider component for the InvoicingContext, managing state and functionality related to invoicing.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - Child components wrapped by the provider.
 * @returns {React.ReactNode} JSX for the InvoicingContextProvider component.
 */
export const InvoicingContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare, user } = useAuthContext();
  const [formValues, setFormValues] = useState(null);
  const { message } = useErrorMessage();
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const [idInvoice, setIdInvoice] = useState('');
  const [invoiceEnums, setInvoicesEnums] = useState();
  const [customerAccountEnums, setCustomerAccountEnums] = useState({});
  const [customerAccountOptionsLoading, setCustomerAccountOptionsLoading] =
    useState(false);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [invoicesOptionsLoading, setInvoicesOptionsLoading] = useState(false);
  const [customerAccountIds, setCustomerAccountIds] = useState([]);
  const [filterMonth, setFilterMonth] = useState(dayjs());
  const [statuses, setStatuses] = useState([]);
  const [selectedCustomerAccountTypes, setSelectedCustomerAccountTypes] =
    useState([]);
  const [childs, setChilds] = useState([]);

  const getChilds = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/child?daycare=${daycare}`
      });
      setChilds(data);
    } catch (e) {
      message(e);
    }
  };

  const getInvoicesEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setInvoicesEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomerAccountEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customer-accounts/enums'
      });
      setCustomerAccountEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (user.role !== 'guests:PARENT') {
        setCustomerAccountOptionsLoading(true);
        await getCustomerAccountEnums();
        setCustomerAccountOptionsLoading(false);
      }
    })();
  }, [daycare]);

  const getCustomerAccount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customer-accounts?daycare=${daycare}`
      });
      setCustomerAccounts(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (user.role !== 'guests:PARENT') {
        setInvoicesOptionsLoading(true);
        await getInvoicesEnums();
        await getCustomerAccount();
        await getChilds();
        setInvoicesOptionsLoading(false);
      }
    })();
  }, []);

  const deleteRejection = async (id, callback) => {
    try {
      await dispatchAPI('DELETE', { url: `/rejection/${id}` });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteTransfer = async (id, callback) => {
    try {
      await dispatchAPI('DELETE', { url: `/transfer/${id}` });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadTransfer = async (id, type, callback, record) => {
    try {
      if (type === 'csv') {
        const result = await dispatchAPI('GET', {
          url: `/transfer/${id}/generate/${type}`
        });
        const blob = new Blob([result.data], {
          type: 'text/csv;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${format(new Date(), 'yyyyMMdd_hhmmss')}_vrmnt.csv`;
        a.click();
      } else {
        let data = record?.xml_content;
        if (!data) {
          const result = await dispatchAPI('GET', {
            url: `/transfer/${id}/generate/${type}`
          });
          data = result.data;
        }

        const blob = new Blob([data], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${format(new Date(), 'yyyyMMdd_hhmmss')}_vrmt.xml`;
        a.click();
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  const onPrintAction = async (id) => {
    setIdInvoice(id);
    setIsSpinLoading(true);
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `/invoices/${id}/generate`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });
      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition'].length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      antdMessage.success(t('success.messages.download'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIdInvoice('');
    setIsSpinLoading(false);
  };

  return (
    <InvoicingContext.Provider
      value={{
        downloadTransfer,
        deleteRejection,
        deleteTransfer,
        onPrintAction,
        isSpinLoading,
        idInvoice,
        invoiceEnums,
        customerAccounts,
        invoicesOptionsLoading,
        customerAccountIds,
        setCustomerAccountIds,
        filterMonth,
        setFilterMonth,
        statuses,
        setStatuses,
        customerAccountEnums,
        customerAccountOptionsLoading,
        selectedCustomerAccountTypes,
        setSelectedCustomerAccountTypes,
        setFormValues,
        formValues,
        daycare,
        childs
      }}
    >
      {children}
    </InvoicingContext.Provider>
  );
};

export default () => useContext(InvoicingContext);
