import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Row, Space, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Datatable } from '../../../../components';
import { useColumns } from './columns';
import { CreateImportModal } from './CreateImportModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Displays a datatable for managing cash balance imports and provides options to download a template
 * for new imports and to add new imports through a modal.
 *
 * This component utilizes the `Datatable` component to list existing cash balance imports and offers
 * functionalities to refresh the datatable. Users can download a predefined template for imports or
 * initiate a new import process via a modal window. The component relies on the `useColumns` custom
 * hook to define the columns for the datatable based on the current refresh state and the `useAuthContext`
 * to access the daycare context. The visibility of the import modal is controlled by a local state.
 *
 * @component
 * @returns {JSX.Element} A layout consisting of buttons for downloading templates and adding new imports,
 * followed by a datatable of cash balance imports and a modal for creating or importing new records.
 */
export const CashBalanceImports = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { dateFilter, refresh, setRefresh } = useAnalysisContext();
  const { daycare } = useAuthContext();
  const [visible, setVisible] = useState(false);

  const getBalanceTemplate = async () => {
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `daycares/balance-template`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });
      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition'].length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      antdMessage.success(t('success.messages.download'));
    } catch (e) {
      message(e);
    }
  };

  const columns = useColumns(refresh, setRefresh);

  return (
    <>
      <Row justify="end">
        <Space>
          <Button onClick={getBalanceTemplate}>
            <DownloadOutlined />
            {t('analysis.table.download_template')}
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setVisible(!visible)}
          >
            {t('analysis.table.new_import')}
          </Button>
        </Space>
      </Row>
      <Datatable
        resourceName="balance-imports"
        forceRefresh={refresh}
        setRefresh={setRefresh}
        columns={columns}
        customActionColumn
        extraQuery={`daycare=${daycare}${
          dateFilter && dateFilter !== 'NaN'
            ? `&year=${dayjs(dateFilter).year()}`
            : ''
        }`}
        populate="author,file"
        scroll={{ x: 1000 }}
      />
      <CreateImportModal
        refresh={refresh}
        setRefresh={setRefresh}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};
