import React, { useEffect, useState, createContext, useContext } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';
import { days } from '../utils/days';

export const ReferenceWeekContext = createContext();

export const ReferenceWeekProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [selectedDaycares, setSelectedDaycares] = useState([]);
  const [daycaresOpeningDays, setDaycaresOpeningDays] = useState([]);
  const [daycareOpeningDays, setDaycareOpeningDays] = useState([]);
  const [referenceWeeks, setReferenceWeeks] = useState([
    [
      { day: 'monday', time_slots: [] },
      { day: 'tuesday', time_slots: [] },
      { day: 'wednesday', time_slots: [] },
      { day: 'thursday', time_slots: [] },
      { day: 'friday', time_slots: [] }
    ]
  ]);

  const getOpeningHours = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/hours?daycare=${
          user?.last_selected_daycare || user?.daycare || null
        }`
      });

      const openingDays = days
        .map((day) => {
          const daycareOpeningDay = data.find(
            (daycareDay) => daycareDay.opening_day === day
          );
          return daycareOpeningDay;
        })
        .filter(Boolean);

      setDaycareOpeningDays(openingDays);
    } catch (e) {
      message(e);
    }
  };

  const getDaycaresOpeningDays = async () => {
    try {
      if (!selectedDaycares.length) {
        setDaycaresOpeningDays([]);
        return;
      }

      const { data } = await dispatchAPI('GET', {
        url: `/daycares/opening-days?daycares=${selectedDaycares.join(',')}`
      });

      setDaycaresOpeningDays(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOpeningHours();
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      await getDaycaresOpeningDays();
    })();
  }, [selectedDaycares]);

  return (
    <ReferenceWeekContext.Provider
      value={{
        referenceWeeks,
        setReferenceWeeks,
        daycareOpeningDays,
        setSelectedDaycares,
        daycaresOpeningDays
      }}
    >
      {children}
    </ReferenceWeekContext.Provider>
  );
};

/**
 * A custom hook for accessing the PreregistrationContext.
 *
 * @function
 *
 * @returns {Object} The context values for managing preregistration state.
 */
export const useReferenceWeekContext = () => {
  const context = useContext(ReferenceWeekContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
