import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload
} from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import {
  drawerFormLayout,
  drawerTailFormLayout
} from '../../../../../utils/constants/formLayout';

const { Option } = Select;

/**
 * DocumentModal provides an interface for users to add or edit documents associated with a child.
 * This modal allows users to upload files, set document titles, types, and visibility preferences.
 *
 * @function
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.refresh - Flag to indicate a component rerender.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @param {string} props.source - Source/type of the document.
 * @param {string} props.childName - Name of the child associated with the document.
 * @param {string} props.purpose - Indicates the purpose of the modal (e.g., 'edit').
 * @param {Object} props.docToUpdate - Document to be updated if editing.
 *
 * @returns {ReactElement} Rendered DocumentModal
 */
export const DocumentModal = ({
  refresh,
  setRefresh,
  source,
  childName,
  purpose,
  docToUpdate
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const postDocument = async (body) => {
    setIsSubmitting(true);

    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('documents', file);
    });
    formData.append('values', JSON.stringify(body));

    try {
      await dispatchAPI('PATCH', {
        url: `children/${id}/post-documents`,
        body: formData
      });
      setIsSubmitting(false);
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      setIsSubmitting(false);

      message(e);
    }
  };

  const patchDocument = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `children/${id}/patch-document/${docToUpdate._id}`,
        body
      });
      setIsSubmitting(false);
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = (body) => {
    (async () => {
      if (purpose === 'edit') {
        await patchDocument(body);
      } else {
        await postDocument(body);
      }
    })();
  };

  const checkboxOptions = [
    {
      value: 'PARENTS',
      label: t(`children.documents.modal.parents`)
    },
    {
      value: 'TEAMS',
      label: t(`children.documents.modal.teams`)
    }
  ];

  useEffect(() => {
    if (isModalOpenedLocaly && purpose !== 'edit') {
      form.setFieldsValue({
        type: source
      });
    }
  }, [isModalOpenedLocaly, purpose, source, form]);

  useEffect(() => {
    if (purpose === 'edit' && isModalOpenedLocaly && docToUpdate) {
      form.setFieldsValue(docToUpdate);
    }
  }, [purpose, isModalOpenedLocaly, docToUpdate]);

  const onCancel = () => {
    setIsModalOpenedLocaly(!isModalOpenedLocaly);
  };

  const draggerProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const existingFileName = fileList.some(
        (document) => document.name === file.name
      );
      if (existingFileName) return message(t('existing_file_name'));
      const fileExtension = file.name.split('.').pop();
      if (['png', 'PNG', 'jpg', 'JPG', 'pdf', 'PDF'].includes(fileExtension)) {
        setFileList([...fileList, file]);
      } else {
        message('Not a PNG or JPG file.');
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <Modal
      open={isModalOpenedLocaly}
      footer={null}
      title={`${t('children.documents.modal.title')} ${childName}`}
      onCancel={onCancel}
    >
      <Form form={form} onFinish={handleSubmit} {...drawerFormLayout}>
        {purpose !== 'edit' && (
          <Form.Item name={['file']} label={t('children.documents.modal.file')}>
            <Upload {...draggerProps}>
              <Button icon={<UploadOutlined />}>
                {t('children.documents.modal.load_file')}
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          name={['document_title']}
          label={t('children.documents.modal.name')}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['type']} label={t('children.documents.modal.type')}>
          <Select>
            <Option value="ATTESTATION">
              {t(`children.documents.divider.ATTESTATION`)}
            </Option>
            <Option value="OTHERS">
              {t(`children.documents.divider.OTHERS`)}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={['visibility']}
          label={t('children.documents.modal.visibility')}
        >
          <Checkbox.Group
            options={checkboxOptions}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
        </Form.Item>
        <Form.Item {...drawerTailFormLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onCancel}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

DocumentModal.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  childName: PropTypes.string,
  source: PropTypes.string,
  handleModal: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  purpose: PropTypes.string,
  docToUpdate: PropTypes.shape({
    _id: PropTypes.string
  })
};

DocumentModal.defaultProps = {
  refresh: false,
  setRefresh: null,
  childName: undefined,
  source: undefined,
  handleModal: null,
  open: false,
  setOpen: null,
  purpose: undefined,
  docToUpdate: null
};
