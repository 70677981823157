import React from 'react';
import { Badge, Button, Dropdown, List, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import BellIcon from '../../assets/images/icons/noto_bell.svg';
import { NotificationMenuItem } from './NotificationMenuItem';

/**
 * `Notifications` is a React component that renders a dropdown list of user notifications.
 * It utilizes the `useSocketContext` to fetch and manage notifications, including marking them as read or deleting them.
 *
 * The component handles loading states and displays a bell icon with a badge showing the count of unread notifications.
 * Each notification is rendered using the `NotificationMenuItem` component.
 *
 * This component also integrates with `useAuthContext` for user data and `useErrorMessage` for error handling.
 *
 * @returns {JSX.Element | null} A dropdown list of notifications or null if there are no notifications.
 */
export const Notifications = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const {
    notifications,
    refreshNotifications,
    setRefreshNotifications,
    initLoading,
    loadMore,
    notificationsList,
    loading,
    notificationsUnreadCount
  } = useSocketContext();

  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/${id}/delete`
      });
      setRefreshNotifications(!refreshNotifications);
    } catch (e) {
      message(e);
    }
  };

  const readNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/${id}/read`
      });
      setRefreshNotifications(!refreshNotifications);
    } catch (e) {
      message(e);
    }
  };

  const sortedNotificationsList = (notificationsList || []).sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const menu = !loading ? (
    <List
      className="notifications-list"
      loading={initLoading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={sortedNotificationsList}
      renderItem={(item) =>
        item?.item?.type && (
          <NotificationMenuItem
            notification={item}
            onDeleteNotification={deleteNotification}
            onReadNotification={readNotification}
            userID={user?._id}
            t={t}
          />
        )
      }
    />
  ) : (
    <Row
      className="loading-notifications-list"
      justify="center"
      align="center"
      style={{ flexDirection: 'column', textAlign: 'center' }}
    >
      {t('notifications.form.loading')}
      <Spin />
    </Row>
  );

  return notifications?.length ? (
    <Dropdown trigger={['hover']} overlay={menu}>
      <Button className="notification-btn">
        <Badge count={notificationsUnreadCount} status="warning" size="small">
          <img src={BellIcon} alt="Notifications" />
        </Badge>
      </Button>
    </Dropdown>
  ) : null;
};
