import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Steps, Checkbox } from 'antd';
import { SignatureCanvas, ContentCustom } from '../../components';
import { FallbackComponent } from '../../components/FallbackModal/FallbackComponent';
import { useSignaturesMethods } from './SignaturesMethods';
import { Document } from './Document';
import { Success } from './Success';

/**
 * Component for handling signatures.
 * This component returns the parent signatures component.
 *
 * @param {boolean} update_sepa_mandate - A boolean to determine if the sepa mandate should be updated
 * @component
 * @returns {JSX.Element} An error modal if there is an error, a document, a signature canvas or a success message when all the documents are signed.
 */
export const Signatures = ({ update_sepa_mandate }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { getDocuments, postSignatures } = useSignaturesMethods();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [contractInfos, setContractInfos] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [signedFiles, setSignedFiles] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentRead, setDocumentRead] = useState(false);
  const [displaySignature, setDisplaySignature] = useState(false);
  const [signaturesDone, setSignaturesDone] = useState(false);
  const [modal, setModal] = useState(null);

  const getFiles = async () => {
    const {
      error,
      contract_number,
      start_date,
      files,
      status,
      new_sepa_mandate_waiting
    } = await getDocuments(token);

    if (error) {
      setModal(<FallbackComponent />);
    } else {
      setContractInfos({
        contract_number,
        start_date
      });

      if (!new_sepa_mandate_waiting && status === 'VALIDATED') {
        setSignaturesDone(true);
      } else {
        setSteps(
          files?.map((file) => ({
            title: file.metadata.originalName,
            key: file._id,
            file
          }))
        );
      }
    }
  };

  const nextStep = () => {
    setPageNumber(1);
    setCurrentStep(currentStep + 1);
    setDisplaySignature(false);
    setDocumentRead(false);
  };

  const handleCheck = (e) => {
    setDisplaySignature(e.target.checked);
  };

  const handleFinish = (image) => {
    setSignedFiles((prev) => [
      ...prev,
      {
        ...currentFile,
        read_and_approved: true,
        signature: image
      }
    ]);

    if (currentStep < steps.length - 1) {
      nextStep();
    }
  };

  const contentToDisplay = () => {
    if (signaturesDone) {
      return (
        <Success
          data={contractInfos}
          update_sepa_mandate={update_sepa_mandate}
        />
      );
    }
    return (
      <>
        <Steps current={currentStep} items={steps} />
        {documentRead ? (
          <Flex
            align="center"
            justify="center"
            vertical
            style={{ padding: 32 }}
          >
            <Checkbox onChange={handleCheck}>
              {t('signatures.read_and_approved')}
            </Checkbox>
            {displaySignature && (
              <SignatureCanvas
                wrapperStyle={{ marginTop: 16 }}
                handleFinish={handleFinish}
                loading={loading}
              />
            )}
          </Flex>
        ) : (
          currentFile && (
            <Document
              currentFile={currentFile}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setDocumentRead={setDocumentRead}
            />
          )
        )}
      </>
    );
  };

  useEffect(() => {
    (async () => {
      await getFiles();
    })();
  }, [token]);

  useEffect(() => {
    if (steps[currentStep]) {
      setCurrentFile(steps[currentStep].file);
    }
  }, [currentStep, steps]);

  useEffect(async () => {
    if (signedFiles.length > 0 && signedFiles.length === steps.length) {
      setLoading(true);

      const url = update_sepa_mandate
        ? '/contracts/update-sepa-mandate'
        : '/contracts/signatures';

      const success = await postSignatures(token, signedFiles, url);

      setLoading(false);

      if (success) {
        setSignaturesDone(true);
      } else {
        setModal(<FallbackComponent />);
      }
    }
  }, [signedFiles]);

  return (
    <ContentCustom
      style={{
        maxHeight: '100vh',
        overflow: 'hidden auto',
        margin: 0,
        padding: 32
      }}
    >
      {modal}
      {contentToDisplay()}
    </ContentCustom>
  );
};

Signatures.propTypes = {
  update_sepa_mandate: PropTypes.bool
};

Signatures.defaultProps = {
  update_sepa_mandate: false
};
