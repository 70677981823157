import { useState, useCallback, useEffect } from 'react';
import { Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * Custom hook for managing customer account fields.
 * This hook fetches customer accounts from the backend and provides the necessary fields for forms.
 *
 * @hook
 * @returns {Object} An object containing customer account fields.
 */
export const useCustomerAccountFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [enums, setEnums] = useState({});

  const getCustomerAccounts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customer-accounts?daycare=${daycare}`
      });
      setCustomerAccounts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customer-accounts/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCustomerAccounts();
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const existingCustomerAccountFields = [
    {
      name: ['customer'],
      rules: [{ required: false }],
      input: (
        <Select loading={isFieldsLoading}>
          {customerAccounts.map((customerAccount) => (
            <Option key={customerAccount._id} value={customerAccount._id}>
              {t(`${customerAccount.label}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const newCustomerAccountFields = [
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['customer_nature'],
      rules: [{ required: true }],
      input: (
        <Radio.Group>
          {enums?.customer_natures?.map((nature) => (
            <Radio key={nature} value={nature}>
              {t(`customer-account.form.${nature}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    }
  ];

  return {
    existingCustomerAccountFields,
    newCustomerAccountFields,
    isFieldsLoading
  };
};
