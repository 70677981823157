import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Button, Flex, Typography } from 'antd';
import { WarningOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useSuccessMessage } from '../../../../utils/successMessage';

const { Text } = Typography;

/**
 * FeesCardTitleAndButtons component - Displays the title and buttons for the fees card.
 *
 * @param {Function} setContract - Function to set the contract state.
 * @param {Object} contract - Contract object containing the new_sepa_mandate_waiting property.
 * @param {string} contract._id - Unique identifier of the contract.
 * @param {boolean} contract.new_sepa_mandate_waiting - Indicates if a new SEPA mandate is waiting to be activated.
 * @returns {JSX.Element} A component that displays the title and buttons for the fees card.
 */
export const FeesCardTitleAndButtons = ({ setContract, contract }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();

  const cancelNewSepaMandate = async () => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/contracts/${contract._id}/cancel-update-sepa-mandate`
      });
      setContract(data);
      successMessage('sepa_mandate_cancelled');
    } catch (error) {
      message(error);
    }
  };

  return (
    <Flex justify="space-between">
      <span>{t('contracts.show.fee')}</span>
      {contract.new_sepa_mandate_waiting && (
        <Flex gap="small">
          <Text>{t('contracts.show.new_sepa_mandate_waiting')}</Text>
          <Popconfirm
            title={t('contracts.show.cancel_new_sepa_mandate')}
            okText={t('buttons.validate')}
            okButtonProps={{ danger: true }}
            cancelText={t('buttons.cancel')}
            onConfirm={() => cancelNewSepaMandate()}
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              <CloseSquareOutlined color="red" />
            </Button>
          </Popconfirm>
        </Flex>
      )}
    </Flex>
  );
};

FeesCardTitleAndButtons.propTypes = {
  setContract: PropTypes.func,
  contract: PropTypes.shape({
    new_sepa_mandate_waiting: PropTypes.bool,
    _id: PropTypes.string
  })
};

FeesCardTitleAndButtons.defaultProps = {
  setContract: null,
  contract: null
};
