import dayjs from 'dayjs';

/**
 * Generates an array of month options from a specified date range.
 * Each option includes the numerical value of the month and its formatted name.
 *
 * @function
 *
 * @param {Object} dateRange - An object containing `start_date` and `end_date` to define the range.
 * @returns {Array} An array of objects, each representing a month in the range with `value` and `label` properties.
 */
export const generateMonthOptions = (dateRange) => {
  const options = [];
  let currentMonth = dayjs(dateRange.start_date);
  const endMonth = dayjs(dateRange.end_date);
  const today = dayjs().startOf('month');

  while (
    (currentMonth.isBefore(endMonth) ||
      currentMonth.isSame(endMonth, 'month')) &&
    (currentMonth.isBefore(today) || currentMonth.isSame(today, 'month'))
  ) {
    const yearName = currentMonth.year();
    let monthName = currentMonth.format('MMMM');
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

    options.push({
      value: currentMonth.format('DD-MM-YYYY'),
      label: `${monthName} ${yearName}`
    });

    currentMonth = currentMonth.add(1, 'month');
  }

  return options;
};
