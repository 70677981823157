import { useState, useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { CreateUpdateUser } from './CreateUpdateUser';
import { ExtraButtons } from './ExtraButtons';
import { useFormatter } from '../../utils/formatter';

/**
 * ListUsers component
 * @component ListUsers
 * @returns {object} JSX - ListUsers component elements
 */
export const ListUsers = () => {
  const { usersDataFormatter } = useFormatter();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [daycares, setDaycares] = useState([]);
  const [selectedDaycares, setSetselectedDaycares] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [enums, setEnums] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateUser
        key={modalKey}
        purpose={purpose}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        daycares={daycares}
        record={record}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  const columns = useColumns(handleModalBehaviour, setRefresh);

  const getDaycares = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/daycares/name' });
      setDaycares(data);
    } catch (error) {
      message(error);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getDaycares();
      await getEnums();
      setIsLoading(false);
    })();
  }, []);

  const onsearchRoles = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchDaycares = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      {modal}
      <ListResource
        customLoading={isLoading}
        resourceName="users"
        populate="daycare,photo"
        columns={columns}
        customActionColumn
        headers={headers}
        formatter={usersDataFormatter}
        resourceModelName="User"
        openModal
        showModal={() => handleModalBehaviour(undefined, 'create')}
        setIsModalOpen={setIsModalOpen}
        forceRefresh={refresh}
        extraQuery={`${
          selectedDaycares.length ? `&daycare=${selectedDaycares}` : ''
        }${selectedRoles.length ? `&role=${selectedRoles}` : ''}`}
        extraButtons={
          <ExtraButtons
            onsearchRoles={onsearchRoles}
            onsearchDaycares={onsearchDaycares}
            setSetselectedDaycares={setSetselectedDaycares}
            setSelectedRoles={setSelectedRoles}
            selectedRoles={selectedRoles}
            selectedDaycares={selectedDaycares}
            daycares={daycares}
            enums={enums}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
};
