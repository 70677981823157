import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Flex } from 'antd';
import {
  CloseOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ReferenceWeek } from '../../pre-registrations/ReferenceWeek';
import { ReferenceWeekContext } from '../../../contexts/ReferenceWeekContext';

/**
 * Component for rendering the contract form.
 * This component is rendered inside the pre-registration form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @prop {String} purpose String containing the purpose of the form.
 * @returns {React.Component} Contract form component
 */
export const PlanningForm = ({ handlePrevious, purpose, origin, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);

  return (
    <>
      {referenceWeeks?.map((_, index) => (
        <ReferenceWeek index={index} purpose={purpose} />
      ))}
      {referenceWeeks?.length < 10 && (
        <Button onClick={() => setReferenceWeeks([...referenceWeeks, []])}>
          {t('buttons.add_reference_week')}
        </Button>
      )}
      <Flex justify="end" gap="small">
        <Button
          type="link"
          danger
          onClick={() => {
            setReferenceWeeks([{}]);
            navigate(-1);
          }}
        >
          {t('buttons.cancel')}
          <CloseOutlined />
        </Button>
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        {origin !== 'CHILD' || purpose === 'create' ? (
          <Button type="primary" onClick={onFinish}>
            {t('buttons.next')}
            <ArrowRightOutlined />
          </Button>
        ) : (
          <Button type="primary" onClick={onFinish}>
            {t('buttons.validate')}
          </Button>
        )}
      </Flex>
    </>
  );
};

PlanningForm.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    contract: PropTypes.shape({
      reference_weeks: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }),
  origin: PropTypes.string,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }),
  onFinish: PropTypes.func.isRequired
};
PlanningForm.defaultProps = {
  initialValues: {},
  origin: undefined,
  form: null
};
