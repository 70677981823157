import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { FileTextOutlined, EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { listContent } from './listContent';
import { PDFviewer } from '../../components/PDFviewer/PDFviewer';

/**
 * `ShowTemplate` is a component that retrieves and displays a specific template document
 * based on the ID parameter from the URL. It fetches the document data from the server
 * and allows the user to generate a PDF version of the document.
 *
 * It utilizes various hooks such as `useTranslation` for internationalization, `useParams`
 * to get the ID parameter from the URL, and `useState` and `useEffect` to manage
 * the component's state and lifecycle respectively.
 *
 * @component
 * @returns {React.Element} A JSX element that displays the template document details and provides a button to generate a PDF version of the document.
 *
 * @example
 *
 * return (
 *   <ShowTemplate />
 * );
 */
export const ShowTemplate = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [template, setTemplate] = useState();
  const [templateBlob, setTemplateBlob] = useState();
  const [documentUrl, setDocumentUrl] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/${id}?populate=author`
      });
      setTemplate(data);
    } catch (e) {
      message(e);
    }
  };

  // UPDATE THE COMPONENT WITH A PDF VIEWER

  const generatePDF = () => {
    // Create a URL from the Blob object
    const url = URL.createObjectURL(templateBlob);

    // Use the URL to create a download link or display the PDF in the browser
    // Example: Create a download link
    const link = document.createElement('a');
    link.href = url;
    link.download = 'document.pdf';
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  const handleUpdateTemplate = () => {
    navigate(`/template-docs/edit/${encodeURIComponent(id)}`);
  };

  useEffect(() => {
    (async () => {
      try {
        await getTemplate();
        const { data } = await dispatchAPI('POST', {
          url: `template-documents/${id}/generateRawPDF`,
          responseType: 'arraybuffer' // Set the responseType to receive the response as an ArrayBuffer
        });

        // Create a Blob object from the ArrayBuffer
        const blob = new Blob([data], { type: 'application/pdf' });

        setTemplateBlob(blob);

        const url = URL.createObjectURL(blob);

        setDocumentUrl(url);
      } catch (e) {
        message(e);
      }
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={template?.title}
        extra={
          <>
            <Button onClick={generatePDF}>
              <FileTextOutlined />
              {t('template-documents.show.generate_PDF')}
            </Button>
            <Button onClick={handleUpdateTemplate}>
              <EditOutlined />
              {t('template-documents.edit_button')}
            </Button>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Card title={t('template-documents.show.infos')}>
              <DescriptionList
                data={listContent(template || {}, t)}
                translate
              />
            </Card>
          </Col>
          <Col xs={16}>
            <Card>
              <PDFviewer
                currentFile={documentUrl}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
