import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Select, message as Message } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { formItemLayout } from '../../../utils/constants/formLayout';

const { Option } = Select;
const { success: SuccessMessage } = Message;

/**
 * CopyConfigModal Component
 *
 * This component renders a modal that allows users to copy configurations
 * from one daycare to another. It fetches the list of available daycares,
 * excluding the current one, and provides a form to select the target daycare
 * and submit the copy request.
 *
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.isModalOpen - Flag indicating whether the modal is open
 * @param {Function} props.setIsModalOpen - Function to set the modal open state
 * @param {Function} props.setRefresh - Function to trigger a refresh after successful configuration copy
 * @returns {JSX.Element} The rendered modal component
 *
 */
export const CopyConfigModal = ({
  isModalOpen,
  setIsModalOpen,
  setRefresh
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, daycare } = useAuthContext();
  const [daycares, setDaycares] = useState([]);
  const [selectedDaycare, setSelectedDaycare] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const onFinish = async () => {
    try {
      setIsSending(true);
      await dispatchAPI('POST', {
        url: 'daycares/copy-configuration',
        body: {
          targetDaycareId: daycare,
          sourceDaycareId: selectedDaycare
        }
      });
      form.resetFields();
      setRefresh(true);
      setIsModalOpen(false);
      SuccessMessage(t('success.messages.configuration_copied'));
    } catch (error) {
      message(error);
    } finally {
      setIsSending(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const getDaycares = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/daycares/name'
      });
      setDaycares(
        (data || [])
          .filter(({ _id }) => _id !== daycare)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ _id, name }) => ({ _id, name }))
      );
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getDaycares();
    })();
  }, []);

  const filterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title={t('configurations.invoicing.copy_config_modal.title')}
      onOk={onFinish}
      open={isModalOpen}
      onCancel={onCancel}
      okButtonProps={{ loading: isSending }}
    >
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="daycare"
          label={t('configurations.invoicing.copy_config_modal.select_daycare')}
        >
          <Select
            loading={isLoading}
            onChange={(value) => setSelectedDaycare(value)}
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
          >
            {daycares.map(({ _id, name }) => (
              <Option value={_id}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CopyConfigModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};
