import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ArrivalsOrDepartures } from './ArrivalsOrDepartures';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * A component for displaying the attendance check page.
 *
 * This component renders a page header and two columns of `ArrivalsOrDepartures` components,
 * one for arrivals and the other for departures. It uses Ant Design's `Row` and `Col` for layout
 * and `useTranslation` for internationalization of the title. The `ContentCustom` and
 * `PageHeaderCustom` components are used for consistent styling and layout across the application.
 *
 * @component
 * @returns {JSX.Element} The JSX rendering of the attendance check page.
 *
 * @example
 * return (
 *   <AttendanceCheck />
 * )
 */
export const AttendanceCheck = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState({ arrivals: {}, departures: {} });
  const [refresh, setRefresh] = useState(false);

  const getEvents = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `pickup-times/events?daycare=${daycare}&date=${dayjs().format(
          'YYYY-MM-DD'
        )}`
      });
      setEvents(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEvents();
      setIsLoading(false);
    })();
  }, [refresh]);
  return (
    <>
      <PageHeaderCustom title={t('attendance-check.title')} />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <ArrivalsOrDepartures
              resourceName="arrivals"
              data={events.arrivals}
              isLoading={isLoading}
              setRefresh={setRefresh}
            />
          </Col>
          <Col xs={24} md={12}>
            <ArrivalsOrDepartures
              resourceName="departures"
              data={events.departures}
              isLoading={isLoading}
              setRefresh={setRefresh}
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
