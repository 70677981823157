import { Button, Divider, Flex, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ParentPlanning } from './ParentPlanning';

/**
 * ParentPlanningTabs Component
 *
 * This component displays tabs for each child of the parent, allowing them to view the planning for each child.
 *
 * @component
 * @returns {JSX.Element} The rendered ParentPlanningTabs component.
 */
export const ParentPlanningTabs = ({
  parentsChildren,
  isLoading,
  setIsLoading,
  setScreen
}) => {
  const { t } = useTranslation();
  const items = (parentsChildren || []).map((child) => ({
    key: child._id,
    label: `${child.first_name}`,
    children: (
      <ParentPlanning
        child={child}
        isPlanningLoading={isLoading}
        setIsPlanningLoading={setIsLoading}
      />
    )
  }));

  const buttonsLabel = [
    t('parents.home.send_document'),
    t('parents.home.add_absence'),
    t('parents.home.add_occasional_reservation')
  ];

  return (
    <>
      <Tabs type="card" items={items} />
      <Divider />
      <Flex vertical gap="small">
        {buttonsLabel.map((label, index) => (
          <Button
            block
            key={label}
            onClick={() => setScreen(index + 1)}
            style={{ margin: 0 }}
          >
            {label}
          </Button>
        ))}
      </Flex>
    </>
  );
};

ParentPlanningTabs.propTypes = {
  parentsChildren: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setScreen: PropTypes.func
};

ParentPlanningTabs.defaultProps = {
  parentsChildren: null,
  isLoading: false,
  setIsLoading: null,
  setScreen: null
};
