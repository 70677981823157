import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Card, Row, Col } from 'antd';
import { DescriptionList } from '../../../../components';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useContractListContent } from './listContent';
import { DocumentTab } from './DocumentTab';
import { ContractPlanning } from './ContractPlanning';
import { ReferenceWeekContext } from '../../../../contexts/ReferenceWeekContext';
import { BillingTab } from './BillingTab';

/**
 * useTabContent
 *
 * @hook
 * @description Custom hook that handles the content of the tabs in the contract show page
 * @returns {object} items
 */
export const useTabContent = () => {
  const { childID, contractID } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [contract, setContract] = useState({});
  const [child, setChild] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { contractInformation } = useContractListContent(contract);
  const { setReferenceWeeks } = useContext(ReferenceWeekContext);

  const getChild = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/children/${childID}?populate=parents,parents.user`
      });
      setChild(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const getContract = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${contractID}?populate=customer_account,documents.file,rib`
      });
      setContract(data);
      setReferenceWeeks(data.reference_weeks);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getContract();
      await getChild();
    })();
  }, [refresh]);

  useEffect(() => {
    const updatedContract = { ...contract, parents: child.parents };
    setContract({ ...updatedContract });
  }, [child]);

  const items = [
    {
      key: '1',
      label: t('contracts.tabsTitle.terms'),
      children: (
        <Row>
          <Col span={18}>
            <Card>
              <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
                <DescriptionList
                  layout="vertical"
                  data={contractInformation}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      key: '2',
      label: t('contracts.tabsTitle.planning'),
      children: (
        <Col className="contract-planning">
          <ContractPlanning contract={contract} />
        </Col>
      )
    },
    {
      key: '3',
      label: t('contracts.tabsTitle.billing'),
      children: (
        <BillingTab
          contract={contract}
          setContract={setContract}
          child={child}
        />
      )
    },
    {
      key: '4',
      label: t('contracts.tabsTitle.documents'),
      children: (
        <DocumentTab
          documents={contract?.documents}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    }
  ];

  return { items };
};
