import { useTranslation } from 'react-i18next';
import { Tabs, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { useTabContent } from './useTabContent';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import TerminatedModal from './TerminatedModal';

/**
 * ShowContract component - Displays the contract details of a pre-registration.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowContract = () => {
  const { t } = useTranslation();
  const { items } = useTabContent();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { contractID } = useParams();
  const [contract, setContract] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const isAdmin = user?.role.startsWith('admins');

  const getContract = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/${contractID}?fields=number,status,terminated_date`
      });
      setContract(data);
    } catch (e) {
      message(e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getContract();
    })();
  }, [contractID, refresh]);

  return (
    <>
      {isModalOpen && (
        <TerminatedModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          contract={contract}
          setRefresh={setRefresh}
        />
      )}
      <PageHeaderCustom
        title={`${t('contract.title')} ${contract?.number || ''}`}
        extra={
          isAdmin &&
          contract?.status === 'ACTIVE' &&
          !contract?.terminated_date && (
            <Button type="primary" onClick={openModal}>
              <FileExcelOutlined style={{ fontSize: 18, color: 'red' }} />
              {t('contracts.show.buttons.terminated')}
            </Button>
          )
        }
      />
      <ContentCustom>
        <Tabs defaultActiveKey="1" items={items} type="card" />
      </ContentCustom>
    </>
  );
};
