/**
 * Mapping of notification types to their corresponding icons, links, and message keys.
 *
 * @typedef {Object} NotificationTypeMap
 * @property {string} icon - The icon associated with the notification type.
 * @property {string} link - The link to navigate to when clicking on the notification.
 * @property {string} messageKey - The translation key for the notification message.
 */

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const notificationTypeMap = (item) => {
  const { t } = useTranslation();

  return {
    Invoice: {
      icon: 'InvoiceIcon',
      link: 'INVOICING',
      messageKey: item?.additionalInfos
        ? `${t('notifications.form.unpaid_invoice', {
            number: item?.additionalInfos?.[0]
          })} ${t(`invoices.tags.${item?.additionalInfos?.[1]}`)}`
        : ''
    },
    'Child-Reservation': {
      icon: 'PlanningIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.child-reservation', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`,
        date: item?.additionalInfos?.[0]
      })}`
    },
    'Child-Birthday': {
      icon: 'BirthdayIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.birthday', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`
      })}`
    },
    'Child-Absence': {
      icon: 'PlanningIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.child-absence', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`,
        date: item?.additionalInfos?.[0]
      })}`
    },
    'Child-Vaccination': {
      icon: 'VaccinationIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.vaccination', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`,
        vaccine: item?.additionalInfos?.[0]
      })}`
    },
    'Child-Document': {
      icon: 'DocumentsIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.document', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`
      })}`
    },
    'Child-Missing-Pickup-Time': {
      icon: 'PlanningIcon',
      link: 'CHILDREN',
      messageKey: `${t('notifications.form.child-missing-pickup-time', {
        name: `${item?.type?.first_name || ''} ${item?.type?.last_name || ''}`,
        date: item?.additionalInfos?.[0]
          ? dayjs(item?.additionalInfos?.[0]).format('DD/MM/YYYY')
          : ''
      })}`
    },
    'Pre-registration': {
      icon: 'PreRegistrationIcon',
      link: 'PRE_REGISTRATION',
      messageKey: `${t('notifications.form.new_registration', {
        name: `${item?.type?.child?.first_name || ''} ${
          item?.type?.child?.last_name || ''
        }`
      })}`
    },
    Employee: {
      icon: 'AbsenceIcon',
      link: 'ORDERS',
      messageKey: 'notifications.form.employee_notification'
    }
  };
};
