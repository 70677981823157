import { hours } from '../../utils/days';

/**
 * @description HoursBar component. Displays the daycare's opening hours on a single bar
 * @component
 * @returns {JSX} The hours bar
 */
export const HoursBar = () => (
  <thead className="hours-bar">
    <tr>
      {hours.map((hour) => (
        <th key={hour}>
          <span>{hour}</span>
        </th>
      ))}
    </tr>
  </thead>
);
