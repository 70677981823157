import { message as antdMessage } from 'antd';
import { routes } from '../../../utils/constants/adminRoutes';

/**
 * Utility function for submitting contract forms.
 *
 * This function handles the submission of contract forms for children and pre-registrations,
 * including uploading required documents and sending the form data to the backend.
 *
 * @param {string} origin - The origin of the form (children, parents or pre-registrations).
 * @param {Function} dispatchAPI - A function to make API requests.
 * @param {Function} navigate - A function for programmatic navigation.
 * @param {Array} documents - The list of documents associated with the contract.
 * @param {Array} filesConfiguration - The configuration for document files.
 * @param {Function} message - A function to display error messages.
 * @param {Function} t - A function for translation.
 * @param {Array} filesList - The list of document files to be uploaded.
 * @param {string} daycare - The daycare information.
 * @param {string} childID - The ID of the child associated with the contract.
 * @param {string} contractID - The ID of the contract (for patching).
 * @param {Object} termAndConditions - Term and conditions data.
 * @param {Object} planning - Planning data.
 * @param {Object} billing - Billing data.
 * @param {string} preRegistrationID - The ID of the pre-registration (if applicable).
 * @param {Object} additionalInfo - Additional information for pre-registrations (if applicable).
 * @param {string} customerAccount - The customer account information (if applicable).
 * @param {string} paymentMethod - The payment method information (if applicable).
 * @param {Function} setIsLoading - A function to set boolean loading button.
 * @param {Function} setIsReadyForSubmit - Function to set isReadyForSubmit.
 * @param {Function} setSuccess - A function to set boolean success.
 * @returns {Object} An object containing functions for submitting contract forms.
 */
export const submitForm = (
  origin,
  dispatchAPI,
  navigate,
  documents,
  filesConfiguration,
  message,
  t,
  filesList,
  daycare,
  childID,
  contractID,
  termAndConditions,
  planning,
  billing,
  preRegistrationID,
  additionalInfo,
  customerAccount,
  paymentMethod,
  setIsLoading,
  setIsReadyForSubmit,
  setSuccess
) => {
  const submitFormFromChildren = async () => {
    setIsLoading(true);
    try {
      const body = {
        daycare,
        childID,
        termAndConditions,
        planning,
        billing
      };

      await dispatchAPI('POST', {
        url: `/contracts/children`,
        body
      });
      antdMessage.success(t('contracts.messages.success'));
      setIsLoading(false);
      return navigate(`${routes.CHILDREN}/show/${childID}`);
    } catch (e) {
      if (e?.response?.data?.description) {
        return message(e?.response?.data?.description);
      }
      setIsReadyForSubmit(false);
      setIsLoading(false);
      return message(e);
    }
  };

  const submitFormFromPreRegistration = async () => {
    setIsLoading(true);
    const requiredDocuments = documents.filter((document) => document.required);
    const triggerBoolFunction =
      (requiredDocuments || []).length > 0
        ? requiredDocuments.every((document) =>
            filesConfiguration.some(
              (file) => file.fileKey === document.document_title
            )
          )
        : true;

    try {
      if (!triggerBoolFunction) {
        return message(t('missing_required'));
      }
      const body = {
        origin,
        daycare,
        'pre-registration_id': preRegistrationID,
        additional_info: additionalInfo,
        customer_account: customerAccount,
        payment_method: paymentMethod
      };
      const formData = new FormData();

      if (filesList.length && filesConfiguration.length) {
        filesList.forEach((file) => {
          formData.append('documents', file);
        });
        formData.append(
          'filesConfiguration',
          JSON.stringify(filesConfiguration)
        );
      }

      formData.append('values', JSON.stringify(body));

      await dispatchAPI('POST', {
        url: `/contracts/pre-registrations`,
        body: formData
      });

      antdMessage.success(t('contracts.messages.success'));
      if (origin === 'PARENTS') {
        setSuccess(true);
      } else navigate(`${routes.PRE_REGISTRATION}`);

      return navigate(-2);
    } catch (e) {
      return message(e);
    } finally {
      setIsLoading(false);
    }
  };

  const submitFormPatch = async () => {
    setIsLoading(true);
    try {
      const body = {
        daycare,
        childID,
        termAndConditions,
        planning,
        billing
      };

      await dispatchAPI('PATCH', {
        url: `/contracts/${contractID}/children`,
        body
      });
      antdMessage.success(t('contracts.messages.success_update'));
      return navigate(-1);
    } catch (e) {
      if (e.response.data.description === 'CONTRACT_ACTIVE_ON_THOSE_DATES') {
        navigate(-1);
        return message('CONTRACT_ACTIVE_ON_THOSE_DATES', 10);
      }
      return message(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submitFormFromChildren,
    submitFormFromPreRegistration,
    submitFormPatch
  };
};
