import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Divider } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { usePaymentMethodFields } from '../Fields/paymentMethodFields';

/**
 * Component for rendering the payment method form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @returns {React.Component} Payment method form component
 */
export const PaymentMethodForm = ({
  onFinish,
  handlePrevious,
  directDebit,
  setDirectDebit
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();

  const { paymentMethodField, paymentMethodSEPAField } =
    usePaymentMethodFields(setDirectDebit);

  return (
    <>
      {paymentMethodField.map((field) => generateFields('contract', field))}
      {directDebit && (
        <>
          <Divider orientation="left">{t('contract.form.rib.title')}</Divider>
          {paymentMethodSEPAField.map((field) =>
            generateFields('contract', field)
          )}
        </>
      )}
      <Flex justify="flex-end" gap="small">
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </>
  );
};

PaymentMethodForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  directDebit: PropTypes.bool,
  setDirectDebit: PropTypes.func
};

PaymentMethodForm.defaultProps = {
  directDebit: undefined,
  setDirectDebit: null
};
