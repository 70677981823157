import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, Button, Row, Form, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { EditableCell, EditableRow } from './EditableCell';
import { useColumns } from './columns';
import { formKeys, handleAdd, handleArrayFilter, pickFormKey } from './utils';

const translations = {
  FIFTEEN_MINUTES: 15,
  THIRTY_MINUTES: 30,
  SIXTY_MINUTES: 60
};

const multiplierTranslations = {
  FIFTEEN_MINUTES: 1,
  THIRTY_MINUTES: 2,
  SIXTY_MINUTES: 4
};

/**
 * The ExtraHoursTable component.
 *
 * @param {object} props - The props.
 * @param {object} props.dataSource - The dataSource.
 * @param {function} setDataSource - Function to set dataSource state.
 * @param {string} props.target - The targeted array.
 * @param {Object} props.form - Form instance.
 * @param {string} arrayFilter - Round up rules state.
 * @returns {React.Component} The component.
 */
export const ExtraHoursTable = ({
  dataSource,
  setDataSource,
  target,
  form,
  arrayFilter
}) => {
  const { t } = useTranslation();
  const columns = useColumns();

  const [filteredData, setFilteredData] = useState([]);

  const translationKey =
    target === 'occasional_extra_fees'
      ? arrayFilter.occasional
      : arrayFilter.regulars;
  const translatedValue = translations[translationKey];
  const extraFeeMultiplier = multiplierTranslations[translationKey] || 1;
  const isVisible = pickFormKey(target, translationKey);

  useEffect(() => {
    if (arrayFilter) {
      setFilteredData(() => {
        const updatedState = handleArrayFilter(
          dataSource,
          target,
          translationKey
        );

        return updatedState;
      });
    }
  }, [arrayFilter, dataSource]);

  const columnsWithEditable = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        index: rowIndex,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        disabled: col.disabled,
        setDataSource,
        filteredData,
        formKey: isVisible
      })
    };
  });

  return (
    <>
      {formKeys.map((formKey) => (
        <Flex style={{ display: formKey === isVisible ? 'block' : 'none' }}>
          <Row
            style={{
              flexDirection: 'column'
            }}
          >
            {t(
              'configurations.invoicing.form.bill_excess_count_on_regular_care'
            )}
            <Form.Item name={[formKey]} style={{ width: '300%' }}>
              <Table
                pagination={false}
                components={{
                  body: {
                    row: EditableRow,
                    cell: EditableCell
                  }
                }}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={filteredData}
                columns={columnsWithEditable}
              />
            </Form.Item>
          </Row>
          <Row justify="end" style={{ marginTop: 10 }}>
            <Button
              type="primary"
              onClick={() =>
                handleAdd(
                  filteredData,
                  translatedValue,
                  setDataSource,
                  formKey,
                  extraFeeMultiplier,
                  form
                )
              }
            >
              {t('configurations.invoicing.form.extra_duration.add')}
            </Button>
          </Row>
        </Flex>
      ))}
    </>
  );
};

ExtraHoursTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({ duration: PropTypes.number, extra_fee: PropTypes.number })
  ),
  setDataSource: PropTypes.func,
  target: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFields: PropTypes.func,
    setFieldValue: PropTypes.func
  }),
  arrayFilter: PropTypes.string
};

ExtraHoursTable.defaultProps = {
  dataSource: {},
  setDataSource: null,
  form: null,
  arrayFilter: undefined
};
