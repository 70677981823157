/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Divider, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useAuthorizationsFields } from '../../../Contracts/Fields/authorizationsFields';
import { formItemLayout } from '../../../../utils/constants/formLayout';

/**
 * AuthorizationsForm component for displaying and handling authorization form fields.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.onFinish - A function to be called when the form is successfully submitted.
 * @param {Object} props.form - Ant Design form instance for managing form state.
 * @returns {React.Element} The rendered AuthorizationsForm component.
 */
export const AuthorizationsForm = ({ onFinish, form }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { authorizationsFields, dataAuthorizationField } =
    useAuthorizationsFields();
  const [specificPermissionsValue, setSpecificPermissionsValue] = useState('');

  // Debounce the onFinish function to delay its execution
  const debouncedOnFinish = debounce((values) => onFinish(values), 1500);

  return (
    <Form
      onValuesChange={(_, allValues) => debouncedOnFinish(allValues)}
      form={form}
      {...formItemLayout}
    >
      <Divider orientation="left">
        {t('contract.form.titles.authorizations')}
      </Divider>
      <Row style={{ flexDirection: 'column' }}>
        {(authorizationsFields || []).map((field) =>
          generateFields('contract', field)
        )}
      </Row>
      <Divider orientation="left">
        {t('contract.form.titles.data-authorizations')}
      </Divider>
      <Row style={{ flexDirection: 'column' }}>
        {dataAuthorizationField.map((field) => {
          if (field.name[1] === 'specific_permissions') {
            return (
              <Input.TextArea
                key={field.name.join('-')}
                value={specificPermissionsValue}
                onChange={(e) => {
                  setSpecificPermissionsValue(e.target.value);
                  debouncedOnFinish(form.getFieldsValue()); // Trigger debounced onFinish
                }}
                style={{ resize: 'none' }}
                rows={4}
              />
            );
          }
          return generateFields('contract', field);
        })}
      </Row>
    </Form>
  );
};

AuthorizationsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired
};
