import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import contentToDisplay from './contentToDisplay';

/**
 * @hook useListContent
 * @description
 * useListContent is a hook that returns the content of the bank informations card.
 * It is used in the BankInformationsCard component.
 *
 * @param {boolean} visible - If the content is visible
 * @param {Object} data - The data of the bank informations
 * @returns {Array} The content of the bank informations card
 */
export const useListContent = (visible, data = {}) => {
  const translationKey = 'pre-registrations.show.bank_informations';
  const { t } = useTranslation();
  const { contract = {} } = data;
  const { rib = {}, payment_method = {} } = contract;
  const {
    bic = null,
    iban = null,
    rum = null,
    name = null,
    signature_date = null
  } = rib;
  const { method = null } = payment_method || {};

  return [
    {
      label: `${translationKey}.method`,
      content: method ? t(`payments.enums.payment_methods.${method}`) : '-',
      span: 1
    },
    ...(method === 'DIRECTDEBIT'
      ? [
          {
            label: `${translationKey}.name`,
            content: name,
            span: 2
          },
          {
            label: `${translationKey}.bic`,
            content: contentToDisplay(bic, visible),
            span: 1
          },
          {
            label: `${translationKey}.iban`,
            content: contentToDisplay(iban, visible),
            span: 2
          },
          {
            label: `${translationKey}.rum`,
            content: contentToDisplay(rum, visible),
            span: 1
          },
          {
            label: `${translationKey}.signature_date`,
            content: signature_date
              ? dayjs(signature_date).format('DD/MM/YYYY')
              : '-',
            span: 1
          }
        ]
      : [])
  ];
};
