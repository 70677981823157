import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Button, DatePicker, Flex, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import InvoicingContext from '../InvoicingContext';
import { invoiceStatuses } from '../../../utils/constants/tagColors';
import { AccountingExportButton } from '../../../components/AccountingExportButton/AccountingExportButton';

const { Option } = Select;
const translationKey = 'invoices.list.placeholders';

/**
 * ExtraButtons component generates additional buttons and controls for filtering invoice data.
 *
 * @component
 *
 * @returns {JSX.Element} JSX containing additional buttons and controls for filtering invoice data.
 */
export const ExtraButtons = () => {
  const { t } = useTranslation();
  const {
    customerAccountIds,
    setCustomerAccountIds,
    filterMonth,
    setFilterMonth,
    statuses,
    setStatuses,
    invoiceEnums,
    invoicesOptionsLoading,
    customerAccounts
  } = InvoicingContext();

  const onsearchStatus = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchCustomerAccount = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Flex align="center" gap="small" wrap>
      <Flex align="center" gap="small">
        <Button
          type="link"
          className="no-padding"
          onClick={() =>
            setFilterMonth(dayjs(filterMonth).subtract(1, 'month'))
          }
        >
          <LeftCircleFilled
            style={{ color: 'var(--primaryColor)', fontSize: 24 }}
          />
        </Button>
        <DatePicker
          style={{ width: 120 }}
          picker="month"
          onChange={setFilterMonth}
          value={dayjs(filterMonth)}
          allowClear={false}
        />
        <Button
          type="link"
          className="no-padding"
          onClick={() => setFilterMonth(dayjs(filterMonth).add(1, 'month'))}
        >
          <RightCircleFilled
            style={{ color: 'var(--primaryColor)', fontSize: 24 }}
          />
        </Button>
      </Flex>
      <Flex gap="small" wrap style={{ flex: '1 0 auto' }}>
        <Select
          style={{ flex: '1 0 240px' }}
          className="contains-tags"
          showSearch
          mode="multiple"
          allowClear
          defaultValue={statuses}
          placeholder={t(`${translationKey}.status`)}
          loading={invoicesOptionsLoading}
          filterOption={(input, option) => onsearchStatus(input, option)}
          onSelect={(value) => {
            setStatuses([...statuses, value]);
          }}
          onDeselect={(value) =>
            setStatuses((prevStatus) =>
              prevStatus.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setStatuses([]);
          }}
        >
          {invoiceEnums?.status?.map((s) => (
            <Option key={s} value={s} data-label={t(`invoices.tags.${s}`)}>
              <Tag color={invoiceStatuses[s]}>{t(`invoices.tags.${s}`)}</Tag>
            </Option>
          ))}
        </Select>
        <Select
          style={{ flex: '2 0 240px' }}
          showSearch
          mode="multiple"
          allowClear
          defaultValue={customerAccountIds}
          placeholder={t(`${translationKey}.customer_accounts`)}
          loading={invoicesOptionsLoading}
          filterOption={(input, option) =>
            onsearchCustomerAccount(input, option)
          }
          onSelect={(value) => {
            setCustomerAccountIds([...customerAccountIds, value]);
          }}
          onDeselect={(value) =>
            setCustomerAccountIds((prevStatus) =>
              prevStatus.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setCustomerAccountIds([]);
          }}
        >
          {customerAccounts?.map((c) => (
            <Option key={c._id} value={c._id}>
              {c.label}
            </Option>
          ))}
        </Select>
        <AccountingExportButton objective="invoices" />
      </Flex>
    </Flex>
  );
};
