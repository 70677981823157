import { useTranslation } from 'react-i18next';

/**
 * Columns for the table.
 * @hook
 * @returns {Array} The columns for the table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('configurations.invoicing.form.effort_rate.columns.one_child'),
      key: 'one_child',
      dataIndex: 'one_child',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.two_children'
      ),
      key: 'two_children',
      dataIndex: 'two_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.three_children'
      ),
      key: 'three_children',
      dataIndex: 'three_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.four_children'
      ),
      key: 'four_children',
      dataIndex: 'four_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.five_children'
      ),
      key: 'five_children',
      dataIndex: 'five_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.six_children'
      ),
      key: 'six_children',
      dataIndex: 'six_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.seven_children'
      ),
      key: 'seven_children',
      dataIndex: 'seven_children',
      editable: true
    },
    {
      title: t(
        'configurations.invoicing.form.effort_rate.columns.eight_children'
      ),
      key: 'eight_children',
      dataIndex: 'eight_children',
      editable: true
    }
  ];
};
