import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const MercuryAppContext = createContext({});

export const MercuryAppContextProvider = ({ children }) => (
  <MercuryAppContext.Provider value={{}}>{children}</MercuryAppContext.Provider>
);
MercuryAppContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(MercuryAppContext);
