import { Form } from 'antd';
import React from 'react';

const EditableContext = React.createContext(null);

/**
 * A component for rendering an editable row in a table. It wraps a table row (`<tr>`) element in a Form and provides it with form functionality using Ant Design's `Form.useForm`. The form instance is then provided to all the cells in the row through `EditableContext`.
 *
 * @component
 *
 * This component is part of the editable table setup, which allows individual rows in a table to be editable. It uses Ant Design's `Form` component to create a new form instance for each row, enabling independent form states for each row's editable cells. The form instance is shared among the cells in a row via a React context (`EditableContext`), allowing them to access and modify the row's data.
 *
 * @param {Object} props - The props passed to the table row.
 * @returns {JSX.Element} A table row (`<tr>`) wrapped in a `Form` component, with form functionality enabled for editable cells within the row.
 */
export const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
