import { Divider, Flex, Form, Input, Space, Transfer } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { icons } from './icons';

const { TextArea } = Input;

/**
 * A form component for adding or editing meals with ingredient selection.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.meal - The type of meal (e.g., 'breakfast', 'lunch').
 * @param {Array} props.ingredients - Array of available ingredients for selection.
 * @param {Object} props.field - Field object for form item naming.
 * @param {Object} props.form - The form instance for managing form data.
 * @param {Object} props.dayMeal - The meal data for a specific day, used in edit mode.
 * @returns {JSX.Element} A form for meal creation or editing with ingredient selection.
 */
export const MealForm = ({ meal, ingredients, field, form, dayMeal }) => {
  const { t } = useTranslation();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const filterOption = (inputValue, option) => {
    const lowerInputValue = inputValue.toLowerCase();

    return option.filters.some((filter) =>
      filter.toLowerCase().includes(lowerInputValue)
    );
  };

  useEffect(() => {
    if (dayMeal && dayMeal.ingredients) {
      const selectedIngredientIds = dayMeal.ingredients.map(
        (ingredient) => ingredient._id
      );
      setTargetKeys(selectedIngredientIds);
    }
  }, [dayMeal]);

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    const fieldName = `${field.name}.ingredients`;
    form.setFieldsValue({
      [fieldName]: targetKeys
    });
  }, [targetKeys, field.name, form]);

  const mealIcon = (mealType) => {
    switch (mealType) {
      case 'SNACK':
        return 0;
      case 'APPETIZER':
        return 1;
      case 'MAIN_COURSE':
        return 2;
      case 'DESSERT':
        return 3;
      case 'AFTERNOON_SNACK':
        return 4;
      default:
        return 2;
    }
  };

  return (
    <>
      <Divider orientation="left" style={{ borderColor: 'red' }}>
        <Flex gap="small" align="center">
          <img
            src={icons[mealIcon(meal)].icon}
            alt={icons[mealIcon(meal)].title}
            width={20}
          />
          {t(`meals.titles.${meal}`)}
        </Flex>
      </Divider>
      <Form.Item name={[field.name, 'meal_type']} hidden>
        <Input value={meal} />
      </Form.Item>
      <Form.Item
        name={[field.name, 'name']}
        label={t('meals.form.name')}
        {...formItemLayout}
      >
        <TextArea autoSize />
      </Form.Item>
      <Form.Item name={[field.name, 'ingredients']} style={{ width: 752 }}>
        <Transfer
          dataSource={ingredients}
          showSearch
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          filterOption={filterOption}
          onChange={onChange}
          onSelectChange={handleSelectChange}
          titles={[t('meals.form.ingredients'), t('meals.form.selected')]}
          render={(item) => (
            <Space key={item._id}>
              {item?.title && (
                <img
                  src={item.title}
                  alt={`icon-${item.title}`}
                  style={{ width: 24 }}
                />
              )}
              {item.description}
            </Space>
          )}
        />
      </Form.Item>
    </>
  );
};

MealForm.propTypes = {
  meal: PropTypes.string.isRequired,
  dayMeal: PropTypes.shape({
    ingredients: PropTypes.arrayOf(PropTypes.string)
  }),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  field: PropTypes.shape({
    name: PropTypes.number
  }).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired
};

MealForm.defaultProps = {
  ingredients: [],
  dayMeal: null
};
