import { Card, Col, Divider, Row, Skeleton } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useListContent } from '../listContent';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { DescriptionList } from '../../../../components';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { displayChildGenderLogo } from './utils';
import { Planning } from './Planning';

/**
 * General information tab for displaying and editing child details.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.child - The child object containing information like photo, gender, and _id.
 * @param {string} props.child.photo - The URL of the child's photo.
 * @param {string} props.child.gender - The gender of the child ('BOY', 'GIRL', or null).
 * @param {string} props.child._id - The unique identifier for the child.
 * @param {boolean} props.refresh - Boolean flag indicating whether to refresh the component.
 * @param {Function} props.setRefresh - Function to trigger a refresh of the component.
 * @param {boolean} props.isLoading - Boolean flag indicating whether data is still loading.
 * @returns {JSX.Element} JSX representation of the GeneralInfosTab component.
 */
export const GeneralInfosTab = ({ child, refresh, setRefresh, isLoading }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { childInformation } = useListContent(child || {});
  const { message } = useErrorMessage();
  const [fileList, setFileList] = useState([]);

  const childLogo = displayChildGenderLogo(
    child,
    fileList,
    setFileList,
    message,
    dispatchAPI,
    refresh,
    setRefresh
  );

  return (
    <>
      <Card>
        <Row gutter={[24, 24]}>
          <Col
            span={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {childLogo}
          </Col>
          <Col span={9}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <DescriptionList
                layout="vertical"
                data={childInformation}
                translate
              />
            </Skeleton>
          </Col>
        </Row>
      </Card>
      <Divider>{t('children.show.planning.title')}</Divider>
      <Planning isParentView={false} child={child} />
    </>
  );
};

GeneralInfosTab.propTypes = {
  child: PropTypes.shape({
    photo: PropTypes.string,
    gender: PropTypes.string,
    _id: PropTypes.string
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  isLoading: PropTypes.bool
};

GeneralInfosTab.defaultProps = {
  child: {},
  refresh: false,
  setRefresh: null,
  isLoading: false
};
