import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Card, Col, Select, Upload, Flex } from 'antd';
import {
  DeleteOutlined,
  FolderOpenFilled,
  ArrowLeftOutlined,
  HourglassOutlined,
  CheckCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/errorMessage';
import { deleteDocument } from '../utils/deleteDocument';
import { draggerProps } from '../utils/draggerProps';
import { handleTempFileListSave } from '../utils/handleTempFileListSave';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useAuthContext } from '../../../contexts/AuthContext';
import { documentFormConfigs } from '../utils/documentFormConfigs';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * Component for rendering the document form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Function} setDocuments Function to be called to update documents state.
 * @prop {Array} documents Object of arrays of documents.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @prop {Function} submitForm Function to be called when the Form is submitting.
 * @prop {Array} filesConfiguration - Array of files configuration for API treatment.
 * @prop {Function} setFilesConfiguration - Function to set filesConfiguration.
 * @prop {Array} filesList - Array of contract files.
 * @prop {Function} setFilesList - Function to set the filesList.
 * @prop {String} contractID - ID of the contract.
 * @prop {Boolean} loading - Loading state when the form is submitting.
 *
 * @returns {React.Component} Payment method form component
 */
export const DocumentForm = ({
  documents,
  handlePrevious,
  submitForm,
  filesConfiguration,
  setFilesConfiguration,
  setFilesList,
  filesList,
  contractID,
  loading
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [tempFileList, setTempFileList] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const { downloadDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();

  const {
    handleDownload,
    displayDownloadButton,
    displayFileName,
    displayDeleteButton
  } = documentFormConfigs(filesConfiguration, filesList, downloadDocument);

  const deleteDocumentByID = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `contracts/${contractID}/delete-document/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 24, minHeight: 200 }}>
        <Col xs={24} xl={14}>
          <Flex vertical gap="small">
            {documents?.map((item) => (
              <Card key={item.name} className="document-card">
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap="small">
                    {filesConfiguration.some(
                      (config) => config.fileKey === item.document_title
                    ) ? (
                      <CheckCircleOutlined />
                    ) : (
                      <HourglassOutlined />
                    )}

                    {`${item?.document_title}${
                      item?.required
                        ? ` - ${t('contracts.form.item_required')}`
                        : ''
                    }`}
                  </Flex>

                  {displayFileName(item)}

                  <Flex>
                    {displayDeleteButton(item) && (
                      <Button
                        onClick={() =>
                          deleteDocument(
                            item.document_title,
                            filesConfiguration,
                            setFilesConfiguration,
                            setFilesList,
                            filesList,
                            deleteDocumentByID,
                            t
                          )
                        }
                        type="link"
                        icon={<DeleteOutlined />}
                      />
                    )}
                    {displayDownloadButton(item) && (
                      <Button
                        onClick={() => handleDownload(item)}
                        type="link"
                        icon={<UploadOutlined />}
                      />
                    )}
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>
        </Col>
        <Col xs={24} xl={10} style={{ height: 'fit-content' }}>
          <Dragger
            {...draggerProps(
              tempFileList,
              setTempFileList,
              message,
              filesList,
              t
            )}
            showUploadList={false}
            style={{ marginBottom: 8 }}
          >
            <p className="ant-upload-drag-icon">
              <FolderOpenFilled />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          <Flex vertical gap="small">
            {(tempFileList || []).map((file, index) => (
              <Flex key={file.name} align="center">
                <span
                  style={{
                    maxWidth: 'calc(100% - 44px)'
                  }}
                  className="text-ellipsis"
                >
                  {file.name}
                </span>
                <Button
                  onClick={() =>
                    setTempFileList((prevList) => {
                      const updatedList = [...prevList];
                      updatedList.splice(index, 1);
                      return updatedList;
                    })
                  }
                  type="link"
                >
                  <DeleteOutlined />
                </Button>
              </Flex>
            ))}
          </Flex>
          <Flex align="center" justify="space-between" wrap="wrap" gap="small">
            {tempFileList.length > 0 && (
              <Select
                id="requied_documents_select"
                style={{ width: 'calc(100% - 131px)' }}
                onSelect={(value) => {
                  setSelectedType(value);
                }}
                placeholder={t('files.create.category_placeholder')}
              >
                {documents.map((item) => (
                  <Option key={item._id} value={item.document_title}>
                    {item.document_title}
                  </Option>
                ))}
              </Select>
            )}
            {selectedType && tempFileList?.length && (
              <Button
                onClick={() =>
                  handleTempFileListSave(
                    tempFileList,
                    filesConfiguration,
                    selectedType,
                    setFilesConfiguration,
                    setFilesList,
                    setTempFileList,
                    setSelectedType,
                    t,
                    deleteDocumentByID,
                    undefined,
                    'document-form'
                  )
                }
                type="primary"
              >
                {`${t('buttons.save')}`}
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      <Flex justify="flex-end" gap="small">
        <Button disabled={loading} type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button loading={loading} type="primary" onClick={submitForm}>
          {t('buttons.validate')}
        </Button>
      </Flex>
    </>
  );
};

DocumentForm.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  setFilesList: PropTypes.func,
  filesConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  filesList: PropTypes.arrayOf(PropTypes.shape({})),
  setFilesConfiguration: PropTypes.func,
  contractID: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

DocumentForm.defaultProps = {
  documents: [],
  filesConfiguration: [],
  filesList: [],
  setFilesList: null,
  setFilesConfiguration: null,
  contractID: ''
};
