import { Button, Col, Divider, Flex, Popconfirm, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  PaperClipOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { dateRender, upperCaseFirstCharacter } from '../News/utils';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';

/**
 * Renders the detailed view of a specific event.
 *
 * @component
 *
 * This component is responsible for displaying detailed information about an event.
 * It shows the event date, time, groups associated with the event, a description, and
 * any attachments. Additionally, it provides options to download attachments and delete them.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.resource - The event resource object containing details such as
 * date, hours, groups, description, thumbnail, attachment, and visibility.
 * @param {Function} props.deleteFileById - Function to delete the attached file by its ID.
 *
 * @returns {JSX.Element} A detailed view of an event, including its date, description,
 * groups, attachment, and visibility tags.
 */
export const EventsShowRender = ({ resource, deleteFileById }) => {
  const { t } = useTranslation();
  const { token, user } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();

  const userBoolean = ['guests:PARENT', 'users:TABLET'].includes(user?.role);

  return (
    <Flex vertical style={{ maxWidth: 1000 }} className="event-show-wrapper">
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={18}>
          <Flex vertical gap="large">
            <Flex justify="space-between" gap="middle" align="center">
              <Flex vertical>
                <span className="event-day">
                  {upperCaseFirstCharacter(
                    dayjs(resource?.date).format('dddd')
                  )}
                </span>
                <span className="event-date">
                  {dayjs(resource?.date).format('D MMMM')}
                </span>
                <span className="event-time">
                  {resource?.hours?.[0] &&
                    dayjs(resource.hours[0]).format('HH:mm')}
                  {resource?.hours?.[1] &&
                    ` - ${dayjs(resource.hours[1]).format('HH:mm')}`}
                </span>
              </Flex>
              <Flex gap="small">
                {(resource?.groups || []).map((group) => (
                  <Tag key={group._id} color={group.tag_color}>
                    {group.label}
                  </Tag>
                ))}
              </Flex>
            </Flex>
            <p>{resource?.description}</p>
            <Flex gap="small" align="center">
              <Button
                type="link"
                onClick={() => downloadDocument(resource?.attachment)}
                style={{ padding: '4px 0' }}
              >
                <PaperClipOutlined />
                {resource?.attachment?.metadata?.originalName}
              </Button>
              {!userBoolean && (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ danger: true }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteFileById(resource?.attachment?._id)}
                  icon={<WarningOutlined />}
                >
                  <Button type="link">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          </Flex>
        </Col>
        <Col xs={24} xl={6}>
          {resource?.thumbnail?._id && (
            <img
              src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${resource?.thumbnail?._id}/${token}`}
              style={{ maxWidth: '100%' }}
              alt="thumbnail-icon"
            />
          )}
        </Col>
      </Row>
      {!userBoolean && (
        <>
          <Divider />
          <Flex justify="space-between">
            <span style={{ color: 'var(--mediumGray)' }}>
              {dateRender(resource, t)}
            </span>
            <Flex gap="small">
              {(resource?.visibility || []).map((visItem) => (
                <Tag color={visibilitiesColors[visItem]} key={visItem}>
                  {t(`communication.news.list.type.${visItem}`)}
                </Tag>
              ))}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

EventsShowRender.propTypes = {
  resource: PropTypes.shape({
    date: PropTypes.string,
    hours: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    thumbnail: PropTypes.shape({
      _id: PropTypes.string
    }),
    attachment: PropTypes.shape({
      _id: PropTypes.string,
      metadata: PropTypes.shape({
        originalName: PropTypes.string
      })
    }),
    visibility: PropTypes.arrayOf(PropTypes.string)
  }),
  deleteFileById: PropTypes.func.isRequired
};

EventsShowRender.defaultProps = {
  resource: null
};
