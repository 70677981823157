import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * `WeekTimes` is a React component that displays the total planned and actual (real) times for a given week.
 * It's typically used in the context of planning or scheduling to provide a quick overview of time allocation.
 *
 * The component leverages the `useTranslation` hook for internationalization, ensuring that the labels for 'planned' and 'real' times
 * are translated according to the current language settings.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.realTotalTime - The total actual time for the week.
 * @param {string} props.plannedTotalTime - The total planned time for the week.
 * @param {string} props.contractTime - Hours per week settled in the contract.
 *
 * @returns {JSX.Element} A component displaying the planned and actual times in a spaced layout.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.realTotalTime {string} - The total actual time for the week.
 * @propTypes.plannedTotalTime {string} - The total planned time for the week.
 */
export const WeekTimes = ({
  realTotalTime,
  plannedTotalTime,
  contractTime
}) => {
  const { t } = useTranslation();

  return (
    <Space className="planning-time-count">
      {contractTime && (
        <span>
          {`${t('employees.show.planning.contract')} : `}
          <strong>{`${contractTime}h00`}</strong>
        </span>
      )}
      <span>
        {`${t('children.show.planning.planned')} : `}
        <strong>{plannedTotalTime}</strong>
      </span>
      <span>
        {`${t('children.show.planning.real')} : `}
        <strong>{realTotalTime}</strong>
      </span>
    </Space>
  );
};

WeekTimes.propTypes = {
  realTotalTime: PropTypes.string,
  plannedTotalTime: PropTypes.string,
  contractTime: PropTypes.number
};

WeekTimes.defaultProps = {
  realTotalTime: undefined,
  plannedTotalTime: undefined,
  contractTime: undefined
};
