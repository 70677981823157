import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Row, Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Custom component for displaying a thread of messages or notes
 *
 * @component
 * @prop {array} data - Array of messages or notes
 * @prop {boolean} loading - Loading state
 * @returns {JSX.Element} - Custom component for displaying a thread of messages or notes
 */
export const Thread = ({ data, loading }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const currentDate = dayjs().startOf('day');

  const messageTitle = ({ from: { first_name, last_name }, date }) => (
    <Row justify="space-between">
      <span>{`${first_name} ${last_name}`}</span>
      <span>{dayjs(date).format('HH:mm')}</span>
    </Row>
  );

  const justify = ({ from: { _id } }) => (_id === user._id ? 'end' : 'start');

  /**
   * Display a divider if the current message is the first of the day
   *
   * @function
   * @param {object} item - Current message
   * @param {number} index - Current index
   * @returns {boolean} - True if the current message is the first of the day
   */
  const displayDivider = (item, index) => {
    if (index === 0) return true;
    const previousMessage = data[index - 1];
    const itemDate = dayjs(item.date).startOf('day');
    const previousMessageDate = dayjs(previousMessage.date).startOf('day');
    return (
      itemDate.isSame(currentDate, 'day') &&
      !previousMessageDate.isSame(currentDate, 'day')
    );
  };

  /**
   * Display a formatted date
   *
   * @function
   * @param {object} date - Current date of the message
   * @returns {string} - Date to display
   */
  const dateFormat = (date) => {
    const itemDate = dayjs(date).startOf('day');
    const dayDiff = currentDate.diff(itemDate, 'days');

    switch (dayDiff) {
      case 0:
        return t('today');
      case 1:
        return t('yesterday');
      case 2:
        return t('before_yesterday');
      default:
        return itemDate.format('DD/MM/YYYY');
    }
  };

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data || []}
      className="messages-list"
      renderItem={(item, index) => (
        <>
          {displayDivider(item, index) && (
            <Divider
              style={{ margin: '0', padding: '16px 0' }}
              orientationMargin={0}
            >
              <span
                style={{
                  backgroundColor: '#F0F0F0',
                  borderRadius: 16,
                  padding: '4px 8px',
                  width: '100%',
                  fontSize: 14
                }}
              >
                {dateFormat(item.date)}
              </span>
            </Divider>
          )}
          <Row justify={justify(item)}>
            <List.Item
              className={
                item.from._id === user._id
                  ? 'message-list-item right'
                  : 'message-list-item left'
              }
            >
              <List.Item.Meta
                title={messageTitle(item)}
                description={item?.content}
              />
            </List.Item>
          </Row>
        </>
      )}
    />
  );
};

Thread.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool.isRequired
};

Thread.defaultProps = {
  data: []
};
