import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Calendar } from 'antd';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { dateCellRender } from './calendarComponents/cellRender';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateMeal } from './calendarComponents/CreateUpdateMeal';
import { DownloadMenuModal } from './DownloadMenuModal';
import { headerRender } from './headerRender';

/**
 * MealsCalendar component for displaying and managing a calendar of meals.
 *
 * @component
 *
 * It provides a calendar view where each day can be clicked to manage the meals
 * for that day. It uses a modal for adding or editing meals and fetches meal data
 * from a backend service.
 *
 * @returns {JSX.Element} The MealsCalendar component with meal management functionality.
 */
export const MealsCalendar = () => {
  const { t } = useTranslation();
  const { daycare, dispatchAPI } = useAuthContext();
  const [refresh, setRefresh] = useState();
  const { message } = useErrorMessage();
  const [meals, setMeals] = useState();
  const [modal, setModal] = useState();

  const onModalOpen = (resourceName, date) => {
    const modalKey = Date.now();

    const dayMeals = meals.filter(
      (meal) =>
        dayjs(meal.date).format('DD/MM/YYYY') ===
        dayjs(date).format('DD/MM/YYYY')
    )?.[0];

    setModal(
      <CreateUpdateMeal
        key={modalKey}
        setRefresh={setRefresh}
        resourceName={resourceName}
        date={date}
        dayMeals={dayMeals}
      />
    );
  };

  const onDownloadModalOpen = () => {
    const modalKey = Date.now();
    setModal(<DownloadMenuModal key={modalKey} />);
  };

  const getMeals = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `meals?daycare=${daycare}`
      });
      setMeals(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getMeals();
    })();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={t('meals.title')}
        extra={
          <Button onClick={onDownloadModalOpen} type="primary">
            {t('buttons.download')}
            <DownloadOutlined />
          </Button>
        }
      />
      <ContentCustom>
        <Calendar
          locale="fr"
          mode="month"
          headerRender={headerRender}
          cellRender={(value) => {
            const date = dayjs(value).format('DD/MM/YYYY');
            const dayMeal = (meals || []).find(
              (meal) => dayjs(meal.date).format('DD/MM/YYYY') === date
            );
            return dateCellRender(value, onModalOpen, dayMeal);
          }}
          style={{ userSelect: 'none' }}
          className="meals-calendar"
        />
      </ContentCustom>
      {modal}
    </>
  );
};
