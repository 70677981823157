import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Col, DatePicker, Tag, Spin, Row } from 'antd';
import { Liquid } from '@ant-design/charts';
import { LoadingOutlined } from '@ant-design/icons';
import { useHomeContext } from '../../../contexts/HomeContext';
import { getStats } from '../../../utils/getStatsPerDay';
import { planningColors } from '../../../utils/constants/tagColors';

import IsWaling from '../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../assets/images/icons/healthicons_baby-0306m.svg';

/**
 * @component AttendanceCard
 * @description Display the attendance card with the occupation rate of the daycare
 * @returns {JSX} AttendanceCard component
 */
export const AttendanceCard = () => {
  const { t } = useTranslation();
  const {
    daycareInfos,
    selectedWeek,
    setSelectedWeek,
    statsPerWeek,
    closedDays,
    attendanceCardLoading
  } = useHomeContext();

  const onChange = (date) => {
    setSelectedWeek(
      Array.from({ length: 5 }, (_, i) =>
        dayjs(date).startOf('week').add(i, 'day')
      )
    );
  };

  /**
   * @function getStatsPerDay
   * @description Get the stats per day
   * @param {array} dont_walk - Array of children who don't walk
   * @param {array} walk - Array of children who walk
   * @param {string} day - Day of the selected week
   * @returns {JSX} - Stats per day
   */
  const getStatsPerDay = (dont_walk, walk, day) => {
    if (dont_walk && walk) {
      const { peakDontWalkAtPeakHour, peakWalkAtPeakHour } = getStats(
        dont_walk,
        walk
      );

      const percentage = daycareInfos?.capacity
        ? (peakDontWalkAtPeakHour + peakWalkAtPeakHour) / daycareInfos.capacity
        : 0;

      const config = {
        percent: Number(percentage),
        height: 100,
        outline: {
          distance: 4
        },
        wave: {
          length: 50
        },
        theme: {
          styleSheet: {
            brandColor:
              percentage >= 1 ? planningColors.OVER : planningColors.BELOW
          }
        },
        statistic: {
          content: {
            style: {
              fontSize: 18
            }
          }
        }
      };

      const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1);

      const getTagColor = (percent) => {
        if (percent >= 1) {
          return planningColors.OVER;
        }
        return planningColors.BELOW;
      };

      return (
        <Col key={day} span={Math.floor(24 / selectedWeek.length)}>
          <Flex vertical align="center">
            <p className="no-margin" style={{ textAlign: 'center' }}>
              {capitalizeFirstLetter(dayjs(day).format('dddd DD-MM'))}
            </p>
            <Spin
              spinning={percentage === 'NaN'}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24
                  }}
                  spin
                />
              }
            >
              <Liquid {...config} />
            </Spin>
            <Flex justify="center" gap="small">
              <Tag color={getTagColor(percentage)} className="no-margin">
                <img src={DontWalk} alt="dont-walk" />
                {peakDontWalkAtPeakHour}
              </Tag>
              <Tag color={getTagColor(percentage)} className="no-margin">
                <img src={IsWaling} alt="is_walking" />
                {peakWalkAtPeakHour}
              </Tag>
            </Flex>
          </Flex>
        </Col>
      );
    }
    return true;
  };

  return (
    <Card
      loading={daycareInfos?.capacity === undefined || attendanceCardLoading}
      title={
        <Flex justify="space-between" align="baseline">
          <span>{t('home.titles.attendance')}</span>
          <DatePicker
            allowClear={false}
            picker="week"
            defaultValue={dayjs()}
            onChange={onChange}
            format="WW-YYYY"
          />
        </Flex>
      }
      style={{ width: '100%' }}
    >
      <Row justify="space-between">
        {selectedWeek &&
          selectedWeek.length &&
          selectedWeek.map((day) =>
            closedDays?.includes(dayjs(day).format('DD/MM/YYYY')) ? (
              <p key={day} style={{ textAlign: 'center' }}>
                {t('home.attendance_card.closed')}
              </p>
            ) : (
              getStatsPerDay(
                statsPerWeek?.[1]?.filter(
                  (f) =>
                    dayjs(f.day).format('DD/MM/YYYY') ===
                    dayjs(day).format('DD/MM/YYYY')
                ),
                statsPerWeek?.[0]?.filter(
                  (f) =>
                    dayjs(f.day).format('DD/MM/YYYY') ===
                    dayjs(day).format('DD/MM/YYYY')
                ),
                day
              )
            )
          )}
      </Row>
    </Card>
  );
};
