import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton, Flex, Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { DescriptionList } from '../../../components';
import { CreateUpdateShow } from './CreateUpdateShow';
import { useListContent } from './listContent';

/**
 * Display the daycare's informations.
 * @component
 * @param {object} data The daycare's informations.
 * @param {boolean} isLoading Is the data loading.
 * @param {boolean} refresh - Boolean to refresh data.
 * @param {Function} setRefresh - Function to refresh data.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowDaycare = ({ data, isLoading, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const listContent = useListContent(data);

  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateShow
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  return (
    <>
      <Flex align="center" gap="small">
        <Divider orientation="left">
          {t('daycares.form.main_informations')}
        </Divider>
        {user.role !== 'users:MANAGER' && (
          <>
            {modal}
            <Button
              type="primary"
              onClick={() => handleModalBehaviour(data, 'edit')}
            >
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </>
        )}
      </Flex>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <DescriptionList
          data={listContent}
          translate
          openModal
          forceRefresh={refresh}
          layout="vertical"
        />
      </Skeleton>
    </>
  );
};

ShowDaycare.propTypes = {
  data: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

ShowDaycare.defaultProps = {
  data: {},
  isLoading: false,
  refresh: undefined,
  setRefresh: null
};
