import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Modal, Divider } from 'antd';
import { CreateUpdateContainerModal } from '../../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useTerminatedFields } from './terminatedFields';

/**
 * TerminatedModal is a component that displays a modal for terminating a contract.
 * It includes a form with fields retrieved from `useTerminatedFields` and handles the submission
 * of the termination date, formatting it to the end of the day.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isModalOpen - Indicates if the modal is open.
 * @param {function} props.closeModal - Function to close the modal.
 * @param {Object} props.contract - The contract object containing contract details.
 * @param {string} props.contract._id - The ID of the contract to terminate.
 * @param {function} props.setRefresh - Function to refresh the parent component data.
 * @returns {JSX.Element} The rendered component.
 */
const TerminatedModal = ({ isModalOpen, closeModal, contract, setRefresh }) => {
  const { t } = useTranslation();

  const { fields, isFieldsLoading } = useTerminatedFields();

  const config = {
    onCreateResource: {
      setBody: (body) => ({
        ...body,
        terminated_date: dayjs(body?.terminated_date).endOf('day')
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`contracts.show.terminated_modal.title`)}`}
      width={900}
      footer={false}
    >
      <Divider />
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="create"
        baseUrl={`/contracts/${contract?._id}/terminated`}
        resource="contracts"
        closeModal={closeModal}
        id={contract?._id}
        withGetResource
        setRefresh={setRefresh}
        withoutPageHeaderCustom
        successCreateMessage={t(
          'contracts.show.terminated_modal.success_message'
        )}
        config={config}
      />
      <Divider />
    </Modal>
  );
};

TerminatedModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default TerminatedModal;
