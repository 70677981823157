import { Flex, Menu } from 'antd';
import { useState } from 'react';
import logo from '../../assets/images/logomaiavillage.png';

/**
 * Component for rendering an external preregistration menu.
 *
 * @component
 *
 * @returns {JSX.Element} The ExternalPreregistrationMenu component.
 */
export const ExternalPreregistrationMenu = () => {
  const [current] = useState('preregistrations');

  const items = [
    {
      label: (
        <a href="https://www.maiavillage.fr/" rel="noopener noreferrer">
          Accueil
        </a>
      ),
      key: 'home'
    },
    {
      label: (
        <a href="https://www.maiavillage.fr/" rel="noopener noreferrer">
          Qui nous sommes
        </a>
      ),
      key: 'who_we_are'
    },
    {
      label: (
        <a
          href="http://localhost:3000/pre-registrations/create"
          rel="noopener noreferrer"
        >
          Pré-inscriptions
        </a>
      ),
      key: 'preregistrations'
    },
    {
      label: (
        <a href="https://www.maiavillage.fr/" rel="noopener noreferrer">
          Nos Villages
        </a>
      ),
      key: 'villages'
    },
    {
      label: (
        <a href="https://www.maiavillage.fr/" rel="noopener noreferrer">
          Blog
        </a>
      ),
      key: 'blog'
    },
    {
      label: (
        <a href="https://www.maiavillage.fr/" rel="noopener noreferrer">
          Contact
        </a>
      ),
      key: 'contact'
    }
  ];

  return (
    <Flex className="outside-app-menu" align="center" justify="center">
      <img src={logo} alt="logo" />
      <Flex>
        <Menu
          selectedKeys={[current]}
          defaultSelectedKeys={['preregistrations']}
          mode="horizontal"
          items={items}
        />
      </Flex>
    </Flex>
  );
};
