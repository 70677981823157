/**
 * Generates the CSS style object for a half circle element.
 *
 * @param {boolean} isSelected - Whether the element is selected.
 * @param {boolean} isSecondHalf - Whether it represents the second half of the circle.
 * @returns {Object} The CSS style object.
 */
export const circleHalfStyle = (isSelected, isSecondHalf) => ({
  width: '50%',
  height: '100%',
  borderRadius: isSecondHalf ? '0 15px 15px 0' : '15px 0 0 15px',
  position: 'absolute',
  background: isSelected ? 'var(--primaryColor)' : 'transparent',
  top: '0',
  left: isSecondHalf ? '50%' : '0'
});

/**
 * Generates the CSS style object for a full day element.
 *
 * @param {boolean} isSelected - Whether the element is selected.
 * @returns {Object} The CSS style object.
 */
export const fullDayStyle = (isSelected) => ({
  width: '100%',
  height: '100%',
  borderRadius: '15px',
  background: isSelected ? 'var(--primaryColor)' : 'transparent'
});
