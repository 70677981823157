import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../components';

const { Text, Title } = Typography;

/**
 * Renders the success page for signatures.
 *
 * @param {object} data - The data object containing the contract number and start date.
 * @param {string} data.contract_number - The contract number.
 * @param {string} data.start_date - The start date.
 * @param {boolean} update_sepa_mandate - A boolean indicating whether the SEPA mandate should be updated.
 * @returns {JSX.Element} The success page component.
 */
export const Success = ({ data, update_sepa_mandate }) => {
  const { t } = useTranslation();
  const { contract_number, start_date } = data;

  return (
    <ContentCustom>
      <Flex align="center" justify="center" vertical style={{ padding: 32 }}>
        <Flex align="center" justify="center" gap="large">
          <CheckOutlined style={{ fontSize: 48, color: 'var(--addColor)' }} />
          <Title>{t('signatures.success')}</Title>
        </Flex>
        <Text>{t('signatures.success_message', { contract_number })}</Text>
        {update_sepa_mandate ? (
          <Text>{t('signatures.sepa_mandate_updated')}</Text>
        ) : (
          <Text>{t('signatures.start_date_message', { start_date })}</Text>
        )}
        <Button style={{ marginTop: 16 }} onClick={() => window.close()}>
          {t('buttons.close_page')}
        </Button>
      </Flex>
    </ContentCustom>
  );
};

Success.propTypes = {
  data: PropTypes.shape({
    contract_number: PropTypes.string,
    start_date: PropTypes.string
  }),
  update_sepa_mandate: PropTypes.bool
};

Success.defaultProps = {
  data: {
    contract_number: '',
    start_date: ''
  },
  update_sepa_mandate: false
};
