import { Button, Divider, Popconfirm, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { routes } from '../../../../utils/constants/adminRoutes';
import { childrenGender } from '../../../../utils/constants/tagColors';
import IsWalking from '../../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../../assets/images/icons/healthicons_baby-0306m.svg';
import { getAge } from '../../utils/getAge';

const iconSize = 18;

/**
 * useSiblingColumns is a custom hook that generates columns configuration for displaying and managing sibling records in a table.
 *
 * @hook
 *
 * @param {function} handleSiblingModal - A function to handle the sibling modal.
 * @param {function} removeFromParents - A function to set the refresh state of the table.
 * @returns {Array} An array of column configuration objects.
 * @example
 * // Usage example:
 * const siblingColumns = useSiblingColumns(refreshState, setRefreshState, handleSiblingModal);
 */
export const useSiblingColumns = (handleSiblingModal, removeFromParents) => {
  const { t } = useTranslation();

  return [
    {
      title: t('children.list.last_name'),
      key: 'last_name',
      dataIndex: 'last_name'
    },
    {
      title: t('children.list.first_name'),
      key: 'first_name',
      dataIndex: 'first_name'
    },
    {
      title: t('children.list.gender'),
      key: 'gender',
      dataIndex: 'gender',
      render: (gender) => (
        <Tag color={childrenGender[gender]}>
          {t(`children.genders.${gender}`)}
        </Tag>
      )
    },
    {
      title: t('children.list.group'),
      key: 'group',
      dataIndex: 'group',
      sorter: true,
      render: (group) =>
        group && <Tag color={group?.tag_color}>{group?.label}</Tag>
    },
    {
      title: t('children.list.is_walking.title'),
      key: 'is_walking',
      dataIndex: 'is_walking',
      sorter: true,
      render: (bool) => {
        if (bool) {
          return (
            <>
              <img src={IsWalking} alt="is_walking" />
              {t('children.list.is_walking.yes')}
            </>
          );
        }
        return (
          <>
            <img src={DontWalk} alt="dont-walk" />
            {t('children.list.is_walking.no')}
          </>
        );
      }
    },
    {
      title: t('children.list.age'),
      key: 'birth_date',
      dataIndex: 'birth_date',
      sorter: true,
      render: (date) => getAge(date, t)
    },
    {
      render: (record) => (
        <Row align="end">
          <Link
            to={{
              pathname: `${routes.CHILDREN}/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
            <Divider type="vertical" />
          </Link>
          <>
            <Button
              onClick={() => handleSiblingModal(record, 'edit', 'children')}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Button>
            <Divider type="vertical" />
          </>
          {record?.status !== 'ARCHIVED' && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => removeFromParents(record?._id, 'sibling')}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </Row>
      )
    }
  ];
};
