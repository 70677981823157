import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CreateDaycare } from './CreateDaycare';

/**
 * @description DaycareModal component
 * @component
 * @param {function} setRefresh - To set the refresh
 * @returns {JSX} The modal to create a daycare
 */
export const DaycareModal = ({ setRefresh }) => {
  const { t } = useTranslation();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  return (
    <Modal
      width={800}
      title={t('daycares.form.add')}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
    >
      <CreateDaycare
        setModalVisible={setIsModalOpenedLocaly}
        onCancel={closeModal}
        setRefresh={setRefresh}
      />
    </Modal>
  );
};

DaycareModal.propTypes = {
  setRefresh: PropTypes.func.isRequired
};
