import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Header } from '../Header';
import { CreateEditCommunicationModal } from './CreateEditCommunicationModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useContainerRender } from './useContainerRender';

/**
 * Component for displaying and managing different types of communication-related resources.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.resourceName - The type of resource to display and manage.
 *                                      Accepts 'events', 'infos', or 'news'.
 *
 * @returns {React.ReactElement} A React Element that renders the communication resources
 *                               based on the specified `resourceName`. It includes a header
 *                               for controls and a main content area for the resource list.
 *
 * @example
 * // To display a blog container for news resources
 * <BlogContainer resourceName="news" />
 */
export const BlogContainer = ({ resourceName }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [resources, setResources] = useState([]);
  const [modal, setModal] = useState();
  const [refresh, setRefresh] = useState(false);
  const [enums, setEnums] = useState();

  const handleModal = (record, purpose) => {
    const modalKey = Date.now();

    setModal(
      <CreateEditCommunicationModal
        key={modalKey}
        refresh={refresh}
        purpose={purpose}
        setRefresh={setRefresh}
        record={record}
        resourceName={resourceName}
      />
    );
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const containerRender = useContainerRender(
    resources,
    handleModal,
    enums,
    refresh,
    setRefresh
  );

  return (
    <>
      <Header
        resourceName={resourceName}
        setResources={setResources}
        refresh={refresh}
        setRefresh={setRefresh}
        handleModal={handleModal}
        enums={enums}
      />
      {containerRender[resourceName]}
      {modal}
    </>
  );
};

BlogContainer.propTypes = {
  resourceName: PropTypes.string.isRequired
};
