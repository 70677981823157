import PropTypes from 'prop-types';
import { List } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { InfosCard } from './InfosCard';

/**
 * Renders a list of information resources using the Ant Design Collapse component.
 *
 * @component
 *
 * This component takes an array of information resources and maps each resource into a collapsible panel.
 * Each panel displays the title, content, visibility tags, and action buttons for editing or deleting the resource.
 * The editing action triggers a modal for resource modification, and the delete action removes the resource
 * from the server and updates the parent component's state.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.resources - Array of information resource objects to be rendered.
 * @param {Function} props.handleModal - Function to handle opening the modal for editing resources.
 * @param {boolean} props.refresh - State indicating if a refresh is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @returns {JSX.Element} A list of collapsible panels representing the information resources.
 */
export const InfosRender = ({
  resources,
  handleModal,
  refresh,
  setRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `infos/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return (
    <List
      className="info-list"
      dataSource={resources}
      renderItem={(resource) => (
        <InfosCard
          key={resource._id}
          resource={resource}
          handleModal={handleModal}
          deleteResource={deleteResource}
        />
      )}
      itemLayout="vertical"
      pagination={{
        pageSize: 5
      }}
    />
  );
};

InfosRender.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  handleModal: PropTypes.func.isRequired
};

InfosRender.defaultProps = {
  refresh: false,
  setRefresh: null
};
