import Construction from '../../../assets/images/icons/noto_nesting-dolls.svg';
import Awakening from '../../../assets/images/icons/noto_teddy-bear.svg';
import Gardening from '../../../assets/images/icons/noto_hibiscus.svg';
import Language from '../../../assets/images/icons/noto_brain.svg';
import ManualWork from '../../../assets/images/icons/noto_crayon.svg';
import SoundAwakening from '../../../assets/images/icons/noto_violin.svg';
import Outside from '../../../assets/images/icons/noto_office-building.svg';
import Symbolic from '../../../assets/images/icons/noto_performing-arts.svg';
import Handling from '../../../assets/images/icons/noto_hand-with-fingers-splayed-medium-light-skin-tone.svg';
import Motricity from '../../../assets/images/icons/noto_soccer-ball.svg';
import Others from '../../../assets/images/icons/noto_puzzle-piece.svg';

/**
 * Array of activity categories, each with a resource name, an associated icon, and an empty data array.
 * @typedef {Object} ActivityCategory
 * @property {string} resourceName - The name of the resource associated with the activities.
 * @property {JSX.Element} icon - JSX representation of the category's icon.
 * @property {Array} data - An array to store activity data associated with the category.
 */
export const activities = [
  {
    resourceName: 'construction',
    icon: <img src={Construction} alt="construction-logo" />,
    data: []
  },
  {
    resourceName: 'awakening',
    icon: <img src={Awakening} alt="awakening-logo" />,
    data: []
  },
  {
    resourceName: 'gardening',
    icon: <img src={Gardening} alt="gardening-logo" />,
    data: []
  },
  {
    resourceName: 'language',
    icon: <img src={Language} alt="language-logo" />,
    data: []
  },
  {
    resourceName: 'manual_work',
    icon: <img src={ManualWork} alt="manual-work-logo" />,
    data: []
  },
  {
    resourceName: 'sound_awakening',
    icon: <img src={SoundAwakening} alt="sound-awakening-logo" />,
    data: []
  },
  {
    resourceName: 'outside',
    icon: <img src={Outside} alt="outside-logo" />,
    data: []
  },
  {
    resourceName: 'symbolic',
    icon: <img src={Symbolic} alt="symbolic-logo" />,
    data: []
  },
  {
    resourceName: 'handling',
    icon: <img src={Handling} alt="handling-logo" />,
    data: []
  },
  {
    resourceName: 'motricity',
    icon: <img src={Motricity} alt="motricity-logo" />,
    data: []
  },
  {
    resourceName: 'others',
    icon: <img src={Others} alt="others-logo" />,
    data: []
  }
];

/**
 * Transforms fetched activity data into an array of activity categories with associated data.
 * @param {Object} fetchedData - The fetched activity data with categories as keys.
 * @returns {Array<ActivityCategory>} Array of activity categories with associated data.
 */
export const transformData = (fetchedData) =>
  activities.map((activity) => ({
    ...activity,
    data: fetchedData[activity.resourceName] || []
  }));
