import PropTypes from 'prop-types';
import { Button, Flex, Divider } from 'antd';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useHealthFields } from '../Fields/healthFields';
import { useDietFields } from '../Fields/dietFields';
import { useContractFields } from '../Fields/contractFields';
import { useAuthorizationsFields } from '../Fields/authorizationsFields';

/**
 * Component for rendering the additional info form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @returns {React.Component} Additional info form component
 */
export const AdditionalInfo = ({ onFinish }) => {
  const generateFields = useGenerateFormItem();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authorizationsFields, dataAuthorizationField } =
    useAuthorizationsFields();
  const { healthFields } = useHealthFields();
  const { dietFields } = useDietFields();
  const { contractFields } = useContractFields();

  return (
    <>
      <Flex justify="center">{t('contract.form.titles.header_text')}</Flex>
      <Divider orientation="left">{t('contract.title')}</Divider>
      {contractFields.map((field) => generateFields('contract', field))}
      <Divider orientation="left">{t('contract.form.titles.health')}</Divider>
      {healthFields.map((field) => generateFields('contract', field))}
      <Divider orientation="left">{t('contract.form.titles.diet')}</Divider>
      {dietFields.map((field) => generateFields('contract', field))}
      <Divider orientation="left">
        {t('contract.form.titles.authorizations')}
      </Divider>
      {authorizationsFields.map((field) => generateFields('contract', field))}
      <Divider orientation="left">
        {t('contract.form.titles.data-authorizations')}
      </Divider>
      {dataAuthorizationField.map((field) => generateFields('contract', field))}
      <Flex justify="flex-end" gap="small">
        <Button type="link" danger onClick={() => navigate(-1)}>
          {t('buttons.cancel')}
          <CloseOutlined />
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </>
  );
};

AdditionalInfo.propTypes = {
  onFinish: PropTypes.func.isRequired
};
