import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useCAFScaleContext } from './CAFScaleContext';

/**
 * Generates an array of table configurations for different sections based on CAF scale data.
 *
 * @hook
 *
 * @returns {Array<Object>} An array of table configurations, each containing dividerTitle, resourceColumns, resourceName, and items properties.
 */
export const useTables = () => {
  const { t } = useTranslation();
  const { scale } = useCAFScaleContext();
  const {
    serviceForChildcareColumns,
    disabilityBonusColumns,
    socialDiversityBonusColumns
  } = useColumns();

  return [
    {
      dividerTitle: t(
        'global_settings.CAF_scale.dividers.service_for_childcare_0_to_5_years'
      ),
      resourceColumns: serviceForChildcareColumns,
      resourceName: 'service_for_childcare_0_to_5_years',
      items: scale?.service_for_childcare_0_to_5_years || []
    },
    {
      dividerTitle: t('global_settings.CAF_scale.dividers.disability_bonus'),
      resourceColumns: disabilityBonusColumns,
      resourceName: 'disability_bonus',
      items: scale?.disability_bonus || []
    },
    {
      dividerTitle: t(
        'global_settings.CAF_scale.dividers.social_diversity_bonus'
      ),
      resourceColumns: socialDiversityBonusColumns,
      resourceName: 'social_diversity_bonus',
      items: scale?.social_diversity_bonus || []
    }
  ];
};
