/**
 * Generates props for a file dragger component.
 *
 * @hook
 *
 * @param {Array<Object>} fileList - The list of files.
 * @param {Function} setFileList - The function to set the file list.
 * @param {Function} message - The function to display messages.
 * @param {Function} t - The translation function.
 * @returns {Object} Props object for the file dragger component.
 */
export const useDraggerProps = (fileList, setFileList, message, t) => ({
  multiple: false,
  onRemove: (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  },
  beforeUpload: (file) => {
    const existingFileName = fileList.some(
      (document) => document.name === file.name
    );
    if (existingFileName) return message(t('existing_file_name'));
    const fileExtension = file.name.split('.').pop();
    if (['xls', 'XLS', 'xlsx', 'XLSX'].includes(fileExtension)) {
      setFileList([...fileList, file]);
    } else {
      message('Not a XLSX file.');
      return true;
    }
    return false;
  },
  fileList
});
