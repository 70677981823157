import { Card, Col, Row, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useListContent } from './summaryListContent';
import { PlanningCircles } from '../../../components/PlanningCircles/PlanningCircles';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { Invoices } from './Invoices';

/**
 * @description
 * Summary is a component that displays the summary information of a pre-registration.
 * It is displayed in the PreRegistrationForm component.
 *
 * @component
 * @param {Object} householdDetails - The data of the household
 * @param {number} total_annual_income - The total annual income of the household
 * @param {Array} referenceWeeks - The reference weeks of the planning
 * @returns {React.Component} Summary component
 */
export const Summary = ({
  householdDetails,
  referenceWeeks,
  daycareDetails,
  contractDetails,
  setContractDetails
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryData, setSummaryData] = useState({
    effort_rate: 0,
    hourly_rate: 0
  });
  const { parents_information, estimated_quote } = useListContent({
    ...householdDetails,
    effort_rate: summaryData.effort_rate,
    hourly_rate: summaryData.hourly_rate
  });

  const getSummaryDatas = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `pre-registrations/summary`,
        body: {
          daycare: daycareDetails[0],
          number_of_children: Number(householdDetails.dependent_children),
          annual_incomes: Number(householdDetails.annual_incomes),
          referenceWeeks,
          start_date: contractDetails.contract.start_date,
          end_date: contractDetails.contract.end_date
        }
      });
      setSummaryData(data);
      setContractDetails({
        ...contractDetails,
        contract: {
          ...contractDetails.contract,
          effort_rate: data.effort_rate,
          hourly_rate: data.hourly_rate,
          invoice_schedule: data.invoice_schedule
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getSummaryDatas();
    })();
  }, []);

  const referenceWeeksIncludesSlots = !!referenceWeeks
    .map((week) =>
      week.map((day) =>
        day.time_slots.some((slot) => slot.start_time || slot.end_time)
      )
    )
    .filter(Boolean).length;

  return (
    <Skeleton loading={isLoading}>
      <Row gutter={[16, 16]} className="summary-wrapper">
        <Col xs={24} lg={12}>
          <Card
            title={t('pre-registrations.form.summary.planning')}
            bordered={false}
            style={{ width: '100%', marginBottom: 16 }}
          >
            {referenceWeeksIncludesSlots ? (
              <PlanningCircles referenceWeeks={referenceWeeks} />
            ) : (
              <Typography.Title>
                {t('pre-registrations.form.flexible')}
              </Typography.Title>
            )}
          </Card>
          <Card
            title={t('pre-registrations.form.summary.parents')}
            bordered={false}
            style={{ width: '100%', marginBottom: 16 }}
          >
            <Row>
              {parents_information.map((item) => (
                <Col span={item.span} key={item.content}>
                  <div className="summary-description-label">
                    {t(item.label)}
                  </div>
                  <div>{item.content}</div>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <Card
            title={t('pre-registrations.form.summary.estimated_quote')}
            bordered={false}
            style={{ width: '100%', marginBottom: 16 }}
          >
            <Row>
              {estimated_quote.map((item) => (
                <Col span={item.span} key={item.content}>
                  <div className="summary-description-label">
                    {t(item.label)}
                  </div>
                  <div>{item.content}</div>
                </Col>
              ))}
            </Row>
          </Card>

          <Invoices invoiceSchedule={summaryData.invoice_schedule} />
        </Col>
      </Row>
    </Skeleton>
  );
};
Summary.propTypes = {
  householdDetails: PropTypes.shape({
    dependent_children: PropTypes.number,
    effort_rate: PropTypes.number,
    annual_incomes: PropTypes.number
  }),
  daycareDetails: PropTypes.shape({
    daycares: PropTypes.arrayOf(PropTypes.string)
  }),
  referenceWeeks: PropTypes.arrayOf(PropTypes.shape({})),
  setContractDetails: PropTypes.func,
  contractDetails: PropTypes.shape({
    contract: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string
    })
  })
};
Summary.defaultProps = {
  householdDetails: {},
  referenceWeeks: [],
  setContractDetails: null,
  daycareDetails: {},
  contractDetails: {}
};
