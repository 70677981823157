import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { Button, Flex, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useFields } from './fields';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  drawerFormLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { ReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { ReferenceWeek } from '../pre-registrations/ReferenceWeek';
import {
  isNotEmptyWeek,
  weeksFormatter
} from '../pre-registrations/Summary/utils/weeks';

const EMPLOYEES_URL = '/employees';

/**
 * A React component for creating or updating employee information. This component
 * presents a form modal for inputting employee details including personal information,
 * contract details, and assigning positions and groups. It supports both creating a new
 * employee record and editing existing ones.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {Object} [props.record] - The existing employee data to be edited. Required if purpose is 'edit'.
 * @param {Function} props.setRefresh - Function to trigger refresh of the listing view upon successful submission.
 * @param {boolean} props.isLoading - Flag indicating if the component is currently loading data.
 * @param {Object} props.enums - Enumeration data for form fields like contract types.
 * @param {Array<Object>} props.teamPositions - List of team positions to be selected in the form.
 * @param {Array<Object>} props.childrenGroups - List of children groups to be selected in the form.
 * @returns {ReactElement} A modal dialog with a form for creating or updating employee information.
 *
 * @example
 * // To open a modal for creating a new employee:
 * <CreateUpdateEmployee
 *   purpose="create"
 *   setRefresh={refreshFunction}
 *   isLoading={false}
 *   enums={enumData}
 *   teamPositions={positionsData}
 *   childrenGroups={groupsData}
 * />
 *
 */
export const CreateUpdateEmployee = ({
  purpose,
  record,
  setRefresh,
  isLoading,
  enums,
  teamPositions,
  childrenGroups
}) => {
  const { t } = useTranslation();
  const { daycare, dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const generateFields = useGenerateFormItem();
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);

  const { fields, isFieldsLoading, displays, fileList, setDisplays } =
    useFields(purpose, isLoading, enums, teamPositions, childrenGroups, form);

  useEffect(() => {
    if (record && purpose === 'edit' && !isFieldsLoading) {
      setReferenceWeeks(record?.reference_weeks);
      setDisplays((prevState) => {
        const updatedState = { ...prevState };
        if (record.week_management === 'REFERENCE_WEEKS') {
          updatedState.referenceWeeks = true;
        }
        const selectedPosition = teamPositions.find(
          (position) => position._id === record.position._id
        );
        if (selectedPosition?.child_management) {
          updatedState.childrenGroups = true;
        }
        return updatedState;
      });

      form.setFieldsValue({
        ...record,
        position: record.position._id,
        children_groups: (record.children_groups || []).map(
          (group) => group._id
        ),
        ...(record.contract_start_date && {
          contract_start_date: dayjs.utc(record.contract_start_date)
        }),
        ...(record.contract_end_date && {
          contract_end_date: dayjs.utc(record.contract_end_date)
        })
      });
    }
  }, [purpose, record, isFieldsLoading]);

  const onFinish = async (body) => {
    const formData = new FormData();

    if (
      !isNotEmptyWeek(referenceWeeks) &&
      body.week_management === 'REFERENCE_WEEKS'
    ) {
      message(t('EMPTY_REFERENCE_WEEK'));
      return;
    }

    if (body.week_management === 'REFERENCE_WEEKS') {
      const updatedBody = {
        ...body,
        reference_weeks: weeksFormatter(referenceWeeks, purpose)
      };

      formData.append('values', JSON.stringify({ ...updatedBody, daycare }));
    } else {
      formData.append(
        'values',
        JSON.stringify({ ...body, daycare, reference_weeks: [] })
      );
    }

    if (fileList.length) {
      fileList.forEach((file) => formData.append('photo', file));
    }
    try {
      setIsSubmitting(true);
      const url =
        purpose === 'create'
          ? `${EMPLOYEES_URL}?daycare=${daycare}`
          : `${EMPLOYEES_URL}/${record._id}`;
      await dispatchAPI(purpose === 'create' ? 'POST' : 'PATCH', {
        url,
        body: formData
      });
      setIsModalOpenedLocaly(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t(`employees.form.title.${purpose}`)}
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
        setRefresh((prev) => !prev);
      }}
      footer={null}
      width={800}
    >
      <Form {...drawerFormLayout} onFinish={onFinish} form={form}>
        {fields.map((field) => generateFields('employees', field))}
        {displays.referenceWeeks && (
          <>
            {referenceWeeks?.map((_, index) => (
              <ReferenceWeek
                index={index}
                initialValues={record?.reference_weeks}
                purpose={purpose}
              />
            ))}
            {referenceWeeks?.length < 4 && (
              <Button
                type="dashed"
                block
                onClick={() => setReferenceWeeks([...referenceWeeks, []])}
              >
                {t('buttons.add_reference_week')}
              </Button>
            )}
          </>
        )}
        <Form.Item {...tailFormItemLayout} style={{ marginTop: 16 }}>
          <Flex justify="flex-end">
            <Button
              type="link"
              danger
              onClick={() => {
                setIsModalOpenedLocaly(false);
              }}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateUpdateEmployee.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string,
    user_values: PropTypes.shape({}),
    reference_weeks: PropTypes.arrayOf(PropTypes.shape({})),
    position: PropTypes.shape({
      _id: PropTypes.string
    }),
    children_groups: PropTypes.arrayOf(PropTypes.shape({})),
    contract_start_date: PropTypes.string,
    contract_end_date: PropTypes.string,
    week_management: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  enums: PropTypes.shape({}).isRequired,
  teamPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  childrenGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

CreateUpdateEmployee.defaultProps = {
  purpose: '',
  record: {
    _id: '',
    user_values: {}
  },
  setRefresh: () => {}
};
