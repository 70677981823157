import { ClockCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('pre-registrations.form.details'),
      key: 'details',
      dataIndex: 'details',
      render: () => (
        <>
          <ClockCircleFilled
            style={{ color: '#49C301', fontSize: 18, marginRight: 6 }}
          />
          <span>{t(`invoices.list.regular`)}</span>
        </>
      )
    },
    {
      title: t('pre-registrations.form.hourly_rate'),
      key: 'hourly_rate',
      dataIndex: 'hourly_rate',
      sorter: true
    },
    {
      title: t('pre-registrations.form.total_hours'),
      key: 'totalHours',
      dataIndex: 'totalHours',
      sorter: true,
      render: (record) => record && `${record.toFixed(2)} h`
    },
    {
      title: t('pre-registrations.form.all_included_total'),
      key: 'all_included_total',
      dataIndex: 'all_included_total',
      sorter: true,
      render: (record) => record && `${record.toFixed(2)} €`
    }
  ];
};
