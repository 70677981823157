import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { invoiceStatuses } from '../../../../../utils/constants/tagColors';

/**
 * @hook useColumns
 * @description The columns of the invoices table
 * @returns {Array} columns
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.list.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status) => (
        <Tag color={invoiceStatuses[status]}>
          {t(`invoices.tags.${status}`)}
        </Tag>
      )
    },
    {
      title: t('invoices.list.due_date'),
      key: 'due_date',
      dataIndex: 'due_date',
      sorter: true,
      render: (due_date) => dayjs(due_date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.list.total_with_taxes'),
      key: 'total',
      dataIndex: 'total',
      sorter: true,
      render: (total) => total && `${total} €`
    },
    {
      title: t('invoices.list.to_pay'),
      key: 'to_pay',
      dataIndex: 'to_pay',
      sorter: true
    }
  ];
};
