import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

const StyledSuccess = styled.div`
  width: 100%;
  text-align: center;
  flex: 1;
  padding: 8vh 24px;
`;

const SuccessTitle = styled.h1`
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
`;

/**
 * SuccessPreRegistration
 * @component
 * @description Component for success pre-registration
 *
 * @param {function} setSwitchToForm - Function to set screen to department choice.
 * @param {function} setCurrent - Function to set form step.
 *
 * @example
 * return (
 * <SuccessPreRegistration
 *   setSwitchToForm={setSwitchToForm}
 * />
 * )
 * @returns {component} SuccessPreRegistration
 */
export const SuccessPreRegistration = ({
  setSwitchToForm,
  setCurrent,
  setChildDetails,
  setContractDetails,
  setDaycareDetails,
  setHouseholdDetails,
  setParent1Details,
  setParent2Details,
  setReferenceWeeks
}) => {
  const { t } = useTranslation();
  const handleOtherPreRegistration = () => {
    setChildDetails({});
    setContractDetails({});
    setDaycareDetails({});
    setHouseholdDetails({});
    setParent1Details({});
    setParent2Details({});
    setReferenceWeeks([
      [
        { day: 'monday', time_slots: [] },
        { day: 'tuesday', time_slots: [] },
        { day: 'wednesday', time_slots: [] },
        { day: 'thursday', time_slots: [] },
        { day: 'friday', time_slots: [] }
      ]
    ]);
    setSwitchToForm(false);
    setCurrent(0);
  };

  return (
    <StyledSuccess>
      <SuccessTitle>{t('pre-registrations.success.title')}</SuccessTitle>
      <div>
        <p>{t('pre-registrations.success.message')}</p>
      </div>
      <div>
        <Button type="primary" onClick={handleOtherPreRegistration}>
          {t('buttons.add_other_pre-registration')}
        </Button>
      </div>
    </StyledSuccess>
  );
};

SuccessPreRegistration.propTypes = {
  setSwitchToForm: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  setChildDetails: PropTypes.func.isRequired,
  setContractDetails: PropTypes.func.isRequired,
  setDaycareDetails: PropTypes.func.isRequired,
  setHouseholdDetails: PropTypes.func.isRequired,
  setParent1Details: PropTypes.func.isRequired,
  setParent2Details: PropTypes.func.isRequired,
  setReferenceWeeks: PropTypes.func.isRequired
};
