import { Modal } from 'antd';

const handleAction = (
  tempFileList,
  filesConfiguration,
  selectedType,
  setFilesConfiguration,
  setFilesList,
  setTempFileList,
  setSelectedType,
  isIncluded,
  deleteDocumentByID,
  postDocument,
  origin
) => {
  tempFileList.forEach((file) => {
    const updatedList = [...filesConfiguration];
    if (isIncluded) {
      const indexToReplace = updatedList.findIndex(
        (config) => config.fileKey === selectedType
      );

      if (indexToReplace !== -1) {
        updatedList[indexToReplace] = {
          name: file.name,
          fileKey: selectedType,
          type: file.type
        };
      }
    } else {
      updatedList.push({
        name: file.name,
        fileKey: selectedType,
        type: file.type
      });
    }
    setFilesConfiguration(updatedList);
  });
  setFilesList((prevList) => {
    if (isIncluded) {
      const fileConfig = filesConfiguration.find(
        (itemDoc) => itemDoc?.fileKey === selectedType
      );

      if (fileConfig) {
        const indexToReplace = prevList.findIndex(
          (file) =>
            file?.metadata?.originalName === fileConfig.name ||
            file?.name === fileConfig.name
        );

        if (indexToReplace !== -1) {
          if (prevList[indexToReplace]?._id) {
            deleteDocumentByID(prevList[indexToReplace]._id);
          }
          const updatedList = [...prevList];
          updatedList[indexToReplace] = tempFileList[0];
          return updatedList;
        }
      }
    } else {
      return [...prevList, ...tempFileList];
    }

    return prevList;
  });
  if (origin === 'document-tab') {
    postDocument(tempFileList);
  }
  setTempFileList([]);
  setSelectedType();
};

/**
 * Handles the temporary file list saving operation.
 * It updates the files configuration and list based on the provided temporary file list.
 *
 * @function
 * @param {Array} tempFileList - The temporary list of files to be saved.
 * @param {Array} filesConfiguration - The current configuration of files.
 * @param {string} selectedType - The selected type of file.
 * @param {Function} setFilesConfiguration - Setter function for updating the files configuration.
 * @param {Function} setFilesList - Setter function for updating the file list.
 * @param {Function} setTempFileList - Setter function for updating the temporary file list.
 * @param {Function} setSelectedType - Setter function for updating the selected file type.
 * @param {Function} t - Translation module.
 * @param {Function} deleteDocumentByID - Function to delete a document thanks to its ID.
 * @param {Function} postDocument - Function to post the document and update contract documents.
 * @param {string} origin - Origin of the function call.
 *
 * @returns {void}
 */
export const handleTempFileListSave = (
  tempFileList,
  filesConfiguration,
  selectedType,
  setFilesConfiguration,
  setFilesList,
  setTempFileList,
  setSelectedType,
  t,
  deleteDocumentByID,
  postDocument,
  origin
) => {
  const isIncluded = filesConfiguration.some(
    (config) => config.fileKey === selectedType
  );
  const action = () =>
    handleAction(
      tempFileList,
      filesConfiguration,
      selectedType,
      setFilesConfiguration,
      setFilesList,
      setTempFileList,
      setSelectedType,
      isIncluded,
      deleteDocumentByID,
      postDocument,
      origin
    );
  if (isIncluded) {
    Modal.confirm({
      title: t('contracts.modal.replace-action'),
      content: t('contracts.modal.replace-content'),
      onOk: () => action()
    });
  } else return action();
  return false;
};
