import dayjs from 'dayjs';

/**
 * Generates functions to determine which hours and minutes should be disabled for a time picker.
 *
 * @function
 *
 * @param {number} hourStart - The starting hour of the selectable range.
 * @param {number} hourEnd - The ending hour of the selectable range.
 * @param {string | null} selectedHourStartTime - The currently selected start time in ISO format, if any.
 * @param {string | null} selectedHourEndTime - The currently selected end time in ISO format, if any.
 * @param {number} minuteStart - The starting minute of the selectable range.
 * @param {number} minuteEnd - The ending minute of the selectable range.
 * @param {Array<Object>} referenceDay - Array of objects representing reference days with start and end times.
 * @returns {Object} An object containing functions to disable hours, minutes, and seconds in a time picker.
 */
export const disabledTime = (
  hourStart,
  hourEnd,
  selectedHourStartTime,
  selectedHourEndTime,
  minuteStart,
  minuteEnd,
  referenceDay
) => ({
  disabledHours: () => {
    const disabledHours = [];
    const selectedStartHour = selectedHourStartTime
      ? dayjs(selectedHourStartTime).hour()
      : null;
    const selectedEndHour = selectedHourEndTime
      ? dayjs(selectedHourEndTime).hour()
      : null;

    for (let i = 0; i < hourStart; i += 1) {
      disabledHours.push(i);
    }
    for (let i = hourEnd + 1; i < 24; i += 1) {
      disabledHours.push(i);
    }

    if (selectedStartHour !== null && selectedEndHour !== null) {
      for (let i = 0; i <= selectedEndHour; i += 1) {
        if (!disabledHours.includes(i)) {
          disabledHours.push(i);
        }
      }
    }

    return disabledHours;
  },
  disabledMinutes: (selectedHour) => {
    const disabledMinutes = [];
    if (selectedHour === hourStart) {
      for (let i = 0; i < minuteStart; i += 1) {
        disabledMinutes.push(i);
      }
    }
    if (selectedHour === hourEnd) {
      for (let i = minuteEnd + 1; i < 60; i += 1) {
        disabledMinutes.push(i);
      }
    }

    if (referenceDay && selectedHour === hourEnd) {
      referenceDay.forEach((day) => {
        const endHour = dayjs.utc(day.end_time).hour();
        if (endHour === hourEnd) {
          const endMinute = dayjs.utc(day.end_time).minute();
          for (let i = endMinute + 1; i < 60; i += 1) {
            if (!disabledMinutes.includes(i)) {
              disabledMinutes.push(i);
            }
          }
        }
      });
    }

    return disabledMinutes;
  },
  disabledSeconds: () => []
});
