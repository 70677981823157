import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
// import { getMessaging } from 'firebase/messaging';
// import { messaging } from '../firebase';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const SocketContext = createContext({});

/**
 * @description Socket context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Socket context provider
 */
export const SocketContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { token, dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [socket, setSocket] = useState();
  const [connectedUsers, setConnectedUsers] = useState([]);

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.emit('logout');
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  useEffect(() => {
    if (socket)
      socket.on('connectedUsers', (users) => setConnectedUsers(users));
  }, [socket]);

  const [notifications, setNotifications] = useState([]);
  const [notificationsUnreadCount, setNotificationsUnreadCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [loadedCount, setLoadedCount] = useState(0);
  const count = 5;

  // const requestPermission = () => {
  //   const messagingInstance = getMessaging(messaging);
  //   messagingInstance
  //     .requestPermission()
  //     .then(() => messagingInstance.getToken())
  //     .then((requestToken) => {
  //       console.log('Token:', requestToken);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  const getNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications?limit=${count}&populate=item.type,recipients&recipients=${user?._id}`
      });
      setNotifications(data);
      setNotificationsList(data);
      setLoadedCount(data.length);
    } catch (e) {
      message(e);
    }
  };

  const getNotificationsCount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications/count`
      });
      setNotificationsUnreadCount(data.unreadCount);
      setNotificationsCount(data.count);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token && socket && user && user?._id) {
      setLoading(true);
      (async () => {
        // requestPermission();
        await getNotifications();
        await getNotificationsCount();
      })();
      setInitLoading(false);
      setLoading(false);
    }
  }, [refreshNotifications, socket, token, user]);

  useEffect(() => {
    if (socket) {
      const handleNewNotification = async () => {
        await getNotifications();
        await getNotificationsCount();
      };

      socket.on('newNotification', handleNewNotification);

      return () => {
        socket.off('newNotification', handleNewNotification);
      };
    }
    return false;
  }, [socket]);

  const onLoadMore = async () => {
    const skipValue = loadedCount;
    setLoading(true);
    setNotificationsList(
      notifications.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: {},
          picture: {}
        }))
      )
    );
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications?limit=${count}&skip=${skipValue}&populate=item.type,recipients&recipients=${user?._id}`
      });
      const newData = notifications.concat(data);
      setNotifications(newData);
      setNotificationsList(newData);
      setLoadedCount(loadedCount + data.length);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message(e);
    }
  };

  const loadMore =
    !initLoading &&
    !loading &&
    notificationsList.length !== notificationsCount ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px'
        }}
      >
        <Button type="primary" onClick={onLoadMore}>
          {t('buttons.loading_more')}
        </Button>
      </div>
    ) : null;

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectedUsers,
        notifications,
        refreshNotifications,
        setRefreshNotifications,
        loadMore,
        initLoading,
        loading,
        notificationsList,
        notificationsUnreadCount
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
