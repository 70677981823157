import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Divider, Card, Flex, Col } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useParentsFields } from '../Fields/parentsFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useHouseholdFields } from '../Fields/householdFields';
import { formItemLayout } from '../../../utils/constants/formLayout';

/**
 * Component for rendering the parent forms.
 * This component is rendered inside the pre-registration form.
 * It renders two forms, one for each parent.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @returns {React.Component} Parent forms component
 */
export const ParentForms = ({ onFinish, handlePrevious, initialValues }) => {
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const [isMonoparentalFamily, setIsMonoparentalFamily] = useState(false);
  const { parentsFields } = useParentsFields(form);
  const { householdFields } = useHouseholdFields(
    isMonoparentalFamily,
    setIsMonoparentalFamily
  );
  const { t } = useTranslation();

  const handleFinish = (values) => {
    const combinedValues = {
      parent1: values.parent1,
      parent2: values.parent2,
      household: values.household
    };
    onFinish(combinedValues);
  };

  const handleCopyAddress = () => {
    const parent1Address = form.getFieldValue(['parent1', 'address']);
    form.setFieldsValue({ parent2: { address: parent1Address } });
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
    if (initialValues?.household?.monoparental_family) {
      setIsMonoparentalFamily(initialValues.household.monoparental_family);
    }
  }, [initialValues, form]);

  return (
    <Form
      onFinish={handleFinish}
      {...formItemLayout}
      form={form}
      className="prereg-form parent-form"
    >
      <Flex vertical gap="large">
        <Card title={t('pre-registrations.form.household.title')}>
          {householdFields.map((field) => (
            <React.Fragment key={field.name}>
              {generateFields('pre-registrations', field)}
            </React.Fragment>
          ))}
        </Card>
        <Row justify="space-between" gutter={[20, 20]}>
          <Col xs={24} xl={!isMonoparentalFamily ? 12 : 24}>
            <Card title={t('pre-registrations.form.parent1.title')}>
              {parentsFields('parent1').map((field, index) => (
                <React.Fragment key={field.name}>
                  {generateFields('pre-registrations', field)}
                  {index === 3 && (
                    <Divider orientation="left">
                      {t('pre-registrations.divider.contact_details')}
                    </Divider>
                  )}
                  {index === 13 && (
                    <Divider orientation="left">
                      {t(
                        'pre-registrations.divider.professional_and_familial_situation'
                      )}
                    </Divider>
                  )}
                </React.Fragment>
              ))}
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            {!isMonoparentalFamily && (
              <Card title={t('pre-registrations.form.parent2.title')}>
                {parentsFields('parent2').map((field, index) => (
                  <React.Fragment key={field.name}>
                    {generateFields('pre-registrations', field)}
                    {index === 3 && (
                      <Flex
                        wrap="nowrap"
                        align="center"
                        gap="small"
                        style={{ maxWidth: '100%' }}
                      >
                        <Divider
                          orientation="left"
                          style={{ minWidth: 'unset' }}
                        >
                          {t('pre-registrations.divider.contact_details')}
                        </Divider>
                        <Button onClick={handleCopyAddress}>
                          {' '}
                          {t('buttons.copy_parent1_address')}
                        </Button>
                      </Flex>
                    )}
                    {index === 13 && (
                      <Divider orientation="left">
                        {t(
                          'pre-registrations.divider.professional_and_familial_situation'
                        )}
                      </Divider>
                    )}
                  </React.Fragment>
                ))}
              </Card>
            )}
          </Col>
        </Row>
        <Flex justify="flex-end" gap="small">
          <Button type="primary" onClick={handlePrevious}>
            <ArrowLeftOutlined />
            {t('buttons.previous')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.next')}
            <ArrowRightOutlined />
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

ParentForms.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    household: {
      monoparental_family: PropTypes.bool
    }
  }),
  purpose: PropTypes.string.isRequired
};
ParentForms.defaultProps = {
  initialValues: {}
};
