import { Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import IsWalking from '../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../assets/images/icons/healthicons_baby-0306m.svg';

/**
 * Custom hook for generating content for a child list.
 *
 * @param {Object} data - Child-related data to be used for generating content.
 * @param {string} data.birth_date - The birth date of the child.
 * @param {Object} data.group - The group information of the child.
 * @param {string} data.group.tag_color - The tag color of the child's group.
 * @param {string} data.group.label - The label of the child's group.
 * @param {boolean} data.is_walking - A boolean indicating whether the child is walking.
 * @param {string} data.hygiene - The hygiene status of the child.
 * @param {Object} data.weight - The weight information of the child.
 * @param {number} data.weight.value - The weight value of the child.
 * @param {string} data.weight.unit - The unit of weight measurement.
 * @returns {Object} An object containing child information content.
 */
export const useListContent = (data = {}) => {
  const { birth_date, group, is_walking, hygiene, weight } = data;
  const { t } = useTranslation();

  const childInformation = [
    {
      label: 'pre-registrations.show.child.birth_date',
      content: (birth_date && dayjs(birth_date).format('DD/MM/YYYY')) || '',
      span: 2
    },
    {
      label: 'children.list.group',
      content: group && <Tag color={group?.tag_color}>{group?.label}</Tag>,
      span: 1
    },
    {
      label: 'children.list.is_walking.title',
      content: is_walking ? (
        <Flex align="center" gap="small">
          <img src={IsWalking} alt="is_walking" />
          {t('children.list.is_walking.yes')}
        </Flex>
      ) : (
        <Flex align="center" gap="small">
          <img src={DontWalk} alt="dont-walk" />
          {t('children.list.is_walking.no')}
        </Flex>
      ),
      span: 3
    },
    {
      label: 'children.show.hygiene',
      content: hygiene ? t(`children.show.hygieneEnums.${hygiene}`) : '',

      span: 2
    },
    {
      label: 'children.show.weight',
      content:
        weight?.value && weight?.unit ? `${weight.value} ${weight.unit}` : '',
      span: 1
    }
  ];

  return {
    childInformation
  };
};
