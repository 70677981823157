import { Modal } from 'antd';

/**
 * Deletes a document based on its name from the configuration and files list.
 * If a matching configuration or file is found, it will be removed.
 *
 * @function
 * @param {string} name - The name of the document to be deleted.
 * @param {Array} filesConfiguration - The current configuration of files.
 * @param {Function} setFilesConfiguration - Setter function to update the files configuration.
 * @param {Function} setFilesList - Setter function to update the file list.
 * @param {Array} filesList - The current list of files.
 * @param {Function} deleteDocumentByID - Function to delete a document by File ID.
 * @param {Function} t - Translation module.
 *
 * @return {void}
 */
export const deleteDocument = (
  name,
  filesConfiguration,
  setFilesConfiguration,
  setFilesList,
  filesList,
  deleteDocumentByID,
  t
) => {
  Modal.confirm({
    title: t('contracts.modal.delete-action'),
    content: t('contracts.modal.delete-content'),
    onOk: () => {
      const configIndex = filesConfiguration.findIndex(
        (item) => item.fileKey === name
      );

      if (configIndex !== -1) {
        setFilesConfiguration((prevList) => {
          const updatedList = [...prevList];
          updatedList.splice(configIndex, 1);
          return updatedList;
        });
      }

      if (configIndex !== -1) {
        const fileIndex = filesList.findIndex(
          (file) =>
            file.name === filesConfiguration[configIndex].name ||
            file.metadata.originalName === filesConfiguration[configIndex].name
        );
        if (fileIndex !== -1) {
          setFilesList((prevList) => {
            const updatedList = [...prevList];
            if (updatedList[fileIndex]?._id) {
              deleteDocumentByID(updatedList[fileIndex]._id);
            }
            updatedList.splice(fileIndex, 1);
            return updatedList;
          });
        }
      }
    }
  });
};
