import PropTypes from 'prop-types';
import { Row, Col, Flex } from 'antd';
import { PreRegistrationCard } from './PreRegistration/PreRegistrationCard';
import { ChildCard } from './Child/ChildCard';
import { HouseholdCard } from './Household/HouseholdCard';
import { ParentCard } from './Parent/ParentCard';
import { ContractCard } from './Contract/ContractCard';
import { PlanningCard } from './Planning/PlanningCard';
import { QuotationCard } from './Quotation/QuotationCard';

/**
 * Renders the pre-registration information section.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the pre-registration.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @returns {JSX.Element} The rendered component.
 */
export const PreRegistrationInfos = ({ data, isLoading }) => (
  <Row gutter={[16, 16]}>
    <Col xs={24} xl={12}>
      <Flex vertical gap="middle">
        <PreRegistrationCard isLoading={isLoading} data={data} />
        <ChildCard isLoading={isLoading} data={data} />
        <HouseholdCard isLoading={isLoading} data={data} />
        {data?.parents?.map((parent, index) => (
          <ParentCard
            key={parent}
            isLoading={isLoading}
            data={parent}
            index={index}
          />
        ))}
      </Flex>
    </Col>
    <Col xs={24} xl={12}>
      <Flex vertical gap="middle">
        <ContractCard isLoading={isLoading} data={data} />
        <PlanningCard
          isLoading={isLoading}
          data={data?.contract_infos?.reference_weeks}
        />
        <QuotationCard isLoading={isLoading} data={data?.contract_infos} />
      </Flex>
    </Col>
  </Row>
);

PreRegistrationInfos.propTypes = {
  data: PropTypes.shape({
    parents: PropTypes.arrayOf(PropTypes.shape({})),
    contract_infos: PropTypes.shape({
      reference_weeks: PropTypes.arrayOf(
        PropTypes.shape({
          weekNumber: PropTypes.number.isRequired,
          startDate: PropTypes.string.isRequired,
          endDate: PropTypes.string.isRequired
        })
      )
    })
  }),
  isLoading: PropTypes.bool
};

PreRegistrationInfos.defaultProps = {
  data: {
    parents: [],
    contract_infos: {
      reference_weeks: [
        {
          weekNumber: 0,
          startDate: '',
          endDate: ''
        }
      ]
    }
  },
  isLoading: false
};
