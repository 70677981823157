import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Flex } from 'antd';
import BirthdayCakeDisabled from '../../../assets/images/icons/noto_birthday-cake_gray.svg';
import BirthdayCake from '../../../assets/images/icons/noto_birthday-cake.svg';

dayjs.extend(duration);

/**
 * Calculates the number of days until the next upcoming birthday
 * based on the provided birth date.
 *
 * @function
 * @param {string|Date} date - The birth date to calculate from.
 * @returns {React.ReactNode} A JSX element containing an image
 *                            (either a birthday cake or a disabled birthday cake)
 *                            and the number of days until the next birthday.
 *                            Returns an empty string if the birthday is more than a week away.
 *
 * @example
 *
 * calculateBirthDateDiff('1990-01-01');
 * // Output: <><img src={BirthdayCakeDisabled} alt="birthday-cake-disabled" />5</>
 * // (Assuming today's date is '2022-12-27' and the birthday is 5 days away.)
 */
export const calculateBirthDateDiff = (date) => {
  if (date) {
    const birthdayDate = dayjs(date);
    const today = dayjs();
    let component;

    // Determine the birthday for the current year.
    let nextBirthday = dayjs()
      .year(today.year())
      .month(birthdayDate.month())
      .date(birthdayDate.date());

    // If the birthday has already passed this year, consider the next year.
    if (today.isAfter(nextBirthday)) {
      nextBirthday = nextBirthday.add(1, 'year');
    }

    const diffInDays = nextBirthday.diff(today, 'day');

    if (diffInDays > 0 && diffInDays < 7) {
      component = (
        <Flex align="center">
          <img src={BirthdayCakeDisabled} alt="birthday-cake-disabled" />
          <span
            style={{
              color: 'var(--mediumGray)',
              fontSize: 12,
              whiteSpace: 'nowrap'
            }}
          >{`J-${diffInDays}`}</span>
        </Flex>
      );
    } else if (diffInDays === 0) {
      component = <img src={BirthdayCake} alt="birthday-cake" />;
    } else {
      component = '';
    }

    return { component, diffInDays };
  }
  return { component: '', diffInDays: undefined };
};
