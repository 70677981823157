import { useTranslation } from 'react-i18next';
import { Col, Modal, Row, Select } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { generateDocumentsOld } from './generateDocumentsOld';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { generateMonthOptions } from '../utils/generateMonthOptions';

/**
 * A modal component for generating documents related to a child in a daycare.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.open - Determines whether the modal is open.
 * @param {Function} props.setOpen - A function to toggle the modal's open state.
 * @param {string} props.childName - The name of the child for whom the document is being generated.
 * @param {boolean} props.RIB - Presence of RIB.
 * @param {Object} props.contractsInfos - Dates of active contract.
 * @returns {JSX.Element} The JSX representation of the component.
 */
export const GenerateDocumentModal = ({
  open,
  setOpen,
  childName,
  RIB,
  contractsInfos
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [documentType, setDocumentType] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const { id } = useParams();

  const options = [
    {
      value: 'PRESENCE_CERTIFICATE',
      label: t('children.documents.modal.options.PRESENCE_CERTIFICATE')
    },
    {
      value: 'PAJE_ATTESTATION',
      label: t('children.documents.modal.options.PAJE_ATTESTATION')
    },
    ...(RIB
      ? [
          {
            value: 'SEPA_MANDATE',
            label: t('children.documents.modal.options.SEPA_MANDATE')
          }
        ]
      : [])
  ];

  const monthOptions = generateMonthOptions(contractsInfos);

  const handleModal = () => {
    if (!documentType && open) {
      message(t('children.documents.modal.missing_type'));
    } else if (documentType && open) {
      generateDocumentsOld(
        dispatchAPI,
        message,
        documentType,
        id,
        daycare,
        t,
        selectedMonth
      );
      setOpen(!open);
    } else setOpen(!open);
  };

  return (
    <Modal
      open={open}
      onOk={handleModal}
      onCancel={() => setOpen(!open)}
      title={`${t('children.documents.modal.documents_title')} ${childName}`}
    >
      <Row>
        <Col span={8}>{t('children.documents.modal.select_type')}</Col>
        <Col span={16}>
          <Select
            options={options}
            onChange={(value) => setDocumentType(value)}
            style={{ width: 250 }}
          />
        </Col>
        {documentType === 'PAJE_ATTESTATION' && (
          <>
            <Col span={8}>{t('children.documents.modal.select_month')}</Col>
            <Col span={16}>
              <Select
                options={monthOptions}
                onChange={(value) => setSelectedMonth(value)}
                style={{ width: 250 }}
              />
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
};

GenerateDocumentModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  childName: PropTypes.string,
  RIB: PropTypes.bool,
  contractsInfos: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  })
};

GenerateDocumentModal.defaultProps = {
  open: false,
  setOpen: null,
  childName: undefined,
  RIB: undefined,
  contractsInfos: null
};
