import { Divider, Flex, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import {
  determineAnalysisColor,
  formatNumberWithSpaces
} from '../../../../utils/formatter';
import { CAFStatementTable } from './CAFStatementTable';

/**
 * Displays CAF (Caisse d'Allocation Familiale) statement data in a table format.
 *
 * This component presents financial data related to CAF statements, allowing users to toggle
 * between 'previsional' and 'real' data modes using a switch. It utilizes the `useAnalysisContext`
 * to fetch the date range filter and the `useColumns` hook to dynamically generate table columns
 * based on the selected mode. The component is structured with rows and columns for layout, using
 * dividers to separate sections for expenses and outcomes, each represented by a table.
 *
 * @component
 * @returns {JSX.Element} The CAFStatement component with two tables for expenses and outcomes.
 */
export const CAFStatementContainer = () => {
  const { t } = useTranslation();
  const { CAFStatement, CAFStatementMode, setCAFStatementMode, dateFilter } =
    useAnalysisContext();

  const handleSwitch = () => {
    if (CAFStatementMode === 'previsional') {
      setCAFStatementMode('real');
    } else {
      setCAFStatementMode('previsional');
    }
  };

  const result = CAFStatement ? CAFStatement.result : 0;

  return (
    <Flex vertical gap="small">
      <Flex justify="space-between" align="center">
        {`${t('analysis.budget_forecast')} ${dayjs(dateFilter)
          .startOf('year')
          .format('DD/MM/YYYY')} ${t('analysis.to')} ${dayjs(dateFilter)
          .endOf('day')
          .format('DD/MM/YYYY')}`}
        <Flex align="center" gap="small">
          {t('analysis.financial_year_result')}
          <span
            style={{ color: determineAnalysisColor(result) }}
          >{`${formatNumberWithSpaces(result)}€`}</span>
        </Flex>
      </Flex>
      <Flex align="center" gap="small">
        {t('analysis.table.previsional')}
        <Switch onChange={handleSwitch} />
        {t('analysis.table.real')}
      </Flex>

      <Divider>{t('analysis.expenses')}</Divider>
      <CAFStatementTable
        datas={(CAFStatement?.datas || []).filter(
          (data) => data.type === 'EXPENSES'
        )}
        mode={CAFStatementMode}
      />
      <Divider>{t('analysis.outcomes')}</Divider>
      <CAFStatementTable
        datas={(CAFStatement?.datas || []).filter(
          (data) => data.type === 'INCOMES'
        )}
        mode={CAFStatementMode}
      />

      <Flex align="center" justify="center">
        {t('analysis.financial_year_result')}
        <span
          style={{ color: determineAnalysisColor(result) }}
        >{`${formatNumberWithSpaces(result)}€`}</span>
      </Flex>
    </Flex>
  );
};
