// Main nav menuItems
export const routes = {
  HOME: '/',
  PLANNING: '/planning',
  MESSAGES_AND_NOTES: '/message-and-notes'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PRE_REGISTRATION: '/pre-registrations',
  TIME_RECORDER: '/time-recorder',
  PASSWORD_CREATION: '/post-pwd',
  SIGNATURES: '/signatures',
  CHECKOUT_PAYMENT: '/checkout-payment'
};

// url search parameters
export const pathSearches = {};
