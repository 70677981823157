/**
 * @function contentToDisplay
 * @description
 * contentToDisplay is a function that returns the content to display.
 * It hides the content if it is not visible.
 *
 * @param {string} content - The content to display
 * @param {boolean} visible - If the content is visible
 * @exemple contentToDisplay('FR3217569000702497525694Q88', false) => 'FR******************88'
 * @exemple contentToDisplay('FR3217569000702497525694Q88', true) => 'FR3217569000702497525694Q88'
 * @returns {string} The content to display
 */
export default (content, visible) => {
  if (!content) {
    return '-';
  }

  if (!visible) {
    const firstPart = content.slice(0, 2); // Pick the first 2 characters
    const hiddenPart = content.slice(2, -2).replace(/\S/g, '*'); // Replace all characters except spaces with *
    const lastPart = content.slice(-2); // Pick the last 2 characters
    return `${firstPart}${hiddenPart}${lastPart}`;
  }

  return content;
};
