import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { drawerTailFormLayout } from '../../utils/constants/formLayout';

/**
 * ModalTail component renders the tail part of a modal form, including cancel and save buttons.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {Function} props.handleModal - The function to handle modal closing.
 * @param {boolean} props.isSubmitting - A flag indicating whether the form is submitting or not.
 * @returns {JSX.Element} The JSX representation of the ModalTail component.
 */
export const ModalTail = ({ handleModal, isSubmitting }) => {
  const { t } = useTranslation();
  return (
    <Form.Item {...drawerTailFormLayout}>
      <Row justify="end">
        <Button
          style={{ margin: '0 10px' }}
          type="link"
          danger
          onClick={handleModal}
        >
          {`${t('buttons.cancel')} `}
          <CloseOutlined />
        </Button>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          {`${t('buttons.save')} `}
          <CheckOutlined />
        </Button>
      </Row>
    </Form.Item>
  );
};

ModalTail.propTypes = {
  handleModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};
