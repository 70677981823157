/**
 * Generates a modal title based on the purpose, child name, resource name, translation function, and record data.
 *
 * @param {string} purpose - The purpose of the modal ('create' or 'edit').
 * @param {string} childName - The name of the child associated with the parent.
 * @param {string} resourceName - The name of the resource.
 * @param {function} t - The translation function for localization.
 * @param {Object} record - The record object associated with the resource.
 * @returns {string} The generated modal title.
 * @example
 * // Usage example:
 * const title = modalTitle('create', 'Child Name', 'parent', translateFunction, parentRecord);
 */
export const modalTitle = (purpose, childName, resourceName, t, record) => {
  const modalPurpose = purpose === 'create' ? 'new_parent' : 'edit_parent';
  const modalSuffix = purpose === 'create' ? childName : '';

  if (resourceName === 'parent') {
    return `${t(`children.show.parents.modal.${modalPurpose}`)}${modalSuffix}`;
  }
  return `${record?.first_name} ${record?.last_name}`;
};
