import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { customerNature } from '../../../utils/constants/tagColors';
import UnpaidIcon from '../../../assets/images/icons/noto_cross-mark.svg';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('customer-account.list.unpaid'),
      key: 'unpaid',
      dataIndex: 'total_to_pay',
      render: (total_to_pay) =>
        total_to_pay > 0 ? (
          <img src={UnpaidIcon} alt={t('customer-account.list.unpaid')} />
        ) : (
          ''
        ),
      sorter: true
    },
    {
      title: t('customer-account.list.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('customer-account.list.customer_nature'),
      key: 'customer_nature',
      dataIndex: 'customer_nature',
      render: (text) => (
        <Tag color={customerNature[text]}>
          {t(`customer-account.form.${text}`)}
        </Tag>
      ),
      sorter: true
    },

    {
      title: t('customer-account.list.invoiced_amount'),
      key: 'total',
      dataIndex: 'total',
      render: (total) => `${total} €` || '0.00 €',
      sorter: true
    },
    {
      title: t('customer-account.list.remaining_amount'),
      key: 'total_to_pay',
      dataIndex: 'total_to_pay',
      render: (total_to_pay) => `${total_to_pay} €` || '0.00 €',
      sorter: true
    }
  ];
};
