/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Space, Form, Table, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useColumns } from './columns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useVaccinesFields } from './vaccinesFields';
import { tailFormItemLayout } from '../../../../utils/constants/formLayout';
import { ModalTail } from '../../../../components/ModalTail/ModalTail';

/**
 * VaccinesList component for displaying and managing vaccine records.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Array} props.vaccineData - An array of vaccine data objects.
 * @param {boolean} props.refresh - Indicates if a refresh action is needed.
 * @param {function} props.setRefresh - Function to set the refresh state.
 * @param {string} props.healthID - The ID of the health record associated with the vaccines.
 * @param {Array} props.daycareVaccines - An array of daycare vaccine objects.
 * @returns {JSX.Element} The rendered VaccinesList component.
 */
export const VaccinesList = ({
  vaccineData,
  refresh,
  setRefresh,
  healthID,
  daycareVaccines
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const generateFields = useGenerateFormItem();
  const { vaccinesFields } = useVaccinesFields(daycareVaccines);
  const [vaccineToEdit, setVaccineToEdit] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formPurpose, setFormPurpose] = useState();

  const handleModal = (record, purpose) => {
    setFormPurpose(purpose);
    setIsModalVisible((prevOpen) => {
      if (prevOpen) {
        setVaccineToEdit();
        form.resetFields();
        return false;
      }
      setVaccineToEdit(record);
      return true;
    });
  };

  const columns = useColumns(
    refresh,
    setRefresh,
    handleModal,
    healthID,
    daycareVaccines
  );

  const addVaccines = async (values) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/healths/${healthID}`,
        body: values
      });
      form.resetFields();
      setIsSubmitting(false);
      handleModal();
      setRefresh(!refresh);
    } catch (e) {
      setIsSubmitting(false);

      if (e.response) message(e.response.status);
    }
  };

  const editVaccines = async (values) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/healths/${healthID}/${vaccineToEdit._id}`,
        body: values
      });
      form.resetFields();
      setIsSubmitting(false);
      handleModal();
      setRefresh(!refresh);
    } catch (e) {
      setIsSubmitting(false);

      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    if (formPurpose === 'add') {
      await addVaccines(values);
    } else await editVaccines(values);
  };

  useEffect(() => {
    if (vaccineToEdit) {
      form.setFieldsValue({
        ...vaccineToEdit,
        vaccine: vaccineToEdit.vaccine._id
      });
    }
  }, [vaccineToEdit]);

  return (
    <>
      <Table columns={columns} dataSource={vaccineData} scroll={{ x: 1000 }} />

      <Space direction="vertical" style={{ width: '100%' }}>
        <Button
          type="dashed"
          block
          onClick={() => handleModal(undefined, 'add')}
        >
          {t('buttons.add')}
        </Button>
      </Space>
      <Modal
        title={t('children.show.health.title.add_update_vaccine')}
        open={isModalVisible}
        onCancel={handleModal}
        footer={null}
        width="60%"
      >
        <Form onFinish={handleSubmit} form={form}>
          {(vaccinesFields || []).map((field) =>
            generateFields('vaccines', field)
          )}
          <ModalTail isSubmitting={isSubmitting} handleModal={handleModal} />
        </Form>
      </Modal>
    </>
  );
};
VaccinesList.propTypes = {
  vaccineData: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  healthID: PropTypes.string,
  daycareVaccines: PropTypes.arrayOf(PropTypes.shape({}))
};

VaccinesList.defaultProps = {
  vaccineData: null,
  refresh: false,
  setRefresh: null,
  healthID: undefined,
  daycareVaccines: null
};
