/**
 * Generates the URL visibility filter based on the user role and provided visibility filter.
 *
 * @function
 *
 * @param {Object} user The user object containing role information.
 * @param {string[]} visibilityFilter The array of visibility filters.
 * @returns {string|null} The URL visibility filter string based on the user role, or null if not applicable.
 */

export const generateUrlVisibilityFilter = (user, visibilityFilter) => {
  switch (user?.role) {
    case 'guests:PARENT':
      return 'PARENTS';
    case 'users:TABLET':
      return 'TEAMS';
    default: {
      if (visibilityFilter.length) {
        return visibilityFilter;
      }
      return null;
    }
  }
};
