import { useTranslation } from 'react-i18next';
import { Flex, Select, DatePicker } from 'antd';
import { useHomeContext } from '../../../contexts/HomeContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * @component Selects
 * @description Component that renders the select for the daycares and the range picker. Used in the home page.
 * @returns {JSX} Component JSX
 */
export const Selects = () => {
  const { t } = useTranslation();
  const {
    rangeMonths,
    setRangeMonths,
    daycares,
    monthFormat,
    selectedDaycare,
    setSelectedDaycare,
    userRole
  } = useHomeContext();

  const onSelectDaycare = (values) => {
    if (values === 'all') {
      setSelectedDaycare(values);
      return;
    }
    const daycare = daycares.find((d) => d._id === values);
    setSelectedDaycare(daycare);
  };

  const filterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Flex
      justify={userRole !== 'users:MANAGER' ? 'space-between' : 'flex-end'}
      wrap="wrap"
    >
      {userRole !== 'users:MANAGER' && (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={daycares.length === 0}
          defaultValue={selectedDaycare}
          onChange={(values) => onSelectDaycare(values)}
          className="mobile-select"
          style={{ width: 280 }}
          disabled={userRole === 'users:MANAGER'}
        >
          <Option value="all">{t('home.all_establishments')}</Option>
          {daycares
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((daycare) => (
              <Option value={daycare._id} key={daycare._id}>
                {daycare.name}
              </Option>
            ))}
        </Select>
      )}
      <Flex align="center" gap="small">
        <span>{t('home.range_picker_label')}</span>
        <RangePicker
          picker="month"
          defaultValue={rangeMonths}
          allowEmpty={[false, false]}
          allowClear={false}
          format={monthFormat}
          onCalendarChange={(values) => setRangeMonths(values)}
          style={{ width: 200 }}
        />
      </Flex>
    </Flex>
  );
};
