import PropTypes from 'prop-types';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useLabelColumns } from '../labelColumns';

/**
 * AllergiesTable component for displaying a table of allergies and managing their data.
 *
 * @component
 * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {React.Element} The rendered AllergiesTable component.
 */
export const AllergiesTable = ({ onModalOpen, setRefresh, refresh }) => {
  const { t } = useTranslation();
  const columns = useLabelColumns(setRefresh, onModalOpen, 'allergies');

  return (
    <>
      <Flex justify="flex-end">
        <Button
          onClick={() => onModalOpen({}, 'create', 'allergies')}
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <ListResource
        resourceName="allergies"
        columns={columns}
        resourceModelName="Allergies"
        archivedButton={false}
        withPageHeader={false}
        showAction={false}
        onDoubleClickAction={false}
        withUploadButton={false}
        withCreateButton={false}
        openModal
        forceRefresh={refresh}
        customActionColumn
      />
    </>
  );
};

AllergiesTable.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool
};

AllergiesTable.defaultProps = {
  refresh: false
};
