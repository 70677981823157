import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';

/**
 * @description
 * HouseholdCard is a component that displays the household information
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the household
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} HouseholdCard component
 */
export const HouseholdCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data);
  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      title={t('pre-registrations.show.cards.household')}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
        <DescriptionList
          headStyle={{ borderBottom: 'none' }}
          layout="vertical"
          data={listContent}
          translate
        />
      </Skeleton>
    </Card>
  );
};

HouseholdCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool
};

HouseholdCard.defaultProps = {
  isLoading: false
};
