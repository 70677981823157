import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Tag, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Custom hook that provides form fields and related functionality for the users module.
 *
 * @hook
 * @param {string} purpose - The purpose of the form (e.g., 'create', 'edit').
 * @param {object} record - The user record to pre-fill the form with.
 * @param {array} daycares - The list of daycares for the 'managed_daycares' field.
 * @param {object} form - The form instance from antd.
 * @returns {object} - An object containing the form fields, loading state, and file list state.
 */
export const useFields = (purpose, record, daycares, form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(record?.role || '');

  const onChangeRole = (value) => {
    setSelectedRole(value);
    const isRegionalManager = value.includes('REGIONAL-MANAGER');
    if (!isRegionalManager) {
      form.setFieldsValue({ managed_daycares: [] });
    }
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select onChange={onChangeRole} loading={isFieldsLoading}>
          {(roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {t(`users.tags.${role.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    ...(selectedRole.includes('REGIONAL-MANAGER')
      ? [
          {
            name: ['managed_daycares'],
            rules: [{ required: selectedRole.includes('REGIONAL-MANAGER') }],
            input: (
              <Select
                mode="multiple"
                allowClear
                placeholder={t('users.placeholders.managed_daycares')}
              >
                {(daycares || []).map((daycare) => (
                  <Option key={daycare._id} value={daycare._id}>
                    {daycare.name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'phone_number.number',
      name: ['mobile_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: purpose === 'edit'
    }
  ];

  const getRoles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setRoles(data.roles);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getRoles();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    fileList,
    setFileList
  };
};
