import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { AnalysisContextProvider } from '../../contexts/AnalysisContext';
import { Analysis } from './Analysis';
import { ShowBalance } from './TabsContent/CashBalanceImports/ShowBalance';

/**
 * Defines the routing for the Analysis section within the application.
 *
 * This component wraps the Analysis-related routes with the AnalysisContextProvider to ensure all nested components have access to the shared analysis data context. It defines three routes: the root route for the Analysis page, a route for showing individual balance details using the ShowBalance component, and a catch-all route that displays an exception page for any undefined paths.
 *
 * @component
 * @returns {JSX.Element} A JSX element containing the Routes wrapped by the AnalysisContextProvider.
 */
export const AnalysisRouter = () => (
  <AnalysisContextProvider>
    <Routes>
      <Route index element={<Analysis />} />
      <Route path="/show/:id" element={<ShowBalance />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </AnalysisContextProvider>
);
