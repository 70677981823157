import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * @hook useDownloadDocument
 * @description Hook to view in an other tab, download or print a document
 * @returns {Object} { downloadDocument, viewDocument, printDocument }
 */
export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * @function downloadDocument
   * @async
   * @description Download a document
   * @param {Object} doc Document to download
   * @returns {void}
   * @example
   * downloadDocument(doc);
   * @example
   * downloadDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = originalName;
      a.click();
      notification.success({ message: t('export.messages.success') });
    } catch (e) {
      message(e);
    }
  };

  /**
   * @function viewDocument
   * @async
   * @description View a document in an other tab
   * @param {Object} doc Document to view
   * @returns {void}
   * @example
   * viewDocument(doc);
   * @example
   * viewDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const viewDocument = async (doc) => {
    try {
      let blob = {};
      if (!doc.data) {
        const response = await dispatchAPI('GET', {
          url: `/files/${doc._id}`,
          responseType: 'blob'
        });
        blob = new Blob([response.data], {
          type: response.data.type
        });
        const fileURL = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * @function printDocument
   * @async
   * @description Print a document
   * @param {Object} doc Document to print
   * @returns {void}
   * @example
   * printDocument(doc);
   * @example
   * printDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const printDocument = async (doc) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${doc._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });

      const url = window.URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;

      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return { downloadDocument, viewDocument, printDocument };
};
