import NewsIcon from '../../../assets/images/icons/news_icon.svg';
import EventsIcon from '../../../assets/images/icons/events_icon.svg';
import InfosIcon from '../../../assets/images/icons/infos_icon.svg';
import DocumentsIcon from '../../../assets/images/icons/documents_icon.svg';
import { Documents } from '../TabsContent/Documents/Documents';
import { BlogContainer } from '../TabsContent/BlogContainer';

/**
 * Custom hook to create tab items for the communication section.
 *
 * This hook returns an array of tab items, each with a unique key, a label that includes an icon and a text label,
 * and children which are the content of the tab.
 *
 * @function useTabItems
 * @param {Function} t - Translation function from `react-i18next`.
 * @returns {Array<Object>} An array of tab item objects.
 */
export const useTabItems = (t) => [
  {
    key: '1',
    label: (
      <>
        <img src={NewsIcon} alt="news-icon" />
        {t('communication.tabs.news')}
      </>
    ),
    children: <BlogContainer resourceName="news" />
  },
  {
    key: '2',
    label: (
      <>
        <img src={EventsIcon} alt="events-icon" />
        {t('communication.tabs.events')}
      </>
    ),
    children: <BlogContainer resourceName="events" />
  },
  {
    key: '3',
    label: (
      <>
        <img src={InfosIcon} alt="infos-icon" />
        {t('communication.tabs.infos')}
      </>
    ),
    children: <BlogContainer resourceName="infos" />
  },
  {
    key: '4',
    label: (
      <>
        <img src={DocumentsIcon} alt="news-icon" />
        {t('communication.tabs.documents')}
      </>
    ),
    children: <Documents />
  }
];
