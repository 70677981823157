import { Button, Flex, List, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';
import { dateRender } from './utils';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * Displays a card representing a news article.
 *
 * @component
 *
 * This component presents a news article in a card format. It includes a thumbnail image (if available),
 * the title, content snippet, and the number of views. Each card also shows the visibility status
 * of the news article as tags and provides an edit button to modify the article.
 *
 * Clicking on the title or content snippet navigates to the detailed view of the news article.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.news - The news article data containing information like title,
 * content, visibility, views count, and thumbnail.
 * @param {Function} props.handleModal - Function to handle opening the modal for editing news.
 *
 * @returns {JSX.Element} A card component representing a news article.
 */
export const NewsCard = ({ news, handleModal }) => {
  const { t } = useTranslation();
  const { token, user } = useAuthContext();
  const navigate = useNavigate();

  const userBoolean = ['guests:PARENT', 'users:TABLET'].includes(user?.role);

  const handleNavigateToShow = () =>
    navigate(`${routes.COMMUNICATION}/show/news/${news?._id}`);

  return (
    <List.Item
      className="news-list-item"
      actions={[
        !userBoolean && (
          <>
            <Button type="link" onClick={handleNavigateToShow}>
              <EyeOutlined />
            </Button>
            <Button type="link" onClick={() => handleModal(news, 'edit')}>
              <EditOutlined />
            </Button>
          </>
        )
      ].filter(Boolean)}
      extra={
        news?.thumbnail?._id && (
          <img
            src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${news?.thumbnail?._id}/${token}`}
            alt="thumbnail-icon"
          />
        )
      }
      onClick={userBoolean ? handleNavigateToShow : undefined}
      style={{ cursor: userBoolean ? 'pointer' : 'default' }}
    >
      <ReactQuill
        value={news?.content}
        readOnly
        modules={{ toolbar: false }}
        theme="bubble"
        className="quill-card"
      />
      <List.Item.Meta
        title={news?.title}
        description={
          !userBoolean && (
            <Flex vertical gap="small" align="flex-start">
              <Flex gap="small">
                {(news?.visibility || []).map((visItem) => (
                  <Tag color={visibilitiesColors[visItem]} key={visItem}>
                    {t(`communication.news.list.type.${visItem}`)}
                  </Tag>
                ))}
              </Flex>
              <span className="news-card-views">{`${dateRender(news, t)} - ${
                news?.views?.length
              } ${t('communication.news.card.views')}`}</span>
            </Flex>
          )
        }
      />
    </List.Item>
  );
};

NewsCard.propTypes = {
  news: PropTypes.shape({
    _id: PropTypes.string,
    start_visibility: PropTypes.string,
    end_visibility: PropTypes.string,
    content: PropTypes.string,
    thumbnail: PropTypes.shape({
      _id: PropTypes.string
    }),
    title: PropTypes.string,
    visibility: PropTypes.arrayOf(PropTypes.string),
    views: PropTypes.arrayOf(PropTypes.string)
  }),
  handleModal: PropTypes.func.isRequired
};

NewsCard.defaultProps = {
  news: null
};
