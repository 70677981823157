import {
  CheckOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Divider, Popconfirm, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { determineVaccineDatesManagement } from './determineVaccineDatesManagement';

const iconSize = 18;

/**
 * Custom hook for generating columns for a vaccine list table.
 *
 * @hook
 *
 * @param {boolean} refresh - Indicates if a refresh action is needed.
 * @param {function} setRefresh - Function to set the refresh state.
 * @param {function} handleModal - Function to handle the modal for editing vaccine records.
 * @param {string} healthID - The ID of the health record associated with the vaccines.
 * @param {Array} daycareVaccines - An array of daycare vaccine objects.
 * @returns {Array} An array of column objects for the vaccine list table.
 */
export const useColumns = (
  refresh,
  setRefresh,
  handleModal,
  healthID,
  daycareVaccines
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteDocument = async (vaccineID) => {
    try {
      await dispatchAPI('DELETE', {
        url: `healths/${healthID}/${vaccineID}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t('children.show.health.vaccine'),
      key: 'vaccine',
      dataIndex: 'vaccine',
      sorter: true,
      render: (vaccine) => vaccine && vaccine?.name
    },
    {
      title: t('children.show.health.first_dose'),
      key: 'first_dose',
      dataIndex: 'first_dose',
      sorter: true,
      render: (date) =>
        date ? (
          <Row style={{ color: 'green' }}>
            {dayjs(date).format('DD/MM/YYYY')}
            <CheckOutlined />
          </Row>
        ) : (
          <Row style={{ color: 'red' }}>
            {dayjs().format('DD/MM/YYYY')}
            <ClockCircleOutlined />
          </Row>
        )
    },
    {
      title: t('children.show.health.second_dose'),
      sorter: true,
      render: (record) =>
        determineVaccineDatesManagement(daycareVaccines, record, 2, t)
    },
    {
      title: t('children.show.health.third_dose'),
      sorter: true,
      render: (record) =>
        determineVaccineDatesManagement(daycareVaccines, record, 3, t)
    },
    {
      title: t('children.show.health.fourth_dose'),
      sorter: true,
      render: (record) =>
        determineVaccineDatesManagement(daycareVaccines, record, 4, t)
    },
    {
      render: (record) => (
        <>
          <Button onClick={() => handleModal(record, 'edit')}>
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
