import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';

/**
 * @description
 * AuthorizationsCard is a component that displays the authorizations informations
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the child
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} AuthorizationsCard component
 */
export const AuthorizationsCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data);
  const title = t('pre-registrations.show.cards.authorizations');

  return (
    <Card headStyle={{ borderBottom: 'none' }} title={title}>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <DescriptionList data={listContent} translate layout="vertical" />
      </Skeleton>
    </Card>
  );
};

AuthorizationsCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool
};

AuthorizationsCard.defaultProps = {
  isLoading: false
};
