import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pre-registrations/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['first_name'],
      input: <Input />
    },
    {
      name: ['last_name'],
      input: <Input />
    },
    {
      name: ['honorific'],
      rules: [{ required: false }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.honorifics || []).map((honorific) => (
            <Option key={honorific} value={honorific}>
              {t(`pre-registrations.form.honorifics.${honorific}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional'],
      rules: [{ required: false }]
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'state'],
      rules: [{ required: false }]
    },
    {
      name: ['mobile_phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['landline_phone_number'],
      rules: [{ required: false }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['landline_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['landline_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading,
    enums
  };
};
