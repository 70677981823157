import { Button, Divider, Flex } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWelfareFields } from '../Fields/welfareFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

/**
 * React component for rendering a billing form in contract creation/update.
 *
 * This component provides a form for capturing billing information, including welfare-related fields,
 * customer account selection, and payment method details.
 *
 * @component
 * @param {Function} onFinish - Callback function triggered when the form is successfully submitted.
 * @param {Function} handlePrevious - Callback function to navigate to the previous step.
 * @param {Object} initialValues - Initial values for the form fields.
 * @param {Array} contracts - Array of children contracts.
 * @returns {JSX.Element} The rendered BillingForm component.
 */
export const BillingForm = ({ onFinish, handlePrevious, contracts, form }) => {
  const { t } = useTranslation();

  const generateFields = useGenerateFormItem();

  useEffect(() => {
    if (contracts) {
      const mostRecentContract = contracts.sort(
        (a, b) => a.created_at - b.created_at
      )[0];
      form.setFieldsValue(mostRecentContract);
    }
  }, [form, contracts]);

  const { fields } = useWelfareFields();

  return (
    <>
      <Divider orientation="left">{t('contracts.form.welfare_fields')}</Divider>
      {fields.map((field) => generateFields('contracts', field))}
      <Flex gap="small" justify="end">
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.validate')}
        </Button>
      </Flex>
    </>
  );
};

BillingForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  })
};
BillingForm.defaultProps = {
  contracts: null,
  form: null
};
