import { Avatar, Badge, Col, List, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import { displayDate, truncateString } from '../utils';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useConversationContext } from '../../../../contexts/ConversationContext';

/**
 * `InboxItem` is a component that renders a single item in the inbox list.
 * It displays essential information about a conversation, such as the last message,
 * the date of the last message, and whether there are any unread messages.
 *
 * This component uses the `useAuthContext` to access the current user's data and
 * `useConversationContext` for conversation-related actions like setting the selected
 * conversation and marking messages as read.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.item - The conversation item to be rendered. Includes messages, last message date, and users.
 *
 * @returns {JSX.Element} A List.Item component representing a conversation in the inbox.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.item {Object} - The conversation item. It should include an array of messages, last message date, and an array of users.
 */
export const InboxItem = ({ item }) => {
  const { user } = useAuthContext();
  const { setSelectedConversation, patchToReadConversation } =
    useConversationContext();
  const { users, last_message_date, messages: itemMessages } = item;

  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
    patchToReadConversation(conversation._id);
  };

  const recipient = users.find((userItem) => userItem._id !== user._id);

  const containsUnread = itemMessages.some(
    (message) =>
      !!message?.unread?.length &&
      message.unread.some((unreadItem) => unreadItem === user._id)
  );

  return (
    <List.Item
      onClick={() => handleConversationClick(item)}
      style={{ width: '100%' }}
      className="parent-inbox-item"
    >
      <Row className="inbox-item-row">
        <Col xs={3} sm={2} lg={1}>
          <Avatar src={recipient?.photo} />
        </Col>
        <Col xs={21} sm={22} lg={23}>
          <Row style={{ flexDirection: 'column' }}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Col className="inbox-item-recipient">{`${recipient.first_name} ${recipient.last_name}`}</Col>
              <Col>
                <Space>
                  {containsUnread && <Badge color="red" />}
                  <span className="conversation-date">
                    {displayDate(last_message_date)}
                  </span>
                </Space>
              </Col>
            </Col>
            <Col className="inbox-item-description">
              {truncateString(itemMessages[itemMessages.length - 1].content)}
            </Col>
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};

InboxItem.propTypes = {
  item: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({ content: PropTypes.string })),
    last_message_date: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

InboxItem.defaultProps = {
  item: null
};
