import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Input, Row, Skeleton, List } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { SendOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useConversationContext } from '../../../../contexts/ConversationContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { tailFormItemLayout } from '../../../../utils/constants/formLayout';
import { ConversationItem } from './ConversationItem';

const { TextArea } = Input;

/**
 * `ParentConversation` is a React component for displaying and sending messages within a selected conversation.
 * It uses Ant Design components for the UI, and integrates with custom context hooks for conversation and authentication data.
 *
 * The component handles loading conversation data, displaying messages, and posting new messages. It includes features like
 * conditionally styling messages based on the sender and dynamically rendering messages with dates.
 *
 * @returns {JSX.Element} A JSX element that provides the UI for viewing a conversation's messages and sending new ones.
 */
export const ParentConversation = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const {
    selectedConversation,
    refreshConversation,
    setRefreshConversation,
    setSelectedConversation
  } = useConversationContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getConversation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `conversations/${selectedConversation._id}?populate=users,messages.from`
      });
      setSelectedConversation(data);
    } catch (error) {
      message(error);
    }
  };

  const sendMessage = async (values) => {
    try {
      setIsSubmitting(true);
      const url = `conversations`;
      const { _id: from } = user;
      const to = selectedConversation.users.find(
        (userItem) => userItem._id !== user._id
      );
      const body = {
        ...values,
        from,
        category: 'PARENTS',
        to,
        management_only: false,
        daycare
      };
      await dispatchAPI('POST', {
        url,
        body
      });
      form.resetFields();
      setRefreshConversation(!refreshConversation);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getConversation();
      setIsLoading(false);
    })();
  }, [refreshConversation]);

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [selectedConversation.messages]); // Dependency on messages

  return (
    <Skeleton loading={isLoading}>
      <div className="parent-conversation-wrapper">
        <div id="scrollableDiv" ref={scrollableDivRef}>
          <InfiniteScroll
            dataLength={selectedConversation.messages.length}
            next={getConversation}
            hasMore={selectedConversation.messages.length < 10}
            inverse
            loader={
              <Skeleton loading={isLoading} avatar paragraph={{ rows: 1 }} />
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              itemLayout="horizontal"
              dataSource={selectedConversation.messages}
              renderItem={(item) => <ConversationItem messageItem={item} />}
            />
          </InfiniteScroll>
        </div>
        <Form form={form} onFinish={sendMessage} className="parent-inbox-form">
          <Divider solid />
          <Form.Item name={['content']} label={null}>
            <TextArea
              className="conversation-textarea"
              placeholder={t('messages_and_notes.placeholder')}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {`${t('buttons.send')} `}
                <SendOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </Skeleton>
  );
};
