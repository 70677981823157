import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography, message as antdMessage } from 'antd';
import { useCallback, useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Component for exporting data to CSV or XLSX format.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.fileName - The name of the exported file.
 * @param {string} props.dataName - The name of the data for translation.
 * @param {string} props.url - The API endpoint URL for fetching data.
 * @param {Array} props.headers - The headers for CSV columns.
 * @param {Function} [props.formatter] - Optional function to format data before exporting.
 * @param {string} [props.extraQuery] - Optional extra query parameters for the API request.
 * @param {string} [props.populate] - Optional field to populate in the API request.
 * @param {string} [props.format] - Optional format for export ('csv' or 'xlsx').
 * @returns {JSX.Element} The ExportButton component.
 */
export const ExportButton = ({
  fileName,
  dataName,
  url,
  headers,
  formatter,
  populate,
  extraQuery,
  format,
  customExportUrl
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const { message } = useErrorMessage();
  const needsFetch = useRef(true);

  const fetchUrl = `${url}${customExportUrl ? '/export' : ''}?${
    extraQuery ? `${extraQuery}&` : ''
  }${populate ? `populate=${populate}` : ''}`;

  const fetchData = useCallback(async () => {
    if (needsFetch.current) {
      setVisible(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: fetchUrl
        });
        if (data.length) setDataCSV(formatter ? data.map(formatter) : data);
        else {
          setDataCSV([]);
        }
        needsFetch.current = false;
      } catch (e) {
        message(e);
        setDataCSV([]);
      } finally {
        setVisible(false);
      }
    }
  }, [dispatchAPI, fetchUrl, formatter, message]);

  const fetchXLSXExport = () => {
    setVisible(true);
    try {
      const translatedHeaders = headers.map((header) => ({
        ...header,
        label: t(`${dataName}.form.${header.label}`)
      }));

      const finalHeaders = translatedHeaders.map((th) => th.label);
      const worksheetData = [finalHeaders];

      dataCSV.forEach((item) => {
        const row = translatedHeaders.map((header) => {
          if (header.key.includes('.')) {
            const keys = header.key.split('.');
            let value = item;
            keys.forEach((key) => {
              value = (value || {})[key];
            });
            return value || '';
          }
          return item[header.key] || '';
        });
        worksheetData.push(row);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${fileName}.xlsx`);

      antdMessage.success(t('success.messages.download'));
    } catch (e) {
      message(e);
    } finally {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (format === 'xlsx' && dataCSV.length) {
      fetchXLSXExport();
    }
  }, [dataCSV]);

  useEffect(() => {
    setDataCSV([]);
    needsFetch.current = true;
  }, [dataName, url, headers]);

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        open={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning={visible} size="large" style={{ margin: 16 }} />
        <Typography.Text>{t('exports.preparing')}</Typography.Text>
      </Modal>
      <CSVLink
        style={{ color: 'inherit' }}
        asyncOnClick
        separator=";"
        onClick={(e, done) => {
          if (format === 'xlsx') {
            e.preventDefault();
            if (needsFetch.current) {
              fetchData().then(() => {
                done(false);
                needsFetch.current = true;
              });
            }
          } else if (needsFetch.current) {
            e.persist();
            e.preventDefault();
            fetchData().then(() => {
              e.target.click();
              done(false);
              needsFetch.current = true;
            });
          } else {
            done();
          }
        }}
        filename={fileName}
        data={dataCSV}
        headers={(headers || []).map(({ label, key }) => ({
          label: t(`${dataName}.form.${label}`),
          key: key || label
        }))}
      >
        <DownloadOutlined style={{ fontSize: '14px', marginRight: 8 }} />
        {t(`buttons.export_${format}`)}
      </CSVLink>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formatter: PropTypes.func,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  format: PropTypes.string,
  customExportUrl: PropTypes.bool
};

ExportButton.defaultProps = {
  formatter: null,
  extraQuery: null,
  populate: null,
  format: 'csv',
  customExportUrl: false
};
