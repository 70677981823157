/**
 * Custom hook for generating informations content data for displaying invoice customer_account informations details in a list format.
 *
 * @hook
 * @param {object} data - Invoice data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useCustomerAccountContent = (data = {}) => [
  {
    label: 'invoices.show.label',
    span: 3,
    content: data?.label
  },
  {
    label: 'invoices.show.address',
    span: 3,
    content:
      data?.billing_address?.length &&
      (data?.billing_address || []).map((address) => (
        <p>
          {`${address?.number} ${address?.street} ${address?.postal_code} ${address?.city}, ${address?.state}`}
        </p>
      ))
  }
];
