// Note : Don't delete commented code in case the customer wants to add the create button again.
import { Col, Input, Row, Select, Tag } from 'antd';
// import { Button, Col, Input, Row, Space, Switch } from 'antd';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { preRegistrationStatus } from '../../utils/constants/tagColors';

const { Search } = Input;
const { Option } = Select;

/**
 * PreRegistrationHeader component displays the header section for pre-registrations.
 * It includes a search input, an archive switch, and a button to create new registrations.
 *
 * @param {Object} props - Properties passed to the component.
 * @param {string} [props.searchValue=undefined] - The current value in the search input.
 * @param {function} [props.searchResource=null] - Callback function triggered on search.
 * @param {string} [props.statusFilter=undefined] - The current value in the status filter.
 * @param {function} [props.setStatusFilter=null] - Function to set the filter status.
 *
 * @returns {React.ElementType} Rendered component.
 */
export const PreRegistrationHeader = ({
  searchValue,
  searchResource,
  statusFilter,
  setStatusFilter
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [statuses, setStatuses] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `pre-registrations/enums`
      });
      setStatuses(data.status);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t(`pre-registrations.title`)} />
      <Row justify="space-between" gutter={[8, 16]} style={{ margin: 16 }}>
        <Col xs={24} md={6}>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Col xs={24} md={18}>
          <Select
            onChange={(value) => setStatusFilter(value)}
            style={{ minWidth: 200, float: 'right' }}
            defaultValue={statusFilter}
            mode="multiple"
            allowClear
            className="contains-tags"
          >
            {statuses.map((enumItem) => (
              <Option key={enumItem} value={enumItem}>
                <Tag color={preRegistrationStatus[enumItem]}>
                  {t(`pre-registrations.form.status.${enumItem}`)}
                </Tag>
              </Option>
            ))}
          </Select>

          {/* <Link to={`${pathname}/create`}>
            <Button type="add">
              {`${t('buttons.create')}`}
              &nbsp;
              <AddIcon />
            </Button>
          </Link> */}
        </Col>
      </Row>
    </>
  );
};

PreRegistrationHeader.propTypes = {
  // pathname: PropTypes.string,
  searchValue: PropTypes.string,
  searchResource: PropTypes.func,
  statusFilter: PropTypes.arrayOf(PropTypes.string),
  setStatusFilter: PropTypes.func
};

PreRegistrationHeader.defaultProps = {
  // pathname: undefined,
  searchValue: undefined,
  searchResource: null,
  statusFilter: undefined,
  setStatusFilter: null
};
