import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import { disabledTime } from '../TimeRangeBar/utils/disabledTime';
import { useHandleResize } from '../../utils/handleResize';

/**
 * MercuryTimePicker is a custom time picker component that adjusts its appearance based on the screen size.
 * It renders an html input field for mobile devices or an AntDesign TimePicker for computers.
 * It insures a better user experience on mobile devices by using the native date picker.
 *
 * @param {string} format - The format of the time (ex., 'HH:mm').
 * @param {function} onChange - A callback function to handle the time change.
 * @param {string|object} selectedTime - The selected time.
 * @param {object} disabledTimeRange - An object containing the start and end times for the disabled range.
 * @returns {JSX.Element} The rendered time picker component.
 *
 * @example
 * return (
 *   <MercuryTimePicker
 *     format="HH:mm"
 *     onChange={(value) => console.log(value)}
 *     disabledTimeRange={{ start: 6, end: 21 }}
 *   />
 * )
 */
export const MercuryTimePicker = ({
  format,
  onChange,
  selectedTime,
  disabledTimeRange
}) => {
  const { width } = useHandleResize();

  /**
   * Formats the selected time for the Ant Design TimePicker component.
   *
   * @param {string|object} time - The selected time.
   * @returns {object|null} The formatted selected time for the Ant Design TimePicker component.
   */
  const formatSelectedTimeForAntDesignTimePicker = (time) => {
    if (!time) return null;

    if (typeof time === 'object') return time;

    const [hour, minute] = time.split(':');
    return dayjs().set('hour', hour).set('minute', minute);
  };

  // An iPad Pro is 1280 pixels wide
  return width <= 1280 ? (
    <input
      type="time"
      value={selectedTime}
      onChange={(e) => {
        const value = e.target.value;
        onChange(value.length > 0 ? value : null);
      }}
    />
  ) : (
    <TimePicker
      format={format}
      onChange={onChange}
      value={formatSelectedTimeForAntDesignTimePicker(selectedTime)}
      disabledTime={
        disabledTimeRange
          ? () => disabledTime(disabledTimeRange.start, disabledTimeRange.end)
          : null
      }
    />
  );
};

MercuryTimePicker.propTypes = {
  format: PropTypes.string,
  onChange: PropTypes.func,
  selectedTime: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  disabledTimeRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  })
};

MercuryTimePicker.defaultProps = {
  format: 'HH:mm',
  selectedTime: null,
  onChange: () => {},
  disabledTimeRange: null
};
