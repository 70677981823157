import { Route, Routes } from 'react-router-dom';
import { ListPreRegistrations } from './ListPreRegistrations';
import { Exception } from '../../components';
import { CreateUpdatePreRegistration } from './CreateUpdatePreRegistration';
import { ShowPreRegistration } from './ShowPreRegistrations';
import { CreateUpdateContract } from '../Contracts/CreateUpdateContract';
import { PreRegistrationValidation } from './PreRegistrationValidation';
import { ReferenceWeekProvider } from '../../contexts/ReferenceWeekContext';

/**
 * Component for handling pre-registration routing using React Router.
 *
 * This component defines the routes for creating, editing, showing, and accepting pre-registrations.
 * It also includes a route for listing pre-registrations and a fallback route for exceptions.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the pre-registration routing.
 */
export const PreRegistrationRouter = () => (
  <ReferenceWeekProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdatePreRegistration purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdatePreRegistration purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowPreRegistration />} />
      <Route
        path="/accept/:preRegistrationID"
        element={
          <CreateUpdateContract origin="PRE-REGISTRATION" purpose="create" />
        }
      />
      <Route
        path="/contract-validation/:preRegistrationId"
        element={<PreRegistrationValidation />}
      />
      <Route index element={<ListPreRegistrations />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ReferenceWeekProvider>
);
