import { useTranslation } from 'react-i18next';
import { DynamicList } from './DynamicList/DynamicList';
import { ChildrenGroupsTable } from './ChildrenGroups/ChildrenGroupsTable';
import { CAFScale } from './CAFScale/CAFScale';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * @description useTabContent hook. Returns the content of the GlobalSettings tab
 * @returns {Array} The content of the GlobalSettings tab
 */
export const useTabContent = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const tabContent = [
    {
      label: t('global_settings.dynamic_lists.title'),
      key: 'dynamic_lists',
      children: <DynamicList />
    },
    {
      label: t('global_settings.children_groups.title'),
      key: 'children_groups',
      children: <ChildrenGroupsTable />
    },
    user.role === 'admins:SUPER-ADMIN' && {
      label: t('global_settings.CAF_scale.title'),
      key: 'CAF_scale',
      children: <CAFScale />
    }
  ].filter(Boolean);

  return { tabContent };
};
