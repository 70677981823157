import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { DevRouter } from './DevRouter';
import { TimeRecorderRouter } from './TimeRecorderRouter';
import { useThemeContext } from '../contexts/ThemeContext';
import { ManagerRouter } from './ManagerRouter';
import { RegionalManagerRouter } from './RegionalManagerRouter';
import { CorporateRouter } from './CorporateRouter';
import { TabletRouter } from './TabletRouter';
import { ParentRouter } from './ParentRouter';

/**
 * `RouterDispatch` is a React component that serves as a dynamic router dispatcher based on the user's role.
 * It uses the `useAuthContext` to access the current user's information and `useThemeContext` to set the appropriate theme.
 * Depending on the user's role, it renders different router components such as `DevRouter`, `AdminRouter`, `ManagerRouter`,
 * `TimeRecorderRouter`, and `UserRouter`.
 *
 * This component also applies a specific class to the body and sets a theme when there is no user logged in.
 * It's a central part of the application's routing logic, ensuring users are directed to the correct interface
 * based on their role.
 *
 * @component
 * @example
 * return (
 *   <RouterDispatch />
 * )
 * @returns {React.ReactElement} A router component corresponding to the user's role.
 */
export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'developer:DEVELOPER':
        return <DevRouter />;
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'admins:CORPORATE':
        return <CorporateRouter />;
      case 'admins:REGIONAL-MANAGER':
        return <RegionalManagerRouter />;
      case 'users:TABLET':
        return <TabletRouter />;
      case 'users:MANAGER':
        return <ManagerRouter />;
      case 'guests:PARENT':
        return <ParentRouter />;
      case 'users:TIME-RECORDER':
        return <TimeRecorderRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
