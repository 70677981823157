import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { PlanningRangeBar } from '../../planning/PlanningRangeBar/PlanningRangeBar';
import { generateDate } from './utils';

const { Text } = Typography;

/**
 * Represents a row in the planning interface displaying events for a specific day.
 *
 * @component
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.event - The event data for the day.
 * @param {Function} props.openModal - Function to open the modal for adding or editing events.
 * @param {Function} props.deleteEvent - Function to delete an event.
 * @param {Object} props.employee - The employee data associated with the event.
 * @returns {JSX.Element} The JSX representation of the component.
 */
export const DayRow = ({ event, openModal, deleteEvent }) => {
  const generatedDate = generateDate(event);

  return (
    <Row align="middle">
      <Col span={4}>
        <Text style={{ textAlign: 'right' }}>{event.date}</Text>
      </Col>
      <Col span={18}>
        <PlanningRangeBar
          events={event}
          openModal={openModal}
          deleteEvent={deleteEvent}
          childRowDate={generatedDate}
          source="employee"
        />
      </Col>
      <Col span={2} />
    </Row>
  );
};

DayRow.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.string,
    weekStart: PropTypes.string,
    employee: PropTypes.shape({}),
    regulars: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    ),
    childSchedule: PropTypes.shape({
      overtimes: PropTypes.arrayOf(PropTypes.shape({})),
      pickUpAndPlanningMatch: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    _id: PropTypes.string
  }),
  employee: PropTypes.shape({
    _id: PropTypes.string
  }),
  openModal: PropTypes.func,
  deleteEvent: PropTypes.func
};

DayRow.defaultProps = {
  event: null,
  employee: null,
  openModal: null,
  deleteEvent: null
};
