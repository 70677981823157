import dayjs from 'dayjs';

/**
 * Formats the body object by converting date strings to dayjs objects and mapping document titles to file keys.
 *
 * @function
 * @param {Object} values - The input values object.
 * @returns {Object} - The formatted body object.
 */
export default (values) => ({
  ...values,
  authorizations: {
    ...values.authorizations,
    consent_date: values?.authorizations?.consent_date
      ? dayjs(values?.authorizations?.consent_date)
      : null
  },
  rib: {
    ...values?.contract?.rib,
    signature_date: values?.contract?.rib?.signature_date
      ? dayjs(values?.contract?.rib?.signature_date)
      : null
  },
  documents: values?.contract?.documents.map((document) => ({
    ...document,
    fileKey: document?.document_title
  })),
  method: values?.contract?.payment_method?.method
});
