import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Select, Tag } from 'antd';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { ListResource } from '../../../../../components';
import { useColumns } from './columns';
import { invoiceStatuses } from '../../../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../../../utils/constants/adminRoutes';

const { Option } = Select;

/**
 * @component
 * @name InvoicesCard
 * @description Displays the list of invoices card in the customer account page
 * @returns {component} InvoicesCard component
 */
export const InvoicesCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const columns = useColumns();
  const [enums, setEnums] = useState({});
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onsearchStatus = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/invoices/enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      setIsLoading(false);
    })();
  }, [id]);

  return (
    <Card loading={isLoading} title={t('customer-account.show.invoices.title')}>
      <ListResource
        resourceName="invoices"
        extraQuery={`customer_account=${id}&daycare=${daycare}${
          status.length ? `&status=${status}` : ''
        }`}
        columns={columns}
        showAction={{
          pathname: (record) =>
            `${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/show/${record?._id}`
        }}
        onDoubleClickAction={{
          action: (record) =>
            navigate(
              `${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/show/${record?._id}`
            )
        }}
        extraButtons={
          <Col>
            <Select
              style={{ width: '250px', marginLeft: 20 }}
              showSearch
              mode="multiple"
              allowClear
              placeholder="Statut(s)"
              loading={isLoading}
              filterOption={(input, option) => onsearchStatus(input, option)}
              onSelect={(value) => {
                setStatus([...status, value]);
              }}
              onDeselect={(value) =>
                setStatus((prevStatus) =>
                  prevStatus.filter((item) => item !== value)
                )
              }
              onClear={() => {
                setStatus([]);
              }}
            >
              {enums?.status?.map((s) => (
                <Option key={s} value={s} data-label={t(`invoices.tags.${s}`)}>
                  <Tag color={invoiceStatuses[s]}>
                    {t(`invoices.tags.${s}`)}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Col>
        }
        withCreateButton={false}
        editAction={false}
        archiveAction={false}
        deleteAction={false}
        withPageHeader={false}
        withSearchBar={false}
        withPagination={false}
      />
    </Card>
  );
};
