import { Switch } from 'antd';
import { CirclePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

/**
 * A hook to generate form fields for label and color selection, utilizing `react-color`'s CirclePicker for color picking.
 *
 * @hook
 *
 * This hook prepares form field configurations for a label input and a color picker. The color picker uses the CirclePicker
 * component from `react-color` to allow users to select a color. The selected color's hex value is then used to set the
 * tag color through the provided `setTagColor` function.
 *
 * @param {Function} setTagColor - A function to set the tag color state in the parent component.
 * @returns {Object} An object containing the form fields configuration, including properties for the label and color fields.
 */
export const useLabelColorFields = (setTagColor) => {
  const { t } = useTranslation();
  const fields = [
    {
      label: 'label',
      name: 'label',
      rules: [{ required: true }]
    },
    {
      label: 'child_management',
      name: 'child_management',
      rules: [{ required: true }],
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    },
    {
      label: 'color',
      name: 'color',
      input: (
        <CirclePicker
          onChange={(selectedColor) => {
            setTagColor(selectedColor.hex);
          }}
        />
      )
    }
  ];

  return { fields };
};
