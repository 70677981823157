import { Flex, Col, Row, Tag, Button, Skeleton, Table } from 'antd';
import PropTypes from 'prop-types';
import { ChildFollowUp } from './ChildFollowUp';
import { calculateBirthDateDiff } from '../children/utils/calculateBirthDateDiff';
import { useFollowUpContext } from '../../contexts/FollowUpContext';

/**
 * A component for displaying an individual follow-up list for children.
 *
 * This component renders a list of children for individual follow-up. Each child is displayed
 * with their age, name, and group tag. If a specific child is selected in the follow-up context,
 * it shows detailed follow-up information for that child using the `FollowUp` component.
 * It uses the `useFollowUpContext` for accessing the list of children and their activities,
 * and `useAuthContext` for user information. The `calculateBirthDateDiff` function is used to
 * calculate and display each child's age.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleModalBehaviour - A function to handle modal interactions for activities.
 * @returns {JSX.Element} The JSX rendering of the individual children follow-up list or detailed follow-up for a selected child.
 *
 * @example
 * const handleEdit = (activity, mode) => {
 *   // handle edit action
 * };
 * return (
 *   <IndividualFollowUpChildrenList handleModalBehaviour={handleEdit} />
 * )
 */
export const IndividualFollowUpChildrenList = ({ handleModalBehaviour }) => {
  const { daysChildren, child, setChild, setOrigin } = useFollowUpContext();

  const nameColor = (gender) => {
    if (gender === 'BOY') {
      return 'var(--logoBlue)';
    }
    if (gender === 'GIRL') {
      return 'var(--logoPurple)';
    }
    return 'var(--mediumGray)';
  };

  const onClick = (childItem) => {
    setChild(childItem);
    setOrigin('child');
  };

  return !Object?.keys(child)?.length > 0 ? (
    <Skeleton loading={!daysChildren}>
      <Flex vertical gap="small">
        {daysChildren.length > 0 ? (
          (daysChildren || []).map((childItem) => {
            const { component } = calculateBirthDateDiff(childItem.birth_date);
            return (
              <Button
                onClick={() => onClick(childItem)}
                key={childItem._id}
                className="individual-followup-child"
              >
                <Row style={{ width: '100%' }} gutter={[8, 8]}>
                  <Col span={3}>{component}</Col>
                  <Col
                    span={16}
                    style={{ color: nameColor(childItem.gender) }}
                  >{`${childItem.first_name} ${childItem.last_name}`}</Col>
                  <Col span={5}>
                    <Tag color={childItem.group.tag_color}>
                      {childItem.group.label}
                    </Tag>
                  </Col>
                </Row>
              </Button>
            );
          })
        ) : (
          <Table dataSource={[]} />
        )}
      </Flex>
    </Skeleton>
  ) : (
    <ChildFollowUp handleModalBehaviour={handleModalBehaviour} />
  );
};

IndividualFollowUpChildrenList.propTypes = {
  handleModalBehaviour: PropTypes.func.isRequired
};
