export const useListContent = (data = {}) => {
  const {
    annual_incomes,
    dependent_children,
    effort_rate,
    hourly_rate,
    presence_fee
  } = data;

  const parents_information = [
    {
      label: 'pre-registrations.form.summary.effort_rate',
      span: 8,
      content: effort_rate ? `${effort_rate} €` : '-'
    },
    {
      label: 'pre-registrations.form.summary.annual_incomes',
      span: 8,
      content: annual_incomes + 1 ? `${annual_incomes} €` : '-'
    },
    {
      label: 'pre-registrations.form.summary.dependent_children',
      span: 8,
      content: dependent_children || '-'
    }
  ];

  const estimated_quote = [
    {
      label: 'pre-registrations.form.summary.presence_fee',
      span: 8,
      content: hourly_rate ? `${hourly_rate} € / H` : '-'
    },
    {
      label: 'pre-registrations.form.summary.applied_fee',
      span: 8,
      content: presence_fee ? `${presence_fee} €` : '-'
    }
  ];
  return { parents_information, estimated_quote };
};
