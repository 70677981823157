import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PlanningHeader } from './PlanningHeader';
import { printPlanning } from '../../children/Show/GeneralInfos/utils/printPlanning';
import EventModal from './EventModal';
import { HoursBar } from '../../../components/HoursBar/HoursBar';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { Week } from './Week';

/**
 * Component for displaying the planning details for a specific employee.
 *
 * @component
 *
 * @param {Object} props - Props for the EmployeePlanning component.
 * @param {Object} props.employee - Details of the employee.
 * @returns {JSX.Element} EmployeePlanning component.
 */
export const EmployeePlanning = ({ employee }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [planningMode, setPlanningMode] = useState('week');
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [eventEnums, setEventEnums] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [eventEditId, setEventEditId] = useState();
  const [planningLoading, setPlanningLoading] = useState(false);
  const [modalDate, setModalDate] = useState();
  const [purpose, setPurpose] = useState();
  const [planningTypeFilters, setPlanningTypeFilters] = useState([
    'regulars',
    'regular_absences',
    'irregular_absences',
    'holidays',
    'catch_up_days'
  ]);
  const [refresh, setRefresh] = useState(false);

  const deleteEvent = async (eventID) => {
    try {
      await dispatchAPI('DELETE', {
        url: `employee-events/${eventID}`
      });
    } catch (e) {
      message(e);
    }
  };

  const getEmployeeEvents = async () => {
    setPlanningLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `employee-events/planning-events/${id}?date=${selectedDay}&planning_type=${planningTypeFilters}&daycare=${daycare}&planning_mode=${planningMode}`
      });
      setEvents(data);
    } catch (e) {
      message(e);
    } finally {
      setPlanningLoading(false);
    }
  };

  const getEmployeeEventsEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'employee-events/enums'
      });
      setEventEnums(data?.types);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmployeeEventsEnums();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getEmployeeEvents();
    })();
  }, [planningTypeFilters, selectedDay, planningMode, refresh]);

  const openModal = (type, _, purp, eventId, date) => {
    setSelectedEmployee(employee);
    setPurpose(purp);
    setSelectedType(type);
    setEventEditId(eventId);
    setOpen(true);
    setModalDate(date);
  };

  const closeModal = () => {
    setPurpose('');
    setSelectedType('');
    setEventEditId('');
    setOpen(false);
    setModalDate();
  };

  return (
    <>
      <Flex vertical justify="center" style={{ marginTop: -32 }}>
        <Flex justify="flex-end">
          <Button
            onClick={printPlanning}
            type="primary"
            style={{ position: 'relative', top: 32 }}
          >
            <PrinterOutlined />
            {t('buttons.print')}
          </Button>
        </Flex>
        <PlanningHeader
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          eventEnums={eventEnums}
          planningTypeFilters={planningTypeFilters}
          setPlanningTypeFilters={setPlanningTypeFilters}
          planningMode={planningMode}
          setPlanningMode={setPlanningMode}
          plannedTotalTime={events?.plannedTotalTime}
          realTotalTime={events?.realTotalTime}
          loading={!!events.length}
          contractTime={employee?.contract_hours_per_week || 0}
        />
      </Flex>
      {open && (
        <EventModal
          isModalOpen={open}
          eventEnums={eventEnums}
          closeModal={closeModal}
          selectedEmployee={selectedEmployee}
          purpose={purpose}
          type={selectedType}
          eventId={eventEditId}
          selectedDay={modalDate}
          daycare={daycare}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      <div id="printable-planning">
        {`${employee.last_name} ${employee.first_name}`}
        <Row>
          <Col span={4} />
          <Col span={18}>
            <table>
              <HoursBar />
            </table>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} />
        {planningLoading ? (
          <CustomSpin />
        ) : (
          <>
            {(events.eventsResult || []).map((eventResult) => (
              <Week
                key={eventResult[0]?._id}
                events={eventResult}
                openModal={openModal}
                deleteEvent={deleteEvent}
                planningMode={planningMode}
                isPlanningView={false}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

EmployeePlanning.propTypes = {
  employee: PropTypes.shape({
    contract_hours_per_week: PropTypes.number,
    last_name: PropTypes.string,
    first_name: PropTypes.string
  })
};

EmployeePlanning.defaultProps = {
  employee: null
};
