import React, { useState } from 'react';

import { Button, Divider } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import useColumns from './columns';
import { ListResource } from '../../../components';
import InvoicingContext from '../InvoicingContext';
import { AccountingExportButton } from '../../../components/AccountingExportButton/AccountingExportButton';

const Transfers = () => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const { deleteTransfer, downloadTransfer } = InvoicingContext();
  const [isDownloading, setIsDownloading] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const updateTransfer = async (id, body) => {
    try {
      setForceRefresh(false);
      await dispatchAPI('PATCH', { url: `/transfer/${id}`, body });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadContent = (key, record) => (
    <>
      <Button
        onClick={async () => {
          if (!isDownloading[key]) {
            setIsDownloading({ ...isDownloading, [key]: true });
            await downloadTransfer(key, 'csv', () =>
              setIsDownloading({ ...isDownloading, [key]: false })
            );
          }
        }}
      >
        CSV
      </Button>
      <Divider type="vertical" />
      <Button
        onClick={async () => {
          if (!isDownloading[key]) {
            setIsDownloading({ ...isDownloading, [key]: true });
            await downloadTransfer(
              key,
              'xml',
              () => setIsDownloading({ ...isDownloading, [key]: false }),
              record
            );
          }
        }}
      >
        XML
      </Button>
    </>
  );

  const columns = useColumns(
    updateTransfer,
    downloadContent,
    deleteTransfer,
    setForceRefresh,
    isDownloading
  );

  return (
    <ListResource
      resourceName="transfers"
      resourceModelName="Transfers"
      extraQuery={`daycare=${daycare}`}
      withUploadButton={false}
      archivedButton={false}
      extraButtons={<AccountingExportButton objective="payments" />}
      columns={columns}
      customActionColumn
      forceRefresh={forceRefresh}
    />
  );
};

export default Transfers;
