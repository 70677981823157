/**
 * Generates an array of card objects based on provided translation and data.
 *
 * @hook
 *
 * @param {Function} t - The translation function.
 * @param {Object} graphsData - The data for graphs.
 * @param {Object} graphsData.carriedOutHours - The data for carried out hours.
 * @param {Object} graphsData.billedHours - The data for billed hours.
 * @param {Object} graphsData.occupationRate - The data for occupation rate.
 * @param {Object} graphsData.incomesData - The data for operating incomes evolution.
 * @param {Object} graphsData.expensesData - The data for operating expenses evolution.
 * @param {Object} graphsData.resultsData - The data for operating results evolution.
 * @returns {Array<Object>} An array of card objects with title, spanSize, datas, mode, and key properties.
 */

export const useCards = (t, graphsData) => [
  {
    title: t('analysis.cards.carried_out_hours_follow_up'),
    spanSize: 8,
    datas: graphsData.carriedOutHours || {},
    mode: 'follow_up',
    key: 'carried_out_hours'
  },
  {
    title: t('analysis.cards.billed_hours_follow_up'),
    spanSize: 8,
    datas: graphsData.billedHours || {},
    mode: 'follow_up',
    key: 'billed_hours'
  },
  {
    title: t('analysis.cards.occupation_rate_follow_up'),
    spanSize: 8,
    datas: graphsData.occupationRate || {},
    mode: 'follow_up',
    key: 'occupation_rate'
  },
  {
    title: t('analysis.cards.operating_incomes_evolution'),
    spanSize: 24,
    datas: graphsData.incomesData || {},
    mode: 'evolution',
    key: 'incomes'
  },
  {
    title: t('analysis.cards.operating_expenses_evolution'),
    spanSize: 12,
    datas: graphsData.expensesData || {},
    mode: 'evolution',
    key: 'expenses'
  },
  {
    title: t('analysis.cards.operating_results_evolution'),
    spanSize: 12,
    datas: graphsData.resultsData || {},
    mode: 'evolution',
    key: 'results'
  }
];
