import { Flex } from 'antd';
import styled from 'styled-components';
import { icons } from './icons';

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

/**
 * Renders the content of a calendar cell with meal information for a specific date.
 *
 * @function
 *
 * @param {Object} value - The date value for the calendar cell.
 * @param {Function} onModalOpen - Function to handle opening a modal for meal management.
 * @param {Object} meal - Meal information for the date, including meals and their ingredients.
 * @returns {JSX.Element} Rendered content for the calendar cell.
 */
export const dateCellRender = (value, onModalOpen, meal) => {
  const date = new Date(value);

  const mealIcon = (mealType) => {
    switch (mealType) {
      case 'SNACK':
        return 0;
      case 'APPETIZER':
        return 1;
      case 'MAIN_COURSE':
        return 2;
      case 'DESSERT':
        return 3;
      case 'AFTERNOON_SNACK':
        return 4;
      default:
        return 2;
    }
  };

  const mealColor = (mealType) => {
    switch (mealType) {
      case 'SNACK':
        return 'var(--logoPurple)';
      case 'APPETIZER':
        return 'var(--logoOrange)';
      case 'MAIN_COURSE':
        return 'var(--logoBlue)';
      case 'DESSERT':
        return 'var(--logoPink)';
      case 'AFTERNOON_SNACK':
        return 'var(--logoYellow)';
      default:
        return 'var(--borderColor)';
    }
  };

  const handleOnCellClick = () => {
    onModalOpen('meals', date);
  };

  return (
    <Cell onClick={handleOnCellClick}>
      {(meal?.meals || [])
        .map((mealItem) => {
          if (mealItem.name) {
            return (
              <Flex
                justify="space-between"
                gap="small"
                align="center"
                className="calendar-meal-item"
                style={{ borderColor: mealColor(mealItem.meal_type) }}
              >
                <div
                  className="calendar-meal-icon"
                  style={{ backgroundColor: mealColor(mealItem.meal_type) }}
                >
                  <img
                    src={icons[mealIcon(mealItem.meal_type)].icon}
                    alt={icons[mealIcon(mealItem.meal_type)].name}
                    width={16}
                  />
                </div>
                <span style={{ flex: 2 }}>{mealItem.name}</span>
                <Flex className="calendar-meal-ingredients">
                  {(mealItem.ingredients || []).map((ingredient) =>
                    ingredient.icon ? (
                      <img
                        src={ingredient.icon}
                        alt={ingredient.name}
                        width={16}
                      />
                    ) : null
                  )}
                </Flex>
              </Flex>
            );
          }
          return null;
        })
        .filter(Boolean)}
    </Cell>
  );
};
