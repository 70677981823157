import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useTabContent } from './useTabContent';
import { useAuthContext } from '../../contexts/AuthContext';
import { ParentInbox } from './ParentInbox/ParentInbox';

/**
 * `MessagesAndNotes` is a React component that renders a messaging and notes interface.
 * It displays different content based on the user's role, utilizing tabs for navigation if the user is not a 'guests:PARENT'.
 *
 * The component uses Ant Design's `Tabs` for tabbed navigation and custom components like `ContentCustom` and `PageHeaderCustom`
 * for layout and UI elements. The tabs and their content are dynamically generated based on the user's role and access permissions.
 *
 * If the user's role is 'guests:PARENT', the component renders a specialized `ParentInbox` interface for parent-specific messaging.
 *
 * @component
 * @returns {JSX.Element} A JSX element that renders the appropriate messaging and notes interface based on the user's role.
 */
export const MessagesAndNotes = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { items } = useTabContent();
  return user?.role !== 'guests:PARENT' ? (
    <>
      <PageHeaderCustom
        title={t('messages_and_notes.title')}
        withoutBreadCrumbs
        withoutNavigateBack
      />
      <ContentCustom style={{ overflowY: 'hidden' }}>
        <Tabs type="card" defaultActiveKey="conversations" items={items} />
      </ContentCustom>
    </>
  ) : (
    <ParentInbox />
  );
};
