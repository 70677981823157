import { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
// import { UpdateAlert } from '../../components/UpdateAlert/UpdateAlert';
import LoginForm from './LoginForm';
import DoubleFactorAuthForm from '../doubleFactorAuth/DoubleFactorAuthForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import CreatePwdForm from './CreatePwdForm';
import Logo from '../../assets/images/logomaiavillage.png';
import loginBg1 from '../../assets/images/daniela-turcanu-e8_HO1vxizw-unsplash.jpg';
import loginBg2 from '../../assets/images/faith-crabtree-WQQAZ0aTJ1c-unsplash.jpg';
import loginBg3 from '../../assets/images/hector-falcon-sRoK57qexa8-unsplash.jpg';
import loginBg4 from '../../assets/images/raimond-klavins-MPh8bEso87Q-unsplash.jpg';
import loginBg5 from '../../assets/images/mike-cox-PtkuybF_Wx8-unsplash.jpg';

export const Login = () => {
  const location = useLocation();
  const state = location.state || {};
  const { token } = useParams();
  const { t } = useTranslation();
  const { isDoubleFactorAuth } = useAuthContext();
  const { Title } = Typography;
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    doubleFactorAuth: (
      <DoubleFactorAuthForm switchForm={(f) => setCurrentForm(f)} />
    ),
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    ),
    createPwd: <CreatePwdForm switchForm={(f) => setCurrentForm(f)} />
  };

  const imgArray = [loginBg1, loginBg2, loginBg3, loginBg4, loginBg5];

  const getRandomImage = () => {
    const randInd = Math.floor(Math.random() * imgArray.length);
    return imgArray[randInd];
  };

  useEffect(() => {
    if (isDoubleFactorAuth) setCurrentForm('doubleFactorAuth');
  }, [isDoubleFactorAuth]);

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  useEffect(() => {
    if (state === 'createPwd') setCurrentForm('createPwd');
  }, [state]);

  return (
    <>
      {/* <UpdateAlert /> */}
      <Layout
        className="login-layout"
        style={{
          backgroundImage: `url(${getRandomImage()})`
        }}
      >
        <Row justify="center" align="middle">
          <Col
            xs={{ span: 22 }}
            md={{ span: 16 }}
            lg={{ span: 14 }}
            xl={{ span: 10 }}
            xxl={{ span: 8 }}
            className="login-form-col"
          >
            <img
              style={{
                width: 240,
                marginBottom: 56
              }}
              alt="Logo"
              src={Logo}
            />
            <Title className="login-title">
              {t(`login.title.${currentForm}`)}
            </Title>
            {forms[currentForm]}
          </Col>
        </Row>
      </Layout>
    </>
  );
};
