import { days } from '../../../../utils/days';

/**
 * @description Formats the reference weeks to the format required to be displayed in the summary page
 * @param {Array} referenceWeeks - reference weeks
 * @param {String} purpose - purpose of the reference weeks
 * @returns {Array} formatted reference weeks
 */
export const weeksFormatter = (referenceWeeks) =>
  referenceWeeks.map((referenceWeek) => {
    const week = days.map((day, index) => ({
      day,
      time_slots: referenceWeek[index]?.time_slots.map((timeSlot) => ({
        start_time: timeSlot?.start_time,
        end_time: timeSlot?.end_time
      }))
    }));

    return week;
  });

export const isNotEmptyWeek = (weeks) =>
  weeks.some((week) => {
    const dayValues = Object.values(week);
    return dayValues.some(
      (timeSlot) =>
        timeSlot?.time_slots[0]?.start_time ||
        timeSlot?.time_slots[1]?.start_time
    );
  });
