import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Col, Flex, Row } from 'antd';
import PropTypes from 'prop-types';
import { PrinterOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import EventModal from '../../../planning/EventModal';
import { HoursBar } from '../../../../components/HoursBar/HoursBar';
import { PlanningHeader } from './PlanningHeader';
import { Week } from './Week';
import { printPlanning } from './utils/printPlanning';
import { CustomSpin } from '../../../../components/CustomSpin/CustomSpin';
import { usePlanningContext } from '../../../../contexts/PlanningContext';

/**
 * `Planning` is a React component for displaying and managing a child's planning and events,
 * such as absences, adaptations, and pickup times. This component provides functionality for
 * adding, editing, and deleting these events.
 *
 * @component
 *
 * It uses the `useAuthContext` for API requests and `useErrorMessage` for error handling.
 * It also utilizes several child components like `EventModal`, `DayRow`, `HoursBar`, and `PlanningHeader`
 * to render different aspects of the planning.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isParentView - Indicates if the planning is being viewed in a parent's context.
 *
 * @returns {JSX.Element} A component that renders the planning view with all functionalities.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.isParentView {boolean} - Indicates if the planning is being viewed in a parent's context. Required.
 */
export const Planning = ({ child }) => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { setSelectedChild } = usePlanningContext();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [isPlanningLoading, setIsPlanningLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(dayjs.utc().toISOString());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [purpose, setPurpose] = useState('');
  const [eventEditId, setEventEditId] = useState('');
  const [recorderId, setRecorderId] = useState('');
  const [previsionalPlanning, setPrevisionalPlanning] = useState([]);
  const [planningMode, setPlanningMode] = useState('week');
  const [modalDate, setModalDate] = useState();
  const [planningTypeFilters, setPlanningTypeFilters] = useState([
    'REGULAR',
    'OCCASIONAL',
    'ADAPTATION',
    'ABSENCE'
  ]);
  const { t } = useTranslation();
  const [displayPlanningRangeBarButtons, setDisplayPlanningRangeBarButtons] =
    useState(true);

  useEffect(() => {
    const relativeHeaderMonth = dayjs(selectedDay).month();
    const relativeHeaderYear = dayjs(selectedDay).year();
    const todayMonth = dayjs().month();
    const todayYear = dayjs().year();

    const boolean =
      relativeHeaderMonth >= todayMonth - 1 && todayYear === relativeHeaderYear;

    setDisplayPlanningRangeBarButtons(boolean);
  }, [selectedDay]);

  const getChildEvents = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pickup-times/child-events/${child?._id}?daycare=${daycare}&status=ACTIVE&archived=false&date=${selectedDay}&populate=contracts&planning_type=${planningTypeFilters}&planning_mode=${planningMode}`
      });
      setEvents(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setSelectedChild(child);
  }, [child]);

  useEffect(() => {
    if (Object.keys(child).length) {
      (async () => {
        setIsPlanningLoading(true);
        await getChildEvents();
        setIsPlanningLoading(false);
      })();
    }
  }, [daycare, selectedDay, child, planningTypeFilters, planningMode]);

  const openModal = (type, _, purp, eventId, timeRecorderId, refDay, date) => {
    setPurpose(purp);
    setSelectedType(type);
    setEventEditId(eventId);
    setRecorderId(timeRecorderId);
    setIsModalOpen(true);
    setModalDate(date);
    setPrevisionalPlanning(refDay);
  };

  const closeModal = () => {
    setPurpose('');
    setSelectedType('');
    setEventEditId('');
    setRecorderId('');
    setIsModalOpen(false);
    getChildEvents();
    setModalDate();
    setPrevisionalPlanning([]);
  };

  const deleteEvent = async (type, eventId, timeRecorderId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/pickup-times/event/${timeRecorderId}?type=${type}&eventId=${eventId}`
      });
      getChildEvents();
    } catch (e) {
      message(e);
    }
  };

  return (
    <Flex vertical justify="center" style={{ marginTop: -32 }}>
      <Flex justify="flex-end">
        <Button
          onClick={printPlanning}
          type="primary"
          disabled={!events}
          style={{ position: 'relative', top: 32 }}
        >
          <PrinterOutlined />
          {t('buttons.print')}
        </Button>
      </Flex>
      {isModalOpen && (
        <EventModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedChild={child}
          purpose={purpose}
          type={selectedType}
          eventId={eventEditId}
          recorderId={recorderId}
          selectedDay={modalDate}
          daycare={daycare}
          previsionalPlanning={previsionalPlanning}
        />
      )}
      <PlanningHeader
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        planningMode={planningMode}
        setPlanningMode={setPlanningMode}
        plannedTotalTime={events?.plannedTotalTime}
        realTotalTime={events?.realTotalTime}
        loading={isPlanningLoading}
        planningTypeFilters={planningTypeFilters}
        setPlanningTypeFilters={setPlanningTypeFilters}
      />
      <div id="printable-planning">
        <Row>
          <Col span={4} />
          <Col span={18}>
            <table>
              <HoursBar />
            </table>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} />
        {isPlanningLoading ? (
          <CustomSpin />
        ) : (
          <>
            {(events.eventsResult || []).map((eventResult) => (
              <Week
                key={eventResult[0]?._id}
                events={eventResult}
                openModal={openModal}
                deleteEvent={deleteEvent}
                planningMode={planningMode}
                isPlanningView={false}
                displayButtons={displayPlanningRangeBarButtons}
              />
            ))}
          </>
        )}
      </div>
    </Flex>
  );
};

Planning.propTypes = {
  child: PropTypes.shape({
    _id: PropTypes.string
  })
};

Planning.defaultProps = {
  child: null
};
