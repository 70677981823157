import { useState, useEffect } from 'react';
import { Input, Select, Switch, Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { handleDepartment } from '../../../../utils/handleDepartment';

const { Option } = Select;

/**
 * useParentsFields is a custom hook that provides fields and options for parent-related forms.
 *
 * @hook
 *
 * @param {object} form - Form instance.
 *
 * @returns {object} An object containing fields and options for parent-related forms.
 * @property {Array} parentsFields - An array of form fields for creating or editing parent information.
 * @property {boolean} isParentFieldsLoading - A boolean indicating whether parent-related field data is being loaded.
 * @property {object} enums - An object containing enumerated values used in the form.
 * @property {Array} isExistingParentFields - An array of form fields for adding an existing parent to a child's profile.
 * @property {Array} authorizationFields - An array of form fields for parent authorizations.
 * @example
 * // Usage example:
 * const { parentsFields, isParentFieldsLoading, enums, isExistingParentFields, authorizationFields } = useParentsFields();
 */
export const useParentsFields = (form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [isParentFieldsLoading, setIsParentFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [parents, setParents] = useState([]);
  const [relationships, setRelationships] = useState([]);

  const fetchOptions = async () => {
    try {
      const [
        preregistrationResponse,
        childResponse,
        parentsResponse,
        childrenResponse,
        relationshipsResponse
      ] = await Promise.all([
        dispatchAPI('GET', {
          url: '/pre-registrations/enums'
        }),
        dispatchAPI('GET', { url: `children/${id}?populate=siblings` }),
        dispatchAPI('GET', { url: 'parents/create-parent' }),
        dispatchAPI('GET', { url: 'children' }),
        dispatchAPI('GET', { url: 'children/enums' })
      ]);
      setEnums(preregistrationResponse.data);
      setParents(parentsResponse.data);
      setParents((prevParents) => {
        const updatedList = [...(prevParents || []), ...childrenResponse.data];
        const filterListByParents = updatedList.filter(
          (item) =>
            !childResponse.data.parents.some(
              (parentItem) => parentItem.parent._id === item._id
            )
        );
        const filterListBySiblings = filterListByParents.filter(
          (item) =>
            !childResponse.data.siblings.some(
              (sibling) => sibling._id === item._id
            ) && item._id !== id
        );
        return filterListBySiblings;
      });
      setRelationships(relationshipsResponse.data.relationships);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsParentFieldsLoading(true);
      await fetchOptions();
      setIsParentFieldsLoading(false);
    })();
  }, []);

  const parentsFields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['honorific'],
      input: (
        <Select loading={isParentFieldsLoading}>
          {(enums.honorifics || []).map((honorific) => (
            <Option key={honorific} value={honorific}>
              {t(`pre-registrations.form.honorifics.${honorific}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['relationship'],
      rules: [{ required: true }],
      input: (
        <Select loading={isParentFieldsLoading}>
          {(enums.relationships || []).map((relationship) => (
            <Option key={relationship} value={relationship}>
              {t(`pre-registrations.form.relationships.${relationship}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['is_principal_contact'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional']
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }],
      input: (
        <Input
          type="number"
          onChange={(e) => handleDepartment(e, form, ['address', 'state'])}
        />
      )
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'state'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      rules: [{ required: true }],
      name: ['mobile_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['landline_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['landline_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['landline_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['social_security_system'],
      input: (
        <Select loading={isParentFieldsLoading}>
          {(enums.social_security_systems || []).map((system) => (
            <Option key={system} value={system}>
              {t(`pre-registrations.form.security_systems.${system}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['employment_contract'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['pick_up_authorization'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['app_authorization'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    }
  ];

  const isExistingParentFields = [
    {
      name: ['parent'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: 300 }}
          allowClear
          showSearch
          optionFilterProp="children"
        >
          {parents.map((parent) => {
            const fullName = parent.user
              ? `${parent?.user?.first_name} ${parent?.user?.last_name}`
              : `${parent?.first_name} ${parent?.last_name} - ${t(
                  'parents.form.child-in'
                )}`;
            return (
              <Option key={parent?._id} value={parent?._id}>
                {fullName}
              </Option>
            );
          })}
        </Select>
      )
    },
    {
      name: ['relationship'],
      rules: [{ required: true }],
      input: (
        <Select style={{ width: 300 }}>
          {relationships.map((relationship) => (
            <Option value={relationship} key={relationship}>
              {t(`parents.list.relationship.${relationship}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const authorizationFields = [
    {
      name: ['pick_up_authorization'],
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['app_authorization'],
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    }
  ];

  return {
    parentsFields,
    isParentFieldsLoading,
    enums,
    isExistingParentFields,
    authorizationFields
  };
};
