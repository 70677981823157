import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Button, DatePicker, Flex, Space, Timeline } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { ContentCustom } from '../../../../components';
import { activities as activitiesArray } from '../../../Configuration/Activities/activitiesArray';

/**
 * FollowUp Component.
 *
 * This component is responsible for displaying a timeline of activities for a specific child.
 * It uses a DatePicker to allow users to select a date and then fetches and displays activities
 * for the child on that date. The component integrates with several hooks for state management,
 * API calls, and error handling.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.child - The child object for which the follow-up is displayed.
 * @param {string} props.child._id - The ID of the child.
 * @param {Object} props.child.group - The group object associated with the child.
 * @param {string} props.child.group._id - The ID of the child's group.
 *
 * @example
 * const child = {
 *   _id: 'child123',
 *   group: { _id: 'group123' }
 * };
 * <FollowUp child={child} />
 *
 * @returns {React.ReactElement} A React component that displays the follow-up timeline.
 */

export const FollowUp = ({ child }) => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [activities, setActivities] = useState([]);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));

  const getChildFollowUp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `follow-ups/single-child?children=${child._id}&groups=${child.group._id}&date=${date}&daycare=${daycare}`
      });
      setActivities(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getChildFollowUp();
    })();
  }, [child, date]);

  const items = (activities || []).map((activity) => {
    const activityChildren = activitiesArray.find(
      (activityItem) => activityItem.resourceName === activity.activity.type
    );

    return {
      label: <Flex vertical>{dayjs(activity.date).format('HH:mm')}</Flex>,
      dot: <Button shape="circle">{activityChildren?.icon}</Button>,
      children: <Space>{activity.activity.label}</Space>
    };
  });

  return (
    <ContentCustom>
      <Flex justify="flex-end">
        <DatePicker
          format="DD/MM/YYYY"
          onChange={(value) => {
            setDate(value.format('YYYY-MM-DD'));
          }}
        />
      </Flex>
      <Timeline items={items} mode="left" className="followup-timeline" />
    </ContentCustom>
  );
};

FollowUp.propTypes = {
  child: PropTypes.shape({
    _id: PropTypes.string,
    group: PropTypes.shape({
      _id: PropTypes.string
    })
  })
};

FollowUp.defaultProps = {
  child: null
};
