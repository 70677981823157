import { Route, Routes } from 'react-router-dom';
import { InvoicingContextProvider } from './InvoicingContext';
import { ListInvoices } from './invoices/ListInvoices';
import { ShowInvoice } from './invoices/ShowInvoice';
import { ListPayments } from './payments/ListPayments';
import { Exception } from '../../components';
import { ListCustomerAccounts } from './customerAccounts/ListCustomerAccounts';
import { ShowCustomerAccount } from './customerAccounts/ShowCustomerAccount';
import ListTransfers from './transfers/ListTransfers';
import CreateUpdateTransfer from './transfers/CreateUpdateTransfer';
import ShowTransfer from './transfers/ShowTransfer';
import ListRejections from './rejections/ListRejections';
import { CreateUpdateInvoice } from './invoices/CreateUpdateInvoice';
import ShowRejection from './rejections/ShowRejection';

export const InvoicingRouter = () => (
  <InvoicingContextProvider>
    <Routes>
      <Route path="/invoices" element={<ListInvoices />} />
      <Route
        path="/invoices/create/"
        element={<CreateUpdateInvoice purpose="create" />}
      />
      <Route
        path="/invoices/edit/:id"
        element={<CreateUpdateInvoice purpose="edit" />}
      />
      <Route path="/invoices/show/:id" element={<ShowInvoice />} />
      <Route path="/payments" element={<ListPayments />} />
      <Route
        path="/customers-accounts/show/:id"
        element={<ShowCustomerAccount />}
      />
      <Route path="/customers-accounts" element={<ListCustomerAccounts />} />
      <Route path="/transfers" element={<ListTransfers />} />
      <Route
        path="/transfers/create"
        element={<CreateUpdateTransfer purpose="create" />}
      />
      <Route
        path="/transfers/edit/:id"
        element={<CreateUpdateTransfer purpose="edit" />}
      />
      <Route path="/transfers/show/:id" element={<ShowTransfer />} />
      <Route path="/rejections" element={<ListRejections />} />
      <Route path="/rejections/show/:id" element={<ShowRejection />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </InvoicingContextProvider>
);
