import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Steps, Layout, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { ReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { useContractMethods } from './ContractMethods';
import {
  weeksFormatter,
  isNotEmptyWeek
} from '../pre-registrations/Summary/utils/weeks';
import { submitForm } from './utils/submitForm';
import mainBg from '../../assets/images/mainBg.png';
import { useContractForms } from './useContractForms';
import { SubmitModal } from '../pre-registrations/SubmitModal';
import { SuccessContract } from './SuccessContract';
import { formItemLayout } from '../../utils/constants/formLayout';
import { PreRegistrationAlreadyCompleted } from './PreRegistrationAlreadyCompleted';

/**
 * Component for rendering the contract creation form.
 *
 * @component
 * @param {string} origin - Origin of where the component is called.
 * @param {string} purpose - Purpose of the form.
 * @returns {React.Component} Contract creation form component
 */
export const CreateUpdateContract = ({ origin, purpose }) => {
  const { childID, preRegistrationID, contractID, daycareId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI, daycare, token } = useAuthContext();
  const { message } = useErrorMessage();
  const { getChildName, getMandatoryDocuments, getContract, fetchDecodedIds } =
    useContractMethods();
  const [documents, setDocuments] = useState([]);
  const [current, setCurrent] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [termAndConditions, setTermsAndConditions] = useState(null);
  const [planning, setPlanning] = useState(null);
  const [billing, setBilling] = useState(null);
  const [customerAccount, setCustomerAccount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [childName, setChildName] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('existingCustomerAccount');
  const [child, setChild] = useState();
  const [isReadyForSubmit, setIsReadyForSubmit] = useState(false);
  const [isFlexible, setIsFlexible] = useState(false);
  const [directDebit, setDirectDebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ids, setIds] = useState({
    preRegistrationID: null,
    daycareId: null
  });
  const [preRegistrationStatus, setPreRegistrationStatus] = useState('NONE');
  const [dataPrincipalParent, setDataPrincipalParent] = useState({});
  const [filesList, setFilesList] = useState([]);
  const [form] = Form.useForm();
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);

  const BgLayout = styled(Layout)`
    background: ${origin === 'PARENTS' ? `url(${mainBg})` : 'transparent'};
    height: ${origin === 'PARENTS' ? '100vh' : 'fit-content'};
    overflow: hidden auto;
  `;

  const title = () => {
    switch (true) {
      case origin === 'CHILD':
        return `${t(`contracts.form.title.${purpose}`)} ${child?.first_name} ${
          child?.last_name
        }`;
      default:
        return `${t('contract.pre-registration.validate')} ${childName}`;
    }
  };

  const fetchDataPreRegistration = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'pre-registrations/copy_principal_parent',
        body: {
          preRegistrationID
        }
      });
      setDataPrincipalParent(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (!token) {
      (async () => {
        await fetchDecodedIds({ preRegistrationID, daycareId }, setIds);
      })();
    }
    if (preRegistrationID) {
      (async () => {
        await fetchDataPreRegistration();
      })();
    }
  }, [preRegistrationID, daycareId]);

  const fetchStatus = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'pre-registrations/get-status',
        body: {
          id_preregistration: preRegistrationID,
          id_daycare: daycareId
        }
      });

      if (data === 'WAITING' || data === 'COMPLETED' || data === 'ACCEPTED') {
        setPreRegistrationStatus(data);
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (!token) {
      fetchStatus();
    }
  }, [token]);

  const getChildNameData = async () => {
    let method = '';
    let url = '';
    let body = {};

    if (origin === 'PARENTS' && !ids.preRegistrationID) {
      return;
    }

    switch (origin) {
      case 'CHILD':
        method = 'GET';
        url = `children/${childID}?populate=parents.user,contracts`;
        break;
      case 'PARENTS':
        method = 'POST';
        url = 'pre-registrations/child-name';
        body = { id: ids.preRegistrationID };
        break;
      default:
        method = 'GET';
        url = `pre-registrations/${preRegistrationID}`;
        break;
    }

    const data = await getChildName(method, url, body);
    if (data) {
      if (origin === 'CHILD') {
        setChild(data);
      } else {
        const name =
          origin === 'PARENTS'
            ? `${data?.first_name} ${data?.last_name}`
            : `${data?.child?.first_name} ${data?.child?.last_name}`;
        setChildName(name);
      }
    }
  };

  const getMandatoryDocumentsData = async () => {
    const data = await getMandatoryDocuments(ids.daycareId || daycare);
    setDocuments(data.documents_dynamic_list);
  };

  useEffect(() => {
    (async () => {
      if (ids.preRegistrationID || preRegistrationID || childID) {
        await getChildNameData();
      }
      await getMandatoryDocumentsData();
    })();
  }, [ids.preRegistrationID, preRegistrationID, childID]);

  const onFinishInfoForm = () => {
    const values = form.getFieldsValue();
    if (
      values.contract.billing_type === undefined ||
      values.contract.planning_type === undefined ||
      values.diet.meal_type === undefined ||
      values.authorizations.exit_permission === undefined ||
      values.authorizations.capture_media === undefined ||
      values.authorizations.apply_makeup === undefined ||
      values.authorizations.administer_antipyretic === undefined ||
      values.authorizations.admin_care === undefined ||
      !values.authorizations.consent_date
    ) {
      return message(t('REQUIRED_FIELDS'));
    }
    setAdditionalInfo(values);
    return setCurrent(current + 1);
  };

  const onFinishCustomerForm = () => {
    const values = form.getFieldsValue();
    if (!values.customer) {
      if (
        values?.customer_nature === undefined ||
        values?.label === undefined
      ) {
        return message(t('REQUIRED_FIELDS'));
      }
    }
    setCustomerAccount(values);
    return setCurrent(current + 1);
  };

  const onFinishPaymentForm = () => {
    const values = form.getFieldsValue();
    setPaymentMethod(values);
    setCurrent(current + 1);
  };

  const onFinishTermsForm = () => {
    const values = form.getFieldsValue();
    setTermsAndConditions(values);
    if (purpose === 'edit' && origin === 'CHILD' && isFlexible) {
      setIsReadyForSubmit(true);
    } else {
      setCurrent(current + 1);
    }
  };

  const onFinishBilling = () => {
    const values = form.getFieldsValue();
    setBilling(values);
    setIsReadyForSubmit(true);
  };

  const handlePrevious = () => {
    setCurrent(current - 1);
  };

  const {
    submitFormFromChildren,
    submitFormFromPreRegistration,
    submitFormPatch
  } = submitForm(
    origin,
    dispatchAPI,
    navigate,
    documents,
    filesConfiguration,
    message,
    t,
    filesList,
    ids.daycareId || daycare,
    childID,
    contractID,
    termAndConditions,
    planning,
    billing,
    ids.preRegistrationID || preRegistrationID,
    additionalInfo,
    customerAccount,
    paymentMethod,
    setIsLoading,
    setIsReadyForSubmit,
    setSuccess
  );

  const handleSubmit = () => {
    if (origin === 'CHILD' && purpose === 'create') {
      submitFormFromChildren();
    } else if (origin === 'CHILD' && purpose === 'edit') {
      submitFormPatch();
    } else {
      submitFormFromPreRegistration();
    }
    setReferenceWeeks([
      [
        { day: 'monday', time_slots: [] },
        { day: 'tuesday', time_slots: [] },
        { day: 'wednesday', time_slots: [] },
        { day: 'thursday', time_slots: [] },
        { day: 'friday', time_slots: [] }
      ]
    ]);
  };

  useEffect(() => {
    if (isReadyForSubmit) {
      handleSubmit();
    }
  }, [isReadyForSubmit]);

  const onFinishPlanningForm = (values) => {
    if (!isNotEmptyWeek(referenceWeeks)) {
      message(t('empty_week'));
      return;
    }

    const contract = {
      ...values.contract,
      reference_weeks: weeksFormatter(referenceWeeks, purpose)
    };

    setPlanning((prevPlanning) => ({ ...prevPlanning, contract }));
    if (origin === 'CHILD' && purpose === 'edit') {
      setIsReadyForSubmit(true);
    } else {
      setCurrent(current + 1);
    }
  };

  const getContractData = async () => {
    const data = await getContract(contractID);
    form.setFieldsValue({
      ...data,
      start_date: dayjs(data.start_date),
      end_date: dayjs(data.end_date)
    });

    if (data) {
      setTermsAndConditions({
        ...data,
        start_date: dayjs(data.start_date),
        end_date: dayjs(data.end_date)
      });
      setReferenceWeeks(data.reference_weeks);
      setPlanning({
        contract: {
          ...data.contract,
          reference_weeks: weeksFormatter(referenceWeeks, purpose)
        }
      });
      setBilling({
        parents: child?.parents,
        household: {
          ...data.household
        },
        customer_account: data.customer_account,
        ...data.payment_method
      });
      setFilesList(data.documents.map((doc) => doc.file));
      setFilesConfiguration(
        data.documents.map((doc) => ({
          name: doc?.file?.metadata?.originalName,
          fileKey: doc.document_title,
          type: doc.type
        }))
      );
      setIsFlexible(data.is_flexible_planning);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getContractData();
      })();
    }
  }, [child]);

  const { contractForms } = useContractForms(
    origin,
    purpose,
    onFinishTermsForm,
    termAndConditions,
    setIsFlexible,
    isFlexible,
    child,
    isLoading,
    onFinishPlanningForm,
    handlePrevious,
    planning,
    handleSubmit,
    onFinishBilling,
    billing,
    additionalInfo,
    onFinishInfoForm,
    customerAccount,
    onFinishCustomerForm,
    selectedRadio,
    setSelectedRadio,
    paymentMethod,
    onFinishPaymentForm,
    setDocuments,
    documents,
    filesConfiguration,
    setFilesConfiguration,
    filesList,
    setFilesList,
    contractID,
    form,
    directDebit,
    setDirectDebit,
    dataPrincipalParent
  );

  const render = () => {
    switch (true) {
      case origin === 'PARENTS' && success:
        return <SuccessContract />;
      case origin === 'PARENTS' &&
        preRegistrationStatus &&
        (preRegistrationStatus === 'COMPLETED' ||
          preRegistrationStatus === 'ACCEPTED'):
        return <PreRegistrationAlreadyCompleted />;
      default:
        return (
          <Flex vertical gap="large">
            <Steps current={current}>
              {origin === 'CHILD' ? (
                <>
                  <Steps.Step
                    title={t('contracts.form.steps.terms-and-conditions')}
                  />
                  {!isFlexible && (
                    <Steps.Step title={t('contracts.form.steps.planning')} />
                  )}
                  {purpose === 'create' && (
                    <Steps.Step title={t('contracts.form.steps.billing')} />
                  )}
                </>
              ) : (
                <>
                  <Steps.Step title={t('contract.steps.additional_info')} />
                  {origin !== 'PARENTS' && (
                    <Steps.Step title={t('contract.steps.customer_account')} />
                  )}
                  <Steps.Step title={t('contract.steps.payment_method')} />
                  <Steps.Step title={t('contract.steps.documents')} />
                </>
              )}
            </Steps>
            <Form {...formItemLayout} form={form}>
              {contractForms[current]}
            </Form>
          </Flex>
        );
    }
  };

  return (
    <BgLayout>
      <SubmitModal isSubmitting={isLoading} />
      <PageHeaderCustom
        withoutBreadCrumbs
        withoutNavigateBack={origin === 'PARENTS'}
        title={title()}
      />
      <ContentCustom>
        {origin === 'PARENTS' && success ? (
          <SuccessContract />
        ) : (
          <Flex vertical gap="large">
            {render()}
          </Flex>
        )}
      </ContentCustom>
    </BgLayout>
  );
};

CreateUpdateContract.propTypes = {
  origin: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired
};
