import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useLabelColumns } from '../labelColumns';

/**
 * FoodAllergiesTable component for displaying a table of food allergies and managing their data.
 *
 * @component
 * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {React.Element} The rendered FoodAllergiesTable component.
 */
export const FoodAllergiesTable = ({ onModalOpen, setRefresh, refresh }) => {
  const { t } = useTranslation();
  const columns = useLabelColumns(setRefresh, onModalOpen, 'food-allergies');

  return (
    <>
      <Flex justify="end">
        <Button
          onClick={() => onModalOpen({}, 'create', 'food-allergies')}
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <ListResource
        resourceName="food-allergies"
        columns={columns}
        resourceModelName="Food-allergies"
        archivedButton={false}
        withPageHeader={false}
        showAction={false}
        onDoubleClickAction={false}
        withUploadButton={false}
        openModal
        withCreateButton={false}
        customActionColumn
        forceRefresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

FoodAllergiesTable.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool
};

FoodAllergiesTable.defaultProps = {
  refresh: false
};
