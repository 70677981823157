import PropTypes from 'prop-types';
import { Button, Popconfirm, Tooltip, Flex } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  WarningOutlined,
  SendOutlined,
  FileTextOutlined,
  UndoOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/constants/adminRoutes';
import { PreRegistrationsMethods } from './methods';

/**
 * @component - ExtraButtons
 * @description
 * ExtraButtons is a component that displays the extra buttons for the pre-registration show page.
 * It is displayed in the ShowPreRegistration component.
 * The buttons are different depending on the status of the pre-registration.
 * The buttons are used to accept, refuse, edit, archive or finalize the pre-registration.
 * @prop {boolean} isLoading - If the data is loading
 * @prop {function} setLoading - Function to set the loading state
 * @prop {Object} preRegistration - The pre-registration data
 * @prop {string} childName - The first name and last name of the child in one string
 * @prop {function} updatePreRegistrationStatus - Function to update the status of the pre-registration
 * @returns {React.Component} ExtraButtons component
 */
export const ExtraButtons = ({
  isLoading,
  setLoading,
  preRegistration,
  childName,
  updatePreRegistrationStatus
}) => {
  const { t } = useTranslation();
  const { getStatusByDaycare, isAlreadyAcceptedByAnotherDaycare, resendEmail } =
    PreRegistrationsMethods();
  const { daycares, _id } = preRegistration;
  const status = getStatusByDaycare(daycares);
  const isAlreadyAccepted = isAlreadyAcceptedByAnotherDaycare(daycares);

  const renderEditButton = () => (
    <Link to={`${routes.PRE_REGISTRATION}/edit/${_id}`}>
      <Button type="primary">
        {`${t('buttons.edit')} `}
        <EditOutlined />
      </Button>
    </Link>
  );

  const renderDeclineButton = () => (
    <Popconfirm
      title={t('datatable.column.action.refuse.title')}
      okText={t('datatable.column.action.refuse.ok')}
      okButtonProps={{ danger: true }}
      cancelText={t('datatable.column.action.refuse.cancel')}
      onConfirm={() => updatePreRegistrationStatus('REFUSED')}
      icon={<WarningOutlined />}
    >
      <Button danger disabled={isLoading}>
        <CloseOutlined />
        {`${t('buttons.decline')} `}
      </Button>
    </Popconfirm>
  );

  const renderArchiveButton = () => (
    <Popconfirm
      title={t('datatable.column.action.archive.title')}
      okText={t('datatable.column.action.archive.ok')}
      okButtonProps={{ danger: true }}
      cancelText={t('datatable.column.action.archive.cancel')}
      onConfirm={() => updatePreRegistrationStatus('ARCHIVED')}
      icon={<WarningOutlined />}
    >
      <Button danger disabled={isLoading}>
        <CloseOutlined />
        {`${t('buttons.archive')} `}
      </Button>
    </Popconfirm>
  );

  const renderSendSignatureEmailButton = () => (
    <Popconfirm
      title={t('pre-registrations.show.tabs.resend_signature_email_warning')}
      okText={t('pre-registrations.show.tabs.resend_signature_email')}
      okButtonProps={{ danger: true }}
      cancelText={t('datatable.column.action.archive.cancel')}
      onConfirm={() => resendEmail(_id, setLoading)}
      icon={<WarningOutlined />}
    >
      <Button type="primary" disabled={isLoading}>
        <SendOutlined />
        {`${t('pre-registrations.show.tabs.resend_signature_email')} `}
      </Button>
    </Popconfirm>
  );

  const renderReintegrateButton = () => (
    <Popconfirm
      title={t('datatable.column.action.reintegrate.title')}
      okText={t('datatable.column.action.reintegrate.ok')}
      okButtonProps={{ danger: true }}
      cancelText={t('datatable.column.action.archive.cancel')}
      onConfirm={() => updatePreRegistrationStatus('PENDING')}
      icon={<WarningOutlined />}
    >
      <Tooltip
        title={
          isAlreadyAccepted &&
          t('pre-registrations.show.tabs.is_already_accepted')
        }
      >
        <Button disabled={isLoading || isAlreadyAccepted}>
          <UndoOutlined />
          {`${t('buttons.reintegrate')} `}
        </Button>
      </Tooltip>
    </Popconfirm>
  );

  switch (status) {
    case 'PENDING':
      return (
        <Flex gap="small">
          <Tooltip
            title={t('pre-registrations.show.tabs.accept_and_fill_form')}
          >
            <Link
              to={`${routes.PRE_REGISTRATION}/accept/${_id}`}
              state={{ childName }}
            >
              <Button type="primary">
                <FileTextOutlined />
                {`${t('buttons.accept_and_fill')} `}
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            title={t('pre-registrations.show.tabs.accept_and_send_mail')}
          >
            <Popconfirm
              title={t(
                'pre-registrations.show.tabs.accept_and_send_mail_warning'
              )}
              okText={t('pre-registrations.show.tabs.accept_folder')}
              cancelText={t('buttons.cancel')}
              onConfirm={() => updatePreRegistrationStatus('WAITING')}
              icon={<WarningOutlined />}
            >
              <Button type="primary" disabled={isLoading}>
                <SendOutlined />
                {`${t('buttons.accept')} `}
              </Button>
            </Popconfirm>
          </Tooltip>
          {renderEditButton()}
          {renderDeclineButton()}
        </Flex>
      );
    case 'WAITING':
      return (
        <Flex gap="small">
          {renderEditButton()}
          {renderDeclineButton()}
        </Flex>
      );
    case 'COMPLETED':
      return (
        <Flex gap="small">
          <Link to={`${routes.PRE_REGISTRATION}/contract-validation/${_id}`}>
            <Button type="primary" disabled={isLoading}>
              <CheckOutlined />
              {`${t('buttons.final')} `}
            </Button>
          </Link>
          {renderEditButton()}
          {renderDeclineButton()}
        </Flex>
      );
    case 'WAITING_FOR_SIGNATURE':
      return renderSendSignatureEmailButton();
    case 'ACCEPTED':
      return renderArchiveButton();
    case 'REFUSED':
      return (
        <>
          {renderArchiveButton()}, {renderReintegrateButton()}
        </>
      );
    default:
      return null;
  }
};

ExtraButtons.propTypes = {
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  preRegistration: PropTypes.shape({
    daycares: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        status: PropTypes.string
      })
    ),
    contract: PropTypes.shape({
      _id: PropTypes.string
    }),
    _id: PropTypes.string
  }),
  childName: PropTypes.string,
  updatePreRegistrationStatus: PropTypes.func
};

ExtraButtons.defaultProps = {
  isLoading: false,
  setLoading: () => {},
  preRegistration: {
    daycares: [],
    contract: {
      _id: ''
    },
    _id: ''
  },
  childName: '',
  updatePreRegistrationStatus: () => {}
};
