import PropTypes from 'prop-types';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useLabelColumns } from '../labelColumns';

/**
 * OccasionalReservationReasonsTable
 * @description Component for occasional reservation reasons table, displayed in global settings
 * @component
 * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {component} OccasionalReservationReasonsTable
 */
export const OccasionalReservationReasonsTable = ({
  onModalOpen,
  setRefresh,
  refresh
}) => {
  const { t } = useTranslation();
  const columns = useLabelColumns(
    setRefresh,
    onModalOpen,
    'occasional-reservation-reasons'
  );

  return (
    <>
      <Flex justify="end">
        <Button
          onClick={() =>
            onModalOpen({}, 'create', 'occasional-reservation-reasons')
          }
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <ListResource
        resourceName="occasional-reservation-reasons"
        columns={columns}
        resourceModelName="Occasional-reservation-reason"
        archivedButton={false}
        withPageHeader={false}
        showAction={false}
        onDoubleClickAction={false}
        withUploadButton={false}
        openModal
        forceRefresh={refresh}
        withCreateButton={false}
        customActionColumn
      />
    </>
  );
};

OccasionalReservationReasonsTable.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool
};

OccasionalReservationReasonsTable.defaultProps = {
  refresh: false
};
