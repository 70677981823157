import PropTypes from 'prop-types';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useLabelColumns } from '../labelColumns';

/**
 * ChildhoodIllnessesTable component for displaying a table of childhood illnesses and managing their data.
 *
 * @component
 *  * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {React.Element} The rendered ChildhoodIllnessesTable component.
 */
export const ChildhoodIllnessesTable = ({
  onModalOpen,
  setRefresh,
  refresh
}) => {
  const { t } = useTranslation();
  const columns = useLabelColumns(
    setRefresh,
    onModalOpen,
    'childhood-illnesses'
  );

  return (
    <>
      <Flex justify="end">
        <Button
          onClick={() => onModalOpen({}, 'create', 'childhood-illness')}
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <ListResource
        resourceName="childhood-illness"
        columns={columns}
        resourceModelName="Childhood-illness"
        archivedButton={false}
        withPageHeader={false}
        showAction={false}
        onDoubleClickAction={false}
        withUploadButton={false}
        openModal
        withCreateButton={false}
        customActionColumn
        forceRefresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

ChildhoodIllnessesTable.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool
};

ChildhoodIllnessesTable.defaultProps = {
  refresh: false
};
