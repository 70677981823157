import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Button, Flex, Divider } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useContractFields } from '../Fields/contractFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { ReferenceWeek } from '../ReferenceWeek';
import { ReferenceWeekContext } from '../../../contexts/ReferenceWeekContext';
import { formItemLayout } from '../../../utils/constants/formLayout';

/**
 * Component for rendering the contract form.
 * This component is rendered inside the pre-registration form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @prop {String} purpose String containing the purpose of the form.
 * @returns {React.Component} Contract form component
 */
export const ContractForm = ({
  onFinish,
  handlePrevious,
  initialValues,
  purpose
}) => {
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const [isFlexible, setIsFlexible] = useState(false);
  const { contractFields } = useContractFields(setIsFlexible);
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      onFinish={(values) => onFinish(values, isFlexible)}
      {...formItemLayout}
      form={form}
    >
      {contractFields.map((field) =>
        generateFields('pre-registrations', field)
      )}
      <Flex className="prereg-planning" vertical>
        <Divider> {t('pre-registrations.divider.desired_planning')}</Divider>
        {referenceWeeks?.map((_, index) => (
          <ReferenceWeek
            index={index}
            initialValues={initialValues?.contract?.reference_weeks}
            purpose={purpose}
          />
        ))}
        {referenceWeeks?.length < 10 && (
          <Button onClick={() => setReferenceWeeks([...referenceWeeks, []])}>
            {t('buttons.add_reference_week')}
          </Button>
        )}
      </Flex>
      <Flex justify="end" gap="small">
        <Button
          type="primary"
          onClick={() => {
            handlePrevious();
          }}
        >
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </Form>
  );
};

ContractForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    contract: PropTypes.shape({
      reference_weeks: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
};
ContractForm.defaultProps = {
  initialValues: {}
};
