/**
 * Filters an array of documents based on their type.
 *
 * The function takes an array of document objects and sorts them into two categories based on the type:
 * 'ATTESTATION' and all other types which are categorized as 'otherDocuments'.
 *
 * @function
 * @param {Object[]} documents - An array of document objects.
 * @param {string} documents[].type - The type of the document.
 * @returns {Object} An object containing two arrays: 'proofOfAbsences' and 'otherDocuments'.
 */
export const filterDocuments = (documents) =>
  documents.reduce(
    (acc, item) => {
      if (item.type === 'ATTESTATION') {
        acc.proofOfAbsences.push(item);
      } else {
        acc.otherDocuments.push(item);
      }
      return acc;
    },
    { proofOfAbsences: [], otherDocuments: [] }
  );
