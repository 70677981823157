import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card, Skeleton, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { VisibleButton } from '../../../../components/VisibleButton/VisibleButton';
import { useListContent } from './useListContent';

/**
 * @description
 * BankInformationsCard is a component that displays the invoicing informations
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the child
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} BankInformationsCard component
 */
export const BankInformationsCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const listContent = useListContent(visible, data);
  const title = t('pre-registrations.show.cards.bank_informations');
  const isDirectDebitPayment =
    data?.contract?.payment_method?.method === 'DIRECTDEBIT';

  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      title={
        <Flex justify="space-between">
          {title}
          {isDirectDebitPayment && (
            <VisibleButton visible={visible} setVisible={setVisible} />
          )}
        </Flex>
      }
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <DescriptionList data={listContent} translate layout="vertical" />
      </Skeleton>
    </Card>
  );
};

BankInformationsCard.propTypes = {
  data: PropTypes.shape({
    contract: PropTypes.shape({
      payment_method: PropTypes.shape({
        method: PropTypes.string
      })
    })
  }).isRequired,
  isLoading: PropTypes.bool
};

BankInformationsCard.defaultProps = {
  isLoading: false
};
