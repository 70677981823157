import { reverseConvertEventType } from '../../employee/planning/utils';

/**
 * Generates parameters for opening a modal based on the event.
 *
 * @function
 *
 * @param {object} event - The event object.
 * @returns {object} - Parameters for opening a modal, including type and id.
 */
export const generateOpenModalParameters = (event) => {
  let type;
  let id;
  if (event?.childSchedule?.pickUpAndPlanningMatch?.length > 0) {
    type = event?.childSchedule?.pickUpAndPlanningMatch[0].type;
    id = event?.childSchedule?.pickUpAndPlanningMatch[0].id;
  }

  return { type: reverseConvertEventType(type), id };
};
