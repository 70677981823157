import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Logo from '../../assets/images/logomaiavillage.png';
import LogoCollapsed from '../../assets/images/logomaiavillage_square.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { MercuryAppContextProvider } from '../../contexts/MercuryAppContext';
import packageJson from '../../../package.json';
import mainBg from '../../assets/images/mainBg.png';
import { ParentNavMenu } from './ParentNavMenu';
import { ParentFooterMenu } from './ParentFooterMenu';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100dvh;
  max-width: 100vw;
  overflow: hidden;

  @supports (-moz-appearance: none) {
    height: 100vh;
  }
`;

const BgLayout = styled(Layout)`
  background-image: url(${mainBg});
  background-size: cover;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow: hidden auto;
  display: block;
  z-index: 9;

  @media screen and (max-width: 575px) {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100vw;
    max-width: 100vw;
  }

  @supports (-moz-appearance: none) {
    min-height: 100vh;
    max-height: 100vh;
  }
`;

const StyledSider = styled.div`
  height: 100dvh;
  z-index: 10;
  overflow: hidden;
  position: fixed;
  left: 0;
  padding-top: 12px;

  @supports (-moz-appearance: none) {
    height: 100vh;
  }
`;

const LogoDiv = styled.div`
  position: relative;
  height: 64px;
  padding-left: 0;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 52px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  min-height: calc(100dvh - 48px);
  max-height: calc(100dvh - 48px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  @supports (-moz-appearance: none) {
    min-height: calc(90vh - 48px);
    max-height: calc(90vh - 48px);
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 10px;
  text-align: right;
  background: transparent;
  color: #fff;

  a {
    color: #fff !important;
    &:hover {
      color: var(--logoYellow) !important;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
`;

/**
 * `ParentLayout` is a React component that provides the main layout structure for the application.
 * It includes a sidebar navigation, header, and main content area. The layout is responsive,
 * adjusting based on the window width, and includes a theme switcher for user customization.
 *
 * The component utilizes various libraries such as styled-components for styling, Ant Design for UI elements,
 * and react-i18next for internationalization. It also uses React Router for navigation and dayjs for date handling.
 *
 * @returns {JSX.Element} The JSX for the parent layout, including the sidebar, header, content area,
 * and footer. It also provides a context provider for application-wide state management.
 */
export const ParentLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <MercuryAppContextProvider>
      <StyledLayout as={Layout}>
        {/* <UpdateAlert /> */}
        <StyledSider
          as={Sider}
          width={256}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsedWidth={collapseWidth}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <LogoDiv>
            <Link to="/">
              <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
            </Link>
          </LogoDiv>
          <ParentNavMenu setCollapsed={setCollapsed} />
        </StyledSider>
        <BgLayout>
          <HeaderLayout
            collapsed={collapsed}
            collapseSider={setCollapsed}
            collapseWidth={collapseWidth}
          />
          <StyledContent as={Content} id="mainContent">
            <Outlet />
            <StyledFooter as={Footer}>
              {width < 768 ? (
                <ParentFooterMenu />
              ) : (
                <>
                  {`${t('global.title')} v${
                    packageJson.version
                  } ©${dayjs().year()} powered by `}
                  <a href="https://strateg.in">Strateg.in</a>
                </>
              )}
            </StyledFooter>
          </StyledContent>
        </BgLayout>
      </StyledLayout>
    </MercuryAppContextProvider>
  );
};
