import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import {
  contractStatus,
  contractTypes
} from '../../../../utils/constants/tagColors';
import { routes } from '../../../../utils/constants/adminRoutes';
import contentToDisplay from '../../../pre-registrations/Descriptions/BankInformationsCard/contentToDisplay';

/**
 * Custom hook that generates the content for a contract list item.
 *
 * @hook
 * @param {Object} data - The contract data.
 * @param {boolean} visible - The visibility flag.
 * @returns {Object} - The contract content information.
 */
export const useContractListContent = (data, visible) => {
  const {
    number,
    start_date,
    end_date,
    status,
    parents,
    customer_account,
    payment_method,
    hourly_rate,
    applied_fee,
    billing_type,
    planning_type,
    rib,
    household,
    terminated_date
  } = data;
  const { t } = useTranslation();

  const getSocialSecuritySystem = (parent) => {
    if (parent && parent.parent && parent?.parent?.social_security_system) {
      return t(`${parent?.parent?.social_security_system}`);
    }
    return '-';
  };

  const relatedContractParents = (parents || []).filter(
    (parent) => parent.contract_related === true
  );

  const contractInformation = [
    {
      label: 'contracts.list.number',
      content: number,
      span: 1
    },
    {
      label: `contracts.list.status.status`,
      content: (
        <Tag color={contractStatus[status]}>
          {t(`contracts.list.status.${status}`)}
        </Tag>
      ),
      span: 1
    },
    {
      label: 'contracts.list.start_date',
      content: dayjs(start_date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: 'contracts.list.end_date',
      content: dayjs(end_date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: `contracts.list.billing_type`,
      content: (
        <Tag color={contractTypes[billing_type]}>
          {billing_type ? t(`contracts.list.types.${billing_type}`) : '-'}
        </Tag>
      ),
      span: 1
    },
    {
      label: `contracts.list.planning_type`,
      content: planning_type ? t(`contracts.list.types.${planning_type}`) : '-',
      span: 1
    },
    ...relatedContractParents.map((parent, index) => ({
      label:
        index === 0
          ? 'contracts.list.firstParent'
          : 'contracts.list.secondParent',
      content: parents && (
        <Link to={`${routes.PARENTS}/show/${parent?.parent?._id}`}>
          {`${parent?.parent?.user?.first_name} ${parent?.parent?.user?.last_name}`}
        </Link>
      ),
      span: 1
    })),
    ...(terminated_date
      ? [
          {
            label: `contracts.list.terminated_date`,
            content: dayjs(terminated_date).format('DD/MM/YYYY'),
            span: 1
          }
        ]
      : [])
  ];

  const customerAccount = [
    {
      label: 'contracts.show.customer_account',
      content: customer_account?.label,
      span: 1
    }
  ];

  const feeInformation = [
    {
      label: 'contracts.show.payment_method',
      content: t(`contract.form.payment_methods.${payment_method?.method}`),
      span: 1
    },
    {
      label: 'contracts.show.presence_fee',
      content: hourly_rate && `${hourly_rate}€`,
      span: 1
    },
    {
      label: 'contracts.show.applied_fee',
      content: applied_fee,
      span: 1
    }
  ];

  const cafInformation = [
    {
      label: 'contracts.show.beneficiary_number',
      content: household?.beneficiary_number,
      span: 1
    },
    {
      label: 'contracts.show.annual_income',
      content: household?.annual_incomes && `${household?.annual_incomes}€`,
      span: 1
    },
    {
      label: 'contracts.show.dependent_children',
      content: household?.dependent_children,
      span: 1
    },
    {
      label: 'contracts.show.dependent_children_with_disabilities',
      content: household?.dependent_children_with_disabilities,
      span: 1
    },
    {
      label: 'contracts.show.parent1_security_system',
      content:
        (parents &&
          parents.length > 0 &&
          getSocialSecuritySystem(parents[0])) ||
        '-',
      span: 1
    },
    {
      label: 'contracts.show.parent2_security_system',
      content:
        (parents &&
          parents.length > 1 &&
          getSocialSecuritySystem(parents[1])) ||
        '-',
      span: 1
    }
  ];

  const sepaInformation = [
    {
      label: 'contracts.show.name',
      content: rib?.name,
      span: 1
    },
    {
      label: 'contracts.show.iban',
      content: contentToDisplay(rib?.iban, visible),
      span: 1
    },
    {
      label: 'contracts.show.bic',
      content: contentToDisplay(rib?.bic, visible),
      span: 1
    },
    {
      label: 'contracts.show.signed_at',
      content: dayjs(rib?.signature_date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: 'contracts.show.rum',
      content: contentToDisplay(rib?.rum, visible),
      span: 2
    }
  ];

  return {
    contractInformation,
    customerAccount,
    feeInformation,
    cafInformation,
    sepaInformation
  };
};
