import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useVaccinesFields = () => {
  const { t } = useTranslation();

  const vaccinesFields = [
    {
      label: 'vaccine_denomination',
      name: 'name',
      rules: [{ required: true }]
    },
    {
      label: 'second_dose_interval',
      name: 'second_dose_interval',
      input: <Input suffix={t('daycares.form.months')} />
    },
    {
      label: 'third_dose_interval',
      name: 'third_dose_interval',
      input: <Input suffix={t('daycares.form.months')} />
    },
    {
      label: 'fourth_dose_interval',
      name: 'fourth_dose_interval',
      input: <Input suffix={t('daycares.form.months')} />
    }
  ];

  return {
    vaccinesFields
  };
};
