import { Col, Card, Row, Table, Divider, Button, Flex } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useColumns } from '../../payments/columns';
import { DescriptionList } from '../../../../components';
import { usePaymentContent } from './paymentContent';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Component for displaying payments details.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Invoice data containing payments details
 * @returns {JSX.Element} Component JSX
 */

export const PaymentCard = ({
  openPaymentModal,
  total,
  to_pay,
  payments,
  onEditAction,
  onDeleteAction
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const listContentPayment = usePaymentContent(total, to_pay);

  const columns = useColumns(enums, onEditAction, onDeleteAction);

  return (
    <Row gutter={[24, 24]}>
      <Col span="24">
        <Card title={t('invoices.show.titles.payment')}>
          <DescriptionList
            data={listContentPayment}
            translate
            columns={24}
            layout="vertical"
          />
          <Flex align="center" wrap="nowrap" gap="small">
            <Divider orientation="left">
              {t('invoices.show.titles.payments')}
            </Divider>
            {to_pay !== '0.00' ? (
              <Button type="primary" onClick={openPaymentModal}>
                <PlusOutlined />
                {t('buttons.add')}
              </Button>
            ) : (
              []
            )}
          </Flex>
          <Table
            dataSource={payments || []}
            columns={columns}
            pagination={false}
            scroll={{ x: 1000 }}
          />
        </Card>
      </Col>
    </Row>
  );
};

PaymentCard.propTypes = {
  openPaymentModal: PropTypes.func.isRequired,
  total: PropTypes.number,
  to_pay: PropTypes.number,
  payments: PropTypes.arrayOf(PropTypes.shape({})),
  onEditAction: PropTypes.func,
  onDeleteAction: PropTypes.func
};

PaymentCard.defaultProps = {
  payments: [],
  total: undefined,
  to_pay: undefined,
  onEditAction: undefined,
  onDeleteAction: undefined
};
