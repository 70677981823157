import dayjs from 'dayjs';

/**
 * Updates the `start_time` and `end_time` of the given reference day (`refDay`)
 * based on overlapping times in a provided array of provisional plannings.
 *
 * The function compares the `start_time` and `end_time` of each planning object
 * against the `refDay`, adjusting the times accordingly if an overlap is detected.
 *
 * - If a planning's `start_time` is before `refDay.start_time` and its `end_time` is
 *   after `refDay.start_time`, the `refDay.start_time` will be updated to that planning's `end_time`.
 * - If a planning's `start_time` is before `refDay.end_time` but after the updated `start_time`,
 *   the `refDay.end_time` will be updated to that planning's `start_time`.
 *
 * @param {Object} refDay - The reference day object that contains `start_time`, `end_time`, and `_id`.
 * @param {string} refDay._id - Unique identifier for the reference day.
 * @param {string|Date} refDay.start_time - The original start time of the reference day (ISO format).
 * @param {string|Date} refDay.end_time - The original end time of the reference day (ISO format).
 *
 * @param {Array<Object>} previsionalPlanning - An array of planning objects.
 * @param {string} previsionalPlanning[].type - The type of planning (e.g., 'events').
 * @param {string} previsionalPlanning[].start_time - The start time of the planning (ISO format).
 * @param {string} previsionalPlanning[].end_time - The end time of the planning (ISO format).
 * @param {string} previsionalPlanning[].id - Unique identifier of the planning.
 *
 * @returns {Object} The updated `refDay` object with adjusted `start_time` and `end_time` if applicable.
 * If no overlapping planning events are found, the original `refDay` object is returned.
 *
 * @example
 * const refDay = {
 *   _id: '1',
 *   start_time: '2024-09-09T08:00:00.000Z',
 *   end_time: '2024-09-09T16:00:00.000Z'
 * };
 * const plannings = [
 *   { _id: '2', type: 'events', start_time: '2024-09-09T07:00:00.000Z', end_time: '2024-09-09T12:00:00.000Z' },
 *   { _id: '3', type: 'events', start_time: '2024-09-09T15:00:00.000Z', end_time: '2024-09-09T18:00:00.000Z' }
 * ];
 *
 * const updatedDay = updateRefDay(refDay, plannings);
 * console.log(updatedDay);
 * // Output:
 * // {
 * //   _id: '1',
 * //   start_time: '2024-09-09T12:00:00.000Z',
 * //   end_time: '2024-09-09T15:00:00.000Z'
 * // }
 */
const updateRefDay = (refDay, previsionalPlanning) => {
  const filteredPrevisionalPlanning = previsionalPlanning.filter(
    (planning) => planning._id !== refDay._id && planning.type === 'events'
  );

  if (filteredPrevisionalPlanning.length === 0) {
    return refDay;
  }

  let updatedStartTime = dayjs(refDay.start_time);
  let updatedEndTime = dayjs(refDay.end_time);

  filteredPrevisionalPlanning.forEach((planning) => {
    const planningStartTime = dayjs(planning.start_time);
    const planningEndTime = dayjs(planning.end_time);

    if (
      (planningStartTime.isSame(updatedStartTime) ||
        planningStartTime.isBefore(updatedStartTime)) &&
      planningEndTime.isAfter(updatedStartTime)
    ) {
      updatedStartTime = planningEndTime;
    }

    if (
      (planningStartTime.isBefore(updatedEndTime) ||
        planningStartTime.isSame(updatedEndTime)) &&
      planningStartTime.isAfter(updatedStartTime)
    ) {
      updatedEndTime = planningStartTime;
    }
  });

  return {
    ...refDay,
    start_time: updatedStartTime.toISOString(),
    end_time: updatedEndTime.toISOString()
  };
};

/**
 * Creates time limits based on the event type and reference day data.
 *
 * @function
 *
 * @param {string} type - The type of event (e.g., 'absences').
 * @param {Array} reference_day - An array containing reference day data.
 * @param {Array} previsionalPlanning - An array containing the previsional planning.
 * @param {number} index - The index of the reference day in the array.
 * @returns {object} An object containing time limits for start and end hours and minutes.
 */
export const createTimesLimits = (
  type,
  reference_day,
  previsionalPlanning,
  index = 0
) => {
  let hourStart = 6;
  let hourEnd = 21;
  let minuteStart = 0;
  let minuteEnd = 60;

  const excludedTypes = [
    'absences',
    'regular_absences',
    'irregular_absences',
    'catch_up_days',
    'holidays'
  ];

  if (
    !reference_day ||
    !excludedTypes.includes(type) ||
    reference_day.length === 0 ||
    !reference_day[index]
  ) {
    return {
      hourStart: Number(hourStart),
      hourEnd: Number(hourEnd),
      minuteStart: Number(minuteStart),
      minuteEnd: Number(minuteEnd)
    };
  }

  if (reference_day[index] && Object.keys(reference_day[index]).length >= 2) {
    const refDayUpdated = updateRefDay(
      reference_day[index],
      previsionalPlanning
    );
    hourStart = dayjs.utc(refDayUpdated.start_time).hour();
    hourEnd = dayjs.utc(refDayUpdated.end_time).hour();
    minuteStart = dayjs.utc(refDayUpdated.start_time).minute();
    minuteEnd = dayjs.utc(refDayUpdated.end_time).minute();
  }

  return {
    hourStart: Number(hourStart),
    hourEnd: Number(hourEnd),
    minuteStart: Number(minuteStart),
    minuteEnd: Number(minuteEnd)
  };
};
