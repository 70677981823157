import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Select, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * ExtraButtons component
 * @component ExtraButtons
 * @param {function} onsearchRoles - function to filter roles
 * @param {function} onsearchDaycares - function to filter daycares
 * @param {function} setSetselectedDaycares - function to set selected daycares
 * @param {function} setSelectedRoles - function to set selected roles
 * @param {array} selectedDaycares - array of selected daycares
 * @param {array} selectedRoles - array of selected roles
 * @param {array} daycares - array of daycares
 * @param {object} enums - object with enums
 * @param {boolean} isLoading - boolean to indicate if data is loading
 * @returns {object} JSX - ExtraButtons component elements
 */
export const ExtraButtons = ({
  onsearchRoles,
  onsearchDaycares,
  setSetselectedDaycares,
  setSelectedRoles,
  selectedDaycares,
  selectedRoles,
  daycares,
  enums,
  isLoading
}) => {
  const { t } = useTranslation();

  const getRole = (role) => {
    const roleArray = role.split(':');
    return roleArray[1];
  };

  return (
    <Row>
      <Col>
        <Select
          style={{ width: '250px', marginLeft: 20 }}
          showSearch
          mode="multiple"
          allowClear
          placeholder={t('users.list.extra_buttons.roles')}
          loading={isLoading}
          filterOption={(input, option) => onsearchRoles(input, option)}
          onSelect={(value) => {
            setSelectedRoles([...selectedRoles, value]);
          }}
          onDeselect={(value) =>
            setSelectedRoles((prevRoles) =>
              prevRoles.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setSelectedRoles([]);
          }}
        >
          {enums?.roles?.map((r) => (
            <Option
              key={r}
              value={r}
              data-label={t(`users.tags.${getRole(r)}`)}
            >
              <Tag color={userRoles[getRole(r)]}>
                {t(`users.tags.${getRole(r)}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Select
          style={{ width: '250px', marginLeft: 20 }}
          showSearch
          mode="multiple"
          allowClear
          placeholder={t('users.list.extra_buttons.daycares')}
          loading={isLoading}
          filterOption={(input, option) => onsearchDaycares(input, option)}
          onSelect={(value) => {
            setSetselectedDaycares([...selectedDaycares, value]);
          }}
          onDeselect={(value) =>
            setSetselectedDaycares((prevDaycares) =>
              prevDaycares.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setSetselectedDaycares([]);
          }}
        >
          {daycares?.map((d) => (
            <Option key={d._id} value={d._id}>
              {d.name}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

ExtraButtons.propTypes = {
  onsearchRoles: PropTypes.func.isRequired,
  onsearchDaycares: PropTypes.func.isRequired,
  setSetselectedDaycares: PropTypes.func.isRequired,
  setSelectedRoles: PropTypes.func.isRequired,
  selectedDaycares: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  daycares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enums: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
