import { useTranslation } from 'react-i18next';
import { Divider, Flex, Table } from 'antd';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import {
  determineAnalysisColor,
  formatNumberWithSpaces
} from '../../../../utils/formatter';
import { useTables } from './tables';
import { useColumns } from './columns';

/**
 * Renders the CAF Estimation section within the analysis module, displaying the simulation over a limited forecast budget
 * and the annual right amount. It utilizes the 'useAnalysisContext' to access the hypothesesBody and dynamically displays
 * the limit value. The component also uses 'useTables' to generate an array of table components, each associated with a specific
 * part of the CAF Estimation data.
 *
 * @component
 *
 * @returns {JSX.Element} The CAF Estimation section, which includes a header displaying the simulation limit and the annual right amount,
 * followed by a series of tables generated based on the data provided to 'useTables'. Each table is preceded by a divider with a title
 * indicating the section of the data it represents.
 */
export const CAFEstimation = () => {
  const columns = useColumns();
  const { t } = useTranslation();
  const { CAFEstimation: CAFEstimationData } = useAnalysisContext();
  const tablesArray = useTables(t, CAFEstimationData?.datas || []);

  return (
    <>
      <Flex justify="space-between" align="center">
        {t('analysis.simulation_over_limited_forecast_budget', {
          limit: `${CAFEstimationData?.ceiling_price || '0.00'}€`
        })}
        <Flex align="center" gap="small">
          {t('analysis.annual_right_amount')}
          <span style={{ color: determineAnalysisColor(0) }}>{`${
            CAFEstimationData?.total_rights_amount
              ? formatNumberWithSpaces(CAFEstimationData?.total_rights_amount)
              : 0
          }€`}</span>
        </Flex>
      </Flex>
      {tablesArray.map(({ key, dataSource, dividerTitle }) => (
        <Flex key={key} vertical style={{ marginBlockEnd: 16 }}>
          <Divider orientation="left">{dividerTitle}</Divider>
          <Table
            style={{ width: '100%' }}
            columns={columns}
            scroll={{ x: 1000 }}
            dataSource={dataSource}
            pagination={false}
          />
        </Flex>
      ))}
    </>
  );
};
