import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Display a modal to create or update a closure time.
 *
 * @component
 * @param {string} purpose The purpose of the modal.
 * @param {Object} record The record to update.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The state to refresh the table.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateActivityModal = ({
  purpose,
  record,
  refresh,
  setRefresh,
  resourceName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const fields = useFields();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);

      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/daycares/${daycare}/activities/${resourceName}/${record._id}`,
          body
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/daycares/${daycare}/activities/${resourceName}`,
          body
        });
      }
      form.resetFields();
      closeModal();
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={
        purpose === 'create'
          ? t('activities.form.add_activity')
          : t('activities.form.edit_activity')
      }
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        isCustomSubmitting={isSubmitting}
        purpose={purpose}
        customSubmit={customSubmit}
        resource="daycares"
        customFormInstance={form}
        config={config}
        isModalOpen
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

CreateUpdateActivityModal.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }),
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  resourceName: PropTypes.string
};

CreateUpdateActivityModal.defaultProps = {
  purpose: '',
  record: {
    _id: ''
  },
  setRefresh: () => {},
  refresh: false,
  resourceName: undefined
};
