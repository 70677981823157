import { Button, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import InvoiceIcon from '../../../assets/images/icons/notifications/notif-icon-invoicing.png';
import PlanningIcon from '../../../assets/images/icons/notifications/notif-icon-ponctual-planning.png';
import PreRegistrationIcon from '../../../assets/images/icons/notifications/notif-icon-preregistration.png';
import AbsenceIcon from '../../../assets/images/icons/notifications/notifi-icon-absence.png';
import BirthdayIcon from '../../../assets/images/icons/notifications/notifi-icon-birthday.png';
import VaccinationIcon from '../../../assets/images/icons/notifications/notifi-icon-vaccination.png';
import DocumentsIcon from '../../../assets/images/icons/documentsIcon.svg';
import { routes } from '../../../utils/constants/adminRoutes';
import { createdSince } from './createdSince';
import { notificationTypeMap } from './notificationsArray';

const icons = {
  InvoiceIcon,
  PlanningIcon,
  BirthdayIcon,
  VaccinationIcon,
  PreRegistrationIcon,
  AbsenceIcon,
  DocumentsIcon
};

/**
 * Handle the link for each notification menu item.
 *
 * @param {Object} notification - The notification data.
 * @param {Function} deleteNotification - The function to delete a notification.
 * @param {Function} onReadNotification - Function to patch notification to read.
 * @param {Object} t - The translation function for i18n.
 * @returns {JSX.Element} The JSX element for the notification link.
 */
export const handleNotifMenuItemLink = (
  notification,
  deleteNotification,
  onReadNotification,
  t
) => {
  const { item } = notification;
  const notificationType = `${item?.ref}${
    item?.action ? `-${item?.action}` : ''
  }`;

  const { icon, link, messageKey } =
    notificationTypeMap(item)[notificationType];

  return (
    item?.type &&
    item?.type?._id && (
      <Link
        to={{
          pathname: `${routes[link]}/${
            link === 'INVOICING' ? 'invoices/' : ''
          }show/${item?.type?._id}`
        }}
        onClick={() => onReadNotification(notification._id)}
      >
        <Flex justify="space-between" align="center" gap="middle">
          <img src={icons[icon]} alt="micro-icon" />

          <Flex vertical>
            <span className="notification-text">{`${t(messageKey, {
              name: `${item?.type?.child?.first_name || ''} ${
                item?.type?.child?.last_name || ''
              }`
            })}`}</span>
            <span className="notification-date">
              {createdSince(notification?.created_at, t)}
            </span>
          </Flex>
          <Button danger type="link">
            <CloseOutlined
              onClick={() => deleteNotification(notification?._id)}
            />
          </Button>
        </Flex>
      </Link>
    )
  );
};
