import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { PreRegistrationRouter } from '../routes/pre-registrations/PreRegistrationRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { EmployeeRouter } from '../routes/employee/EmployeeRouter';
import { InvoicingRouter } from '../routes/invoicing/InvoicingRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { ChildrenRouter } from '../routes/children/childrenRouter';
import { ParentsRouter } from '../routes/parents/ParentsRouter';
import { Configuration } from '../routes/Configuration/Configuration';
import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';
import { MessagesAndNotesRouter } from '../routes/messages-and-notes/MessagesAndNotesRouter';
import { PlanningRouter } from '../routes/planning/PlanningRouter';
import { ReferenceWeekProvider } from '../contexts/ReferenceWeekContext';
import { CreateUpdatePreRegistration } from '../routes/pre-registrations/CreateUpdatePreRegistration';
import { TimeRecorder } from '../routes/planning/TimeRecorder/TimeRecorder';
import { RequireAuth } from './RequireAuth';
import { MealsCalendar } from '../routes/meals/MealsCalendar';
import { CommunicationRouter } from './CommunicationRouter';
import { Analysis } from '../routes/analysis/Analysis';
import { FollowUpRouter } from '../routes/follow-up/FollowUpRouter';
import { AnalysisContextProvider } from '../contexts/AnalysisContext';
import { EmployeeContextProvider } from '../contexts/EmployeeContext';
import { CommunicationContextProvider } from '../contexts/CommunicationContext';
import { ConfigurationContextProvider } from '../routes/Configuration/ConfigurationContext';

/**
 * `CorporateRouter` is a React component that defines the routing structure for the corporate interface.
 * It sets up a series of routes using `react-router-dom`, facilitating navigation to various parts of the application
 * such as Home, Settings, Employee Management, etc. This router includes routes for login, email confirmation,
 * and other specific functionalities like time recording and pre-registration.
 *
 * The component is wrapped with `BrowserRouter` and applies the `AdminTheme` for consistent styling across the application.
 * The `RequireAuth` component is used to secure routes that require user authentication. Additionally, the component
 * manages the application's layout and styling by applying a specific class to the body element.
 *
 * @component
 * @example
 * return (
 *   <CorporateRouter />
 * )
 * @returns {React.ReactElement} The CorporateRouter component containing all routes for the corporate interface.
 */
export const CorporateRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PRE_REGISTRATION}/create/*`}
          element={
            <ReferenceWeekProvider>
              <CreateUpdatePreRegistration purpose="create" />
            </ReferenceWeekProvider>
          }
        />
        <Route
          path={`${outOfNavRoutes.TIME_RECORDER}/*`}
          element={<TimeRecorder />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route
            path={`${routes.ANALYSIS}/*`}
            element={
              <AnalysisContextProvider>
                <Analysis />
              </AnalysisContextProvider>
            }
          />
          <Route path={`${routes.PLANNING}/*`} element={<PlanningRouter />} />
          <Route
            path={`${routes.PRE_REGISTRATION}/*`}
            element={<PreRegistrationRouter />}
          />
          <Route
            path={`${routes.EMPLOYEES}/*`}
            element={
              <ReferenceWeekProvider>
                <EmployeeContextProvider>
                  <EmployeeRouter />
                </EmployeeContextProvider>
              </ReferenceWeekProvider>
            }
          />
          <Route path={`${routes.INVOICING}/*`} element={<InvoicingRouter />} />
          <Route path={`${routes.FOLLOW_UP}/*`} element={<FollowUpRouter />} />
          <Route path={`${routes.CHILDREN}/*`} element={<ChildrenRouter />} />
          <Route path={`${routes.PARENTS}/*`} element={<ParentsRouter />} />
          <Route
            path={`${routes.MESSAGES_AND_NOTES}/*`}
            element={<MessagesAndNotesRouter />}
          />
          <Route path={`${routes.MEALS}/*`} element={<MealsCalendar />} />
          <Route
            path={`${routes.COMMUNICATION}/*`}
            element={
              <CommunicationContextProvider>
                <CommunicationRouter />
              </CommunicationContextProvider>
            }
          />
          <Route
            path={`${outOfNavRoutes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          />
          <Route
            path={`${routes.CONFIGURATIONS}`}
            element={
              <ConfigurationContextProvider>
                <Configuration />
              </ConfigurationContextProvider>
            }
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
