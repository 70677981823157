import { EventsRender } from './Events/EventsRender';
import { InfosRender } from './Infos/InfosRender';
import { NewsRender } from './News/NewsRender';

/**
 * Hook for rendering different types of content based on the type of resource.
 *
 * @hook
 *
 * @param {Array} resources - The array of resources to render.
 * @param {Function} handleModal - The function to handle modal opening.
 * @param {Object} enums - An object containing enums data.
 * @param {boolean} refresh - A boolean flag indicating whether a refresh is needed.
 * @param {Function} setRefresh - The function to set the refresh flag.
 * @returns {Object} An object containing JSX elements for rendering different types of content.
 */
export const useContainerRender = (
  resources,
  handleModal,
  enums,
  refresh,
  setRefresh
) => ({
  events: <EventsRender resources={resources} handleModal={handleModal} />,
  infos: (
    <InfosRender
      resources={resources}
      enums={enums}
      handleModal={handleModal}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  ),
  news: (
    <NewsRender
      resources={resources}
      enums={enums}
      handleModal={handleModal}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  )
});
