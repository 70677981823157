/**
 * Custom hook for generating form fields configuration for label-based items.
 *
 * @returns {Object} An object containing form field definitions for label-based items.
 */
export const useLabelFields = () => {
  const fields = [
    {
      label: 'label',
      name: 'label',
      rules: [{ required: true }]
    }
  ];

  return { fields };
};
