import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating content data for a health list.
 *
 * @hook
 *
 * @param {Object} data - The data object containing health-related information.
 * @param {Array} data.allergies - An array of allergy objects.
 * @param {Array} data.childhood_illnesses - An array of childhood illness objects.
 * @param {boolean} data.disability - Indicates if the child has a disability.
 * @param {string} data.personalized_care_plan - Personalized care plan information.
 * @param {string} data.comment - Additional comments.
 * @returns {Object} An object containing content lists for allergies, childhood illnesses,
 * personalized care, and notes.
 */
export const useHealthListContent = (data = {}) => {
  const {
    allergies,
    childhood_illnesses,
    disability,
    personalized_care_plan,
    comment
  } = data;
  const { t } = useTranslation();

  const allergiesList = [
    {
      label: '',
      content: (allergies || []).map((allergy) => (
        <span key={allergy._id}>{allergy.label}</span>
      )),
      span: 1
    }
  ];

  const childhood_illnesses_list = [
    {
      label: '',
      content: (childhood_illnesses || []).map((illness) => (
        <span key={illness._id}>{illness.label}</span>
      )),
      span: 1
    }
  ];

  const personalized_care = [
    {
      label: t('children.show.health.personalized_care.is_disabled'),
      content: disability ? t(`yes`) : t('no'),
      span: 8
    },
    {
      label: t('children.show.health.personalized_care.personalized_care_plan'),
      content: personalized_care_plan,
      span: 8
    }
  ];

  const note = [
    {
      label: '',
      content: comment,
      span: 8
    }
  ];

  return {
    allergiesList,
    childhood_illnesses_list,
    personalized_care,
    note
  };
};
