import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Display a modal to create or update a closure time.
 * @component
 * @param {string} purpose The purpose of the modal.
 * @param {boolean} isModalOpen The state of the modal.
 * @param {function} setIsModalOpen The function to set the state of the modal.
 * @param {Object} record The record to update.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The state to refresh the table.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateBankHoliday = ({
  purpose,
  record,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const { fields, isFieldsLoading } = useFields(purpose);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record,
        date: dayjs(record.date)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);

      const updatedBody = {
        ...body,
        date: dayjs(body.date).add(2, 'hours')
      };

      await dispatchAPI('PATCH', {
        url: `/daycares/${daycare}/holidays${
          purpose === 'edit' ? `/${record._id}` : ''
        }`,
        body: updatedBody
      });
      form.resetFields();

      closeModal();
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={
        purpose === 'create'
          ? t('daycares.form.add_holidays')
          : t('daycares.form.edit_holidays')
      }
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        isCustomSubmitting={isSubmitting}
        purpose={purpose}
        customSubmit={customSubmit}
        withGetResource={false}
        resource="daycares"
        customFormInstance={form}
        config={config}
        isModalOpen
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={record}
      />
    </Modal>
  );
};

CreateUpdateBankHoliday.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

CreateUpdateBankHoliday.defaultProps = {
  purpose: '',
  record: {},
  setRefresh: () => {},
  refresh: false
};
