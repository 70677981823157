import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';

/**
 * @description
 * ContractCard is a component that displays the contract informations
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the child
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} ContractCard component
 */
export const ContractCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data);

  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      title={t('pre-registrations.show.cards.contract')}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
        <DescriptionList data={listContent} translate layout="vertical" />
      </Skeleton>
    </Card>
  );
};

ContractCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool
};

ContractCard.defaultProps = {
  isLoading: false
};
