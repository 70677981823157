import { useState, useEffect } from 'react';
import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerNature } from '../../../utils/constants/tagColors';

const { Option } = Select;

export const useFields = (customerAccountId, enums) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  useEffect(() => {
    (() => {
      if (enums && customerAccountId) setIsFieldsLoading(false);
    })();
  }, []);

  const fields = [
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['customer_nature'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear showSearch>
          {(enums?.customer_natures || []).map((nature) => (
            <Option key={nature} value={nature}>
              <Tag color={customerNature[nature]}>
                {t(`customer-account.form.${nature}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
