import { useDepartmentArray } from './departmentArray';

/**
 * Handles the department selection by finding a department object in the array
 * returned by `useDepartmentArray` that includes the input postal code and
 * then sets the department name in the form fields.
 *
 * @param {Event} e - The event object.
 * @param {Object} form - The form object.
 * @param {string} name - The name of the field to be updated in the form.
 *
 * @returns {void}
 *
 * @example
 *
 * handleDepartment(event, form, 'departmentField');
 *
 */
export const handleDepartment = (e, form, name) => {
  const postal_code = e.target.value;
  const array = useDepartmentArray();

  const departmentObject = array.find((department) =>
    department.postalCode.includes(postal_code)
  );

  if (departmentObject?.department) {
    form.setFields([
      {
        name,
        value: departmentObject.department
      }
    ]);
  }
};
