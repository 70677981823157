import { useTranslation } from 'react-i18next';

/**
 * @hook useListContent
 * @description
 * useListContent is a hook that returns the content of the contract informations card.
 * It is used in the ContractInformationsCard component.
 *
 * @param {Object} data - The data of the contract informations
 * @returns {Array} The content of the contract informations card
 */
export const useListContent = (data = {}) => {
  const translationKey = 'pre-registrations.show.contract_informations';
  const { t } = useTranslation();
  const { contract = {} } = data;
  const { number = '-', billing_type = '-', planning_type = '' } = contract;

  return [
    {
      label: `${translationKey}.number`,
      content: number,
      span: 3
    },
    {
      label: `${translationKey}.billing_type`,
      content: t(`contract.enums.contract_types.${billing_type}`),
      span: 1
    },
    {
      label: 'pre-registrations.show.contract_informations.planning_type',
      content: t(`contract.enums.contract_types.${planning_type}`),
      span: 1
    }
  ];
};
