import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  FileOutlined,
  CheckOutlined,
  WarningOutlined,
  RedoOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { InvoiceCard } from './ShowCards/InvoiceCard';
import { InformationsCards } from './ShowCards/InformationsCards';
import { PaymentCard } from './ShowCards/PaymentCard';
import PaymentModal from '../payments/PaymentModal';
import InvoicingContext from '../InvoicingContext';
import SendInvoiceModal from './SendInvoiceModal';
import { routes } from '../../../utils/constants/adminRoutes';

/**
 * Component for displaying detailed information about an invoice.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowInvoice component
 */
export const ShowInvoice = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [payments, setPayments] = useState([]);
  const [isCreditModal, setIsCreditModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const navigate = useNavigate();
  const [paymentId, setPaymentId] = useState('');
  const [isModalSendInvoiceOpen, setIsModalSendInvoiceOpen] = useState(false);
  const [validateInvoiceId, setValidateInvoiceId] = useState('');
  const [customerAccountId, setCustomerAccountId] = useState('');

  const { onPrintAction, isSpinLoading } = InvoicingContext();

  const onValidateAction = (data) => {
    setIsModalSendInvoiceOpen(true);
    setValidateInvoiceId(data?._id);
    setCustomerAccountId(data?.customer_account?._id);
  };

  const closeModalValidateInvoice = () => {
    setIsModalSendInvoiceOpen(false);
    setValidateInvoiceId('');
    setCustomerAccountId('');
  };

  const generateInvoiceAction = async (data) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/invoices/${data._id}`,
        body: data
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const getPayments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payments?invoice=${id}&populate=invoice,customer_account`
      });
      setPayments(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const getInvoice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices/${id}?populate=contract,customer_account,daycare,daycare.invoicing_configuration,child`
      });
      setInvoice(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const openPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const openCreditModal = () => {
    setIsCreditModal(true);
    openPaymentModal();
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setIsCreditModal(false);
  };

  const onEditAction = (idPayment) => {
    setPurpose('edit');
    setPaymentId(idPayment);
    setIsPaymentModalOpen(true);
  };

  const onDeleteAction = async (idPayment) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payments/${idPayment}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getInvoice();
      await getPayments();
      setIsLoading(false);
    })();
  }, [getInvoice, refresh]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <>
      {isPaymentModalOpen && (
        <PaymentModal
          isModalOpen={isPaymentModalOpen}
          closeModal={closePaymentModal}
          daycare={daycare}
          setRefresh={setRefresh}
          purpose={purpose}
          paymentId={paymentId}
          invoiceId={invoice._id}
          customerAccountId={invoice.customer_account._id}
          isCreditModal={isCreditModal}
        />
      )}
      {isModalSendInvoiceOpen && (
        <SendInvoiceModal
          isModalSendInvoiceOpen={isModalSendInvoiceOpen}
          closeModalValidateInvoice={closeModalValidateInvoice}
          setRefresh={setRefresh}
          validateInvoiceId={validateInvoiceId}
          customerAccountId={customerAccountId}
          purpose="edit"
        />
      )}
      <PageHeaderCustom
        title={`${t('invoices.show.title')} n° ${invoice?.number}`}
        extra={
          <Flex gap="small">
            {invoice?.status === 'PAID' && (
              <Button type="primary" onClick={openCreditModal}>
                <FileOutlined />
                {`${t(`invoices.show.buttons.credit`)} `}
              </Button>
            )}
            {invoice?.status === 'CREATE' && (
              <>
                {!invoice.invalid_pickup_times_presence ? (
                  <Tooltip
                    title={
                      invoice?.no_pick_up_times
                        ? t('invoices.show.buttons.no_pick_up_times')
                        : null
                    }
                  >
                    <Button
                      type="primary"
                      onClick={() => onValidateAction(invoice)}
                      disabled={invoice.no_pick_up_times}
                    >
                      <CheckOutlined />
                      {t('buttons.validate_invoice')}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate(`${routes.CHILDREN}/show/${invoice.child._id}`)
                    }
                  >
                    <WarningOutlined />
                    {t('buttons.correct_invalid_slots')}
                  </Button>
                )}
                {(invoice?.type === 'CLASSIC' ||
                  invoice?.type === 'BALANCE') && (
                  <Popconfirm
                    title={t('datatable.column.action.generate.title')}
                    okText={t('datatable.column.action.generate.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.generate.cancel')}
                    onConfirm={() => generateInvoiceAction(invoice)}
                    icon={<WarningOutlined />}
                  >
                    <Button type="primary">
                      <RedoOutlined style={{ fontSize: 18 }} type="delete" />{' '}
                      {t('buttons.regenerate_invoice')}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
            <Button
              loading={isSpinLoading}
              disabled={isSpinLoading}
              type="primary"
              onClick={() => onPrintAction(id)}
            >
              <DownloadOutlined />
              {t('invoices.show.buttons.download')}
            </Button>
          </Flex>
        }
      />
      <ContentCustom>
        {!isLoading && (
          <Flex vertical gap="middle">
            <InformationsCards data={invoice} />
            <InvoiceCard
              data={invoice?.invoiceDetails}
              total={invoice?.total}
              child={invoice?.child}
              type={invoice?.type}
              invoice={invoice}
            />
            {invoice?.status !== 'CREATE' && (
              <PaymentCard
                openPaymentModal={openPaymentModal}
                total={invoice?.total}
                to_pay={invoice?.to_pay}
                payments={payments}
                onEditAction={onEditAction}
                onDeleteAction={onDeleteAction}
              />
            )}
          </Flex>
        )}
      </ContentCustom>
    </>
  );
};
