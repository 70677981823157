import { Indicators } from '../TabsContent/Indicators/Indicators';
import { CashBalanceImports } from '../TabsContent/CashBalanceImports/CashBalanceImports';
import { HypothesesContainer } from '../TabsContent/Hypotheses/Hypotheses';
import { CAFEstimation } from '../TabsContent/CAFEstimation/CAFEstimation';
import { CAFStatementContainer } from '../TabsContent/CAFStatement/CAFStatement';

/**
 * Custom hook to create tab items for the communication section.
 *
 * This hook returns an array of tab items, each with a unique key, a label that includes an icon and a text label,
 * and children which are the content of the tab.
 *
 * @function useTabItems
 * @param {Function} t - Translation function from `react-i18next`.
 * @returns {Array<Object>} An array of tab item objects.
 */
export const useTabItems = (t) => [
  {
    key: '1',
    label: t('analysis.tabs.indicators'),
    children: <Indicators />
  },
  {
    key: '2',
    label: t('analysis.tabs.cash_balance_imports'),

    children: <CashBalanceImports />
  },
  {
    key: '3',
    label: t('analysis.tabs.hypotheses'),

    children: <HypothesesContainer />
  },
  {
    key: '4',
    label: t('analysis.tabs.CAF_estimation'),
    children: <CAFEstimation />
  },
  {
    key: '5',
    label: t('analysis.tabs.CAF_statement'),
    children: <CAFStatementContainer />
  }
];
