import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useTabContent } from './useTabContent';

/**
 * Global settings page - only for super admin
 * @component
 * @returns {JSX.Element} The component
 */
export const GlobalSettings = () => {
  const { t } = useTranslation();
  const { tabContent } = useTabContent();

  return (
    <>
      <PageHeaderCustom
        title={t('global_settings.title')}
        withoutBreadCrums
        withoutNavigateBack
      />
      <ContentCustom>
        <Tabs type="card" defaultActiveKey="dynamic_lists" items={tabContent} />
      </ContentCustom>
    </>
  );
};
