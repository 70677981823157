import { useCallback, useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { ReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PreRegistrationCard } from './PreRegistrationsCard';
import { ContentCustom } from '../../components';
import { PreRegistrationHeader } from './PreRegistrationHeader';

/**
 * ListPreRegistrations component renders a list of pre-registration cards.
 * It also includes functionality to fetch pre-registrations,
 * manage the search query, and toggling archive mode.
 *
 * @returns {React.ElementType} Rendered list of pre-registrations.
 */
export const ListPreRegistrations = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { daycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { setReferenceWeeks } = useContext(ReferenceWeekContext);
  const [preregistrations, setPreregistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [statusFilter, setStatusFilter] = useState([
    'PENDING',
    'WAITING',
    'COMPLETED'
  ]);

  const getPreRegistrations = useCallback(async () => {
    setIsLoading(true);
    const searchURL = searchValue ? `/search/${searchValue}` : null;

    try {
      const daycares = statusFilter.map((status) => ({ _id: daycare, status }));
      const url = `pre-registrations${
        searchURL || ''
      }?daycares=${JSON.stringify(daycares)}&populate=contract`;

      const { data } = await dispatchAPI('GET', {
        url
      });
      setPreregistrations(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, [statusFilter, daycare, searchValue]);

  useEffect(() => {
    (async () => {
      await getPreRegistrations();
    })();
  }, [getPreRegistrations]);

  useEffect(() => {
    setReferenceWeeks([{}]);
  }, [setReferenceWeeks]);

  const searchResource = (value) => {
    navigate({
      pathname,
      search: value ? `?k=${value}` : ''
    });
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  return (
    <>
      <PreRegistrationHeader
        searchValue={searchValue}
        searchResource={searchResource}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {preregistrations.map((item) => (
            <PreRegistrationCard preregistration={item} isLoading={isLoading} />
          ))}
        </Flex>
      </ContentCustom>
    </>
  );
};
