import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Flex, Input, Select, Tag } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { visibilitiesColors } from '../../utils/constants/tagColors';
import { handleDateFilter } from './utils/handleDateFilter';
import { generateUrlVisibilityFilter } from './utils/urlVisibilityFilter';
import { useHandleResize } from '../../utils/handleResize';

const { Search } = Input;

/**
 * A header component with search and filter functionality for resources.
 *
 * This component is designed to provide a unified header for resource pages in the application.
 * It includes a search bar, filtering options, and a create button. The search bar allows users
 * to search for resources by keywords. The filtering options vary depending on the resource type
 * and can include visibility filters and a date picker for events. The create button opens a modal
 * for adding a new resource.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {string} props.resourceName - The name of the resource, used to construct API endpoints.
 * @param {Function} props.setResources - Function to update the state of resources in the parent component.
 * @param {Function} props.handleModal - Function to handle the opening of the create or edit modal.
 * @param {boolean} props.refresh - State to trigger a re-fetch of resources.
 * @param {Object} props.enums - Enumerations for the resource, like visibility options.
 * @returns {JSX.Element} A header component with search and filter functionalities.
 */
export const Header = ({
  resourceName,
  setResources,
  handleModal,
  refresh,
  enums
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [visibilityFilter, setVisibilityFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const { width } = useHandleResize();

  const searchResource = (value) => {
    const search = value ? `?k=${value}` : '';
    navigate({
      pathname,
      search
    });
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) {
      setSearchValue(keyword);
    } else {
      setSearchValue(null);
    }
  }, [keyword]);

  const getResources = async () => {
    try {
      const searchURL = `/search/${encodeURIComponent(searchValue)}`;
      let populate = '';

      if (resourceName === 'news') {
        populate += 'populate=thumbnail,images';
      }
      if (resourceName === 'events') {
        populate += 'populate=thumbnail,groups';
      }

      const urlVisibilityFilter = generateUrlVisibilityFilter(
        user,
        visibilityFilter
      );

      const url = `/${resourceName}${(searchValue && searchURL) || ''}?${
        visibilityFilter ? `visibility=${urlVisibilityFilter}&` : ''
      }${dateFilter ? `${dateFilter}&` : ''}daycare=${daycare}&${populate}`;

      const { data } = await dispatchAPI('GET', {
        url
      });
      setResources(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getResources();
    })();
  }, [searchValue, refresh, visibilityFilter, dateFilter]);

  const visibilityOptions = (enums?.visibility || []).map((enumItem) => ({
    label: (
      <Tag color={visibilitiesColors[enumItem]}>
        {t(`communication.documents.list.type.${enumItem}`)}
      </Tag>
    ),
    value: enumItem
  }));

  const userBoolean = !['guests:PARENT', 'users:TABLET'].includes(user?.role);

  return (
    <Flex justify="space-between" gap="small" wrap="wrap">
      <Flex align="center" gap="small" wrap={width < 769 ? 'wrap' : 'nowrap'}>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => searchResource(value)}
          style={{ maxWidth: 240 }}
        />
        {userBoolean && (
          <Select
            options={visibilityOptions}
            mode="multiple"
            allowClear
            onChange={(value) => setVisibilityFilter(value)}
            defaultValue={visibilityFilter}
            style={{ width: 260 }}
            placeholder={t('placeholder.visible_by')}
            className="contains-tags"
          />
        )}
        {resourceName === 'events' && (
          <DatePicker
            picker="year"
            onChange={(e) => handleDateFilter(e, setDateFilter)}
            placeholder={t('placeholder.year')}
          />
        )}
      </Flex>
      {userBoolean && (
        <Button onClick={() => handleModal(undefined, 'create')} type="primary">
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      )}
    </Flex>
  );
};

Header.propTypes = {
  resourceName: PropTypes.string.isRequired,
  setResources: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  refresh: PropTypes.bool,
  enums: PropTypes.shape({
    visibility: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  })
};

Header.defaultProps = {
  enums: null,
  refresh: false
};
