import { useTranslation } from 'react-i18next';
import { renderValue } from './utils';

/**
 * Generates column configurations for tables in the analysis section.
 * Each column is set up with a title, key, dataIndex, and a render function if necessary.
 * The render function is used to format the data displayed in each cell, such as formatting dates
 * or numbers, or providing default values when data is missing.
 *
 * @hook
 *
 * @returns {Array} An array of objects where each object represents the configuration for a table column,
 * including the column title, unique key, data index, and a render function for custom cell rendering.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('analysis.table.code_index'),
      key: 'code',
      dataIndex: 'code',
      render: (index) => index || '-'
    },
    {
      title: t('analysis.table.label'),
      key: 'label',
      dataIndex: 'label'
    },
    {
      title: t('analysis.table.calculation'),
      key: 'calculation',
      dataIndex: 'calculation',
      render: (operation) => operation || '-'
    },
    {
      title: t('analysis.table.value'),
      render: (record) => (record.code && renderValue(record)) || '0€'
    }
  ];
};
