import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/tabletRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { TimeRecorder } from '../routes/planning/TimeRecorder/TimeRecorder';
import { RequireAuth } from './RequireAuth';
import PostPwdForm from '../routes/login/PostPwdForm';
import { ChildrenFollowUp } from '../routes/children-follow-up/ChildrenFollowUp';
import { FollowUpContextProvider } from '../contexts/FollowUpContext';
import { AttendanceCheck } from '../routes/attendance-check/AttendanceCheck';
import { MessagesAndNotesRouter } from '../routes/messages-and-notes/MessagesAndNotesRouter';
import { CommunicationRouter } from './CommunicationRouter';
import { PlanningRouter } from '../routes/planning/PlanningRouter';
import { CommunicationContextProvider } from '../contexts/CommunicationContext';

/**
 * Defines the routing logic specifically for tablet-sized screens.
 *
 * This component sets up a router using React Router. It defines various routes
 * and their corresponding components for the application. It includes routes
 * for login, email confirmation, password creation, time recording, planning,
 * children follow-up, and a catch-all route for exceptions. Some routes are
 * protected and require authentication, which is managed by `RequireAuth`.
 *
 * The component also applies a user theme and sets a class on the body element
 * for styling purposes.
 *
 * @component
 * @returns {JSX.Element} The JSX rendering of the router with defined routes.
 *
 * @example
 * return (
 *   <TabletRouter />
 * )
 */
export const TabletRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route
          path={`${outOfNavRoutes.TIME_RECORDER}/*`}
          element={<TimeRecorder />}
        />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<AttendanceCheck />} />
          <Route path={`${routes.PLANNING}/*`} element={<PlanningRouter />} />
          <Route
            path={`${routes.ATTENDANCE_CHECK}`}
            element={<AttendanceCheck />}
          />
          <Route
            path={`${routes.CHILDREN_FOLLOW_UP}`}
            element={
              <FollowUpContextProvider>
                <ChildrenFollowUp />
              </FollowUpContextProvider>
            }
          />
          <Route
            path={`${routes.MESSAGES_AND_NOTES}/*`}
            element={<MessagesAndNotesRouter />}
          />
          <Route
            path={`${routes.COMMUNICATION}/*`}
            element={
              <CommunicationContextProvider>
                <CommunicationRouter />
              </CommunicationContextProvider>
            }
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
