/**
 * Custom hook for generating informations content data for displaying payment informations details in a list format.
 *
 * @hook
 * @param {object} data - Invoice data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const usePaymentContent = (total, to_pay) => [
  {
    label: 'invoices.show.payment.total',
    span: 1,
    content: `${total} €` || '0.00 €'
  },
  {
    label: 'invoices.show.payment.left_to_pay',
    span: 1,
    content: `${to_pay} €` || '0.00 €'
  }
];
