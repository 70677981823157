import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';

/**
 * @description
 * ChildCard is a component that displays the child information
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the child
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} ChildCard component
 */
export const ChildCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const childInformation = useListContent(data);

  return (
    <Card
      title={t('pre-registrations.show.cards.child')}
      headStyle={{ borderBottom: 'none' }}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
        <DescriptionList layout="vertical" data={childInformation} translate />
      </Skeleton>
    </Card>
  );
};

ChildCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool
};

ChildCard.defaultProps = {
  isLoading: false
};
