import { Card, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { CreateUpdateClosureTime } from './CreateUpdateClosureTime';

/**
 * Display a table of closure times.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ClosureTimeTable = () => {
  const { daycare, user } = useAuthContext();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateClosureTime
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  const columns = useColumns(handleModalBehaviour, refresh, setRefresh);

  return (
    <Card
      size="small"
      title={t('daycares.form.closing_periods')}
      extra={
        user?.role !== 'users:MANAGER' && (
          <Button onClick={() => handleModalBehaviour({}, 'create')}>
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        )
      }
    >
      {modal}
      <ListResource
        resourceName="daycares/closure_period"
        tradKey="daycares"
        columns={columns}
        resourceModelName="Daycare"
        extraQuery={`daycare=${daycare}`}
        withSearchBar={false}
        withCreateButton={false}
        onDoubleClickAction={null}
        withPageHeader={false}
        withUploadButton={false}
        customActionColumn
        archivedButton={false}
        forceRefresh={refresh}
        scroll={{ x: 500 }}
      />
    </Card>
  );
};
