/**
 * Determines whether the save button should be displayed based on the current step and available steps.
 *
 * @function
 *
 * @param {string} step - The current step in the process.
 * @param {string[]} steps - An array of available steps in the process.
 * @returns {boolean} - Returns true if the save button should be displayed, otherwise false.
 */
export const handleDisplaySaveButton = (step, steps) =>
  (steps.some((key) => key === 'footer') && step === 'body') ||
  step === 'footer';
