import { DatePicker, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * Custom hook for generating form fields related to authorizations and data authorization.
 *
 * @returns {Object} An object containing form field definitions for authorizations and data authorization.
 */
export const useAuthorizationsFields = () => {
  const { t } = useTranslation();

  const radioGroup = (
    <Radio.Group>
      <Radio value>{t(`yes`)}</Radio>
      <Radio value={false}>{t(`no`)}</Radio>
    </Radio.Group>
  );

  const authorizationsFields = [
    {
      name: ['authorizations', 'administer_antipyretic'],
      rules: [{ required: true }],
      input: radioGroup
    },
    {
      name: ['authorizations', 'admin_care'],
      rules: [{ required: true }],
      input: radioGroup
    },
    {
      name: ['authorizations', 'exit_permission'],
      rules: [{ required: true }],
      input: radioGroup
    },
    {
      name: ['authorizations', 'capture_media'],
      rules: [{ required: true }],
      input: radioGroup
    },
    {
      name: ['authorizations', 'apply_makeup'],
      rules: [{ required: true }],
      input: radioGroup
    },
    {
      name: ['authorizations', 'specific_permissions'],
      input: <TextArea style={{ resize: 'none' }} rows={4} />
    }
  ];

  const dataAuthorizationField = [
    {
      name: ['authorizations', 'consent_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    }
  ];

  return { authorizationsFields, dataAuthorizationField };
};
