import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useColumns } from './columns';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import { EditableCell } from '../../../global-settings/CAFScale/EditableCell';
import { CafStatement } from '../../../../models/CafStatement';
import { EditableRow } from '../../../global-settings/CAFScale/EditableRow';

/**
 * A table component for displaying CAF statement data.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.datas - The data to be displayed in the table.
 * @param {string} [props.mode='previsional'] - The mode of the table ('previsional' or 'actual').
 * @returns {JSX.Element} The CAFStatementTable component.
 */
export const CAFStatementTable = ({ datas, mode }) => {
  const { CAFStatement, refresh, setRefresh } = useAnalysisContext();
  const columns = useColumns(mode);
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleItemUpdate = async (dataIndex, recordId, newValue) => {
    const updatedDocument = { ...CAFStatement };

    const itemIndex = updatedDocument.datas.findIndex(
      (item) => item._id === recordId
    );

    if (itemIndex !== -1) {
      updatedDocument.datas[itemIndex][dataIndex] = Number(newValue);
    }

    if (itemIndex === 12) {
      const allTotalExpenses = updatedDocument.datas[13][dataIndex];
      const contributions = updatedDocument.datas[12][dataIndex];

      const updatedTotal = allTotalExpenses + contributions;

      updatedDocument.datas[13][dataIndex] = updatedTotal;
    }
    if (itemIndex === 34) {
      const allTotalExpenses = updatedDocument.datas[35][dataIndex];
      const contributions = updatedDocument.datas[34][dataIndex];

      const updatedTotal = allTotalExpenses + contributions;

      updatedDocument.datas[35][dataIndex] = updatedTotal;
    }

    await CafStatement.patch(
      updatedDocument._id,
      updatedDocument,
      refresh,
      setRefresh
    );
  };

  const columnsWithEditable = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        suffix: col.suffix,
        handleItemUpdate,
        mode,
        source: 'statement'
      })
    };
  });

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      pagination={false}
      scroll={{ x: 1000 }}
      rowKey={(record, index) => record._id || index}
      dataSource={datas}
      columns={columnsWithEditable}
    />
  );
};

CAFStatementTable.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({})),
  mode: PropTypes.string
};

CAFStatementTable.defaultProps = {
  datas: null,
  mode: 'previsional'
};
