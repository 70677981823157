import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Modal, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { tailFormItemLayout } from '../../../utils/constants/formLayout';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { MealForm } from './MealForm';

dayjs.extend(LocalizedFormat);

/**
 * A modal component for creating or updating meal plans for a specific date.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {Function} props.setRefresh - Function to trigger a refresh in the parent component.
 * @param {string} props.resourceName - The name of the resource being managed.
 * @param {string} props.date - The date for which the meal is being planned.
 * @param {Object} props.dayMeals - The meal data for the specified date (for edit mode).
 * @returns {JSX.Element} A modal with a form for meal creation or editing.
 */
export const CreateUpdateMeal = ({
  setRefresh,
  resourceName,
  date,
  dayMeals
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [mealTypes, setMealTypes] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const getIngredients = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ingredients?daycare${daycare}`
      });
      const formattedData = data.map((ingredient) => ({
        key: ingredient._id,
        title: ingredient?.icon || '',
        description: ingredient?.name,
        filters: ingredient?.keywords
      }));
      setIngredients(formattedData);
    } catch (e) {
      message(e);
    }
  };

  const getMealTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/meals/enums`
      });
      setMealTypes(data.meal_types);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setOptionsLoading(true);
      await getIngredients();
      await getMealTypes();
      setOptionsLoading(false);
    })();
  }, []);

  const customSubmit = async (body) => {
    try {
      setIsSubmitting(true);

      if (dayMeals) {
        await dispatchAPI('PATCH', {
          url: `/${resourceName}/${dayMeals._id}`,
          body
        });
      } else {
        await dispatchAPI('POST', {
          url: `/${resourceName}`,
          body: {
            ...body,
            daycare,
            date
          }
        });
      }
      form.resetFields();
      setIsModalOpenedLocaly(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mealTypes.length > 0 && !dayMeals) {
      form.setFieldsValue({
        meals: mealTypes.map((mealType) => ({
          meal_type: mealType,
          name: '',
          ingredients: []
        }))
      });
    } else {
      form.setFieldsValue(dayMeals);
    }
  }, [mealTypes, form]);

  return (
    <Modal
      title={`${t(`${resourceName}.modal.meal`)} ${dayjs(date).format('LL')}`}
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
      }}
      footer={null}
      width={800}
      className="create-meal-modal"
    >
      <Skeleton loading={optionsLoading}>
        <Form form={form} onFinish={customSubmit}>
          <Form.List name={['meals']}>
            {(fields) => (
              <>
                {fields.map((field, index) => (
                  <Space
                    key={field.key}
                    align="baseline"
                    style={{ marginBottom: 16 }}
                  >
                    <MealForm
                      ingredients={ingredients}
                      meal={mealTypes[index]}
                      field={field}
                      form={form}
                      dayMeal={dayMeals ? dayMeals.meals[index] : null}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>

          <Form.Item {...tailFormItemLayout}>
            <Flex justify="flex-end" gap="small">
              <Button
                type="link"
                danger
                onClick={() => setIsModalOpenedLocaly(false)}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};

CreateUpdateMeal.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  resourceName: PropTypes.string.isRequired,
  date: PropTypes.string,
  dayMeals: PropTypes.shape({
    _id: PropTypes.string,
    meals: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

CreateUpdateMeal.defaultProps = {
  purpose: '',
  record: null,
  setRefresh: () => {},
  date: undefined,
  dayMeals: null
};
