import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Popover, Select, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompactPicker } from 'react-color';
import { useErrorMessage } from '../../utils/errorMessage';
import { useIconArray } from './IconArray';
import usePanelContext from './ConfigurationContext';
import ButtonPanel from './ButtonPanel';

/**
 * EditableCell component for rendering editable cells within a table.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.editing - Indicates whether the cell is in editing mode.
 * @param {string} props.dataIndex - The key of the data field associated with the cell.
 * @param {string} props.title - The title of the cell.
 * @param {string} props.inputType - Specifies the type of input to render ('color', 'icon', 'display', or defaulting to an <Input> component).
 * @param {Object} props.record - The record object associated with the cell.
 * @param {number} props.index - The index of the cell in the table.
 * @param {*} props.children - The children components of the cell.
 * @param {string} props.colorPicked - The selected color value (used for the color input type).
 * @param {Function} props.setColorPicked - Function to set the selected color value.
 * @param {boolean} props.isDisplayed - Indicates whether a particular item is displayed (used for the display input type).
 * @param {Function} props.setIsDisplayed - Function to toggle the displayed state of an item.
 * @param {Function} props.setIconValue - Unused in the component.
 * @param {*} restProps - Additional props to be spread onto the <td> element.
 * @returns {JSX.Element} The rendered EditableCell component.
 */
export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  colorPicked,
  setColorPicked,
  isDisplayed,
  setIsDisplayed,
  selectType,
  iconValue,
  setIconValue,
  ...restProps
}) => {
  const { t } = useTranslation();
  const iconArray = useIconArray();
  let inputNode;

  const popoverContent = (
    <CompactPicker
      color={colorPicked}
      onChangeComplete={(color) => setColorPicked(color.hex)}
    />
  );

  switch (inputType) {
    case 'color':
      inputNode = (
        <Popover content={popoverContent} title={t('configurations.color')}>
          <Tag color={colorPicked}>{colorPicked}</Tag>
        </Popover>
      );
      break;
    case 'icon':
      inputNode = (
        <Select>
          {iconArray.map((icon) => (
            <Select.Option value={icon.label}>{icon.value}</Select.Option>
          ))}
        </Select>
      );

      break;
    case 'display':
      inputNode = (
        <Switch
          defaultChecked={record.display || isDisplayed}
          onChange={() => setIsDisplayed(!isDisplayed)}
        />
      );
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * ConfigurationTable component for rendering a table with editable cells.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {Array} props.items - The items to display in the table.
 * @param {Array} props.resourceColumns - The columns configuration for the table.
 * @param {string} props.resourceName - The name of the resource.
 * @returns {JSX.Element} The rendered ConfigurationTable component.
 */
const ConfigurationTable = ({ items, resourceColumns, resourceName }) => {
  const { t } = useTranslation();
  const { editItem, deleteItem, cancel, editingKey, setEditingKey, isEditing } =
    usePanelContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [colorPicked, setColorPicked] = useState('#ababab');
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [iconValue, setIconValue] = useState('');

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
    if (record?.color) setColorPicked(record.color);
  };

  const setValues = ({ color, icon }) => {
    form.setFieldsValue({
      color: colorPicked || color,
      display: isDisplayed,
      iconValue: iconValue || icon
    });
  };

  const save = async (record) => {
    setValues(record);
    try {
      const row = await form.validateFields();
      editItem(row, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
  };

  const columns = [
    ...resourceColumns,

    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      align: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        if (record.reserved !== true) {
          return (
            <ButtonPanel
              editingKey={editingKey}
              editable={editable}
              saveItem={() => save(record)}
              edit={() => edit(record)}
              cancel={cancel}
              deleteItem={() => deleteItem(record, resourceName)}
            />
          );
        }
        return false;
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (col?.editable) {
      const createInputType = (dataIndex) => {
        switch (dataIndex[0]) {
          case 'icon':
            return 'icon';
          case 'color':
            return 'color';
          case 'display':
            return 'display';
          default:
            return 'text';
        }
      };

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: createInputType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          colorPicked,
          setColorPicked,
          isDisplayed,
          setIsDisplayed,
          iconValue,
          setIconValue
        })
      };
    }
    return col;
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={items}
        columns={mergedColumns}
        style={{ overflowX: 'auto', overflowY: 'visible' }}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  setColorPicked: PropTypes.string.isRequired,
  colorPicked: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  setIsDisplayed: PropTypes.bool.isRequired,
  iconValue: PropTypes.string.isRequired,
  setIconValue: PropTypes.string.isRequired,
  selectType: PropTypes.string.isRequired
};

ConfigurationTable.propTypes = {
  items: PropTypes.string.isRequired,
  resourceColumns: PropTypes.shape([{}]).isRequired,
  resourceName: PropTypes.string.isRequired
};

export default ConfigurationTable;
