import dayjs from 'dayjs';

/**
 * Utility function to recursively parse dates in an object.
 *
 * @function
 *
 * @param {Object} data - The object containing dates to parse.
 * @returns {Object} The object with dates parsed using dayjs.
 */
const parseDates = (data) => {
  if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Date) {
        // eslint-disable-next-line no-param-reassign
        data[key] = dayjs(data[key]);
      } else if (typeof data[key] === 'object') {
        this.parseDates(data[key]);
      }
    });
  }
  return data;
};

/**
 * Base model class for interacting with API endpoints.
 */
export class BaseModel {
  static dispatchAPI;

  constructor(modelName, schema, data) {
    this.modelName = modelName;
    let verifiedData = schema?.parse(data) || data;
    verifiedData = parseDates(verifiedData);
    Object.keys(verifiedData).forEach((key) => {
      this[key] = verifiedData[key];
    });
  }

  /**
   * Fetches a single instance of the model by ID.
   *
   * @function
   *
   * @param {string} id - The ID of the instance to fetch.
   * @param {Object} options - Additional options for the request.
   * @param {string} options.populate - Optional parameter to specify fields to populate.
   * @returns {Promise<Object>} A promise that resolves to the fetched instance.
   */

  static getOneById(id, { populate = '' }) {
    return this.dispatchAPI('GET', {
      url: `/${this.modelName}/${id}`,
      params: { populate }
    });
  }

  /**
   * Fetches all instances of the model.
   *
   * @function
   *
   * @returns {Promise<Array>} A promise that resolves to an array of instances.
   */
  static getAll() {
    return this.dispatchAPI('GET', { url: `/${this.modelName}s` });
  }

  /**
   * Creates a new instance of the model.
   *
   * @function
   *
   * @param {Object} props - The properties of the new instance.
   * @returns {Promise<Object>} A promise that resolves to the created instance.
   */
  static create(props) {
    return this.dispatchAPI('POST', {
      url: `/${this.modelName}`,
      body: { ...props }
    });
  }

  /**
   * Updates the current instance with the provided properties.
   *
   * @function
   *
   * @param {String} id - Id of the document to update.
   * @param {Object} props - The properties to update.
   * @returns {Promise<void>} A promise that resolves when the update is complete.
   */
  static update(id, props) {
    return this.dispatchAPI('PATCH', {
      url: `/${this.modelName}/${id}`,
      data: { ...props }
    }).then(() => {
      Object.keys(props).forEach((key) => {
        this[key] = props[key];
      });
    });
  }

  /**
   * Deletes the current instance.
   *
   * @function
   *
   * @returns {Promise<void>} A promise that resolves when the deletion is complete.
   */
  delete() {
    return this.dispatchAPI('DELETE', { url: `/${this.modelName}/${this.id}` });
  }

  /**
   * Fetches enums associated with the model.
   *
   * @function
   *
   * @returns {Promise<Object>} A promise that resolves to the enums associated with the model.
   */
  getEnum() {
    return this.dispatchAPI('GET', { url: `/api/${this.modelName}/enums` });
  }
}
