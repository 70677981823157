import PropTypes from 'prop-types';
import { Card, Col, Flex, List, Menu, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { NewsCard } from './NewsCard';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { getTotalByTopic, sortByMostViews } from './utils';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * A React component for rendering a news section with topic filters and a list of the most viewed news.
 * It allows users to filter news by various topics and displays news items in a grid layout.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.resources - The array of news items to be displayed.
 * @param {Function} props.handleModal - Function to handle modal actions.
 * @param {Object} props.enums - Enumerations for topics to filter news items.
 *
 * @returns {React.Component} A component that renders a filtered view of news items with a sidebar for topic selection and a section for most viewed news.
 */
export const NewsRender = ({ resources, handleModal, enums }) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const [topicFilter, setTopicFilter] = useState('ALL');
  const [current, setCurrent] = useState([]);

  const menuItems = [
    ...(enums?.topic || []).map((topic) => ({
      label: `${t(`communication.news.list.type.${topic}`)} (${getTotalByTopic(
        resources,
        topic
      )})`,
      key: topic
    })),
    {
      label: `${t('communication.news.all_topics')} (${resources?.length})`,
      key: 'ALL'
    }
  ];

  const handleMenuClick = (key) => {
    if (key === 'ALL') {
      setCurrent(key);
      setTopicFilter();
    }
    setCurrent(key);
    setTopicFilter(key);
  };

  return (
    <Row gutter={[16, 16]} className="communication-wrapper">
      <Col xs={24} md={10} lg={8} xl={6} xxl={4}>
        <Flex vertical gap="middle">
          <Card
            title={t('communication.news.topics')}
            bordered={false}
            className="comm-news-menu"
          >
            <Menu
              onClick={(e) => handleMenuClick(e.key)}
              selectedKeys={[current]}
              mode="vertical"
              items={menuItems}
              className="news-category-menu"
            />
          </Card>
          <Card
            title={t('communication.news.most_views')}
            bordered={false}
            className="comm-news-menu"
          >
            <Flex vertical gap="small">
              {(sortByMostViews(resources) || []).map((news) => (
                <Link to={`${routes.COMMUNICATION}/show/news/${news?._id}`}>
                  <Flex align="center" gap="middle" key={news._id}>
                    {news?.thumbnail?._id && (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${news?.thumbnail?._id}/${token}`}
                        alt="thumbnail-icon"
                        className="comm-thumbnail-img"
                      />
                    )}
                    {news?.title}
                  </Flex>
                </Link>
              ))}
            </Flex>
          </Card>
        </Flex>
      </Col>
      <Col xs={24} md={14} lg={16} xl={18} xxl={20}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 4
          }}
          className="news-list"
          dataSource={(resources || []).filter((news) => {
            if (topicFilter === 'ALL') return news.topic;
            return news.topic === topicFilter;
          })}
          renderItem={(resource) => (
            <NewsCard
              key={resource._id}
              news={resource}
              handleModal={handleModal}
            />
          )}
          pagination={{
            pageSize: 8
          }}
        />
      </Col>
    </Row>
  );
};

NewsRender.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enums: PropTypes.shape({
    topic: PropTypes.arrayOf(PropTypes.string)
  }),
  handleModal: PropTypes.func.isRequired
};

NewsRender.defaultProps = {
  enums: null
};
