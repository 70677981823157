import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Card, Col, Divider, Flex, Row, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import ArrivalsIcon from '../../assets/images/icons/noto_bellhop-bell.svg';
import DepartureIcon from '../../assets/images/icons/noto_hourglass-done.svg';
import { calculateTimeDiff, determineRealTimeColor } from './utils';
import { PickupTimeModal } from './PickupTimeModal';

/**
 * A component that displays a list of arrival or departure events.
 *
 * This component fetches and displays a list of either arrival or departure events
 * for children at a daycare. It uses the `useAuthContext` for API calls and `useErrorMessage`
 * for error handling. The events are displayed in a card with each event showing the child's
 * name, expected time, and actual time. The component can be configured to show either
 * arrivals or departures based on the `resourceName` prop.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource to display ('arrivals' or 'departures').
 * @param {Object} [props.data] - Optional initial data for the component.
 * @param {boolean} props.isLoading - Boolean for data loading.
 * @param {Function} props.setRefresh - Function to refresh datas.
 * @returns {JSX.Element} The JSX rendering of the arrivals or departures list.
 *
 * @example
 * return (
 *   <ArrivalsOrDepartures resourceName="arrivals" />
 * )
 */
export const ArrivalsOrDepartures = ({
  resourceName,
  data,
  isLoading,
  setRefresh
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState();

  const iconUrl = resourceName === 'arrivals' ? ArrivalsIcon : DepartureIcon;

  const handleModalBehaviour = (child) => {
    const modalKey = Date.now();

    setModal(
      <PickupTimeModal
        key={modalKey}
        setRefresh={setRefresh}
        child={child}
        source={resourceName}
      />
    );
  };

  return (
    <>
      <Card
        title={
          <Flex align="center" gap="middle">
            <img src={iconUrl} alt={`${resourceName}-icon`} />
            {t(`attendance-check.form.${resourceName}`)}
          </Flex>
        }
        className={`attendance-card ${resourceName}`}
        bordered={false}
      >
        <Skeleton loading={isLoading}>
          <Flex vertical gap="small">
            {data?.waiting?.length ? (
              data.waiting.map((event) => {
                const expectedTime =
                  resourceName === 'arrivals'
                    ? event.expectedDropOffTime
                    : event.expectedPickUpTime;

                const formattedExpectedTime = calculateTimeDiff(expectedTime);
                const childColor =
                  event.child.gender === 'GIRL'
                    ? 'var(--logoPink)'
                    : 'var(--logoBlueDarker)';

                const content = (
                  <Row justify="space-between">
                    <Col
                      span={12}
                      style={{ color: childColor, textAlign: 'left' }}
                    >{`${event.child.first_name} ${event.child.last_name}`}</Col>
                    <Col span={5}>{expectedTime}</Col>
                    <Col
                      span={7}
                      style={{
                        color: formattedExpectedTime.color,
                        fontWeight: 'bold'
                      }}
                    >
                      {formattedExpectedTime.text}
                    </Col>
                  </Row>
                );

                return (
                  <Button
                    block
                    onClick={() => handleModalBehaviour(event.child)}
                    className="attendance-item"
                  >
                    {content}
                  </Button>
                );
              })
            ) : (
              <Flex
                justify="center"
                style={{
                  margin: '32px 0px 32px 0px'
                }}
              >
                {t(`attendance-check.form.no-children-${resourceName}`)}
              </Flex>
            )}
          </Flex>
          {!!data?.validated?.length && (
            <Divider>{t('attendance-check.form.validated')}</Divider>
          )}
          <Flex vertical gap="small">
            {(data.validated || []).map((event) => {
              const expectedTime =
                resourceName === 'arrivals'
                  ? event.expectedDropOffTime
                  : event.expectedPickUpTime;
              const realTime =
                resourceName === 'arrivals'
                  ? dayjs.utc(event.droppedOffAt).format('HH:mm')
                  : dayjs.utc(event.pickedUpAt).format('HH:mm');

              const childColor =
                event.child.gender === 'GIRL'
                  ? 'var(--logoPink)'
                  : 'var(--logoBlueDarker)';

              const realTimeColor = determineRealTimeColor(
                realTime,
                expectedTime
              );

              return (
                <Row justify="space-between" className="attendance-item">
                  <Col
                    span={12}
                    style={{ color: childColor, textAlign: 'left' }}
                  >{`${event.child.first_name} ${event.child.last_name}`}</Col>
                  <Col span={5}>{expectedTime}</Col>
                  <Col span={5} style={{ color: realTimeColor }}>
                    {realTime}
                  </Col>
                  <Col span={2}>
                    <CheckCircleOutlined
                      style={{ color: 'var(--addColor)', fontSize: 16 }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Flex>
        </Skeleton>
      </Card>
      {modal}
    </>
  );
};

ArrivalsOrDepartures.propTypes = {
  data: PropTypes.shape({
    waiting: PropTypes.arrayOf(PropTypes.shape({})),
    validated: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  resourceName: PropTypes.string.isRequired,
  isLoading: PropTypes.string.isRequired,
  setRefresh: PropTypes.func
};

ArrivalsOrDepartures.defaultProps = {
  data: {},
  setRefresh: null
};
