import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Calculate a person's age based on their birthdate and format it as a string.
 *
 * @param {string} date - The birthdate in a valid date string format (e.g., 'YYYY-MM-DD').
 * @param {function} t - A translation function for localization.
 * @returns {string} A formatted string representing the person's age, or an empty string if `date` is falsy.
 */
export const getAge = (date, t) => {
  if (date) {
    const birthdayDate = dayjs(date);
    const today = dayjs();

    const ageInYears = today.diff(birthdayDate, 'year');

    birthdayDate.add(ageInYears, 'year');
    const ageInMonths = today.diff(birthdayDate, 'month');

    birthdayDate.add(ageInMonths, 'month');
    const ageInWeeks = today.diff(birthdayDate, 'week');

    birthdayDate.add(ageInWeeks, 'week');
    const ageInDays = today.diff(birthdayDate, 'day');

    switch (true) {
      case ageInDays < 0:
        return '';
      case ageInYears > 0:
        if (ageInYears === 1) {
          return `${ageInYears} ${t('year')}`;
        }
        return <div>{`${ageInYears} ${t('years')}`}</div>;
      case ageInMonths > 0:
        if (ageInMonths === 1) {
          return `${ageInMonths} ${t('month')}`;
        }
        return <div>{`${ageInMonths} ${t('months')}`}</div>;
      case ageInWeeks > 0:
        if (ageInWeeks === 1) {
          return `${ageInWeeks} ${t('week')}`;
        }
        return <div>{`${ageInWeeks} ${t('weeks')}`}</div>;
      case ageInDays === 1:
        return `${ageInDays} ${t('day')}`;
      default:
        return <div>{`${ageInDays} ${t('days')}`}</div>;
    }
  }
  return '';
};
