import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFields } from '../fields';
import { CreateUpdateContainerModal } from '../../../../components/CreateUpdateContainer/CreateUpdateContainerModal';

/**
 * `ParentModal` is a React component that renders a modal for creating or updating conversations.
 * It utilizes Ant Design's `Modal` component for the modal UI and a custom `CreateUpdateContainerModal` for form handling.
 *
 * This component is integrated with custom hooks for form field management and API dispatching. It handles the submission
 * of form data to create or update conversation records.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} props.forceRefresh - A boolean to trigger a refresh in the parent component.
 * @param {Function} props.setForceRefresh - A function to update the forceRefresh state in the parent component.
 *
 * @returns {JSX.Element} A modal component for handling the creation or updating of conversation records.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.forceRefresh {boolean} - A boolean to trigger a refresh in the parent component. Default is false.
 * @propTypes.setForceRefresh {Function} - A function to update the forceRefresh state in the parent component. Can be null.
 */
export const ParentModal = ({ forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const { fields, isFieldsLoading } = useFields();
  const { dispatchAPI, user, daycare } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = useErrorMessage();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (_, values) => {
    try {
      setIsSubmitting(true);
      const url = `conversations`;
      const { _id: from } = user;
      const body = {
        ...values,
        from,
        category: 'PARENTS',
        management_only: false,
        daycare
      };
      await dispatchAPI('POST', {
        url,
        body
      });
      closeModal();
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t('messages_and_notes.modal_title')}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        baseUrl="messages_and_notes"
        resource="messages_and_notes"
        config={config}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={null}
        withGetResource={false}
      />
    </Modal>
  );
};

ParentModal.propTypes = {
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

ParentModal.defaultProps = {
  forceRefresh: false,
  setForceRefresh: null
};
