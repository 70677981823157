import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import useFields from './fields';

const PaymentModal = ({
  isModalOpen,
  closeModal,
  purpose,
  paymentId,
  daycare,
  setRefresh,
  invoiceId,
  customerAccountId,
  isCreditModal
}) => {
  const { t } = useTranslation();

  const { fields, isFieldsLoading } = useFields(
    purpose,
    daycare,
    invoiceId,
    customerAccountId,
    isCreditModal
  );

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        operation_date: data.operation_date && dayjs(data.operation_date)
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(
        `payments.modal.title.${purpose}${isCreditModal ? '_credit' : ''}`
      )}`}
      width={900}
      footer={false}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="payments"
        resource="payments"
        closeModal={closeModal}
        id={paymentId}
        withGetResource
        config={config}
        setRefresh={setRefresh}
        withoutPageHeaderCustom
      />
    </Modal>
  );
};

PaymentModal.propTypes = {
  paymentId: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedChild: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    _id: PropTypes.string
  }),
  purpose: PropTypes.string,
  daycare: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
  customerAccountId: PropTypes.string,
  isCreditModal: PropTypes.bool
};

PaymentModal.defaultProps = {
  paymentId: null,
  selectedChild: null,
  purpose: null,
  invoiceId: undefined,
  customerAccountId: undefined,
  isCreditModal: undefined
};

export default PaymentModal;
