import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * CreateUpdateDynamicListContent component for creating or updating dynamic list items.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource being managed.
 * @param {string} props.purpose - The purpose of the operation, either 'create' or 'edit'.
 * @param {boolean} props.isModalOpen - A boolean flag indicating whether the modal is open.
 * @param {function} props.setIsModalOpen - A function to set the modal open/close state.
 * @param {Object} props.record - The record being edited or created.
 * @param {boolean} props.refresh - A boolean flag indicating whether to refresh the list after the operation.
 * @param {function} props.setRefresh - A function to set the refresh state.
 * @param {Object} props.fields - The form fields for the dynamic list item.
 * @param {boolean} props.isFieldsLoading - A boolean flag indicating whether the fields are in a loading state.
 * @returns {React.Element} The rendered CreateUpdateDynamicListContent component.
 */
export const CreateUpdateDynamicListContent = ({
  resourceName,
  purpose,
  record,
  fields,
  setRefresh,
  tagColor,
  setTagColor
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpenLocaly, setIsModalOpenLocaly] = useState(true);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: () => {
        setTagColor(record.color);
        return {
          ...record
        };
      }
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/${resourceName}/${record._id}`,
          body:
            resourceName !== 'team_positions'
              ? body
              : {
                  ...body,
                  color: tagColor.hex
                }
        });
      } else {
        await dispatchAPI('POST', {
          url: `/${resourceName}`,
          body:
            resourceName !== 'team_positions'
              ? body
              : {
                  ...body,
                  color: tagColor.hex
                }
        });
      }
      form.resetFields();
      setIsModalOpenLocaly(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t(
        `global_settings.dynamic_lists.modals.${
          purpose === 'create' ? 'add' : 'edit'
        }-${resourceName}`
      )}
      open={isModalOpenLocaly}
      onCancel={() => {
        setIsModalOpenLocaly(false);
      }}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        isCustomSubmitting={isSubmitting}
        purpose={purpose}
        customSubmit={customSubmit}
        resource={resourceName}
        config={config}
        isModalOpen
        withoutPageHeaderCustom
        customFormInstance={form}
        closeModal={() => setIsModalOpenLocaly(false)}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

CreateUpdateDynamicListContent.propTypes = {
  resourceName: PropTypes.string.isRequired,
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    color: PropTypes.shape({
      hex: PropTypes.string
    })
  }),
  fields: PropTypes.shape({}),
  setRefresh: PropTypes.func.isRequired,
  tagColor: PropTypes.string,
  setTagColor: PropTypes.func
};

CreateUpdateDynamicListContent.defaultProps = {
  purpose: '',
  record: {
    _id: ''
  },
  fields: null,
  tagColor: null,
  setTagColor: null
};
