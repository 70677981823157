import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Flex, TimePicker } from 'antd';
import { disabledTime } from '../TimeRangeBar/utils/disabledTime';
import { useHandleResize } from '../../utils/handleResize';

const { RangePicker } = TimePicker;

/**
 * MercuryTimeRangePicker is a custom time range picker component that adjusts its appearance based on the screen size.
 * It renders an html input field for mobile devices or an AntDesign TimePicker.RangePicker for computers.
 * It insures a better user experience on mobile devices by using the native date picker.
 *
 * @param {object} form - The Ant Design form instance object.
 * @param {string} formInput - The name of the form input. (ex: 'times')
 * @param {string} format - The format of the time range (ex., 'HH:mm').
 * @param {object} disabledTimeRange - An object containing the start and end times for the disabled range.
 * @param {function} onChangeCallback - A custom callback function to handle the time range change.
 * @returns {JSX.Element} The rendered time range picker component.
 *
 * @example
 * return (
 *   <MercuryTimeRangePicker
 *     formInput="times"
 *     form={form}
 *     format="HH:mm"
 *     disabledTimeRange={{ start: 6, end: 21 }}
 *     onChangeCallback={(value) => console.log(value)}
 *   />
 * )
 */
export const MercuryTimeRangePicker = ({
  form,
  formInput,
  format,
  disabledTimeRange,
  onChangeCallback
}) => {
  const { width } = useHandleResize();

  const onChange = (value, inputId) => {
    if (!form || !formInput || !value || !inputId) return null;

    const [hour, minute] = value.split(':');
    const formattedValue = dayjs().set('hour', hour).set('minute', minute);

    let newValue;

    if (onChangeCallback) {
      newValue =
        inputId === 'start' ? [formattedValue, null] : [null, formattedValue];
      onChangeCallback(newValue);
    } else {
      const initialFormInputValue = form.getFieldValue(formInput) || [
        null,
        null
      ];

      newValue =
        inputId === 'start'
          ? [formattedValue, initialFormInputValue[1]]
          : [initialFormInputValue[0], formattedValue];
      form.setFieldsValue({ [formInput]: newValue });
    }

    return newValue;
  };

  // An iPad Pro is 1280 pixels wide
  return width <= 1280 ? (
    <Flex className="time-rangepicker" gap={8} align="center">
      <input
        type="time"
        min={disabledTimeRange.start}
        max={disabledTimeRange.end}
        onChange={(e) => onChange(e.target.value, 'start')}
      />
      <input
        type="time"
        min={disabledTimeRange.start}
        max={disabledTimeRange.end}
        onChange={(e) => onChange(e.target.value, 'end')}
      />
    </Flex>
  ) : (
    <RangePicker
      format={format}
      onChange={(value) => {
        form.setFieldsValue({ [formInput]: value });
      }}
      disabledTime={() =>
        disabledTime(disabledTimeRange.start, disabledTimeRange.end)
      }
    />
  );
};

MercuryTimeRangePicker.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  formInput: PropTypes.string.isRequired,
  format: PropTypes.string,
  disabledTimeRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }),
  onChangeCallback: PropTypes.func
};

MercuryTimeRangePicker.defaultProps = {
  format: 'HH:mm',
  disabledTimeRange: {
    start: 0,
    end: 23
  },
  onChangeCallback: null
};
