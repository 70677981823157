import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Flex } from 'antd';
import { ContentCustom } from '../../components';
import { Header } from './Header';
import { NavigateBackButton } from './NavigateBackButton';

/**
 * Renders the success page for the checkout payment process.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.session - The session object.
 * @param {Object} props.session.invoice - The invoice object.
 * @param {string} props.session.invoice.number - The invoice number.
 * @param {number} props.session.invoice.total - The invoice total.
 * @returns {JSX.Element} The rendered component.
 */
export const SuccessCheckout = ({ session }) => {
  const { t } = useTranslation();
  const successTranslationKey = 'credit-card-payments.success';

  const cardTitle = (
    <>
      <Flex style={{ color: 'var(--logoPurple)' }}>
        {t('credit-card-payments.invoice_number', {
          number: session?.invoice?.number
        })}
      </Flex>
      <Flex justify="space-between">
        <p>{t('credit-card-payments.total')}</p>
        <p>{`${(session?.invoice?.total ?? 0).toFixed(2)} €`}</p>
      </Flex>
    </>
  );

  return (
    <>
      <Header />
      <ContentCustom
        style={{
          minWidth: '70vw',
          maxHeight: '50vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Card style={{ flex: '1 0 auto' }} title={cardTitle}>
          <p>{t(`${successTranslationKey}.saved_payment`)}</p>
          <p>{t(`${successTranslationKey}.confirmation_email_send`)}</p>
        </Card>
        <NavigateBackButton />
      </ContentCustom>
    </>
  );
};

SuccessCheckout.propTypes = {
  session: PropTypes.shape({
    invoice: PropTypes.shape({
      number: PropTypes.string,
      total: PropTypes.number
    })
  })
};

SuccessCheckout.defaultProps = {
  session: {
    invoice: {
      number: '',
      total: 0
    }
  }
};
