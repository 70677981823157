/**
 * @description
 * useListContent is a hook that returns the content of the quotation card.
 * It is used in the QuotationCard component.
 *
 * @param {Object} data - The data of the parent
 * @returns {Array} The content of the parent card
 */
export const useListContent = (data = {}) => {
  const listContent = [
    {
      label: 'pre-registrations.show.quotation.hourly_rate',
      content: (data?.hourly_rate && `${data.hourly_rate} € / H`) || '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.quotation.applied_rate',
      content: '-',
      span: 1
    }
  ];

  return listContent;
};
