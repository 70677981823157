import { useState, useCallback, useEffect } from 'react';
import { Checkbox, Flex, Spin } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { usePreregistrationContext } from '../../../contexts/PreregistrationContext';
import { useReferenceWeekContext } from '../../../contexts/ReferenceWeekContext';

/**
 * Hook for getting the child fields.
 * This hook is used in the child form.
 *
 * @hook
 * @returns {Object} childFields and isFieldsLoading
 */
export const useDaycareFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { departmentFilter } = usePreregistrationContext();
  const { setSelectedDaycares } = useReferenceWeekContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [daycares, setDaycares] = useState([]);

  const getDaycares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `pre-registrations/daycares?address.state=${departmentFilter}`
      });
      setDaycares(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getDaycares();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const daycareFields = [
    {
      startWithDivider: false,
      noLabel: true,
      name: ['daycares'],
      rules: [{ required: true }],
      input: isFieldsLoading ? (
        <Spin />
      ) : (
        <Checkbox.Group
          className="prereg-daycare-btn"
          onChange={(e) => setSelectedDaycares(e)}
          options={daycares.map((daycare) => {
            const component = (
              <Flex vertical align="flex-start" gap="small">
                {daycare.logo && (
                  <img
                    src={daycare.logo}
                    alt="daycare-logo"
                    style={{ width: 'auto', height: 40 }}
                  />
                )}
                <Flex vertical>
                  {daycare.name}
                  <Flex
                    gap="small"
                    align="center"
                    className="prereg-daycare-location"
                  >
                    <EnvironmentOutlined />
                    {daycare.address.city}
                  </Flex>
                </Flex>
              </Flex>
            );
            return {
              label: component,
              value: daycare?._id
            };
          })}
        />
      )
    }
  ];

  return {
    daycareFields,
    isFieldsLoading
  };
};
