export const useListContent = (data = {}) => {
  const {
    address,
    user,
    mobile_phone_number,
    landline_phone_number,
    children
  } = data;

  const principal_infos = [
    {
      label: 'parents.show.first_name',
      span: 8,
      content: user?.first_name || '-'
    },
    {
      label: 'parents.show.last_name',
      span: 8,
      content: user?.last_name || '-'
    },
    {
      label: 'parents.show.beneficiary_number',
      span: 8,
      content:
        children?.length && children[0]?.contracts?.length
          ? children[0]?.contracts[0]?.household?.beneficiary_number
          : '-'
    }
  ];
  const coordonees = [
    {
      label: 'parents.show.address',
      span: 16,
      content:
        address && address?.number !== '' ? (
          <>
            {address?.number} {address?.street}
            <br />
            {address?.additional}
            {address?.additional && <br />}
            {address?.postal_code} {address?.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: 'parents.show.state',
      span: 8,
      content: address?.state || '-'
    },
    {
      label: 'parents.show.email',
      span: 24,
      content: user?.email || '-'
    },
    {
      label: 'parents.show.mobile_phone_number',
      span: 8,
      content:
        mobile_phone_number && mobile_phone_number?.number ? (
          <div>
            {`${
              mobile_phone_number?.country_code
            } ${mobile_phone_number?.number.slice(
              0,
              1
            )} ${mobile_phone_number?.number.slice(1).replace(/(.{2})/g, '$1 ')}
                  `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'parents.show.landline_phone_number',
      span: 8,
      content:
        landline_phone_number && landline_phone_number?.number ? (
          <div>
            {`${
              landline_phone_number?.country_code
            } ${landline_phone_number?.number.slice(
              0,
              1
            )} ${landline_phone_number?.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                  `}
          </div>
        ) : (
          '-'
        )
    }
  ];

  const professional_and_familial_situation = [
    {
      label: 'parents.show.annual_income',
      span: 8,
      content:
        (children?.length &&
          children[0]?.contracts?.length &&
          `${children[0].contracts[0].household?.annual_incomes.toFixed(
            2
          )}€`) ||
        '-'
    },
    {
      label: 'parents.show.dependent_children',
      span: 8,
      content:
        (children?.length &&
          children[0]?.contracts?.length &&
          children[0].contracts[0].household?.dependent_children) ||
        '-'
    }
  ];

  return { principal_infos, coordonees, professional_and_familial_situation };
};
