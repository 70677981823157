import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CheckoutStatus } from './CheckoutStatus';

/**
 * Returns an array of columns configuration for the credit card payment table.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'credit-card-payments.columns';

  return [
    {
      title: t(`${translationKey}.invoice_number`),
      key: 'invoice_number',
      dataIndex: 'invoice',
      render: (invoice) =>
        invoice && invoice?._id && invoice?.number ? (
          <Link to={`/invoicing/invoices/show/${invoice._id}`}>
            {invoice.number}
          </Link>
        ) : (
          '-'
        )
    },
    {
      title: t(`${translationKey}.status`),
      key: 'status',
      render: (record) => <CheckoutStatus checkout={record} />
    },
    {
      title: t(`${translationKey}.amount`),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => (amount ? `${(amount / 100).toFixed(2)} €` : '-')
    },
    {
      title: t(`${translationKey}.customer_account`),
      key: 'customer_account',
      dataIndex: 'customer_account',
      render: (customer_account) => customer_account?.label || '-'
    },
    {
      title: t(`${translationKey}.payment_date`),
      key: 'payment_date',
      dataIndex: 'payment_date',
      render: (payment_date) =>
        payment_date ? dayjs(payment_date).format('DD/MM/YYYY') : '-'
    }
  ];
};
