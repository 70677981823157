import { Button, Flex, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useColumns } from './columns';
import { CreateUpdateActivityModal } from './CreateUpdateActivityModal';

/**
 * Component for displaying a list of activities with the ability to create, update, and delete activities.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.activity - The activity data to be displayed.
 * @param {string} props.activity.resourceName - The name of the resource associated with the activities.
 * @param {Array} props.activity.data - An array of activity data to be displayed in a table.
 * @param {boolean} props.refresh - Boolean flag indicating whether to refresh the component.
 * @param {Function} props.setRefresh - Function to trigger a refresh of the component.
 * @returns {JSX.Element} JSX representation of the Activity component.
 */
export const Activity = ({ activity, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateActivityModal
        key={modalKey}
        resourceName={activity.resourceName}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    );
  };

  const columns = useColumns(
    handleModalBehaviour,
    refresh,
    setRefresh,
    activity.resourceName
  );

  return (
    <>
      {modal}
      <Flex justify="flex-end">
        <Button onClick={() => handleModalBehaviour(undefined, 'create')}>
          <PlusOutlined />
          {t('buttons.add')}
        </Button>
      </Flex>
      <Table columns={columns} dataSource={activity.data} />
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    resourceName: PropTypes.string,
    data: PropTypes.arrayOf()
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

Activity.defaultProps = {
  activity: null,
  refresh: false,
  setRefresh: null
};
