import { Flex, Form, Input } from 'antd';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * A component for rendering an editable cell within a table. This cell can switch between read-only and editable modes depending on the `editable` prop.
 *
 * @component
 *
 * In editable mode, the cell displays an input field, allowing the user to modify the cell's content. Changes to the input are handled by the `handleItemUpdate` function, which updates the corresponding record's data in the parent component's state. In read-only mode, the cell simply displays its children, typically the cell's value.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.editable - Determines if the cell is editable.
 * @param {string} props.dataIndex - The key of the data being displayed and edited in this cell.
 * @param {Object} props.record - The record containing the cell's data.
 * @param {number} props.index - The index of the cell's row in the table.
 * @param {string} [props.suffix] - Optional suffix to display in the input field.
 * @param {JSX.Element} props.children - The content to be displayed in read-only mode.
 * @param {Function} props.handleItemUpdate - Function to call when the input value changes, updating the parent component's state.
 * @param {Object} restProps - Additional properties passed to the cell.
 * @returns {JSX.Element} A table cell (`<td>`) that can be either an input field or plain text, based on its editable state.
 */
export const EditableCell = ({
  editable,
  dataIndex,
  record,
  index,
  suffix,
  children,
  handleItemUpdate,
  mode,
  source,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const conditionToDisplayEditableInput =
    ([
      'Contrepartie, contributions à titre gratuit',
      'Contributions volontaires'
    ].includes(record?.label) &&
      source === 'statement') ||
    source === 'scale' ||
    dataIndex === 'CAF_declared_total';

  if (
    record?.disabled_children_rate &&
    dataIndex === 'place_ceiling_cost_price_variable'
  ) {
    if (record?.disabled_children_rate === '>= 5% et < 7,5%') {
      return (
        <td {...restProps} key={record?._id}>
          <Flex
            justify="space-between"
            align="center"
            style={{
              margin: 0
            }}
          >
            +
            <Form.Item
              name={[dataIndex]}
              rules={[
                {
                  required: true
                }
              ]}
              initialValue={record?.[dataIndex]}
              style={{ margin: 0 }}
            >
              <Input
                suffix={suffix}
                value={record?.[dataIndex]}
                ref={inputRef}
                onChange={async (e) => {
                  await handleItemUpdate(
                    dataIndex,
                    record?._id,
                    e.target.value
                  );
                }}
              />
            </Form.Item>
            {t('global_settings.CAF_scale.labels.%_aaeh_children')}
          </Flex>
        </td>
      );
    }
    return (
      <td {...restProps} key={record?._id}>
        <Input disabled bordered={false} />
      </td>
    );
  }
  if (conditionToDisplayEditableInput) {
    return (
      <td {...restProps} key={record?._id}>
        {editable ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0
            }}
            rules={[
              {
                required: true
              }
            ]}
            initialValue={record?.[dataIndex]}
          >
            <Input
              suffix={suffix}
              value={record?.[dataIndex]}
              ref={inputRef}
              onChange={async (e) => {
                await handleItemUpdate(dataIndex, record?._id, e.target.value);
              }}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }
  return (
    <td {...restProps} key={record?._id}>
      {children}
    </td>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  index: PropTypes.string.isRequired,
  handleItemUpdate: PropTypes.func.isRequired,
  mode: PropTypes.string,
  source: PropTypes.string.isRequired
};

EditableCell.defaultProps = {
  suffix: undefined,
  editable: false,
  mode: undefined
};
