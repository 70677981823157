import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Form, Select, Input, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { drawerTailFormLayout } from '../../../../utils/constants/formLayout';

/**
 * A modal component for adding new rows to the financial data of a cash balance import.
 *
 * This component presents a form within a modal dialog, allowing users to add new financial data rows to an existing balance import. It includes fields for selecting an accounting account, specifying an accounting code, and inputting the label for the new row. Upon form submission, it updates the balance state with the new row and resets the form fields before closing the modal. The state for form submission status and form values is managed locally.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.open - Controls the visibility of the modal.
 * @param {Function} props.setOpen - Function to toggle the modal's visibility.
 * @param {Object} props.balance - The current balance data object.
 * @param {Function} props.setBalance - Function to update the balance data with the new row.
 * @param {Function} props.patchBalance - Function to patch balance.
 * @returns {JSX.Element} The modal component for adding a new row to the balance data.
 */
export const RowAddModal = ({
  open,
  setOpen,
  balance,
  setBalance,
  patchBalance
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const options = (balance?.data || []).map((option) => ({
    label: `${option.compte} - ${t(`analysis.table.${option.label}`)}`,
    value: option._id
  }));

  const handleOptionSelect = (option) => {
    const itemIndex = balance?.data?.findIndex((item) => item._id === option);

    if (itemIndex !== -1) {
      form.setFields([
        { name: 'accounting_account', value: balance.data[itemIndex].compte }
      ]);
    }
  };

  const onFinish = async ({
    accounting_class,
    accounting_account,
    intitulé
  }) => {
    setIsSubmitting(true);
    setBalance((prevBalance) => {
      const updatedDocument = { ...prevBalance };

      const itemIndex = updatedDocument?.data?.findIndex(
        (item) => item._id === accounting_class
      );

      const itemToUpdate = updatedDocument.data[itemIndex];

      if (itemIndex !== -1) {
        itemToUpdate.data.push({
          compte: accounting_account,
          intitulé,
          janvier: 0,
          février: 0,
          mars: 0,
          avril: 0,
          mai: 0,
          juin: 0,
          juillet: 0,
          août: 0,
          septembre: 0,
          octobre: 0,
          novembre: 0,
          décembre: 0
        });
      }
      return updatedDocument;
    });
    await patchBalance();
    setIsSubmitting(false);
    form.resetFields();
    setOpen(!open);
  };

  return (
    <Modal open={open} onCancel={() => setOpen(!open)} footer={null}>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={['accounting_class']}
          label={t('analysis.table.accounting_class')}
        >
          <Select
            options={options}
            onSelect={(option) => handleOptionSelect(option)}
          />
        </Form.Item>
        <Form.Item
          name={['accounting_account']}
          label={t('analysis.table.accounting_account')}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['intitulé']} label={t('analysis.table.label')}>
          <Input />
        </Form.Item>
        <Form.Item {...drawerTailFormLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => {
                form.resetFields();
                setOpen(!open);
              }}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

RowAddModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  balance: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        compte: PropTypes.string
      })
    )
  }),
  setBalance: PropTypes.func,
  patchBalance: PropTypes.func.isRequired
};

RowAddModal.defaultProps = {
  open: false,
  setOpen: null,
  balance: null,
  setBalance: null
};
