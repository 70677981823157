import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { headersColumn } from './headers';
import InvoicingContext from '../InvoicingContext';
import PaymentModal from '../payments/PaymentModal';
import { ExtraButtons } from './ExtraButtons';
import { useFormatter } from '../../../utils/formatter';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

/**
 * @component
 * @name ListInvoices
 * @description Displays the list of invoices
 * @returns {component} ListInvoices component
 */
export const ListInvoices = () => {
  const { invoicesDataFormatter } = useFormatter();
  const { daycare } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isCreditModal, setIsCreditModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [customerAccountId, setCustomerAccountId] = useState('');
  const {
    onPrintAction,
    isSpinLoading,
    idInvoice,
    invoiceEnums,
    customerAccountIds,
    filterMonth,
    statuses
  } = InvoicingContext();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get('p') || !params.get('pS')) {
      navigate(`${routes.INVOICING}${subRoutes.INVOICING.INVOICES}?p=1&pS=10`, {
        replace: true
      });
    }
  }, [location, navigate]);

  const onOpenCreditModalAction = (data) => {
    setIsCreditModal(true);
    setIsPaymentModalOpen(true);
    setCustomerAccountId(data.customer_account._id);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setIsCreditModal(false);
    setCustomerAccountId('');
  };

  const columns = useColumns(
    invoiceEnums,
    onPrintAction,
    onOpenCreditModalAction,
    isSpinLoading,
    idInvoice
  );

  return (
    <>
      {isPaymentModalOpen && (
        <PaymentModal
          isModalOpen={isPaymentModalOpen}
          closeModal={closePaymentModal}
          daycare={daycare}
          setRefresh={setRefresh}
          purpose="create"
          customerAccountId={customerAccountId}
          isCreditModal={isCreditModal}
        />
      )}
      <ListResource
        resourceName="invoices"
        populate="customer_account,child,daycare.invoicing_configuration"
        columns={columns}
        headers={headersColumn}
        formatter={invoicesDataFormatter}
        resourceModelName="Invoice"
        withImportButton={false}
        archivedButton={false}
        forceRefresh={refresh}
        customActionColumn
        extraQuery={`${
          filterMonth
            ? `due_date>=${dayjs(filterMonth)
                .startOf('month')
                .toISOString()}&due_date<=${dayjs(filterMonth)
                .endOf('month')
                .toISOString()}`
            : ''
        }${statuses.length ? `&status=${statuses}` : ''}${
          customerAccountIds.length
            ? `&customer_account=${customerAccountIds}`
            : ''
        }&daycare=${daycare}`}
        extraButtons={
          <Col span={19}>
            <ExtraButtons />
          </Col>
        }
      />
    </>
  );
};
