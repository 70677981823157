import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Calculates and returns the age (in weeks, months, or years) between the provided start and end dates.
 * The function uses the `dayjs` library to determine the duration.
 * It will return the age in weeks, months, or years based on the duration between dates.
 *
 * @param {string} rawStartDate - The raw start date (expected to be in a format understood by dayjs).
 * @param {string} rawEndDate - The raw end date (expected to be in a format understood by dayjs).
 * @param {function} t - The translation function used for translating text.
 * @returns {React.ElementType} Rendered age in weeks, months, or years, followed by the translated string for "on entry".
 */
export const calculateEntryAge = (rawStartDate, rawEndDate, t) => {
  const startDate = dayjs(rawStartDate);
  const endDate = dayjs(rawEndDate);

  const diff = endDate.diff(startDate);
  const dur = dayjs.duration(diff);

  switch (true) {
    case dur.weeks() > 3:
      return (
        <div>
          {`${dur.months()} ${t('pre-registrations.form.months')}
          ${t('pre-registrations.form.on_entry')}`}
        </div>
      );
    case dur.weeks() > 24:
      return (
        <div>
          {`${dur.years()} ${t('pre-registrations.form.years')}
          ${t('pre-registrations.form.on_entry')}`}
        </div>
      );
    default:
      return (
        <div>
          {`${dur.weeks()} ${t('pre-registrations.form.weeks')}
        ${t('pre-registrations.form.on_entry')}`}
        </div>
      );
  }
};
