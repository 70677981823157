import { useTranslation } from 'react-i18next';
import { Card, Flex, DatePicker, Col, Row } from 'antd';
import { useHomeContext } from '../../../contexts/HomeContext';
import { TimeSlots } from '../../planning/children/TimeSlots';
import { HoursBar } from '../../../components/HoursBar/HoursBar';

/**
 * @component StaffRatioCard
 * @description Component that renders the staff ratio card. Used in the home page.
 * @returns {JSX} Component JSX
 */
export const StaffRatioCard = () => {
  const { t } = useTranslation();
  const {
    timeSlots,
    dateFormat,
    selectedDayStaffRatio,
    setSelectedDayStaffRatio,
    staffRatioCardLoading
  } = useHomeContext();

  return (
    <Card
      loading={staffRatioCardLoading}
      title={
        <Flex justify="space-between" align="baseline">
          <span>{t('home.titles.staff_ratio')}</span>
          <DatePicker
            allowClear={false}
            format={dateFormat}
            defaultValue={selectedDayStaffRatio}
            onChange={(value) => setSelectedDayStaffRatio(value)}
          />
        </Flex>
      }
      style={{ width: '100%' }}
    >
      <Row>
        <Col span={4} />
        <Col span={18}>
          <table>
            <HoursBar />
          </table>
        </Col>
      </Row>
      {timeSlots.map((slot) => (
        <TimeSlots
          key={slot?.isWalking}
          group={slot?.isWalking}
          slotEvents={slot?.slotEvents}
        />
      ))}
    </Card>
  );
};
