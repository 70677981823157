/**
 * Custom hook that organizes provided data into tables for analysis.
 *
 * @hook
 *
 * @param {Function} t - The translation function.
 * @param {Array} data - The array of data objects to be organized into tables.
 * @returns {Array} An array of objects representing tables, each containing a key, a divider title,
 * and a filtered dataSource based on specific criteria.
 */
export const useTables = (t, data) => [
  {
    key: '1',
    dividerTitle: t('analysis.cost_price'),
    dataSource: data.filter((item) => ['A', 'B', 'C'].includes(item.code))
  },
  {
    key: '2',
    dividerTitle: t('analysis.PSU_rate'),
    dataSource: data.filter(
      (item) =>
        ['D', 'E', 'F', 'G'].includes(item.code) ||
        item.label === 'Prix Plafond CNAF'
    )
  },
  {
    key: '3',
    dividerTitle: t('analysis.family_participation'),
    dataSource: data.filter((item) => item.code === 'H')
  },
  {
    key: '4',
    dividerTitle: t('analysis.PSU_right_calculation'),
    dataSource: data.filter((item) =>
      ['I', 'J', 'K', 'L', 'M', 'N', 'O'].includes(item.code)
    )
  },
  {
    key: '5',
    dividerTitle: t('analysis.concertation_places'),
    dataSource: data.filter((item) => ['P', 'Q', 'R', 'T'].includes(item.code))
  },
  {
    key: '6',
    dividerTitle: t('analysis.PSU_right_amount'),
    dataSource: data.filter((item) => item.code === 'U')
  },
  {
    key: '7',
    dividerTitle: t('analysis.social_mixity_bonus'),
    dataSource: data.filter((item) =>
      ['K / B', 'Montant / place', 'Montant bonus mixité sociale'].includes(
        item.label
      )
    )
  },
  {
    key: '8',
    dividerTitle: t('analysis.disability_bonus'),
    dataSource: data.filter((item) =>
      ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2'].includes(item.code)
    )
  },
  {
    key: '9',
    dividerTitle: t('analysis.territorial_bonus'),
    dataSource: data.filter((item) => ['H2', 'I2', 'J2'].includes(item.code))
  }
];
