/**
 * @description Get the peak hour for the day
 * @function getPeakHour
 * @param {dontWalkData} dontWalkData - array of objects with slotEvents
 * @param {walkData} walkData - array of objects with slotEvents
 * @returns {Object} peakHour
 */
const getPeakHourCombined = (dontWalkData, walkData) => {
  const combinedSlots = {};
  dontWalkData.forEach(({ slotEvents }) => {
    Object.entries(slotEvents).forEach(([hour, count]) => {
      combinedSlots[hour] = (combinedSlots[hour] || 0) + count;
    });
  });

  walkData.forEach(({ slotEvents }) => {
    Object.entries(slotEvents).forEach(([hour, count]) => {
      combinedSlots[hour] = (combinedSlots[hour] || 0) + count;
    });
  });
  let peakHour = { hour: null, count: 0 };
  Object.entries(combinedSlots).forEach(([hour, count]) => {
    if (count > peakHour.count) {
      peakHour = { hour, count };
    }
  });

  return peakHour;
};

/**
 * @description Get the stats for chlidren per day
 * @function getStats
 * @param {Array} dont_walk - array of objects with slotEvents
 * @param {Array} walk - array of objects with slotEvents
 * @returns {Object} stats
 */
export const getStats = (dont_walk, walk) => {
  if (dont_walk && walk) {
    const peakHourCombined = getPeakHourCombined(dont_walk, walk);

    const peakDontWalkAtPeakHour = dont_walk.reduce(
      (acc, { slotEvents }) => acc + (slotEvents[peakHourCombined.hour] || 0),
      0
    );
    const peakWalkAtPeakHour = walk.reduce(
      (acc, { slotEvents }) => acc + (slotEvents[peakHourCombined.hour] || 0),
      0
    );

    return { peakHourCombined, peakDontWalkAtPeakHour, peakWalkAtPeakHour };
  }
  return null;
};
