import PropTypes from 'prop-types';
import { Divider, Flex, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { TimeRangeBar } from '../../components/TimeRangeBar/TimeRangeBar';
import { useReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { HoursBar } from '../../components/HoursBar/HoursBar';

/**
 * ReferenceWeek
 * @description Component that handles the reference week and displays the time range bar for each day
 * @param {number} index - index of the week
 * @param {object} initialValues - initial values of the form
 * @param {string} purpose - purpose of the form
 * @returns {JSX.Element} ReferenceWeek
 */
export const ReferenceWeek = ({ index, initialValues, purpose }) => {
  const { t } = useTranslation();
  const {
    referenceWeeks,
    setReferenceWeeks,
    daycareOpeningDays,
    daycaresOpeningDays
  } = useReferenceWeekContext();

  const openingDays =
    daycareOpeningDays.length > 0 ? daycareOpeningDays : daycaresOpeningDays;

  const handleDeleteWeek = () => {
    const myReferenceWeeks = [...referenceWeeks];
    myReferenceWeeks.splice(index, 1);
    setReferenceWeeks(myReferenceWeeks);
  };

  const dividerText = `${t('pre-registrations.divider.reference_week')} ${
    index + 1
  }`;

  return (
    <div className="prereg-planning-wrapper">
      <Flex align="center" gap="small" wrap="nowrap">
        <Divider orientation="left">{dividerText}</Divider>
        {purpose !== null && referenceWeeks.length > 1 && (
          <Button
            icon={<DeleteOutlined style={{ color: 'var(--errorColor)' }} />}
            type="link"
            onClick={handleDeleteWeek}
          />
        )}
      </Flex>
      <table>
        <HoursBar />
        <tbody>
          {openingDays.map((day) => (
            <TimeRangeBar
              key={day.opening_day}
              day={day.opening_day}
              weekIndex={index}
              initialValues={initialValues}
              purpose={purpose}
              openingHour={day.morning_opening}
              closingHour={day.evening_closing}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
ReferenceWeek.propTypes = {
  index: PropTypes.number,
  purpose: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({})
};
ReferenceWeek.defaultProps = {
  index: 0,
  initialValues: {}
};
