import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Card, Form, Button, Flex, Checkbox } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useConversationContext } from '../../contexts/ConversationContext';

const { TextArea } = Input;

/**
 * Custom component for writing and seding a message or a note
 *
 * @component
 * @prop {string} url - The url to send the message or the note
 * @prop {boolean} refresh - Refresh the component
 * @prop {function} setRefresh - Set the refresh state
 * @prop {string} title - Title of the card
 * @prop {boolean} onlyForManagement - Only for management checkbox
 * @prop {string} type - Type of the data to send (message or note)
 * @returns {JSX.Element} - Custom component for writing and seding a message or a note
 */
export const MessageWriter = ({
  url,
  refresh,
  setRefresh,
  title,
  onlyForManagement,
  type
}) => {
  const { t } = useTranslation();
  const { user, dispatchAPI, daycare } = useAuthContext();
  const { socket } = useSocketContext();
  const { selectedConversation, refreshConversation, setRefreshConversation } =
    useConversationContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [management_only, setManagement_only] = useState(false);

  const onChange = (e) => setManagement_only(e.target.checked);

  const onFinish = async (values) => {
    try {
      const { _id: from } = user;
      const to = selectedConversation?.users?.find(
        (item) => item?._id !== from
      )?._id;
      const body = {
        ...values,
        from,
        management_only,
        daycare,
        ...(to && { to })
      };
      setLoading(true);
      await dispatchAPI('POST', { url, body });
      if (type === 'note') {
        setRefresh(!refresh);
      }
      if (type === 'message') {
        if (socket) socket.emit('newMessage', { to });
        setRefreshConversation(!refreshConversation);
      }
      form.resetFields();
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title={title} bordered={false} className="conversation-new-message">
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="content">
          <TextArea
            style={{ resize: 'none' }}
            placeholder={t('placeholder.write_a_message')}
            className="conversation-textarea"
          />
        </Form.Item>
        <Flex justify={onlyForManagement ? 'space-between' : 'flex-end'}>
          {onlyForManagement && (
            <Form.Item name="management_only">
              <Checkbox onChange={onChange}>
                {t('messages_and_notes.form.management_only')}
              </Checkbox>
            </Form.Item>
          )}
          <Button loading={loading} type="primary" htmlType="submit">
            {t('buttons.send')}
          </Button>
        </Flex>
      </Form>
    </Card>
  );
};

MessageWriter.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  title: PropTypes.string,
  onlyForManagement: PropTypes.bool
};

MessageWriter.defaultProps = {
  refresh: false,
  setRefresh: () => {},
  title: '',
  onlyForManagement: false
};
