import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 * @param {function} handleModalBehaviour - A function to handle modals.
 * @param {boolean} refresh - A boolean value indicating whether to force refresh the table.
 * @param {function} setRefresh - A function to set the forceRefresh value.
 *
 * @returns {Array} columns for the datatable
 */
export const useColumns = (handleModalBehaviour, refresh, setRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/daycares/${daycare}/closure_period/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t('daycares.form.title'),
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: t('daycares.form.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      sorter: true,
      render: (start_date) =>
        start_date ? dayjs(start_date).format('DD/MM/YYYY') : '-'
    },
    {
      title: t('daycares.form.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      sorter: true,
      render: (end_date) =>
        end_date ? dayjs(end_date).format('DD/MM/YYYY') : '-'
    },
    ...(user.role !== 'users:MANAGER'
      ? [
          {
            width: 50,
            render: (record) => (
              <>
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => handleModalBehaviour(record, 'edit')}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => deleteResource(record._id, 'edit')}
                >
                  <DeleteOutlined style={{ fontSize: iconSize }} />
                </Button>
              </>
            )
          }
        ]
      : [])
  ];
};
