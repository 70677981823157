import { Radio, Switch, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * @description
 * useDietFields is a hook that returns the fields to display in the diet form
 * @returns {Object} fields to display in the diet form
 */
export const useDietFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [dietEnums, setDietEnums] = useState({});

  const getDietEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/diets/enums'
      });
      setDietEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    await getDietEnums();
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const dietFields = [
    {
      name: ['diet', 'meal_type'],
      rules: [{ required: true }],
      input: (
        <Radio.Group>
          <Space direction="vertical">
            {(dietEnums.meal_types || []).map((meal_type) => (
              <Radio key={meal_type} value={meal_type}>
                {t(`diets.enums.meal_types.${meal_type}`)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )
    },
    {
      name: ['diet', 'vegetarian'],
      rules: [{ required: false }],
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    },
    {
      name: ['diet', 'special_meat_diet'],
      rules: [{ required: false }],
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    }
  ];
  return { dietFields };
};
