import { useTranslation } from 'react-i18next';
import { Input, Switch } from 'antd';
import { handleDepartment } from '../../../../../utils/handleDepartment';

/**
 * @hook useFields
 * @description Hook to get fields for the form
 * @param {object} form - Form instance.
 *
 * @returns {Array} fields
 */
export const useFields = (form) => {
  const { t } = useTranslation();

  return [
    {
      name: ['is_principal_address'],
      valuePropName: 'checked',
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    },
    {
      label: 'billing_address.address_label',
      name: ['address_label'],
      rules: [{ required: true }]
    },
    {
      label: 'billing_address.number',
      name: ['number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'billing_address.street',
      rules: [{ required: true }],
      name: ['street']
    },
    {
      label: 'billing_address.additional',
      name: ['additional']
    },
    {
      label: 'billing_address.postal_code',
      rules: [{ required: true }],
      name: ['postal_code'],
      input: (
        <Input
          type="number"
          onChange={(e) => handleDepartment(e, form, ['state'])}
        />
      )
    },
    {
      label: 'billing_address.city',
      rules: [{ required: true }],
      name: ['city']
    },
    {
      label: 'billing_address.state',
      rules: [{ required: true }],
      name: ['state']
    }
  ];
};
