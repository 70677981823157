import { useTranslation } from 'react-i18next';
import { Tag, Button, Divider, Popconfirm, Popover } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  EditOutlined,
  WarningOutlined,
  DeleteOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import {
  paymentStatuses,
  paymentTypes
} from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const iconSize = 18;

/**
 * @hook useColumns
 * @description Returns columns for payments list
 * @param {Object} enums - enums object
 * @param {Function} onEditAction - edit action
 * @param {Function} onDeleteAction - delete action
 * @param {Function} openDetailsModal - modal to showDetails
 * @returns {Array} columns
 */
export const useColumns = (
  enums,
  onEditAction,
  onDeleteAction,
  openDetailsModal
) => {
  const { t } = useTranslation();

  return [
    {
      title: t('payments.list.number'),
      key: 'number',
      dataIndex: 'number',
      render: (number) => number || ''
    },
    {
      title: t('payments.list.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={paymentTypes[type]}>{t(`payments.tags.${type}`)}</Tag>
      ),
      filters: enums?.types?.map((type) => ({
        text: t(`payments.tags.${type}`),
        value: type
      }))
    },
    {
      title: t('payments.list.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={paymentStatuses[status]}>
          {t(`payments.tags.${status}`)}
        </Tag>
      ),
      filters: enums?.status?.map((status) => ({
        text: t(`payments.tags.${status}`),
        value: status
      }))
    },
    {
      title: t('payments.list.reason'),
      key: 'reason',
      dataIndex: 'reason',
      render: (reason) => (
        <Tag color={paymentStatuses[reason]}>
          {t(`payments.tags.reasons.${reason || 'CLASSIC'}`)}
        </Tag>
      ),
      filters: enums?.reasons?.map((reason) => ({
        text: t(`payments.tags.reasons.${reason}`),
        value: reason
      }))
    },
    {
      title: t('payments.list.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => (amount ? `${amount.toFixed(2)} € ` : '0.00 €')
    },
    {
      title: t('payments.list.amount_allocated'),
      key: 'amount_allocated',
      dataIndex: 'amount_allocated',
      render: (amount_allocated) =>
        amount_allocated ? `${amount_allocated.toFixed(2)} €` : '0.00 €'
    },
    {
      title: t('payments.list.operation_date'),
      key: 'operation_date',
      dataIndex: 'operation_date',
      render: (operation_date) =>
        operation_date && dayjs(operation_date).format('DD/MM/YYYY')
    },
    {
      title: t('payments.list.payment_method'),
      key: 'payment_method',
      dataIndex: 'payment_method',
      render: (payment_method) =>
        payment_method && t(`payments.enums.payment_methods.${payment_method}`)
    },
    {
      title: t('payments.list.label'),
      key: 'label',
      dataIndex: 'label',
      render: (label) => label || '-'
    },
    {
      title: t('payments.list.customer_account'),
      key: 'customer_account',
      dataIndex: 'customer_account',
      render: (customer_account) =>
        (customer_account && customer_account?.label) || '-'
    },
    {
      title: t('payments.list.invoice'),
      key: 'invoice',
      dataIndex: 'invoice',
      render: (invoice) => (
        <Link
          to={{
            pathname: `${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/show/${invoice?._id}`
          }}
        >
          {(invoice && invoice?.number) || ''}
        </Link>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {record.reason === 'REGUL' && (
            <>
              <Popover content={t('buttons.show_credit')} placement="top">
                <Button
                  type="ghost"
                  icon={<InfoCircleOutlined style={{ fontSize: iconSize }} />}
                  onClick={() => openDetailsModal(record)}
                />
              </Popover>
              <Divider type="vertical" />
            </>
          )}
          {!['RECONCILIED', 'REJECTED'].includes(record?.status) && (
            <>
              <Button
                type="ghost"
                onClick={() => onEditAction(record._id)}
                icon={<EditOutlined style={{ fontSize: iconSize }} />}
              />
              <Divider type="vertical" />
            </>
          )}
          <Popconfirm
            title={t('datatable.column.action.yeet.title')}
            okText={t('datatable.column.action.yeet.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.yeet.cancel')}
            onConfirm={() => onDeleteAction(record._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
