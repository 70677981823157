import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useTabContent } from './useTabContent';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useConfigurationContext } from './ConfigurationContext';

/**
 * Configuration page
 * @component
 * @returns {JSX.Element} The component
 */
export const Configuration = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { activeKey, setActiveKey } = useConfigurationContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { tabContent } = useTabContent(data, isLoading, refresh, setRefresh);

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await dispatchAPI('GET', {
        url: `/daycares/${daycare}`
      });
      setData(response.data);
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (daycare) {
      (async () => {
        await getData();
      })();
    }
  }, [daycare, refresh]);

  const title = t(`configurations.title`, { daycare: data?.name });

  return (
    <>
      <PageHeaderCustom title={title} />
      <ContentCustom>
        <Tabs
          defaultActiveKey="general"
          items={tabContent}
          type="card"
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        />
      </ContentCustom>
    </>
  );
};
