import React, { useState, createContext, useContext } from 'react';

export const ConfigurationContext = createContext();

/**
 * Provider component for the ConfigurationContext.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} ConfigurationContextProvider component.
 */
export const ConfigurationContextProvider = ({ children }) => {
  const [activeKey, setActiveKey] = useState('general');

  return (
    <ConfigurationContext.Provider
      value={{
        activeKey,
        setActiveKey
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

/**
 * A custom hook for accessing the ConfigurationContext.
 *
 * @hook
 *
 * @returns {Object} The context values for managing configuration state.
 */
export const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
