import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

/**
 * Renders a custom navigate back button component.
 * It is used in the CheckoutForm and Return components.
 *
 * @component
 * @returns {JSX.Element|null} The navigate back button component or null if the query parameter is not present.
 */
export const NavigateBackButton = () => {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const withNavigateBackButton = urlParams.get('withNavigateBackButton');

  return (
    withNavigateBackButton === 'true' && (
      <Link to="/invoices">
        <Button block style={{ marginTop: '8px' }}>
          <ArrowLeftOutlined />
          {t('buttons.go_back_to_invoices')}
        </Button>
      </Link>
    )
  );
};
