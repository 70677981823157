import { Route, Routes } from 'react-router-dom';
import { CreditCardPaymentsRouter } from './credit-card-payment/CreditCardPaymentsRouter';
import { Exception } from '../../components';

/**
 * Renders the FollowUpRouter component.
 * This component handles the routing for the follow-up features.
 *
 * @returns {JSX.Element} The rendered FollowUpRouter component.
 */
export const FollowUpRouter = () => (
  <Routes>
    <Route
      path="/credit-card-payments/*"
      element={<CreditCardPaymentsRouter />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
