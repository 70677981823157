import { Button, Flex, List, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { upperCaseFirstCharacter } from '../News/utils';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useHandleResize } from '../../../../utils/handleResize';

/**
 * Renders a single event card within a list.
 *
 * This component displays an event with its thumbnail, title, visibility tags, group tags, and date and time details.
 * It provides buttons to edit the event or navigate to a detailed view. The visibility and group tags are displayed using different colors.
 * It uses the Ant Design's `List.Item` and `Tag` components for layout and styling.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.event - The event data to display.
 * @param {Function} props.handleModal - Function to handle the opening of the modal for editing the event.
 * @returns {JSX.Element} The rendered event card component.
 */
export const EventCard = ({ event, handleModal }) => {
  const { t } = useTranslation();
  const { token, user } = useAuthContext();
  const navigate = useNavigate();
  const { width } = useHandleResize();

  const userBoolean = ['guests:PARENT', 'users:TABLET'].includes(user?.role);

  const handleNavigateToShow = () =>
    navigate(`${routes.COMMUNICATION}/show/events/${event?._id}`);

  return (
    <List.Item
      className="event-list-item"
      actions={[
        <>
          <Button type="link" onClick={handleNavigateToShow}>
            <EyeOutlined />
          </Button>
          {!userBoolean && (
            <Button type="link" onClick={() => handleModal(event, 'edit')}>
              <EditOutlined />
            </Button>
          )}
        </>
      ].filter(Boolean)}
      onClick={userBoolean ? handleNavigateToShow : undefined}
      style={{ cursor: userBoolean ? 'pointer' : 'default' }}
      extra={
        event?.thumbnail && (
          <img
            src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${event?.thumbnail?._id}/${token}`}
            alt="thumbnail-icon"
            style={{ width: 100 }}
          />
        )
      }
    >
      <Flex
        gap={width < 576 ? 'none' : 'large'}
        align={width < 576 ? 'flex-start' : 'center'}
        vertical={width < 576}
      >
        <Flex
          vertical={width > 575}
          gap={width < 576 ? 'small' : 'none'}
          className="event-time-wrapper"
        >
          <p className="event-day">{`${upperCaseFirstCharacter(
            dayjs(event?.date).format('dddd')
          )}`}</p>
          <p className="event-date">{`${dayjs(event?.date).format(
            'D MMMM'
          )}`}</p>
          <span className="event-time">
            {event?.hours?.[0] && dayjs(event.hours[0]).format('HH:mm')}
            {event?.hours?.[1] && ` - ${dayjs(event.hours[1]).format('HH:mm')}`}
          </span>
        </Flex>
        <List.Item.Meta
          title={event?.title}
          description={
            <Flex justify="space-between" gap="middle" wrap="wrap">
              {!userBoolean && (
                <Flex gap="small" wrap="wrap">
                  {(event?.visibility || []).map((item) => (
                    <Tag color={visibilitiesColors[item]} key={item}>
                      {t(`communication.news.list.type.${item}`)}
                    </Tag>
                  ))}
                </Flex>
              )}
              <Flex gap="small" wrap="wrap">
                {(event?.groups || []).map((group) => (
                  <Tag key={group._id} color={group.tag_color}>
                    {group.label}
                  </Tag>
                ))}
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </List.Item>
  );
};

EventCard.propTypes = {
  event: PropTypes.shape({
    thumbnail: PropTypes.shape({
      _id: PropTypes.string
    }),
    hours: PropTypes.arrayOf(PropTypes.string),
    visibility: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    title: PropTypes.string,
    _id: PropTypes.string
  }),
  handleModal: PropTypes.func.isRequired
};

EventCard.defaultProps = {
  event: null
};
