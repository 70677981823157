import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { formatNumberWithSpaces } from '../../../../utils/formatter';

const iconSize = 18;

/**
 * Generates columns configuration for a table displaying financial data, either in a main view or in detail view.
 *
 * This function creates a set of column configurations for displaying financial data related to accounting accounts. It supports both a main view, where data is aggregated, and a detailed view for specific accounts. Each column can be configured to display data for each month of a given year, and the columns for the detailed view can be made editable.
 *
 * @hook
 *
 * @param {string} year - The year for which the financial data is displayed.
 * @param {string} origin - Indicates whether the columns are for the main view ('main') or detail view ('detail').
 * @param {Function} deleteRow - Function to delete table row.
 * @returns {Object[]} An array of objects, each representing a column configuration for the table.
 */
export const useColumns = (year, origin, deleteRow) => {
  const { t } = useTranslation();

  return [
    {
      title: t('analysis.table.accounting_account'),
      key: 'compte',
      dataIndex: 'compte'
    },
    {
      title: t('analysis.table.label'),
      key: origin === 'main' ? 'label' : 'intitulé',
      dataIndex: origin === 'main' ? 'label' : 'intitulé',
      render: (label) =>
        origin === 'main' ? t(`analysis.table.${label}`) : label
    },
    ...(origin === 'main'
      ? [
          {
            title: year,
            children: [
              {
                title: t('analysis.table.JANUARY'),
                key: 'janvier',
                dataIndex: ['totals', 'janvier'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.FEBRUARY'),
                key: 'février',
                dataIndex: ['totals', 'février'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.MARCH'),
                key: 'mars',
                dataIndex: ['totals', 'mars'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.APRIL'),
                key: 'avril',
                dataIndex: ['totals', 'avril'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.MAY'),
                key: 'mai',
                dataIndex: ['totals', 'mai'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.JUNE'),
                key: 'juin',
                dataIndex: ['totals', 'juin'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.JULY'),
                key: 'juillet',
                dataIndex: ['totals', 'juillet'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.AUGUST'),
                key: 'août',
                dataIndex: ['totals', 'août'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.SEPTEMBER'),
                key: 'septembre',
                dataIndex: ['totals', 'septembre'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.OCTOBER'),
                key: 'octobre',
                dataIndex: ['totals', 'octobre'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.NOVEMBER'),
                key: 'novembre',
                dataIndex: ['totals', 'novembre'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              },
              {
                title: t('analysis.table.DECEMBER'),
                key: 'décembre',
                dataIndex: ['totals', 'décembre'],
                render: (total) =>
                  (total && `${formatNumberWithSpaces(total)}€`) || ''
              }
            ]
          }
        ]
      : [
          {
            title: t('analysis.table.JANUARY'),
            key: 'janvier',
            dataIndex: 'janvier',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.FEBRUARY'),
            key: 'février',
            dataIndex: 'février',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.MARCH'),
            key: 'mars',
            dataIndex: 'mars',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.APRIL'),
            key: 'avril',
            dataIndex: 'avril',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.MAY'),
            key: 'mai',
            dataIndex: 'mai',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.JUNE'),
            key: 'juin',
            dataIndex: 'juin',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.JULY'),
            key: 'juillet',
            dataIndex: 'juillet',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.AUGUST'),
            key: 'août',
            dataIndex: 'août',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.SEPTEMBER'),
            key: 'septembre',
            dataIndex: 'septembre',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.OCTOBER'),
            key: 'octobre',
            dataIndex: 'octobre',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.NOVEMBER'),
            key: 'novembre',
            dataIndex: 'novembre',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            title: t('analysis.table.DECEMBER'),
            key: 'décembre',
            dataIndex: 'décembre',
            render: (total) =>
              (total && `${formatNumberWithSpaces(total)}€`) || '',
            editable: true,
            suffix: '€'
          },
          {
            render: (record) => (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteRow(record)}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            )
          }
        ])
  ];
};
