import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Flex, Dropdown, Input, Switch } from 'antd';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';

const { Search } = Input;

/**
 * A component for listing resources in a data table with various options and actions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.resourceName - The name of the resource being listed.
 * @param {string} [props.tradKey] - The translation key (optional).
 * @param {string} [props.dataToFetch] - The data to fetch (optional).
 * @param {Array<Object>} props.columns - The columns configuration for the data table.
 * @param {boolean} [props.customActionColumn] - Indicates whether to use a custom action column (optional).
 * @param {Array<Object>} [props.headers] - The headers configuration (optional).
 * @param {string} [props.extraQuery] - An extra query string for API requests (optional).
 * @param {element} [props.extraHeader] - Additional header content (optional).
 * @param {element} [props.extraButtons] - Additional buttons (optional).
 * @param {string} [props.exportUrl] - The URL for exporting data (optional).
 * @param {string} [props.populate] - The populate query string (optional).
 * @param {boolean|Object} [props.withCreateButton] - Indicates whether to display a create button (optional).
 * @param {boolean} [props.extraFilters] - Additional filters (optional).
 * @param {boolean|Object} [props.withImportButton] - Indicates whether to display an import button (optional).
 * @param {boolean} [props.withUploadButton] - Indicates whether to display an upload button (optional).
 * @param {boolean} [props.withPageHeader] - Indicates whether to display a page header (optional).
 * @param {boolean} [props.withSearchBar] - Indicates whether to display a search bar (optional).
 * @param {boolean} [props.forceRefresh] - Indicates whether to force a data refresh (optional).
 * @param {string} [props.resourceModelName] - The name of the resource model (optional).
 * @param {boolean} [props.archivedButton] - Indicates whether to display an archived button (optional).
 * @param {boolean} [props.archiveAction] - Indicates whether to enable archive action (optional).
 * @param {boolean|Object} [props.editAction] - Indicates whether to enable edit action (optional).
 * @param {boolean|Object} [props.duplicateAction] - Indicates whether to enable duplicate action (optional).
 * @param {boolean|Object} [props.printAction] - Indicates whether to enable print action (optional).
 * @param {boolean|Object} [props.showAction] - Indicates whether to enable show action (optional).
 * @param {boolean} [props.deleteAction] - Indicates whether to enable delete action (optional).
 * @param {boolean} [props.customLoading] - Indicates whether to use custom loading (optional).
 * @param {boolean|Object} [props.onDoubleClickAction] - Indicates whether to enable double-click action (optional).
 * @param {Object} [props.scroll] - The scroll configuration for the data table (optional).
 * @param {Object} [props.expandable] - The expandable configuration for the data table (optional).
 * @param {string} [props.path] - The path for navigation (optional).
 * @param {string} [props.rowKey] - The key for table rows (optional).
 * @param {function} [props.formatter] - A function for formatting data (optional).
 * @param {boolean} [props.openModal] - Indicates whether to open a modal (optional).
 * @param {function} [props.setIsModalOpen] - A function to set the modal's open state (optional).
 * @param {function} [props.setPurpose] - A function to set the purpose (optional).
 * @param {function} [props.setRecord] - A function to set the record (optional).
 * @returns {JSX.Element} The rendered component.
 */
export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraFilters,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withoutBreadCrums,
  withSearchBar,
  forceRefresh,
  setRefresh,
  resourceModelName,
  editAction,
  showAction,
  archiveAction,
  duplicateAction,
  archivedButton,
  printAction,
  deleteAction,
  customLoading,
  onDoubleClickAction,
  scroll,
  expandable,
  withPagination,
  path,
  rowKey,
  formatter,
  openModal,
  setIsModalOpen,
  setPurpose,
  setRecord,
  customDeletePath,
  withImportButton,
  showModal,
  customExportUrl
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [isArchived, setIsArchived] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (extraQuery) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}${
          searchValue ? `&k=${searchValue}` : ''
        }`
      });
    }
  }, [extraQuery]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export_csv',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                  customExportUrl={customExportUrl}
                  format="csv"
                />
              )
            },
            {
              key: 'export_xlsx',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                  customExportUrl={customExportUrl}
                  format="xlsx"
                />
              )
            }
          ]
        : []),
      ...(withImportButton
        ? [
            {
              key: 'import',
              label: <ImportButton resourceName={resourceModelName} />
            }
          ]
        : [])
    ]
  };

  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
          withoutBreadCrums={withoutBreadCrums}
        />
      )}
      <ContentCustom>
        <Flex vertical gap="small">
          {extraFilters && (
            <Flex align="center" gap="small">
              {extraFilters}
            </Flex>
          )}
          <Flex
            justify={withSearchBar ? 'space-between' : 'end'}
            align="center"
            gap="small"
            wrap="wrap"
          >
            {withSearchBar && (
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
                style={{ maxWidth: 240 }}
              />
            )}
            {extraButtons}
            {!withCreateButton && archivedButton && (
              <Switch
                checkedChildren={t('buttons.archived')}
                unCheckedChildren={t('buttons.archived')}
                onChange={() => setIsArchived(!isArchived)}
              />
            )}
            {!withCreateButton && headers && (
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            )}
            {withCreateButton && (
              <Flex align="center" gap="small">
                {archivedButton && (
                  <Switch
                    checkedChildren={t('buttons.archived')}
                    unCheckedChildren={t('buttons.archived')}
                    onChange={() => setIsArchived(!isArchived)}
                  />
                )}
                {openModal ? (
                  <Button
                    loading={customLoading}
                    type="primary"
                    onClick={showModal}
                  >
                    {withCreateButton?.buttonText || `${t('buttons.create')}`}
                    &nbsp;
                    {withCreateButton?.buttonIcon || <PlusOutlined />}
                  </Button>
                ) : (
                  <Link to={withCreateButton?.path || `${pathname}/create`}>
                    <Button type="primary">
                      {withCreateButton?.buttonText || `${t('buttons.create')}`}
                      {withCreateButton?.buttonIcon || <PlusOutlined />}
                    </Button>
                  </Link>
                )}
                {withUploadButton && (
                  <Dropdown menu={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex align="center" gap="small">
          {children}
        </Flex>
        <Datatable
          style={{ marginTop: 20 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          setRefresh={setRefresh}
          editAction={editAction}
          showAction={showAction}
          withPagination={withPagination}
          archiveAction={archiveAction}
          isArchived={isArchived}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          customDeletePath={customDeletePath}
          openModal={openModal}
          setPurpose={setPurpose}
          setIsModalOpen={setIsModalOpen}
          setRecord={setRecord}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  withPagination: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  extraFilters: PropTypes.element,
  withoutBreadCrums: PropTypes.bool,
  exportUrl: PropTypes.string,
  customDeletePath: PropTypes.string,
  populate: PropTypes.string,
  customLoading: PropTypes.bool,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  withImportButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  resourceModelName: PropTypes.string,
  archivedButton: PropTypes.bool,
  archiveAction: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  openModal: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setPurpose: PropTypes.func,
  setRecord: PropTypes.func,
  showModal: PropTypes.func,
  customExportUrl: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  customLoading: false,
  extraHeader: null,
  extraButtons: null,
  extraFilters: null,
  withoutBreadCrums: false,
  exportUrl: null,
  archivedButton: true,
  populate: null,
  customActionColumn: false,
  withPagination: true,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  withImportButton: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  customDeletePath: null,
  archiveAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: false,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  openModal: false,
  setIsModalOpen: () => {},
  setPurpose: () => {},
  setRecord: () => {},
  setRefresh: () => {},
  showModal: () => {},
  customExportUrl: false
};
