import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import { useCards } from './useCards';
import { GraphCard } from '../GraphCard';
import { useHandleResize } from '../../../../utils/handleResize';

/**
 * Displays a set of data visualization cards for financial and operational indicators.
 *
 * This component aggregates and displays multiple `GraphCard` components and other custom data visualization components
 * (`OperatingIncomeEvolution`, `OperatingExpenses`, `OperatingResults`). Each card shows a specific aspect of the
 * operational or financial data, such as carried out hours, billed hours, occupation rate, operating income evolution,
 * operating expenses, and operating results. The cards are laid out in a grid using Ant Design's `Row` component.
 *
 * @component
 * @returns {JSX.Element} A row layout filled with various data visualization cards.
 */
export const Indicators = () => {
  const { t } = useTranslation();
  const { graphsData } = useAnalysisContext();
  const { width } = useHandleResize();

  const cards = useCards(t, graphsData);

  return (
    <Row gutter={[8, 8]}>
      {cards.map(({ title, spanSize, datas, key }) => (
        <GraphCard
          title={title}
          spanSize={width > 1599 ? spanSize : 24}
          datas={datas}
          key={key}
        />
      ))}
    </Row>
  );
};
