import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

export const CreateUpdateParent = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { message } = useErrorMessage();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        user: data.user,
        ...data.user,
        mobile_phone_number: data.mobile_phone_number
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        const userFields = {
          first_name: data.first_name,
          last_name: data.last_name
        };

        const parentFields = {
          ...data,
          user: userFields
        };

        return parentFields;
      }
    }
  };

  const updateResource = async (body) => {
    try {
      const userFields = {
        first_name: body.first_name,
        last_name: body.last_name
      };

      const parentFields = {
        ...body,
        user: userFields
      };

      await dispatchAPI('PATCH', {
        url: `parents/${id}?populate=user`,
        body:
          config.onUpdateResource && config.onUpdateResource.setBody
            ? config.onUpdateResource.setBody(parentFields)
            : parentFields
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="parents"
      populate="user"
      resource="parents"
      config={config}
      customSubmit={updateResource}
    />
  );
};

CreateUpdateParent.propTypes = {
  purpose: PropTypes.string.isRequired,
  record: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.shape({})
  })
};

CreateUpdateParent.defaultProps = {
  record: {
    _id: '',
    user: {}
  }
};
