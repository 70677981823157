import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { PreRegistrationsMethods } from '../../methods';
import { preRegistrationStatus } from '../../../../utils/constants/tagColors';

/**
 * @description
 * useListContent is a hook that returns the content of the pre-registration card.
 * It is used in the PreRegistrationCard component.
 *
 * @param {Object} data - The data of the pre-registration
 * @returns {Array} The content of the pre-registration card
 */
export const useListContent = (data = {}) => {
  const { created_at, daycares } = data;
  const { t } = useTranslation();
  const { getStatusByDaycare } = PreRegistrationsMethods();
  const status = getStatusByDaycare(daycares);

  return [
    {
      label: 'pre-registrations.show.created_at',
      content: created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.status',
      content: status ? (
        <Tag color={preRegistrationStatus[status]}>
          {t(`pre-registrations.form.status.${status}`)}
        </Tag>
      ) : (
        '-'
      ),
      span: 1
    }
  ];
};
