import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Layout } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { SuccessCheckout } from './Success';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import mainBg from '../../assets/images/mainBg.png';
import packageJson from '../../../package.json';

const { Footer } = Layout;

const BgLayout = styled(Layout)`
  background-image: url(${mainBg});
  background-size: cover;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden auto;
  display: flex;
  padding: 24px;
  align-items: center;
  text-align: center;
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  background: transparent;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;

  a {
    color: #fff !important;
    &:hover {
      color: var(--logoYellow) !important;
    }
  }
`;

/**
 * Component for handling the return page after a checkout payment.
 *
 * @component
 * @returns {JSX.Element|null} The JSX element representing the return page.
 */
export const Return = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [session, setSession] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  const origin = urlParams.get('origin');

  const getCheckoutSession = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/checkout-payment/session-status',
        body: { sessionId }
      });

      setSession(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getCheckoutSession();
    })();
  }, []);

  let contentToReturn = null;

  if (session) {
    if (session.status === 'complete') {
      contentToReturn = <SuccessCheckout session={session} />;
    } else if (session.status === 'open') {
      contentToReturn = <Navigate to="/" />;
    }
  } else {
    contentToReturn = <CustomSpin />;
  }

  if (origin === 'email') {
    return (
      <BgLayout>
        {contentToReturn}
        <StyledFooter as={Footer}>
          {`${t('global.title')} v${
            packageJson.version
          } ©${dayjs().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </BgLayout>
    );
  }
  return contentToReturn;
};
