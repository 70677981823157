import PropTypes from 'prop-types';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useLabelColorColumns } from '../useLabelColorColumns';

/**
 * TeamPositionsTable
 * @description Component for team positions table, displayed in global settings
 * @component
 * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {component} TeamPositionsTable
 */
export const TeamPositionsTable = ({ onModalOpen, setRefresh, refresh }) => {
  const { t } = useTranslation();
  const columns = useLabelColorColumns(
    setRefresh,
    onModalOpen,
    'team-positions'
  );

  return (
    <>
      <Flex justify="end">
        <Button
          onClick={() => onModalOpen({}, 'create', 'team-positions')}
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <ListResource
        resourceName="team-positions"
        columns={columns}
        resourceModelName="Team-position"
        withPageHeader={false}
        archivedButton={false}
        showAction={false}
        onDoubleClickAction={false}
        withUploadButton={false}
        openModal
        withCreateButton={false}
        customActionColumn
        forceRefresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

TeamPositionsTable.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool
};

TeamPositionsTable.defaultProps = {
  refresh: false
};
