import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/parentRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { ParentTheme } from '../assets/styles/Theme/ParentTheme';
import { MessagesAndNotesRouter } from '../routes/messages-and-notes/MessagesAndNotesRouter';
import { ChildrenFollowUp } from '../routes/children-follow-up/ChildrenFollowUp';
import { ReferenceWeekProvider } from '../contexts/ReferenceWeekContext';
import { CreateUpdatePreRegistration } from '../routes/pre-registrations/CreateUpdatePreRegistration';
import { RequireAuth } from './RequireAuth';
import PostPwdForm from '../routes/login/PostPwdForm';
import { FollowUpContextProvider } from '../contexts/FollowUpContext';
import { ParentHome } from '../routes/home/parentAccess/ParentHome';
import { CommunicationRouter } from './CommunicationRouter';
import { ParentInvoices } from '../routes/invoicing/invoices/ParentInvoices';
import { InvoicingContextProvider } from '../routes/invoicing/InvoicingContext';
import { ParentDocuments } from '../routes/children/ParentDocuments';
import { CheckoutPaymentRouter } from '../routes/checkout-payment/CheckoutPaymentRouter';
import { CommunicationContextProvider } from '../contexts/CommunicationContext';

/**
 * `ParentRouter` is a React component that defines the routing structure for the application.
 * It uses `BrowserRouter` and `Routes` from `react-router-dom` to set up navigation paths
 * and render different components based on the current URL path. It also provides certain
 * global setups like applying the user theme and managing body class for styling purposes.
 *
 * @returns {JSX.Element} The JSX for the router setup, including routes for login, confirmation,
 * profile, planning, and other features of the application.
 */
export const ParentRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'parent';
    }
  }, []);

  return (
    <BrowserRouter>
      <ParentTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route
          path={`${outOfNavRoutes.PRE_REGISTRATION}/create/*`}
          element={
            <ReferenceWeekProvider>
              <CreateUpdatePreRegistration purpose="create" />
            </ReferenceWeekProvider>
          }
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${routes.PLANNING}/*`} element={<ParentHome />} />
          <Route path="/" element={<ParentHome />} />
          <Route
            path={`${routes.CHILDREN_FOLLOW_UP}`}
            element={
              <FollowUpContextProvider>
                <ChildrenFollowUp />
              </FollowUpContextProvider>
            }
          />
          <Route
            path={`${routes.COMMUNICATION}/*`}
            element={
              <CommunicationContextProvider>
                <CommunicationRouter />
              </CommunicationContextProvider>
            }
          />
          <Route
            path={`${routes.MESSAGES_AND_NOTES}/*`}
            element={<MessagesAndNotesRouter />}
          />
          <Route
            path={`${routes.INVOICES}/*`}
            element={
              <InvoicingContextProvider>
                <ParentInvoices />
              </InvoicingContextProvider>
            }
          />
          <Route
            path={`${outOfNavRoutes.CHECKOUT_PAYMENT}/*`}
            element={<CheckoutPaymentRouter />}
          />
          <Route path={`${routes.DOCUMENTS}/*`} element={<ParentDocuments />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
