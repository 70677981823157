import { Form, Input, Switch, Flex, Button, Card } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useBankAccountFields = () => {
  const { t } = useTranslation();

  const bankAccountFields = [
    {
      noLabel: true,
      input: (
        <Form.List name={['bank_accounts']}>
          {(fields, { add, remove }) => (
            <Flex gap="small" align="center" wrap="wrap">
              {fields.map((field) => (
                <Card key={field.key} className="form-list-card">
                  <Button
                    type="link"
                    onClick={() => remove(field.name)}
                    style={{ position: 'absolute', top: 8, right: 0 }}
                  >
                    <CloseOutlined />
                  </Button>
                  <Form.Item
                    {...field}
                    valuePropName="checked"
                    label={t(
                      'configurations.invoicing.form.bank_account.is_principal_account'
                    )}
                    name={[field.name, 'is_principal_account']}
                    rules={[{ required: true }]}
                  >
                    <Switch
                      unCheckedChildren={t('no')}
                      checkedChildren={t('yes')}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.bank_name'
                    )}
                    name={[field.name, 'bank_name']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.account_owner'
                    )}
                    name={[field.name, 'account_owner']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.bank_iban'
                    )}
                    name={[field.name, 'iban']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.bank_bic'
                    )}
                    name={[field.name, 'bic']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.creditor_id'
                    )}
                    name={[field.name, 'creditor_id']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.bank_account.payment_term'
                    )}
                    name={[field.name, 'payment_term']}
                  >
                    <Input type="number" addonAfter={t('days')} />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t(
                    'configurations.invoicing.form.bank_account.add_bank_account'
                  )}
                </Button>
              </Form.Item>
            </Flex>
          )}
        </Form.List>
      )
    }
  ];

  return bankAccountFields;
};
