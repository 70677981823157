import PropTypes from 'prop-types';
import { Row, Col, Flex } from 'antd';
import { ContractInformationsCard } from './ContractInformations/ContractInformationsCard';
import { AuthorizationsCard } from './Authorizations/AuthorizationsCard';
import { DietCard } from './Diet/DietCard';
import { DocumentsCard } from './DocumentsCard/DocumentsCard';
import { BankInformationsCard } from './BankInformationsCard/BankInformationsCard';
import { HealthCard } from './Health/HealthCard';

/**
 * Renders the ContractInfos component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the component.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered ContractInfos component.
 */
export const ContractInfos = ({ data, isLoading }) => (
  <Row gutter={[16, 16]}>
    <Col xs={24} xl={12}>
      <Flex vertical gap="middle">
        <ContractInformationsCard isLoading={isLoading} data={data} />
        <AuthorizationsCard isLoading={isLoading} data={data} />
        <HealthCard isLoading={isLoading} data={data} />
      </Flex>
    </Col>
    <Col xs={24} xl={12}>
      <Flex vertical gap="middle">
        <DocumentsCard isLoading={isLoading} data={data} />
        <BankInformationsCard isLoading={isLoading} data={data} />
        <DietCard isLoading={isLoading} data={data} />
      </Flex>
    </Col>
  </Row>
);

ContractInfos.propTypes = {
  data: PropTypes.shape({}),
  isLoading: PropTypes.bool
};

ContractInfos.defaultProps = {
  data: {},
  isLoading: false
};
