import { Checkbox, DatePicker, Input, Tag, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { drawerFormLayout } from '../../../utils/constants/formLayout';
import { visibilitiesColors } from '../../../utils/constants/tagColors';

/**
 * A form component for creating and editing information-related content.
 *
 * This component presents a form for users to input or edit information content,
 * such as news or announcements. It includes fields for setting the visibility
 * duration, choosing visibility options, selecting a topic, and entering the
 * content title and details. The content details are edited using a rich text
 * editor provided by ReactQuill.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.enums - Enumerations for the form, including visibility and topic options.
 * @param {Object} props.record - The existing data record for editing mode, containing the content to be edited.
 * @param {Object} props.form - Form instance.
 * @returns {JSX.Element} A form component for creating or editing information content.
 */
export const InfosForm = ({ enums, record, form }) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState('');

  useEffect(() => {
    if (record?.content) {
      setEditorState(record.content);
    }
  }, []);

  useEffect(() => {
    form.setFieldValue('content', editorState);
  }, [editorState]);

  const visibilityOptions = (enums?.visibility || []).map((enumItem) => ({
    label: (
      <Tag color={visibilitiesColors[enumItem]}>
        {t(`communication.documents.list.type.${enumItem}`)}
      </Tag>
    ),
    value: enumItem
  }));

  return (
    <>
      <Form.Item
        name={['title']}
        label={t('communication.infos.modal.info_title')}
        rules={[{ required: true }]}
        {...drawerFormLayout}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['start_visibility']}
        label={t('communication.infos.modal.start_visibility')}
        rules={[{ required: true }]}
        {...drawerFormLayout}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['end_visibility']}
        label={t('communication.infos.modal.end_visibility')}
        {...drawerFormLayout}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>{' '}
      <Form.Item
        name={['visibility']}
        label={t('communication.infos.modal.visibility')}
        rules={[{ required: true }]}
        {...drawerFormLayout}
      >
        <Checkbox.Group options={visibilityOptions} />
      </Form.Item>
      <Form.Item
        name={['content']}
        label={t('communication.infos.modal.content')}
        rules={[{ required: true }]}
      >
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          onChange={(value) => setEditorState(value)}
          theme="snow"
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean']
              ]
            }
          }}
        />
      </Form.Item>
    </>
  );
};

InfosForm.propTypes = {
  enums: PropTypes.shape({
    visibility: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  record: PropTypes.shape({
    content: PropTypes.string
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  })
};

InfosForm.defaultProps = {
  enums: null,
  record: null,
  form: null
};
