import { useState, useCallback, useEffect } from 'react';
import { Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * Custom hook for managing payment method fields.
 * This hook fetches payment methods from the backend and provides the necessary fields for forms.
 *
 * @param {function} setDirectDebit - Function to set direct debit state.
 *
 * @hook
 * @returns {Object} An object containing payment method fields.
 */
export const usePaymentMethodFields = (setDirectDebit) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getPaymentEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/payments/enums'
      });
      setPaymentMethods(data.payment_methods);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getPaymentEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleChange = (method) => {
    if (method) {
      if (method === 'DIRECTDEBIT') setDirectDebit(true);
      else setDirectDebit(false);
    }
  };

  const paymentMethodField = [
    {
      name: ['method'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          optionFilterProp="children"
          showSearch
          onChange={(value) => handleChange(value)}
        >
          {(paymentMethods || []).map((paymentMethod) => (
            <Option key={paymentMethod} value={paymentMethod}>
              {t(`contract.form.payment_methods.${paymentMethod}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const paymentMethodSEPAField = [
    {
      name: ['rib', 'name'],
      rules: [{ required: true }]
    },
    {
      name: ['rib', 'iban'],
      hasFeedback: IBAN,
      rules: [
        { required: true },
        () => ({
          validator(_, value) {
            if (IBAN.isValid(value)) return Promise.resolve();
            return Promise.reject(t('errors.message.invalid_IBAN'));
          }
        })
      ]
    },
    {
      name: ['rib', 'bic'],
      rules: [
        { required: true },
        () => ({
          validator(_, value) {
            if (
              value &&
              (value.length === 8 || value.length === 11) &&
              !value.includes(' ')
            ) {
              return Promise.resolve();
            }
            return Promise.reject(t('errors.message.wrong_format_BIC'));
          }
        })
      ]
    },
    {
      name: ['rib', 'signature_date'],
      rules: [{ required: true }],
      input: <DatePicker />
    }
  ];

  return {
    paymentMethodField,
    paymentMethodSEPAField,
    isFieldsLoading
  };
};
