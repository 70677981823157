import { BaseModel } from './BaseModel';

/**
 * Represents a CafStatement object.
 */
export class CafStatement extends BaseModel {
  constructor(data) {
    super('caf-statement', undefined, data);
  }

  /**
   * Executes a PATCH request to update a resource with the provided ID using the dispatchAPI function.
   *
   * @function
   *
   * @param {string} id - The ID of the resource to update.
   * @param {Object} body - The data to be sent in the request body.
   * @param {boolean} refresh - State to refresh data.
   * @param {forceRefresh} setRefresh - Function to update refresh state.
   * @returns {Promise<Object>} A Promise representing the result of the PATCH request.
   */
  static patch(id, body, refresh, setRefresh) {
    this.dispatchAPI('PATCH', {
      url: `/caf-statements/${id}`,
      body
    });

    setRefresh(!refresh);
  }
}
