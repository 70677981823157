import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, message as Message } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { EditableCell, EditableRow } from './EditableCell';
import { useColumns } from './columns';

const { success: successMessage } = Message;

/**
 * Display a table of applied effort rates.
 * @component
 * @param {Array} dataSource The data source.
 * @param {string} id The id of the daycare.
 * @param {Function} setIsSubmitting A function to set the submitting state.
 * @param {Function} setRefresh A function to refresh the table.
 * @returns {JSX.Element} The rendered component.
 */
export const AppliedEffortRateTable = ({
  dataSource,
  id,
  setIsSubmitting,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const columns = useColumns();
  const isEditable = false;
  const components = {
    body: {
      row: isEditable ? EditableRow : 'tr',
      cell: isEditable ? EditableCell : 'td'
    }
  };

  const updateResource = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/invoicing-configuration/${id}`,
        body: {
          applied_effort_rates: body
        }
      });
      setRefresh((prev) => !prev);
      successMessage(t('success.messages.configuration_updated'));
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    updateResource(newData);
  };

  const columnsWithEditable = columns.map((col) => {
    if (!col.editable || !isEditable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  return (
    <>
      {t('configurations.invoicing.form.effort_rate.title')}
      <Table
        pagination={false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columnsWithEditable}
      />
    </>
  );
};

AppliedEffortRateTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};

AppliedEffortRateTable.defaultProps = {
  dataSource: []
};
