export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
export const hours = [
  '06h',
  '07h',
  '08h',
  '09h',
  '10h',
  '11h',
  '12h',
  '13h',
  '14h',
  '15h',
  '16h',
  '17h',
  '18h',
  '19h',
  '20h'
];
