import { useState } from 'react';
import { Form, Input, Select, Switch, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Convert a file to base64
 *
 * @function
 * @param {File} file - The file to convert
 * @returns {Promise} - The base64 string
 */
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Fields for the daycare form
 * @function
 *
 * @returns {Object} - The fields and the base64 list
 * @returns {Object[]} fields - The fields
 * @returns {Object} base64List - The base64 list
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [base64List, setBase64List] = useState({});

  const handleFileChange = (field, file) => {
    setFileList((prevFileList) => ({
      ...prevFileList,
      [field]: file
    }));
  };

  const handleFileRemove = (field) => {
    setFileList((prevFileList) => {
      const updatedFileList = { ...prevFileList };
      delete updatedFileList[field];
      return updatedFileList;
    });
    setBase64List((prevBase64List) => {
      const updatedBase64List = { ...prevBase64List };
      delete updatedBase64List[field];
      return updatedBase64List;
    });
  };

  const handleBeforeUpload = async (field, file) => {
    const fileExtension = file.name.split('.').pop();
    if (
      fileExtension === 'png' ||
      fileExtension === 'PNG' ||
      fileExtension === 'jpg' ||
      fileExtension === 'JPG'
    ) {
      handleFileChange(field, file);
      const base64 = await fileToBase64(file);
      setBase64List((prevBase64List) => ({
        ...prevBase64List,
        [field]: base64
      }));
      return false;
    }
    message('Not a PNG or JPG file.');
    return true;
  };

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      rules: [{ required: true }],
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      rules: [{ required: true }],
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'address.city',
      rules: [{ required: true }],
      name: ['address', 'city']
    },
    {
      label: 'address.state',
      rules: [{ required: true }],
      name: ['address', 'state']
    },
    {
      label: 'capacity',
      rules: [{ required: true }],
      name: ['capacity'],
      input: <Input type="number" />
    },
    {
      label: 'supply_meals_diappers',
      rules: [{ required: true }],
      name: ['supply_meals_diappers'],
      valuePropName: 'checked',
      input: <Switch unCheckedChildren={t('no')} checkedChildren={t('yes')} />
    },
    {
      name: ['logo'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('logo')}
          beforeUpload={(file) => handleBeforeUpload('logo', file)}
          fileList={fileList.logo ? [fileList.logo] : []}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['manager_signature'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('manager_signature')}
          beforeUpload={(file) => handleBeforeUpload('manager_signature', file)}
          fileList={
            fileList.manager_signature ? [fileList.manager_signature] : []
          }
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['stamp'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('stamp')}
          beforeUpload={(file) => handleBeforeUpload('stamp', file)}
          fileList={fileList.stamp ? [fileList.stamp] : []}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      startWithDivider: {
        title: t('daycares.form.supervisory_rules')
      },
      label: 'number_of_staff_children_walking',
      rules: [{ required: true }],
      name: ['number_of_staff_children_walking'],
      input: <Input type="number" style={{ marginBottom: 18 }} />
    },
    {
      label: 'number_of_staff_children_not_walking',
      rules: [{ required: true }],
      name: ['number_of_staff_children_not_walking'],
      input: <Input type="number" />
    }
  ];

  return {
    fields,
    base64List
  };
};
