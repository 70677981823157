import { Form, Input, Button, Card, Radio, Flex } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Group } = Radio;

/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useContractFeesFields = () => {
  const { t } = useTranslation();

  const contractFeesFields = [
    {
      name: 'registration_fees',
      label: t('registration_fees'),
      input: <Input type="number" addonAfter="€" />
    },
    {
      noLabel: true,
      input: (
        <Form.List name={['other_contract_fees']}>
          {(fields, { add, remove }) => (
            <Flex gap="small" align="center" wrap="wrap">
              {fields.map((field) => (
                <Card key={field.key} className="form-list-card">
                  <Button
                    type="link"
                    onClick={() => remove(field.name)}
                    style={{ position: 'absolute', top: 8, right: 0 }}
                  >
                    <CloseOutlined />
                  </Button>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.other_contract_fees.label'
                    )}
                    name={[field.name, 'label']}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.other_contract_fees.amount'
                    )}
                    name={[field.name, 'amount']}
                  >
                    <Input type="number" addonAfter="€" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t(
                      'configurations.invoicing.form.other_contract_fees.bill_by'
                    )}
                    name={[field.name, 'bill_by']}
                  >
                    <Group>
                      <Radio value="day">{t('day')}</Radio>
                      <Radio value="month">{t('month')}</Radio>
                    </Group>
                  </Form.Item>
                </Card>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                {t(
                  'configurations.invoicing.form.other_contract_fees.add_button'
                )}
              </Button>
            </Flex>
          )}
        </Form.List>
      )
    }
  ];

  return contractFeesFields;
};
