import { useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { contractTypes } from '../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Custom hook for managing contract fields.
 * This hook fetches contract types from the backend and provides the necessary fields for forms.
 *
 * @hook
 * @returns {Object} An object containing contract fields.
 */
export const useContractFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [contractEnums, setContractEnums] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getContractEnums = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/contracts/enums'
      });
      setContractEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getContractEnums();
    })();
  }, []);

  const contractFields = [
    {
      name: ['contract', 'planning_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(contractEnums.planningTypes || []).map((contract_type) => (
            <Option key={contract_type} value={contract_type}>
              {t(`contract.enums.contract_types.${contract_type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contract', 'billing_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(contractEnums.billingTypes || []).map((contract_type) => (
            <Option key={contract_type} value={contract_type}>
              <Tag color={contractTypes[contract_type]}>
                {t(`contract.enums.contract_types.${contract_type}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];
  return { contractFields };
};
