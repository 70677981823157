export const headers = [
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'position',
    key: 'position'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number'
  },
  {
    label: 'children_groups',
    key: 'children_groups'
  },
  {
    label: 'contract_type',
    key: 'contract_type'
  },
  {
    label: 'contract_hours_per_week',
    key: 'contract_hours_per_week'
  },
  {
    label: 'contract_start_date',
    key: 'contract_start_date'
  },
  {
    label: 'contract_end_date',
    key: 'contract_end_date'
  },
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'phone_number_full',
    key: 'phone_number'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.state',
    key: 'address.state'
  }
];
