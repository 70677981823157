import { useNavigate } from 'react-router-dom';
import { Tag, Button, Popconfirm, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * Hook for generating columns configuration for the employee table.
 *
 * @hook
 *
 * @param {boolean} refresh - Flag indicating whether to refresh the table.
 * @param {function} setRefresh - Function to set the refresh flag.
 * @param {function} handleModal - Function to handle modal operations.
 * @returns {Array} - Array of column configurations.
 */
export const useColumns = (refresh, setRefresh, handleModal) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteEmployee = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/employees/${id}`
      });
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('employees.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('employees.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('employees.form.position'),
      key: 'position',
      dataIndex: 'position',
      sorter: true,
      render: (position) =>
        position && position.label ? (
          <Tag color={position?.color?.hex}>{position.label || ''}</Tag>
        ) : (
          ''
        )
    },
    {
      title: t('employees.form.phone_number.number'),
      key: 'mobile_phone_number',
      dataIndex: 'mobile_phone_number',
      render: (mobile_phone_number) =>
        mobile_phone_number?.country_code &&
        mobile_phone_number?.number &&
        `${mobile_phone_number?.country_code} ${mobile_phone_number?.number}`
    },
    {
      title: t('employees.form.children_groups'),
      key: 'children_groups',
      dataIndex: 'children_groups',
      sorter: true,
      render: (children_groups) =>
        children_groups &&
        children_groups.length > 0 &&
        children_groups.map((group) =>
          group && group.label ? (
            <Tag
              key={group._id}
              color={group?.tag_color}
              style={{ marginRight: 4 }}
            >
              {group?.label}
            </Tag>
          ) : (
            '-'
          )
        )
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => navigate(`/employees/show/${record?._id}`)}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => handleModal(record, 'edit', 'documents')}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteEmployee(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
