import { useTranslation } from 'react-i18next';
import { Row, Col, Flex, Card } from 'antd';
import { HomeContextProvider } from '../../contexts/HomeContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { TurnoverCard } from './components/TurnoverCard';
import { AttendanceCard } from './components/AttendanceCard';
import { StaffRatioCard } from './components/StaffRatio';
import { InvoicesCard } from './components/InvoicesCard';
import { ChildrenCard } from './components/ChildrenCard';
import { MealsCard } from './components/MealsCard';

import { Selects } from './components/Selects';
import { FiloueExportButton } from './components/FiloueExportButton';
import { LoadingModal } from './LoadingModal';

/**
 * @component Home
 * @description Component that renders the home page with all the cards.
 * @returns {JSX} Component JSX
 */
export const Home = () => {
  const { t } = useTranslation();

  return (
    <HomeContextProvider>
      <PageHeaderCustom
        title={t('home.title')}
        extra={<FiloueExportButton />}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          <Card>
            <Flex vertical gap="middle">
              <Selects />
              <Row gutter={[16, 16]} className="home-infos-graphics">
                <Col xs={24} xxl={12}>
                  <TurnoverCard />
                </Col>
                <Col xs={24} xxl={12}>
                  <InvoicesCard />
                </Col>
              </Row>
            </Flex>
          </Card>
          <Row gutter={[16, 16]}>
            <Col xs={24} xxl={12}>
              <Flex vertical gap="middle">
                <AttendanceCard />
                <StaffRatioCard />
                <MealsCard />
              </Flex>
            </Col>
            <Col xs={24} xxl={12}>
              <Flex vertical gap="middle">
                <ChildrenCard />
              </Flex>
            </Col>
          </Row>
        </Flex>
      </ContentCustom>
      <LoadingModal />
    </HomeContextProvider>
  );
};
