import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { NotesThread } from './NotesThread';
import { MessageWriter } from '../MessageWriter';

export const Notes = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);

  const title = t('messages_and_notes.titles.write_a_note');

  return (
    <Card className="staff-notes-wrapper">
      <Row>
        <Col xs={24} xl={12} className="notes-col">
          <NotesThread refresh={refresh} />
        </Col>
        <Col xs={24} xl={12} className="new-note-col">
          <MessageWriter
            url="/notes"
            refresh={refresh}
            setRefresh={setRefresh}
            title={title}
            onlyForManagement
            type="note"
          />
        </Col>
      </Row>
    </Card>
  );
};
