import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Typography, Button, Flex, Divider, Input } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { usePaymentMethodFields } from '../../../Contracts/Fields/paymentMethodFields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { formItemLayout } from '../../../../utils/constants/formLayout';

/**
 * UpdatePaymentModal component - Displays a modal for updating the payment method of a contract.
 *
 * @component
 * @param {boolean} isModalOpen - Indicates whether the modal is open.
 * @param {function} closeModal - Function to close the modal.
 * @param {Object} contract - The contract object.
 * @param {function} patchPayment - Function to patch the payment method of the contract.
 * @returns {JSX.Element} The rendered component.
 */
const UpdatePaymentModal = ({
  isModalOpen,
  closeModal,
  contract,
  patchPayment
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [directDebit, setDirectDebit] = useState(false);
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();

  const { paymentMethodField, paymentMethodSEPAField } =
    usePaymentMethodFields(setDirectDebit);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await patchPayment(values);
    setIsSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      method: contract?.payment_method?.method,
      daycare: contract?.daycare,
      ...(contract?.rib
        ? {
            rib: {
              ...contract?.rib,
              signature_date: dayjs(contract?.rib?.signature_date)
            },
            oldRib: contract?.rib?._id
          }
        : {})
    });
    if (contract?.payment_method?.method === 'DIRECTDEBIT')
      setDirectDebit(true);
  }, [contract, form]);

  const handleChange = ({ method }) => {
    if (method) {
      if (method === 'DIRECTDEBIT') setDirectDebit(true);
      else setDirectDebit(false);
    }
  };

  return (
    <Modal
      centered
      width={800}
      footer={false}
      open={isModalOpen}
      onCancel={() => {
        closeModal();
      }}
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('contract.modal.title')}
        </Typography.Title>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleChange}
        {...formItemLayout}
      >
        {paymentMethodField.map((field) => generateFields('contract', field))}
        {directDebit && (
          <>
            <Divider orientation="left">{t('contract.form.rib.title')}</Divider>
            {paymentMethodSEPAField.map((field) =>
              generateFields('contract', field)
            )}
          </>
        )}
        <Form.Item name="daycare" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="oldRib" hidden>
          <Input />
        </Form.Item>
        <Flex justify="flex-end" gap="small">
          <Button
            style={{ margin: '0 8px' }}
            type="link"
            danger
            onClick={() => closeModal()}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {`${t('buttons.validate')} `}
            <CheckOutlined />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

UpdatePaymentModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    payment_method: PropTypes.shape({
      method: PropTypes.string
    }),
    daycare: PropTypes.string,
    rib: PropTypes.shape({
      signature_date: PropTypes.string,
      _id: PropTypes.string
    })
  }),
  patchPayment: PropTypes.func.isRequired
};

UpdatePaymentModal.defaultProps = {
  contract: {}
};

export default UpdatePaymentModal;
