import { useTranslation } from 'react-i18next';
import { ShowDaycare } from './Showdaycare/ShowDaycare';
import { ListTimetable } from './ListTimetable';
import ListTemplateDocs from '../template-docs/ListTemplateDocs';
import { DynamicListTable } from './DynamicLists/DynamicList';
import { Invoicing } from './Invoicing/Invoicing';
import { useAuthContext } from '../../contexts/AuthContext';
import { Activities } from './Activities/Activities';
import { IngredientsList } from './Ingredients/IngredientsList';
import { Accounting } from './Accounting/Accounting';

/**
 * useTabContent
 * A custom hook that builds the tab content for the daycare configuration page
 *
 * @hook
 * @param {object} data The daycare's informations.
 * @param {boolean} isLoading Is the data loading.
 * @param {boolean} refresh - Boolean to refresh data.
 * @param {Function} setRefresh - Function to refresh data.
 * @returns {object} tabContent
 * @example
 * const { tabContent } = useTabContent();
 */
export const useTabContent = (data, isLoading, refresh, setRefresh) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const tabContent = [
    {
      label: t('daycares.form.general'),
      key: 'general',
      children: (
        <ShowDaycare
          data={data}
          isLoading={isLoading}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    },
    {
      label: t('daycares.form.opening'),
      key: 'opening',
      children: <ListTimetable />
    },
    ...(user.role !== 'users:MANAGER'
      ? [
          {
            label: t('daycares.form.document_templates'),
            key: 'document_templates',
            children: <ListTemplateDocs />
          },
          ...(['admins:SUPER-ADMIN', 'admins:CORPORATE'].includes(user.role)
            ? [
                {
                  label: t('daycares.form.invoicing'),
                  key: 'invoicing',
                  children: <Invoicing />
                }
              ]
            : []),
          {
            label: t('daycares.form.accounting'),
            key: 'accounting',
            children: <Accounting />
          },
          {
            label: t('daycares.form.dynamic_lists'),
            key: 'dynamic_lists',
            children: <DynamicListTable />
          },
          {
            label: t('daycares.form.activities'),
            key: 'activities',
            children: <Activities />
          }
        ]
      : []),
    {
      label: t('daycares.form.ingredients'),
      key: 'indregients',
      children: <IngredientsList />
    }
  ];

  return { tabContent };
};
