import PropTypes from 'prop-types';
import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ContactIcon from '../../../../assets/images/icons/material-symbols_contact-phone.svg';
import EmployerIcon from '../../../../assets/images/icons/mdi_account-payment.svg';

/**
 * @description
 * PanelHeader is a component that displays the header of a panel.
 * It is displayed in the ParentPanel component.
 *
 * @component
 * @param {Object} data - The data of the parent
 * @param {number} index - The index of the parent
 * @returns {React.Component} PanelHeader component
 */
export const PanelHeader = ({ data, index }) => {
  const { t } = useTranslation();
  const { is_principal_contact, employment_contract } = data;

  return (
    <Flex align="center" gap="small" className="parent-collapse-meta">
      <span>{t('pre-registrations.descriptions.parent_index', { index })}</span>
      {is_principal_contact && (
        <Tooltip title={t('pre-registrations.descriptions.principal_contact')}>
          <img src={ContactIcon} alt="principal-contact" />
        </Tooltip>
      )}
      {employment_contract && (
        <Tooltip
          title={t('pre-registrations.descriptions.employment_contract')}
        >
          <img src={EmployerIcon} alt="employment-contract" />
        </Tooltip>
      )}
    </Flex>
  );
};

PanelHeader.propTypes = {
  data: PropTypes.shape({
    is_principal_contact: PropTypes.bool,
    employment_contract: PropTypes.bool
  }).isRequired,
  index: PropTypes.number.isRequired
};
