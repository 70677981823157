import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

export const EmployeeContext = createContext({ isValid: false });

/**
 * Provides a React context for employee-related data, including enumerations,
 * team positions, and children groups. It fetches and stores essential data for
 * managing employees in the application, making it accessible to any component
 * within the context provider's tree.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - Child components that can access the context.
 * @returns {ReactElement} A context provider that wraps child components.
 *
 */
export const EmployeeContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [teamPositions, setTeamPositions] = useState([]);
  const [childrenGroups, setChildrenGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees/enums' });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  const getPosition = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/team-positions' });
      setTeamPositions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getChildrenGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/children-groups' });
      setChildrenGroups(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      await getPosition();
      await getChildrenGroups();
      setIsLoading(false);
    })();
  }, []);
  return (
    <EmployeeContext.Provider
      value={{
        enums,
        teamPositions,
        childrenGroups,
        isLoading
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

/**
 * Custom React hook to access employee-related context.
 * This hook allows you to access enums, team positions, children groups, and a loading state
 * related to employee management throughout your application.
 *
 * @hook
 *
 * @returns {Object} The employee context state including enums, teamPositions, childrenGroups, and isLoading.
 *
 * @example
 * // Use within a component to get employee-related data.
 * const { enums, teamPositions, childrenGroups, isLoading } = useEmployeeContext();
 */
export const useEmployeeContext = () => {
  const context = useContext(EmployeeContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
