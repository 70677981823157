import { ChildFollowUp } from '../ChildFollowUp';

/**
 * Generates a list of menu items for a parent component.
 *
 * This function takes an array of children and maps each child to a menu item.
 * Each menu item includes the child's first name and a `FollowUp` component.
 * If there are no children, it returns an empty array.
 *
 * @param {Array} children - Array of child objects.
 * @param {Array} activities - Array of daily activities.
 * @returns {Array} An array of objects, each representing a menu item.
 *
 * @example
 * const childData = [{ first_name: 'John' }, { first_name: 'Jane' }];
 * const menuItems = parentItems(childData);
 * // Output: [{ key: '0', label: 'John', children: <FollowUp /> }, { key: '1', label: 'Jane', children: <FollowUp /> }]
 */

export const parentItems = (children) =>
  children?.length > 0
    ? [
        ...children.map((child) => ({
          key: child._id,
          label: child.first_name,
          children: <ChildFollowUp />
        }))
      ]
    : [];
