import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Calculate the duration between two dates in years, months, weeks, or days and format it.
 *
 * @param {string} rawStartDate - The raw start date in a valid date format.
 * @param {string} rawEndDate - The raw end date in a valid date format.
 * @param {function} t - A translation function for getting localized strings.
 * @returns {string} The formatted duration as a string with units (years, months, weeks, or days).
 *                   Returns an empty string if the duration is negative or invalid.
 */
export const getContractDuration = (rawStartDate, rawEndDate, t) => {
  const startDate = dayjs(rawStartDate);
  const endDate = dayjs(rawEndDate);

  const ageInYears = endDate.diff(startDate, 'year');

  startDate.add(ageInYears, 'year');
  const ageInMonths = endDate.diff(startDate, 'month');

  startDate.add(ageInMonths, 'month');
  const ageInWeeks = endDate.diff(startDate, 'week');

  startDate.add(ageInWeeks, 'week');
  const ageInDays = endDate.diff(startDate, 'day');

  switch (true) {
    case ageInDays < 0:
      return '';
    case ageInYears > 0:
      return <div>{`${ageInYears} ${t('pre-registrations.form.years')}`}</div>;
    case ageInMonths > 0:
      return (
        <div>{`${ageInMonths} ${t('pre-registrations.form.months')}`}</div>
      );
    case ageInWeeks > 0:
      return <div>{`${ageInWeeks} ${t('pre-registrations.form.weeks')}`}</div>;
    default:
      return (
        <div>{`${ageInDays} ${t('pre-registrations.form.date_days')}`}</div>
      );
  }
};
