import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, message as Message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { useErrorMessage } from '../../utils/errorMessage';
import { drawerFormLayout } from '../../utils/constants/formLayout';

/**
 * @description CreateDaycare form
 * @component
 * @param {function} setModalVisible - To set the modal visible
 * @param {function} onCancel - To cancel the modal
 * @param {function} setRefresh - To set the refresh
 * @returns {JSX} The form to create a daycare
 */
export const CreateDaycare = ({ setModalVisible, onCancel, setRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fields, base64List } = useFields(enums, form);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/daycares/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const handleOk = async (values) => {
    try {
      setIsSubmitting(true);
      const { logo, manager_signature, stamp } = base64List;
      const body = {
        ...values,
        logo,
        manager_signature,
        stamp
      };
      await dispatchAPI('POST', { url: '/daycares', body });
      Message.success(t('daycares.messages.success'));
      setModalVisible(false);
      setRefresh((prev) => !prev);
      return null;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        return message(data.message);
      }
      return message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <Form {...drawerFormLayout} onFinish={handleOk} form={form}>
      {fields.map((field) => generateFields('daycares', field))}
      <Row justify="end">
        <Button
          style={{ margin: '0 10px' }}
          type="link"
          danger
          onClick={onCancel}
        >
          {`${t('buttons.cancel')} `}
        </Button>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          {`${t('buttons.save')} `}
        </Button>
      </Row>
    </Form>
  );
};

CreateDaycare.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};
