import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { MercuryDatePicker } from '../../../components/DateAndTimePickers/MercuryDatePicker';
import { MercuryTimeRangePicker } from '../../../components/DateAndTimePickers/MercuryTimeRangePicker';

const { TextArea } = Input;

/**
 * EventForm is a React component that renders a form for creating or updating events.
 * It supports different types of events like absences or reservations and allows
 * users to specify children, dates, times, reasons, and additional comments.
 *
 * @component
 *
 * This component uses the `useTranslation` hook for internationalization, `useAuthContext`
 * for dispatching API requests, and `useErrorMessage` for error handling.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.parentsChildren - List of children related to the parent.
 * @param {Function} props.setScreen - Function to set the current screen or view in the parent component.
 * @param {string} props.type - Type of event, e.g., 'absences' or 'reservations'.
 *
 * @returns {JSX.Element} A form component for creating or updating events.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.parentsChildren {Array} - List of children related to the parent.
 * @propTypes.setScreen {Function} - Function to set the current screen or view in the parent component.
 * @propTypes.type {string} - Type of event, e.g., 'absences' or 'reservations'. Required.
 */
export const EventForm = ({ parentsChildren, setScreen, type }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [absencesReason, setAbsencesReason] = useState([]);
  const [reservationsReason, setReservationsReason] = useState([]);

  const getAbsencesReason = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/occasional-reservation-reasons'
      });
      setReservationsReason(data);
    } catch (e) {
      message(e);
    }
  };

  const getReservationsReason = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/absence-reason' });
      setAbsencesReason(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getAbsencesReason();
      await getReservationsReason();
      setIsFieldsLoading(false);
    })();
  }, []);

  const postNotification = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `notifications/${type}`,
        body: {
          ...body,
          user: user._id
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const postEvent = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `/pickup-times/children-event`,
        body: {
          ...body,
          type,
          times: [
            body?.times &&
              dayjs(body.times[0])
                .utcOffset(0)
                .year(body.date.year())
                .month(body.date.month())
                .date(body.date.date())
                .hour(dayjs(body.times[0]).hour())
                .minute(dayjs(body.times[0]).minute()),
            body?.times &&
              dayjs(body.times[1])
                .utcOffset(0)
                .year(body.date.year())
                .month(body.date.month())
                .date(body.date.date())
                .hour(dayjs(body.times[1]).hour())
                .minute(dayjs(body.times[1]).minute())
          ],
          date: dayjs(body.date).add(2, 'hour'),
          absence_deduction: 'non_deductible',
          take_vacation: false,
          origin: 'parent'
        }
      });
      await postNotification(body);
      setScreen(0);
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const options = (parentsChildren || []).map((child) => ({
    key: child._id,
    label: child.first_name,
    value: child._id
  }));

  const absencesReasonOptions = (absencesReason || []).map((reason) => ({
    key: reason._id,
    label: reason.name,
    value: reason._id
  }));

  const reservationReasonOptions = (reservationsReason || []).map((reason) => ({
    key: reason._id,
    label: reason.label,
    value: reason._id
  }));

  return (
    <Form form={form} onFinish={postEvent}>
      <Form.Item
        name={['children']}
        label={t('parents.home.children')}
        required
      >
        <Checkbox.Group options={options} />
      </Form.Item>
      <Form.Item name={['date']} label={t('parents.home.date')} required>
        <MercuryDatePicker />
      </Form.Item>
      <Form.Item name={['times']} label={t('parents.home.times')} required>
        <MercuryTimeRangePicker
          format="HH:mm"
          disabledTimeRange={{ start: 6, end: 21 }}
          form={form}
          formInput="times"
        />
      </Form.Item>
      <Form.Item name={['reason']} label={t('parents.home.reason')} required>
        <Select
          loading={isFieldsLoading}
          options={
            type === 'absences'
              ? absencesReasonOptions
              : reservationReasonOptions
          }
        />
      </Form.Item>
      <Form.Item name={['comment']} label={t('parents.home.comment')}>
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item>
        <Row justify="end">
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={() => setScreen(0)}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

EventForm.propTypes = {
  parentsChildren: PropTypes.arrayOf(PropTypes.shape({})),
  setScreen: PropTypes.func,
  type: PropTypes.string.isRequired
};

EventForm.defaultProps = {
  parentsChildren: null,
  setScreen: null
};
