import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import {
  CloseOutlined,
  CheckOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Row,
  message as Message,
  Button,
  Input,
  Divider,
  Popconfirm
} from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

const { success: SuccessMessage } = Message;

/**
 * The CalculMethodModal component.
 * @component
 *
 * @param {object} props Component props.
 * @param {boolean} props.isCalculMethodModalOpen Whether the modal is open.
 * @param {function} props.closeModal Function to close the modal.
 * @param {function} props.setRefresh Function to refresh the page.
 * @param {object} props.dataSource Data source to populate the form.
 * @returns {React.Component} The component.
 */
export const CalculMethodModal = ({
  isCalculMethodModalOpen,
  closeModal,
  setRefresh,
  dataSource
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    const cafFeesModification = dataSource?.caf_fees_modification || [];
    cafFeesModification.push({
      date: dayjs().utc(true).add(1, 'day'),
      application_date: dayjs().utc(true).startOf('year')
    });
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `invoicing-configuration/${daycare}?modificationConfirmed=${true}`,
        body: {
          ...values,
          caf_fees_modification: cafFeesModification
        }
      });
      form.resetFields();
      setRefresh(true);
      closeModal();
      SuccessMessage(t('configurations.invoicing.modal.success_message'));
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const onCancel = () => {
    form.resetFields();
    closeModal();
  };

  useEffect(() => {
    if (dataSource) form.setFieldsValue(dataSource);
  }, [dataSource]);

  return (
    <Modal
      title={t('configurations.invoicing.modal.title')}
      open={isCalculMethodModalOpen}
      footer={null}
      onCancel={onCancel}
      width={800}
    >
      <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
        <Divider>{t('configurations.invoicing.modal.ressources')}</Divider>
        <Form.Item
          name="minimum_monthly_resources"
          label={t('configurations.invoicing.form.minimum_monthly_resources')}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name="maximum_monthly_resources"
          label={t('configurations.invoicing.form.maximum_monthly_resources')}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Divider>{t('configurations.invoicing.modal.effort_rate')}</Divider>
        <Form.Item
          name={['applied_effort_rates', 0, 'one_child']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.one_child'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'two_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.two_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'three_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.three_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'four_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.four_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'five_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.five_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'six_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.six_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'seven_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.seven_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item
          name={['applied_effort_rates', 0, 'eight_children']}
          label={t(
            'configurations.invoicing.form.effort_rate.columns.eight_children'
          )}
        >
          <Input type="number" addonAfter="€" />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onCancel}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Popconfirm
              title={
                <>
                  {`${t(
                    'configurations.invoicing.modal.popconfirm.text_1'
                  )} ${dayjs().startOf('year').format('DD/MM/YYYY')} ?`}
                  <br />
                  {t('configurations.invoicing.modal.popconfirm.text_2')}
                  <br />
                  {t('configurations.invoicing.modal.popconfirm.text_3')}
                  <br />
                  {t('configurations.invoicing.modal.popconfirm.text_4')}
                  <br />
                  {t('configurations.invoicing.modal.popconfirm.text_5')}
                </>
              }
              onConfirm={() => form.submit()}
              okText={t('configurations.invoicing.modal.popconfirm.yes')}
              cancelText={t('configurations.invoicing.modal.popconfirm.no')}
              icon={<WarningOutlined />}
              okButtonProps={{ type: 'danger' }}
            >
              <Button
                type="primary"
                htmlType="button"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {`${t('buttons.save')}`}
                <CheckOutlined />
              </Button>
            </Popconfirm>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CalculMethodModal.propTypes = {
  isCalculMethodModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  dataSource: PropTypes.shape({
    caf_fees_modification: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
