import { useState } from 'react';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useColumns } from './columns';
import { ExtraFilters } from './ExtraFilters';
import { CreateUpdateDocumentModal } from './CreateUpdateDocumentModal.';
import { useInitialVisibilityState } from './useInitialVisibilityState';

/**
 * Component for displaying and managing documents.
 *
 * @component
 *
 * This component renders a list of documents using the `ListResource` component.
 * It includes functionalities such as opening a modal for adding or editing documents,
 * custom filter options, and document management actions like archiving and downloading.
 * The component fetches documents related to a specific daycare and allows
 * interactions through a dynamically generated table.
 *
 * @returns {React.Component} A component that renders a list of documents with management functionalities.
 */
export const Documents = () => {
  const { daycare, user } = useAuthContext();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState();

  const initialVisibilityState = useInitialVisibilityState(user.role);

  const [visibilityFilter, setVisibilityFilter] = useState(
    initialVisibilityState
  );
  const [typeFilter, setTypeFilter] = useState(['HEALTH', 'MENU']);

  const onModalOpen = (record, purpose, resourceName) => {
    const modalKey = Date.now();

    setModal(
      <CreateUpdateDocumentModal
        key={modalKey}
        purpose={purpose}
        record={record}
        refresh={refresh}
        setRefresh={setRefresh}
        resourceName={resourceName}
      />
    );
  };

  const isAdmin = !['guests:PARENT', 'users:TABLET'].includes(user?.role);

  const columns = useColumns(refresh, setRefresh, onModalOpen, isAdmin);

  return (
    <>
      <ListResource
        resourceName="documents"
        customActionColumn
        columns={columns}
        populate="file"
        extraQuery={`daycare=${daycare}&visibility=${visibilityFilter}&document_type=${typeFilter}`}
        resourceModelName="Document"
        forceRefresh={refresh}
        archivedButton={false}
        withCreateButton={false}
        extraButtons={
          <ExtraFilters
            onModalOpen={onModalOpen}
            visibilityFilter={visibilityFilter}
            setVisibilityFilter={setVisibilityFilter}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            isAdmin={isAdmin}
          />
        }
        setRefresh={setRefresh}
        withUploadButton={false}
        withImportButton={false}
        withPageHeader={false}
        scroll={{ x: 800 }}
      />
      {modal}
    </>
  );
};
