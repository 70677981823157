import { useTranslation } from 'react-i18next';
import { Conversations } from './Conversations/Conversations';
import { Notes } from './Notes/Notes';

export const useTabContent = () => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('messages_and_notes.titles.conversations'),
      key: 'conversations',
      children: <Conversations />
    },
    {
      label: t('messages_and_notes.titles.notes'),
      key: 'notes',
      children: <Notes />
    }
  ];

  return { items };
};
