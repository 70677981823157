import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { checkoutStatus } from '../../../utils/constants/tagColors';

/**
 * Renders the checkout status component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.checkout - The checkout object containing checkout and payment status.
 * @param {string} props.checkout.checkout_status - The checkout status.
 * @param {string} props.checkout.payment_status - The payment status.
 * @returns {JSX.Element} The rendered checkout status component.
 */
export const CheckoutStatus = ({ checkout }) => {
  const { t } = useTranslation();
  const { checkout_status, payment_status } = checkout;
  let colorStatus;

  if (!checkout_status || !payment_status) return '-';

  if (checkout_status === 'OPEN') {
    colorStatus = 'PENDING';
  } else if (payment_status === 'PAID') {
    colorStatus = 'PAID';
  } else if (payment_status === 'UNPAID') {
    colorStatus = 'UNPAID';
  } else if (payment_status === 'PARTIALY_PAID') {
    colorStatus = 'PARTIALY_PAID';
  }

  const statusString = `${t(
    `credit-card-payments.checkout_status.${checkout_status}`
  )} / ${t(`credit-card-payments.payment_status.${payment_status}`)}`;

  return <Tag color={checkoutStatus[colorStatus]}>{statusString}</Tag>;
};

CheckoutStatus.propTypes = {
  checkout: PropTypes.shape({
    checkout_status: PropTypes.string.isRequired,
    payment_status: PropTypes.string.isRequired
  }).isRequired
};
