import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Flex } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useChildFields } from '../Fields/childFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Component for rendering the child form.
 * This component is rendered inside the pre-registration form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @returns {React.Component} Child form component
 */
export const ChildForm = ({ onFinish, initialValues, handlePrevious }) => {
  const [form] = Form.useForm();
  const { token } = useAuthContext();
  const navigate = useNavigate();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { childFields } = useChildFields();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      onFinish={onFinish}
      {...formItemLayout}
      form={form}
      className="prereg-form"
    >
      {childFields.map((field) => generateFields('pre-registrations', field))}
      <Flex justify="end" gap="small">
        {token ? (
          <Button type="link" onClick={() => navigate(-1)}>
            {t('buttons.cancel')}
          </Button>
        ) : (
          <Button type="primary" onClick={handlePrevious}>
            <ArrowLeftOutlined />
            {t('buttons.previous')}
          </Button>
        )}
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </Form>
  );
};

ChildForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({})
};
ChildForm.defaultProps = {
  initialValues: {}
};
