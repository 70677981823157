/**
 * Updates the reference weeks with new time slot data for a specific day in a week.
 *
 * @function
 *
 * @param {Array<Array<{ day: string, time_slots: Array<{ start_time: string, end_time: string }> }>>} prevReferenceWeeks - The previous reference weeks data.
 * @param {number} weekIndex - The index of the week to be updated.
 * @param {string} day - The day to be updated.
 * @param {Function} timeToString - Function to convert time to string format.
 * @param {Date} selectedEndTime - The selected end time for the first time slot.
 * @param {Date} selectedEndTime2 - The selected end time for the second time slot.
 * @param {Date} selectedStartTime - The selected start time for the first time slot.
 * @param {Date} selectedStartTime2 - The selected start time for the second time slot.
 * @returns {Array<Array<{ day: string, time_slots: Array<{ start_time: string, end_time: string }> }>>} The updated reference weeks data.
 */

export const getUpdateWeeks = (
  prevReferenceWeeks,
  weekIndex,
  day,
  timeToString,
  selectedEndTime,
  selectedEndTime2,
  selectedStartTime,
  selectedStartTime2
) => {
  const newReferenceWeeks = [...prevReferenceWeeks];
  let weekModified = false;

  const currentWeekData = newReferenceWeeks[weekIndex] || [];
  const existingDayIndex = currentWeekData.findIndex((d) => d.day === day);

  const newDayData = {
    day,
    time_slots: [
      {
        start_time: timeToString(selectedStartTime),
        end_time: timeToString(selectedEndTime)
      },
      {
        start_time: timeToString(selectedStartTime2),
        end_time: timeToString(selectedEndTime2)
      }
    ]
  };

  if (existingDayIndex >= 0) {
    const existingDay = currentWeekData[existingDayIndex];
    if (
      JSON.stringify(existingDay.time_slots) !==
      JSON.stringify(newDayData.time_slots)
    ) {
      currentWeekData[existingDayIndex] = newDayData;
      weekModified = true;
    }
  } else {
    currentWeekData.push(newDayData);
    weekModified = true;
  }

  if (weekModified) {
    newReferenceWeeks[weekIndex] = currentWeekData;
    return newReferenceWeeks;
  }

  return prevReferenceWeeks;
};
