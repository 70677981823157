import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { ListParents } from './ListParents';
import { ShowParent } from './ShowParent';
import { CreateUpdateParent } from './CreateUpdateParent';

export const ParentsRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowParent />} />
    <Route path="/edit/:id" element={<CreateUpdateParent purpose="edit" />} />
    <Route index element={<ListParents />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
