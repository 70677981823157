import { Route, Routes } from 'react-router-dom';
import { ListCreditCardPayments } from './ListCreditCardPayments';
import { Exception } from '../../../components';

/**
 * Renders the router for credit card payments.
 * @returns {JSX.Element} The credit card payments router component.
 */
export const CreditCardPaymentsRouter = () => (
  <Routes>
    <Route index element={<ListCreditCardPayments />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
