import { useEffect, useState } from 'react';
import { Col, Row, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { contractStatus } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * ExtraButtons component for filtering and searching resources.
 *
 * This component provides input fields and dropdowns for filtering and searching resources.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Function} props.setFilterStatusOptions - A function to set the selected filter status options.
 * @param {Function} props.searchResource - A function to search resources based on a keyword.
 * @param {Function} props.filterStatusOptions - Statuses filter options.
 * @returns {JSX.Element} JSX element representing the ExtraButtons component.
 */
export const ExtraButtons = ({
  setFilterStatusOptions,
  filterStatusOptions
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [statuses, setStatuses] = useState([]);

  const getContractStatuses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/enums`
      });
      setStatuses(data.status);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getContractStatuses();
    })();
  }, []);

  return (
    <Row style={{ marginBottom: 0 }} gutter={{ xs: 8, sm: 16, md: 24 }}>
      <Col>
        <Select
          allowClear
          mode="multiple"
          onChange={(value) => setFilterStatusOptions(value)}
          style={{ width: '250px', marginLeft: 20 }}
          placeholder={t('children.list.filter_by_type')}
          defaultValue={filterStatusOptions}
        >
          {(statuses || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={contractStatus[status]}>
                {t(`contracts.tags.${status}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

ExtraButtons.propTypes = {
  setFilterStatusOptions: PropTypes.func.isRequired,
  filterStatusOptions: PropTypes.arrayOf(PropTypes.string)
};

ExtraButtons.defaultProps = {
  filterStatusOptions: []
};
