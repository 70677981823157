import { useTranslation } from 'react-i18next';
import { Button, Divider, Flex, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import canAccessAppIcon from '../../../../assets/images/icons/canAccessAppIcon.png';
import cantAccessAppIcon from '../../../../assets/images/icons/cantAccessAppIcon.png';
import canPickIcon from '../../../../assets/images/icons/canPickIcon.png';
import cantPickIcon from '../../../../assets/images/icons/cantPickIcon.png';
import { routes } from '../../../../utils/constants/adminRoutes';

const iconSize = 18;

/**
 * Custom hook for generating columns configuration for a datatable displaying parent records.
 *
 * @hook
 *
 * @param {function} handleModalBehaviour - A function for handling modals.
 * @param {function} removeFromParents - A function to remove parent from child's parent array.
 * @returns {Array} An array of column configuration objects.
 * @example
 * // Usage example:
 * const columns = useColumns(handleModalFunction, removeFromParents);
 */
export const useColumns = (handleModalBehaviour, removeFromParents) => {
  const { t } = useTranslation();

  return [
    {
      title: t('parents.list.last_name'),
      key: 'parent',
      dataIndex: 'parent',
      render: ({ user }) => user && user.first_name
    },
    {
      title: t('parents.list.first_name'),
      key: 'parent',
      dataIndex: 'parent',
      render: ({ user }) => user && user.last_name
    },
    {
      title: t('parents.list.relationship.title'),
      key: 'relationship',
      dataIndex: 'relationship',
      sorter: true,
      render: (relationship) => t(`parents.list.relationship.${relationship}`)
    },
    {
      title: t('parents.list.phone_number'),
      key: 'parent',
      dataIndex: 'parent',
      render: ({ mobile_phone_number }) =>
        mobile_phone_number?.country_code &&
        mobile_phone_number?.number &&
        `${mobile_phone_number?.country_code} ${mobile_phone_number?.number}`
    },
    {
      title: t('parents.list.pick_up_authorization'),
      key: 'pick_up_authorization',
      dataIndex: 'pick_up_authorization',
      sorter: (a, b) => a.pick_up_authorization - b.pick_up_authorization,
      render: (boolean) =>
        boolean ? (
          <Flex align="center" gap="small">
            <img src={canPickIcon} alt="can-pick-icon" />
            {t('yes')}
          </Flex>
        ) : (
          <Flex align="center" gap="small">
            <img src={cantPickIcon} alt="cant-pick-icon" />
            {t('no')}
          </Flex>
        )
    },
    {
      title: t('parents.list.app_authorization'),
      key: 'app_authorization',
      dataIndex: 'app_authorization',
      sorter: (a, b) => a.app_authorization - b.app_authorization,
      render: (app_authorization) =>
        app_authorization ? (
          <Flex align="center" gap="small">
            <img src={canAccessAppIcon} alt="can-pick-icon" />
            {t('yes')}
          </Flex>
        ) : (
          <Flex align="center" gap="small">
            <img src={cantAccessAppIcon} alt="cant-pick-icon" />
            {t('no')}
          </Flex>
        )
    },
    {
      align: 'right',
      render: (record) => (
        <Flex align="center">
          <Link
            to={{
              pathname: `${routes.PARENTS}/show/${record?.parent?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
            <Divider type="vertical" />
          </Link>
          <Button
            type="link"
            className="no-padding"
            onClick={() => {
              const formattedRecord = {
                ...record,
                ...record.parent,
                ...record.parent.user,
                _id: record._id
              };
              return handleModalBehaviour(formattedRecord, 'edit', 'parent');
            }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          {record?.status !== 'ARCHIVED' &&
            record.contract_related === false && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => removeFromParents(record?._id, 'parent')}
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined
                    style={{ color: 'var(--errorColor', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
        </Flex>
      )
    }
  ];
};
