import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { PreRegistrationInfos } from './Descriptions/PreRegistrationInfos';
import { ContractInfos } from './Descriptions/ContractInfos';

/**
 * Custom hook that returns an array of tab items for pre-registrations.
 *
 * @hook
 * @param {object} data - The data object.
 * @param {boolean} isLoading - Indicates whether the data is loading.
 * @returns {Array} An array of tab items.
 */
export const useTabsItems = (data, isLoading) => {
  const { t } = useTranslation();

  return [
    {
      label: t('pre-registrations.show.tabs.pre-registration'),
      key: 'pre-registration',
      children: <PreRegistrationInfos data={data} isLoading={isLoading} />
    },
    {
      label: (
        <Tooltip
          title={
            !data?.contract &&
            t('pre-registrations.show.tabs.contract_not_available')
          }
        >
          {t('pre-registrations.show.tabs.contract')}
        </Tooltip>
      ),
      key: 'contract',
      children: <ContractInfos data={data} isLoading={isLoading} />,
      disabled: !data?.contract
    }
  ];
};
