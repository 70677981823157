import dayjs from 'dayjs';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paymentStatuses } from '../../../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../../../utils/constants/adminRoutes';

/**
 * @hook useColumns
 * @description The columns of the invoices table
 * @returns {Array} columns
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const tradKey = 'customer-account.show.payments.list';

  return [
    {
      title: t(`${tradKey}.status`),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status) => (
        <Tag color={paymentStatuses[status]}>
          {t(`invoices.tags.${status}`)}
        </Tag>
      )
    },
    {
      title: t('payments.list.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => amount && `${amount.toFixed(2)} €`,
      sorter: true
    },
    {
      title: t('payments.list.amount_allocated'),
      key: 'amount_allocated',
      dataIndex: 'amount_allocated',
      render: (amount_allocated) =>
        amount_allocated && `${amount_allocated.toFixed(2)} €`,
      sorter: true
    },
    {
      title: t(`${tradKey}.operation_date`),
      key: 'operation_date',
      dataIndex: 'operation_date',
      render: (date) => dayjs(date).format('DD/MM/YYYY')
    },
    {
      title: t('payments.list.payment_method'),
      key: 'payment_method',
      dataIndex: 'payment_method',
      sorter: true,
      render: (payment_method) =>
        payment_method && t(`payments.enums.payment_methods.${payment_method}`)
    },
    {
      title: t('payments.list.invoice'),
      key: 'invoice',
      dataIndex: 'invoice',
      render: (invoice) => (
        <Link
          to={{
            pathname: `${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/show/${invoice?._id}`
          }}
        >
          {(invoice && invoice?.number) || ''}
        </Link>
      )
    }
  ];
};
