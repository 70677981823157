import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * @hook useListContent
 * @description
 * useListContent is a hook that returns the content of the authorizations card.
 * It is used in the ContractInformationsCard component.
 *
 * @param {Object} data - The data of the authorizations card
 * @returns {Array} The content of the authorizations card card
 */
export const useListContent = (data = {}) => {
  const translationKey = 'pre-registrations.show.authorizations_card';
  const { t } = useTranslation();
  const { authorizations = {} } = data;
  const {
    administer_antipyretic = false,
    admin_care = false,
    exit_permission = false,
    capture_media = false,
    apply_makeup = false,
    specific_permissions = '-',
    consent_date = null
  } = authorizations;

  return [
    {
      label: `${translationKey}.administer_antipyretic`,
      content: t(administer_antipyretic ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.admin_care`,
      content: t(admin_care ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.exit_permission`,
      content: t(exit_permission ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.capture_media`,
      content: t(capture_media ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.apply_makeup`,
      content: t(apply_makeup ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.consent_date`,
      content: consent_date ? dayjs(consent_date).format('DD/MM/YYYY') : '-',
      span: 1
    },
    {
      label: `${translationKey}.specific_permissions`,
      content: specific_permissions,
      span: 3
    }
  ];
};
