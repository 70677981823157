import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

export const CAFScaleContext = createContext({});

/**
 * Provides a context for managing the CAF scale data, including fetching, updating, and filtering the scale data by year.
 *
 * @component
 *
 * This context provider encapsulates the logic for interacting with the backend to fetch and update CAF scale data. It provides a central point of access to the scale data and related operations for its child components. The context maintains the state of the current year filter, the scale data for the selected year, and a refresh trigger to re-fetch data when necessary.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {JSX.Element} A context provider wrapping its children, providing them access to CAF scale data and operations.
 */
export const CAFScaleContextProvider = ({ children }) => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [yearFilter, setYearFilter] = useState(dayjs());
  const [scale, setScale] = useState();
  const [refresh, setRefresh] = useState();

  const getCAFScale = async () => {
    const year = dayjs(yearFilter).year();
    try {
      const { data } = await dispatchAPI('GET', {
        url: `caf-scales?year=${year}`
      });
      if (data.length > 0) {
        setScale(data[0]);
      } else {
        await dispatchAPI('POST', {
          url: 'caf-scales',
          body: {
            daycare,
            year
          }
        });
        setRefresh(!refresh);
      }
    } catch (e) {
      message(e);
    }
  };

  const patchScale = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `caf-scales/${id}`,
        body
      });
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (yearFilter) {
      (async () => {
        await getCAFScale();
      })();
    }
  }, [yearFilter, daycare, refresh]);

  return (
    <CAFScaleContext.Provider
      value={{
        refresh,
        setRefresh,
        yearFilter,
        setYearFilter,
        scale,
        setScale,
        patchScale
      }}
    >
      {children}
    </CAFScaleContext.Provider>
  );
};

CAFScaleContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

/**
 * Custom hook to consume the CAFScaleContext.
 *
 * @hook
 *
 * This hook provides easy access to the CAF scale context within child components of the `CAFScaleContextProvider`. It allows components to access and manipulate the scale data, year filter, and perform API operations related to the CAF scale.
 *
 * @returns {Object} The context value containing the state and functions related to the CAF scale.
 * @throws {Error} Throws an error if used outside of the `CAFScaleContextProvider`.
 */
export const useCAFScaleContext = () => {
  const context = useContext(CAFScaleContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
