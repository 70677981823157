import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import useContactsFields from './fields';

/**
 *
 * @functions
 * SendInvoiceModal component displays a modal for sending invoices.
 * @param {Object} props - The props object containing component properties.
 * @param {boolean} props.isModalSendInvoiceOpen - Flag indicating whether the send invoice modal is open.
 * @param {Function} props.closeModalValidateInvoice - Function to close the send invoice modal.
 * @param {Function} props.setRefresh - Function to trigger a refresh action.
 * @param {string} props.validateInvoiceId - The ID of the invoice to validate.
 * @param {string} props.customerAccountId - The ID of the customer account associated with the invoice.
 * @param {string} props.purpose - The purpose of the invoice.
 * @returns {JSX.Element} JSX element representing the send invoice modal.
 */
const SendInvoiceModal = ({
  isModalSendInvoiceOpen,
  closeModalValidateInvoice,
  setRefresh,
  validateInvoiceId,
  customerAccountId,
  purpose
}) => {
  const { t } = useTranslation();

  const { fields, isFieldsLoading } = useContactsFields(validateInvoiceId);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        contacts: (data?.contacts || []).map((contact) => ({
          ...contact,
          send_email: true
        }))
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalSendInvoiceOpen}
      onCancel={closeModalValidateInvoice}
      title={`${t(`invoices.modal.title`)}`}
      width={900}
      footer={false}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="customer-accounts"
        resource="customer-accounts"
        updateUrl="invoices/email"
        closeModal={closeModalValidateInvoice}
        id={customerAccountId}
        withGetResource
        config={config}
        setRefresh={setRefresh}
        withoutPageHeaderCustom
        specialFormLayout
        successEditMessage={t('success.messages.update_mail')}
      />
    </Modal>
  );
};

SendInvoiceModal.propTypes = {
  validateInvoiceId: PropTypes.string,
  isModalSendInvoiceOpen: PropTypes.bool.isRequired,
  closeModalValidateInvoice: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  setRefresh: PropTypes.func.isRequired,
  customerAccountId: PropTypes.string
};

SendInvoiceModal.defaultProps = {
  validateInvoiceId: null,
  purpose: null,
  customerAccountId: undefined
};

export default SendInvoiceModal;
