/**
 * Handles changes in the planning header by updating planning type filters and filters.
 *
 * @param {Function} setPlanningTypeFilters - Function to update the planning type filters.
 * @param {Function} setFilters - Function to update the general filters.
 * @param {Array} initialFilters - The initial filters to reset to when certain conditions are met.
 * @param {Array} value - The array of selected filter values.
 *
 * @returns {void}
 *
 * @example
 * planningHeaderOnChange(setPlanningTypeFilters, setFilters, initialFilters, value);
 *
 * - If the `value` array contains any of ['ADAPTATION', 'REGULAR', 'OCCASIONAL'],
 *   the filters will reset to `initialFilters`.
 * - Otherwise, the last filter in the array will be removed.
 */
export const planningHeaderOnChange = (
  setPlanningTypeFilters,
  setFilters,
  initialFilters,
  value
) => {
  setPlanningTypeFilters(() => {
    setFilters((prev) => {
      const updatedState = [...prev];
      if (
        value.some((el) => ['ADAPTATION', 'REGULAR', 'OCCASIONAL'].includes(el))
      ) {
        return initialFilters;
      }

      return updatedState.slice(0, -1);
    });
    return value;
  });
};
