import { formatNumberWithSpaces } from '../../../../utils/formatter';

/**
 * Determines the suffix based on the provided code.
 *
 * @function
 *
 * @param {string} code - The code used to determine the suffix.
 * @returns {string} The suffix associated with the code.
 */
const suffix = (code) => {
  switch (code) {
    case 'F':
    case 'R':
    case 'N':
    case 'C2':
    case 'D2':
      return '%';
    case 'B':
    case 'I':
    case 'P':
    case 'A2':
    case 'B2':
      return '';
    default:
      return '€';
  }
};

/**
 * Renders the value of a record based on its code and adds a suffix based on the code.
 *
 * @function
 *
 * @param {Object} record - The record object containing code and value properties.
 * @param {string} record.code - The code associated with the record.
 * @param {number} record.value - The value of the record.
 * @returns {string} The rendered value with an optional suffix.
 */
export const renderValue = (record) => {
  if (['B', 'I', 'P', 'A2', 'B2'].includes(record.code)) {
    if (record.value === 0) {
      return '0';
    }
    return record.value;
  }
  return `${formatNumberWithSpaces(record.value)} ${suffix(record.code)}`;
};
