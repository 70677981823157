import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Flex, Radio, Select } from 'antd';
import { useState } from 'react';
import { WeekSwitcher } from './WeekSwitcher';
import { WeekTimes } from './WeekTimes';
import { planningTypeFiltersArray } from './utils';
import { planningHeaderOnChange } from './utils/planningHeaderOnChange';

/**
 * `PlanningHeader` is a React component that provides a header for the planning interface.
 * It includes a radio group for switching between weekly and monthly views, a week switcher
 * for navigating between different weeks, and a display for total planned and actual times.
 *
 * The component is designed to adapt based on whether it is being viewed in a parent's context.
 * It uses `useTranslation` for internationalization.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.selectedDay - The currently selected day in the planner.
 * @param {Function} props.setSelectedDay - Function to set the selected day.
 * @param {boolean} props.isParentView - Indicates if the planner is being viewed in a parent's context.
 * @param {string} props.planningMode - Current planning mode (week/month).
 * @param {Function} props.setPlanningMode - Function to set the planning mode.
 * @param {string} props.plannedTotalTime - The total planned time for the week/month.
 * @param {string} props.realTotalTime - The total actual time for the week/month.
 * @param {boolean} props.loading - Loading state of the data.
 * @param {array} props.planningTypeFilters - Array of type planning filters.
 * @param {function} props.setPlanningTypeFilters - Function to set the type planning filters.
 *
 * @returns {JSX.Element} The header component for the planning interface.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.selectedDay {string} - The currently selected day in the planner.
 * @propTypes.setSelectedDay {Function} - Function to set the selected day.
 * @propTypes.isParentView {boolean} - Indicates if the planner is being viewed in a parent's context. Required.
 * @propTypes.planningMode {string} - Current planning mode (week/month). Required.
 * @propTypes.setPlanningMode {Function} - Function to set the planning mode.
 * @propTypes.plannedTotalTime {string} - The total planned time for the week/month.
 * @propTypes.realTotalTime {string} - The total actual time for the week/month.
 */
export const PlanningHeader = ({
  selectedDay,
  setSelectedDay,
  planningMode,
  setPlanningMode,
  plannedTotalTime,
  realTotalTime,
  loading,
  planningTypeFilters,
  setPlanningTypeFilters
}) => {
  const { t } = useTranslation();
  const initialFilters = planningTypeFiltersArray(t);
  const [filters, setFilters] = useState(initialFilters);

  return (
    <Flex vertical gap="small">
      <Flex style={{ width: 'calc(100% - 140px)', maxWidth: 480 }}>
        <Select
          options={filters}
          mode="multiple"
          style={{ flex: '1 1 480px' }}
          value={planningTypeFilters}
          onChange={(value) =>
            planningHeaderOnChange(
              setPlanningTypeFilters,
              setFilters,
              initialFilters,
              value
            )
          }
          className="contains-tags"
        />
      </Flex>
      <Flex justify="space-between" align="center" wrap gap="small">
        <Radio.Group
          onChange={(e) => setPlanningMode(e.target.value)}
          defaultValue={planningMode}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value="week">
            {t('children.show.planning.options.weekly_view')}
          </Radio.Button>
          <Radio.Button value="month">
            {t('children.show.planning.options.monthly_view')}
          </Radio.Button>
        </Radio.Group>
        <WeekSwitcher
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          planningMode={planningMode}
        />
        <WeekTimes
          plannedTotalTime={plannedTotalTime}
          realTotalTime={realTotalTime}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

PlanningHeader.propTypes = {
  selectedDay: PropTypes.string,
  setSelectedDay: PropTypes.func,
  planningMode: PropTypes.string.isRequired,
  setPlanningMode: PropTypes.func,
  plannedTotalTime: PropTypes.string,
  realTotalTime: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  planningTypeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPlanningTypeFilters: PropTypes.func.isRequired
};

PlanningHeader.defaultProps = {
  selectedDay: undefined,
  setSelectedDay: null,
  setPlanningMode: null,
  plannedTotalTime: undefined,
  realTotalTime: undefined
};
