import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * DescriptionList component for rendering a list of descriptions with titles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the description list.
 * @param {Array|Object} props.data - The data to be displayed as descriptions.
 * @param {boolean} props.skipEmpty - Whether to skip rendering empty descriptions.
 * @param {boolean} props.translate - Whether to translate the description labels.
 * @param {string} props.layout - The layout of the description list (horizontal, vertical, etc.).
 * @returns {React.Element} The rendered DescriptionList component.
 */
export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  layout
}) => {
  const { t } = useTranslation();
  return (
    <Descriptions title={title} layout={layout} colon={false}>
      {data.map(
        (item) =>
          (item.content !== '-' || !skipEmpty) && (
            <Descriptions.Item
              label={translate ? t(`${item.label}`) : item.label}
              span={item.span || 1}
              key={item.label}
            >
              <Typography style={{ fontWeight: 500 }}>
                {item?.content || '-'}
              </Typography>
            </Descriptions.Item>
          )
      )}
    </Descriptions>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  layout: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.object
  ]).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  layout: ''
};
