/**
 * Handles the active key change based on the current step and direction.
 *
 * @function
 *
 * @param {string[]} steps - An array of steps in the process.
 * @param {function} setStep - Function to update the current step.
 * @param {string} direction - The direction of navigation ('forward' or 'backward').
 *
 * @returns {void}
 */
export const handleActiveKey = (steps, setStep, direction) => {
  setStep((prev) => {
    switch (true) {
      case prev === 'config' && steps.length === 2 && direction === 'forward':
      case prev === 'header' && direction === 'forward':
      case prev === 'footer' && direction === 'backward':
        return 'body';
      case prev === 'body' && direction === 'forward':
        return 'footer';
      case prev === 'config' && direction === 'forward':
      case prev === 'body' && steps.length > 2 && direction === 'backward':
        return 'header';
      case prev === 'body' && direction === 'backward':
      case prev === 'header' && direction === 'backward':
        return 'config';
      default:
        return false;
    }
  });
};
