import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form, Input, Button, Switch, Divider, Checkbox } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { drawerFormLayout } from '../../../utils/constants/formLayout';

const { Option } = Select;

/**
 * Custom hook for generating contacts fields data for customer_accoun form.
 *
 * @hook
 * @returns {object} Object containing contacts fields and loading status
 * @property {Array} fields - Array of field objects for contacts form
 * @property {boolean} isFieldsLoading - Loading status for field data
 */

const useContactsFields = (validateInvoiceId) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  useEffect(() => {
    if (validateInvoiceId) setIsFieldsLoading(false);
  }, []);

  const contactsFields = [
    {
      name: ['invoice'],
      noLabel: true,
      hidden: true,
      initialValue: validateInvoiceId
    },
    {
      name: ['contacts'],
      noLabel: true,
      input: (
        <Form.List name="contacts" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.name} style={{ position: 'relative' }}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'is_principal']}
                    name={[field.name, 'is_principal']}
                    valuePropName="checked"
                    label={t('customer-account.form.is_principal')}
                    {...drawerFormLayout}
                  >
                    <Switch
                      checkedChildren={t('yes')}
                      unCheckedChildren={t('no')}
                    />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'var(--errorColor)',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'contact_label']}
                    name={[field.name, 'contact_label']}
                    label={t('customer-account.form.contact_label')}
                    rules={[{ required: true }]}
                    {...drawerFormLayout}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'email']}
                    name={[field.name, 'email']}
                    label={t('customer-account.form.email')}
                    rules={[{ required: true }, { type: 'email' }]}
                    {...drawerFormLayout}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'mobile_phone_number']}
                    name={[field.name, 'mobile_phone_number']}
                    label={t('customer-account.form.mobile_phone_number')}
                    {...drawerFormLayout}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        key={[
                          field.name,
                          'mobile_phone_number',
                          'country_code'
                        ]}
                        name={[
                          field.name,
                          'mobile_phone_number',
                          'country_code'
                        ]}
                        initialValue="+33"
                      >
                        <Select style={{ width: '25%' }}>
                          <Option value="+33">+33</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        key={[field.name, 'mobile_phone_number', 'number']}
                        name={[field.name, 'mobile_phone_number', 'number']}
                      >
                        <Input type="number" style={{ width: '75%' }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'landline_phone_number']}
                    name={[field.name, 'landline_phone_number']}
                    label={t('customer-account.form.landline_phone_number')}
                    {...drawerFormLayout}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        key={[
                          field.name,
                          'landline_phone_number',
                          'country_code'
                        ]}
                        name={[
                          field.name,
                          'landline_phone_number',
                          'country_code'
                        ]}
                        initialValue="+33"
                      >
                        <Select style={{ width: '25%' }}>
                          <Option value="+33">+33</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        key={[field.name, 'landline_phone_number', 'number']}
                        name={[field.name, 'landline_phone_number', 'number']}
                      >
                        <Input type="number" style={{ width: '75%' }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'send_email']}
                    name={[field.name, 'send_email']}
                    label={t('customer-account.form.send_email')}
                    valuePropName="checked"
                    {...drawerFormLayout}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Divider />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('customer-account.form.add_contact')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
  return {
    fields: contactsFields,
    isFieldsLoading
  };
};

export default useContactsFields;
