import { EventsShowRender } from './TabsContent/Events/EventsShowRender';
import { NewsShowRender } from './TabsContent/News/NewsShowRender';

/**
 * Hook for rendering different types of content based on the type of resource in a show context.
 *
 * @hook
 *
 * @param {Object} resource - The resource to render.
 * @param {Function} deleteFileById - The function to delete a file by its ID.
 * @returns {Object} An object containing JSX elements for rendering different types of content.
 */
export const useShowRender = (resource, deleteFileById) => ({
  news: <NewsShowRender resource={resource} deleteFileById={deleteFileById} />,
  events: (
    <EventsShowRender resource={resource} deleteFileById={deleteFileById} />
  )
});
