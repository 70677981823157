import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createTitle, handleChangeTime } from './utils';

/**
 * `WeekSwitcher` is a React component for navigating between weeks in a planning or calendar view.
 * It allows the user to go to the previous or next week relative to the currently selected day.
 *
 * The component uses dayjs for date manipulation and displays the week number and date range of the current week.
 * It uses Ant Design's `Button`, `Col`, and `Row` components for layout and navigation icons.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.selectedDay - The currently selected day in ISO format.
 * @param {Function} props.setSelectedDay - Function to set the selected day.
 * @param {string} props.planningMode - Mode of the planning.
 *
 * @returns {JSX.Element} A component that provides buttons to navigate to the previous or next week and displays the current week's date range.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.selectedDay {string} - The currently selected day in ISO format.
 * @propTypes.setSelectedDay {Function} - Function to set the selected day.
 */
export const WeekSwitcher = ({ selectedDay, setSelectedDay, planningMode }) => {
  const { t } = useTranslation();

  const title = createTitle(planningMode, selectedDay, t);

  return (
    <Flex justify="center" align="center">
      <Button
        type="link"
        onClick={() =>
          handleChangeTime(
            'substract',
            planningMode,
            selectedDay,
            setSelectedDay
          )
        }
      >
        <LeftCircleFilled
          style={{ color: 'var(--primaryColor)', fontSize: 24 }}
        />
      </Button>
      <Flex vertical style={{ padding: 8, marginRight: 24, marginLeft: 24 }}>
        <Flex justify="center" className="planning-week-title">
          {title}
        </Flex>
        <Flex justify="center" style={{ color: 'var(--disabledColor)' }}>
          <Flex vertical>{`${dayjs(selectedDay)
            .startOf(planningMode)
            .format('DD/MM/YYYY')} - ${dayjs(selectedDay)
            .endOf(planningMode)
            .format('DD/MM/YYYY')}`}</Flex>
        </Flex>
      </Flex>
      <Button
        type="link"
        onClick={() =>
          handleChangeTime('add', planningMode, selectedDay, setSelectedDay)
        }
      >
        <RightCircleFilled
          style={{ color: 'var(--primaryColor)', fontSize: 24 }}
        />
      </Button>
    </Flex>
  );
};

WeekSwitcher.propTypes = {
  selectedDay: PropTypes.string,
  setSelectedDay: PropTypes.func,
  planningMode: PropTypes.string
};

WeekSwitcher.defaultProps = {
  selectedDay: undefined,
  setSelectedDay: null,
  planningMode: undefined
};
