import dayjs from 'dayjs';
import { days } from '../../../utils/days';

/**
 * @description
 * getHourMinuteDiff is a function that returns an object with
 * two numbers hoursDiff and minutesDiff.
 * hoursDiff is the difference between the end_time and start_time in hours.
 * minutesDiff is the difference between the end_time and start_time in minutes.
 * The hoursDiff and minutesDiff are returned.
 *
 * @function
 * @param {String} start_time - The start time
 * @param {String} end_time - The end time
 * @returns {Object} An object with two numbers
 */
const getHourMinuteDiff = (start_time, end_time) => {
  const hoursDiff = dayjs(end_time, 'HH:mm').diff(
    dayjs(start_time, 'HH:mm'),
    'hour'
  );
  const minutesDiff =
    dayjs(end_time, 'HH:mm').diff(dayjs(start_time, 'HH:mm'), 'minute') % 60;

  return { hoursDiff, minutesDiff };
};

/**
 * @description
 * getTotalHoursAndMinutesForWeek is a function that returns an object with
 * two numbers totalHours and totalMinutes.
 * totalHours is the total number of hours selected for the week.
 * totalMinutes is the total number of minutes selected for the week.
 * The totalMinutes are converted to hours and added to the totalHours.
 * The totalMinutes are also converted to minutes and added to the totalMinutes.
 * The totalHours and totalMinutes are returned.
 *
 * @function
 * @param {Object} week - The reference week
 * @returns {Object} An object with two numbers
 */
export const getTotalHoursAndMinutesForWeek = (week) => {
  let totalHours = 0;
  let totalMinutes = 0;

  days.forEach((_, index) => {
    const timeSlot1 = week?.[index]?.time_slots[0];
    const timeSlot2 = week?.[index]?.time_slots[1];

    if (timeSlot1 && timeSlot1.start_time && timeSlot1.end_time) {
      const { hoursDiff, minutesDiff } = getHourMinuteDiff(
        timeSlot1.start_time,
        timeSlot1.end_time
      );
      totalHours += hoursDiff;
      totalMinutes += minutesDiff;
    }

    if (timeSlot2 && timeSlot2.start_time && timeSlot2.end_time) {
      const { hoursDiff, minutesDiff } = getHourMinuteDiff(
        timeSlot2.start_time,
        timeSlot2.end_time
      );

      totalHours += hoursDiff;
      totalMinutes += minutesDiff;
    }
  });

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes = Math.ceil(totalMinutes % 60);
  return { totalHours, totalMinutes };
};
