import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Typography, Button, Flex } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import { useWelfareFields } from '../../../Contracts/Fields/welfareFields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';

/**
 * UpdateCafModal component - Displays a modal for updating the CAF (Caisse d'Allocations Familiales) information of a contract.
 *
 * @component
 * @param {boolean} isModalCafOpen - Indicates whether the CAF modal is open.
 * @param {function} closeModalCaf - Function to close the CAF modal.
 * @param {Object} contract - The contract object containing household and parent information.
 * @param {function} patchCaf - Function to patch the CAF information of the contract.
 * @returns {JSX.Element} The rendered component.
 */
const UpdateCafModal = ({
  isModalCafOpen,
  closeModalCaf,
  contract,
  patchCaf
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { fields } = useWelfareFields();

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await patchCaf(values);
    setIsSubmitting(false);
  };

  useEffect(() => {
    form.setFields([
      {
        name: ['household', 'beneficiary_number'],
        value: contract?.household?.beneficiary_number
      },
      {
        name: ['household', 'dependent_children'],
        value: contract?.household?.dependent_children
      },
      {
        name: ['household', 'dependent_children_with_disabilities'],
        value: contract?.household?.dependent_children_with_disabilities
      },
      {
        name: ['household', 'annual_incomes'],
        value: contract?.household?.annual_incomes
      },
      ...(contract?.parents || []).map((parent, index) => ({
        name: ['parents', index, 'parent', 'social_security_system'],
        value: parent.parent.social_security_system
      }))
    ]);
  }, [contract, form]);

  const render = () => {
    if (contract?.household?.monoparental_family) {
      return fields
        .slice(0, 5)
        .map((field) => generateFields('contracts', field));
    }
    return fields.map((field) => generateFields('contracts', field));
  };
  return (
    <Modal
      centered
      width={800}
      footer={false}
      open={isModalCafOpen}
      onCancel={() => {
        closeModalCaf();
      }}
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('contract.modalCAF.title')}
        </Typography.Title>
      }
    >
      <Form form={form} onFinish={handleSubmit} {...formItemLayout}>
        {render()}
        <Flex justify="flex-end" gap="small">
          <Button
            style={{ margin: '0 8px' }}
            type="link"
            danger
            onClick={() => closeModalCaf()}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {`${t('buttons.validate')} `}
            <CheckOutlined />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

UpdateCafModal.propTypes = {
  isModalCafOpen: PropTypes.bool.isRequired,
  closeModalCaf: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    household: PropTypes.shape({
      beneficiary_number: PropTypes.string,
      dependent_children: PropTypes.number,
      dependent_children_with_disabilities: PropTypes.number,
      annual_incomes: PropTypes.number,
      monoparental_family: PropTypes.bool
    }),
    parents: PropTypes.shape({
      parent: PropTypes.shape({
        social_security_system: PropTypes.string
      })
    })
  }),
  patchCaf: PropTypes.func.isRequired
};

UpdateCafModal.defaultProps = {
  contract: {}
};

export default UpdateCafModal;
