import { useState } from 'react';
import { Form, Input, Select, Upload, DatePicker, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../utils/errorMessage';
import { handleDepartment } from '../../utils/handleDepartment';

const { Option } = Select;
const { Dragger } = Upload;

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Custom hook to generate form fields for creating or editing an employee profile.
 *
 * This hook prepares an array of field configurations used to render a dynamic form with Ant Design components. It includes handling for
 * uploading and previewing employee photos, input validation, and dynamic loading of select options based on the provided enums, team positions,
 * and children groups data. It utilizes custom utility functions like `fileToBase64` for converting uploaded files to base64 strings and
 * `handleDepartment` for updating form values based on department changes. The hook supports both creation and edition purposes, adjusting
 * fields availability and rules accordingly.
 *
 * @param {string} purpose - Indicates the purpose of the form, either 'create' or 'edit'.
 * @param {boolean} isFieldsLoading - Indicates if select options are currently being loaded.
 * @param {Object} enums - Contains enum values for select options, like contract types.
 * @param {Array} teamPositions - Array of team positions for the 'position' select field.
 * @param {Array} childrenGroups - Array of children groups for the 'children_groups' select field.
 * @param {Object} form - Ant Design form instance for managing form state.
 * @returns {Object} An object containing two properties: `fields`, an array of field configurations for rendering the form, and `base64`, a state holding the base64 string of the uploaded file.
 */
export const useFields = (
  purpose,
  isFieldsLoading,
  enums,
  teamPositions,
  childrenGroups,
  form
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [displays, setDisplays] = useState({
    childrenGroups: false,
    referenceWeeks: false
  });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([file]);
        return true;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const handlePositionSelection = (value) => {
    const selectedPosition = teamPositions.find(
      (position) => position._id === value
    );

    setDisplays((prevState) => {
      const updatedState = { ...prevState };
      if (selectedPosition?.child_management) {
        updatedState.childrenGroups = true;
      } else {
        updatedState.childrenGroups = false;
      }
      return updatedState;
    });
  };

  const handleWeekManagementSelection = (value) => {
    const boolean = value === 'REFERENCE_WEEKS';

    setDisplays((prevState) => {
      const updatedState = { ...prevState };
      updatedState.referenceWeeks = boolean;
      return updatedState;
    });
  };

  const fields = [
    {
      label: 'photo',
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'last_name',
      name: ['last_name'],
      rules: [{ required: true }],
      startWithDivider: {
        orientation: 'left',
        title: t('employees.form.coordinates')
      }
    },
    {
      label: 'first_name',
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'phone_number.number',
      name: ['mobile_phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: purpose === 'edit'
    },
    {
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional']
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }],
      input: (
        <Input
          type="number"
          onChange={(e) => handleDepartment(e, form, ['address', 'state'])}
        />
      )
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'state'],
      rules: [{ required: true }]
    },
    {
      startWithDivider: {
        orientation: 'left',
        title: t('employees.form.contract')
      },
      label: 'position',
      name: ['position'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          onSelect={(value) => handlePositionSelection(value)}
        >
          {(teamPositions || []).map((position) => (
            <Option key={position._id} value={position._id}>
              <Tag color={position?.color?.hex}>{position.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    ...(displays.childrenGroups
      ? [
          {
            label: 'children_groups',
            name: ['children_groups'],
            input: (
              <Select loading={isFieldsLoading} mode="multiple" allowClear>
                {(childrenGroups || []).map((group) => (
                  <Option key={group._id} value={group._id}>
                    <Tag color={group?.tag_color}>{group?.label}</Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      label: 'contract_start_date',
      name: ['contract_start_date'],
      input: <DatePicker format="DD/MM/YYYY" />,
      rules: [{ required: true }]
    },
    {
      label: 'contract_end_date',
      name: ['contract_end_date'],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      label: 'contract_hours_per_week',
      name: ['contract_hours_per_week'],
      input: <Input type="number" />,
      rules: [{ required: true }]
    },
    {
      label: 'contract_type',
      name: ['contract_type'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.contract_types || []).map((contractType) => (
            <Option key={contractType} value={contractType}>
              {t(`employees.enums.contract_types.${contractType}`)}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'week_management',
      name: ['week_management'],
      input: (
        <Select
          loading={isFieldsLoading}
          onSelect={(value) => handleWeekManagementSelection(value)}
        >
          {(enums?.week_management || []).map((contractType) => (
            <Option key={contractType} value={contractType}>
              {t(`employees.enums.contract_types.${contractType}`)}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    }
  ];

  return {
    fields,
    fileList,
    displays,
    setDisplays
  };
};
