import { useTranslation } from 'react-i18next';
import { Card, Col, Collapse, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import { useColumns } from './columns';

export const Invoices = ({ invoiceSchedule }) => {
  const { t } = useTranslation();

  const columns = useColumns();

  const items = (invoiceSchedule || []).map((invoice) => ({
    key: invoice.date,
    label: (
      <Row justify="space-between">
        <Col>{invoice.date}</Col>
        <Col>{`${invoice.all_included_total.toFixed(2)} €`}</Col>
      </Row>
    ),
    children: (
      <Table
        columns={columns}
        dataSource={[invoice]}
        pagination={false}
        scroll={{ x: 1000 }}
      />
    )
  }));

  return (
    <Card
      title={t('pre-registrations.form.summary.invoices')}
      bordered={false}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Collapse items={items} />
    </Card>
  );
};

Invoices.propTypes = {
  invoiceSchedule: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      all_included_total: PropTypes.number
    })
  )
};

Invoices.defaultProps = {
  invoiceSchedule: []
};
