import { useCallback, useEffect, useState } from 'react';
import { Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;

/**
 * Custom hook for managing form fields related to diet information.
 *
 * @returns {Object} An object containing fields and loading state for diet-related form fields.
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [allergies, setAllergies] = useState([]);

  const fields = [
    {
      name: ['meal_type'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.meal_types || []).map((type) => (
            <Option key={type} value={type}>
              {t(`children.form.meal_types.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['vegetarian'],
      valuePropName: 'checked',
      rules: [{ required: true }],
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['special_meat_diet'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={false}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    },
    {
      name: ['allergies'],
      input: (
        <Select mode="multiple" allowClear showSearch>
          {(allergies || []).map((allergy) => (
            <Option key={allergy._id} value={allergy._id}>
              {allergy.label}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/diets/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAllergies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/food-allergies' });
      setAllergies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getAllergies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
