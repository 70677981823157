import dayjs from 'dayjs';

/**
 * @description
 * isTimeSelected is a function that returns an object with three booleans
 * isFirstHalf, isSecondHalf and fullDay.
 * isFirstHalf is true if the first half of the day is selected.
 * isSecondHalf is true if the second half of the day is selected.
 * fullDay is true if the full day is selected.
 *
 * @function
 * @param {Object} referenceWeeks - The reference weeks
 * @param {number} index - The index of the reference week
 * @param {number} dayIndex - The index of the day of the week
 * @returns {Object} An object with three booleans
 */
export const isTimeSelected = (referenceWeeks, index, dayIndex) => {
  const timeSlot1 = referenceWeeks[index]?.[dayIndex]?.time_slots[0];
  const timeSlot2 = referenceWeeks[index]?.[dayIndex]?.time_slots[1];

  // dayjs the dates
  if (timeSlot1) {
    timeSlot1.start_time = timeSlot1?.start_time
      ? dayjs(timeSlot1.start_time, 'HH:mm')
      : timeSlot1?.start_time;
    timeSlot1.end_time = timeSlot1.end_time
      ? dayjs(timeSlot1.end_time, 'HH:mm')
      : timeSlot1?.end_time;
  }
  if (timeSlot2) {
    timeSlot2.start_time = timeSlot2.start_time
      ? dayjs(timeSlot2.start_time, 'HH:mm')
      : timeSlot2?.start_time;
    timeSlot2.end_time = timeSlot2.end_time
      ? dayjs(timeSlot2.end_time, 'HH:mm')
      : timeSlot2?.end_time;
  }

  const isFirstHalf =
    (timeSlot1 && timeSlot1.start_time?.hour() < 12) ||
    (timeSlot2 && timeSlot2.start_time?.hour() < 12);
  const isSecondHalf =
    (timeSlot1 && timeSlot1.start_time?.hour() >= 12) ||
    (timeSlot2 && timeSlot2.start_time?.hour() >= 12);
  const fullDay =
    (timeSlot1 &&
      timeSlot1.start_time?.hour() < 12 &&
      timeSlot1 &&
      timeSlot1.end_time?.hour() > 12) ||
    (timeSlot2 &&
      timeSlot2.start_time?.hour() < 12 &&
      timeSlot2 &&
      timeSlot2.end_time?.hour() > 12);
  return { isFirstHalf, isSecondHalf, fullDay };
};
