import dayjs from 'dayjs';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Content for the list.
 * @hook
 * @param {Object} data The data to display.
 * @returns {Array} The content for the list.
 */
export const useListContent = (data = {}) => {
  const {
    name,
    address,
    email,
    phone_number,
    capacity,
    supply_meals_diappers,
    stamp,
    opening_date,
    manager_signature,
    logo,
    number_of_staff_children_walking,
    number_of_staff_children_not_walking
  } = data;
  const { t } = useTranslation();

  return [
    {
      label: t('daycares.form.name'),
      content: name || '-'
    },
    {
      label: t('daycares.form.email'),
      content: email || '-'
    },
    {
      label: t('daycares.form.phone_number.number'),
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                        `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: t('daycares.form.address_full'),
      content:
        address && address.number !== '' ? (
          <>
            {address.number} {address.street}
            <br />
            {address.additional}
            {address.additional && <br />}
            {address.postal_code} {address.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: t('daycares.form.capacity'),
      content: capacity || '-'
    },
    {
      label: t('daycares.form.opening_date'),
      content: opening_date ? dayjs(opening_date).format('DD/MM/YYYY') : '-'
    },
    {
      label: t('daycares.form.supply_meals_diappers'),
      content: supply_meals_diappers ? t('yes') : t('no')
    },
    {
      label: t('daycares.form.number_of_staff_children_walking'),
      content: number_of_staff_children_walking || '-'
    },
    {
      label: t('daycares.form.number_of_staff_children_not_walking'),
      content: number_of_staff_children_not_walking || '-'
    },
    {
      label: t('daycares.form.stamp'),
      content: stamp ? <Avatar size={64} src={stamp} /> : '-'
    },
    {
      label: t('daycares.form.logo'),
      content: logo ? <Avatar size={64} src={logo} /> : '-'
    },
    {
      label: t('daycares.form.manager_signature'),
      content: manager_signature ? (
        <Avatar size={64} src={manager_signature} />
      ) : (
        '-'
      )
    }
  ];
};
