/**
 * For each form step, updates the pre-registration object based on the given resource and values.
 *
 * @function
 * @param {string} resource - The resource to update. (ex: 'additional_info')
 * @param {object} values - The values to update the pre-registration with.
 * @param {function} setPreRegistration - The function to update the pre-registration state.
 * @param {object} preRegistration - The current pre-registration object.
 * @returns {void}
 */
export default (resource, values, setPreRegistration, preRegistration) => {
  switch (resource) {
    case 'additional_info':
      setPreRegistration({
        ...preRegistration,
        contract: { ...preRegistration.contract, ...values.contract },
        authorizations: {
          ...preRegistration.authorizations,
          ...values.authorizations
        },
        health: {
          ...preRegistration.health,
          ...values.health
        },
        diet: {
          ...preRegistration.diet,
          ...values.diet
        }
      });
      break;
    case 'customer_account':
      setPreRegistration({
        ...preRegistration,
        customer_account: values.customer
          ? {
              customer: values.customer,
              already_exists: true
            }
          : values.customer_nature &&
            values.billing_address &&
            values.contacts &&
            values.label && {
              already_exists: false,
              customer_nature: values.customer_nature,
              billing_address: values.billing_address,
              contacts: values.contacts,
              label: values.label
            }
      });
      break;
    case 'payment_method':
      if (values.method === 'CARD') {
        setPreRegistration({
          ...preRegistration,
          contract: {
            ...preRegistration.contract
          }
        });
      } else {
        setPreRegistration({
          ...preRegistration,
          contract: {
            ...preRegistration.contract,
            rib: {
              ...preRegistration.contract.rib,
              ...values
            }
          }
        });
      }

      break;
    default:
      break;
  }
};
