import { ParentDocumentsList } from './ParentDocumentsList';

/**
 * Hook for generating parent document tabs based on parent documents data.
 *
 * @hook
 *
 * @param {Array} parentDocuments - Array of parent documents data.
 * @param {boolean} forceRefresh - Boolean to force a refresh of the documents.
 * @param {Function} setForceRefresh - Function to set the forceRefresh state.
 * @returns {Array} Array of tab objects containing label, key, and children components.
 */
export const useParentDocumentTabs = (
  parentDocuments,
  forceRefresh,
  setForceRefresh
) =>
  parentDocuments.map((child) => ({
    label: `${child.first_name || ''}`,
    key: child._id,
    children: (
      <ParentDocumentsList
        childID={child._id}
        documents={child.documents}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
    )
  }));
