import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { Document as PdfDocument, Page, pdfjs } from 'react-pdf';

// Set the PDF worker script URL. This is required for the PDF document to render.
// The purpose of a worker is to perform heavy computations without blocking the main thread.
// We are using the worker from the PDF.js library - hosted on a CDN.
// If you are having "Failed to load PDF" errors, you may need to host the worker yourself.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Renders a PDF document with navigation buttons for paging through the document.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.currentFile - The current file object containing the blob URL of the PDF document. (example: { ...currentFile, url: 'blob:http://localhost:3000/aee672c3-1430-4983-bd33-1af3b6dafa08' })
 * @param {number} props.pageNumber - The current page number.
 * @param {Function} props.setPageNumber - The function to update the current page number.
 * @param {Function} props.setDocumentRead - The function to update the display of the read and approved checkbox.
 * @returns {JSX.Element} The rendered PDF document component.
 */
export const Document = ({
  currentFile,
  pageNumber,
  setPageNumber,
  setDocumentRead
}) => {
  const { t } = useTranslation();
  const [numOfPages, setNumOfPages] = useState();
  const isLastPage = pageNumber === numOfPages;
  const isFirstPage = pageNumber === 1;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  const nextPage = () => {
    const nextPageNumber = pageNumber + 1;
    if (nextPageNumber <= numOfPages) {
      setPageNumber(nextPageNumber);
    }
    window.scrollTo(0, 0);
  };

  return (
    <PdfDocument file={currentFile.url} onLoadSuccess={onDocumentLoadSuccess}>
      <Page
        scale={1.5}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        pageNumber={pageNumber}
      />
      <Flex gap="middle" align="center" justify="center">
        <Button
          disabled={isFirstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          {t('buttons.previous')}
        </Button>
        <Typography>{`${pageNumber} / ${numOfPages}`}</Typography>
        <Button
          onClick={() => (isLastPage ? setDocumentRead(true) : nextPage())}
          type={isLastPage ? 'primary' : 'default'}
        >
          {t(isLastPage ? 'buttons.sign_contract' : 'buttons.next')}
        </Button>
      </Flex>
    </PdfDocument>
  );
};

Document.propTypes = {
  currentFile: PropTypes.shape({
    url: PropTypes.string
  }),
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  setDocumentRead: PropTypes.func
};

Document.defaultProps = {
  currentFile: {},
  pageNumber: 1,
  setPageNumber: () => {},
  setDocumentRead: () => {}
};
