import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/tabletRoutes';

/**
 * A navigation menu component specifically designed for tablet-sized screens.
 *
 * This component displays a navigation menu using Ant Design's `Menu` component.
 * It supports nesting of routes and automatically sets the active menu item
 * based on the current route. It also provides an option to collapse the menu
 * on selection, which is useful for responsive layouts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - A function to set the collapsed state of the navigation menu.
 * @returns {JSX.Element} The JSX rendering of the navigation menu.
 *
 * @example
 * const handleCollapse = (isCollapsed) => {
 *   // handle collapse state
 * };
 * return (
 *   <TabletNavMenu setCollapsed={handleCollapse} />
 * )
 */
export const TabletNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              )
            }
      )}
    />
  );
};

TabletNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
