import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  invoiceStatuses,
  invoiceTypes
} from '../../../../utils/constants/tagColors';

/**
 * Custom hook for generating informations content data for displaying invoice informations details in a list format.
 *
 * @hook
 * @param {object} data - Invoice data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useInformationsContent = (data = {}) => {
  const { t } = useTranslation();

  const { number, status, created_at, due_date, type } = data;

  return [
    {
      label: 'invoices.show.number',
      span: 1,
      content: number || '-'
    },
    {
      label: 'invoices.show.status',
      span: 1,
      content: (
        <Tag color={invoiceStatuses[status]}>
          {t(`invoices.tags.${status || 'N/A'}`)}
        </Tag>
      )
    },
    {
      label: 'invoices.show.type',
      span: 1,
      content: (
        <Tag color={invoiceTypes[type]}>
          {t(`invoices.tags.${type || 'N/A'}`)}
        </Tag>
      )
    },
    {
      label: 'invoices.show.created_at',
      span: 2,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'invoices.show.due_date',
      span: 2,
      content: (due_date && dayjs(due_date).format('DD/MM/YYYY')) || '-'
    }
  ];
};
