import { Button, Card, Col, Flex, Row, Skeleton, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { calculateEntryAge } from './utils/entryAge';
import { preRegistrationStatus } from '../../utils/constants/tagColors';
import { calculateContractDuration } from './utils/contractDuration';
import { routes } from '../../utils/constants/adminRoutes';
import { PlanningCircles } from '../../components/PlanningCircles/PlanningCircles';
import { PreRegistrationsMethods } from './methods';

/**
 * PreRegistrationCard component displays details of a pre-registration in card format.
 * It includes child's name, age, entry and exit date, status, incomes, and other details.
 *
 * @param {Object} props - Properties passed to the component.
 * @param {Object} props.preregistration - Object containing pre-registration details.
 * @param {Object} props.preregistration.child - Child's details.
 * @param {string} props.preregistration.child.first_name - First name of the child.
 * @param {string} props.preregistration.child.last_name - Last name of the child.
 * @param {string} props.preregistration.child.birth_date - Birth date of the child.
 * @param {Object} props.preregistration.contract_infos - Contract information.
 * @param {string} props.preregistration.contract_infos.start_date - Contract start date.
 * @param {string} props.preregistration.contract_infos.end_date - Contract end date.
 * @param {Object[]} props.preregistration.contract_infos.reference_weeks - Reference weeks data.
 * @param {string} props.preregistration.status - Status of the pre-registration.
 * @param {Object} props.preregistration.household - Household details.
 * @param {number} props.preregistration.household.annual_incomes - Annual incomes.
 * @param {Object[]} props.preregistration.daycares - An array of the pre-registration status for the current daycare.
 * @param {string} props.preregistration._id - ID of the pre-registration.
 * @param {boolean} [props.isLoading=false] - Flag to determine if the component is in a loading state.
 *
 * @returns {React.ElementType} Rendered component.
 */
export const PreRegistrationCard = ({ preregistration, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getStatusByDaycare } = PreRegistrationsMethods();

  const { child, contract_infos, household, daycares, _id } = preregistration;
  const status = getStatusByDaycare(daycares);

  const entryAge = calculateEntryAge(
    child.birth_date,
    contract_infos.start_date,
    t
  );

  const contractDuration = calculateContractDuration(
    contract_infos.start_date,
    contract_infos.end_date,
    t
  );

  const nameColor = (gender) => {
    if (gender === 'BOY') {
      return 'var(--logoBlue)';
    }
    if (gender === 'GIRL') {
      return 'var(--logoPurple)';
    }
    return 'var(--mediumGray)';
  };

  return (
    <Skeleton loading={isLoading}>
      <Card style={{ width: '100%' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={14}>
            <Row gutter={[8, 8]}>
              <Col xs={24} xl={16}>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={9}>
                    <Col
                      style={{
                        color: nameColor(child?.gender),
                        fontWeight: 500
                      }}
                    >{`${child.first_name} ${child.last_name}`}</Col>
                    <Col>{entryAge}</Col>
                  </Col>
                  <Col xs={24} md={7}>
                    {status ? (
                      <Tag color={preRegistrationStatus[status]}>
                        {t(`pre-registrations.form.status.${status}`)}
                      </Tag>
                    ) : (
                      '-'
                    )}
                  </Col>
                  <Col xs={24} md={8}>
                    <Col>{`${t('pre-registrations.form.entry')} : ${dayjs(
                      contract_infos.start_date
                    ).format('DD/MM/YYYY')}`}</Col>
                    <Col>{`${t('pre-registrations.form.exit')} : ${dayjs(
                      contract_infos.end_date
                    ).format('DD/MM/YYYY')}`}</Col>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} xl={8}>
                <Row gutter={[8, 8]}>
                  {' '}
                  <Col
                    xs={24}
                    md={8}
                    style={{ color: 'var(--primaryColor)', fontWeight: 500 }}
                  >
                    {contractDuration}
                  </Col>
                  <Col xs={24} md={16}>
                    {`${t('pre-registrations.form.incomes')} : ${
                      household.annual_incomes
                    } €`}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xxl={10}>
            <Row gutter={[8, 8]}>
              <Col xs={22} md={23} xxl={22}>
                <Flex gap="middle" align="center">
                  <PlanningCircles
                    referenceWeeks={contract_infos.reference_weeks}
                  />
                </Flex>
              </Col>
              <Col xs={2} md={1} xxl={2}>
                <Button
                  type="link"
                  onClick={() =>
                    navigate(`${routes.PRE_REGISTRATION}/show/${_id}`)
                  }
                >
                  <EyeOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Skeleton>
  );
};

PreRegistrationCard.propTypes = {
  preregistration: PropTypes.shape({
    child: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      birth_date: PropTypes.string,
      gender: PropTypes.string
    }),
    contract_infos: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      reference_weeks: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    status: PropTypes.string,
    household: PropTypes.shape({
      annual_incomes: PropTypes.number
    }),
    daycares: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        status: PropTypes.string
      })
    ),
    _id: PropTypes.string
  }),
  isLoading: PropTypes.bool
};

PreRegistrationCard.defaultProps = {
  preregistration: null,
  isLoading: false
};
