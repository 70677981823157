import { Button, Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Custom hook for generating columns configuration for a label-based list.
 *
 * @hook
 * @param {function} setRefresh A function to set the refresh state.
 * @param {function} handleModal The function to open the modal.
 * @param {string} resourceName The name of the resource being managed.
 * @returns {Array} An array of column configuration objects for label-based lists.
 */
export const useLabelColumns = (setRefresh, handleModal, resourceName) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteDocument = (id) => {
    try {
      dispatchAPI('DELETE', {
        url: `/${resourceName}/${id}`
      });
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('global_settings.dynamic_lists.columns.label'),
      dataIndex: 'label',
      key: 'label',
      sorter: true
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => handleModal(record, 'edit', resourceName)}
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
