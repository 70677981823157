import React from 'react';
import { Col, Form, Input, Select, Checkbox, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';

const { Option } = Select;

/**
 * FormInfo is a component that displays a form inside a card component. The form includes fields for
 * entering details like title, status, use case configuration and a checkbox to mark as default template.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string[]} props.status - An array of status strings to be displayed as options in the status select field.
 * @param {Object[]} props.configurations - An array of configuration objects to be displayed as options in the use case select field.
 * @param {Function} [props.setPickedConfiguration] - A function to set the selected configuration based on the use case selected. It defaults to null.
 * @param {Object[]} [props.formValues] - Values of the form.
 * @example
 *
 * const status = ['status1', 'status2'];
 * const configurations = [{ use_case: 'use_case1', collection: 'collection1' }, { use_case: 'use_case2', collection: 'collection2' }];
 * const setPickedConfiguration = (configuration) => { ... };
 *
 * return <FormInfo status={status} configurations={configurations} setPickedConfiguration={setPickedConfiguration} />;
 * @returns {React.Element} A JSX element containing a page header with a creation button and a set of tabs displaying documents categorized by use cases.
 */
const FormInfo = ({
  status,
  configurations,
  setPickedConfiguration,
  formValues
}) => {
  const { t } = useTranslation();

  return (
    <ContentCustom>
      <Col span={24}>
        <Card title={t('template-documents.form.formInfoTitle')}>
          <Form.Item label={t(`template-documents.form.title`)} name="title">
            <Input />
          </Form.Item>

          <Form.Item label={t(`template-documents.form.status`)} name="status">
            <Select>
              {status.map((item) => (
                <Option key={item} value={item}>
                  {t(`template-documents.form.${item}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.useCase`)}
            name="configuration"
          >
            <Select onChange={(value) => setPickedConfiguration(value)}>
              {configurations.map((item) => (
                <Option key={item.use_case} value={item.use_case}>
                  {t(`template-documents.form.${item.use_case}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.defaultTemplate`)}
            name="default_template"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={t(`template-documents.form.header`)}
            name="header"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {formValues.header && (
            <Form.Item
              label={t(`template-documents.form.header_size`)}
              name="header_size"
            >
              <Input type="number" suffix="px" />
            </Form.Item>
          )}
          <Form.Item
            label={t(`template-documents.form.footer`)}
            name="footer"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {formValues.footer && (
            <Form.Item
              label={t(`template-documents.form.footer_size`)}
              name="footer_size"
            >
              <Input type="number" suffix="px" />
            </Form.Item>
          )}
        </Card>
      </Col>
    </ContentCustom>
  );
};

FormInfo.propTypes = {
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      use_case: PropTypes.string,
      collection: PropTypes.string
    })
  ).isRequired,
  formValues: PropTypes.shape({
    header: PropTypes.bool,
    footer: PropTypes.bool
  }),
  setPickedConfiguration: PropTypes.func
};

FormInfo.defaultProps = {
  formValues: null,
  setPickedConfiguration: null
};

export default FormInfo;
