import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Button } from 'antd';

/**
 * CopyParentInfosButton component - Displays a button to copy parent informations to the form
 * @param {string} resource - The resource to copy informations from.
 * @param {string} fieldName - The name of the field to copy informations from.
 * @param {Object} form - The form object.
 * @param {function} form.setFields - Function to set the form fields.
 * @param {Object} principalParent - The principal parent object.
 * @param {string} principalParent.first_name - The first name of the principal parent.
 * @param {string} principalParent.last_name - The last name of the principal parent.
 * @param {string} principalParent.email - The email of the principal parent.
 * @param {Object} principalParent.mobile_phone_number - The mobile phone number of the principal parent.
 * @param {string} principalParent.mobile_phone_number.country_code - The country code of the mobile phone number of the principal parent.
 * @param {string} principalParent.mobile_phone_number.number - The number of the mobile phone number of the principal parent.
 * @param {Object} principalParent.address - The address of the principal parent.
 * @param {string} principalParent.address.number - The number of the address of the principal parent.
 * @param {string} principalParent.address.street - The street of the address of the principal parent.
 * @param {string} principalParent.address.postal_code - The postal code of the address of the principal parent.
 * @param {string} principalParent.address.city - The city of the address of the principal parent.
 * @param {string} principalParent.address.state - The state of the address of the principal parent.
 * @param {string} principalParent.address.additional - The additional of the address of the principal parent.
 * @param {Object} dataPrincipalParent - The principal parent object.
 * @param {string} dataPrincipalParent.first_name - The first name of the principal parent.
 * @param {string} dataPrincipalParent.last_name - The last name of the principal parent.
 * @param {string} dataPrincipalParent.email - The email of the principal parent.
 * @param {Object} dataPrincipalParent.mobile_phone_number - The mobile phone number of the principal parent.
 * @param {string} dataPrincipalParent.mobile_phone_number.country_code - The country code of the mobile phone number of the principal parent.
 * @param {string} dataPrincipalParent.mobile_phone_number.number - The number of the mobile phone number of the principal parent.
 * @param {Object} dataPrincipalParent.address - The address of the principal parent.
 * @param {string} dataPrincipalParent.address.number - The number of the address of the principal parent.
 * @param {string} dataPrincipalParent.address.street - The street of the address of the principal parent.
 * @param {string} dataPrincipalParent.address.postal_code - The postal code of the address of the principal parent.
 * @param {string} dataPrincipalParent.address.city - The city of the address of the principal parent.
 * @param {string} dataPrincipalParent.address.state - The state of the address of the principal parent.
 * @param {string} dataPrincipalParent.address.additional - The additional of the address of the principal parent.
 * @returns {JSX.Element} The rendered component.
 */
export const CopyParentInfosButton = ({
  resource,
  fieldName,
  form,
  principalParent,
  dataPrincipalParent
}) => {
  const { t } = useTranslation();

  const getSource = () => principalParent || dataPrincipalParent;

  const fillBillingAddressFormWithPrincipalParent = () => {
    const addressFields = [
      'address_label',
      'number',
      'street',
      'postal_code',
      'city',
      'state',
      'additional'
    ];

    const source = getSource();

    const fields = addressFields.map((field) => ({
      name: ['billing_address', fieldName, field],
      value:
        field === 'address_label'
          ? `${source?.first_name} ${source?.last_name}`
          : source?.address?.[field] || ''
    }));

    form.setFields(fields);
  };

  const fillContactsFormWithPrincipalParent = () => {
    const contactFields = [
      'is_principal',
      'contact_label',
      'email',
      'mobile_phone_number',
      'landline_phone_number'
    ];
    const source = getSource();

    const setValue = (field) => {
      if (field === 'is_principal') {
        return source?.is_principal_contact;
      }
      if (field === 'contact_label') {
        return `${source?.first_name} ${principalParent?.last_name}`;
      }
      return source?.[field];
    };

    const fields = contactFields.map((field) => ({
      name: ['contacts', fieldName, field],
      value: setValue(field)
    }));

    form.setFields(fields);
  };

  return (
    <Flex justify="end">
      <Button
        onClick={() =>
          resource === 'billing_address'
            ? fillBillingAddressFormWithPrincipalParent()
            : fillContactsFormWithPrincipalParent()
        }
      >
        {t('buttons.copy_principal_parent')}
      </Button>
    </Flex>
  );
};

CopyParentInfosButton.propTypes = {
  resource: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFields: PropTypes.func
  }).isRequired,
  principalParent: PropTypes.shape({
    is_principal_contact: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    mobile_phone_number: PropTypes.shape({
      country_code: PropTypes.string,
      number: PropTypes.string
    }),
    address: PropTypes.shape({
      number: PropTypes.string,
      street: PropTypes.string,
      postal_code: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      additional: PropTypes.string
    })
  }).isRequired,
  dataPrincipalParent: PropTypes.shape({
    is_principal_contact: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    mobile_phone_number: PropTypes.shape({
      country_code: PropTypes.string,
      number: PropTypes.string
    }),
    address: PropTypes.shape({
      number: PropTypes.string,
      street: PropTypes.string,
      postal_code: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      additional: PropTypes.string
    })
  }).isRequired
};
