/**
 * Custom hook for generating informations content data for displaying invoice daycare informations details in a list format.
 *
 * @hook
 * @param {object} data - Invoice data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useDaycareContent = (data = {}) => {
  const { name, address } = data;

  return [
    {
      label: 'invoices.show.name',
      span: 3,
      content: name || '-'
    },
    {
      label: 'invoices.show.address',
      span: 3,
      content:
        address &&
        `${address?.number} ${address?.street} ${address?.postal_code} ${address?.city}, ${address?.state}`
    }
  ];
};
