export const userRoles = {
  PARENT: '#F5A16F',
  'TIME-RECORDER': 'blue',
  TABLET: '#5EC2EE',
  MANAGER: 'red',
  'REGIONAL-MANAGER': '#D384BA',
  CORPORATE: 'green',
  'SUPER-ADMIN': 'purple',
  DEVELOPER: 'black'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const preRegistrationStatus = {
  ACCEPTED: '#3F9249',
  REFUSED: '#F33C52',
  PENDING: '#727274',
  WAITING: '#c0bdbf',
  COMPLETED: '#28B2EC',
  WAITING_FOR_SIGNATURE: '#D385BA',
  ALREADY_ACCEPTED_BY_ANOTHER_DAYCARE: '#bfbfbf'
};

export const childrenGender = {
  BOY: 'blue',
  GIRL: 'pink'
};

export const contractStatus = {
  CLOSED: '#221E1F',
  ACTIVE: '#3F9249',
  SUSPENDED: '#F33C52',
  PENDING: '#727274',
  VALIDATED: '#F1813D',
  COMPLETED: '#28B2EC',
  WAITING_FOR_SIGNATURE: '#D385BA'
};

export const templateStatus = {
  'CREATING-PROCESS': '#FADB14',
  VALIDATED: '#2DE180'
};

export const visibilitiesColors = {
  PARENTS: '#F1813D',
  TEAMS: '#28B2EC'
};

export const contractTypes = {
  PRIVATE_PERSON: '#727274',
  COMPANY: '#28B2EC'
};

export const planningColors = {
  OVER: '#ffc9ce',
  BELOW: '#aaf7b5'
};

export const invoiceStatuses = {
  CREATE: '#c0bdbf',
  SENT: '#5EC5F1',
  PENDING: '#D385BA',
  PAID: '#6EAA75',
  PARTIALY_PAID: '#F5A16D',
  UNPAID_1: '#F66D7D',
  UNPAID_2: '#C45764',
  UNPAID_3: '#93414B',
  LEGAL_RECOVERY: 'black'
};

export const checkoutStatus = {
  PENDING: '#5EC5F1',
  PAID: '#6EAA75',
  UNPAID: '#F66D7D',
  PARTIALY_PAID: '#F5A16D'
};

export const paymentStatuses = {
  TO_RECONCILE: '#F39155',
  RECONCILIED: '#9FC7A3',
  REJECTED: '#F79CA7'
};

export const paymentTypes = {
  CREDIT: '#F39155',
  CLASSIC: '#9FC7A3'
};

export const principalColorPlanning = {
  reference_day: '#49C301',
  adaptations: '#FAB225',
  reservations: '#6EB8FE',
  overtimes: '#C661A5',
  regulars: '#49C301',
  regular_absences: '#FAB225',
  irregular_absences: '#F54B4B',
  holidays: '#6EB8FE',
  catch_up_days: '#713696'
};

export const secondColorPlanning = {
  reference_day: '#8AD777',
  adaptations: '#F8CC7F',
  reservations: '#84C2FE',
  overtimes: '#CB8EB7',
  regulars: '#49C301',
  regular_absences: '#FAB225',
  irregular_absences: '#F54B4B',
  holidays: '#6EB8FE',
  catch_up_days: '#713696'
};

export const realColorPlanning = {
  reference_day: '#49C301',
  adaptations: '#FAB225',
  absences: '#F54B4B',
  reservations: '#6EB8FE',
  regulars: '#49C301',
  regular_absences: '#FAB225',
  irregular_absences: '#F54B4B',
  holidays: '#6EB8FE',
  catch_up_days: '#713696'
};

export const planningBorderColor = {
  reference_day: '#225c00',
  adaptations: '#a17215',
  absences: '#8a2121',
  reservations: '#2a557d',
  overtimes: '#753962'
};

export const customerNature = {
  PHYSICAL: 'blue',
  MORAL: 'pink'
};

export const employeesPositions = {
  MANAGER: 'red',
  'NURSERY-ASSISTANT': 'green',
  NURSE: 'orange',
  COOK: 'blue',
  'CHILDCARE-PRACTITIONER': 'purple',
  'NURSERY-NURSE': 'grey',
  SECRETARY: 'yellow',
  OTHER: 'black'
};

export const employeesContracts = {
  CDI: 'green',
  CDD: 'orange',
  INTERSHIP: 'blue',
  APPRENTICESHIP: 'purple',
  OTHER: 'grey'
};

export const childrenGroups = {
  SMALL: 'green',
  IN_BETWEEN: 'orange',
  OLDER: 'blue'
};

export const invoiceTypes = {
  CLASSIC: 'blue',
  FROM_SCRATCH: 'green',
  REGUL: 'orange',
  BALANCE: 'purple'
};
