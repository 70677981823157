import { Card, Col, Row, Flex, message as antdMessage, Button } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { DescriptionList } from '../../../../components';
import { VisibleButton } from '../../../../components/VisibleButton/VisibleButton';
import { useContractListContent } from './listContent';
import { Invoices } from './Invoices';
import UpdatePaymentModal from './UpdatePaymentModal';
import UpdateCafModal from './UpdateCafModal';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { FeesCardTitleAndButtons } from './FeesCardTitleAndButtons';

/**
 * BillingTab component - Renders billing information and invoices for a given contract.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.contract - Contract object for which billing info and invoices are displayed.
 * @param {string} props.contract._id - Unique identifier of the contract.
 * @param {Object} props.contract.payment_method - Payment method details of the contract.
 * @param {string} props.contract.payment_method.method - Payment method type.
 * @param {boolean} props.contract.new_sepa_mandate_waiting - Indicates if a new SEPA mandate is waiting to be activated.
 * @param {Function} props.setContract - Function to set the contract state.
 * @param {Object} props.child - Child object for which the contract is associated.
 * @returns {JSX.Element} A JSX component that displays billing and invoice information in a layout of cards.
 */
export const BillingTab = ({ contract, setContract, child }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCafOpen, setIsModalCafOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { viewDocument } = useDownloadDocument();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalCaf = () => {
    setIsModalCafOpen(true);
  };

  const closeModalCaf = () => {
    setIsModalCafOpen(false);
  };

  const { customerAccount, feeInformation, cafInformation, sepaInformation } =
    useContractListContent(contract, visible);

  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?contract=${contract?._id}&populate=child,daycare.invoicing_configuration`
      });
      setInvoices(data);
    } catch (e) {
      message(e);
    }
    closeModal();
  };

  const viewDoc = async (_id) => {
    if (_id) {
      await viewDocument({ _id });
    } else {
      message.error(t('errors.message.display_document'));
    }
  };

  const patchPayment = async (body) => {
    try {
      const updateBody = {
        ...body,
        daycareID: body.daycare,
        child,
        contract,
        household: {
          ...body.household,
          annual_incomes: Number(body.household.annual_incomes)
        }
      };
      const { data } = await dispatchAPI('PATCH', {
        url: `/contracts/${contract._id}/payment_method`,
        body: updateBody
      });

      setContract(data);
      antdMessage.success(t('contract.modal.success_message'));
      closeModal();
    } catch (e) {
      message(e);
    }
  };

  const patchCaf = async (body) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/contracts/${contract._id}/CAF_information/`,
        body
      });
      setContract(data);
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const SEPA_Mandate = () => {
    const signatures = contract.signatures;

    const sepaSignature = signatures.find(
      (signature) =>
        signature.type === 'sepa_mandate' && signature.is_still_in_effect
    );

    if (sepaSignature) {
      return sepaSignature.file;
    }

    return null;
  };

  useEffect(() => {
    (async () => {
      await getInvoices();
    })();
  }, [contract]);

  return (
    <>
      {isModalOpen && (
        <UpdatePaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          contract={contract}
          patchPayment={patchPayment}
        />
      )}
      {isModalCafOpen && (
        <UpdateCafModal
          isModalCafOpen={isModalCafOpen}
          closeModalCaf={closeModalCaf}
          contract={contract}
          patchCaf={patchCaf}
        />
      )}
      <Row gutter={[16, 16]}>
        <Col span={14}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title={t('contracts.show.customer_account')}>
                <DescriptionList
                  layout="vertical"
                  data={customerAccount}
                  translate
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={
                  <FeesCardTitleAndButtons
                    setContract={setContract}
                    contract={contract}
                  />
                }
                actions={[
                  !contract?.new_sepa_mandate_waiting && (
                    <Button type="primary" onClick={openModal}>
                      <EditOutlined />
                      {t('buttons.edit')}
                    </Button>
                  )
                ]}
              >
                <DescriptionList
                  layout="vertical"
                  data={feeInformation}
                  translate
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={t('contracts.show.CAF_information')}
                actions={[
                  <Button type="primary" onClick={openModalCaf}>
                    <EditOutlined />
                    {t('buttons.edit')}
                  </Button>
                ]}
              >
                <DescriptionList
                  layout="vertical"
                  data={cafInformation}
                  translate
                />
              </Card>
            </Col>
            {contract?.payment_method?.method === 'DIRECTDEBIT' && (
              <Col span={24}>
                <Card
                  title={
                    <Flex justify="space-between">
                      {t('contracts.show.SEPA')}
                      <VisibleButton
                        visible={visible}
                        setVisible={setVisible}
                      />
                    </Flex>
                  }
                >
                  <DescriptionList
                    layout="vertical"
                    data={sepaInformation}
                    translate
                  />
                  <Flex justify="center">
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() => viewDoc(SEPA_Mandate())}
                    >
                      {t('contracts.show.display_SEPA')}
                    </Button>
                  </Flex>
                </Card>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={10}>
          <Invoices invoices={invoices} />
        </Col>
      </Row>
    </>
  );
};

BillingTab.propTypes = {
  contract: PropTypes.shape({
    new_sepa_mandate_waiting: PropTypes.bool,
    payment_method: PropTypes.shape({
      method: PropTypes.string
    }),
    _id: PropTypes.string,
    rib: {
      _id: PropTypes.string
    },
    signatures: PropTypes.string
  }),
  setContract: PropTypes.func,
  child: PropTypes.shape({
    _id: PropTypes.string
  })
};

BillingTab.defaultProps = {
  contract: null,
  setContract: null,
  child: null
};
