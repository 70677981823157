import { useState } from 'react';
import { Button, DatePicker, Flex, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { generateDocumentsOld } from '../children/Show/Documents/components/generateDocumentsOld';

/**
 * A modal component for downloading meals menus for a selected week.
 *
 * @component
 * @returns {JSX.Element} The modal component with a date picker and a download button.
 */
export const DownloadMenuModal = () => {
  const { t } = useTranslation();
  const { daycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [week, setWeek] = useState(dayjs().week());
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDownload = async () => {
    try {
      setIsSubmitting(true);
      await generateDocumentsOld(
        dispatchAPI,
        message,
        'MEALS_MENU',
        undefined,
        week,
        t,
        daycare
      );
    } catch (e) {
      message(e);
    } finally {
      setIsModalOpenedLocaly(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t(`meals.modal.download_meal`)}
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
      }}
      footer={null}
      width={560}
      className="dowload-menu-modal"
    >
      {!isSubmitting ? (
        <Flex vertical gap="middle">
          <Flex align="center" gap="small">
            {t('meals.modal.week_number')}
            <DatePicker
              allowClear={false}
              picker="week"
              defaultValue={dayjs()}
              onChange={(value) => setWeek(dayjs(value).week())}
              format="WW-YYYY"
            />
          </Flex>

          <Flex justify="flex-end" gap="small">
            <Button
              type="link"
              danger
              onClick={() => setIsModalOpenedLocaly(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" onClick={handleDownload}>
              {`${t('buttons.download')} `}
              <CheckOutlined />
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};
