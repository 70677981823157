import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Flex, Card, DatePicker, Row, Col, Skeleton } from 'antd';
import { useHomeContext } from '../../../contexts/HomeContext';
import { icons } from './icons';

/**
 * MealsCard Component
 *
 * This component displays a card with the projected number of collations, lunches, and snacks for children in a daycare for a selected week.
 * It allows the user to select a week and fetches the meal projections for that week from the API.
 *
 * @returns {JSX.Element} The MealsCard component.
 */
export const MealsCard = () => {
  const { t } = useTranslation();
  const { onChange, counters, mealsCardLoading } = useHomeContext();

  const meals = [
    {
      key: 1,
      icon: icons[0].icon,
      title: icons[0].title,
      value: counters.countersCollation || 0,
      translationKey: t('meals.projection.COLLATION')
    },
    {
      key: 2,
      icon: icons[2].icon,
      title: icons[2].title,
      value: counters.countersLunch || 0,
      translationKey: t('meals.projection.LUNCH')
    },
    {
      key: 3,
      icon: icons[4].icon,
      title: icons[4].title,
      value: counters.countersSnack || 0,
      translationKey: t('meals.projection.SNACK')
    }
  ];

  return (
    <Card
      title={
        <Flex justify="space-between" align="baseline">
          <span>{t('home.titles.meals')}</span>
          <DatePicker
            allowClear={false}
            picker="week"
            defaultValue={dayjs()}
            onChange={onChange}
            format="WW-YYYY"
          />
        </Flex>
      }
    >
      <Skeleton loading={mealsCardLoading}>
        <Row gutter={[20, 20]}>
          {meals.map((meal) => (
            <Col key={meal.key} span={8}>
              <Flex justify="center">
                <img src={meal.icon} alt={meal.title} width={75} />
              </Flex>
              <Flex justify="center">
                <p>
                  {meal.value} {t(meal.translationKey)}
                </p>
              </Flex>
            </Col>
          ))}
        </Row>
      </Skeleton>
    </Card>
  );
};
