import { useEffect, useState } from 'react';
import { Row, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { InvoicingCreateUpdateContainer } from './InvoicingCreateUpdateContainer';
import { useCollapses } from './useCollapses';
import { CopyConfigModal } from './CopyConfigModal';
import { CalculMethodModal } from './CalculMethodModal';

/**
 * The Invoicing component.
 * @component
 * @returns {React.Component} The component.
 */
export const Invoicing = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [dataSource, setDataSource] = useState({
    regular_extra_fees_fifteen_minutes: [],
    regular_extra_fees_thirty_minutes: [],
    regular_extra_fees_sixty_minutes: [],
    occasional_extra_fees_fifteen_minutes: [],
    occasional_extra_fees_thirty_minutes: [],
    occasional_extra_fees_sixty_minutes: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalculMethodModalOpen, setIsCalculMethodModalOpen] = useState(false);
  const [arrayFilter, setArrayFilter] = useState({
    occasional: 'FIFTEEN_MINUTES',
    regulars: 'FIFTEEN_MINUTES'
  });
  const [form] = Form.useForm();

  const openModal = () => {
    setIsCalculMethodModalOpen(true);
  };

  const closeModal = () => {
    setIsCalculMethodModalOpen(false);
  };

  const collapses = useCollapses(
    dataSource,
    setDataSource,
    setIsSubmitting,
    setRefresh,
    setIsLoading,
    daycare,
    enums,
    form,
    arrayFilter,
    setArrayFilter,
    openModal
  );

  useEffect(() => {
    if (arrayFilter) {
      form.setFields([
        {
          name: 'occasional_round_up_rules',
          value: arrayFilter.occasional
        },
        {
          name: 'regular_round_up_rules',
          value: arrayFilter.regulars
        }
      ]);
    }
  }, [arrayFilter, dataSource]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/invoicing-configuration/enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  const getCurrentConfiguration = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/invoicing-configuration/${daycare}`
      });
      const { invoicing_configuration, caf_fees_modification } = data;
      setDataSource({
        ...invoicing_configuration,
        regular_extra_fees_fifteen_minutes:
          invoicing_configuration?.regular_extra_fees_fifteen_minutes || [],
        regular_extra_fees_thirty_minutes:
          invoicing_configuration?.regular_extra_fees_thirty_minutes || [],
        regular_extra_fees_sixty_minutes:
          invoicing_configuration?.regular_extra_fees_sixty_minutes || [],
        occasional_extra_fees_fifteen_minutes:
          invoicing_configuration?.occasional_extra_fees_fifteen_minutes || [],
        occasional_extra_fees_thirty_minutes:
          invoicing_configuration?.occasional_extra_fees_thirty_minutes || [],
        occasional_extra_fees_sixty_minutes:
          invoicing_configuration?.occasional_extra_fees_sixty_minutes || [],

        caf_fees_modification
      });
      setArrayFilter({
        occasional:
          invoicing_configuration?.occasional_round_up_rules ||
          'FIFTEEN_MINUTES',
        regulars:
          invoicing_configuration?.regular_round_up_rules || 'FIFTEEN_MINUTES'
      });
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getCurrentConfiguration();
    })();
  }, [daycare, refresh]);

  return (
    <>
      {isCalculMethodModalOpen && (
        <CalculMethodModal
          isCalculMethodModalOpen={isCalculMethodModalOpen}
          closeModal={closeModal}
          setRefresh={setRefresh}
          dataSource={dataSource}
        />
      )}
      {isModalOpen && (
        <CopyConfigModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setRefresh={setRefresh}
        />
      )}
      <Row justify="end">
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          {t('buttons.import_from_other_daycare')}
        </Button>
      </Row>
      <InvoicingCreateUpdateContainer
        collapses={collapses}
        id={daycare}
        setRefresh={setRefresh}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        data={dataSource}
        isLoading={isLoading}
        form={form}
      />
    </>
  );
};
