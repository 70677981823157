import dayjs from 'dayjs';
import React, { useState, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../utils/errorMessage';
import { useAuthContext } from './AuthContext';

export const PlanningContext = createContext();

/**
 * A provider component for managing planning-related state and functionality.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const PlanningProvider = ({ children: contextChildren }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { daycare, dispatchAPI, token } = useAuthContext();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [children, setChildren] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [childrenGroups, setChildrenGroups] = useState([]);
  const [daycareSelected, setDaycareSelected] = useState({});
  const [statsPerWeek, setStatsPerWeek] = useState([]);
  const [daycareClosedDays, setDaycareClosedDays] = useState([]);
  const [isPlanningLoading, setIsPlanningLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(dayjs());
  const [selectedChild, setSelectedChild] = useState({});
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [isEmployeePlanningLoading, setIsEmployeePlanningLoading] =
    useState(false);
  const [refresh, setRefresh] = useState(false);

  const getChildren = async () => {
    try {
      setChildren([]);
      setTimeSlots([]);
      const { data } = await dispatchAPI('GET', {
        url: `/pickup-times/events-planning?daycare=${daycare}&group=${selectedGroup}&status=ACTIVE&archived=false&${
          selectedDay
            ? `&date>=${dayjs(selectedDay)
                .startOf('day')
                .toISOString()}&date<=${dayjs(selectedDay)
                .endOf('day')
                .toISOString()}`
            : ''
        }&populate=contracts`
      });
      setChildren(data.newEvents);
      setTimeSlots(data.totalChildrenByTimeslot);
    } catch (error) {
      message(error);
    } finally {
      setIsPlanningLoading(false);
    }
  };

  const getEmployees = async () => {
    setIsEmployeePlanningLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employee-events/employees-planning?daycare=${daycare}&archived=false&${
          selectedDay
            ? `&date>=${dayjs(selectedDay)
                .startOf('day')
                .toISOString()}&date<=${dayjs(selectedDay)
                .endOf('day')
                .toISOString()}`
            : ''
        }`
      });
      setEmployees(data);
    } catch (e) {
      message(e);
    } finally {
      setIsEmployeePlanningLoading(false);
    }
  };

  const getChildrenPerWeek = async () => {
    try {
      setStatsPerWeek([]);
      const { data } = await dispatchAPI('GET', {
        url: `/pickup-times/events-week?daycare=${daycare}&group=${selectedGroup}&status=ACTIVE&archived=false&${
          selectedWeek.length
            ? `&date=${dayjs(selectedWeek[0]).toISOString()}`
            : ''
        }&populate=contracts`
      });
      setStatsPerWeek(data);
    } catch (error) {
      message(error);
    }
  };

  const getChildrenGroups = async () => {
    try {
      setChildrenGroups([]);
      setIsSelectLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/children-groups'
      });
      setChildrenGroups(data);
    } catch (error) {
      message(error);
    } finally {
      setIsSelectLoading(false);
    }
  };

  const getDaycare = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}`
      });
      setDaycareSelected(data);
      const closedDays = [];
      if (data?.bank_holidays?.length) {
        data?.bank_holidays.forEach((holiday) =>
          closedDays.push(dayjs(holiday.date).format('DD/MM/YYYY'))
        );
      }
      if (data?.closure_period?.length) {
        data?.closure_period.forEach((period) => {
          const start = dayjs.utc(period.start_date).startOf('day');
          const end = dayjs.utc(period.end_date).endOf('day');
          let currentDay = start.clone();
          while (currentDay.isBefore(end)) {
            closedDays.push(currentDay.format('DD/MM/YYYY'));
            currentDay = currentDay.clone().add(1, 'day');
          }
        });
      }
      setDaycareClosedDays(closedDays);
    } catch (error) {
      message(error);
    }
  };

  const deleteEvent = async (type, eventId, timeRecorderId, source) => {
    const resourceName =
      source === 'child' ? 'pickup-times' : 'employee-events';
    try {
      await dispatchAPI('DELETE', {
        url: `/${resourceName}/event/${timeRecorderId}?type=${type}&eventId=${eventId}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (daycareSelected?.timezone) await getChildrenPerWeek();
    })();
  }, [selectedWeek, daycareSelected]);

  useEffect(() => {
    if (token) {
      (async () => {
        setIsPlanningLoading(true);
        await getDaycare();
        await getChildrenGroups();
      })();
    }
  }, [selectedDay, selectedGroup, daycare, token]);

  useEffect(() => {
    const handleResize = async () => {
      if (daycareSelected?.timezone) {
        setIsPlanningLoading(true);
        await getChildren();
        await getEmployees();
        await getChildrenPerWeek();
        await getChildrenGroups();
        setIsPlanningLoading(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [daycareSelected, refresh]);

  useEffect(() => {
    (async () => {
      if (daycareSelected?.timezone) await getChildren();
    })();
  }, [daycareSelected]);

  const selectOptions = [
    {
      value: 'all',
      label: t('plannings.children.filter.all'),
      key: 'all'
    },
    ...childrenGroups.map(({ _id, label }) => ({
      value: _id,
      label,
      key: _id
    }))
  ];

  return (
    <PlanningContext.Provider
      value={{
        isSelectLoading,
        setIsSelectLoading,
        children,
        setChildren,
        timeSlots,
        setTimeSlots,
        childrenGroups,
        setChildrenGroups,
        daycareSelected,
        setDaycareSelected,
        statsPerWeek,
        setStatsPerWeek,
        daycareClosedDays,
        setDaycareClosedDays,
        isPlanningLoading,
        setIsPlanningLoading,
        selectedDay,
        setSelectedDay,
        selectedChild,
        setSelectedChild,
        selectedGroup,
        setSelectedGroup,
        selectedWeek,
        setSelectedWeek,
        getChildren,
        deleteEvent,
        selectOptions,
        isEmployeePlanningLoading,
        employees,
        refresh,
        setRefresh
      }}
    >
      {contextChildren}
    </PlanningContext.Provider>
  );
};

/**
 * A custom hook for accessing the PlanningContext.
 *
 * @returns {Object} The context values for managing planning state.
 * @throws {Error} Context must be used within a context provider.
 */
export const usePlanningContext = () => {
  const context = useContext(PlanningContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
