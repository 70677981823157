import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../components';
import UpdateSettings from './UpdateSettings';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <UpdateSettings />
    </>
  );
};
