import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import { useCAFScaleContext } from './CAFScaleContext';
import { EditableCell } from './EditableCell';
import { EditableRow } from './EditableRow';

/**
 * A table component that allows inline editing of its cells.
 * It uses the context provided by `EditableContext` to manage form states within table rows.
 *
 * @component
 *
 * This table component is designed to render editable cells for specific columns in a table.
 * It integrates with Ant Design's `Table` and `Form` components to provide inline editing capabilities.
 * The table supports custom cell rendering and editing for specified columns.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.items - The data items to display in the table.
 * @param {Array} props.resourceColumns - The column configuration for the table, including editable settings.
 * @param {string} props.resourceName - The name of the resource being edited. This is used in context operations.
 * @returns {JSX.Element} A table component with editable cells.
 */
const EditableTable = ({ items, resourceColumns, resourceName }) => {
  const { scale, patchScale } = useCAFScaleContext();
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleItemUpdate = async (dataIndex, recordId, newValue) => {
    const updatedDocument = { ...scale };

    const arrayToUpdate = updatedDocument[resourceName];

    const itemIndex = arrayToUpdate.findIndex((item) => item._id === recordId);

    if (itemIndex !== -1) {
      arrayToUpdate[itemIndex][dataIndex] = newValue;
    }

    await patchScale(updatedDocument._id, updatedDocument);
  };

  const columns = resourceColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        suffix: col.suffix,
        index,
        resourceName,
        source: 'scale',
        handleItemUpdate
      })
    };
  });

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      pagination={false}
      scroll={{ x: 1000 }}
      rowKey={(record, index) => record._id || index}
      dataSource={items}
      columns={columns}
    />
  );
};

EditableTable.propTypes = {
  items: PropTypes.string.isRequired,
  resourceColumns: PropTypes.shape([{}]).isRequired,
  resourceName: PropTypes.string.isRequired
};

export default EditableTable;
