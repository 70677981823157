import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';

/**
 * @description
 * PreRegistrationCard is a component that displays the pre-registration information
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the pre-registration
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} PreRegistrationCard component
 */
export const PreRegistrationCard = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data);

  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      title={t('pre-registrations.show.cards.folder')}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 1 }}>
        <DescriptionList data={listContent} layout="vertical" translate />
      </Skeleton>
    </Card>
  );
};

PreRegistrationCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool
};

PreRegistrationCard.defaultProps = {
  isLoading: false
};
