import { useTranslation } from 'react-i18next';
import { TeamPositionsTable } from './TeamPositions/TeamPositionsTable';
import { AbsenceReasonsTable } from './AbsenceReasons/AbsenceReasonsTable';
import { OccasionalReservationReasonsTable } from './OccasionalReservationReasons/OccasionalReservationReasonsTable';
import { AllergiesTable } from './Allergies/AllergiesTable';
import { ChildhoodIllnessesTable } from './ChildhoodIllnesses/ChildhoodIllnesses';
import { FoodAllergiesTable } from './FoodAllergies/FoodAllergiesTable';

/**
 * usePanelContent
 * @hook Custom hook for panel content
 * @param {function} onModalOpen The function to open the modal.
 * @param {function} setRefresh The function to refresh the table.
 * @param {boolean} refresh The refresh state.
 * @returns {array} panel content
 */
export const usePanelContent = (onModalOpen, setRefresh, refresh) => {
  const { t } = useTranslation();

  return [
    {
      label: t('global_settings.dynamic_lists.panel_content.allergies'),
      key: 'allergies',
      children: (
        <AllergiesTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    },
    {
      label: t('global_settings.dynamic_lists.panel_content.food-allergies'),
      key: 'food-allergies',
      children: (
        <FoodAllergiesTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    },
    {
      label: t(
        'global_settings.dynamic_lists.panel_content.chilhood_illnesses'
      ),
      key: 'chilhood_illnesses',
      children: (
        <ChildhoodIllnessesTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    },
    {
      label: t('global_settings.dynamic_lists.panel_content.team_positions'),
      key: 'team_positions',
      children: (
        <TeamPositionsTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    },
    {
      label: t('global_settings.dynamic_lists.panel_content.absence_reasons'),
      key: 'absence_reasons',
      children: (
        <AbsenceReasonsTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    },
    {
      label: t(
        'global_settings.dynamic_lists.panel_content.occasional_reservation_reasons'
      ),
      key: 'occasional_reservation_reasons',
      children: (
        <OccasionalReservationReasonsTable
          onModalOpen={onModalOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )
    }
  ];
};
