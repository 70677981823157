import { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExtraButtons } from './components/ExtraButtons';
import CustomerAccountModal from './CustomerAccountModal';
import { headersColumn } from './headers';
import { useFormatter } from '../../../utils/formatter';
import InvoicingContext from '../InvoicingContext';

/**
 * ListCustomerAccounts component
 * @component ListCustomerAccounts
 * @returns {object} JSX - ListCustomerAccounts component elements
 */
export const ListCustomerAccounts = () => {
  const { customerAccountsFormatter } = useFormatter();
  const { daycare } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [customerAccountId, setCustomerAccountId] = useState('');
  const [purpose, setPurpose] = useState('edit');
  const columns = useColumns();
  const {
    customerAccountEnums,
    customerAccountOptionsLoading,
    selectedCustomerAccountTypes,
    setSelectedCustomerAccountTypes
  } = InvoicingContext();

  const closeModal = () => {
    setCustomerAccountId('');
    setIsModalOpen(false);
  };
  const onsearchType = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      {isModalOpen && (
        <CustomerAccountModal
          isModalOpen={isModalOpen}
          enums={customerAccountEnums}
          closeModal={closeModal}
          customerAccountId={customerAccountId}
          setRefresh={setRefresh}
          purpose={purpose}
        />
      )}
      <ListResource
        resourceName="customer-accounts"
        columns={columns}
        resourceModelName="Customer-account"
        withCreateButton={false}
        headers={headersColumn}
        withSearch={false}
        forceRefresh={refresh}
        formatter={customerAccountsFormatter}
        openModal
        setIsModalOpen={setIsModalOpen}
        setRecord={setCustomerAccountId}
        setPurpose={setPurpose}
        extraQuery={`daycare=${daycare}${
          selectedCustomerAccountTypes.length
            ? `&customer_nature=${selectedCustomerAccountTypes}`
            : ''
        }`}
        extraButtons={
          <ExtraButtons
            onsearchType={onsearchType}
            setSelectedTypes={setSelectedCustomerAccountTypes}
            selectedTypes={selectedCustomerAccountTypes}
            enums={customerAccountEnums}
            isLoading={customerAccountOptionsLoading}
          />
        }
      />
    </>
  );
};
