import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Activity } from './Activity';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  activities as activitiesArray,
  transformData
} from './activitiesArray';
import { CopyConfigModal } from './CopyConfigModal';

/**
 * Component for displaying a list of activities within a daycare.
 * @component
 * @returns {JSX.Element} JSX representation of the Activities component.
 */
export const Activities = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [activities, setActivities] = useState(activitiesArray);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getActivities = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `daycares/${daycare}/activities`
      });
      const transformedData = transformData(data);
      setActivities(transformedData);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getActivities();
    })();
  }, [daycare, refresh]);

  const items = activities.map((activity) => ({
    children: (
      <Activity
        key={activity._id}
        activity={activity}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    ),
    key: activity._id,
    label: (
      <Space>
        {activity.icon} {t(`activities.form.${activity.resourceName}`)}
      </Space>
    )
  }));

  return (
    <>
      <Row justify="end">
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          {t('buttons.import_from_other_daycare')}
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        <CopyConfigModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setRefresh={setRefresh}
        />

        <Col xs={24} xl={12}>
          <Collapse items={items.slice(0, 6)} />
        </Col>
        <Col xs={24} xl={12}>
          <Collapse items={items.slice(6)} />
        </Col>
      </Row>
    </>
  );
};
