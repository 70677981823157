import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * ContractMethods is a custom hook that provides methods related to contracts.
 * It includes functions to retrieve child names and mandatory documents.
 *
 * @returns {Object} An object containing the following methods:
 *   - getChildName: A function that retrieves the child name by making a dispatchAPI request.
 *   - getMandatoryDocuments: A function that retrieves mandatory documents by making a dispatchAPI request.
 */
export const useContractMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves the child name using the specified method, URL, and body.
   * If the request is successful, the child name is returned. Otherwise, null is returned.
   * @function
   * @async
   * @param {string} method - The HTTP method to use for the request.
   * @param {string} url - The URL to send the request to.
   * @param {object} body - The request body.
   * @returns {Promise<string|null>} The child name if successful, otherwise null.
   */
  const getChildName = async (method, url, body) => {
    try {
      const { data } = await dispatchAPI(method, { url, body });

      return data || null;
    } catch (error) {
      message(error);
    }

    return null;
  };

  /**
   * Retrieves the mandatory documents for a given daycare ID.
   * If the request is successful, the mandatory documents are returned. Otherwise, an empty array is returned.
   * This function retrieves mandatory documents data by making a POST request, even though it's essentially a GET operation.
   * The endpoint is designed as a 'withoutTokenRoute' and does not support GET requests with query parameters or filters.
   * @function
   * @async
   * @param {string} id - The ID of the document.
   * @returns {Promise<Array>} - A promise that resolves to an array of mandatory documents.
   */
  const getMandatoryDocuments = async (id) => {
    if (id) {
      try {
        const { data } = await dispatchAPI('POST', {
          url: `daycares/mandatory-documents`,
          body: { id }
        });
        return data || [];
      } catch (e) {
        message(e);
      }
    }

    return [];
  };

  /**
   * Retrieves a contract by its ID.
   * If the request is successful, the contract data is returned. Otherwise, null is returned.
   * @function
   * @async
   * @param {string} id - The ID of the contract. (ex. ObjectId)
   * @returns {Promise<Object|null>} - A promise that resolves to the contract data, or null if not found.
   */
  const getContract = async (id) => {
    if (id) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `contracts/${id}?populate=documents.file`
        });
        return data || null;
      } catch (error) {
        message(error);
      }
    }

    return null;
  };

  /**
   * Performs a request to fetch decoded IDs based on pre-registration ID and daycare ID.
   * @async
   * @param {object} params - The parameters for the request.
   * @param {string} params.preRegistrationID - The pre-registration ID.
   * @param {string} params.daycareId - The daycare ID.
   * @param {function} setIds - The function to set the IDs.
   * @returns {Promise<void>} A promise resolved once the IDs are decoded and set.
   */
  const fetchDecodedIds = async ({ preRegistrationID, daycareId }, setIds) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'pre-registrations/get-ids',
        body: { preRegistrationID, daycareId }
      });
      setIds(data);
    } catch (e) {
      message(e);
    }
  };

  return { getChildName, getMandatoryDocuments, getContract, fetchDecodedIds };
};
