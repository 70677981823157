import { useState } from 'react';
import { Col } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { CreateUpdateEmployee } from './CreateUpdateEmployee';
import { useFormatter } from '../../utils/formatter';
import { ExtraButtons } from './ExtraButtons';
import { useEmployeeContext } from '../../contexts/EmployeeContext';

/**
 * Component for listing employees with additional filtering and creation capabilities.
 *
 * @component
 *
 * This component utilizes the `ListResource` component to display a list of employees, allowing for filtering by contract type, position, and children groups.
 * It integrates functionality to open a modal for creating or editing employees, leveraging the `CreateUpdateEmployee` component. The component makes use of various hooks
 * and context providers to manage state, such as selection arrays for filters and modal visibility. It also defines functions for searching and filtering within select inputs,
 * and passes these along with other props to the `ExtraButtons` component for rendering additional action buttons. Data formatting is handled through the `useFormatter` hook,
 * ensuring that employee data is displayed according to specific formatting rules. The component is designed to work within an application that manages daycare operations,
 * supporting dynamic data loading based on user interactions and context states.
 *
 * @returns {React.Component} The ListEmployees component, rendering a list of employees with filter and action capabilities.
 */
export const ListEmployees = () => {
  const { employeesDataFormatter } = useFormatter();
  const { daycare } = useAuthContext();
  const { isLoading, enums, teamPositions, childrenGroups } =
    useEmployeeContext();
  const [refresh, setRefresh] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedChildrenGroups, setSelectedChildrenGroups] = useState([]);
  const [modal, setModal] = useState(null);

  const onsearchContracts = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchPositions = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchChildrenGroups = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onModalOpen = (record, purpose) => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <CreateUpdateEmployee
        key={modalKey}
        purpose={purpose}
        record={record}
        setRefresh={setRefresh}
        isLoading={isLoading}
        enums={enums}
        teamPositions={teamPositions}
        childrenGroups={childrenGroups}
      />
    );
  };

  const columns = useColumns(refresh, setRefresh, onModalOpen);

  return (
    <>
      {modal}
      <ListResource
        resourceName="employees"
        populate={['position', 'children_groups', 'code']}
        columns={columns}
        customActionColumn
        headers={headers}
        formatter={employeesDataFormatter}
        withImportButton={false}
        withCreateButton={false}
        resourceModelName="Employee"
        extraQuery={`daycare=${daycare}${
          selectedContracts.length > 0
            ? `&contract_type=${selectedContracts}`
            : ''
        }${
          selectedPositions.length > 0 ? `&position=${selectedPositions}` : ''
        }${
          selectedChildrenGroups.length > 0
            ? `&children_groups=${selectedChildrenGroups}`
            : ''
        }`}
        openModal
        forceRefresh={refresh}
        extraButtons={
          <Col span={19}>
            <ExtraButtons
              onsearchContracts={onsearchContracts}
              onsearchPositions={onsearchPositions}
              onsearchChildrenGroups={onsearchChildrenGroups}
              setSetselectedContracts={setSelectedContracts}
              setSelectedPositions={setSelectedPositions}
              setSelectedChildrenGroups={setSelectedChildrenGroups}
              selectedContracts={selectedContracts}
              selectedPositions={selectedPositions}
              selectedChildrenGroups={selectedChildrenGroups}
              onModalOpen={onModalOpen}
              isLoading={isLoading}
              enums={enums}
              teamPositions={teamPositions}
              childrenGroups={childrenGroups}
            />
          </Col>
        }
      />
    </>
  );
};
