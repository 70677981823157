import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Layout } from 'antd';
import packageJson from '../../../package.json';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { Header } from './Header';
import { NavigateBackButton } from './NavigateBackButton';
import { SuccessCheckout } from './Success';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import mainBg from '../../assets/images/mainBg.png';
import { parentContentStyle } from '../../utils/constants/parentContentStyle';

const { Footer } = Layout;

const BgLayout = styled(Layout)`
  background-image: url(${mainBg});
  background-size: cover;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden auto;
  display: block;
  padding: 24px;
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  background: transparent;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;

  a {
    color: #fff !important;
    &:hover {
      color: var(--logoYellow) !important;
    }
  }
`;

/**
 * CheckoutForm component.
 * Renders the Stripe checkout form for processing payments.
 *
 * @component
 * @param {object} props - Component props
 * @param {boolean} props.withHeader - Whether to display the header
 * @param {boolean} props.withNavigateBackButton - Whether to display the back button
 * @returns {object} JSX - CheckoutForm component
 */
export const CheckoutForm = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [options, setOptions] = useState(null);
  const [publicApiKey, setPublicApiKey] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const withNavigateBackButton = urlParams.get('withNavigateBackButton');
  const withHeader = urlParams.get('withHeader');
  const origin = urlParams.get('origin');

  const getPublicKey = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/checkout-payment/public-key'
      });
      setPublicApiKey(data);
    } catch (error) {
      message(error);
    }
  };

  const fetchClientSecret = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/checkout-payment/create-checkout-session',
        body: { token, withHeader, withNavigateBackButton, origin }
      });
      setOptions(data);
    } catch (error) {
      message(error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getPublicKey();
      await fetchClientSecret();
    })();
  }, []);

  if (options?.payment_status === 'paid') {
    return <SuccessCheckout session={options} />;
  }

  const contentToReturn = options ? (
    <>
      <Header />
      <ContentCustom id="checkout" style={parentContentStyle}>
        <EmbeddedCheckoutProvider
          stripe={loadStripe(publicApiKey)}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        <NavigateBackButton />
      </ContentCustom>
    </>
  ) : (
    <CustomSpin />
  );

  if (origin === 'email') {
    return (
      <BgLayout>
        {contentToReturn}
        <StyledFooter as={Footer}>
          {`${t('global.title')} v${
            packageJson.version
          } ©${dayjs().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </BgLayout>
    );
  }
  return contentToReturn;
};
