/**
 * Hook to determine the initial visibility state based on the user's role.
 *
 * @hook
 *
 * @param {string} role - The role of the user.
 * @returns {string[]} Initial visibility state.
 */

export const useInitialVisibilityState = (role) => {
  switch (role) {
    case 'users:TABLET':
      return ['TEAMS'];
    case 'guests:PARENT':
      return ['PARENTS'];
    default:
      return ['PARENTS', 'TEAMS'];
  }
};
