import PropTypes from 'prop-types';
import { List } from 'antd';
import { EventCard } from './EventCard';

/**
 * Renders a list of event resources.
 *
 * @component
 *
 * This component is responsible for rendering a list of event resources. Each event
 * is displayed in a row format, showing key details such as the event's date,
 * title, visibility tags, and a thumbnail image if available. It provides an edit
 * button for each event, triggering a modal for editing the event details.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.resources - An array of event resources to be rendered.
 * Each resource object contains details of the event, like title, date, visibility,
 * groups, and an optional thumbnail.
 * @param {Function} props.handleModal - Function to open the modal for editing
 * an event. It takes the event object and the purpose ('edit') as parameters.
 *
 * @returns {JSX.Element[]} An array of JSX elements, each representing a row
 * with event details.
 */
export const EventsRender = ({ resources, handleModal }) => (
  <List
    dataSource={resources}
    renderItem={(resource) => (
      <EventCard
        key={resource._id}
        event={resource}
        handleModal={handleModal}
      />
    )}
    itemLayout="vertical"
    pagination={{
      pageSize: 5
    }}
  />
);

EventsRender.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleModal: PropTypes.func.isRequired
};
