import { useTranslation } from 'react-i18next';
import { Button, Divider, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { routes } from '../../../../utils/constants/adminRoutes';

const iconSize = 18;

/**
 * Custom hook to generate column definitions for a table displaying balance imports.
 * It includes actions for viewing and deleting balance imports.
 *
 * The hook leverages the `useTranslation` hook for internationalization, allowing column titles
 * and action prompts to be translated based on the current language setting.`useAuthContext` provides access to the API dispatch
 * method for performing delete operations. The `useErrorMessage` hook is utilized to display error messages
 * if any API requests fail.
 *
 * @hook
 *
 * @param {boolean} refresh - A boolean value indicating whether the table data needs to be refreshed.
 * @param {Function} setRefresh - A function to toggle the refresh state, triggering a re-fetch of table data.
 * @returns {Array} An array of column definitions for use in an Ant Design `Table` component. Each column
 * definition includes properties such as title, key, dataIndex, sorter, and custom render functions for
 * specific columns to include actions like viewing and deleting balance imports.
 */
export const useColumns = (refresh, setRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  const deleteBalanceImport = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `balance-imports/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  return [
    {
      title: t('analysis.table.file'),
      key: 'file',
      dataIndex: 'file',
      sorter: true,
      sortDirections,
      render: (file) => file && file?.metadata?.originalName
    },
    {
      title: t('analysis.table.month'),
      key: 'month',
      dataIndex: 'month',
      sorter: true,
      sortDirections,
      render: (date) => date && dayjs(date).format('MM-YYYY')
    },
    {
      title: t('analysis.table.import_date'),
      key: 'import_date',
      dataIndex: 'import_date',
      sorter: true,
      sortDirections,
      render: (date) => date && dayjs(date).format('DD/MM/YYYY')
    },
    {
      title: t('analysis.table.author'),
      key: 'author',
      dataIndex: 'author',
      render: (author) =>
        author && `${author?.first_name || ''} ${author?.last_name || ''}`
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            type="link"
            onClick={() => navigate(`${routes.ANALYSIS}/show/${record?._id}`)}
            className="no-padding"
          />
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteBalanceImport(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
