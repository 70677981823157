import { DatePicker, Select } from 'antd';

/**
 * Vaccines Fields for the form
 * @hook
 *
 * @param {Array} vaccines - An array of vaccine objects.
 * @returns {Array} The fields to use in the form.
 */
export const useVaccinesFields = (vaccines) => {
  const dateFormat = 'DD/MM/YYYY';

  const vaccinesFields = [
    {
      label: 'vaccine_denomination',
      name: 'vaccine',
      rules: [{ required: true }],
      input: (
        <Select
          options={(vaccines || []).map((opt) => ({
            value: opt._id,
            label: opt.name
          }))}
        />
      )
    },
    {
      label: 'first_dose',
      name: 'first_dose',
      input: <DatePicker format={dateFormat} />
    },
    {
      label: 'second_dose',
      name: 'second_dose',
      input: <DatePicker format={dateFormat} />
    },
    {
      label: 'third_dose',
      name: 'third_dose',
      input: <DatePicker format={dateFormat} />
    },
    {
      label: 'fourth_dose',
      name: 'fourth_dose',
      input: <DatePicker format={dateFormat} />
    }
  ];

  return {
    vaccinesFields
  };
};
