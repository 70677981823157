import { Form, Modal, message as antDmessage } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * EditDietModal component for editing diet information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.forceRefresh - A boolean flag indicating whether the component should force a refresh.
 * @param {function} props.setForceRefresh - A function to set the refresh state.
 * @param {Object} props.record - The diet record to be edited.
 * @param {string} props.childName - The name of the child associated with the diet information.
 * @returns {React.Element} The rendered EditDietModal component.
 */
export const EditDietModal = ({
  forceRefresh,
  setForceRefresh,
  record,
  childName
}) => {
  const { t } = useTranslation();
  const { fields, isFieldsLoading } = useFields();
  const { dispatchAPI } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (objective, body) => {
    try {
      setIsSubmitting(true);
      const url = `diet/${record._id}`;
      await dispatchAPI(objective === 'create' ? 'POST' : 'PATCH', {
        url,
        body
      });
      closeModal();
      setForceRefresh(!forceRefresh);
      form.resetFields();
      antDmessage.success(t('children.show.diet.success'));
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={childName}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        baseUrl="children"
        resource="children"
        config={config}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

EditDietModal.propTypes = {
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string
  }),
  childName: PropTypes.string
};

EditDietModal.defaultProps = {
  forceRefresh: false,
  setForceRefresh: null,
  record: null,
  childName: undefined
};
