import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

const iconSize = 18;

export const useIngredientColumns = (handleModal, deleteDocument) => {
  const { t } = useTranslation();

  const sortDirections = ['ascend', 'descend', 'ascend'];

  return [
    {
      title: t('ingredients.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortDirections,
      width: 400
    },
    {
      title: t('ingredients.form.icon'),
      key: 'icon',
      dataIndex: 'icon',
      render: (icon) =>
        icon && <img src={icon} alt={`icon-${icon}`} style={{ width: 32 }} />
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => handleModal(record, 'edit', 'ingredients')}
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
