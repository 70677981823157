/**
 * Custom hook to define form fields for different modules.
 *
 * @hook
 *
 * @returns {object} An object containing arrays of form fields for invoices, payments, and rejections.
 */
export const useFields = () => {
  const invoicesFields = [
    {
      label: 'accounting_journal',
      name: ['invoices', 'accounting_journal'],
      rules: [{ required: true }]
    },
    {
      label: 'customer_account_code',
      name: ['invoices', 'customer_account_code'],
      rules: [{ required: true }]
    },
    {
      label: 'product_account_code',
      name: ['invoices', 'product_account_code'],
      rules: [{ required: true }]
    },
    {
      label: 'registration_fees_account_code',
      name: ['invoices', 'registration_fees_account_code'],
      rules: [{ required: true }]
    },
    {
      label: 'miscellaneous_account_code',
      name: ['invoices', 'miscellaneous_account_code'],
      rules: [{ required: true }]
    }
  ];

  const paymentFields = [
    {
      label: 'accounting_journal',
      name: ['payments', 'accounting_journal'],
      rules: [{ required: true }]
    },
    {
      label: 'customer_account_code',
      name: ['payments', 'customer_account_code'],
      rules: [{ required: true }]
    },
    {
      label: 'bank_account_code',
      name: ['payments', 'bank_account_code'],
      rules: [{ required: true }]
    }
  ];

  const rejectionFields = [
    {
      label: 'accounting_journal',
      name: ['rejections', 'accounting_journal'],
      rules: [{ required: true }]
    },
    {
      label: 'customer_account_code',
      name: ['rejections', 'customer_account_code'],
      rules: [{ required: true }]
    },
    {
      label: 'bank_account_code',
      name: ['rejections', 'bank_account_code'],
      rules: [{ required: true }]
    }
  ];

  return { invoicesFields, paymentFields, rejectionFields };
};
