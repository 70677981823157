export const headers = [
  {
    label: 'last_name',
    key: 'user.last_name'
  },
  {
    label: 'first_name',
    key: 'user.first_name'
  },
  {
    label: 'phone_number',
    key: 'phone_number'
  },
  {
    label: 'children',
    key: 'children'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.state',
    key: 'address.state'
  },
  {
    label: 'social_security_system',
    key: 'social_security_system'
  },
  {
    label: 'beneficiary_number',
    key: 'beneficiary_number'
  },
  {
    label: 'dependent_children',
    key: 'dependent_children'
  },
  {
    label: 'dependent_children_with_disabilities',
    key: 'dependent_children_with_disabilities'
  },
  {
    label: 'annual_incomes',
    key: 'annual_incomes'
  }
];
