import React from 'react';
import PropTypes from 'prop-types';
import { Button, Radio, Flex } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { renderCustomerAccountFields } from './useRenderCustomerAccountFields';

/**
 * Component for rendering the customer account form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} preRegistration Object containing the pre-registration data.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @prop {string} selectedRadio String containing the selected radio option. (existingCustomerAccount|newCustomerAccount)
 * @prop {Function} setSelectedRadio Function to set the selected radio option.
 * @prop {Object} form Object containing the form data.
 * @param {object} dataPrincipalParent - The principal parent object.
 * @returns {React.Component} Customer account form component
 */
export const CustomerAccountForm = ({
  onFinish,
  preRegistration,
  handlePrevious,
  selectedRadio,
  setSelectedRadio,
  form,
  dataPrincipalParent
}) => {
  const { t } = useTranslation();
  const options = ['existingCustomerAccount', 'newCustomerAccount'];

  const principalParent = preRegistration?.parents?.find(
    (parent) => parent?.is_principal_contact
  );

  const handleRadioChange = ({ target: { value } }) => {
    setSelectedRadio(value);
    form.setFields([
      {
        name: ['billing_address'],
        value: [{}]
      },
      {
        name: ['contacts'],
        value: [
          {
            mobile_phone_number: {
              country_code: '+33'
            },
            landline_phone_number: {
              country_code: '+33'
            }
          }
        ]
      },
      {
        name: ['customer_nature'],
        value: undefined
      },
      {
        name: ['label'],
        value: undefined
      }
    ]);
  };

  return (
    <>
      <Flex justify="center" style={{ marginBottom: 16 }}>
        <Radio.Group
          value={selectedRadio}
          onChange={handleRadioChange}
          buttonStyle="solid"
        >
          {options.map((option) => (
            <Radio.Button key={option} value={option}>
              {t(`contract.form.${option}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Flex>
      {renderCustomerAccountFields(
        selectedRadio,
        form,
        principalParent,
        dataPrincipalParent
      )}
      <Flex justify="flex-end" gap="small">
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </>
  );
};

CustomerAccountForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  preRegistration: PropTypes.shape({
    parents: PropTypes.arrayOf(
      PropTypes.shape({
        is_principal_contact: PropTypes.bool
      })
    )
  }),
  handlePrevious: PropTypes.func.isRequired,
  selectedRadio: PropTypes.bool.isRequired,
  setSelectedRadio: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFields: PropTypes.func
  }),
  dataPrincipalParent: PropTypes.shape({
    is_principal_contact: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    mobile_phone_number: PropTypes.shape({
      country_code: PropTypes.string,
      number: PropTypes.string
    }),
    address: PropTypes.shape({
      number: PropTypes.string,
      street: PropTypes.string,
      postal_code: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      additional: PropTypes.string
    })
  }).isRequired
};

CustomerAccountForm.defaultProps = {
  form: null,
  preRegistration: null
};
