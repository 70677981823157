import { Card, Table } from 'antd';
import PropTypes from 'prop-types';
import { useColumns } from './columns';

/**
 * @component
 * @name AmountsCard
 * @description Displays the amounts card in the customer account page
 * @returns {component} AmountsCard component
 */
export const AmountsCard = ({ amount }) => {
  const columns = useColumns();

  return (
    <Card>
      <Table
        dataSource={amount}
        columns={columns}
        pagination={false}
        scroll={{ x: 560 }}
      />
    </Card>
  );
};

AmountsCard.propTypes = {
  amount: PropTypes.arrayOf(PropTypes.shape({}))
};

AmountsCard.defaultProps = {
  amount: [undefined]
};
