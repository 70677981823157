import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Button, Skeleton } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { NewMessageModal } from './NewMessageModal';
import { ListConversationsItem } from './ListConversationsItem';

/**
 * @component
 * @name ListConversations
 * @description A component that renders the list of conversations
 * @returns {jsx} ListConversations component
 */
export const ListConversations = () => {
  const {
    user: { role }
  } = useAuthContext();
  const { getConversations, conversations, loading } = useConversationContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <NewMessageModal open={isModalOpen} onCancel={closeModal} />
      <div
        id="scrollableDiv"
        style={{
          maxHeight: '100%',
          overflow: 'auto'
        }}
      >
        <InfiniteScroll
          dataLength={conversations.length}
          next={getConversations}
          hasMore={conversations.length < 10}
          loader={<Skeleton loading={loading} avatar paragraph={{ rows: 1 }} />}
          scrollableTarget="scrollableDiv"
        >
          <List
            itemLayout="horizontal"
            dataSource={conversations}
            renderItem={(item) => <ListConversationsItem item={item} />}
          />
        </InfiniteScroll>
      </div>
      {!role.includes('TABLET') && (
        <Button
          onClick={openModal}
          className="new-conversation-btn"
          shape="circle"
        >
          <MessageOutlined />
        </Button>
      )}
    </>
  );
};
