import { useTranslation } from 'react-i18next';
import { Card, Col, Collapse, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useColumns } from '../../../invoicing/invoices/ShowCards/columnsInvoice';

/**
 * Renders a summary row for the table.
 * @param {number} total - The total amount to display.
 * @param {Array} columns - The columns of the table.
 * @returns {Function} A function that returns a JSX component representing the summary row.
 */
const renderSummary = (total, columns) => () =>
  (
    <Table.Summary.Row>
      <Table.Summary.Cell colSpan={columns.length - 1}>
        Total TTC
      </Table.Summary.Cell>
      <Table.Summary.Cell>{`${total}`}</Table.Summary.Cell>
    </Table.Summary.Row>
  );

/**
 * Invoices component - displays a list of invoices.
 *
 * @param {Object[]} invoices - An array of invoice objects.
 * @param {string} invoices[].date - The invoice date.
 * @param {number} invoices[].all_included_total - The total amount of the invoice.
 * @returns {JSX.Element} A JSX component that displays the invoices in a card with a collapse panel.
 */
export const Invoices = ({ invoices }) => {
  const { t } = useTranslation();
  const child = invoices[0]?.child;
  const columns = useColumns(child);

  const items = (invoices || [])
    .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
    .map((invoice) => ({
      key: invoice.date,
      label: (
        <Row justify="space-between">
          <Col>{dayjs(invoice?.due_date).format('DD/MM/YYYY')}</Col>
          <Col>{`${Number(invoice?.total).toFixed(2)} €`}</Col>
        </Row>
      ),
      children: (
        <Table
          dataSource={invoice.invoiceDetails}
          columns={columns}
          pagination={false}
          summary={renderSummary(invoice.total, columns)}
          scroll={{ x: 1000 }}
        />
      )
    }));

  return (
    <Card
      title={t('pre-registrations.form.summary.invoices')}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Collapse items={items} />
    </Card>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      child: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      }),
      date: PropTypes.string,
      all_included_total: PropTypes.number
    })
  )
};

Invoices.defaultProps = {
  invoices: []
};
