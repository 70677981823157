import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';
import { useColumns } from './columns';

/**
 * Renders a list of credit card payments.
 *
 * @component
 * @returns {JSX.Element} The rendered list of credit card payments.
 */
export const ListCreditCardPayments = () => {
  const { daycare } = useAuthContext();
  const columns = useColumns();
  const extraQuery = `daycare=${daycare}&populate=invoice,customer_account`;

  return (
    <ListResource
      withCreateButton={false}
      archiveAction={false}
      archivedButton={false}
      editAction={false}
      showAction={false}
      onDoubleClickAction={false}
      resourceName="credit-card-payments"
      resourceModelName="CreditCardPayment"
      columns={columns}
      extraQuery={extraQuery}
    />
  );
};
