import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

/**
 * Custom hook for displaying error messages using Ant Design notification.
 *
 * @hook
 *
 * @returns {Object} An object containing a function to display error messages.
 * @example
 * // Usage in a functional component
 * const { message } = useErrorMessage();
 * // Displaying an error message
 * message('404'); // Displays a default error message for status code 404
 * // Displaying a custom error message with specific duration and notification type
 * message('500', 6, 'custom_key', 'error'); // Displays a custom error message for status code 500 with a duration of 6 seconds and error notification type
 */
export const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      const m = status?.response?.data?.message;
      const d =
        status?.response?.data?.description || status?.response?.data?.message;
      if (s !== 498 && s !== 'Request failed with status code 498')
        notification[notificationType]({
          message: t(`errors.message.${d || m || s}`),
          key: key || 'network_feedback',
          duration
        });
    }
  };
};
