import { Button, Divider, Popconfirm, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 * @param {boolean} refresh The state to refresh the table.
 * @param {function} setRefresh The function to refresh the table.
 * @param {function} handleModal The function to handle modal behaviour.
 * @returns {Array} columns for the datatable
 */
export const useDocumentsColumns = (refresh, setRefresh, handleModal) => {
  const { t } = useTranslation();
  const { daycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const updateDocument = (record) => {
    try {
      dispatchAPI('PATCH', {
        url: `/daycares/${daycare}/dynamic_list/${record?._id}/documents`,
        body: {
          ...record,
          required: !record?.required
        }
      });
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    }
  };

  const deleteDocument = (id) => {
    try {
      dispatchAPI('DELETE', {
        url: `/daycares/${daycare}/dynamic_list/${id}/documents`
      });
      setTimeout(() => {
        setRefresh(!refresh);
      }, 500);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('daycares.form.document_title'),
      key: 'document_title',
      dataIndex: 'document_title',
      sorter: true
    },
    {
      title: t('daycares.form.required'),
      key: 'required',
      dataIndex: 'required',
      sorter: true,
      render: (_, record) => (
        <Switch
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
          defaultChecked={record?.required}
          onChange={() => updateDocument(record)}
        />
      )
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => handleModal(record, 'edit', 'documents')}
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
