import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useHandleResize } from '../../utils/handleResize';

/**
 * MercuryDatePicker is a custom date picker component that adjusts its appearance based on the screen size.
 * It renders an html input field for mobile devices or an AntDesign date picker for computers.
 * It insures a better user experience on mobile devices by using the native date picker.
 *
 * @component
 * @param {string} format - The date format to be used in the date picker.
 * @param {function} onChange - The function to be called when the date is changed.
 * @returns {JSX.Element} The rendered date picker component.
 *
 * @example
 * return (
 *   <MercuryDatePicker
 *     format="DD/MM/YYYY"
 *     onChange={(value) => console.log(value)}
 *   />
 * )
 */
export const MercuryDatePicker = ({ format, onChange }) => {
  const { width } = useHandleResize();

  // An iPad Pro is 1280 pixels wide
  return width <= 1280 ? (
    <input
      type="date"
      onChange={(e) => {
        const value = e.target.value;
        onChange(value.length > 0 ? dayjs(value) : null);
      }}
    />
  ) : (
    <DatePicker format={format} onChange={onChange} />
  );
};

MercuryDatePicker.propTypes = {
  format: PropTypes.string,
  onChange: PropTypes.func
};

MercuryDatePicker.defaultProps = {
  format: 'DD/MM/YYYY',
  onChange: () => {}
};
