import { Form, Skeleton } from 'antd';
import { useRef } from 'react';
import { useAnalysisContext } from '../../../../contexts/AnalysisContext';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';
import { Hypotheses } from '../../../../models/Hypotheses';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Renders a form for entering and submitting hypotheses data for analysis.
 *
 * This component provides a user interface for inputting various financial and operational
 * hypotheses data such as capacity, range hour, annual working days, theoretical capacity,
 * occupation rates for the past years, and more. The data entered in the form can be submitted
 * for further analysis. The form fields include both editable and disabled inputs, with some
 * fields displaying calculated values based on previous entries. The component also handles the
 * form submission logic, updating the analysis context with the entered hypotheses data.
 *
 * @component
 * @returns {JSX.Element} A form filled with input fields for hypotheses data.
 */
export const HypothesesContainer = () => {
  const { daycare } = useAuthContext();
  const [form] = Form.useForm();
  const { setHypothesesBody, daycareInfos, refresh, setRefresh } =
    useAnalysisContext();
  const timeoutRef = useRef(null);

  const generateFields = useGenerateFormItem();

  const onChange = async (values) => {
    setHypothesesBody(values);
    await Hypotheses.postAndReplacePrevious({ ...values, daycare });
    setRefresh(!refresh);
  };

  const handleValueChange = (_, values) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onChange(values);
    }, 2000);
  };

  const fields = useFields(daycareInfos);

  return (
    <Skeleton loading={!daycareInfos}>
      <Form form={form} onValuesChange={handleValueChange}>
        {fields.map((field) => generateFields('analysis', field))}
      </Form>
    </Skeleton>
  );
};
