import { Col, Flex, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useHomeContext } from '../../contexts/HomeContext';
/**
 * A modal component used to display loading status for analysis pages.
 *
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the LoadingModal component.
 */
export const LoadingModal = () => {
  const { t } = useTranslation();
  const { dataLoading, open } = useHomeContext();

  const pages = Object.keys(dataLoading);

  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      title={t('home.modal.loading_title')}
    >
      <Flex justify="center">
        <Spin />
      </Flex>
      {pages.map((page) => (
        <Row key={page} justify="space-between">
          <Col>{t(`home.modal.${page}`)}</Col>
          <Col>
            {dataLoading[page] === true ? (
              <CloseOutlined style={{ color: 'red' }} />
            ) : (
              <CheckOutlined style={{ color: 'green' }} />
            )}
          </Col>
        </Row>
      ))}
    </Modal>
  );
};
