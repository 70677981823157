import { useState, useCallback, useEffect } from 'react';
import { Input, Select, Switch, Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useCompanyFields } from './companyFields';
import { handleDepartment } from '../../../utils/handleDepartment';

const { Option } = Select;

/**
 * useParentsFields
 * A custom hook that builds the parents fields for the pre-registration form
 *
 * @hook
 * @returns {object} parentsFields
 *
 * @param {object} form - Form instance
 * @example
 * const { parentsFields } = useParentsFields();
 */
export const useParentsFields = (form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { companyFields } = useCompanyFields();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [parent1ContractOn, setParent1ContractOn] = useState(false);
  const [parent2ContractOn, setParent2ContractOn] = useState(false);
  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);

  useEffect(() => {
    if (parent1ContractOn) {
      setParent2ContractOn(false);
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }

    if (parent2ContractOn) {
      setParent1ContractOn(false);
      setDisabled1(true);
    } else {
      setDisabled1(false);
    }
  }, [parent1ContractOn, parent2ContractOn]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pre-registrations/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const parentsFields = (parent) => [
    {
      name: [parent, 'first_name'],
      rules: [{ required: true }]
    },
    {
      name: [parent, 'last_name'],
      rules: [{ required: true }]
    },
    {
      name: [parent, 'honorific'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.honorifics || []).map((honorific) => (
            <Option key={honorific} value={honorific}>
              {t(`pre-registrations.form.honorifics.${honorific}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: [parent, 'relationship'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.relationships || []).map((relationship) => (
            <Option key={relationship} value={relationship}>
              {t(`pre-registrations.form.relationships.${relationship}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: [parent, 'is_principal_contact'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: [parent, 'address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: [parent, 'address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: [parent, 'address', 'additional']
    },
    {
      name: [parent, 'address', 'postal_code'],
      rules: [{ required: true }],
      input: (
        <Input
          type="number"
          onChange={(e) =>
            handleDepartment(e, form, [parent, 'address', 'state'])
          }
        />
      )
    },
    {
      name: [parent, 'address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: [parent, 'address', 'state'],
      rules: [{ required: true }]
    },
    {
      name: [parent, 'email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      rules: [{ required: true }],
      name: [parent, 'mobile_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={[parent, 'mobile_phone_number', 'country_code']}
            initialValue="+33"
            rules={[{ required: true }]}
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={[parent, 'mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: [parent, 'landline_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={[parent, 'landline_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={[parent, 'landline_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: [parent, 'social_security_system'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.social_security_systems || []).map((system) => (
            <Option key={system} value={system}>
              {t(`pre-registrations.form.security_systems.${system}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: [parent, 'employment_contract'],
      input: (
        <Switch
          disabled={parent === 'parent1' ? disabled1 : disabled2}
          onChange={
            parent === 'parent1' ? setParent1ContractOn : setParent2ContractOn
          }
          checked={parent === 'parent1' ? parent1ContractOn : parent2ContractOn}
        />
      )
    },
    ...(parent === 'parent1' && parent1ContractOn
      ? companyFields.map((field) => ({
          ...field,
          name: [parent, 'company', ...field.name]
        }))
      : []),
    ...(parent === 'parent2' && parent2ContractOn
      ? companyFields.map((field) => ({
          ...field,
          name: [parent, 'company', ...field.name]
        }))
      : [])
  ];

  return {
    parentsFields,
    isFieldsLoading,
    enums
  };
};
