import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Collapse, Flex, Space, Button, Spin } from 'antd';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  CreditCardOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import InvoicingContext from '../InvoicingContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Component to display a list of parent invoices.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {Array<object>} props.invoices - Array of invoice objects.
 * @param {boolean} props.refresh - Boolean indicating if the component should refresh.
 * @param {function} props.setRefresh - Function to set the refresh state.
 * @returns {JSX.Element} ParentInvoicesList component.
 */
export const ParentInvoicesList = ({ invoices, refresh, setRefresh }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { onPrintAction, isSpinLoading } = InvoicingContext();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const printInvoice = searchParams.get('print_invoice') === 'true';
  const invoiceID = searchParams.get('invoice_id');

  const calculateDue = (total, payments) => {
    let due = total;
    payments.forEach((payment) => {
      due -= payment.amount;
    });

    return Number(due.toFixed(2));
  };

  const handlePayment = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/checkout-payment/encode-invoice-id/${id}`
      });

      if (data && data.token) {
        navigate(
          `/checkout-payment/checkout/${data.token}?withHeader=true&withNavigateBackButton=true&origin=parents`
        );
      }
    } catch (error) {
      message(error);
    } finally {
      setRefresh(!refresh);
    }
  };

  useEffect(async () => {
    if (printInvoice && invoiceID) {
      await onPrintAction(invoiceID);
    }
  }, [printInvoice, invoiceID]);

  const items = (invoices || [])
    .map(({ reference_month, status, total, payments, _id }) => {
      const due = calculateDue(total, payments);
      if (status !== 'CREATE') {
        return {
          key: _id,
          label: (
            <Flex justify="space-between">
              {dayjs(reference_month).format('DD/MM/YYYY')}
              <Space>
                {status === 'PAID' ? (
                  <CheckCircleOutlined
                    style={{ color: 'var(--addColor)', fontSize: 16 }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: 'var(--errorColor)', fontSize: 16 }}
                  />
                )}
                {`${Number(total)?.toFixed(2)} €`}
              </Space>
            </Flex>
          ),
          children: (
            <Flex vertical gap="middle">
              <Flex vertical>
                <Flex justify="space-between">
                  {t('invoices.form.total_ttc')}
                  <strong>{`${total} €`}</strong>
                </Flex>
                <Flex justify="space-between">
                  {t('invoices.form.to_pay')}
                  <strong
                    style={{
                      color: due > 0 ? 'var(--errorColor)' : 'var(--addColor)'
                    }}
                  >{`${due} €`}</strong>
                </Flex>
              </Flex>
              <Flex vertical gap="small">
                {status !== 'PAID' && status !== 'CREATE' && (
                  <Button
                    icon={<CreditCardOutlined />}
                    type="primary"
                    onClick={() => handlePayment(_id)}
                  >
                    {t('invoices.form.payment_by_CB')}
                  </Button>
                )}

                <Button
                  icon={<DownloadOutlined />}
                  type="primary"
                  onClick={() => onPrintAction(_id)}
                  loading={isSpinLoading}
                >
                  {t('invoices.form.download_detailed_facture')}
                </Button>
              </Flex>
            </Flex>
          )
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  return invoices.length === 0 ? (
    <Flex justify="center" align="center" style={{ marginTop: 16 }}>
      {t('invoices.form.no_invoices')}
    </Flex>
  ) : (
    <Spin spinning={isSpinLoading}>
      <Collapse items={items} />
    </Spin>
  );
};

ParentInvoicesList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

ParentInvoicesList.defaultProps = {
  invoices: null,
  refresh: undefined,
  setRefresh: null
};
