import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, Flex, Select, Tag } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';

/**
 * Component for rendering extra filters for document list.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {Function} props.onModalOpen - Function to open the modal.
 * @param {string} props.typeFilter - The current type filter value.
 * @param {Function} props.setTypeFilter - Function to set the type filter value.
 * @param {string} props.visibilityFilter - The current visibility filter value.
 * @param {Function} props.setVisibilityFilter - Function to set the visibility filter value.
 * @param {boolean} props.isAdmin - Flag indicating if the user is an admin.
 * @returns {JSX.Element} Extra filters component JSX.
 */
export const ExtraFilters = ({
  onModalOpen,
  typeFilter,
  setTypeFilter,
  visibilityFilter,
  setVisibilityFilter,
  isAdmin
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `documents/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const handleOptionSelect = (value, source) => {
    if (source === 'type') {
      setTypeFilter(value);
    }
    if (source === 'visibility') {
      setVisibilityFilter(value);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const visibilityOptions = (enums?.visibility || []).map((enumItem) => ({
    label: (
      <Tag color={visibilitiesColors[enumItem]}>
        {t(`communication.documents.list.type.${enumItem}`)}
      </Tag>
    ),
    value: enumItem
  }));

  const documentTypeOptions = (enums?.document_type || []).map((enumItem) => ({
    label: <Tag>{t(`communication.documents.list.type.${enumItem}`)}</Tag>,
    value: enumItem
  }));

  return (
    <Flex gap="middle" wrap="wrap">
      {isAdmin && (
        <Select
          options={visibilityOptions}
          loading={!visibilityOptions}
          onChange={(value) => handleOptionSelect(value, 'visibility')}
          mode="multiple"
          allowClear
          defaultValue={visibilityFilter}
          placeholder={t('placeholder.visible_by')}
          style={{ width: 260 }}
          className="contains-tags"
        />
      )}
      <Flex gap="small" align="center">
        {t('communication.documents.list.document_type')} :
        <Select
          options={documentTypeOptions}
          loading={!documentTypeOptions}
          onChange={(value) => handleOptionSelect(value, 'type')}
          mode="multiple"
          defaultValue={typeFilter}
          allowClear
          style={{ width: 200 }}
          className="contains-tags"
        />
      </Flex>
      {isAdmin && (
        <Button
          type="primary"
          onClick={() => onModalOpen(undefined, 'create', 'documents')}
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      )}
    </Flex>
  );
};

ExtraFilters.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  typeFilter: PropTypes.string,
  setTypeFilter: PropTypes.func,
  visibilityFilter: PropTypes.string,
  setVisibilityFilter: PropTypes.func,
  isAdmin: PropTypes.string
};

ExtraFilters.defaultProps = {
  typeFilter: undefined,
  setTypeFilter: null,
  visibilityFilter: undefined,
  setVisibilityFilter: null,
  isAdmin: undefined
};
