import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Popconfirm,
  Row,
  Skeleton
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { CreateUpdateEmployee } from './CreateUpdateEmployee';
import { useEmployeeContext } from '../../contexts/EmployeeContext';
import { EmployeePlanning } from './planning/EmployeePlanning';

export const ShowEmployee = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, token } = useAuthContext();
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const { isLoading, enums, teamPositions, childrenGroups } =
    useEmployeeContext();
  const [employee, setEmployee] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { generalInformations, positionInformations } =
    useListContent(employee);
  const [modal, setModal] = useState(null);

  const title = `${employee.first_name} ${employee.last_name} - ${employee?.position?.label}`;

  const getEmployee = useCallback(async () => {
    setEmployeeLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employee/${id}?populate=code,position,children_groups,photo`
      });
      setEmployee(data);
    } catch (e) {
      message(e);
    }
    setEmployeeLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getEmployee();
    })();
  }, [getEmployee, refresh]);

  const deleteEmployee = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/employee/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onModalOpen = () => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <CreateUpdateEmployee
        key={modalKey}
        purpose="edit"
        record={employee}
        setRefresh={setRefresh}
        isLoading={isLoading}
        enums={enums}
        teamPositions={teamPositions}
        childrenGroups={childrenGroups}
      />
    );
  };

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrums
        title={!employeeLoading && title}
        extra={
          <>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={onModalOpen}
            >
              {t('buttons.edit')}
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteEmployee}
              icon={<WarningOutlined />}
            >
              <Button danger icon={<DeleteOutlined />}>
                {`${t('buttons.delete')} `}
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Flex vertical gap="large">
          <Row gutter={[24, 24]}>
            <Col xs={24} xxl={14}>
              <Card>
                <Row align="middle" gutter={[0, 24]}>
                  <Col xs={24} xl={4}>
                    <Skeleton
                      loading={employeeLoading}
                      title={0}
                      paragraph={0}
                      avatar={{ size: 88 }}
                      active
                    >
                      {employee?.photo ? (
                        <Avatar
                          size={80}
                          src={
                            employee?.photo
                              ? `${process.env.REACT_APP_API_URL}/employees/get-img-url/${employee?.photo._id}/${token}`
                              : ''
                          }
                        />
                      ) : (
                        <Avatar size={80} />
                      )}
                    </Skeleton>
                  </Col>
                  <Col xs={24} xl={20}>
                    <Skeleton
                      active
                      loading={employeeLoading}
                      paragraph={{ rows: 5 }}
                    >
                      <DescriptionList
                        data={generalInformations}
                        translate
                        layout="vertical"
                      />
                    </Skeleton>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} xxl={10}>
              <Card>
                <Skeleton
                  active
                  loading={employeeLoading}
                  paragraph={{ rows: 5 }}
                >
                  <DescriptionList
                    data={positionInformations}
                    translate
                    layout="vertical"
                  />
                </Skeleton>
              </Card>
            </Col>
          </Row>
          <Flex vertical gap="small">
            <Divider>{`${t('employees.show.planning.title')} `}</Divider>
            <EmployeePlanning employee={employee} />
          </Flex>
        </Flex>
      </ContentCustom>
      {modal}
    </>
  );
};
