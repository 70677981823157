import { useTranslation } from 'react-i18next';
import { DatePicker, Tabs } from 'antd';
import dayjs from 'dayjs';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { useTabItems } from './utils/useTabItems';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import { LoadingModal } from './LoadingModal';

/**
 * A component for rendering the analysis section of the application.
 *
 * This component serves as the main container for the analysis section, displaying a page header and a set of tabs for navigating between different analysis views. It utilizes the `useAnalysisContext` to manage global state such as the selected date range for filtering data and the active tab key. The `useTabItems` hook is used to generate the items (tabs) based on the translation context, allowing for dynamic localization. The component also includes a date range picker in the tab bar for selecting the date range to filter the analysis data.
 *
 * @component
 * @returns {JSX.Element} The Analysis component, consisting of a page header and a set of tabs for different analysis views.
 */
export const Analysis = () => {
  const { t } = useTranslation();
  const { setDateFilter, tabsActiveKey, setTabsActiveKey } =
    useAnalysisContext();

  const items = useTabItems(t);

  return (
    <>
      <PageHeaderCustom title={t('analysis.title')} />
      <ContentCustom>
        <Tabs
          type="card"
          items={items}
          activeKey={tabsActiveKey}
          onChange={(key) => setTabsActiveKey(key)}
          tabBarExtraContent={
            !['3'].includes(tabsActiveKey) && (
              <DatePicker
                allowClear={false}
                picker="month"
                defaultValue={dayjs().endOf('year')}
                onChange={(value) => setDateFilter(dayjs(value))}
              />
            )
          }
        />
      </ContentCustom>
      <LoadingModal />
    </>
  );
};
