import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import ListWorkflow from './ListWorkflow';
import { CreateUpdateWorkflow } from './CreateUpdateWorkFlow';
import ListWorkflowExecution from './executions/ListWorkflowExecution';
/* import ShowWorkflow from './ShowWorkflow'; */

export const WorkflowRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateWorkflow purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateWorkflow purpose="edit" />} />
    <Route path="/executions" element={<ListWorkflowExecution />} />
    {/* <Route path="/show/:id" element={<ShowWorkflow />} /> */}
    <Route index element={<ListWorkflow />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
