/**
 * Returns an array of department objects.
 * Each object contains the name of a French department along with its respective postal code as a string.
 *
 * @function
 * @return {Array<{ department: string, postalCode: string }>} - An array of department objects.
 * @example
 *
 * const departments = useDepartmentArray();
 * // Output: { department: 'Ain', postalCode: '01' }
 *
 */

export const useDepartmentArray = () => [
  { department: 'Ain', postalCode: '01' },
  { department: 'Aisne', postalCode: '02' },
  { department: 'Allier', postalCode: '03' },
  { department: 'Alpes-de-Haute-Provence', postalCode: '04' },
  { department: 'Hautes-Alpes', postalCode: '05' },
  { department: 'Alpes-Maritimes', postalCode: '06' },
  { department: 'Ardèche', postalCode: '07' },
  { department: 'Ardennes', postalCode: '08' },
  { department: 'Ariège', postalCode: '09' },
  { department: 'Aube', postalCode: '10' },
  { department: 'Aude', postalCode: '11' },
  { department: 'Aveyron', postalCode: '12' },
  { department: 'Bouches-du-Rhône', postalCode: '13' },
  { department: 'Calvados', postalCode: '14' },
  { department: 'Cantal', postalCode: '15' },
  { department: 'Charente', postalCode: '16' },
  { department: 'Charente-Maritime', postalCode: '17' },
  { department: 'Cher', postalCode: '18' },
  { department: 'Corrèze', postalCode: '19' },
  { department: "Côte-d'Or", postalCode: '21' },
  { department: "Côtes-d'Armor", postalCode: '22' },
  { department: 'Creuse', postalCode: '23' },
  { department: 'Dordogne', postalCode: '24' },
  { department: 'Doubs', postalCode: '25' },
  { department: 'Drôme', postalCode: '26' },
  { department: 'Eure', postalCode: '27' },
  { department: 'Eure-et-Loir', postalCode: '28' },
  { department: 'Finistère', postalCode: '29' },
  { department: 'Gard', postalCode: '30' },
  { department: 'Haute-Garonne', postalCode: '31' },
  { department: 'Gers', postalCode: '32' },
  { department: 'Gironde', postalCode: '33' },
  { department: 'Hérault', postalCode: '34' },
  { department: 'Ille-et-Vilaine', postalCode: '35' },
  { department: 'Indre', postalCode: '36' },
  { department: 'Indre-et-Loire', postalCode: '37' },
  { department: 'Isère', postalCode: '38' },
  { department: 'Jura', postalCode: '39' },
  { department: 'Landes', postalCode: '40' },
  { department: 'Loir-et-Cher', postalCode: '41' },
  { department: 'Loire', postalCode: '42' },
  { department: 'Haute-Loire', postalCode: '43' },
  { department: 'Loire-Atlantique', postalCode: '44' },
  { department: 'Loiret', postalCode: '45' },
  { department: 'Lot', postalCode: '46' },
  { department: 'Lot-et-Garonne', postalCode: '47' },
  { department: 'Lozère', postalCode: '48' },
  { department: 'Maine-et-Loire', postalCode: '49' },
  { department: 'Manche', postalCode: '50' },
  { department: 'Marne', postalCode: '51' },
  { department: 'Haute-Marne', postalCode: '52' },
  { department: 'Mayenne', postalCode: '53' },
  { department: 'Meurthe-et-Moselle', postalCode: '54' },
  { department: 'Meuse', postalCode: '55' },
  { department: 'Morbihan', postalCode: '56' },
  { department: 'Moselle', postalCode: '57' },
  { department: 'Nièvre', postalCode: '58' },
  { department: 'Nord', postalCode: '59' },
  { department: 'Oise', postalCode: '60' },
  { department: 'Orne', postalCode: '61' },
  { department: 'Pas-de-Calais', postalCode: '62' },
  { department: 'Puy-de-Dôme', postalCode: '63' },
  { department: 'Pyrénées-Atlantiques', postalCode: '64' },
  { department: 'Hautes-Pyrénées', postalCode: '65' },
  { department: 'Pyrénées-Orientales', postalCode: '66' },
  { department: 'Bas-Rhin', postalCode: '67' },
  { department: 'Haut-Rhin', postalCode: '68' },
  { department: 'Rhône', postalCode: '69' },
  { department: 'Haute-Saône', postalCode: '70' },
  { department: 'Saône-et-Loire', postalCode: '71' },
  { department: 'Sarthe', postalCode: '72' },
  { department: 'Savoie', postalCode: '73' },
  { department: 'Haute-Savoie', postalCode: '74' },
  { department: 'Paris', postalCode: '75' },
  { department: 'Seine-Maritime', postalCode: '76' },
  { department: 'Seine-et-Marne', postalCode: '77' },
  { department: 'Yvelines', postalCode: '78' },
  { department: 'Deux-Sèvres', postalCode: '79' },
  { department: 'Somme', postalCode: '80' },
  { department: 'Tarn', postalCode: '81' },
  { department: 'Tarn-et-Garonne', postalCode: '82' },
  { department: 'Var', postalCode: '83' },
  { department: 'Vaucluse', postalCode: '84' },
  { department: 'Vendée', postalCode: '85' },
  { department: 'Vienne', postalCode: '86' },
  { department: 'Haute-Vienne', postalCode: '87' },
  { department: 'Vosges', postalCode: '88' },
  { department: 'Yonne', postalCode: '89' },
  { department: 'Territoire de Belfort', postalCode: '90' },
  { department: 'Essonne', postalCode: '91' },
  { department: 'Hauts-de-Seine', postalCode: '92' },
  { department: 'Seine-Saint-Denis', postalCode: '93' },
  { department: 'Val-de-Marne', postalCode: '94' },
  { department: "Val-d'Oise", postalCode: '95' },
  { department: 'Corse-du-Sud', postalCode: '201' },
  { department: 'Haute-Corse', postalCode: '202' },
  { department: 'Guadeloupe', postalCode: '971' },
  { department: 'Martinique', postalCode: '972' },
  { department: 'Guyane', postalCode: '973' },
  { department: 'La Réunion', postalCode: '974' },
  { department: 'Mayotte', postalCode: '976' }
];
