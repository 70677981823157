import { useCallback, useEffect, useState } from 'react';
import { Select, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { days } from '../../../utils/days';

const { Option } = Select;

/**
 * Fields for the form
 * @hook
 * @param {string} purpose - The purpose of the form.
 * @param {boolean} refresh - Indicates if the form should be refreshed.
 * @returns {Array} The fields to use in the form.
 */
export const useFields = (purpose, refresh) => {
  const { message } = useErrorMessage();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [displayedDays, setDisplayedDays] = useState([]);
  const { t } = useTranslation();

  const format = 'HH:mm';
  const minuteStep = 15;

  const getDisplayedDays = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/hours?daycare=${daycare}`
      });

      const openingDays = data.map((day) => day.opening_day.toLowerCase());
      const filteredDays = days.filter((day) => !openingDays.includes(day));
      setDisplayedDays(purpose === 'create' ? filteredDays : days);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getDisplayedDays();
    setIsFieldsLoading(false);
  }, [refresh, daycare]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      label: 'opening_day',
      name: 'opening_day',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} disabled={purpose === 'edit'}>
          {displayedDays.map((day) => (
            <Option key={day} value={day}>
              {t(`week_days.${day}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'morning_opening',
      name: 'morning_opening',
      rules: [{ required: true }],
      input: <TimePicker format={format} minuteStep={minuteStep} />
    },
    {
      label: 'morning_closing',
      name: 'morning_closing',
      input: <TimePicker format={format} minuteStep={minuteStep} />
    },
    {
      label: 'afternoon_opening',
      name: 'afternoon_opening',
      input: <TimePicker format={format} minuteStep={minuteStep} />
    },
    {
      label: 'evening_closing',
      name: 'evening_closing',
      rules: [{ required: true }],
      input: <TimePicker format={format} minuteStep={minuteStep} />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
