import PropTypes from 'prop-types';
import { List, Avatar, Badge, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { displayDate } from '../ParentInbox/utils';

/**
 * @component
 * @name ListConversationsItem
 * @description A single item of the list of conversations
 * @prop {Object} item The conversation object
 * @returns {jsx} jsx - ListConversationsItem component
 */
export const ListConversationsItem = ({ item }) => {
  const { user } = useAuthContext();
  const {
    setSelectedConversation,
    selectedConversation,
    patchToReadConversation
  } = useConversationContext();
  const { users, last_message_date, messages: itemMessages } = item;

  const setClassname = ({ _id }) => {
    if (!selectedConversation) return 'conversation-item';
    if (selectedConversation && _id === selectedConversation._id)
      return 'conversation-item selected';
    return 'conversation-item';
  };

  const conversationTitle = () => {
    const { first_name, last_name, role } = users.filter(
      (userLocal) => userLocal._id !== user._id
    )[0];
    const containsUnread = itemMessages.some(
      (message) =>
        !!message?.unread?.length &&
        message.unread.some((unreadItem) => unreadItem === user._id)
    );

    return (
      <Flex justify="space-between" gap="small" align="center">
        <span
          style={{
            color:
              role === 'guests:PARENT' ? 'var(--logoOrange)' : 'var(--logoPink)'
          }}
        >{`${first_name} ${last_name}`}</span>
        <Flex gap="small" align="center">
          {containsUnread && <Badge color="red" />}
          <span className="conversation-date">
            {displayDate(last_message_date)}
          </span>
        </Flex>
      </Flex>
    );
  };

  // This function is used to display the last message in the item in the list of conversations
  const description = (messages) => {
    if (!messages.length) return '';
    const index = messages.length - 1;
    return messages[index].content;
  };

  const handleListItemOnClick = (conversation) => {
    setSelectedConversation(conversation);
    patchToReadConversation(conversation._id);
  };

  return (
    <List.Item
      onClick={() => handleListItemOnClick(item)}
      className={setClassname(item)}
    >
      <List.Item.Meta
        avatar={
          <Avatar
            src={
              users.filter((userLocal) => userLocal._id !== user._id)[0]?.photo
            }
          />
        }
        title={conversationTitle()}
        description={description(itemMessages)}
      />
    </List.Item>
  );
};

ListConversationsItem.propTypes = {
  item: PropTypes.shape({
    last_message_date: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired
      })
    ).isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};
