export const headersColumn = [
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'rum',
    key: 'rum'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'due_date',
    key: 'due_date'
  },
  {
    label: 'customer_account',
    key: 'customer_account.label'
  },
  {
    label: 'customer_account_address',
    key: 'customer_account_address'
  },
  {
    label: 'total_with_taxes',
    key: 'total'
  },
  {
    label: 'to_pay',
    key: 'to_pay'
  }
];
