import dayjs from 'dayjs';

/**
 * Truncates a string to a maximum length of 30 characters.
 * If the string exceeds 30 characters, it is shortened and appended with an ellipsis ('...').
 *
 * @param {string} string - The string to be truncated.
 * @returns {string} The truncated string with an ellipsis if it exceeds 30 characters, otherwise the original string.
 */
export const truncateString = (string) => {
  if (string.length > 30) {
    return `${string.slice(0, 30)}...`;
  }
  return string;
};

/**
 * Formats a given date to a human-readable string.
 * If the date is from the current day, it returns the time in 'HH:mm' format.
 * Otherwise, it returns the date in 'DD/MM/YYYY' format.
 *
 * @param {string | Date} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const displayDate = (date) => {
  const formattedDate = dayjs(date);
  if (dayjs(formattedDate).startOf('day').isSame(dayjs().startOf('day'))) {
    return dayjs(date).format('HH:mm');
  }
  return dayjs(date).format('DD/MM/YYYY');
};
