import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { ListConversations } from './ListConversations';
import { ConversationThread } from './ConversationThread';
import { MessageWriter } from '../MessageWriter';

/**
 * @component
 * @name ConversationDisplay
 * @description A component that displays the list of conversations and the conversation thread
 * @returns {jsx} jsx - ConversationDisplay component
 */
export const ConversationDisplay = () => {
  const { t } = useTranslation();
  const {
    user: { _id, role }
  } = useAuthContext();
  const { selectedConversation, conversations } = useConversationContext();

  const title = () => {
    const { users } = selectedConversation;
    if (!users) return '';
    const { first_name, last_name } = users.filter(
      (userLocal) => userLocal._id !== _id
    )[0];
    return t('messages_and_notes.titles.sent_new_message_to', {
      first_name,
      last_name
    });
  };

  return (
    <Row>
      <Col xs={24} md={12} xl={6} className="conversations-list">
        <ListConversations />
      </Col>
      <Col xs={24} md={12} xl={18} className="conversation-col">
        <Row>
          <Col
            xs={24}
            xl={!role.includes('TABLET') ? 12 : 24}
            className="messages-col"
          >
            <ConversationThread />
          </Col>
          {!role.includes('TABLET') && (
            <Col xs={24} xl={12} className="new-message-col">
              {conversations.length > 0 && (
                <MessageWriter
                  title={title()}
                  url="/conversations"
                  type="message"
                />
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
