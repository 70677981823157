import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Generates menu items based on the provided event, openModal function, and user role.
 *
 * @hook
 *
 * @param {object} event - The event object containing event details.
 * @param {Function} openModal - The function to open a modal for creating reservations, absences, adaptations, or pickup times.
 * @param {object} user - The user object containing user details including role.
 * @returns {object[]} An array of menu items to be displayed based on the event and user role.
 */
export const useMenuItems = (event, openModal, user) => {
  const { t } = useTranslation();

  const events = [
    ...(event?.pickup_times || []),
    ...(event?.absences || [])
  ].map((pickupTime) => ({
    ...pickupTime,
    type: 'events'
  }));

  const refDay = (event?.reference_day || [])
    .filter((day) => Object.keys(day).some((key) => key === 'start_time'))
    .filter(Boolean)
    .map((slot) => ({ ...slot, on_previsional_planning: 'REGULARS' }));
  const formattedReservations = (event?.reservations || []).map(
    (reservation) => ({
      ...reservation,
      on_previsional_planning: 'RESERVATIONS'
    })
  );
  const formattedAdaptations = (event?.adaptations || []).map((adaptation) => ({
    ...adaptation,
    on_previsional_planning: 'ADAPTATIONS'
  }));

  const eventPlanificationToConsider =
    formattedAdaptations?.length > 0 ? formattedAdaptations : refDay;

  const planificationWithReservations =
    formattedReservations?.length > 0
      ? [...formattedReservations, ...eventPlanificationToConsider]
      : eventPlanificationToConsider;
  const previsionalPlanning = [...planificationWithReservations, ...events];

  const projectionPlanningSize =
    event?.childSchedule?.planningProjections?.length || 0;
  const previsionalPresence =
    refDay?.length > 0 ||
    event?.reservations?.length > 0 ||
    event?.adaptations?.length > 0;

  const eventAbsenceDeclaration =
    (event?.absences?.length || 0) === 0 &&
    (event?.pickup_times?.length || 0) === 0;

  const displayReservationAdaptationMenuItems =
    eventAbsenceDeclaration &&
    (event?.adaptations?.length === 0 ||
      !Object.keys(event).some((eventKey) => eventKey === 'adaptations')) &&
    (event?.reservations?.length === 0 ||
      !Object.keys(event).some((eventKey) => eventKey === 'reservations'));

  const displayAbsenceTimeRecorderButtonsMenuItem =
    projectionPlanningSize > 0 && previsionalPresence;

  const displayAbsenceButtonsMenuItem =
    displayAbsenceTimeRecorderButtonsMenuItem && event?.absences?.length === 0;

  const displayTimeRecorderMenuItem =
    displayAbsenceTimeRecorderButtonsMenuItem &&
    event?.pickup_times?.length < 2;

  const reservationButton = displayReservationAdaptationMenuItems &&
    user?.role !== 'users:TABLET' && {
      key: 'reservations',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'reservations',
              event?.child,
              'create',
              undefined,
              undefined,
              previsionalPlanning
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.reservations')}
        </Button>
      )
    };
  const absenceButton = displayAbsenceButtonsMenuItem &&
    user?.role !== 'users:TABLET' && {
      key: 'absences',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'absences',
              event?.child,
              'create',
              undefined,
              undefined,
              previsionalPlanning
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.absences')}
        </Button>
      )
    };
  const adaptationButton = displayReservationAdaptationMenuItems &&
    user?.role !== 'users:TABLET' && {
      key: 'adaptations',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'adaptations',
              event?.child,
              'create',
              undefined,
              undefined,
              previsionalPlanning
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.adaptations')}
        </Button>
      )
    };
  const timeRecorderButton = displayTimeRecorderMenuItem && {
    key: 'pickup_times',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'pickup_times',
            event?.child,
            'create',
            undefined,
            undefined,
            previsionalPlanning
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.pickup_times')}
      </Button>
    )
  };

  const items = [
    reservationButton,
    absenceButton,
    adaptationButton,
    timeRecorderButton
  ].filter(Boolean);

  return items;
};
