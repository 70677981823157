import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin, Modal } from 'antd';

/**
 * A modal component used for displaying a loading spinner and a message while submitting a form.
 *
 * @component
 * @param {boolean} isSubmitting - Indicates whether the form is currently being submitted.
 * @returns {JSX.Element} The SubmitModal component.
 */
export const SubmitModal = ({ isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <Modal open={isSubmitting} footer={null} width={800} closable={false}>
      <Row style={{ flexDirection: 'column' }} gutter={[32, 32]}>
        <Col style={{ textAlign: 'center' }}>
          <Spin />
        </Col>
        <Col style={{ textAlign: 'center' }}>
          {t('pre-registrations.form.form_submitting')}
        </Col>
      </Row>
    </Modal>
  );
};

SubmitModal.propTypes = {
  isSubmitting: PropTypes.bool
};

SubmitModal.defaultProps = {
  isSubmitting: false
};
