import dayjs from 'dayjs';

/**
 * Configures API request and response handling options for a given resource.
 *
 * @param {string} resourceName - The name of the resource.
 * @param {Object} record - The record object associated with the resource.
 * @param {string} childBase64 - Encoded base 64 child photo.
 * @param {string} setChildBase64 - Function to set base64 child state.
 * @param {string} parentBase64 - Encoded base 64 parent photo.
 * @param {string} setParentBase64 - Function to set base64 parent state.
 * @returns {Object} An object containing configuration options for API requests and responses.
 * @example
 * // Usage example:
 * const resourceConfig = config('parent', parentRecord, 'base64data');
 */
export const config = (resourceName, record) => {
  if (resourceName === 'parent') {
    return {
      onGetResource: {
        setFields: (data) => ({
          ...data
        })
      },
      onGetResourceFromModal: {
        setFields: () => ({
          ...record
        })
      },
      onUpdateResource: {
        setBody: (data) => ({
          ...data
        })
      }
    };
  }
  return {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.entry_date && dayjs(data.entry_date),
        value: data.weight.value,
        unit: data.weight.unit
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record,
        entry_date: dayjs(record?.entry_date),
        value: record?.weight?.value,
        unit: record?.weight?.unit
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
};
