import formatBodyForNextStep from './formatBodyForNextStep';

/**
 * Handles the next step in the pre-registration process.
 *
 * @function
 * @param {Object} form - Form instance.
 * @param {number} current - The current step index.
 * @param {function} setCurrent - The function to update the current step index.
 * @param {Object} preRegistration - The pre-registration object.
 * @param {function} setPreRegistration - The function to update the pre-registration object.
 * @returns {void}
 */
export default (
  form,
  current,
  setCurrent,
  preRegistration,
  setPreRegistration
) => {
  const values = form.getFieldsValue();

  switch (current) {
    case 0:
      formatBodyForNextStep(
        'additional_info',
        values,
        setPreRegistration,
        preRegistration
      );
      setCurrent(current + 1);
      break;
    case 1:
      formatBodyForNextStep(
        'customer_account',
        values,
        setPreRegistration,
        preRegistration
      );
      setCurrent(current + 1);
      break;
    case 2:
      formatBodyForNextStep(
        'payment_method',
        values,
        setPreRegistration,
        preRegistration
      );
      setCurrent(current + 1);
      break;
    default:
      break;
  }
};
