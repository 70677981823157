export const headersColumn = [
  {
    label: 'label',
    key: 'label'
  },
  {
    label: 'customer_nature',
    key: 'customer_nature'
  },
  {
    label: 'customer_account_address',
    key: 'customer_account_address'
  },
  {
    label: 'unpaid',
    key: 'unpaid'
  },
  {
    label: 'total',
    key: 'total'
  },
  {
    label: 'total_to_pay',
    key: 'total_to_pay'
  }
];
