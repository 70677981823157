export const headers = [
  {
    label: 'birth_date',
    key: 'birth_date'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'gender',
    key: 'gender'
  },
  {
    label: 'group',
    key: 'group'
  },
  {
    label: 'is_walking',
    key: 'is_walking'
  },
  {
    label: 'age',
    key: 'age'
  },
  {
    label: 'hygiene',
    key: 'hygiene'
  },
  {
    label: 'weight',
    key: 'weight'
  },
  {
    label: 'parent_1',
    key: 'parent_1'
  },
  {
    label: 'parent_2',
    key: 'parent_2'
  },
  {
    label: 'allergies',
    key: 'allergies'
  },
  {
    label: 'childhood_illnesses',
    key: 'childhood_illnesses'
  },
  {
    label: 'personalized_care_plan',
    key: 'personalized_care_plan'
  },
  {
    label: 'disability',
    key: 'disability'
  },
  {
    label: 'meal_type',
    key: 'meal_type'
  },
  {
    label: 'vegetarian',
    key: 'vegetarian'
  },
  {
    label: 'special_meat_diet',
    key: 'special_meat_diet'
  },
  {
    label: 'food_allergies',
    key: 'food_allergies'
  },
  {
    label: 'admin_care',
    key: 'admin_care'
  },
  {
    label: 'exit_permission',
    key: 'exit_permission'
  },
  {
    label: 'capture_media',
    key: 'capture_media'
  },
  {
    label: 'apply_makeup',
    key: 'apply_makeup'
  },
  {
    label: 'administer_antipyretic',
    key: 'administer_antipyretic'
  },
  {
    label: 'specific_permissions',
    key: 'specific_permissions'
  },
  {
    label: 'consent_date',
    key: 'consent_date'
  }
];
