import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTermAndConditionsFields } from '../Fields/termAndConditionsFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

/**
 * React component for rendering a terms and conditions form in contract creation/update.
 *
 * This component provides a form for capturing terms and conditions related to a contract.
 *
 * @component
 * @param {Function} onFinish - Callback function triggered when the form is successfully submitted.
 * @param {Object} initialValues - Initial values for the form fields.
 * @param {Function} setIsFlexible - Function to set isFlexible state.
 * @param {boolean} isFlexible - Boolean for flexible planning condition.
 * @param {boolean} isLoading - Boolean for button loader.
 * @returns {JSX.Element} The rendered TermsAndConditionsForm component.
 */
export const TermsAndConditionsForm = ({
  onFinish,
  setIsFlexible,
  isFlexible,
  contracts,
  purpose,
  isLoading
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { fields } = useTermAndConditionsFields(
    setIsFlexible,
    contracts,
    purpose
  );
  const generateFields = useGenerateFormItem();

  return (
    <>
      {fields.map((field) => generateFields('contracts', field))}
      <Row justify="end">
        <Button
          style={{ margin: '0 10px' }}
          type="link"
          danger
          onClick={() => navigate(-1)}
        >
          {t('buttons.cancel')}
          <CloseOutlined />
        </Button>
        {!isFlexible || purpose === 'create' ? (
          <Button type="primary" onClick={onFinish}>
            {t('buttons.next')}
            <ArrowRightOutlined />
          </Button>
        ) : (
          <Button
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            onClick={onFinish}
          >
            {t('buttons.validate')}
          </Button>
        )}
      </Row>
    </>
  );
};

TermsAndConditionsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  setIsFlexible: PropTypes.func,
  isFlexible: PropTypes.string,
  contracts: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  })
};

TermsAndConditionsForm.defaultProps = {
  initialValues: null,
  setIsFlexible: null,
  isFlexible: false,
  contracts: [],
  form: null
};
