import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { CheckoutForm } from './CheckoutForm';
import { Return } from './Return';

/**
 * CheckoutPaymentRouter component.
 * * This component handles the routing for the checkout features.
 *
 * @returns {JSX.Element} The JSX element representing the CheckoutPaymentRouter component.
 */
export const CheckoutPaymentRouter = () => (
  <Routes>
    <Route path="/checkout/:token" element={<CheckoutForm />} />
    <Route path="/return" element={<Return />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
