/**
 * Handles the key press event and prevents the default action if the key pressed is 'Enter'.
 *
 * @function
 *
 * @param {KeyboardEvent} event The keyboard event object.
 * @returns {void}
 */
export const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};
