import { Input } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * Generates a list of form fields with initial values and input components based on daycare information.
 *
 * This function creates a configuration array for form fields used in a form to display daycare information and analysis data. It includes fields for capacity, hourly range, annual working days, theoretical capacity, occupation rates for the past three years and the current year, previsional annual acts, hour limit, and remarks. Each field contains an initial value (if applicable) from the daycareInfos object and specifies an input component, such as a disabled input box or text area. Some fields also include a dynamic label that reflects the year relative to the current date.
 *
 * @hook
 *
 * @param {Object} daycareInfos - An object containing various pieces of information about the daycare, including capacity, hourly range, total working days, and more.
 * @returns {Array} An array of objects, each representing a field in the form. Each object includes the name of the field, an initial value (if applicable), and the input component to be used in the form.
 */

export const useFields = (daycareInfos) => {
  const { t } = useTranslation();

  return [
    {
      name: ['capacity'],
      initialValue: daycareInfos?.capacity,
      input: <Input disabled suffix={t('analysis.form.places')} />
    },
    {
      name: ['range_hour'],
      initialValue: daycareInfos?.hourRange,
      input: <Input disabled suffix="h" />
    },
    {
      name: ['annual_working_days'],
      initialValue: daycareInfos?.totalWorkingDays,
      input: <Input disabled />
    },
    {
      name: ['general_system_percentage'],
      label: t('analysis.form.%RG'),
      initialValue: daycareInfos?.general_system_percentage,
      input: <Input type="number" />
    },
    {
      name: ['theoric_capacity'],
      initialValue: daycareInfos?.theoric_capacity,
      input: <Input disabled />
    },
    ...(daycareInfos &&
      daycareInfos.occupationRates.length &&
      (daycareInfos?.occupationRates || [])
        .map(({ occupationRate, year }, index) => {
          if (occupationRate) {
            const translation =
              year !== dayjs().year()
                ? `${t('analysis.form.occupation_rate')} ${year}`
                : t('analysis.form.real_occupation_rate', {
                    year: dayjs().year()
                  });

            return {
              name: [`occupation_rate_N-${index + 1}`],
              translate: false,
              initialValue: occupationRate.rate.toFixed(2),
              label: translation,
              input: (
                <Input
                  disabled
                  suffix="%"
                  value={occupationRate.rate.toFixed(2)}
                />
              )
            };
          }
          return null;
        })
        .filter(Boolean)),
    {
      name: ['previsional_occupation_rate'],
      translate: false,
      initialValue: daycareInfos?.previsional_occupation_rate || 0,
      label: t('analysis.form.previsional_occupation_rate', {
        year: dayjs().year()
      }),
      input: <Input type="number" />
    },
    {
      name: ['previsional_annual_acts'],
      initialValue:
        daycareInfos?.totalWorkingDays &&
        daycareInfos?.capacity &&
        daycareInfos?.hourRange
          ? daycareInfos.totalWorkingDays *
            daycareInfos.capacity *
            daycareInfos.hourRange
          : 0,
      input: <Input disabled />
    },
    {
      name: ['hour_limit'],
      initialValue: daycareInfos.hour_limit,
      input: <Input suffix="€/h" type="number" />
    },
    {
      name: ['remarks'],
      initialValue: daycareInfos?.remarks,
      input: <TextArea rows={4} />
    }
  ];
};
