import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { message as antdMessage, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { AuthorizationsForm } from './Form';

/**
 * CreateUpdateAuthorization component for creating or updating authorizations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.auth_data - Authorization data to be displayed or updated.
 * @param {string} props.auth_data._id - The ID of the authorization.
 * @returns {React.Element} The rendered CreateUpdateAuthorization component.
 */
export const CreateUpdateAuthorization = ({ auth_data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        authorizations: { ...data, consent_date: dayjs(data.consent_date) }
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        const Fields = {
          ...data.authorizations
        };

        return Fields;
      }
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `authorizations/${auth_data._id}`,
        body:
          config.onUpdateResource && config.onUpdateResource.setBody
            ? config.onUpdateResource.setBody(body)
            : body
      });
      antdMessage.success(t('authorizations.form.success'));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    form.setFieldsValue(
      config.onGetResource && config.onGetResource.setFields
        ? config.onGetResource.setFields(auth_data)
        : auth_data
    );
  }, [auth_data]);

  return <AuthorizationsForm onFinish={updateResource} form={form} />;
};

CreateUpdateAuthorization.propTypes = {
  auth_data: PropTypes.shape({
    _id: PropTypes.string
  })
};
CreateUpdateAuthorization.defaultProps = {
  auth_data: null
};
