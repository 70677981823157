import PropTypes from 'prop-types';
import { Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

/**
 * A button component that toggles visibility.
 *
 * @component
 * @param {boolean} visible - Indicates whether the button is currently visible.
 * @param {function} setVisible - Callback function to update the visibility state.
 * @returns {JSX.Element} - The rendered button component.
 */
export const VisibleButton = ({ visible, setVisible }) => (
  <Button
    onMouseEnter={() => setVisible(true)}
    onMouseLeave={() => setVisible(false)}
    type="link"
  >
    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
  </Button>
);

VisibleButton.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func
};

VisibleButton.defaultProps = {
  visible: false,
  setVisible: () => {}
};
