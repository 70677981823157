import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { DescriptionList } from '../../../../../components';
import { useListContent } from './listContent';

/**
 * InformationsCard component
 * @component
 * @param {Object} data The data of the customer account
 * @returns {JSX.Element} The component
 */
export const InformationsCard = ({ data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data);

  return (
    <Card title={t('customer-account.show.informations.title')}>
      <DescriptionList data={listContent} translate layout="vertical" />
    </Card>
  );
};

InformationsCard.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    customer_nature: PropTypes.string,
    linked_children: PropTypes.arrayOf({})
  })
};

InformationsCard.defaultProps = {
  data: {}
};
