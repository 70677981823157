import { Route, Routes } from 'react-router-dom';
import { ListEmployees } from './ListEmployees';
import { ShowEmployee } from './ShowEmployee';
import { Exception } from '../../components';

export const EmployeeRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowEmployee />} />
    <Route index element={<ListEmployees />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
