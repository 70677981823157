// eslint-disable-next-line import/no-extraneous-dependencies
import { Line } from '@ant-design/plots';
import { useMemo } from 'react';
import { Flex, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHomeContext } from '../../../contexts/HomeContext';
import { formatAmount } from '../../../utils/formatter';

/**
 * @component
 * @description Component, renders the turnover card
 * @returns {JSX} Component JSX
 */
export const TurnoverCard = () => {
  const { turnoverData, totalTurnoverAmount } = useHomeContext();
  const { t } = useTranslation();

  /**
   * Formats the data to be translated and displayed in the chart
   * @function
   * @returns {Array} - An array of objects with the translated month and the value
   */
  const formattedData = () =>
    turnoverData && turnoverData.length > 0
      ? turnoverData.map(({ month, value }) => ({
          month: t(`year_months.${month}`),
          value: value === 0 ? null : Number(value?.toFixed(2))
        }))
      : [];

  const config = {
    data: formattedData(),
    xField: 'month',
    yField: 'value',
    yAxis: {
      label: {
        formatter: (value) => formatAmount(value)
      }
    },
    label: {
      formatter: ({ value }) => formatAmount(value)
    },
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2
      }
    },
    tooltip: {
      showMarkers: false,
      formatter: ({ value }) => ({
        name: t('home.turnover_card.value_label'),
        value: value ? formatAmount(value) : '0,00 €'
      })
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red'
        }
      }
    },
    interactions: [
      {
        type: 'marker-active'
      }
    ]
  };

  /**
   * Renders the component based on the current data
   * @function
   * @returns {JSX} - The component JSX - a chart or a loading spinner
   */
  const renderedComponent = useMemo(
    () =>
      !turnoverData ? (
        <Spin />
      ) : (
        <Flex vertical gap="large">
          <Line {...config} />
          <Flex justify="space-between">
            <p>{t('home.turnover_card.highest_value')} :</p>
            <p style={{ color: 'var(--secondaryColor)', fontSize: 16 }}>
              {formatAmount(totalTurnoverAmount)}
            </p>
          </Flex>
        </Flex>
      ),
    [totalTurnoverAmount, turnoverData]
  );

  return (
    <Card title={t('home.titles.turnover')} style={{ width: '100%' }}>
      {renderedComponent}
    </Card>
  );
};
