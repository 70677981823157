import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { MercuryTimePicker } from '../../components/DateAndTimePickers/MercuryTimePicker';

const { Option } = Select;

/**
 * A custom hook for generating form fields for activity creation or editing.
 *
 * This hook provides an array of form field configurations based on the given parameters.
 * It adapts the fields depending on whether the activity's origin is a group or a child.
 * The fields include selections for children, groups, dates, activity types, and sub-activities.
 * It uses the `useFollowUpContext` for sub-activities state management and `useTranslation` for field labels.
 *
 * @function
 * @param {Object[]} groups - Array of group objects.
 * @param {Object} activityConfiguration - Configuration object for activities.
 * @param {'child' | 'group'} origin - Origin type of the activity ('child' or 'group').
 * @param {Object[]} children - Array of child objects.
 * @param {string} purpose - The purpose of the form ('create' or 'edit').
 * @param {array} subActivities - Activities relative to their activity group.
 * @param {function} setSubActivities - Function to set subActivities state.
 * @param {function} setSelectedTime - Function to set selectedTime state.
 * @param {string} selectedTime - The selected time.
 * @param {boolean} loadingActivities - Loading state for activities.
 * @param {boolean} loadingChildren - Loading state for children.
 * @param {object} child - Child selected if we are located on child tab.
 * @returns {Object} An object containing an array of field configurations.
 *
 * @example
 * const { fields } = useFields(groups, activityConfiguration, 'child', children);
 */
export const useFields = (
  groups,
  activityConfiguration,
  origin,
  children,
  purpose,
  subActivities,
  setSubActivities,
  setSelectedTime,
  selectedTime,
  loadingActivities,
  loadingChildren,
  child
) => {
  const { t } = useTranslation();

  const activities = activityConfiguration
    ? Object.keys(activityConfiguration).filter(
        (activity) =>
          !['_id', '__v', 'created_at', 'updated_at'].includes(activity)
      )
    : [];

  const fields = [
    ...(origin === 'child'
      ? [
          ...(purpose === 'create'
            ? [
                {
                  name: ['children'],
                  initialValue: child?._id || null,
                  input: (
                    <Select
                      loading={loadingChildren}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {(children || []).map(
                        ({ first_name, last_name, _id }) => (
                          <Option key={_id} value={_id}>
                            {`${first_name} ${last_name}`}
                          </Option>
                        )
                      )}
                    </Select>
                  )
                }
              ]
            : []),
          {
            name: ['groups'],
            hidden: true,
            input: (
              <Select mode="multiple" allowClear showSearch>
                {(groups || []).map((group) => (
                  <Option key={group._id} value={group._id}>
                    {group.label}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [
          {
            name: ['groups'],
            rules: [{ required: false }],
            input: (
              <Select mode="multiple">
                {(groups || []).map((group) => (
                  <Option key={group._id} value={group._id}>
                    {group.label}
                  </Option>
                ))}
              </Select>
            )
          }
        ]),
    {
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <MercuryTimePicker
          onChange={setSelectedTime}
          selectedTime={selectedTime}
          disabledTimeRange={{ start: 6, end: 21 }}
        />
      )
    },
    {
      name: ['activity', 'type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={loadingActivities}
          onChange={(value) => {
            const filteredActivities = activityConfiguration[value];
            setSubActivities(filteredActivities);
          }}
        >
          {(activities || []).map((type) => (
            <Option key={type} value={type}>
              {t(`activities.form.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['activity', 'label'],
      rules: [{ required: true }],
      input: (
        <Select disabled={!subActivities?.length}>
          {(subActivities || []).map((group) => (
            <Option key={group?.label} value={group?.label}>
              {group?.label}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields
  };
};
