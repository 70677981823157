import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useState } from 'react';
import { CreateUpdateContainerModal } from '../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import useFields from './fields';
import { usePlanningContext } from '../../contexts/PlanningContext';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * @component EventModal
 * @description EventModal component. Displays the event modal
 * @param {Object} props Component props
 * @param {Boolean} props.isModalOpen Is the modal open
 * @param {Function} props.closeModal Function to close the modal
 * @param {String} props.purpose The purpose of the modal
 * @param {String} props.type The type of the event
 * @param {String} props.eventId The event id
 * @param {String} props.recorderId The recorder id
 * @param {Object} props.selectedDay The selected day
 * @param {Array} props.previsionalPlanning The previsional planning on selected day
 * @returns {JSX} The event modal
 */
const EventModal = ({
  isModalOpen,
  closeModal,
  purpose,
  type,
  eventId,
  recorderId,
  selectedDay,
  previsionalPlanning
}) => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { t } = useTranslation();
  const { daycare } = useAuthContext();
  const { selectedChild } = usePlanningContext();
  const [form] = Form.useForm();
  const [relatedEvent, setRelatedEvent] = useState(undefined);

  const { fields, isFieldsLoading } = useFields(
    selectedChild,
    selectedDay,
    purpose,
    type,
    eventId,
    daycare,
    previsionalPlanning,
    relatedEvent,
    setRelatedEvent,
    form
  );

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...(['pickup_times'].includes(type)
          ? {
              pickUpTimes: (data.pickup_times || []).map((pickup) => {
                const times0 = pickup.start_time
                  ? dayjs.utc(pickup.start_time)
                  : null;
                const times1 = pickup.end_time
                  ? dayjs.utc(pickup.end_time)
                  : null;
                return {
                  times: [times0, times1],
                  eventId: pickup._id
                };
              })
            }
          : {
              ...data[type]?.find((item) => item?._id === eventId),
              related_previsional: 0,
              times: [
                dayjs.utc(
                  data[type]?.find((item) => item?._id === eventId)?.start_time
                ),
                dayjs.utc(
                  data[type]?.find((item) => item?._id === eventId)?.end_time
                )
              ]
            }),
        date: data.date && dayjs(data.date)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...(['pickup_times'].includes(type)
          ? {
              pickup_times:
                data.pickUpTimes &&
                (data.pickUpTimes || []).map((pickup) => {
                  if (
                    !(
                      pickup.times[1].format('HH:mm:ss') <
                      pickup.times[0].format('HH:mm:ss')
                    )
                  ) {
                    return {
                      start_time: pickup.times[0]
                        .utcOffset(0)
                        .year(data.date.year())
                        .month(data.date.month())
                        .date(data.date.date())
                        .hour(pickup.times[0].hour())
                        .minute(pickup.times[0].minute()),
                      end_time: pickup.times[1]
                        .utcOffset(0)
                        .year(data.date.year())
                        .month(data.date.month())
                        .date(data.date.date())
                        .hour(pickup.times[1].hour())
                        .minute(pickup.times[1].minute()),
                      _id: pickup.eventId
                    };
                  }
                  return {
                    start_time: pickup.times[1]
                      .utcOffset(0)
                      .year(data.date.year())
                      .month(data.date.month())
                      .date(data.date.date())
                      .hour(pickup.times[1].hour())
                      .minute(pickup.times[1].minute()),
                    end_time: pickup.times[0]
                      .utcOffset(0)
                      .year(data.date.year())
                      .month(data.date.month())
                      .date(data.date.date())
                      .hour(pickup.times[0].hour())
                      .minute(pickup.times[0].minute()),
                    _id: pickup.eventId
                  };
                })
            }
          : {
              eventId: data?.eventId && data.eventId,
              [type]: (() => {
                const time0 = dayjs(data.times[0]);
                const time1 = dayjs(data.times[1]);

                let startTime;
                let endTime;

                if (!(time1.format('HH:mm:ss') < time0.format('HH:mm:ss'))) {
                  startTime = time0;
                  endTime = time1;
                } else {
                  startTime = time1;
                  endTime = time0;
                }

                return {
                  start_time: startTime
                    .utcOffset(0)
                    .year(data.date.year())
                    .month(data.date.month())
                    .date(data.date.date())
                    .hour(startTime.hour())
                    .minute(startTime.minute()),
                  end_time: endTime
                    .utcOffset(0)
                    .year(data.date.year())
                    .month(data.date.month())
                    .date(data.date.date())
                    .hour(endTime.hour())
                    .minute(endTime.minute()),
                  reason: data?.reason && data.reason,
                  comment: data?.comment && data.comment,
                  billing: data?.billing && data.billing,
                  absence_deduction:
                    data?.absence_deduction && data.absence_deduction,
                  take_vacation: data?.take_vacation && data.take_vacation,
                  _id: data.eventId && data.eventId
                };
              })()
            }),
        daycare,
        date: data.date && dayjs(data.date),
        type: data?.type && data?.type
      })
    },
    onCreateResource: {
      setBody: (data) => {
        const newBody = data;

        if (!data.date) {
          newBody.date = dayjs(selectedDay).add(2, 'hour');
        } else {
          newBody.date = dayjs(data.date).add(2, 'hour');
        }

        return {
          ...newBody,
          daycare,
          ...(['pickup_times'].includes(newBody.type)
            ? {
                pickup_times: (newBody.pickUpTimes || []).map((slot) => ({
                  start_time: dayjs(slot.times[0])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(slot.times[0]).hour())
                    .minute(dayjs(slot.times[0]).minute()),
                  end_time: dayjs(slot.times[1])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(slot.times[1]).hour())
                    .minute(dayjs(slot.times[1]).minute())
                }))
              }
            : {
                times: [
                  dayjs(newBody.times[0])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(newBody.times[0]).hour())
                    .minute(dayjs(newBody.times[0]).minute()),
                  dayjs(newBody.times[1])
                    .utcOffset(0)
                    .year(newBody.date.year())
                    .month(newBody.date.month())
                    .date(newBody.date.date())
                    .hour(dayjs(newBody.times[1]).hour())
                    .minute(dayjs(newBody.times[1]).minute())
                ]
              })
        };
      }
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`plannings.children.modal.title.${purpose}.${type}`)} ${
        selectedChild?.first_name
      } ${selectedChild?.last_name}`}
      width={700}
      footer={false}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="pickup-times/event"
        resource="time-recorder"
        closeModal={closeModal}
        id={recorderId}
        config={config}
        withoutPageHeaderCustom
        customFormInstance={form}
      />
    </Modal>
  );
};

EventModal.propTypes = {
  recorderId: PropTypes.string,
  eventId: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedChild: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    _id: PropTypes.string
  }),
  purpose: PropTypes.string,
  type: PropTypes.string.isRequired,
  selectedDay: PropTypes.instanceOf(dayjs),
  previsionalPlanning: PropTypes.arrayOf(PropTypes.shape({}))
};

EventModal.defaultProps = {
  selectedChild: null,
  purpose: null,
  eventId: null,
  recorderId: null,
  previsionalPlanning: null,
  selectedDay: undefined
};

export default EventModal;
