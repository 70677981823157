import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * CreateUpdateOpeningHours
 *
 * @component
 * @description Component for create and update opening hours
 * @param {string} purpose - purpose of the modal (create or edit)
 * @param {boolean} isModalOpen - is modal open
 * @param {function} setIsModalOpen - set is modal open
 * @param {object} record - record
 * @param {function} setRefresh - set refresh
 * @param {boolean} refresh - refresh
 * @example
 * return (
 *  <CreateUpdateOpeningHours
 *   fields={fields}
        loadingFields={isFieldsLoading}
        isCustomSubmitting={isSubmitting}
        purpose={purpose}
        customSubmit={customSubmit}
        resource="daycares"
        config={config}
        isModalOpen
        withoutPageHeaderCustom
        closeModal={onOk}
        withGetResource={false}
        record={record}
        forceRefresh={refresh}
 *  />
 * )
 *
 * @returns {component} CreateUpdateOpeningHours
 */
export const CreateUpdateOpeningHours = ({
  purpose,
  record,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const { fields, isFieldsLoading } = useFields(purpose, refresh);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const formatHour = (hour) =>
    dayjs(hour)
      .hour(dayjs(hour).utcOffset(0).hour())
      .minute(dayjs(hour).utcOffset(0).minute());

  const formatDataOnGet = (data) => ({
    ...data,
    morning_opening: data.morning_opening && formatHour(data.morning_opening),
    morning_closing: data.morning_closing && formatHour(data.morning_closing),
    afternoon_opening:
      data.afternoon_opening && formatHour(data.afternoon_opening),
    evening_closing: data.evening_closing && formatHour(data.evening_closing)
  });

  const formatDataOnUpdate = (data) => ({
    ...data,
    morning_opening: data.morning_opening
      .utcOffset(0)
      .hour(data.morning_opening.hour())
      .minute(data.morning_opening.minute()),
    morning_closing: data.morning_closing && dayjs.utc(data.morning_closing),
    afternoon_opening:
      data.afternoon_opening && dayjs.utc(data.afternoon_opening),
    evening_closing: data.evening_closing
      .utcOffset(0)
      .hour(data.evening_closing.hour())
      .minute(data.evening_closing.minute())
  });

  const config = {
    onGetResourceFromModal: {
      setFields: (data) => formatDataOnGet(data)
    },
    onUpdateResource: {
      setBody: (data) => formatDataOnUpdate(data)
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/daycares/${daycare}/hours/${record._id}`,
          body: formatDataOnUpdate(body)
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/daycares/${daycare}/hours`,
          body: formatDataOnUpdate(body)
        });
      }
      form.resetFields();
      closeModal();
      setRefresh(!refresh);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {}, [refresh]);

  return (
    <Modal
      title={
        purpose === 'create'
          ? t('daycares.form.add_opening_hours')
          : t('daycares.form.edit_opening_hours')
      }
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        isCustomSubmitting={isSubmitting}
        purpose={purpose}
        customSubmit={customSubmit}
        resource="daycares"
        config={config}
        customFormInstance={form}
        isModalOpen
        withoutPageHeaderCustom
        closeModal={closeModal}
        withGetResource={false}
        record={record}
        forceRefresh={refresh}
      />
    </Modal>
  );
};

CreateUpdateOpeningHours.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

CreateUpdateOpeningHours.defaultProps = {
  purpose: '',
  record: {
    _id: ''
  },
  setRefresh: () => {},
  refresh: false
};
