import { Route, Routes } from 'react-router-dom';

import { Exception } from '../components';
import { Communication } from '../routes/communication/Communication';
import { ShowResource } from '../routes/communication/TabsContent/ShowResource';

/**
 * CommunicationRouter handles the routing for communication-related pages.
 *
 * @component
 *
 * This component uses react-router-dom's Routes and Route to define the navigation
 * structure for communication-related content. It renders different components
 * based on the URL path.
 *
 * Routes:
 * - "/": Renders the Communication component as the default view.
 * - "/show/:resourceName/:id": Renders the ShowResource component,
 *   which shows details for a specific resource. The resourceName and id
 *   are dynamic segments in the URL.
 * - "*": Catches all other paths and renders the Exception component,
 *   which could be a 404 or a similar error message.
 *
 * @returns {JSX.Element} The Routes component with nested Route components.
 */
export const CommunicationRouter = () => (
  <Routes>
    <Route index element={<Communication />} />
    <Route path="show/:resourceName/:id" element={<ShowResource />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
