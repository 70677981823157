import { useState, useEffect } from 'react';
import { Select, Divider, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from '../utils/errorMessage';
import { DaycareModal } from './Daycare/DaycareModal';
import { useHandleResize } from '../utils/handleResize';

/**
 * @description AdminDaycareSelector component
 * @component
 * @returns {JSX} The daycare selector for the admin
 */
const AdminDaycareSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser, daycare, setDaycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [daycares, setDaycares] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(null);
  const { width } = useHandleResize();

  const onOpenModal = () => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(<DaycareModal key={modalKey} setRefresh={setRefresh} />);
  };

  const patchSelectedDaycare = async (daycareId) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/users/last-selected-daycare`,
        body: {
          last_selected_daycare: daycareId
        }
      });
      setUser(data);
    } catch (error) {
      message(error);
    }
  };

  const selectDaycare = (value) => {
    setIsLoading(true);
    setDaycare(value);
    patchSelectedDaycare(value);
    navigate('/configurations');
    setIsLoading(false);
  };

  const getDaycares = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/daycares/name?fields=-logo,-stamp,-manager_signature,-photo'
      });
      setDaycares(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getDaycares();
    })();
  }, [refresh]);

  const arrayData = (dCare) => {
    const daycareCells = dCare
      .map((item) => ({
        value: item._id,
        label: item.name
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [
      ...[
        {
          value: undefined,
          label: t('placeholder.select_daycare')
        }
      ],
      ...daycareCells
    ];
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div
      style={{
        width: '90%',
        margin: '5%',
        position: 'absolute',
        bottom: 10
      }}
    >
      {modal}
      <Divider style={{ backgroundColor: 'var(--clientColor)' }} />
      <Select
        loading={isLoading}
        defaultValue={user.last_selected_daycare || daycare || undefined}
        style={{
          width: '100%'
        }}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={(value) => selectDaycare(value)}
        options={arrayData(daycares)}
        dropdownStyle={{ minWidth: 200 }}
      />
      <Button
        type="primary"
        style={{
          marginTop: 10,
          width: '100%'
        }}
        onClick={() => onOpenModal()}
      >
        {width > 992 ? t('buttons.add_daycare') : '+'}
      </Button>
    </div>
  );
};
export default AdminDaycareSelector;
