import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { routes } from '../../../../utils/constants/adminRoutes';

const { Title } = Typography;

/**
 * Displays a news item as a card.
 *
 * This component presents a news item using Ant Design's `Card` component. It shows a thumbnail image as the card's cover,
 * the title of the news, and a content preview using React Quill in read-only mode. The content and title are clickable and link to the
 * full news item page. The cover image is obtained from the server using a provided thumbnail ID and a user token for authentication.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.news - The news item data to display. It contains the ID, visibility dates, content, thumbnail, title,
 *                              visibility tags, and the number of views.
 * @returns {JSX.Element} The rendered news theme card component.
 */
export const NewsThemeCard = ({ news }) => {
  const { token } = useAuthContext();

  return (
    <Link to={`${routes.COMMUNICATION}/show/news/${news._id}`}>
      <Card
        className="news-theme-card"
        bordered={false}
        cover={
          news?.thumbnail && (
            <img
              src={`${process.env.REACT_APP_API_URL}/news/get-img-url/${news?.thumbnail}/${token}`}
              alt="thumbnail-icon"
            />
          )
        }
      >
        <Title level={4} className="cursive">
          {news?.title}
        </Title>
        <ReactQuill
          value={news?.content}
          readOnly
          theme="bubble"
          modules={{ toolbar: false }}
          className="quill-card"
        />
      </Card>
    </Link>
  );
};

NewsThemeCard.propTypes = {
  news: PropTypes.shape({
    _id: PropTypes.string,
    start_visibility: PropTypes.string,
    end_visibility: PropTypes.string,
    content: PropTypes.string,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    visibility: PropTypes.arrayOf(PropTypes.string),
    views: PropTypes.number
  })
};

NewsThemeCard.defaultProps = {
  news: null
};
