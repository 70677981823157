import { useTranslation } from 'react-i18next';
import { Input, Form, Select, Switch } from 'antd';

const { Option } = Select;

/**
 * @hook useFields
 * @description Hook to get fields for the form
 * @returns {Array} The fields of the contact form
 */
export const useFields = () => {
  const { t } = useTranslation();

  return [
    {
      name: ['is_principal_contact'],
      valuePropName: 'checked',
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    },
    {
      name: ['contact_label'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ type: 'email' }]
    },
    {
      name: ['mobile_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['landline_phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['landline_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['landline_phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];
};
