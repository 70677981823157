import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to generate content for the list component in the employee details view.
 *
 * This hook prepares and formats the general and position-specific information of an employee to be displayed using Ant Design's `Tag` component and other
 * UI elements. It handles displaying of various employee details including name, pickup code, phone number, email, address, position, children groups, and
 * contract information. It also formats phone numbers and dates appropriately for readability and internationalization support.
 *
 * @param {Object} data - Employee data object.
 * @param {string} [data.first_name] - Employee's first name.
 * @param {string} [data.last_name] - Employee's last name.
 * @param {Object} [data.mobile_phone_number] - Employee's mobile phone number object.
 * @param {string} [data.email] - Employee's email address.
 * @param {Object} [data.code] - Employee's code object.
 * @param {string} [data.position] - Employee's position.
 * @param {Array} [data.children_groups] - Employee's children groups.
 * @param {string} [data.contract_start_date] - Employee's contract start date.
 * @param {string} [data.contract_end_date] - Employee's contract end date.
 * @param {number} [data.contract_hours_per_week] - Employee's contract hours per week.
 * @param {string} [data.contract_type] - Employee's contract type.
 * @param {Object} [data.address] - Employee's address object.
 * @returns {Object} An object containing two arrays: `generalInformations` and `positionInformations`, each containing formatted information for display.
 */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    first_name,
    last_name,
    mobile_phone_number,
    email,
    code = {},
    position,
    children_groups,
    contract_start_date,
    contract_end_date,
    contract_hours_per_week,
    contract_type,
    address
  } = data;

  const { code: pickupCode } = code;

  const generalInformations = [
    {
      label: 'employees.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'employees.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'employees.form.time_code',
      span: 1,
      content: pickupCode || '-'
    },
    {
      label: 'employees.form.phone_number_full',
      span: 1,
      content:
        mobile_phone_number && mobile_phone_number?.number ? (
          <div>
            {`${
              mobile_phone_number?.country_code
            } ${mobile_phone_number?.number.slice(
              0,
              1
            )} ${mobile_phone_number?.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'employees.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'employees.form.address_full',
      span: 1,
      content:
        address && address?.number !== '' ? (
          <>
            {address?.number} {address?.street}
            <br />
            {address?.additional}
            {address?.additional && <br />}
            {address?.postal_code} {address?.city}
          </>
        ) : (
          '-'
        )
    }
  ];

  const positionInformations = [
    {
      label: 'employees.form.position',
      span: 2,
      content:
        position && position.label ? (
          <Tag color={position?.color?.hex}>{position.label}</Tag>
        ) : (
          '-'
        )
    },
    {
      label: 'employees.form.children_groups',
      span: 1,
      content: children_groups?.length
        ? children_groups.map((group) => (
            <Tag color={group?.tag_color} key={group?._id}>
              {group?.label}
            </Tag>
          ))
        : '-'
    },
    {
      label: 'employees.form.contract_start_date',
      span: 1,
      content: contract_start_date
        ? dayjs.utc(contract_start_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: 'employees.form.contract_end_date',
      span: 1,
      content: contract_end_date
        ? dayjs.utc(contract_end_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: 'employees.form.contract_hours_per_week',
      span: 1,
      content: contract_hours_per_week || '-'
    },
    {
      label: 'employees.form.contract_type',
      span: 1,
      content: contract_type
        ? t(`employees.enums.contract_types.${contract_type}`)
        : '-'
    }
  ];

  return { generalInformations, positionInformations };
};
