import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
  message as antDmessage
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  TableOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  drawerTailFormLayout,
  formItemLayout
} from '../../../utils/constants/formLayout';

/**
 * A component that renders a button to export Filoue data. When clicked, it opens a modal
 * with a form allowing the user to input specific details required for the export. The export
 * process is initiated upon form submission. This component uses the `useAuthContext` to access
 * user and daycare information, `useErrorMessage` for error handling, and `useTranslation` for
 * internationalization.
 *
 * @component
 * @example
 * return (
 *   <FiloueExportButton />
 * )
 *
 * @returns {JSX.Element} The FiloueExportButton component with a button to export data. On button click,
 * opens a modal containing a form for data export parameters. The form submission triggers data fetching
 * and file download. Conditional rendering based on user role.
 */
export const FiloueExportButton = () => {
  const { daycare, dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userBoolean = !['users:TABLET', 'users:PARENT'].includes(user?.role);

  const getFiloueExport = async (body) => {
    setIsSubmitting(true);
    try {
      const { headers, data } = await dispatchAPI('POST', {
        url: `daycares/filoue`,
        body: {
          ...body,
          daycare
        },
        responseType: 'arraybuffer'
      });

      let mimeType = 'application/octet-stream';
      let fileExtension = '';
      if (body.format === 'xlsx') {
        mimeType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileExtension = '.xlsx';
      } else if (body.format === 'csv') {
        mimeType = 'text/csv';
        fileExtension = '.csv';
      }

      const blob = new Blob([data], { type: mimeType });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download =
        headers['content-disposition'] || `export${fileExtension}`;
      link.click();

      antDmessage.success(t('children.documents.modal.success'));
      setOpen((prev) => !prev);
      return URL.revokeObjectURL(url);
    } catch (e) {
      return message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModal = () => {
    setOpen((prev) => !prev);
  };

  return (
    userBoolean && (
      <>
        <Button icon={<TableOutlined />} onClick={handleModal} type="primary">
          {t('buttons.filoue_export')}
        </Button>
        <Modal
          open={open}
          onCancel={handleModal}
          footer={null}
          width={800}
          title={t('daycares.form.filoue_informations')}
        >
          <Form form={form} onFinish={getFiloueExport}>
            <Form.Item
              label={
                <Space>
                  {t('daycares.form.Afas_Omega_unique_identifier')}
                  <Tooltip title={t('daycares.form.Afas_Omega_info')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              name={['Afas_Omega_unique_identifier']}
              required
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  {t('daycares.form.CAF_code')}
                  <Tooltip title={t('daycares.form.Afas_Omega_info')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              name={['CAF_code']}
              required
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('daycares.form.year')}
              name={['year']}
              required
              {...formItemLayout}
            >
              <DatePicker picker="year" />
            </Form.Item>
            <Form.Item
              label={t('daycares.form.format')}
              name={['format']}
              required
              {...formItemLayout}
            >
              <Radio.Group
                options={[
                  { label: '.xlsx', value: 'xlsx' },
                  { label: '.csv', value: 'csv' }
                ]}
              />
            </Form.Item>
            <Form.Item {...drawerTailFormLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={handleModal}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {`${t('buttons.download')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  );
};
