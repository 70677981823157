import { useState, useEffect } from 'react';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Flex } from 'antd';
import { Days } from './Days';
import { usePlanningContext } from '../../contexts/PlanningContext';

const weekOfYear = require('dayjs/plugin/weekOfYear');
const isoWeek = require('dayjs/plugin/isoWeek');

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

/**
 * PlanningHeader
 * @component
 *
 * @description Componente PlanningHeader - Header of the children and employees planning page, made to select the week to display
 *
 * @returns {JSX} - Children and employees planning header
 */
export const PlanningHeader = () => {
  const [day, setDay] = useState(dayjs());
  const {
    setSelectedDay,
    selectedDay,
    setSelectedWeek,
    selectedWeek,
    statsPerWeek,
    daycareSelected,
    daycareClosedDays
  } = usePlanningContext();

  const weekInitialValues = () => {
    const firstDayOfWeek = dayjs(day).startOf('isoWeek'); // 'isoWeek' to let the week start on monday
    const weekArray = [];
    for (let i = 0; i < 5; i += 1) {
      const dayOfWeek = dayjs(firstDayOfWeek).add(i, 'day');
      weekArray.push(dayOfWeek);
    }
    setSelectedWeek(weekArray);
  };

  useEffect(
    () => () => {
      setSelectedDay(dayjs());
    },
    []
  );

  useEffect(() => {
    weekInitialValues();
  }, [selectedDay]);

  const handleChangeWeek = (purpose) => {
    if (purpose === 'add') {
      setDay(dayjs(day).add(7, 'day').startOf('week'));
      setSelectedDay(day.add(7, 'day').startOf('week'));
    }
    if (purpose === 'subsctract') {
      setDay(dayjs(day).subtract(7, 'day').startOf('week'));
      setSelectedDay(day.subtract(7, 'day').startOf('week'));
    }
  };

  return (
    <>
      <Flex justify="center" align="center">
        <Button type="link" onClick={() => handleChangeWeek('subsctract')}>
          <LeftCircleFilled
            style={{ color: 'var(--primaryColor)', fontSize: 24 }}
          />
        </Button>
        <Flex vertical style={{ padding: 8, marginRight: 24, marginLeft: 24 }}>
          <Flex
            justify="center"
            className="planning-week-title"
          >{`Semaine ${dayjs(selectedDay).isoWeek()}`}</Flex>
          <Flex justify="center" style={{ color: 'var(--disabledColor)' }}>
            <Flex>{`${dayjs(selectedWeek?.[0]).format('DD/MM/YYYY')} - ${dayjs(
              selectedWeek?.[4]
            ).format('DD/MM/YYYY')}`}</Flex>
          </Flex>
        </Flex>
        <Button type="link" onClick={() => handleChangeWeek('add')}>
          <RightCircleFilled
            style={{ color: 'var(--primaryColor)', fontSize: 24 }}
          />
        </Button>
      </Flex>
      <Days
        week={selectedWeek}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        statsPerWeek={statsPerWeek}
        daycareSelected={daycareSelected}
        daycareClosedDays={daycareClosedDays}
      />
    </>
  );
};

PlanningHeader.propTypes = {};

PlanningHeader.defaultProps = {};
