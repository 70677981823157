import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Calculates and returns the contract duration between the provided start and end dates.
 * The function uses the `dayjs` library to determine the duration.
 * It will return the duration in days, months, or years depending on the length of time.
 *
 * @param {string} rawStartDate - The raw start date of the contract (expected to be in a format understood by dayjs).
 * @param {string} rawEndDate - The raw end date of the contract (expected to be in a format understood by dayjs).
 * @param {function} t - The translation function used for translating text.
 * @returns {React.ElementType} Rendered duration in days, months, or years.
 */
export const calculateContractDuration = (rawStartDate, rawEndDate, t) => {
  const startDate = dayjs(rawStartDate);
  const endDate = dayjs(rawEndDate);

  const diff = endDate.diff(startDate);
  const dur = dayjs.duration(diff);

  switch (true) {
    case dur.months() < 1 && dur.years() < 1:
      return (
        <div>{`${dur.days()} ${t('pre-registrations.form.date_days')}`}</div>
      );
    case dur.years() < 1:
      return (
        <Row style={{ flexDirection: 'column' }}>
          <Col>{`${dur.months()} ${t('pre-registrations.form.months')}`}</Col>
          {dur.days() > 0 && (
            <Col>{`${dur.days()} ${t(
              'pre-registrations.form.date_days'
            )}`}</Col>
          )}
        </Row>
      );

    default:
      return (
        <Row style={{ flexDirection: 'column' }}>
          <Col>{`${dur.years()} ${t('pre-registrations.form.years')}`}</Col>
          {dur.months() > 0 && (
            <Col>{`${dur.months()} ${t('pre-registrations.form.months')}`}</Col>
          )}
          {dur.days() > 0 && (
            <Col>{`${dur.days()} ${t(
              'pre-registrations.form.date_days'
            )}`}</Col>
          )}
        </Row>
      );
  }
};
