/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useFields = () => {
  const fields = [
    {
      label: 'name',
      name: 'name',
      rules: [{ required: true }]
    }
  ];

  return { fields };
};
