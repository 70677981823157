import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { formatNumberWithSpaces } from '../../../../utils/formatter';

/**
 * Generates table column configurations for displaying financial data.
 *
 * This custom hook creates a set of columns for a table component, with the content and format
 * of the columns depending on the specified mode ('previsional' or 'real'). It uses internationalization
 * for column titles and provides custom rendering functions for displaying file names, formatting dates,
 * and concatenating author names. The hook supports sorting for file names and years.
 *
 * @hook
 *
 * @param {string} mode - Determines the type of data to display ('previsional' or 'real').
 * @returns {Array} An array of column configurations for use in a table component.
 */
export const useColumns = (mode) => {
  const { t } = useTranslation();

  return [
    {
      title: t('analysis.table.code'),
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: t('analysis.table.label'),
      key: 'label',
      dataIndex: 'label'
    },
    ...(mode === 'previsional'
      ? [
          {
            title: t('analysis.table.previsional'),
            key: 'previsional',
            dataIndex: 'previsional',
            editable: true,
            suffix: '€',
            render: (value) => (
              <Input
                bordered={false}
                disabled
                value={formatNumberWithSpaces(value)}
                suffix="€"
              />
            )
          }
        ]
      : [
          {
            title: t('analysis.table.real'),
            key: 'real',
            dataIndex: 'real',
            suffix: '€',
            editable: true,
            render: (value) => (
              <Input
                bordered={false}
                disabled
                value={formatNumberWithSpaces(value)}
                suffix="€"
              />
            )
          }
        ]),
    {
      title: t('analysis.table.CAF_declared_total'),
      key: 'annual_projection',
      dataIndex: 'annual_projection',
      editable: true,
      suffix: '€',
      render: (value) => (
        <Input
          bordered={false}
          disabled
          value={formatNumberWithSpaces(value)}
          suffix="€"
        />
      )
    }
  ];
};
