import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../components';

/**
 * Renders the header component for the checkout payment and return page.
 *
 * @component
 * @returns {JSX.Element|null} The rendered header component or null if `withHeader` is falsy.
 */
export const Header = () => {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const withHeader = urlParams.get('withHeader');

  return (
    withHeader === 'true' && (
      <PageHeaderCustom title={t('credit-card-payments.header_title')} />
    )
  );
};
