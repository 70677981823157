import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Table, Tag, Popover, Button, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  invoiceStatuses,
  contractStatus
} from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import InvoicingContext from '../InvoicingContext';

const { Column } = Table;

const ShowTransfer = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [transfer, setTransfer] = useState({});
  const { downloadTransfer } = InvoicingContext();
  const [enums, setEnums] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);

  const getTransfer = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transfers/${id}?populate=invoices.contract customer_account child rib`
      });
      setTransfer(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getTransfer();
    })();
  }, [getTransfer]);

  const downloadContent = (key) => (
    <Spin spinning={isDownloading}>
      <Button
        onClick={async () => {
          setIsDownloading(true);
          await downloadTransfer(key, 'csv', () => setIsDownloading(false));
        }}
      >
        CSV
      </Button>
      <Divider type="vertical" />
      <Button
        onClick={async () => {
          setIsDownloading(true);
          await downloadTransfer(
            key,
            'xml',
            () => setIsDownloading(false),
            transfer
          );
        }}
      >
        XML
      </Button>
    </Spin>
  );

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contracts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('transfers.show.title')}
        extra={
          <Popover
            content={() => downloadContent(id)}
            title={t('buttons.download')}
            trigger="click"
            placement="left"
          >
            <Button
              style={{ padding: 0 }}
              type="primary"
              icon={
                isDownloading[id] ? <LoadingOutlined /> : <DownloadOutlined />
              }
            />
          </Popover>
        }
      />
      <ContentCustom>
        <Typography.Title level={4}>
          {`${t('transfers.show.sub_title_quittances')}  (${
            transfer.levy_count
          }) - virements du ${moment(transfer.levy_date).format('DD/MM/YYYY')}`}
        </Typography.Title>
        <Typography.Text>
          {`${t('transfers.show.iban')}  -  ${transfer?.bank_account?.iban}`}
        </Typography.Text>
        {transfer && (
          <Table
            dataSource={(transfer.invoices || []).map((invoice) => ({
              ...invoice,
              key: invoice._id
            }))}
            loading={isLoading}
            scroll={{ x: 1000 }}
          >
            <Column
              title={t('transfers.show.customer_account')}
              key="name"
              render={(invoice) => (
                <Link
                  to={`${routes.INVOICING}${subRoutes.INVOICING['CUSTOMERS-ACCOUNTS']}/show/${invoice?.customer_account?._id}`}
                >
                  {`${invoice?.customer_account?.label}`}
                </Link>
              )}
              sorter={(a, b) =>
                a?.customer_account?.label?.localeCompare(
                  b?.customer_account?.label
                )
              }
              width={180}
            />
            <Column
              title={t('transfers.show.child')}
              key="last_name"
              render={(invoice) => (
                <Link to={`${routes.CHILDREN}/show/${invoice?.child?._id}`}>
                  {`${invoice?.child?.last_name} ${invoice?.child?.first_name}`}
                </Link>
              )}
              sorter={(a, b) =>
                a?.child?.last_name.localeCompare(b?.child?.last_name)
              }
              width={130}
            />
            <Column
              title={t('transfers.show.contract')}
              key="number"
              render={(invoice) => (
                <Link
                  to={`${routes.CHILDREN}/show/${invoice?.child?._id}/contracts/show/${invoice?.contract?._id}`}
                >
                  {`${invoice?.contract?.number}`}
                </Link>
              )}
              sorter={(a, b) =>
                a?.contract?.number?.localeCompare(b?.contract?.number)
              }
              width={120}
            />
            <Column
              title={t('transfers.show.contract_status')}
              key="status"
              render={(invoice) => (
                <Tag color={contractStatus[invoice?.contract?.status]}>
                  {t(`contracts.tags.${invoice?.contract?.status}`)}
                </Tag>
              )}
              filters={
                enums.statuses &&
                enums.statuses.map((r) => ({
                  text: t(`contracts.tags.${r}`),
                  value: r
                }))
              }
              onFilter={(value, record) =>
                record.contract.status.indexOf(value) === 0
              }
              sorter={(a, b) =>
                t(`contracts.tags.${a?.contract?.status}`).localeCompare(
                  t(`contracts.tags.${b?.contract?.status}`)
                )
              }
              width={180}
            />
            <Column
              title={t('transfers.show.invoice')}
              key="number"
              render={(invoice) => (
                <Link
                  to={`${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/show/${invoice?._id}`}
                >
                  {`${invoice?.number}`}
                </Link>
              )}
              sorter={(a, b) =>
                a?.contract?.number?.localeCompare(b?.contract?.number)
              }
              width={120}
            />
            <Column
              title={t('transfers.show.total')}
              key="rum"
              render={(invoice) => `${invoice?.total?.toFixed(2)} €`}
              width={100}
            />
            <Column
              title={t('transfers.show.invoice_status')}
              key="status"
              render={(invoice) => (
                <Tag color={invoiceStatuses[invoice?.status]}>
                  {t(`invoices.tags.${invoice?.status}`)}
                </Tag>
              )}
              sorter={(a, b) =>
                t(`invoices.tags.${a?.quittance_status}`).localeCompare(
                  t(`invoices.tags.${b?.quittance_status}`)
                )
              }
              width={150}
            />
            <Column
              title={t('transfers.show.rum')}
              key="rum"
              render={(invoice) => invoice?.rib?.rum}
              width={130}
            />
          </Table>
        )}
      </ContentCustom>
    </>
  );
};
export default ShowTransfer;
