import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfigurationTable from './ConfigurationTable';
import useConfigurationContext from './ConfigurationContext';

export const Configuration = ({ resourceName, resourceColumns }) => {
  const { forceRefresh, fetchItems, addItem } = useConfigurationContext();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [newItem] = useState({});

  useEffect(() => {
    fetchItems(resourceName, setItems);
  }, [forceRefresh]);

  return (
    <>
      {items.length > 0 && (
        <ConfigurationTable
          resourceColumns={resourceColumns}
          resourceName={resourceName}
          items={items}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%' }}
        onClick={() => addItem(newItem, resourceName)}
      >
        {t(`configurations.add.${resourceName}`)}
      </Button>
    </>
  );
};

Configuration.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
