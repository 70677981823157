import { useTranslation } from 'react-i18next';

/**
 * Columns for the table.
 * @hook
 * @returns {Array} The columns for the table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('configurations.invoicing.form.extra_duration.duration'),
      key: 'duration',
      dataIndex: 'duration',
      editable: false,
      render: (value) => `${value} min`,
      disabled: true
    },
    {
      title: t('configurations.invoicing.form.extra_duration.extra_fee'),
      key: 'extra_fee',
      dataIndex: 'extra_fee',
      editable: true,
      render: (value) => `${value} h`
    }
  ];
};
