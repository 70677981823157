import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Flex, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DescriptionList } from '../../../../components';
import { EditDietModal } from './EditDietModal';

/**
 * DietTab component for displaying diet-related information and allowing editing.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - A boolean indicating whether the component is in a loading state.
 * @param {Object} props.eatingHabits - Eating habits data to be displayed.
 * @param {boolean} props.refresh - A boolean flag indicating whether the component should trigger a refresh.
 * @param {function} props.setRefresh - A function to set the refresh state.
 * @param {Object} props.diet - Diet information to be displayed and edited.
 * @param {string} props.childName - The name of the child associated with the diet information.
 * @returns {React.Element} The rendered DietTab component.
 */
export const DietTab = ({
  isLoading,
  eatingHabits,
  refresh,
  setRefresh,
  diet,
  childName
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);

  const generateModal = () => {
    const modalKey = Date.now();
    setModal(
      <EditDietModal
        key={modalKey}
        forceRefresh={refresh}
        setForceRefresh={setRefresh}
        record={diet}
        childName={childName}
      />
    );
  };

  return (
    <>
      <Flex align="center" gap="small" wrap="nowrap">
        <Divider orientation="left">{t('children.show.diet.title')}</Divider>
        <Button type="primary" onClick={generateModal}>
          <EditOutlined />
          {t('buttons.edit')}
        </Button>
      </Flex>

      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Card title={t('children.show.diet.titles.eating-habits')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <DescriptionList
                layout="vertical"
                data={eatingHabits}
                translate
              />
            </Skeleton>
          </Card>
        </Col>

        <Col xs={24} xl={12}>
          <Card title={t('children.show.diet.titles.diet-allergies')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <Row style={{ flexDirection: 'column' }}>
                {diet?.allergies?.length ? (
                  diet?.allergies.map((allergy) => (
                    <Col key={allergy._id}>{allergy.label}</Col>
                  ))
                ) : (
                  <Col>{t('children.show.diet.allergies.no-allergies')}</Col>
                )}
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
      {modal}
    </>
  );
};

DietTab.propTypes = {
  isLoading: PropTypes.bool,
  eatingHabits: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  diet: PropTypes.shape({
    allergies: PropTypes.arrayOf(PropTypes.string)
  }),
  childName: PropTypes.string
};

DietTab.defaultProps = {
  isLoading: false,
  eatingHabits: null,
  refresh: false,
  setRefresh: null,
  diet: null,
  childName: undefined
};
