import PropTypes from 'prop-types';
import { Spin } from 'antd';

/**
 * CustomSpin component displays a loading spinner using Ant Design's Spin component.
 *
 * @component
 * @example
 * // Example usage:
 * // import { CustomSpin } from './CustomSpin';
 * // ...
 * // <CustomSpin style={{ top: '50%' }} />
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} props.style - The inline style object for the Spin component.
 *
 * @returns {JSX.Element} - The rendered CustomSpin component.
 */
export const CustomSpin = ({ style }) => {
  const DefaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%'
  };

  return <Spin spinning size="large" style={style || DefaultStyle} />;
};

CustomSpin.propTypes = {
  style: PropTypes.shape({})
};

CustomSpin.defaultProps = {
  style: undefined
};
