import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Steps, Button, message as Message } from 'antd';
import {
  ArrowLeftOutlined,
  TeamOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  SmileOutlined,
  ShopOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ChildForm } from './Forms/ChildForm';
import { DaycareForm } from './Forms/DaycareForm';
import { ContractForm } from './Forms/ContractForm';
import { ParentForms } from './Forms/ParentsForm';
import { Summary } from './Summary/Summary';
import { ReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { ContentCustom } from '../../components';
import { weeksFormatter, isNotEmptyWeek } from './Summary/utils/weeks';
import { usePreregistrationContext } from '../../contexts/PreregistrationContext';
import { DepartmentForm } from './Forms/DepartmentForm';
import { SuccessPreRegistration } from './SuccessPreRegistration';
import { ExternalPreregistrationMenu } from './ExternalPreregistrationMenu';
import { SubmitModal } from './SubmitModal';

/**
 * CreateUpdatePreRegistration
 * @description Component for create and update pre-registration
 *
 * @component
 * @param {string} purpose - purpose of the modal (create or edit)
 * @example
 * return (
 *  <CreateUpdatePreRegistration
 *   purpose={purpose}
 * />
 * )
 * @returns {component} CreateUpdatePreRegistration
 */
export const CreateUpdatePreRegistration = ({ purpose }) => {
  const [current, setCurrent] = useState(0);
  const [childDetails, setChildDetails] = useState(null);
  const [contractDetails, setContractDetails] = useState(null);
  const [contractNumber, setContractNumber] = useState(null);
  const [parent1Details, setParent1Details] = useState(null);
  const [parent2Details, setParent2Details] = useState(null);
  const [householdDetails, setHouseholdDetails] = useState(null);
  const [daycareDetails, setDaycareDetails] = useState(null);
  const { switchToForm, setSwitchToForm, setDepartmentFilter } =
    usePreregistrationContext();
  const { dispatchAPI, token } = useAuthContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setDatas = (data) => {
    const { daycares, child, contract_infos, parents, household, contract } =
      data;
    if (contract) {
      setContractNumber(contract);
    }
    setChildDetails({
      child: {
        ...child,
        birth_date: dayjs(child?.birth_date)
      }
    });
    setDaycareDetails(daycares);
    setContractDetails({
      contract: {
        ...contract_infos,
        start_date: dayjs(contract_infos?.start_date),
        end_date: dayjs(contract_infos?.end_date)
      }
    });
    setReferenceWeeks(contract_infos?.reference_weeks);
    setHouseholdDetails({
      ...household
    });
    for (let i = 0; i < parents.length; i += 1) {
      const parent = parents[i];
      if (i === 0) {
        setParent1Details({
          ...parent,
          employment_contract: parent?.employment_contract || null,
          company: parent?.company || null
        });
      } else {
        setParent2Details({
          ...parent,
          employment_contract: parent?.employment_contract || null,
          company: parent?.company || null
        });
      }
    }
  };

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pre-registrations/${id}?populate=contract`
      });
      setDatas(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      getData();
    }
  }, [purpose]);

  const onFinishChildForm = (values) => {
    setChildDetails(values);
    setCurrent(current + 1);
  };

  const onFinishContractForm = (values, boolean) => {
    if (!isNotEmptyWeek(referenceWeeks) && !boolean) {
      message(t('EMPTY_REFERENCE_WEEK'));
      return;
    }
    const contract = {
      ...values.contract,
      reference_weeks: weeksFormatter(referenceWeeks, purpose)
    };
    setContractDetails({ contract });
    setCurrent(current + 1);
  };

  const onFinishParentForms = (values) => {
    const { parent1, parent2, household } = values;
    setParent1Details(parent1);
    setParent2Details(parent2);
    setHouseholdDetails(household);
    setCurrent(current + 1);
  };

  const onFinishDaycareForm = (values) => {
    const { daycares } = values;
    const daycaresBody = daycares.map((daycare) => ({ _id: daycare }));
    setDaycareDetails(daycaresBody);
    setCurrent(current + 1);
  };

  const handlePrevious = () => {
    setCurrent(current - 1);
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        daycares: daycareDetails,
        contract: contractNumber,
        child: childDetails.child,
        contract_infos: contractDetails.contract,
        parents: [parent1Details],
        household: householdDetails
      };

      if (parent2Details !== null && parent2Details !== undefined) {
        payload.parents.push(parent2Details);
      }
      payload.contract_infos.company_contract =
        parent1Details?.employment_contract ||
        parent2Details?.employment_contract;

      await dispatchAPI(purpose === 'edit' ? 'PATCH' : 'POST', {
        url: `/pre-registrations${purpose === 'edit' ? `/${id}` : ''}`,
        body: payload
      });
      setDepartmentFilter();
      Message.success(
        t(
          purpose === 'edit'
            ? 'pre-registrations.messages.success.edit'
            : 'pre-registrations.messages.success.create'
        )
      );
      if (purpose === 'edit') {
        return navigate(-1);
      }
      setCurrent(current + 1);
      setIsSubmitting(false);

      return true;
    } catch (e) {
      setIsSubmitting(false);

      return message(e);
    }
  };

  const forms = [
    !token && purpose === 'create' && (
      <DaycareForm
        onFinish={onFinishDaycareForm}
        initialValues={daycareDetails}
      />
    ),
    <ChildForm
      onFinish={onFinishChildForm}
      initialValues={childDetails}
      handlePrevious={handlePrevious}
    />,
    <ContractForm
      onFinish={onFinishContractForm}
      handlePrevious={handlePrevious}
      initialValues={contractDetails}
      purpose={purpose}
    />,
    <ParentForms
      onFinish={onFinishParentForms}
      handlePrevious={handlePrevious}
      purpose={purpose}
      initialValues={{
        parent1: parent1Details,
        parent2: parent2Details,
        household: householdDetails
      }}
    />,
    <>
      <Summary
        childDetails={childDetails}
        contractDetails={contractDetails}
        parent1Details={parent1Details}
        parent2Details={parent2Details}
        householdDetails={householdDetails}
        setContractDetails={setContractDetails}
        referenceWeeks={referenceWeeks}
        daycareDetails={daycareDetails}
      />
      <Flex justify="end" gap="small">
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" onClick={submitForm}>
          {t('buttons.validate')}
        </Button>
      </Flex>
    </>
  ].filter(Boolean);

  const steps = [
    !token &&
      purpose === 'create' && {
        title: t('pre-registrations.steps.titles.daycare'),
        icon: <ShopOutlined />
      },
    {
      title: t('pre-registrations.steps.titles.child'),
      icon: <SmileOutlined />
    },
    {
      title: t('pre-registrations.steps.titles.contract'),
      icon: <FileTextOutlined />
    },
    {
      title: t('pre-registrations.steps.titles.parents'),
      icon: <TeamOutlined />
    },
    {
      title: t('pre-registrations.steps.titles.summary'),
      icon: <FileDoneOutlined />
    },
    {
      title: '',
      icon: <CheckOutlined />
    }
  ].filter(Boolean);

  return (
    <>
      {!token && <ExternalPreregistrationMenu />}
      {switchToForm || token ? (
        <ContentCustom
          style={{
            maxHeight: !token ? 'calc(100vh - 245px)' : 'fit-content',
            minHeight: !token ? 'unset' : 'fit-content',
            overflow: !token ? 'hidden auto' : 'inherit'
          }}
        >
          <Steps current={current} style={{ marginBottom: 16 }}>
            {steps.map((step) => (
              <Steps.Item
                key={step.title}
                title={step.title}
                icon={step.icon}
              />
            ))}
          </Steps>
          {forms[current]}
          {current === steps.length - 1 && (
            <SuccessPreRegistration
              setSwitchToForm={setSwitchToForm}
              setCurrent={setCurrent}
              setChildDetails={setChildDetails}
              setContractDetails={setContractDetails}
              setDaycareDetails={setDaycareDetails}
              setDepartmentFilter={setDepartmentFilter}
              setHouseholdDetails={setHouseholdDetails}
              setParent1Details={setParent1Details}
              setParent2Details={setParent2Details}
              setReferenceWeeks={setReferenceWeeks}
            />
          )}
        </ContentCustom>
      ) : (
        <ContentCustom>
          <DepartmentForm />
        </ContentCustom>
      )}
      <SubmitModal isSubmitting={isSubmitting} />
    </>
  );
};

CreateUpdatePreRegistration.propTypes = {
  purpose: PropTypes.string.isRequired
};
