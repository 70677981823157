import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Select, Input, Form, Card, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import InvoicingContext from '../InvoicingContext';

const { Option } = Select;

/**
 * Custom hook that provides fields configuration for creating or updating an invoice from scratch.
 *
 * @function
 * @returns {Object} The fields configuration and the loading state.
 * @returns {Array} return.fields - An array of field configurations for the form.
 * @returns {boolean} return.isFieldsLoading - The loading state of the fields.
 */
const useInvoiceFromScratchFields = () => {
  const { t } = useTranslation();
  const { invoiceEnums, childs, daycare, customerAccounts } =
    InvoicingContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const format = 'DD/MM/YYYY';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (childs?.length) setIsFieldsLoading(false);
  }, [childs]);

  const fields = [
    {
      name: ['child'],
      rules: [{ required: true }],
      startWithDivider: {
        orientation: 'left',
        plain: true,
        title: t('invoices.form.customer_to_invoice')
      },
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(childs || []).map((child) => (
            <Option key={child._id} value={child._id}>
              {`${child?.first_name} ${child?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contract'],
      rules: [{ required: true }],
      hidden: true
    },
    {
      name: ['customer_account'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(customerAccounts || []).map((customer) => (
            <Option key={customer._id} value={customer._id}>
              {customer?.label}
            </Option>
          ))}
        </Select>
      )
    },

    {
      name: ['payment_method'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(invoiceEnums?.paymentChoices || []).map((payment) => (
            <Option key={payment} value={payment}>
              {t(`payments.tags.${payment}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['active_contract'],
      hidden: true
    },
    {
      name: ['rib'],
      hidden: true
    },
    {
      name: ['daycare'],
      rules: [{ required: true }],
      initialValue: daycare,
      hidden: true
    },
    {
      name: ['due_date'],
      rules: [{ required: true }],
      input: <DatePicker format={format} />
    },

    {
      name: ['from_scratch_details'],
      startWithDivider: {
        orientation: 'left',
        plain: true,
        title: t('invoices.form.details_divider')
      },
      input: (
        <Form.List name="from_scratch_details" initialValue={[{}]}>
          {(fieldsDetails, { add, remove }) => (
            <>
              {fieldsDetails.map((field, index) => (
                <Card key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'label']}
                    name={[field.name, 'label']}
                    label={`${t('invoices.form.details.label')} ${index + 1}`}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'amount']}
                    name={[field.name, 'amount']}
                    label={t('invoices.form.details.amount')}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginTop: 16 }}
                  onClick={() => add()}
                >
                  {t('invoices.form.details.add_button')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      startWithDivider: {
        orientation: 'left',
        plain: true,
        title: t('invoices.form.invoice_total')
      },
      name: ['total'],
      rules: [{ required: true }],
      input: <Input type="number" suffix="€" disabled />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useInvoiceFromScratchFields;
