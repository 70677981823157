/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import { Modal } from 'antd';

/**
 * Calculates the total number of resources that match a specific topic within an array of resource objects.
 *
 * The function filters the provided resources based on the given topic and returns the count of resources
 * that match this topic. It assumes that each resource object has a 'topic' property.
 *
 * @function
 *
 * @param {Array} resources - An array of resource objects, each with a 'topic' property.
 * @param {string} topic - The topic to filter the resources by.
 * @returns {number} The count of resources that match the given topic.
 */
export const getTotalByTopic = (resources, topic) =>
  resources.filter((resource) => resource.topic === topic).length;

/**
 * Sorts an array of resource objects by the number of views in descending order and returns the top 3.
 *
 * This function assumes that each resource object has a 'views' property, which is an array.
 * The function sorts the resources based on the length of this array (i.e., the number of views).
 * If there are 3 or fewer resources, it returns the original array without sorting.
 *
 * @function
 *
 * @param {Array} resources - An array of resource objects. Each object should have a 'views' property.
 * @returns {Array} An array of the top 3 resources sorted by the number of views, or the original array if it contains 3 or fewer items.
 */
export const sortByMostViews = (resources) => {
  if (resources.length <= 3) {
    return resources;
  }
  return resources
    .sort((a, b) => {
      const aLength = a?.views?.length || 0;
      const bLength = b?.views?.length || 0;
      return bLength - aLength;
    })
    .slice(0, 3);
};

/**
 * Formats the visibility dates of a news item for display.
 *
 * This function takes a news item object and returns a formatted string of its visibility dates.
 * If only the start date is available, it indicates from when the news is visible.
 * If both start and end dates are available, it shows the range of visibility.
 *
 * @function
 *
 * @param {Object} news - The news item object with `start_visibility` and `end_visibility` properties.
 * @param {Function} t - Translation function for localizing strings.
 * @returns {string} Formatted visibility date/dates for the news item.
 */
export const dateRender = (news, t) => {
  if (news?.start_visibility && !news?.end_visibility) {
    return `${t('communication.news.card.since')} ${dayjs(
      news?.start_visibility
    ).format('DD/MM/YYYY')}`;
  }
  return `${t('communication.news.card.from')} ${dayjs(
    news?.start_visibility
  ).format('DD/MM/YYYY')} ${t('communication.news.card.to')} ${dayjs(
    news?.end_visibility
  ).format('DD/MM/YYYY')}`;
};

/**
 * Compresses the given file, converting HEIC format to JPEG if necessary.
 *
 * @function
 *
 * @param {File} file - The file to be compressed.
 * @returns {Promise<File>} A Promise that resolves to the compressed File.
 */
export const compressFile = async (file) => {
  // Get the file extension in lowercase
  const fileExtension = file.name.split('.').pop().toLowerCase();
  // Check if the file is in HEIC format
  if (fileExtension === 'heic') {
    try {
      return new Promise((resolve) => {
        // Read the file as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);

        // Process the data URL
        reader.onload = async () => {
          const result = reader.result;
          const res = await fetch(result);
          const blob = await res.blob();
          let resultBlob = blob;
          try {
            // Convert HEIC to JPEG using heic2any library
            resultBlob = await heic2any({
              blob,
              toType: 'image/jpeg'
            });
          } catch (error) {
            // If conversion fails, resolve with the original file
            return resolve(file);
          }

          // Image compression options
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1680,
            useWebWorker: true,
            initialQuality: 0.5
          };

          // Compress the resulting blob using imageCompression library
          const compressedBlob = await imageCompression(resultBlob, options);

          // Create a new File from the compressed blob
          const newFile = new File([compressedBlob], file.name.split('.')[0], {
            type: compressedBlob.type
          });

          // Set the uid property on the new file
          newFile.uid = file.uid;

          // Resolve with the compressed File
          return resolve(newFile);
        };
      });
    } catch (error) {
      // If any error occurs, resolve with the original file
      return file;
    }
  } else {
    try {
      // If the file is not in HEIC format, perform regular compression
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1680,
        useWebWorker: true,
        initialQuality: 0.5
      };

      // Compress the file using imageCompression library
      const compressedFile = await imageCompression(file, options);
      // Resolve with the compressed File
      return compressedFile;
    } catch (error) {
      return file;
    }
  }
};

/**
 * Generates properties for the Upload.Dragger component used in file uploads.
 *
 * This function creates a set of properties tailored for different file upload scenarios,
 * supporting both single and multiple file uploads. It handles file removal and pre-upload processing,
 * including file compression for specific origins.
 *
 * @function
 *
 * @param {string} origin - The origin of the file upload (e.g., 'thumbnail', 'images').
 * @param {boolean} multiple - Indicates if multiple file uploads are allowed.
 * @param {string} purpose - The purpose of the file upload ('create' or 'edit').
 * @param {Function} t - Translation function for localization.
 * @param {Array} fileList - The current list of files.
 * @param {Function} setFileList - State setter function for updating the file list.
 * @returns {Object} A set of properties for the Upload.Dragger component.
 */
export const draggerProps = (
  origin,
  multiple,
  purpose,
  t,
  fileList,
  setFileList
) => ({
  multiple,
  onRemove: (file) => {
    if (purpose === 'edit' && file?._id) {
      Modal.confirm({
        title: t('communication.modal.delete_file_warning'),
        content: t('communication.modal.delete_file_content'),
        okType: 'warning',
        onOk: () => {
          setFileList((prevFileList) => {
            const newFileList = { ...prevFileList };
            newFileList[origin] = newFileList[origin].filter((f) => f !== file);
            return newFileList;
          });
        }
      });
    } else {
      setFileList((prevFileList) => {
        const newFileList = { ...prevFileList };
        newFileList[origin] = newFileList[origin].filter((f) => f !== file);
        return newFileList;
      });
    }
  },
  beforeUpload: async (file) => {
    let listFile = file;
    if (['thumbnail', 'images'].includes(origin)) {
      listFile = await compressFile(file);
    }

    setFileList((prevFileList) => ({
      ...prevFileList,
      [origin]: [...prevFileList[origin], listFile]
    }));

    return false;
  },
  fileList: fileList[origin]
});

/**
 * Converts the first character of a string to uppercase.
 *
 *
 * This function takes a string as input and returns the string with its first character converted
 * to uppercase. If the string is empty, it returns the original string without modification.
 * @function
 * @param {string} str - The string to be transformed.
 * @returns {string} The transformed string with the first character in uppercase.
 */
export const upperCaseFirstCharacter = (str) => {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};
