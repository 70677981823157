/**
 * `draggerProps` is a function that returns properties for an Ant Design `Upload.Dragger` component.
 * It handles the logic for file removal and upload, including file type validation and preventing
 * duplicates in the file list.
 *
 * @function
 *
 * @param {Array} fileList - The current list of files.
 * @param {Function} setFileList - Function to update the file list state.
 * @param {Function} message - Function to display messages (e.g., for errors or notifications).
 * @param {Function} t - Function for internationalization translation.
 *
 * @returns {Object} An object containing properties for the `Upload.Dragger` component.
 * - multiple (boolean): Indicates if multiple file upload is allowed.
 * - onRemove (Function): Callback function for file removal.
 * - beforeUpload (Function): Callback function before file upload that performs file type check and duplicate validation.
 * - fileList (Array): The current list of files.
 */
export const draggerProps = (fileList, setFileList, message, t) => ({
  multiple: false,
  onRemove: (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  },
  beforeUpload: (file) => {
    const existingFileName = fileList.some(
      (document) => document.name === file.name
    );
    if (existingFileName) return message(t('existing_file_name'));
    const fileExtension = file.name.split('.').pop();
    if (
      ['png', 'PNG', 'jpg', 'JPG', 'pdf', 'PDF', 'jpeg', 'JPEG'].includes(
        fileExtension
      )
    ) {
      setFileList([...fileList, file]);
    } else {
      message('Not a PNG or JPG file.');
      return true;
    }
    return false;
  },
  fileList
});
