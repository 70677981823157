import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Input, Modal, Row, Upload } from 'antd';
import { useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { BalanceImport } from '../../../../models/BalanceImport';
import { useDraggerProps } from './draggerProps';

const { TextArea } = Input;

/**
 * Displays a modal for creating new balance imports. Users can upload a balance sheet file and select the year it applies to.
 *
 * The modal contains an `Upload` component for file selection and a `DatePicker` for year selection. Submission of the form
 * triggers an API call to create a new balance import record with the selected file and year. The modal provides feedback during
 * the submission process and displays error messages for any issues encountered.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.refresh - Indicates if a refresh of the balance imports list is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state in the parent component.
 * @param {boolean} props.visible - Controls the visibility of the modal.
 * @param {Function} props.setVisible - Function to toggle the modal's visibility.
 *
 * @returns {JSX.Element} A modal dialog with a form for creating a new balance import.
 */
export const CreateImportModal = ({
  refresh,
  setRefresh,
  visible,
  setVisible
}) => {
  const { t } = useTranslation();
  const { daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const draggerProps = useDraggerProps(fileList, setFileList, message, t);

  const handleClose = () => {
    form.resetFields();
    setVisible(!visible);
    setFileList([]);
  };

  const createBalanceImport = async (values) => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      fileList.forEach((file) => {
        formData.append('documents', file);
      });
      formData.append(
        'values',
        JSON.stringify({ ...values, year: dayjs(values.year).year(), daycare })
      );

      await BalanceImport.post(formData);
      setRefresh(!refresh);
      handleClose();
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={visible}
      footer={null}
      title={t('analysis.modal.title')}
      onCancel={handleClose}
    >
      <Form form={form} onFinish={createBalanceImport}>
        <Form.Item name={['file']} label={t('analysis.modal.file')} required>
          <Upload {...draggerProps}>
            <Button icon={<UploadOutlined />}>
              {t('children.documents.modal.load_file')}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item name={['month']} label={t('analysis.modal.month')} required>
          <DatePicker picker="month" />
        </Form.Item>
        <Form.Item name={['remarks']} label={t('analysis.modal.remarks')}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={handleClose}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateImportModal.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func
};

CreateImportModal.defaultProps = {
  refresh: undefined,
  setRefresh: null,
  visible: undefined,
  setVisible: null
};
