import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';

/**
 * Styled component for the success contract component.
 */
const StyledError = styled.div`
  width: 100%;
  text-align: center;
  flex: 1;
  padding: 8vh 24px;
`;

/**
 * Styled component for the success contract title.
 */
const ErrorTitle = styled.h1`
  color: #e3181a;
  font-weight: 600;
  font-size: 72px;
`;

/**
 * Renders the pre-registration already completed error component.
 *
 * This component displays an error message indicating that the pre-registration has already been completed.
 * It uses translations for the text content.
 *
 * @component
 * @returns {JSX.Element} The pre-registration already completed error component.
 */
export const PreRegistrationAlreadyCompleted = () => {
  const { t } = useTranslation();
  const translationKey = 'contract.error';

  return (
    <StyledError>
      <ErrorTitle>{t(`${translationKey}.title`)}</ErrorTitle>
      <div>
        <p>{t(`${translationKey}.message`)}</p>
      </div>
      <div>
        <Button style={{ margin: 16 }} onClick={() => window.close()}>
          {t('buttons.close_page')}
        </Button>
      </div>
    </StyledError>
  );
};
