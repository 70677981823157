import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { visibilitiesColors } from '../../../../../utils/constants/tagColors';

const iconSize = 18;

/**
 * useColumns is a custom hook that returns the column definitions for the antd Table used
 * to display the children's documents. It provides functionalities like edit, view,
 * download, and delete for each document.
 *
 * @function
 * @hook
 * @param {Function} deleteDocument - Function to delete a specified document.
 * @param {Function} handleModal - Function to handle opening/closing of the modal.
 * @param {string} dividerTitle - Title for the divider.
 * @returns {Array} Array of column definitions to be used in the antd Table.
 */
export const useColumns = (deleteDocument, handleModal, dividerTitle) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  const handleEdit = (record) => {
    handleModal(dividerTitle, record);
  };

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Button onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
          <Button onClick={() => viewDocument(record.file)}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Button onClick={() => downloadDocument(record.file)}>
            <DownloadOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];

  return [
    {
      title: t('children.documents.name'),
      key: 'document_title',
      dataIndex: 'document_title',
      sorter: true
    },
    {
      title: t('children.documents.file'),
      key: 'file',
      dataIndex: 'file',
      sorter: true,
      render: (file) => file?.metadata?.originalName
    },
    {
      title: t('children.documents.visibility'),
      key: 'visibility',
      dataIndex: 'visibility',
      sorter: true,
      render: (visibilities) =>
        (visibilities || []).map((visibility) => (
          <Tag color={visibilitiesColors[visibility]}>
            {t(`children.documents.${visibility}`)}
          </Tag>
        ))
    },
    ...actionColumn
  ];
};
