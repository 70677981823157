import { Button, Collapse, Form, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useCollapses } from './useCollapses';
import { ContentCustom } from '../../../components';
import {
  formItemLayout,
  noLabelLayout
} from '../../../utils/constants/formLayout';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

const { Panel } = Collapse;

/**
 * Component for managing accounting configurations.
 *
 * @component
 *
 * @returns {JSX.Element} Accounting component.
 */
export const Accounting = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const collapses = useCollapses();
  const generateFields = useGenerateFormItem();

  const [refresh, setRefresh] = useState(false);

  const updateAccountingConfiguration = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `accounting-configurations`,
        body: {
          ...body,
          daycare
        }
      });
      setRefresh((prev) => !prev);
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchAccountingConfiguration = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `accounting-configurations?daycare=${daycare}`
      });
      if (data.length > 0) {
        form.setFieldsValue(data[0]);
      }
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAccountingConfiguration();
    })();
  }, [refresh]);

  return (
    <ContentCustom>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          onFinish={updateAccountingConfiguration}
          form={form}
        >
          {collapses?.map(({ title, fields }) => (
            <Collapse key={title}>
              <Panel header={title}>
                {fields.map((field) =>
                  generateFields('configurations.accounting', field)
                )}
              </Panel>
            </Collapse>
          ))}
          <Form.Item {...noLabelLayout}>
            <Row justify="center">
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t('buttons.save')}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </ContentCustom>
  );
};
