export const headers = [
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'amount',
    key: 'amount'
  },
  {
    label: 'amount_allocated',
    key: 'amount_allocated'
  },
  {
    label: 'operation_date',
    key: 'operation_date'
  },
  {
    label: 'payment_method',
    key: 'payment_method'
  },
  {
    label: 'label',
    key: 'label'
  },
  {
    label: 'customer_account',
    key: 'customer_account.label'
  }
];
