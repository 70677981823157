import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { MessagesAndNotesRouter } from '../routes/messages-and-notes/MessagesAndNotesRouter';
import { ChildrenPlanning } from '../routes/planning/children/ChildrenPlanning';
import { ReferenceWeekProvider } from '../contexts/ReferenceWeekContext';
import { CreateUpdatePreRegistration } from '../routes/pre-registrations/CreateUpdatePreRegistration';
import { CreateUpdateContract } from '../routes/Contracts/CreateUpdateContract';
import { TimeRecorder } from '../routes/planning/TimeRecorder/TimeRecorder';
import { SignaturesRouter } from '../routes/signatures/SignaturesRouter';
import { CheckoutPaymentRouter } from '../routes/checkout-payment/CheckoutPaymentRouter';
import { RequireAuth } from './RequireAuth';
import PostPwdForm from '../routes/login/PostPwdForm';

export const UserRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route
          path={`${outOfNavRoutes.PRE_REGISTRATION}/create/*`}
          element={
            <ReferenceWeekProvider>
              <CreateUpdatePreRegistration purpose="create" />
            </ReferenceWeekProvider>
          }
        />
        <Route
          path={`${outOfNavRoutes.PRE_REGISTRATION}/accept/:preRegistrationID/:daycareId`}
          element={
            <ReferenceWeekProvider>
              <CreateUpdateContract origin="PARENTS" purpose="create" />
            </ReferenceWeekProvider>
          }
        />
        <Route
          path={`${outOfNavRoutes.SIGNATURES}/*`}
          element={<SignaturesRouter />}
        />
        <Route
          path={`${outOfNavRoutes.CHECKOUT_PAYMENT}/*`}
          element={<CheckoutPaymentRouter />}
        />
        <Route
          path={`${outOfNavRoutes.TIME_RECORDER}/*`}
          element={<TimeRecorder />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${routes.PLANNING}/*`} element={<ChildrenPlanning />} />

          <Route path={routes.HOME} element={<Home />} />
          <Route
            path={`${routes.MESSAGES_AND_NOTES}/*`}
            element={<MessagesAndNotesRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
