import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CreateUpdateContainer } from '../../../components';
import useFields from './fields';

const CreateUpdateTransfer = ({ purpose }) => {
  const { t } = useTranslation();
  const fields = useFields(t);

  const config = {
    onGetResource: {
      setFields: (v) => ({
        ...v,
        levy_date: v.levy_date && moment(v.levy_date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      resource="transfers"
      fields={fields.filter((field) => field.purpose.includes(purpose))}
      purpose={purpose}
      baseUrl="transfer"
      config={config}
    />
  );
};

CreateUpdateTransfer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTransfer;
