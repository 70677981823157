import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { InvoiceCard } from '../invoices/ShowCards/InvoiceCard';

/**
 * DetailPaymentModal is a component that displays a modal for payment details.
 * It includes a form with fields retrieved from `useTerminatedFields` and handles the submission
 * of the termination date, formatting it to the end of the day.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isDetailModalOpen - Indicates if the modal is open.
 * @param {function} props.setIsDetailModalOpen - Function to close the modal.
 * @returns {JSX.Element} The rendered component.
 */
const DetailPaymentModal = ({
  isDetailModalOpen,
  closeDetailsModal,
  paymentDetails
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      open={isDetailModalOpen}
      onCancel={closeDetailsModal}
      title={`${t(`payments.details_modal.title`)}`}
      width={1200}
      footer={false}
    >
      <InvoiceCard
        data={null}
        total={paymentDetails?.amount}
        child={null}
        type={paymentDetails?.reason}
        invoice={paymentDetails}
        resource="payment"
      />
    </Modal>
  );
};

DetailPaymentModal.propTypes = {
  isDetailModalOpen: PropTypes.bool.isRequired,
  closeDetailsModal: PropTypes.func.isRequired,
  paymentDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default DetailPaymentModal;
