import dayjs from 'dayjs';
import { useState } from 'react';
import { Radio, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to generate contract form fields.
 *
 * @hook
 * @param {Function} setIsFlexible - Callback function to set flexible planning status.
 * @returns {Object} Contains contract form fields.
 */
export const useContractFields = (setIsFlexible) => {
  const { t } = useTranslation();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const formatDate = 'DD/MM/YYYY';

  const disabledDate = (current) =>
    current && current <= dayjs().startOf('day');
  const disabledEndDate = (current) =>
    (current && current <= selectedStartDate) || disabledDate(current);

  const contractFields = [
    {
      name: ['contract', 'start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={formatDate}
          onChange={(date) => setSelectedStartDate(date)}
          disabledDate={disabledDate}
        />
      )
    },
    {
      name: ['contract', 'end_date'],
      rules: [{ required: true }],
      input: <DatePicker format={formatDate} disabledDate={disabledEndDate} />
    },
    {
      name: ['contract', 'is_flexible_planning'],
      rules: [{ required: false }],
      input: (
        <Radio.Group onChange={(value) => setIsFlexible(value)}>
          <Radio value>{t('pre-registrations.form.contract.yes')}</Radio>
          <Radio value={false}>{t('pre-registrations.form.contract.no')}</Radio>
        </Radio.Group>
      )
    }
  ];

  return {
    contractFields
  };
};
