import { useTranslation } from 'react-i18next';
import { Row, Divider, Card, Col, Button, Popconfirm, Flex } from 'antd';
import {
  WarningOutlined,
  DeleteOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * Component for displaying a list of parent documents.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {string} props.childID - The ID of the child associated with the documents.
 * @param {Array} props.documents - Array of documents to display.
 * @param {boolean} props.forceRefresh - Boolean to force a refresh of the documents.
 * @param {Function} props.setForceRefresh - Function to set the forceRefresh state.
 * @returns {JSX.Element} ParentDocumentsList component.
 */
export const ParentDocumentsList = ({
  childID,
  documents,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { downloadDocument } = useDownloadDocument();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `children/${childID}/delete-document/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  return documents.length === 0 ? (
    <Flex justify="center" align="center">
      <p style={{ fontStyle: 'italic', color: 'var(--disabledColor)' }}>
        {t('parents.documents.no_documents')}
      </p>
    </Flex>
  ) : (
    <Flex vertical gap="small" style={{ marginBottom: 16 }}>
      {Object.keys(documents).map((key) => (
        <Flex vertical>
          <Divider orientation="center">{t(`documents.form.${key}`)}</Divider>
          {documents[key].length === 0 ? (
            <Flex justify="center" align="center">
              <p style={{ fontStyle: 'italic', color: 'var(--disabledColor)' }}>
                {t('parents.documents.no_document')}
              </p>
            </Flex>
          ) : (
            <Flex vertical gap="small">
              {(documents[key] || []).map((document) => (
                <Card className="parent-document-card">
                  <Row>
                    <strong>{document?.file?.metadata?.originalName}</strong>
                  </Row>
                  <Row align="middle">
                    <Col span={16}>
                      {dayjs(document.date).format('DD/MM/YYYY')}
                    </Col>
                    <Col span={8}>
                      <Flex justify="flex-end" gap="large">
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={() => downloadDocument(document.file)}
                          type="link"
                        />
                        {key !== 'sent_by_daycare' && (
                          <Popconfirm
                            title={t('datatable.column.action.delete.title')}
                            okText={t('datatable.column.action.delete.ok')}
                            okButtonProps={{ type: 'danger' }}
                            cancelText={t(
                              'datatable.column.action.delete.cancel'
                            )}
                            onConfirm={() => deleteResource(document._id)}
                            icon={<WarningOutlined />}
                          >
                            <Button type="link" icon={<DeleteOutlined />} />
                          </Popconfirm>
                        )}
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

ParentDocumentsList.propTypes = {
  documents: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))),
  childID: PropTypes.string,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

ParentDocumentsList.defaultProps = {
  documents: null,
  childID: undefined,
  forceRefresh: undefined,
  setForceRefresh: null
};
