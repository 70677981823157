/**
 * Handles the form dependencies and updates form values based on the resource type.
 *
 * @async
 * @function
 * @param {function} dispatchAPI - The API dispatch function.
 * @param {function} message - The function to display messages.
 * @param {any} value - The value that triggered the form dependencies.
 * @param {Object} formValues - The current form values.
 * @param {string} resource - The resource type being processed.
 * @param {Object} form - The form instance.
 * @param {function} setFormValues - The function to update form values.
 *
 * @returns {Promise<void>} A promise that resolves when the form dependencies have been processed.
 */
export const formDependencies = async (
  dispatchAPI,
  message,
  value,
  formValues,
  resource,
  form,
  setFormValues
) => {
  try {
    const { from_scratch_details } = formValues;
    const keyName = Object.keys(value)[0];
    let child = [];

    const getChild = async (childId) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/child?_id=${childId}&populate=contracts`
        });
        return data;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      return true;
    };

    switch (resource) {
      case 'invoices':
        if (keyName === 'child') {
          child = await getChild(formValues?.child);
        }

        if (!keyName === 'child' || child.length) {
          let activeContract = false;

          if (keyName === 'child') {
            activeContract = (child[0].contracts || []).find(
              (contract) => contract.status === 'ACTIVE'
            );
          } else {
            activeContract = formValues?.active_contract;
          }

          if (!activeContract) {
            setFormValues({
              ...formValues,
              active_contract: null,
              contract: null,
              customer_account: null,
              rib: null,
              payment_method: null
            });
            form.setFieldsValue({
              ...formValues,
              active_contract: null,
              contract: null,
              customer_account: null,
              rib: null,
              payment_method: null
            });
            message('NO_ACTIVE_CONTRACT');
          } else {
            setFormValues({
              ...formValues,
              active_contract: activeContract,
              contract: activeContract?._id,
              customer_account: activeContract?.customer_account,
              rib: activeContract?.rib
            });
            form.setFieldsValue({
              ...formValues,
              active_contract: activeContract,
              contract: activeContract?._id,
              customer_account: activeContract?.customer_account,
              rib: activeContract?.rib
            });
          }
        }
        if (
          from_scratch_details?.length > 0 &&
          from_scratch_details?.[0]?.amount
        ) {
          let sumTotal = 0;
          from_scratch_details.map((detail) => {
            sumTotal += Number(detail?.amount) || 0;
            return sumTotal;
          });
          setFormValues({ ...formValues, total: Number(sumTotal).toFixed(2) });
          form.setFieldsValue({
            ...formValues,
            total: Number(sumTotal).toFixed(2)
          });
        }
        break;
      default:
        break;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
