import { Modal, message as antDmessage } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateContainerModal } from '../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useFields } from './fields';

/**
 * `EditParentModal` is a React component that renders a modal dialog for editing parent information.
 * It utilizes several hooks and contexts to manage state and handle API interactions.
 * The modal allows editing details of a parent record, handling the submission, and updating the state on successful submission.
 *
 * @param {object} props - The props for the component.
 * @param {boolean} props.refresh - A flag used to trigger a refresh in the parent component.
 * @param {Function} props.setRefresh - Function to toggle the refresh state in the parent component.
 * @param {object|null} props.record - The parent record object to be edited. Contains the parent's details.
 * @param {string} props.record._id - The unique identifier of the parent record.
 * @param {object|null} props.record.user - The user object associated with the parent record.
 * @param {string} props.record.user.first_name - The first name of the user associated with the parent record.
 * @param {string} props.record.user.last_name - The last name of the user associated with the parent record.
 * @param {string} props.record.user.email - The email of the user associated with the parent record.
 *
 * @returns {ReactElement} - A modal component for editing parent information.
 */
export const EditParentModal = ({ refresh, setRefresh, record }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fields, isFieldsLoading } = useFields();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = useErrorMessage();

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record,
        first_name: record?.user?.first_name,
        last_name: record?.user?.last_name
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);
      const url = `parents/${id}`;
      await dispatchAPI('PATCH', {
        url,
        body: {
          ...body,
          user: record?.user
        }
      });
      closeModal();
      setRefresh(!refresh);
      antDmessage.success(t('children.show.parents.success'));
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t('parents.form.title.edit')}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        baseUrl="parents"
        resource="parents"
        config={config}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        withoutPageHeaderCustom
        closeModal={closeModal}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

EditParentModal.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    })
  })
};

EditParentModal.defaultProps = {
  refresh: false,
  setRefresh: null,
  record: null
};
