import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Tag } from 'antd';
import { customerNature } from '../../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * ExtraButtons component
 * @component ExtraButtons
 * @param {function} onsearchType - function to filter daycares
 * @param {function} setSelectedTypes - function to set selected roles
 * @param {array} selectedTypes - array of selected roles
 * @param {object} enums - object with enums
 * @param {boolean} isLoading - boolean to indicate if data is loading
 * @returns {object} JSX - ExtraButtons component elements
 */
export const ExtraButtons = ({
  onsearchType,
  setSelectedTypes,
  selectedTypes,
  enums,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <Select
      style={{ width: '300px', marginLeft: 20 }}
      showSearch
      mode="multiple"
      allowClear
      defaultValue={selectedTypes}
      placeholder={t('customer-account.form.extra_buttons.customer_nature')}
      loading={isLoading}
      filterOption={(input, option) => onsearchType(input, option)}
      onSelect={(value) => {
        setSelectedTypes([...selectedTypes, value]);
      }}
      onDeselect={(value) =>
        setSelectedTypes((prevRoles) =>
          prevRoles.filter((item) => item !== value)
        )
      }
      onClear={() => {
        setSelectedTypes([]);
      }}
    >
      {enums?.customer_natures?.map((n) => (
        <Option key={n} value={n} data-label={t(`users.tags.${n}`)}>
          <Tag color={customerNature[n]}>{t(`customer-account.form.${n}`)}</Tag>
        </Option>
      ))}
    </Select>
  );
};

ExtraButtons.propTypes = {
  onsearchType: PropTypes.func.isRequired,
  setSelectedTypes: PropTypes.func.isRequired,
  selectedTypes: PropTypes.arrayOf().isRequired,
  enums: PropTypes.shape({
    customer_natures: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
