import { ParentInvoicesList } from './ParentInvoicesList';

/**
 * Hook for generating parent invoice tabs based on children invoices data.
 *
 * @hook
 *
 * @param {Array} childrenInvoices - Array of children invoices data.
 * @param {boolean} refresh - Boolean to trigger a refresh of the invoices.
 * @param {Function} setRefresh - Function to set the refresh state.
 * @returns {Array} Array of tab objects containing label, key, and children components.
 */
export const useParentInvoicesTabs = (childrenInvoices, refresh, setRefresh) =>
  childrenInvoices.map((child) => ({
    label: `${child.first_name || ''}`,
    key: child._id,
    children: (
      <ParentInvoicesList
        invoices={child.invoices}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    )
  }));
