// Main nav menuItems
export const routes = {
  HOME: '/',
  PLANNING: '/planning',
  CHILDREN: '/children',
  PARENTS: '/parents',
  PRE_REGISTRATION: '/pre-registrations',
  INVOICING: '/invoicing',
  MESSAGES_AND_NOTES: '/message-and-notes',
  MEALS: '/meals',
  COMMUNICATION: '/communication',
  EMPLOYEES: '/employees',
  CONFIGURATIONS: '/configurations'
};

// Main nav subMenuItems
export const subRoutes = {
  PLANNING: {
    CHILDREN_PLANNING: '/children-planning',
    EMPLOYEES_PLANNING: '/employees-planning'
  },
  INVOICING: {
    INVOICES: '/invoices',
    PAYMENTS: '/payments',
    'CUSTOMERS-ACCOUNTS': '/customers-accounts'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TIME_RECORDER: '/time-recorder'
};

// url search parameters
export const pathSearches = {};
