import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tooltip, Tag } from 'antd';
import IsWalking from '../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../assets/images/icons/healthicons_baby-0306m.svg';
import { usePlanningContext } from '../../../contexts/PlanningContext';

/**
 * TimeSlots
 * @description Displays the total children by groups by time slots.
 * @param {Object} group - The group-children with all informations.
 * @param {Object} slotEvents - The time slots with number of children.
 * @returns {JSX.Element} - The TimeSlots component
 */
export const TimeSlots = ({ group, slotEvents }) => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { t } = useTranslation();
  const { daycareSelected } = usePlanningContext();

  return (
    <Row align="middle">
      {group === 'total' ? (
        <Col span={4} />
      ) : (
        <Col span={4} style={{ textAlign: 'right', paddingRight: 8 }}>
          {group ? (
            <img src={IsWalking} alt="is_walking" />
          ) : (
            <img src={DontWalk} alt="dont-walk" />
          )}
        </Col>
      )}
      <Col span={20}>
        <Row style={{ flexWrap: 'nowrap' }}>
          {Object.keys(slotEvents).map((hour, index) => (
            <Tooltip
              key={hour}
              title={
                <Row justify="center">
                  <Col
                    span={24}
                    style={{
                      color: 'black',
                      marginBottom: 7,
                      textAlign: 'center'
                    }}
                  >
                    {t(`plannings.children.slots.${index}`)}
                  </Col>
                  <Col>
                    <Tag
                      color={
                        slotEvents[hour].supervisoryRate < 100
                          ? 'var(--errorColor)'
                          : 'var(--addColor)'
                      }
                    >
                      {slotEvents[hour].supervisoryRate
                        ? `${slotEvents[hour].supervisoryRate}%`
                        : '0%'}
                    </Tag>
                  </Col>
                  <Col>
                    <Tag
                      color={
                        slotEvents[hour] >= daycareSelected?.capacity
                          ? 'var(--errorColor)'
                          : 'var(--addColor)'
                      }
                    >
                      {slotEvents[hour].numberOfChildren}
                    </Tag>
                  </Col>
                </Row>
              }
              placement="top"
              color="white"
            >
              <Col
                key={hour}
                className={`time-slot ${
                  slotEvents[hour].supervisoryRate < 100 &&
                  slotEvents[hour].numberOfChildren > 0
                    ? 'over'
                    : 'below'
                }`}
              />
            </Tooltip>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

TimeSlots.propTypes = {
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  slotEvents: PropTypes.shape({}).isRequired,
  daycareSelected: PropTypes.shape({
    capacity: PropTypes.number
  })
};

TimeSlots.defaultProps = {
  daycareSelected: null
};
