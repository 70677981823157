import { useState, useEffect } from 'react';
import { Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { TextArea } = Input;
const { Option } = Select;

const useFields = (
  purpose,
  daycare,
  invoiceId,
  customerAccountId,
  isCreditModal
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [invoicesNotPaid, setInvoicesNotPaid] = useState([]);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState('');
  const [bankAccounts, setBankAccounts] = useState([]);
  const [enums, setEnums] = useState({});

  const getInvoicesNotPaid = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?status!=PAID${
          selectedAccounts ? `&customer_account=${selectedAccounts}` : ''
        }`
      });
      setInvoicesNotPaid(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomerAccounts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customer-accounts?daycares=${daycare}`
      });
      setCustomerAccounts(data);
    } catch (e) {
      message(e);
    }
  };

  const getDaycare = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}?populate=invoicing_configuration`
      });
      setBankAccounts(data?.invoicing_configuration?.bank_accounts);
    } catch (e) {
      message(e);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const onsearchAccounts = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchInvoices = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchPayments = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchBankAccounts = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    (async () => {
      await getCustomerAccounts();
      await getEnums();
      await getDaycare();
      setIsFieldsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getInvoicesNotPaid();
    })();
  }, [selectedAccounts]);

  const fields = [
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['amount'],
      rules: [{ required: true }],
      input: <Input type="number" addonAfter="€" />
    },
    {
      name: ['customer_account'],
      rules: [{ required: true }],
      initialValue: customerAccountId,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={!!customerAccountId}
          allowClear
          showSearch
          filterOption={(input, option) => onsearchAccounts(input, option)}
          onSelect={(value) => {
            setSelectedAccounts(value);
          }}
          onClear={() => {
            setSelectedAccounts('');
          }}
        >
          {(customerAccounts || []).map((account) => (
            <Option key={account._id} value={account._id}>
              {account.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['invoice'],
      initialValue: invoiceId,
      hidden: isCreditModal,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={!!invoiceId}
          allowClear
          showSearch
          filterOption={(input, option) => onsearchInvoices(input, option)}
        >
          {(invoicesNotPaid || []).map((invoice) => (
            <Option key={invoice.number} value={invoice._id}>
              {t(`${invoice.number} (${invoice.total} €)`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(isCreditModal
      ? []
      : [
          {
            name: ['payment_method'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  onsearchPayments(input, option)
                }
              >
                {(enums?.payment_methods || []).map((payment) => (
                  <Option key={payment} value={payment}>
                    {t(`payments.tags.${payment}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['bank_account'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  onsearchBankAccounts(input, option)
                }
              >
                {(bankAccounts || []).map((bank) => (
                  <Option key={bank._id} value={bank.bank_name}>
                    {bank.bank_name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]),
    {
      name: ['operation_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['comment'],
      input: <TextArea rows={4} />
    },
    {
      name: ['daycare'],
      initialValue: daycare,
      hidden: true,
      rules: [{ required: true }]
    },
    ...(isCreditModal
      ? [
          {
            name: ['type'],
            initialValue: isCreditModal ? 'CREDIT' : '',
            hidden: true
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
