import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ModalTail } from '../../components/ModalTail/ModalTail';

/**
 * PickupTimeModal component is responsible for rendering a modal for adding or updating pickup time for a child.
 *
 * @component
 *
 * @param {Object} props - The props object.
 * @param {string} props.source - The source of the modal (e.g., 'check-in', 'check-out').
 * @param {Object} props.child - The child object for whom the pickup time is being added or updated.
 * @param {string} props.child._id - The ID of the child.
 * @param {string[]} props.child.pickup_code - The pickup codes associated with the child.
 * @param {string} props.child.first_name - The first name of the child.
 * @param {string} props.child.last_name - The last name of the child.
 * @param {Function} props.setRefresh - The function to trigger a refresh after pickup time is added or updated.
 * @returns {JSX.Element} The JSX representation of the PickupTimeModal component.
 */
export const PickupTimeModal = ({ source, child, setRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const handleModal = () => {
    setIsModalOpenedLocaly(!isModalOpenedLocaly);
  };

  const postOrUpdatePickupTime = async (values) => {
    setIsSubmitting(true);
    if (child.pickup_code.length) {
      try {
        await dispatchAPI('POST', {
          url: `/pickup-times/tablet`,
          body: {
            ...values,
            child: { id: child._id, checked: true }
          }
        });
        setRefresh((prev) => !prev);
        handleModal();
      } catch (error) {
        errorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      title={t(`attendance-check.modal.${source}`, {
        child: `${child?.first_name || ''} ${child?.last_name || ''}`
      })}
      open={isModalOpenedLocaly}
      onCancel={handleModal}
      footer={null}
      width={800}
    >
      <Form form={form} onFinish={postOrUpdatePickupTime}>
        <Form.Item label={t('time_recorder.form.label')} name={['code']}>
          <Input />
        </Form.Item>
        <ModalTail isSubmitting={isSubmitting} handleModal={handleModal} />
      </Form>
    </Modal>
  );
};

PickupTimeModal.propTypes = {
  source: PropTypes.string.isRequired,
  child: PropTypes.shape({
    _id: PropTypes.string,
    pickup_code: PropTypes.arrayOf(PropTypes.string),
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  setRefresh: PropTypes.func
};

PickupTimeModal.defaultProps = {
  child: null,
  setRefresh: null
};
