import { useState, useCallback, useEffect } from 'react';
import { Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * Hook for getting the child fields.
 * This hook is used in the child form.
 *
 * @hook
 * @returns {Object} childFields and isFieldsLoading
 */
export const useChildFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pre-registrations/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const childFields = [
    {
      name: ['child', 'first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['child', 'last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['child', 'gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.child_genders || []).map((gender) => (
            <Option key={gender} value={gender}>
              {t(`pre-registrations.form.genders.${gender}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['child', 'birth_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['child', 'place_of_birth'],
      rules: [{ required: false }]
    }
  ];

  return {
    childFields,
    isFieldsLoading
  };
};
