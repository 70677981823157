import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Divider, Form, Input, Flex } from 'antd';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useCAFScaleContext } from './CAFScaleContext';
import EditableTable from './EditableTable';
import { useTables } from './tables';

/**
 * A component for displaying and editing CAF scale data for different bonus categories and services for childcare.
 *
 * @component
 *
 * This component renders a series of editable tables, each corresponding to a different category of the CAF scale, such as service for childcare, disability bonus, and social diversity bonus. It allows users to navigate through different years using left and right buttons or a date picker to view and edit the scale data for the selected year. The data is fetched and updated via the CAFScaleContext.
 *
 * @returns {JSX.Element} A component that displays editable tables for various CAF scale categories and provides navigation controls to switch between years.
 */

export const CAFScale = () => {
  const { t } = useTranslation();
  const { scale, setYearFilter, yearFilter, patchScale } = useCAFScaleContext();
  const [form] = Form.useForm();

  const handleYearChange = (orientation) => {
    if (orientation === 'left') {
      setYearFilter((prevYear) => dayjs(prevYear).subtract(1, 'year'));
    } else setYearFilter((prevYear) => dayjs(prevYear).add(1, 'year'));
  };

  const tables = useTables();

  return (
    <>
      <Flex justify="space-between">
        <Button shape="circle" onClick={() => handleYearChange('left')}>
          <LeftOutlined />
        </Button>
        <DatePicker
          picker="year"
          value={yearFilter}
          onChange={(value) => setYearFilter(value)}
        />
        <Button shape="circle" onClick={() => handleYearChange('right')}>
          <RightOutlined />
        </Button>
      </Flex>
      {tables.map(
        ({ dividerTitle, resourceColumns, resourceName, items }, index) => (
          <>
            <Divider orientation="left">{dividerTitle}</Divider>
            <EditableTable
              resourceColumns={resourceColumns}
              resourceName={resourceName}
              items={items}
            />
            {index === 1 && (
              <Form
                form={form}
                onValuesChange={async (value) => {
                  await patchScale(scale._id, value);
                }}
              >
                <Form.Item
                  label={t(
                    'global_settings.CAF_scale.labels.place_bonus_amount'
                  )}
                  name={['disability_bonus_place_bonus_amount']}
                  initialValue={scale?.disability_bonus_place_bonus_amount}
                  style={{ maxWidth: 400, marginTop: 8 }}
                >
                  <Input suffix="€" />
                </Form.Item>
              </Form>
            )}
          </>
        )
      )}
    </>
  );
};
