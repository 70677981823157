import { useTranslation } from 'react-i18next';

/**
 * @hook useListContent
 * @description
 * useListContent is a hook that returns the content of the diet card.
 * It is used in the ContractInformationsCard component.
 *
 * @param {Object} data - The data of the diet card
 * @returns {Array} The content of the diet card card
 */
export const useListContent = (data = {}) => {
  const translationKey = 'pre-registrations.show.diet_card';
  const { t } = useTranslation();
  const { diet = {} } = data;
  const {
    allergies = [{ label: '' }],
    meal_type = '',
    special_meat_diet = false,
    vegetarian = false
  } = diet;

  return [
    {
      label: `${translationKey}.meal_type`,
      content: t(`${translationKey}.meal_types.${meal_type}`),
      span: 1
    },
    {
      label: `${translationKey}.special_meat_diet`,
      content: t(special_meat_diet ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.vegetarian`,
      content: t(vegetarian ? 'yes' : 'no'),
      span: 1
    },
    {
      label: `${translationKey}.allergies`,
      content: allergies.map((allergy) => t(allergy.label)).join(', '),
      span: 3
    }
  ];
};
