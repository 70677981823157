import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * @hook useFields
 * @description Settings fields
 * @param {boolean} double_factor_authentication - the status of double factor authentication
 * @returns {Object} The fields to be displayed in the form.
 */
const useFields = (double_factor_authentication) => {
  const { t } = useTranslation();

  const fields = [
    {
      label: 'double_factor_authentication',
      name: ['double_factor_authentication'],
      input: (
        <Switch
          defaultChecked={double_factor_authentication}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    }
  ];

  return {
    fields
  };
};

export default useFields;
