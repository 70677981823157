/**
 * Checks if the given hour falls within an unselected time slot.
 *
 * @param {number} hour - The current hour.
 * @param {number} index - The index indicating the first or second time range.
 * @param {object} selectedStartTime - The selected start time for the first range.
 * @param {object} selectedStartTime2 - The selected start time for the second range.
 * @param {object} selectedEndTime - The selected end time for the first range.
 * @param {object} selectedEndTime2 - The selected end time for the second range.
 * @returns {boolean} True if the hour is within an unselected time slot, false otherwise.
 */
export const isTimeSlotUnselected = (
  hour,
  index,
  selectedStartTime,
  selectedStartTime2,
  selectedEndTime,
  selectedEndTime2
) => {
  const startTimeVar = index === 0 ? selectedStartTime : selectedStartTime2;
  const endTimeVar = index === 0 ? selectedEndTime : selectedEndTime2;
  if (!startTimeVar || !endTimeVar) return false;

  const selectedStartHour = startTimeVar?.hour();
  const selectedEndHour = endTimeVar?.hour();
  const currentHour = parseInt(hour, 10);

  return currentHour < selectedStartHour || currentHour > selectedEndHour;
};
