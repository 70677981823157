import dayjs from 'dayjs';

/**
 * Formats vaccine data by converting date strings to Day.js objects.
 *
 * @function
 *
 * @param {Object} data - The vaccine data to be formatted.
 * @returns {Array} An array of formatted vaccine objects.
 */
export const formattedVaccines = (data) =>
  data?.health?.vaccines.map((vaccine) => {
    const formattedVaccine = {
      ...vaccine,
      first_dose: vaccine.first_dose ? dayjs(vaccine.first_dose) : null,
      second_dose: vaccine.second_dose ? dayjs(vaccine.second_dose) : null,
      third_dose: vaccine.third_dose ? dayjs(vaccine.third_dose) : null,
      fourth_dose: vaccine.fourth_dose ? dayjs(vaccine.fourth_dose) : null
    };

    return Object.keys(formattedVaccine).reduce((acc, key) => {
      if (
        formattedVaccine[key] !== null &&
        (!vaccine.vaccine || formattedVaccine[key] !== vaccine.vaccine[key])
      ) {
        acc[key] = formattedVaccine[key];
      }
      return acc;
    }, {});
  });
