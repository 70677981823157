import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Divider, Form, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { tailFormItemLayout } from '../../utils/constants/formLayout';

/**
 * A form component for managing a user's preferences for receiving notifications.
 *
 * This component allows users to customize their notification preferences, including which types of notifications they wish to receive via email and app notifications. It fetches the list of available notification types based on the user's role and displays them as options. Upon submission, the selected preferences are updated in the backend for the current user. It uses the `useAuthContext` for API calls and `useErrorMessage` to display any errors encountered during the fetch or update operations. The form is dynamically populated with the user's current notification preferences when available.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.user - The current user object.
 * @param {boolean} props.refresh - State indicating if a refresh is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @returns {JSX.Element} The NotificationsManagement component with a form for notification preferences.
 */
export const NotificationsManagement = ({ user, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notification-management/enums`
      });
      setEnums(
        data.type
          .filter((item) => item.roles.includes(user?.role))
          .map((item) => ({
            label: t(`profile.notifications.names.${item.name}`),
            value: item.name
          }))
      );
    } catch (e) {
      message(e);
    }
  };

  const patchNotificationManagement = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `notification-management/${user?.notification_management?._id}`,
        body
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
    if (user) {
      form.setFieldsValue(user?.notification_management);
    }
  }, [user]);

  return (
    <>
      <Divider orientation="left">{t('profile.notifications.title')}</Divider>
      <Form
        form={form}
        onFinish={patchNotificationManagement}
        layout="vertical"
        className="notification-form"
      >
        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name={['emails']}
              label={t('profile.notifications.emails')}
            >
              <Checkbox.Group options={enums} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={['notifications']}
              label={t('profile.notifications.notifications')}
            >
              <Checkbox.Group options={enums} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout} style={{ marginTop: 16 }}>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

NotificationsManagement.propTypes = {
  user: PropTypes.shape({
    notification_management: PropTypes.shape({
      _id: PropTypes.string
    }),
    role: PropTypes.string
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

NotificationsManagement.defaultProps = {
  user: null,
  refresh: false,
  setRefresh: null
};
