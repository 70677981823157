/**
 * Generate radio options for time recording.
 *
 * @hook
 *
 * @param {Function} t The translation function.
 * @returns {Object[]} An array of radio options, each containing a label and a corresponding value.
 */
export const generateRadioOptions = (t) => [
  {
    label: t('time-recorder.form.per_day'),
    value: 'per_day'
  },
  {
    label: t('time-recorder.form.per_mid_day'),
    value: 'per_mid_day'
  },
  {
    label: t('time-recorder.form.per_hour'),
    value: 'per_hour'
  },
  {
    label: t('time-recorder.form.non_deductible'),
    value: 'non_deductible'
  }
];

/**
 * Generate reservation adaptation options.
 *
 * @hook
 *
 * @param {Function} t The translation function.
 * @returns {Object[]} An array of adaptation options, each containing a label, value, and key.
 */
export const generateReservationAdaptationOptions = (t) => [
  {
    label: t('time-recorder.form.per_hour'),
    value: 'per_hour',
    key: 'per_hour'
  },
  {
    label: t('time-recorder.form.free'),
    value: 'free',
    key: 'free'
  }
];

/**
 * Check if a given day object represents a valid time slot.
 *
 * @function
 *
 * @param {Object} day The day object to validate.
 * @returns {boolean} `true` if the day object is valid and contains start and end times, otherwise `false`.
 */
const isValidSlot = (day) =>
  day && Object.keys(day).length > 0 && day.start_time && day.end_time;

/**
 * Generate filtered previsional planning.
 *
 * @function
 *
 * @param {Object[]} previsionalPlanning An array of previsional planning data.
 * @returns {Object[]} A filtered and sorted array of previsional planning data, containing only valid time slots and sorted by start time.
 */
export const generateFilteredPrevisionalPlanning = (previsionalPlanning) => {
  let filteredPrevisionalPlanning = [];
  if (previsionalPlanning) {
    filteredPrevisionalPlanning = previsionalPlanning
      .filter((planning) => planning.type !== 'events')
      .map((day) => {
        const boolean = isValidSlot(day);

        if (boolean) {
          return {
            ...day,
            planningType: 'reference_day'
          };
        }
        return null;
      })
      .filter(Boolean)
      .sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
  }
  return filteredPrevisionalPlanning;
};
