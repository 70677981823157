import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { GlobalSettings } from './GlobalSettings';
import { CAFScaleContextProvider } from './CAFScale/CAFScaleContext';

/**
 * @description GlobalSettingsRouter component. Handles the routing for the global settings page
 * @component
 * @returns {JSX} The global settings router
 */
export const GlobalSettingsRouter = () => (
  <Routes>
    <Route
      index
      element={
        <CAFScaleContextProvider>
          <GlobalSettings />
        </CAFScaleContextProvider>
      }
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
