import { DatePicker } from 'antd';

/**
 * Get the fields to use in the form.
 * @hook
 * @returns {Object} The fields to use in the form.
 */
export const useFields = () => {
  const fields = [
    {
      label: 'bank_holyday_name',
      name: 'bank_holyday_name',
      rules: [{ required: true }]
    },
    {
      label: 'date',
      name: 'date',
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    }
  ];

  return {
    fields
  };
};
