import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

/**
 * Custom hook for managing welfare-related fields in contract forms.
 * This hook fetches and provides the necessary fields for welfare information in contract forms.
 *
 * @hook
 * @returns {Object} An object containing welfare-related fields and state.
 */
export const useWelfareFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [socialSecuritySystems, setSocialSecuritySystems] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `parents/enums`
      });
      setSocialSecuritySystems(data.social_security_systems);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      name: ['household', 'beneficiary_number'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['household', 'annual_incomes'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['household', 'dependent_children'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['household', 'dependent_children_with_disabilities'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      name: ['parents', 0, 'parent', 'social_security_system'],
      translate: false,
      label: t('contracts.form.household.social_security_system', {
        number: 1
      }),
      rules: [{ required: true }],
      input: (
        <Select>
          {(socialSecuritySystems || []).map((system) => (
            <Option key={system} value={system}>
              {system}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['parents', 1, 'parent', 'social_security_system'],
      translate: false,
      label: t('contracts.form.household.social_security_system', {
        number: 2
      }),
      rules: [{ required: true }],
      input: (
        <Select>
          {(socialSecuritySystems || []).map((system) => (
            <Option key={system} value={system}>
              {system}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields
  };
};
