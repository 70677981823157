import { useTranslation } from 'react-i18next';

/**
 * A hook that generates column configurations for different sections of the CAF scale settings, including service for childcare, disability bonus, and social diversity bonus.
 *
 * @hook
 *
 * This hook utilizes the `useTranslation` hook to dynamically translate column titles and certain cell contents based on the current locale. It provides configurations for three different sections, each with specific columns that are relevant to the data being displayed. Some columns are marked as editable, allowing for in-place modifications of the data, and suffixes are used to denote units of measurement where applicable.
 *
 * @returns {Object} An object containing arrays of column configurations for the service for childcare, disability bonus, and social diversity bonus sections of the CAF scale settings.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const serviceForChildcareColumns = [
    {
      title: '',
      key: 'label',
      dataIndex: 'label',
      render: (label) => label && t(`global_settings.CAF_scale.labels.${label}`)
    },
    {
      title: t('global_settings.CAF_scale.columns.ceiling_price'),
      key: 'ceiling_price',
      dataIndex: 'ceiling_price',
      editable: true,
      suffix: '€/h'
    },
    {
      title: t('global_settings.CAF_scale.columns.PS_rate'),
      key: 'PS_rate',
      dataIndex: 'PS_rate',
      editable: true,
      suffix: '%'
    },
    {
      title: t('global_settings.CAF_scale.columns.service_cost'),
      key: 'service_cost',
      editable: true,
      dataIndex: 'service_cost',
      suffix: '€/h'
    }
  ];

  const disabilityBonusColumns = [
    {
      title: t('global_settings.CAF_scale.columns.disabled_children_rate'),
      key: 'disabled_children_rate',
      dataIndex: 'disabled_children_rate'
    },
    {
      title: t(
        'global_settings.CAF_scale.columns.place_ceiling_cost_price_fixed'
      ),
      key: 'place_ceiling_cost_price_fixed',
      dataIndex: 'place_ceiling_cost_price_fixed',
      editable: true,
      suffix: '€'
    },
    {
      title: t(
        'global_settings.CAF_scale.columns.place_ceiling_cost_price_variable'
      ),
      key: 'place_ceiling_cost_price_variable',
      dataIndex: 'place_ceiling_cost_price_variable',
      editable: true,
      suffix: '€'
    },
    {
      title: t(
        'global_settings.CAF_scale.columns.concerned_place_financing_rate'
      ),
      key: 'concerned_place_financing_rate',
      dataIndex: 'concerned_place_financing_rate',
      editable: true,
      suffix: '%'
    }
  ];

  const socialDiversityBonusColumns = [
    {
      title: '',
      key: 'label',
      dataIndex: 'label',
      render: (label) => label && t(`global_settings.CAF_scale.labels.${label}`)
    },
    {
      title: '',
      key: 'place_cost',
      dataIndex: 'place_cost',
      editable: true,
      suffix: '€/place'
    },
    {
      title: t(
        'global_settings.CAF_scale.columns.average_family_participation_threshold_per_place'
      ),
      key: 'average_family_participation_threshold_per_place',
      dataIndex: 'average_family_participation_threshold_per_place',
      editable: true,
      suffix: '€/h facturée'
    }
  ];

  return {
    serviceForChildcareColumns,
    disabilityBonusColumns,
    socialDiversityBonusColumns
  };
};
