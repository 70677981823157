import { Avatar, Button, Flex, Tag, Upload } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs, { utc } from 'dayjs';
import GenderBoyLogo from '../../../../../assets/images/icons/genderBoy.png';
import GenderGirlLogo from '../../../../../assets/images/icons/genderGirl.png';
import GenderNeutralLogo from '../../../../../assets/images/icons/genderNeutral.png';
import { fileToBase64 } from '../../../../../components/Daycare/fields';
import { realColorPlanning } from '../../../../../utils/constants/tagColors';

dayjs.extend(utc);

const { Dragger } = Upload;

/**
 * Creates properties for the Upload.Dragger component to handle the upload of a child's photo.
 *
 * @param {Function} setFileList - Function to set the state of file list.
 * @param {Array} fileList - Current file list.
 * @param {Function} dispatchAPI - Function for API call.
 * @param {Function} setRefresh - Function to trigger a refresh.
 * @param {boolean} refresh - Current state of refresh.
 * @param {Function} message - Function to display messages.
 * @param {Object} child - Child object containing details.
 * @returns {Object} Properties for the Upload.Dragger component.
 */
const draggerProps = (
  setFileList,
  fileList,
  dispatchAPI,
  setRefresh,
  refresh,
  message,
  child
) => ({
  multiple: false,
  onRemove: () => {
    setFileList([]);
  },
  beforeUpload: async (file) => {
    const fileExtension = file.name.split('.').pop();
    const base = await fileToBase64(file);
    if (['png', 'PNG'].includes(fileExtension)) {
      setFileList([file]);
      await dispatchAPI('PATCH', {
        url: `child/${child._id}`,
        body: {
          photo: base
        }
      });
      setFileList([]);
      setRefresh(!refresh);
    } else {
      message('Not a PNG file.');
      return true;
    }
    return false;
  },
  fileList
});

/**
 * Displays the appropriate gender logo or child's photo based on the provided child's details.
 *
 * @param {Object} child - Child object containing details including photo and gender.
 * @param {Array} fileList - List of files for the child's photo.
 * @param {Function} setFileList - Function to set the file list.
 * @param {Function} message - Function to display messages.
 * @param {Function} dispatchAPI - Function for API call.
 * @param {boolean} refresh - State to indicate if a refresh is needed.
 * @param {Function} setRefresh - Function to set the refresh state.
 * @returns {JSX.Element} JSX element displaying the child's photo or gender logo.
 */
export const displayChildGenderLogo = (
  child,
  fileList,
  setFileList,
  message,
  dispatchAPI,
  refresh,
  setRefresh
) => {
  switch (true) {
    case !child.photo && child.gender === 'BOY':
      return <img src={GenderBoyLogo} alt="gender-body-logo" />;
    case !child.photo && child.gender === 'GIRL':
      return <img src={GenderGirlLogo} alt="gender-body-logo" />;
    case !child.photo && !child.gender:
      return <img src={GenderNeutralLogo} alt="gender-body-logo" />;
    default:
      return (
        <Flex>
          <Avatar src={child.photo} size={120} />
          <Button type="link" className="no-padding">
            <Dragger
              {...draggerProps(
                child,
                fileList,
                setFileList,
                message,
                dispatchAPI,
                refresh,
                setRefresh
              )}
              className="no-style"
            >
              <EditOutlined />
            </Dragger>
          </Button>
        </Flex>
      );
  }
};

export const planningTypeFiltersArray = (t) => [
  {
    label: (
      <Tag color={realColorPlanning.reference_day}>
        {t('children.show.planning.options.regular')}
      </Tag>
    ),
    key: 'REGULAR',
    value: 'REGULAR'
  },
  {
    label: (
      <Tag color={realColorPlanning.reservations}>
        {t('children.show.planning.options.occasional')}
      </Tag>
    ),
    key: 'OCCASIONAL',
    value: 'OCCASIONAL'
  },
  {
    label: (
      <Tag color={realColorPlanning.adaptations}>
        {t('children.show.planning.options.adaptation')}
      </Tag>
    ),
    key: 'ADAPTATION',
    value: 'ADAPTATION'
  },
  {
    label: (
      <Tag color={realColorPlanning.absences}>
        {t('children.show.planning.options.absence')}
      </Tag>
    ),
    key: 'ABSENCE',
    value: 'ABSENCE'
  }
];

export const handleChangeTime = (
  purpose,
  timeRange,
  selectedDay,
  setSelectedDay
) => {
  let newDay;

  if (timeRange === 'month') {
    newDay =
      purpose === 'add'
        ? dayjs.utc(selectedDay).add(1, 'month').startOf('month').toISOString()
        : dayjs
            .utc(selectedDay)
            .subtract(1, 'month')
            .startOf('month')
            .toISOString();
  } else {
    newDay =
      purpose === 'add'
        ? dayjs.utc(selectedDay).add(7, 'day').toISOString()
        : dayjs.utc(selectedDay).subtract(7, 'day').toISOString();
  }

  setSelectedDay(newDay);
};

export const createTitle = (planningMode, selectedDay, t) => {
  if (planningMode === 'week') {
    return `${t(`children.show.planning.modes.${planningMode}`)} ${dayjs(
      selectedDay
    ).isoWeek()}`;
  }
  const date = dayjs(selectedDay).locale('fr').format('MMMM');
  const capitalizedDate = date.charAt(0).toUpperCase() + date.slice(1);
  return capitalizedDate;
};
