import { Col, Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useInformationsContent } from './informationsContent';
import { useDaycareContent } from './daycareContent';
import { useCustomerAccountContent } from './customerAccountContent';

/**
 * Component for displaying invoice informations.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Invoice data containing invoice informations
 * @returns {JSX.Element} Component JSX
 */

export const InformationsCards = ({ data }) => {
  const { t } = useTranslation();
  const listContentInfo = useInformationsContent(data);
  const listContentDaycare = useDaycareContent(data?.daycare);
  const listContentCustomer = useCustomerAccountContent(data?.customer_account);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={10}>
        <Card title={t('invoices.show.titles.informations')}>
          <Row align="middle">
            <Col span={24}>
              <DescriptionList
                data={listContentInfo}
                translate
                columns={24}
                layout="vertical"
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} xl={7}>
        <Card title={t('invoices.show.titles.daycare')}>
          <Row align="middle">
            <Col span={24}>
              <DescriptionList
                data={listContentDaycare}
                translate
                columns={24}
                layout="vertical"
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} xl={7}>
        <Card title={t('invoices.show.titles.customer_account')}>
          <Row align="middle">
            <Col span={24}>
              <DescriptionList
                data={listContentCustomer}
                translate
                columns={24}
                layout="vertical"
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

InformationsCards.propTypes = {
  data: PropTypes.shape({
    daycare: PropTypes.shape({}),
    customer_account: PropTypes.shape({})
  })
};

InformationsCards.defaultProps = {
  data: PropTypes.shape({
    daycare: null,
    customer_account: null
  })
};
