import dayjs from 'dayjs';

/**
 * Calculate the time elapsed since a given date and format it as a string.
 *
 * @param {Date} date - The date to calculate the elapsed time since.
 * @param {function(string): string} t - The translation function for internationalization.
 * @returns {string} A string representing the time elapsed since the given date.
 */
export const createdSince = (date, t) => {
  const dateformatted = dayjs(date);
  const now = dayjs();

  const differenceInDays = now.diff(dateformatted, 'day');
  const differenceInHours = now.diff(dateformatted, 'hour');
  const differenceInMinutes = now.diff(dateformatted, 'minute');

  if (differenceInMinutes < 60) {
    return `${t('notifications.form.since')}${differenceInMinutes} ${t(
      'notifications.form.minutes'
    )}`;
  }
  if (differenceInHours < 24) {
    return `${t('notifications.form.since')}${differenceInHours} ${t(
      'notifications.form.hours'
    )}`;
  }
  if (differenceInDays === 1) {
    return `${t('notifications.form.since')}${differenceInDays} ${t(
      'notifications.form.day'
    )}`;
  }
  return `${t('notifications.form.since')}${differenceInDays} ${t(
    'notifications.form.days'
  )}`;
};
