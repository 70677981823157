// Main nav menuItems
export const routes = {
  ATTENDANCE_CHECK: '/attendance-check',
  PLANNING: '/planning',
  CHILDREN_FOLLOW_UP: '/follow-up',
  COMMUNICATION: '/communication',
  MESSAGES_AND_NOTES: '/message-and-notes'
};

// Main nav subMenuItems
export const subRoutes = {
  PLANNING: {
    CHILDREN_PLANNING: '/children-planning',
    EMPLOYEES_PLANNING: '/employees-planning'
  }
};
// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TIME_RECORDER: '/time-recorder',
  PASSWORD_CREATION: '/post-pwd'
};

// url search parameters
export const pathSearches = {};
