import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/parentRoutes';

/**
 * `ParentNavMenu` is a React component that renders the navigation menu for the application.
 * It uses the Ant Design `Menu` component for UI, and `NavLink` from React Router for navigation.
 * The component dynamically generates menu items based on the routes and subRoutes defined in the constants.
 * It also supports internationalization using the `react-i18next` library.
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.setCollapsed - A function to set the collapsed state of the menu.
 *
 * @returns {JSX.Element} A JSX element rendering the navigation menu with all menu items.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.setCollapsed {Function} - Function to set the collapsed state of the menu. Required.
 */
export const ParentNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              )
            }
      )}
    />
  );
};

ParentNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
