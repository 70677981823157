import React, { useState, createContext, useContext } from 'react';

export const PreregistrationContext = createContext();

/**
 * A provider component for PreregistrationContext that holds the department filter and form switch state.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components to render within the provider.
 * @returns {JSX.Element} The PreregistrationProvider component.
 */
export const PreregistrationProvider = ({ children }) => {
  const [departmentFilter, setDepartmentFilter] = useState(null);
  const [switchToForm, setSwitchToForm] = useState(false);

  return (
    <PreregistrationContext.Provider
      value={{
        departmentFilter,
        setDepartmentFilter,
        switchToForm,
        setSwitchToForm
      }}
    >
      {children}
    </PreregistrationContext.Provider>
  );
};

/**
 * A custom hook for accessing the PreregistrationContext.
 *
 * @function
 *
 * @returns {Object} The context values for managing preregistration state.
 */
export const usePreregistrationContext = () => {
  const context = useContext(PreregistrationContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
