import { Tabs } from 'antd';
import { useTabContent } from './useTabContent';
import { useConversationContext } from '../../../contexts/ConversationContext';

/**
 * @component
 * @description Conversations component
 * @returns {jsx} Conversations
 */
export const Conversations = () => {
  const { setFilter } = useConversationContext();
  const { items } = useTabContent();

  const onChange = (activeKey) => {
    setFilter(activeKey);
  };

  return (
    <Tabs
      onChange={onChange}
      defaultActiveKey="ALL"
      items={items}
      tabBarStyle={{ margin: '0', padding: '4px 16px 0' }}
      className="conversation-tabs"
    />
  );
};
