import { useMemo } from 'react';
import { Column } from '@ant-design/charts';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHomeContext } from '../../../contexts/HomeContext';
import { formatAmount } from '../../../utils/formatter';

/**
 * @component
 * @description Component, renders the invoices card
 * @returns {JSX} Component JSX
 */
export const InvoicesCard = () => {
  const { t } = useTranslation();
  const { invoicesData } = useHomeContext();

  /**
   * Formats the data to be translated and displayed in the chart
   * @function
   * @returns {Array} - An array of objects with the translated month, translated name and the value
   */
  const formattedData = () =>
    invoicesData && invoicesData.length > 0
      ? invoicesData.map(({ month, value, name }) => ({
          month: t(`year_months.${month}`),
          name: t(`home.invoices_card.tags.${name}`),
          value: value === 0 ? null : value
        }))
      : [];

  const config = {
    data: formattedData(),
    isGroup: true,
    xField: 'month',
    yField: 'value',
    yAxis: {
      label: {
        formatter: (text) => `${text || 0} €`
      }
    },
    seriesField: 'name',
    tooltip: {
      formatter: ({ name, value }) => ({
        name,
        value: formatAmount(value)
      })
    },
    legend: {
      position: 'bottom'
    },
    label: {
      formatter: ({ value }) => formatAmount(value),
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    }
  };

  /**
   * Renders the component based on the current data
   * @function
   * @returns {JSX} - The component JSX - a chart or a loading spinner
   */
  const renderedComponent = useMemo(
    () => (invoicesData ? <Column {...config} /> : <Spin />),
    [invoicesData]
  );

  return (
    <Card
      title={t('home.titles.invoices')}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: ' column',
        justifyContent: 'space-between'
      }}
    >
      {renderedComponent}
    </Card>
  );
};
