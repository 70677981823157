// Main nav menuItems
export const routes = {
  HOME: '/'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation'
};

// url search parameters
export const pathSearches = {};
