import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReferenceWeek } from '../../../pre-registrations/ReferenceWeek';
import { ReferenceWeekContext } from '../../../../contexts/ReferenceWeekContext';

export const ContractPlanning = ({ contract }) => {
  const { referenceWeeks } = useContext(ReferenceWeekContext);

  return referenceWeeks?.map((_, index) => (
    <ReferenceWeek
      index={index}
      initialValues={contract?.reference_weeks}
      purpose={null}
    />
  ));
};

ContractPlanning.propTypes = {
  contract: PropTypes.shape({
    reference_weeks: PropTypes.arrayOf()
  })
};

ContractPlanning.defaultProps = {
  contract: null
};
