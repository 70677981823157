import { useTranslation } from 'react-i18next';

/**
 * @hook useListContent
 * @description
 * useListContent is a hook that returns the content of the health card.
 * It is used in the ContractInformationsCard component.
 *
 * @param {Object} data - The data of the health card
 * @returns {Array} The content of the health card card
 */
export const useListContent = (data = {}) => {
  const translationKey = 'pre-registrations.show.health_card';
  const { t } = useTranslation();
  const { health = {} } = data;
  const {
    allergies = [{ label: '-', _id: '1' }],
    childhood_illnesses = [{ label: '-', _id: '1' }],
    vaccines = [{ label: '-', _id: '1' }],
    disability = false,
    personalized_care_plan = ''
  } = health;

  const formatContent = (items) =>
    items.map((item) => t(item.label)).join(', ');

  return [
    {
      label: `${translationKey}.disability`,
      content: t(disability ? 'yes' : 'no'),
      span: 3
    },
    {
      label: `${translationKey}.allergies`,
      content: formatContent(allergies),
      span: 3
    },
    {
      label: `${translationKey}.childhood_illnesses`,
      content: formatContent(childhood_illnesses),
      span: 3
    },
    {
      label: `${translationKey}.vaccines`,
      content: formatContent(vaccines),
      span: 3
    },
    {
      label: `${translationKey}.personalized_care_plan`,
      content: personalized_care_plan,
      span: 3
    }
  ];
};
