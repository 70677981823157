import dayjs from 'dayjs';

/**
 * Handles the filtering of data based on the selected year.
 *
 * This function receives a date value and a setter function for the date filter state.
 * It uses the provided date to determine the start and end of the selected year.
 * It then constructs a query string representing this date range and updates the date filter state.
 * If no date is provided, it resets the date filter state.
 *
 * @function
 *
 * @param {string} value - The selected date value in ISO format.
 * @param {Function} setDateFilter - Function to set the state of the date filter.
 *
 * @returns {void} - Does not return a value; it updates the state directly.
 */
export const handleDateFilter = (value, setDateFilter) => {
  if (value) {
    const yearStart = dayjs(value).startOf('year');
    const yearEnd = dayjs(value).endOf('year');

    const string = `date>=${yearStart.toISOString()}&date<=${yearEnd.toISOString()}`;
    setDateFilter(string);
  } else setDateFilter();
};
