import { Button, Form, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { drawerTailFormLayout } from '../../../utils/constants/formLayout';
import { useFormContent } from './useFormContent';
import { handleKeyPress } from '../../../utils/formHandleKeyPress';

/**
 * Converts a file object to a Base64 encoded string.
 *
 *
 * This function is used to convert a file object (like an image or a document) into a Base64
 * encoded string. This is particularly useful for preparing files to be sent over HTTP requests
 * where binary data cannot be easily transmitted. It returns a promise that resolves to the
 * Base64 string.
 *
 * @function
 *
 * @param {File} file - The file object to be converted.
 * @returns {Promise<string>} A promise that resolves to a Base64 encoded string representation
 *                            of the input file. The promise is rejected if there's an error during
 *                            the reading process.
 */
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * A modal component for creating and updating documents.
 *
 * @component
 *
 * This component displays a form within a modal for the user to either create a new document
 * or update an existing one. It provides fields for uploading files, entering document details,
 * and setting visibility. The form data is submitted to the server, and the parent component's
 * state is updated accordingly.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.purpose - The purpose of the modal ('create' or 'edit').
 * @param {Object} props.record - The document data record (for 'edit' mode).
 * @param {boolean} props.refresh - State indicating if a refresh is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @param {string} props.resourceName - The name of the resource for API endpoints.
 * @returns {JSX.Element} The modal component for creating or updating documents.
 */
export const CreateEditCommunicationModal = ({
  purpose,
  record,
  setRefresh,
  refresh,
  resourceName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({
    thumbnail: [],
    images: [],
    videos: [],
    attachment: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enums, setEnums] = useState();
  const { message } = useErrorMessage();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const postDocument = async (body) => {
    setIsSubmitting(true);

    const formData = new FormData();

    if (fileList.thumbnail.length) {
      fileList.thumbnail.forEach((file) => formData.append('thumbnail', file));
    }

    if (fileList.images.length) {
      fileList.images.forEach((file) => formData.append('images', file));
    }

    if (fileList.videos.length) {
      fileList.videos.forEach((file) => formData.append('videos', file));
    }

    if (fileList.attachment.length) {
      fileList.attachment.forEach((file) =>
        formData.append('attachment', file)
      );
    }

    formData.append('values', JSON.stringify({ ...body, daycare }));

    const values = resourceName === 'infos' ? { ...body, daycare } : formData;

    try {
      await dispatchAPI('POST', {
        url: `${resourceName}`,
        body: values
      });
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const patchDocument = async (body) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      if (fileList.thumbnail.length) {
        fileList.thumbnail.forEach((file) =>
          formData.append('thumbnail', file)
        );
      }

      if (fileList.images.length) {
        fileList.images.forEach((file) => formData.append('images', file));
      }

      if (fileList.videos.length) {
        fileList.videos.forEach((file) => formData.append('videos', file));
      }

      if (fileList.attachment.length) {
        fileList.attachment.forEach((file) =>
          formData.append('attachment', file)
        );
      }

      formData.append('values', JSON.stringify({ ...body, daycare }));

      const values = resourceName === 'infos' ? { ...body, daycare } : formData;

      await dispatchAPI('PATCH', {
        url: `${resourceName}/${record._id}/`,
        body: values
      });
      setIsSubmitting(false);
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = (body) => {
    (async () => {
      if (purpose === 'edit') {
        await patchDocument(body);
      } else {
        await postDocument(body);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  useEffect(() => {
    if (purpose === 'edit' && isModalOpenedLocaly && record) {
      const updatedFormValues = {
        ...record,
        start_visibility: dayjs(record?.start_visibility),
        ...(record?.end_visibility && {
          end_visibility: dayjs(record?.end_visibility)
        })
      };

      if (resourceName === 'events') {
        updatedFormValues.date = dayjs(record?.date);
        updatedFormValues.hours = [
          dayjs(record?.hours[0]),
          dayjs(record?.hours[1])
        ];
        updatedFormValues.groups = (record?.groups || []).map(
          (group) => group._id
        );
      }
      form.setFieldsValue(updatedFormValues);
    }
  }, [purpose, isModalOpenedLocaly, record]);

  const formContent = useFormContent(
    record,
    enums,
    fileList,
    setFileList,
    form,
    purpose
  );

  return (
    <Modal
      title={t(`communication.${resourceName}.modal.title.${purpose}`)}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onKeyDown={handleKeyPress}
        scrollToFirstError
      >
        {formContent[resourceName]}
        <Form.Item {...drawerTailFormLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={closeModal}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateEditCommunicationModal.propTypes = {
  purpose: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string,
    start_visibility: PropTypes.string,
    end_visibility: PropTypes.string,
    thumbnail: PropTypes.shape({
      metadata: PropTypes.shape({
        originalName: PropTypes.string
      })
    }),
    images: PropTypes.arrayOf(PropTypes.shape({})),
    hours: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

CreateEditCommunicationModal.defaultProps = {
  refresh: false,
  setRefresh: null,
  record: null
};
