import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { CreateUpdateContainerModal } from '../../../../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useFields } from '../fields';

/**
 * AddressesCardModal component
 * @component
 * @param {string} purpose The purpose of the modal - 'edit' or 'create'
 * @param {Object} record The record to update
 * @param {Object} customerAccount The customer account
 * @param {boolean} add Indicates if the modal is for adding a new address
 * @param {Function} setRefresh The function to refresh the data
 * @returns {JSX.Element} The component
 */
export const AddressesCardModal = ({
  purpose,
  record,
  customerAccount,
  add,
  setRefresh
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [form] = Form.useForm();
  const fields = useFields(form);

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const config = {
    onUpdateResource: {
      setBody: (body) => ({
        ...customerAccount,
        billing_address: add
          ? [...customerAccount.billing_address, body]
          : customerAccount.billing_address.map((address, index) =>
              index === record.index ? body : address
            )
      })
    },
    onCreateResource: {
      setBody: (body) => ({
        ...customerAccount,
        billing_address: [...customerAccount.billing_address, body]
      })
    },
    onGetResourceFromModal: {
      setFields: () => ({
        ...record
      })
    }
  };

  return (
    <Modal
      title={
        add
          ? t('customer-account.show.addresses.modal.add_address')
          : t('customer-account.show.addresses.modal.update_address')
      }
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
      }}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={false}
        purpose={purpose}
        baseUrl="customer-accounts"
        recource="customer-accounts"
        tradKey="customer-account"
        customFormInstance={form}
        id={id}
        withGetResource={false}
        config={config}
        record={record}
        setRefresh={setRefresh}
        closeModal={closeModal}
        withoutPageHeaderCustom
        successCreateMessage={t('success.messages.address_created')}
        successEditMessage={t('success.messages.address_updated')}
      />
    </Modal>
  );
};

AddressesCardModal.propTypes = {
  purpose: PropTypes.string.isRequired,
  record: PropTypes.shape({
    _id: PropTypes.string,
    index: PropTypes.number
  }).isRequired,
  customerAccount: PropTypes.shape({
    billing_address: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  add: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};
