import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Row, Col, Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { PlanningHeader } from '../PlanningHeader';
import { HoursBar } from '../../../components/HoursBar/HoursBar';
import { ChildRow } from './ChildRow';
import EventModal from '../EventModal';
import { TimeSlots } from './TimeSlots';
import { usePlanningContext } from '../../../contexts/PlanningContext';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const { Option } = Select;

/**
 * @description ChildrenPlanning component. Displays the children planning page
 * @component
 * @returns {JSX} The children planning page
 */
export const ChildrenPlanning = () => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { t } = useTranslation();
  const {
    selectedDay,
    setSelectedGroup,
    timeSlots,
    isPlanningLoading,
    setSelectedChild,
    getChildren,
    isSelectLoading,
    children,
    deleteEvent,
    selectOptions,
    daycareClosedDays
  } = usePlanningContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [purpose, setPurpose] = useState('');
  const [eventEditId, setEventEditId] = useState('');
  const [recorderId, setRecorderId] = useState('');
  const [previsionalPlanning, setPrevisionalPlanning] = useState([]);
  const [displayPlanningRangeBarButtons, setDisplayPlanningRangeBarButtons] =
    useState(true);

  useEffect(() => {
    const relativeHeaderMonth = dayjs(selectedDay).month();
    const relativeHeaderYear = dayjs(selectedDay).year();
    const todayMonth = dayjs().month();
    const todayYear = dayjs().year();

    const boolean =
      relativeHeaderMonth >= todayMonth - 1 &&
      todayYear === relativeHeaderYear &&
      !daycareClosedDays.includes(dayjs(selectedDay).format('DD/MM/YYYY'));

    setDisplayPlanningRangeBarButtons(boolean);
  }, [selectedDay]);

  const openChildEventModal = (
    type,
    child,
    purp,
    eventId,
    timeRecorderId,
    refDay
  ) => {
    setPurpose(purp);
    setSelectedType(type);
    setSelectedChild(child);
    setEventEditId(eventId);
    setRecorderId(timeRecorderId);
    setIsModalOpen(true);
    setPrevisionalPlanning(refDay);
  };

  const closeChildEventModal = () => {
    setPurpose('');
    setSelectedType('');
    setSelectedChild({});
    setEventEditId('');
    setRecorderId('');
    setPrevisionalPlanning([]);
    setIsModalOpen(false);
    getChildren();
  };

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrums
        withoutNavigateBack
        title={t('plannings.children.title')}
      />
      <ContentCustom>
        {isModalOpen && (
          <EventModal
            isModalOpen={isModalOpen}
            closeModal={closeChildEventModal}
            purpose={purpose}
            type={selectedType}
            eventId={eventEditId}
            recorderId={recorderId}
            selectedDay={selectedDay}
            previsionalPlanning={previsionalPlanning}
          />
        )}
        <PlanningHeader />
        <Flex vertical gap="small">
          <Row align="middle">
            <Col span={4}>
              <Select
                style={{ width: '95%' }}
                loading={isSelectLoading}
                defaultValue={selectOptions[0]}
                onChange={(value) => setSelectedGroup(value)}
              >
                {selectOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={18}>
              <table>
                <HoursBar />
              </table>
            </Col>
          </Row>
          {isPlanningLoading ? (
            <CustomSpin />
          ) : (
            <Flex vertical gap="small">
              {timeSlots?.map((slot) => (
                <TimeSlots
                  key={slot?.isWalking}
                  group={slot?.isWalking}
                  slotEvents={slot?.slotEvents}
                />
              ))}
              {(children || []).map((event) => (
                <ChildRow
                  key={event._id}
                  event={event}
                  openModal={openChildEventModal}
                  deleteEvent={deleteEvent}
                  displayButtons={displayPlanningRangeBarButtons}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </ContentCustom>
    </>
  );
};
