import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Card, Row, Col, Button, Tag, Flex } from 'antd';
import SlotsModal from './SlotsModal';
import IsWalkingBelow from '../../assets/images/icons/mingcute_walk-fill_green.svg';
import IsWalkingOver from '../../assets/images/icons/mingcute_walk-fill_red.svg';
import DontWalkBelow from '../../assets/images/icons/healthicons_baby-0306m_green.svg';
import DontWalkOver from '../../assets/images/icons/healthicons_baby-0306m_red.svg';
import { getStats } from '../../utils/getStatsPerDay';

/**
 * Days
 * @component
 * @description Componente Days - Display the days of the week with the number of children and the coverage rate in one day
 * @prop {object} week - Array of days of the week from Monday to Friday
 * @prop {function} setSelectedDay - Function to set the selected day
 * @returns {JSX} - Days component
 */
export const Days = ({
  week,
  setSelectedDay,
  selectedDay,
  statsPerWeek,
  daycareSelected,
  daycareClosedDays
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [slotSelected, setSlotSelected] = useState({});
  const [walked, setWalked] = useState(false);
  const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const openModal = (slots, walk) => {
    setSlotSelected(slots?.slotEvents);
    setWalked(walk);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSlotSelected({});
  };

  const generateCardClass = (selectDay, day) => {
    if (
      dayjs(selectDay).format('DD/MM/YYYY') === dayjs(day).format('DD/MM/YYYY')
    ) {
      return 'day-card selected';
    }
    if (daycareClosedDays.includes(dayjs(day).format('DD/MM/YYYY'))) {
      return 'day-card closed';
    }
    return 'day-card unselected';
  };

  const getStatsPerDay = (dont_walk, walk) => {
    if (dont_walk && walk) {
      const { peakHourCombined, peakDontWalkAtPeakHour, peakWalkAtPeakHour } =
        getStats(dont_walk, walk);

      return (
        <Flex gap="small" align="center" justify="center" wrap="nowrap">
          <Tag
            className={
              ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                daycareSelected.capacity) *
                100 >=
              100
                ? 'day-tag over'
                : 'day-tag below'
            }
          >
            {daycareSelected?.capacity
              ? `${(
                  ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                    daycareSelected.capacity) *
                  100
                ).toFixed(0)}%`
              : '0%'}
          </Tag>
          <Button
            type="link"
            className="no-padding"
            onClick={() =>
              openModal(
                dont_walk.find((d) => d.slotEvents[peakHourCombined.hour]),
                false
              )
            }
          >
            <Tag
              className={
                ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                  daycareSelected.capacity) *
                  100 >=
                100
                  ? 'day-tag over'
                  : 'day-tag below'
              }
            >
              <img
                src={
                  ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                    daycareSelected.capacity) *
                    100 >=
                  100
                    ? DontWalkOver
                    : DontWalkBelow
                }
                alt="dont_walk"
              />
              {peakDontWalkAtPeakHour}
            </Tag>
          </Button>
          <Button
            type="link"
            className="no-padding"
            onClick={() =>
              openModal(
                walk.find((w) => w.slotEvents[peakHourCombined.hour]),
                true
              )
            }
          >
            <Tag
              className={
                ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                  daycareSelected.capacity) *
                  100 >=
                100
                  ? 'day-tag over'
                  : 'day-tag below'
              }
            >
              <img
                src={
                  ((peakDontWalkAtPeakHour + peakWalkAtPeakHour) /
                    daycareSelected.capacity) *
                    100 >=
                  100
                    ? IsWalkingOver
                    : IsWalkingBelow
                }
                alt="is_walking"
              />
              {peakWalkAtPeakHour}
            </Tag>
          </Button>
        </Flex>
      );
    }
    return true;
  };

  const title = (day) => (
    <>
      <Row justify="center">{dayjs(day).format('D')}</Row>
      <Row justify="center">{capitalize(dayjs(day).format('dddd'))}</Row>
    </>
  );

  return (
    <>
      {isModalOpen && slotSelected && (
        <SlotsModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          slots={slotSelected}
          walked={walked}
          daycareSelected={daycareSelected}
        />
      )}
      {statsPerWeek?.[0]?.length > 0 && statsPerWeek?.[1]?.length > 0 && (
        <Row justify="center" gutter={[10, 10]}>
          {week.map((day) => (
            <Col xs={12} md={8} xl={4} key={day}>
              <Card
                className={generateCardClass(selectedDay, day)}
                onClick={() => setSelectedDay(dayjs(day))}
                title={title(day)}
              >
                {daycareClosedDays.includes(dayjs(day).format('DD/MM/YYYY')) ? (
                  <span>FERMÉ</span>
                ) : (
                  <>
                    {getStatsPerDay(
                      statsPerWeek?.[1]?.filter(
                        (f) =>
                          dayjs(f.day).format('DD/MM/YYYY') ===
                          dayjs(day).format('DD/MM/YYYY')
                      ),
                      statsPerWeek?.[0]?.filter(
                        (f) =>
                          dayjs(f.day).format('DD/MM/YYYY') ===
                          dayjs(day).format('DD/MM/YYYY')
                      )
                    )}
                  </>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

Days.propTypes = {
  daycareSelected: PropTypes.shape({ capacity: PropTypes.number }).isRequired,
  week: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  selectedDay: PropTypes.shape({}).isRequired,
  statsPerWeek: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  daycareClosedDays: PropTypes.arrayOf(PropTypes.string)
};

Days.defaultProps = {
  daycareClosedDays: null
};
