import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/devRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
import { UserRouter } from '../routes/users/UserRouter';
import { EmployeeRouter } from '../routes/employee/EmployeeRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import ConfigurationRouter from './ConfigurationRouter';
import { RequireAuth } from './RequireAuth';

export const DevRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.EMPLOYEES}/*`} element={<EmployeeRouter />} />
          <Route path={`${routes.WORKFLOWS}/*`} element={<WorkflowRouter />} />
          <Route
            path={`${routes.CONFIGURATIONS}`}
            element={<ConfigurationRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
