import { Route, Routes } from 'react-router-dom';
import { ChildrenPlanning } from './children/ChildrenPlanning';
import { EmployeesPlanning } from './employees/EmployeesPlanning';
import { TimeRecorder } from './TimeRecorder/TimeRecorder';
import { Exception } from '../../components';

/**
 * PlanningRouter
 * @component
 * @description Routing for planning section
 * @returns {JSX} JSX - PlanningRouter
 */
export const PlanningRouter = () => (
  <Routes>
    <Route path="/children-planning" element={<ChildrenPlanning />} />
    <Route path="/employees-planning" element={<EmployeesPlanning />} />
    <Route path="/time-recorder" element={<TimeRecorder />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
