import { FollowUp } from '../FollowUp';
import { IndividualFollowUpChildrenList } from '../IndividualFollowUpChildrenList';

/**
 * Generates a list of menu items specifically designed for tablet-sized screens.
 *
 * This function takes an array of children groups and maps each group to a menu item.
 * Each menu item includes the group label and a `FollowUp` component configured with
 * the group's details. It also adds an additional item for individual follow-ups.
 * If there are no children groups, it returns an empty array.
 *
 * @param {Object[]} childrenGroups - Array of children group objects.
 * @param {Function} handleModalBehaviour - A function to handle modal behaviour.
 * @param {Object[]} activities - Array of activity objects.
 * @param {Function} t - The translation function from i18next.
 * @returns {Object[]} An array of objects, each representing a menu item for tablet screens.
 *
 * @example
 * const childrenGroups = [{ label: 'Group 1', _id: '1' }, { label: 'Group 2', _id: '2' }];
 * const handleModal = () => { ... };
 * const activities = [{ ... }, { ... }];
 * const t = i18n.t;
 * const menuItems = tabletItems(childrenGroups, handleModal, activities, t);
 * // Output: Array of menu items including FollowUp components for each group and individual follow-ups.
 */
export const tabletItems = (
  childrenGroups,
  handleModalBehaviour,
  activities,
  t
) =>
  childrenGroups.length
    ? [
        ...childrenGroups.map((childrenGroup, index) => ({
          key: `${index}`,
          label: childrenGroup.label,
          children: (
            <FollowUp
              handleModalBehaviour={handleModalBehaviour}
              groupID={childrenGroup._id}
              activities={activities.filter(
                ({ groups, child }) =>
                  groups.some((group) => group.label === childrenGroup.label) &&
                  !child?._id
              )}
            />
          )
        })),
        {
          key: '4',
          label: t('children-follow-up.form.individual'),
          children: (
            <IndividualFollowUpChildrenList
              handleModalBehaviour={handleModalBehaviour}
            />
          )
        }
      ]
    : [
        {
          label: t('children-follow-up.form.no_chidren')
        }
      ];
