import { Button, Divider, Popconfirm, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { userRoles } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { routes } from '../../utils/constants/adminRoutes';

const iconSize = 18;

export const useColumns = (handleModalBehaviour, setRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const archiveUser = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `users/${id}`,
        body: {
          archived: true,
          archiving_date: dayjs()
        }
      });
      setRefresh((prev) => !prev);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) =>
        role ? (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('users.form.daycare'),
      key: 'daycare',
      dataIndex: 'daycare',
      sorter: true,
      render: (daycare) => (daycare && `${daycare.name}`) || ''
    },
    {
      render: (record) => (
        <Row align="end">
          <Link
            to={{
              pathname: `${routes.USERS}/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
            <Divider type="vertical" />
          </Link>
          <>
            <Button
              type="link"
              className="no-padding"
              onClick={() => handleModalBehaviour(record, 'edit')}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Button>
            <Divider type="vertical" />
          </>
          {record?.status !== 'ARCHIVED' && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => archiveUser(record?._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </Row>
      )
    }
  ];
};
