import AppetizerIcon from '../../../assets/images/icons/noto_green-salad.svg';
import MorningSnackIcon from '../../../assets/images/icons/noto_beverage-box.svg';
import MainCourseIcon from '../../../assets/images/icons/noto_fork-and-knife.svg';
import DessertIcon from '../../../assets/images/icons/noto_pie.svg';
import AfternoonSnackIcon from '../../../assets/images/icons/noto_cookie.svg';

/**
 * Module providing a collection of meal-related icons.
 *
 * Each object in the array represents a specific type of meal or course,
 * containing an SVG image and a descriptive title.
 *
 * @module
 * @type {Array<Object>}
 * @property {string} icon - The SVG image reference for the icon.
 * @property {string} title - A descriptive title for the icon.
 */
export const icons = [
  {
    icon: MorningSnackIcon,
    title: 'MorningSnack-Icon'
  },
  {
    icon: AppetizerIcon,
    title: 'Appetizer-Icon'
  },
  {
    icon: MainCourseIcon,
    title: 'MainCourse-Icon'
  },
  {
    icon: DessertIcon,
    title: 'Dessert-Icon'
  },
  {
    icon: AfternoonSnackIcon,
    title: 'AfternoonSnack-Icon'
  }
];
