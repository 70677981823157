import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './invoiceFromScratchFields';
import InvoicingContext from '../InvoicingContext';

/**
 * CreateUpdateInvoice component is used to create or update an invoice based on the provided purpose.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.purpose - The purpose of the component, which can be 'create' or 'update'.
 *
 * @returns {JSX.Element} The rendered CreateUpdateInvoice component.
 */
export const CreateUpdateInvoice = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields(purpose);
  const { setFormValues } = InvoicingContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        due_date: data?.due_date && dayjs(data?.due_date),
        payment_method: data?.payment_method.method
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        type: 'FROM_SCRATCH',
        range_concerned: [
          dayjs(data.due_date)
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          dayjs(data.due_date)
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ],
        payment_method: data?.payment_method && { method: data?.payment_method }
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        type: 'FROM_SCRATCH',
        range_concerned: [
          dayjs(data.due_date)
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          dayjs(data.due_date)
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ],
        payment_method: data?.payment_method && { method: data?.payment_method }
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="invoices"
      resource="invoices"
      config={config}
      setFormValues={setFormValues}
      needFormDependencies
    />
  );
};

CreateUpdateInvoice.propTypes = {
  purpose: PropTypes.string
};

CreateUpdateInvoice.defaultProps = {
  purpose: 'create'
};
