import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';

/**
 * Fields for the form
 * @hook
 * @returns {Array} The fields to use in the form.
 */
export const useDocumentFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      label: 'document_title',
      name: 'document_title',
      rules: [{ required: true }]
    },
    {
      label: 'required',
      name: 'required',
      valuePropName: 'checked',
      input: <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
    }
  ];

  return {
    fields
  };
};
