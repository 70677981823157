import { Button, Collapse, Flex, List, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { dateRender } from '../News/utils';
import { visibilitiesColors } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Renders a single information card within a list.
 *
 * This component displays the details of an information resource in a collapsible format. The card shows the title, visibility tags,
 * and a collapsible content section rendered with React Quill in read-only mode. It provides action buttons for editing and deleting the resource.
 * The visibility tags are displayed using different colors defined in `visibilitiesColors`.
 * It uses Ant Design's `List.Item` and `Collapse` components for layout and styling.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.resource - The information resource data to display.
 * @param {Function} props.handleModal - Function to handle the opening of the modal for editing the resource.
 * @param {Function} props.deleteResource - Function to delete the resource.
 * @returns {JSX.Element} The rendered information card component.
 */
export const InfosCard = ({ resource, handleModal, deleteResource }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const userBoolean = ['guests:PARENT', 'users:TABLET'].includes(user?.role);

  return (
    <List.Item>
      <Collapse
        key={resource?._id}
        items={[
          {
            key: `${resource?._id} ${resource?.title}`,
            label: (
              <Flex vertical>
                <p style={{ fontSize: 16, fontWeight: 500, margin: 0 }}>
                  {resource?.title}
                </p>
                {!userBoolean && (
                  <Flex align="center" gap="middle">
                    <span style={{ color: 'var(--mediumGray)' }}>
                      {dateRender(resource, t)}
                    </span>
                    <Flex gap="small">
                      {(resource?.visibility || []).map((visItem) => (
                        <Tag color={visibilitiesColors[visItem]} key={visItem}>
                          {t(`communication.news.list.type.${visItem}`)}
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            ),
            children: (
              <ReactQuill
                value={resource?.content}
                readOnly
                modules={{ toolbar: false }}
                theme="bubble"
              />
            ),
            extra: !['guests:PARENT', 'users:TABLET'].includes(user?.role) && (
              <>
                <Button
                  type="link"
                  onClick={() => handleModal(resource, 'edit')}
                >
                  <EditOutlined />
                </Button>
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ danger: true }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteResource(resource?._id)}
                  icon={<WarningOutlined />}
                >
                  <Button type="link">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </>
            )
          }
        ]}
      />
    </List.Item>
  );
};

InfosCard.propTypes = {
  resource: PropTypes.shape({
    _id: PropTypes.string,
    visibility: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.string,
    title: PropTypes.string
  }),
  handleModal: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired
};

InfosCard.defaultProps = {
  resource: null
};
