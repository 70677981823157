import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Input } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [ribs, setRIBs] = useState();

  const today = new Date();
  const year = today.getFullYear();
  const months = [];

  for (let i = today.getMonth(); i > today.getMonth() - 6; i -= 1) {
    if (i + 1 > 0) {
      const month = (i + 1).toString().length === 1 ? `0${i + 1}` : `${i + 1}`;
      months.push(`${month}/${year}`);
    } else {
      const month =
        (12 + (i + 1)).toString().length === 1
          ? `0${12 + (i + 1)}`
          : `${12 + (i + 1)}`;
      months.push(`${month}/${year - 1}`);
    }
  }

  const getDaycare = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}?populate=invoicing_configuration`
      });
      setRIBs(data?.invoicing_configuration?.bank_accounts);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getDaycare();
      setIsLoading(false);
    })();
  }, []);

  return [
    {
      name: ['rib'],
      rules: [{ required: true }],
      label: 'iban',
      input: (
        <Select loading={isLoading}>
          {ribs &&
            ribs.map((r) => (
              <Option
                key={r._id}
                value={r._id}
              >{`${r.bank_name} (${r.iban})`}</Option>
            ))}
        </Select>
      ),
      purpose: ['create']
    },
    {
      name: ['month'],
      rules: [{ required: true }],
      label: 'month',
      input: (
        <Select loading={isLoading}>
          {months.reverse().map((type) => (
            <Option value={type}>{type}</Option>
          ))}
        </Select>
      ),
      purpose: ['create']
    },
    {
      name: ['levy_date'],
      rules: [{ required: true }],
      label: 'levy_date',
      input: <DatePicker format="DD/MM/YYYY" />,
      purpose: ['create']
    },
    {
      name: ['comment'],
      label: 'comment',
      input: <TextArea />,
      purpose: ['create', 'edit']
    },
    {
      name: ['daycare'],
      initialValue: daycare,
      hidden: true,
      rules: [{ required: true }],
      purpose: ['create']
    }
  ];
};

export default useFields;
