import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

/**
 * A modal component for creating or updating ingredients.
 *
 * @param {Object} props - The component props.
 * @param {string} props.purpose - Indicates the purpose of the modal ('create' or 'edit').
 * @param {Object} props.record - The data record for the ingredient being edited.
 * @param {Function} props.setRefresh - Function to trigger a refresh in the parent component.
 * @param {string} props.resourceName - The name of the resource being managed.
 * @returns {JSX.Element} A modal with a form for creating or updating an ingredient.
 */
export const CreateUpdateIngredient = ({
  purpose,
  record,
  setRefresh,
  resourceName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState();
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [query, setQuery] = useState();

  const getIcons = async () => {
    setOptionsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `ingredients/get-icon/${query}`
      });
      const rawOptions = (data || []).map((option, index) => ({
        value: option,
        label: <img src={option} alt={`icon-${index}`} style={{ width: 32 }} />
      }));

      setOptionsLoading(false);
      setOptions(rawOptions);
    } catch (e) {
      setOptionsLoading(false);
      message(e);
    }
  };

  useEffect(() => {
    if (query) {
      (async () => {
        await getIcons();
      })();
    }
  }, [query]);

  useEffect(() => {
    if (record && purpose === 'edit') {
      form.setFieldsValue(record);
      setQuery(record.name);
    }
  }, [record]);

  const customSubmit = async (body) => {
    try {
      setIsSubmitting(true);

      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/${resourceName}/${record._id}`,
          body: { ...body, daycare }
        });
      } else {
        await dispatchAPI('POST', {
          url: `/${resourceName}`,
          body: {
            ...body,
            daycare
          }
        });
      }
      setIsModalOpenedLocaly(false);
      form.resetFields();
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleValidate = () => {
    const queryValue = form.getFieldValue('name');
    setQuery(queryValue);
  };

  return (
    <Modal
      title={t(`ingredients.modal.${purpose === 'edit' ? 'edit' : 'add'}`)}
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
      }}
      footer={null}
      width={800}
    >
      <Form form={form} onFinish={customSubmit}>
        <Form.Item
          name={['name']}
          label={t('ingredients.form.name')}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Flex justify="flex-end">
            <Button onClick={handleValidate}>
              <CheckOutlined />
              {t('buttons.search')}
            </Button>
          </Flex>
        </Form.Item>

        <Form.Item
          name={['icon']}
          label={t('ingredients.form.icon')}
          {...formItemLayout}
        >
          <Select
            options={options}
            loading={optionsLoading && !options}
            disabled={!query && !options}
            style={{ height: 40 }}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Flex justify="flex-end" gap="small">
            <Button
              type="link"
              danger
              onClick={() => setIsModalOpenedLocaly(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateUpdateIngredient.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  resourceName: PropTypes.string.isRequired
};

CreateUpdateIngredient.defaultProps = {
  purpose: '',
  record: null,
  setRefresh: () => {}
};
