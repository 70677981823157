import React from 'react';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { childrenGender } from '../../../../utils/constants/tagColors';

/**
 * @description
 * useListContent is a hook that returns the content of the child card.
 * It is used in the ChildCard component.
 *
 * @param {Object} data - The data of the child
 * @returns {Array} The content of the child card
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const { child } = data;

  return [
    {
      label: 'pre-registrations.show.child.first_name',
      content: child?.first_name,
      span: 1
    },
    {
      label: 'pre-registrations.show.child.last_name',
      content: child?.last_name,
      span: 1
    },
    {
      label: 'pre-registrations.show.child.gender',
      content: child?.gender && (
        <Tag color={childrenGender[child?.gender]}>
          {t(`pre-registrations.form.genders.${child?.gender}`)}
        </Tag>
      ),
      span: 1
    },
    {
      label: 'pre-registrations.show.child.birth_date',
      content: child?.birth_date
        ? dayjs(child?.birth_date).format('DD/MM/YYYY')
        : '-',
      span: 1
    },
    {
      label: 'pre-registrations.show.child.birth_place',
      content: child?.place_of_birth,
      span: 1
    }
  ];
};
