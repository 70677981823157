import { useState } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePanelContent } from './usePanelContent';
import { CreateUpdateDynamicListContent } from './CreateUpdateDynamicListContent';
import { useLabelFields } from './labelFields';
import { useFields as useAbsenceReasonsFields } from './AbsenceReasons/fields';
import { useLabelColorFields } from './useLabelColorFields';

const { Panel } = Collapse;

/**
 * Renders a dynamic list that can handle different types of data, such as absence reasons or team positions, with modals for creating or updating items.
 *
 * This component utilizes the `Collapse` component from Ant Design to display a list of items categorized into panels. It supports handling different
 * types of data by dynamically choosing the appropriate form fields based on the resource name. It integrates with custom hooks to generate these fields
 * and manage state, such as `useLabelFields`, `useAbsenceReasonsFields`, and `useLabelColorFields`.
 *
 * Upon interacting with the list to add or edit items, it triggers a modal (`CreateUpdateDynamicListContent`) configured with the correct fields for
 * the specific item type. It supports customizing item labels and selecting colors for team positions.
 *
 * @component
 * @returns {JSX.Element} The DynamicList component, which includes a collapsible list of dynamic content and a modal for creating or updating items.
 */
export const DynamicList = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);
  const { fields } = useLabelFields();
  const { fields: absenceReasonsFields } = useAbsenceReasonsFields();
  const [tagColor, setTagColor] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { fields: labelColorFields } = useLabelColorFields(setTagColor);

  const chooseFields = (resourceName) => {
    switch (resourceName) {
      case 'absence-reasons':
        return absenceReasonsFields;
      case 'team-positions':
        return labelColorFields;
      default:
        return fields;
    }
  };

  const onModalOpen = (record, purpose, resourceName) => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <CreateUpdateDynamicListContent
        key={modalKey}
        resourceName={resourceName}
        purpose={purpose}
        record={record}
        fields={chooseFields(resourceName)}
        setRefresh={setRefresh}
        tagColor={tagColor}
        setTagColor={setTagColor}
      />
    );
  };
  const panelContent = usePanelContent(onModalOpen, setRefresh, refresh);

  return (
    <>
      {modal}
      <Collapse>
        {panelContent.map(({ label, key, children }) => (
          <Panel key={key} header={t(label)}>
            {children}
          </Panel>
        ))}
      </Collapse>
    </>
  );
};
