import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Switch } from 'antd';

/**
 * @component
 * @name ExtraButton
 * @description Displays the extra button in the payments card. It is used on the PaymentCard component
 * @param {string} tradKey Translation key
 * @param {function} setState Function to set the state
 * @returns {component} ExtraButton component
 */
export const ExtraButton = ({ tradKey, setState }) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" gap="small">
      {t(`${tradKey}.list.display_only_rejected_payments`)}
      <Switch
        onChange={() => setState((prevState) => !prevState)}
        defaultChecked
      />
    </Flex>
  );
};

ExtraButton.propTypes = {
  tradKey: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired
};
