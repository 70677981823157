/**
 * Generates properties for the Dragger component.
 * Provides functionalities like file removal, upload checks, and more.
 *
 * @function
 * @param {Array} tempFileList - The current temporary list of files.
 * @param {Function} setTempFileList - Setter function to update the temporary file list.
 * @param {Function} message - Function to display messages (like error notifications).
 * @param {Array} filesList - Array of contract files.
 * @param {Function} t - Translation module.
 *
 * @returns {Object} The properties for the Dragger component.
 */
export const draggerProps = (
  tempFileList,
  setTempFileList,
  message,
  filesList,
  t
) => ({
  multiple: false,
  onRemove: (file) => {
    const index = tempFileList.indexOf(file);
    const newFileList = tempFileList.slice();
    newFileList.splice(index, 1);
    setTempFileList(newFileList);
  },
  beforeUpload: (file) => {
    const existingFileName = filesList.some(
      (document) => document.name === file.name
    );
    if (existingFileName) return message(t('existing_file_name'));
    const fileExtension = file.name.split('.').pop();
    if (['png', 'PNG', 'jpg', 'JPG', 'pdf', 'PDF'].includes(fileExtension)) {
      setTempFileList([file]);
    } else {
      message('Not a PNG or JPG file.');
      return true;
    }
    return false;
  },
  fileList: tempFileList
});
