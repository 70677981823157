import dayjs from 'dayjs';

/**
 * Generates a date object from the provided event's date and week start.
 *
 * @function
 *
 * @param {object} event - The event object containing date and weekStart properties.
 * @returns {object} A dayjs date object representing the generated date.
 */
export const generateDate = (event) => {
  const dayMonth = event.date.split(' ')[1];
  const year = event.weekStart.split('-')[0];

  return dayjs(`${dayMonth}-${year}`, 'DD/MM/YYYY');
};
