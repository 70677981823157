import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Popconfirm,
  Skeleton,
  Space,
  Timeline,
  Typography,
  Table
} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useFollowUpContext } from '../../contexts/FollowUpContext';
import { activities as activitiesArray } from '../Configuration/Activities/activitiesArray';
import { useErrorMessage } from '../../utils/errorMessage';

const { Title } = Typography;

/**
 * A component for displaying the follow-up activities of a specific child.
 *
 * This component renders a timeline of activities related to a child. Each activity is presented with its
 * time and an edit icon, which triggers the provided `handleModalBehaviour` function with activity details.
 * It filters activities based on the selected child and displays them in a chronological timeline.
 * The component also includes an arrow icon to go back or deselect the current child.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleModalBehaviour - A function to handle modal interactions, such as editing an activity.
 * @returns {JSX.Element} The JSX rendering of the child's follow-up activities timeline.
 *
 * @example
 * const handleEdit = (activity, mode) => {
 *   // handle edit action
 * };
 * return (
 *   <ChildFollowUp
 *     handleModalBehaviour={handleEdit}
 *   />
 * )
 */
export const ChildFollowUp = ({ handleModalBehaviour }) => {
  const { t } = useTranslation();
  const { setChild, child, date, refresh, daysChildren } = useFollowUpContext();
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [childActivities, setChildActivities] = useState([]);
  const [refreshChildActivities, setRefreshChildActivities] = useState();

  const getChildFollowUp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `follow-ups/single-child?children=${child._id}&groups=${child.group._id}&date=${date}&daycare=${daycare}`
      });
      setChildActivities(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteChildFromActivity = async (activityID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `follow-ups/delete-child/${activityID}/${child._id}`
      });
      setRefreshChildActivities(!refreshChildActivities);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (child?._id) {
      (async () => {
        await getChildFollowUp();
      })();
    }
  }, [child, refresh, refreshChildActivities]);

  const nameColor = (gender) => {
    if (gender === 'BOY') {
      return 'var(--logoBlue)';
    }
    if (gender === 'GIRL') {
      return 'var(--logoPurple)';
    }
    return 'var(--mediumGray)';
  };

  const items = (childActivities || []).map((activity) => {
    const activityChildren = activitiesArray.find(
      (activityItem) => activityItem.resourceName === activity.activity.type
    );

    return {
      label: <Flex vertical>{dayjs(activity.date).format('HH:mm')}</Flex>,
      dot: <Button shape="circle">{activityChildren?.icon}</Button>,
      children: (
        <Flex align="center" gap="small">
          <Space>{activity.activity.label}</Space>
          {user?.role === 'users:TABLET' && (
            <Flex>
              <Button
                onClick={() => handleModalBehaviour(activity, 'edit')}
                type="link"
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteChildFromActivity(activity?._id)}
                icon={<DeleteOutlined />}
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Flex>
          )}
        </Flex>
      )
    };
  });

  const mustDisplayForParent =
    Object?.keys(child).length > 0 && items?.length > 0;
  const mustDisplayForTablet =
    Object?.keys(child).length > 0 &&
    daysChildren?.length > 0 &&
    items?.length > 0;
  const conditionToConsider =
    user.role === 'guests:PARENT' ? mustDisplayForParent : mustDisplayForTablet;

  return (
    <Flex vertical className="child-followup-wrapper" gap="small">
      {user?.role === 'users:TABLET' && (
        <Flex gap="middle" align="center">
          <ArrowLeftOutlined onClick={() => setChild({})} />
          <Title level={4} style={{ color: nameColor(child.gender) }}>
            {child?.first_name} {child?.last_name}
          </Title>
        </Flex>
      )}
      <Skeleton loading={!childActivities}>
        {conditionToConsider ? (
          <Timeline items={items} mode="left" className="followup-timeline" />
        ) : (
          <Table dataSource={[]} />
        )}
      </Skeleton>
    </Flex>
  );
};

ChildFollowUp.propTypes = {
  handleModalBehaviour: PropTypes.func
};

ChildFollowUp.defaultProps = {
  handleModalBehaviour: null
};
