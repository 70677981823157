import dayjs from 'dayjs';

/**
 * Generates a list of content items based on provided data.
 *
 * @hook
 *
 * @param {Object} data - The data object containing file, year, author, import_date, and remarks.
 * @param {Object} data.file - The file data.
 * @param {Object} data.year - The year data.
 * @param {Object} data.author - The author data.
 * @param {Object} data.import_date - The import date data.
 * @param {Object} data.remarks - The remarks data.
 * @returns {Array<Object>} An array of content items with label, span, and content properties.
 */
export const useListContent = (data = {}) => {
  const { file, year, author, import_date, remarks } = data;

  return [
    {
      label: 'analysis.table.file',
      span: 1,
      content: (file && file?.metadata?.originalName) || '-'
    },
    {
      label: 'analysis.table.year',
      span: 1,
      content: (year && dayjs(year).format('MM-YYYY')) || '-'
    },
    {
      label: 'analysis.table.import_date',
      span: 1,
      content: (import_date && dayjs(import_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'analysis.table.author',
      span: 1,
      content:
        (author && `${author?.first_name || ''} ${author?.last_name || ''}`) ||
        '-'
    },
    {
      label: 'analysis.table.remarks',
      span: 1,
      content: remarks || '-'
    }
  ];
};
